import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, Subscription, take } from 'rxjs';
import { Game } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';
import { DialogsService } from 'src/app/services/dialogs.service';
import * as confetti from 'canvas-confetti';
import { AdsService } from 'src/app/services/ads.service';
import { UserService } from 'src/app/services/user.service';
import { UserDetails } from 'src/app/interfaces/UserDetails';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit {

  @ViewChild('confettiContainer', { static: false })
  confettiContainer!: ElementRef;
  @ViewChild('thanks', { static: false })
  thanks!: ElementRef;
  adsAuctioned = false;
  scrolledTo = false;

  state$: Observable<Game>;
  user$: Observable<UserDetails>;
  state!: Subscription;

  constructor(private data: DataService,
    private dialog: DialogsService,
    private title: Title,
    private renderer: Renderer2,
    private ads: AdsService,
    private user: UserService
    ) {
    this.state$ = this.data.getState();
    this.title.setTitle('City - WhereTaken');
    this.user$ = this.user.getUserDetails();
  }

  ngOnInit() : void {
    this.data.updatePreviousPage("/city");
    this.checkState();
    this.data.checkIfNoCity();
  }

  checkState() {
    this.state = this.state$.subscribe((val: Game) => {
      if (val.showLinks) {
        if (!this.adsAuctioned) {
          this.ads.startAuction("end_mobile", 'mobile');
          this.adsAuctioned = true;
        }
        if(!this.scrolledTo) {
          setTimeout(() => {
            console.log("Scrolling to thanks");
            this.thanks.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: 'center' });
          }, 1000);
        }
      }
      this.checkConfettiLandmark(val);
    });
  }

  checkConfettiLandmark(val: Game) {
    if(val.cityGuess.guessed && !val.cityGuess.shownConfetti && val.hasMapAndCityRound) {
      this.confettiRun();
    }
  }

  confettiRun() {
    const canvas = this.renderer.createElement('canvas');
    this.renderer.appendChild(this.confettiContainer.nativeElement, canvas);

    let myConfetti = confetti.create(canvas, { resize: true });

    myConfetti({
      origin: {
        x: 0.5,
        y: 1,
      },
    });

    setTimeout(() => {
      myConfetti.reset();
    }, 1000);
  }

  ngOnDestroy() {
    this.state.unsubscribe();
  }
 }
