<div *ngIf="state$ | async as state">
    <h4 class="text-lg font-bold text-center mb-3 mt-8">
        What is the above landmark in {{state.country}} called?
    </h4>
    <div class="grid grid-cols-2 gap-2 mb-3">
        <div (click)="landmarkGuess(landmark, i)" *ngFor="let landmark of state.landmarksGuess.landmarks; let i = index"
            [ngClass]="{
                '!bg-green-300 !text-black': landmark.isCorrect && landmark.isGuessed,
                '!bg-white dark:!bg-slate-600 cursor-pointer' : !landmark.isGuessed && !state.landmarksGuess.roundOver,
                'hover:!bg-neutral-200 dark:hover:!bg-slate-700' : !landmark.isGuessed && !state.landmarksGuess.roundOver,
                'shake': landmark.shake,
                'bg-slate-400' : !landmark.isCorrect && landmark.isGuessed
            }" class="border-2 border-slate-300 rounded-md box select-none flex justify-center items-center text-center shadow-md">
            <div class="w-full px-1 py-3 flex justify-center items-center">
                <span>{{landmark.value}}</span>
            </div>
        </div>
    </div>
</div>