import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StatsToUpdate } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss']
})
export class ChangeComponent {

  showErrorAlert: boolean = false;
  showChangedAlert: boolean = false;

  constructor(private fb: FormBuilder, private data: DataService) {}

  statsForm = this.fb.group({
    totalGames: [0, Validators.required],
    currentStreak: [0, Validators.required],
    maxStreak: [0, Validators.required],
    one: [0, Validators.required],
    two: [0, Validators.required],
    three: [0, Validators.required],
    four: [0, Validators.required],
    five: [0, Validators.required],
    six: [0, Validators.required],
  });

  submitForm() {
    const val = this.statsForm.value;
    this.showErrorAlert = false;
    this.showChangedAlert = false;

    // if(!this.statsForm.valid) {
    //   return;
    // }

    if(!val.one) val.one = 0;
    if(!val.two) val.two = 0;
    if(!val.three) val.three = 0;
    if(!val.four) val.four = 0;
    if(!val.five) val.five = 0;
    if(!val.six) val.six = 0;
    if(!val.totalGames) val.totalGames = 0;
    if(!val.maxStreak) val.maxStreak = 0;
    if(!val.currentStreak) val.currentStreak = 0;

    const total = val.one + val.two + val.three + val.four + val.five + val.six;
    // console.log(val);

    if(val.currentStreak <= total && val.maxStreak <= total && val.totalGames >= total && val.totalGames >= val.currentStreak && val.totalGames >= val.maxStreak && val.totalGames <= 155 ) {
      const stats: StatsToUpdate = {
        totalGames: val.totalGames,
        maxStreak: val.maxStreak,
        currentStreak: val.currentStreak,
        stats: [val.one, val.two, val.three, val.four, val.five, val.six]
      }
      this.data.updateStatsManually(stats);
      this.showChangedAlert = true;
    } else {
      this.showErrorAlert = true;
    }
  }
}