import { Component } from '@angular/core';

@Component({
  selector: 'app-become-premium-member',
  templateUrl: './become-premium-member.component.html',
  styleUrls: ['./become-premium-member.component.scss']
})
export class BecomePremiumMemberComponent {

  signUpToPremium() {
    // if in localhost, navigate to http://localhost:4200/premium-signup, else navigate to https://account.teuteuf.fr/premium-signup
    if (window.location.href.includes('localhost')) {
      window.location.href = 'http://localhost:4200/premium-sign-up';
    } else {
      window.location.href = 'https://account.teuteuf.fr/premium-sign-up';
    }
  }

}
