import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ITeuteufGameInfo } from '../interfaces/interfaces';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class CDNDataService {
  private CDN_BASE_URL = 'https://teuteuf-dashboard-assets.pages.dev/';
  private CDN_DATA_URL = `${this.CDN_BASE_URL}data/`;
  private cache: { [endpoint: string]: any } = {};

  constructor(private http: HttpClient, private platform: PlatformService) {}

  private fetchJSONFromCDN(endpoint: string, useCache = true): Observable<any> {
    if (!this.platform.isInBrowser()) {
      return of(null);
    }

    if (useCache && this.cache[endpoint]) {
      return of(this.cache[endpoint]);
    }

    return this.http.get(this.CDN_DATA_URL + endpoint).pipe(
      tap((data) => {
        if (useCache) {
          this.cache[endpoint] = data;
        }
      })
    );
  }

  getGameInfo(): Observable<ITeuteufGameInfo> {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/teuteuf-games/games-info.json
    return this.fetchJSONFromCDN('teuteuf-games/games-info.json');
  }

  getGameIconUrl(gameName: string): string {
    // Example: https://teuteuf-dashboard-assets.pages.dev/data/teuteuf-games/icons/worldle.svg
    return (
      this.CDN_DATA_URL +
      'teuteuf-games/icons/' +
      gameName.split(' ').join('_').toLowerCase() +
      '.svg'
    );
  }
}
