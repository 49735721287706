import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as confetti from 'canvas-confetti';
import { Observable, Subscription } from 'rxjs';
import { Game } from 'src/app/interfaces/interfaces';
import { AdsService } from 'src/app/services/ads.service';
import { DataService } from 'src/app/services/data.service';
import { DialogsService } from 'src/app/services/dialogs.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  state$: Observable<Game>;
  @ViewChild('confettiContainer', { static: false })
  confettiContainer!: ElementRef;
  @ViewChild('secondQuestion', { static: false })
  secondQuestion!: ElementRef;
  @ViewChild('bonusRound', { static: false })
  bonusRound!: ElementRef;
  state!: Subscription;

  confetti = false;
  showConfetti = true;
  scrolledTo = false;
  adsAuctioned = false;
  adsScrolledTo = false;

  constructor(private data: DataService,
    private renderer: Renderer2,
    private dialog: DialogsService,
    private title: Title,
    private ads: AdsService
    ) {
    this.state$ = this.data.getState();
  }

  ngOnInit(): void {
    this.checkState();
    this.data.updatePreviousPage("/map");
    this.title.setTitle('Map - WhereTaken');
    this.data.checkIfNoMap();
  }

  checkState() {
    this.state = this.state$.subscribe((val) => {
      this.checkConfetti(val);
      if (val.map.roundOver) {
        this.startAuctionMobile();
        this.scrollToMobileAds(val)
      }
      else if (!this.scrolledTo && val.map.showRound) {
        setTimeout(() => {
          this.scrolledTo = true;
          this.secondQuestion.nativeElement.scrollIntoView({ behavior: "smooth", block: "center", inline: 'center' });
          this.startAuctionMobile();
        }, 400)
      }
    });
  }

  scrollToMobileAds(state: Game) {
    if (state.landmarksGuess.roundOver && !this.adsScrolledTo)
      setTimeout(() => {
        this.bonusRound.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: 'center' });
      }, 500)
      this.adsScrolledTo = true;
  }

  startAuctionMobile() {
    if(!this.adsAuctioned) {
      this.ads.startAuction("end_mobile", 'mobile');
      this.adsAuctioned = true;
    }
  }

  showImageDialog() {
    this.dialog.openLandmarkImage();
  }

  checkConfetti(val: any) {
    if (
      val.map.guessed &&
      !val.map.shownConfetti &&
      this.confetti === false
    ) {
      this.confettiRun();
      setTimeout(() => {
        this.showConfetti = false;
      }, 3000);
      this.confetti = true;
    }
  }


  confettiRun() {
    const canvas = this.renderer.createElement('canvas');
    if(!this.confettiContainer?.nativeElement) {
      return;
    }
    this.renderer.appendChild(this.confettiContainer.nativeElement, canvas);
    let myConfetti = confetti.create(canvas, { resize: true });

    myConfetti({
      origin: {
        x: 0.5,
        y: 1,
      },
    });
  }

  ngOnDestroy() {
    this.state.unsubscribe();
  }

}
