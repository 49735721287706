import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Game } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-landmark-image',
  templateUrl: './landmark-image.component.html',
  styleUrls: ['./landmark-image.component.scss']
})
export class LandmarkImageComponent {

  state$: Observable<Game>;

  constructor(private data: DataService) {
    this.state$ = this.data.getState();
  }
}
