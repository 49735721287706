<div class="w-full flex justify-center items-center flex-col gap-2 mt-4" *ngIf="state$ | async as state">
    <div *ngIf="!canShare" class="w-full md:w-2/3">
        <button (click)="share()" class="rounded font-bold border-2 px-4 py-3 p-1 flex gap-2 bg-green-600 hover:bg-green-500 active:bg-green-700 text-lg text-white w-full justify-center items-center">
            <img class="w-6 invert" src="/assets/share.svg">
            <span *ngIf="!copied">Share</span>
            <span *ngIf="copied">Copied to clipboard!</span>
        </button>
    </div>
    <div *ngIf="canShare" class="flex w-full">
        <button (click)="share()" class="rounded font-bold border-2 px-4 py-3 w-3/6 flex gap-2 bg-green-600 hover:bg-green-500 active:bg-green-700 text-lg text-white justify-center items-center">
            <img class="w-5 invert" src="/assets/share.svg">
            <span>Share</span>
        </button>
        <button (click)="copy()" class="rounded font-bold border-2 px-4 py-3 w-3/6 flex gap-2 bg-slate-600 hover:bg-slate-500 active:bg-green-700 text-lg text-white justify-center items-center">
            <img class="w-5 invert" src="/assets/copy.svg">
            <span *ngIf="!copied">Copy</span>
            <span *ngIf="copied">Copied!</span>
        </button>
    </div>
</div>
