import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor() { }

  @Output() boxClicked = new EventEmitter<string>();

  boxClickedEvent(msg: string) {
    this.boxClicked.emit(msg);
  }
}
