import { Injectable } from '@angular/core';
import { randomPerms } from '../data/perms';
import { countries } from '../data/countries';

import { Observable, take } from 'rxjs';
import { UserDetails } from '../interfaces/UserDetails';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { ArchiveGame } from '../interfaces/ArchiveGame';
import { ImageService } from './image.service';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {

  user$: Observable<UserDetails>;

  constructor(private router: Router, private user: UserService, private image: ImageService) {
    this.user$ = this.user.getUserDetails();
  }

  getArchiveGames() {
    let archiveGames: ArchiveGame[] = [];

    const perms = randomPerms;
    const states = countries;

    let permsToDate = [];
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowString = `${tomorrow.getDate()}/${tomorrow.getMonth() + 1}/${tomorrow.getFullYear()}`;

    for (let i = 1; i < perms.length; i++) {
      if (perms[i].date == tomorrowString) {
        break;
      }
      permsToDate.push(perms[i]);
    }

    // Check if supports webp
    const supportsWebP = this.image.supportsWebP();
    let extension = 'jpg';
    if (supportsWebP) {
      extension = 'webp';
    } else extension = 'jpg';


    permsToDate.forEach(perm => {
      states.forEach(state => {
        state.game.forEach(game => {
          if (perm.country === state.name && perm.photoCode === game.number) {
            archiveGames.push({
              number: perm.number,
              date: perm.date,
              code: game.uniqueId,
              src: `/assets/countries/${state.code.toLowerCase()}/main${perm.photoCode}.${extension}`,
            });
          }
        });
      });
    });
    archiveGames.reverse();
    return archiveGames;
  }

  replayTodaysGame() {
    this.user$.pipe(take(1)).subscribe((user: UserDetails) => {
      if (user?.subscription?.isPremium) {
        const perms = randomPerms;
        const states = countries;

        const today = new Date();
        const todayString = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

        let stateName = "";
        let photoCode = 0;
        perms.forEach(perm => {
          if (perm.date == todayString) {
            stateName = perm.country;
            photoCode = perm.photoCode;
          }
        });

        let gameCode = "";
        states.forEach(state => {
          state.game.forEach(game => {
            if (state.name === stateName && game.number === photoCode) {
              gameCode = game.uniqueId;
            }
          });
        });

        this.router.navigate(['/'], { queryParams: { game: gameCode } });

      } else {
        this.router.navigate(['/replay']);
      }
    })
  }
}
