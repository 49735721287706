<div class="wrapper mt-3" *ngIf="state$ | async as state">
  <app-image [imgSrc]="state.imgSrc" class="w-full mb-2"></app-image>
  <app-progress></app-progress>
  <app-guesses></app-guesses>
  <div *ngIf="banner$ | async as banner" class="w-full">
    <div class="bg-blue-100 border-t-4 border-blue-500 rounded text-blue-900 px-4 py-3 shadow-md mt-4 w-full flex gap-1"
      role="alert" *ngIf="banner && banner.title && banner.text">

      <svg style="width: auto !important;" class="fill-current h-6 w-6 text-blue-500 mr-4 flex-shrink-0 !mt-1 md:!mt-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
    </svg>

      <!-- Add flex-grow class here -->
      <div class="flex-grow">
        <!-- Dynamically insert form values here -->
        <h4 class="font-bold banner text-lg md:leading-5 !mb-1">{{ banner.title }}</h4>
        <p class="text-sm banner">{{ banner.text }}</p>
        <a (click)="linkClicked()" *ngIf="banner && banner.linkAddress && banner.linkText" href="{{ banner.linkAddress }}" target="_blank"
          rel="noopener noreferrer" class="text-sm underline banner">
          {{ banner.linkText }}
        </a>
      </div>
    </div>


  </div>
</div>
<br />


<!-- <pre>
    {{ state$ | async | json }}
</pre> -->
