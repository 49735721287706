<mat-dialog-content>
  <div class="text-black dark:!text-white">
    <div class="absolute right-4 top-4">
      <button
        (click)="closeDialog()"
        mat-button
        class="text-black font-bold rounded"
      >
        &#x2715;
      </button>
    </div>
    <h4 class="font-bold text-center mt-3 mb-3">
      <img
        class="inline-img inline-block w-6 mr-1 align-middle"
        ngSrc="./assets/wt-icon.svg"
        width="72"
        height="72"
      /><b>Where</b>Taken
    </h4>
    <p class="text-center mb-4">
      <b class="text-black dark:!text-white"
        >WHERE<span class="text-blue-600 dark:text-blue-300">TAKEN</span></b
      >
      <img
        class="inline-img inline-block w-4 align-middle ml-1"
        ngSrc="./assets/world.png"
        width="72"
        height="72"
      />
      is now
      <b class="text-black dark:!text-white"
        >WHERE<span class="text-blue-600 dark:text-blue-300">TAKEN</span></b
      >
      <img
        class="inline-img inline-block w-4 align-middle mx-1"
        ngSrc="./assets/world.png"
        width="72"
        height="72"
      />
      <i>Classic</i>.
    </p>
    <p class="text-center">
      Check out our brand-new
      <a href="https://wheretaken.com"
        ><img
          class="inline-img inline-block w-5 mr-1 align-middle"
          ngSrc="./assets/wt-icon.svg"
          width="72"
          height="72"
        /><b>Where</b>Taken</a
      >
      designed for photography lovers and travellers who appreciate awesome
      locations around the world.
    </p>
  </div>
  <div class="w-full flex justify-center items-center mt-2">
    <a
      href="https://wheretaken.com"
      class="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded"
    >
      Play NEW
      <img
        class="inline-img inline-block w-5 mr-1 align-middle"
        ngSrc="./assets/wt-icon.svg"
        width="72"
        height="72"
      /><b>Where</b>Taken
    </a>
  </div>
</mat-dialog-content>
