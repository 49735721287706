import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-remove-ads',
  templateUrl: './remove-ads.component.html',
  styleUrls: ['./remove-ads.component.scss']
})
export class RemoveAdsComponent {

  user$: Observable<UserDetails>

  constructor(private user: UserService) {
    this.user$ = this.user.getUserDetails()
  }
}
