<div *ngIf="state$ | async as state">
  <div *ngIf="!(userDetails$ | async)!?.subscription?.isPremium">
    <div class="lg:hidden w-full fixed z-50">
        <div [hidden]="!state.topAdOpen" class="ad-body flex flex-shrink hide-desktop justify-center w-full items-center bg-white dark:bg-slate-700">
            <!-- adngin-top_banner-0  -->
            <div class="mobile-banner-ad lg:hidden w-fill">
                <div id="adngin-top_banner-0"></div>
            </div>
        </div>
        <div [hidden]="state.topAdOpen" class="ad-body h-1 bg-white">
        </div>
        <div class="arrow flex flex-shrink bg-white dark:bg-slate-700 justify-center items-center">
            <button [hidden]="!state.topAdOpen" (click)="toggleTopAd(state.topAdOpen)">
                <img class="w-4" src="./assets/chevron-up.png">
            </button>
            <button [hidden]="state.topAdOpen" (click)="toggleTopAd(state.topAdOpen)">
                <img class="w-4" src="./assets/chevron-down.png">
            </button>
        </div>
    </div>
  </div>
</div>
