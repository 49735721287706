import { Component } from '@angular/core';

@Component({
  selector: 'app-we-logged-you-out',
  templateUrl: './we-logged-you-out.component.html',
  styleUrls: ['./we-logged-you-out.component.scss']
})
export class WeLoggedYouOutComponent {

}
