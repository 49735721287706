import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogsService } from 'src/app/services/dialogs.service';

@Component({
  selector: 'app-enlarge-image',
  templateUrl: './enlarge-image.component.html',
  styleUrls: ['./enlarge-image.component.scss']
})
export class EnlargeImageComponent {

  image$: Observable<string>;

  constructor(private dialog: DialogsService) {
    this.image$ = this.dialog.getImageSrc();
  }
}
