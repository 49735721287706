<div class="mt-3">
  <a (click)="navigateBack()" class="text-blue-500 hover:text-blue-400 cursor-pointer">&#8592; Back</a>
  <div *ngIf="content$ | async as content"
    class="flex flex-col w-full justify-center items-center text-center gap-4 my-4 text-black dark:!text-white">
    <h4>
      <b>{{content.heading.translations[langCode]}}</b>
    </h4>
    <p>
      {{content.subheading.translations[langCode]}}
    </p>

    <!-- Mobile Game List -->
    <div class="flex flex-col gap-3 md:hidden text-start">
      <a *ngFor="let game of content.games" [href]="game.url + '?utm_source=wheretaken_classic&utm_campaign=game_links_page'"
        target="_blank" rel="noreferrer"
        class="flex flex-row w-full items-start gap-3 rounded-md border-2 hover:bg-gray-100 hover:dark:bg-slate-800 p-3 hover:text-current">
        <img [src]="getGameIconUrl(game.name)" class="w-10 h-10" />
        <div class="flex w-full flex-col">
          <h3 class="text-base font-bold">{{ game.name }}</h3>
          <p class="text-sm">{{ game.captions[langCode] }}</p>
        </div>
      </a>
    </div>

    <!-- Desktop Game List -->
    <div class="hidden md:grid grid-cols-2 mt-4 gap-x-3 gap-y-6">
      <a *ngFor="let game of content.games" [href]="game.url + '?utm_source=wheretaken_classic&utm_campaign=game_links_page'"
        target="_blank" rel="noreferrer"
        class="relative flex flex-col w-full items-center gap-2 rounded-md border-2 hover:bg-gray-100 hover:dark:bg-slate-800 p-4 pt-8 hover:text-current">
        <img [src]="getGameIconUrl(game.name)" class="w-10 h-10 mb-4 absolute top-0 -translate-y-1/2 z-auto" />
        <h3 class="text-center text-lg font-bold leading-none">{{ game.name }}</h3>
        <p class="text-center text-sm">{{ game.captions[langCode] }}</p>
      </a>
    </div>

  </div>
</div>