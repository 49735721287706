import { Component, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Game } from 'src/app/interfaces/interfaces';
import { ArchiveService } from 'src/app/services/archive.service';
import { DataService } from 'src/app/services/data.service';

interface Star {
  show: boolean,
  zoom: boolean
}

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss']
})
export class EndComponent implements OnInit{
  state$: Observable<Game>;
  numberOfStars: number = 0;
  stars: Star[] = [
    {
      show: false,
      zoom: false
    },
    {
      show: false,
      zoom: false
    },
    {
      show: false,
      zoom: false
    },
    {
      show: false,
      zoom: false
    }
  ];


  constructor(private data: DataService, private archive: ArchiveService) {
    this.state$ = this.data.getState();
  }

  ngOnInit() : void {
    this.state$.pipe(take(1)).subscribe(game => {
      game.guessed ? this.numberOfStars++ : null;
      game.landmarks.guessed && game.landmarksGuess.guessed? this.numberOfStars++ : null;
      game.map.guessed && game.capitalCity.guessed ? this.numberOfStars++ : null;
      game.cityGuess.guessed ? this.numberOfStars++ : null;
      setTimeout(() => {
        this.runStars();
      }, 1500)
    })
  }

  runStars() {
    for(let i = 1; i < this.numberOfStars+1; i++) {
      setTimeout(() => {
        this.stars[i-1].show = true;
      }, i*200)
    }
  }

  replayGame() {
    this.archive.replayTodaysGame();
  }
}
