import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Game, LandmarkGuess } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-landmark-guess',
  templateUrl: './landmark-guess.component.html',
  styleUrls: ['./landmark-guess.component.scss']
})
export class LandmarkGuessComponent {

  state$: Observable<Game>

  constructor(private data: DataService){
    this.state$ = this.data.getState();
  }

  landmarkGuess(landmark: LandmarkGuess, index: number) {
    this.data.landmarkNameGuess(landmark, index);
  }
}
