<div class="text-black dark:!text-white border border-slate-300 shadow-sm px-4 rounded bg-white dark:!bg-slate-700"
  *ngIf="state$ | async as state">
  <h3 class="text-base md:text-xl mt-3 font-bold">Thanks for playing</h3>
  <h4 class="font-bold text-center mb-1">
    <div *ngIf="state.stars.number === 0">
      <div class="text-lg">Today you're a hermit</div>
    </div>
    <div *ngIf="state.stars.number === 1">
      <div class="text-lg">Today you're a tourist</div>
    </div>
    <div *ngIf="state.stars.number === 2">
      <div class="text-lg">Today you're a traveler</div>
    </div>
    <div *ngIf="state.stars.number === 3">
      <div class="text-lg">Today, you're an explorer</div>
    </div>
    <div *ngIf="state.stars.number === 4">
      <div class="text-lg">Today, you're an expert</div>
    </div>
    <div *ngIf="state.stars.number === 5">
      <div class="text-lg">Today, you're a superstar</div>
    </div>
  </h4>
  <div class="flex justify-center gap-3">
    <div *ngFor="let star of stars" class="w-8 md:w-12">
      <img *ngIf="!star.show" ngSrc="./assets/star.svg" width="200" height="200" alt="Empty star">
      <img class="scale" [hidden]="!star.show" ngSrc="./assets/star-filled.svg" width="200" height="200"
        alt="Empty star">
    </div>
  </div>
  <div class="flex justify-center mt-3">
    <div class="w-3/6 md:w-6/12">
      <img class="rounded" [ngSrc]="state.turtleSrc" width="800" height="800">
    </div>
  </div>
  <div class="mb-2">
    <app-share-buttons></app-share-buttons>
  </div>
  <div class="flex gap-2 items-center justify-center mb-4">
    <button (click)="replayGame()"
      class="text-sm md:text-base border bg-neutral-100 hover:bg-neutral-200 border-black rounded-md text-black font-bold py-2 px-2 shadow-md">Replay
      game</button>
    <button routerLink="/archive"
      class="text-sm md:text-base border bg-neutral-100 hover:bg-neutral-200 border-black rounded-md text-black font-bold py-2 px-2 shadow-md">Play
      archive</button>
  </div>

</div>
