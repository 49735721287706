import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
})
export class RulesComponent implements OnInit {

  isAdmin = false;

  constructor(private title: Title, private data: DataService, private platform: PlatformService) {
    this.title.setTitle('Rules - WhereTaken');
  }

  ngOnInit(): void {
    if(!this.platform.isInBrowser()) return;
    const admin = localStorage.getItem('admin');
    this.isAdmin = admin === 'true' ? true : false;
  }

  navigateBack() {
    this.data.navigateToPreviousPage();
  }
}
