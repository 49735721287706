import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { BehaviorSubject } from 'rxjs';
import { initalBanner } from '../data/initialBanner';
import { initialLink } from '../data/initialLink';

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  private banner = new BehaviorSubject(JSON.parse(JSON.stringify(initalBanner)));
  public banner$ = this.banner.asObservable();

  private links = new BehaviorSubject(JSON.parse(JSON.stringify(initialLink)));
  public links$ = this.links.asObservable();

  constructor(private db: DatabaseService) { }

  getBannerAndLinks() {
    this.db.getBannerAndLinks().subscribe((data: any) => {
      if(data && data.banner) this.banner.next(data.banner);
      if(data && data.link) this.links.next(data.link);
    });
  }
}
