import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatsUpdateService {

  private password = new BehaviorSubject(false);

  constructor() { }

  getPasswordState() : Observable<boolean> {
    return this.password.asObservable();
  }

  enteredPassword() : boolean {
    const state = this.password.value;
    return state;
  }

  updatePassword() {
    this.password.next(true);
  }
}
