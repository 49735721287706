import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  public sendEvent(eventName: string, params: {}): void {
    gtag('event', eventName, params);
  }
}
