import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, take } from 'rxjs';
import { ArchiveGame, Game, Stats } from './interfaces/interfaces';
import { DataService } from './services/data.service';
import { DialogsService } from './services/dialogs.service';
import { PlatformService } from './services/platform.service';
import { UserService } from './services/user.service';
import { UserDetails } from './interfaces/UserDetails';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  queryParams!: Subscription;

  state$: Observable<Game>;
  stats$: Observable<Stats>;
  archive$: Observable<ArchiveGame>;
  user$: Observable<UserDetails>;

  constructor(
    private data: DataService,
    private dialog: DialogsService,
    private route: ActivatedRoute,
    private router: Router,
    private platform: PlatformService,
    private user: UserService
  ) {
    this.state$ = this.data.getState();
    this.stats$ = this.user.getStats();
    this.archive$ = this.data.getIsArchive();
    this.user$ = this.user.getUserDetails();
  }

  ngOnInit(): void {
    this.data.loadStats();
    this.data.loadDist();
    this.data.loadDifficulty();
    this.data.preloadImagesRoundOne();
    this.user.loadUserAndRefreshTokens();
    this.data.checkShouldAdvertiseWhereTakenNew();
    this.data.checkForIFrame();
  }

  ngAfterViewInit() {
    if(!this.platform.isInBrowser()) return;
    if(localStorage.getItem("dark-mode") === "true") {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  returnToToday() {
    this.data.resetArchiveGame();
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.queryParams && this.queryParams.unsubscribe();
  }
}
