import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  map,
  Observable,
  Subscription
} from 'rxjs';
import { DataService } from '../../services/data.service';
import { Country, Game, Stats } from '../../interfaces/interfaces';
import { CountriesService } from 'src/app/services/countries.service';
import * as confetti from 'canvas-confetti';
import { EventsService } from 'src/app/services/events.service';
import { Router } from '@angular/router';
import { AdsService } from 'src/app/services/ads.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  valueChanges!: Subscription;
  state!: Subscription;
  box!: Subscription;

  confetti = false;
  showConfetti = true;

  @ViewChild('dropdown', { read: ElementRef, static: false })
  dropdown!: ElementRef;
  @ViewChild('confettiContainer', { static: false })
  confettiContainer!: ElementRef;
  @ViewChild('inputBox', { static: false })
  inputBox!: ElementRef;
  @ViewChild('bonusRound', { static: false })
  bonusRound!: ElementRef;

  @HostListener('document:click', ['$event'])
  clickOut() {
    if (this.dropdown !== undefined) {
      if (this.focusClicked) {
        this.focusClicked = false;
      } else {
        this.showListBox = false;
      }
    }
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.showListBox && this.list.length > 0) {
      this.input.setValue(this.list[0]);
      this.showListBox = false;
    } else {
      this.guess();
    }
  }

  showListBox = false;
  input = new UntypedFormControl('');
  focusClicked = false;
  countryNames: string[] = [];
  list: string[] = [];
  state$: Observable<Game>;
  showAnswer$: Observable<boolean>;
  stats$: Observable<Stats>;
  inputValue$: any;
  copied = false;
  adsAuctioned = false;

  constructor(
    private data: DataService,
    private countries: CountriesService,
    private renderer: Renderer2,
    private events: EventsService,
    private router: Router,
    private ads: AdsService,
    private user: UserService
  ) {
    this.state$ = this.data.getState();
    this.showAnswer$ = this.state$.pipe(map((val) => val.showAnswer));
    this.inputValue$ = this.state$.pipe(map((val) => val.guessValue));
    this.stats$ = this.user.getStats();
  }

  ngOnInit(): void {
    this.onChanges();
    this.inputChange();
    let countries: Country[] = this.countries.getCountries();
    this.countryNames = countries.map((val) => val.name);
    this.list = JSON.parse(JSON.stringify(this.countryNames));
    this.filterList("");
    this.box = this.events.boxClicked.subscribe(val => {
      this.inputBox && this.inputBox.nativeElement.focus();
    })
  }

  goToLandmarks() {
    this.ads.startAuction('sidebar_left', 'desktop');
    this.router.navigate(['/landmarks']);
  }

  onFocus() {
    if (this.input.value.length >= 0) {
      this.focusClicked = true;
      this.showListBox = true;
    }
  }

  onChanges() {
    this.valueChanges = this.input.valueChanges.subscribe((val) => {
      if (val.length > 0) {
        this.showListBox = true;
        this.filterList(val);
      } else {
        this.showListBox = false;
      }
    });
  }

  inputChange() {
    this.state = this.state$.subscribe((val) => {
      this.input.setValue(val.guessValue);
      if (
        val.showShare &&
        !val.showAnswer &&
        !val.shownConfetti &&
        this.confetti === false
      ) {
        this.confettiRun();
        setTimeout(() => {
          this.showConfetti = false;
        }, 3000);
        this.data.updateConfetti();
        this.confetti = true;
      }
      if (val.showShare) {
        if (!this.adsAuctioned) {
          this.ads.startAuction("end_mobile", 'mobile');
          this.adsAuctioned = true;
          (async () => {
            setTimeout(() => {
              this.bonusRound.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: 'center' });
            }, 1000);
          })();
        }
      }
    });
  }

  filterList(val: string) {
    this.list = [];
    this.countryNames.forEach((word) => {
      if (word.toLowerCase().includes(val.toLowerCase())) {
        this.list.push(word);
      }
    });
  }

  guess() {
    this.data.guess(this.input.value);
    this.filterList("");
  }

  countrySelect(country: string) {
    this.data.updateInputValue(country);
    this.showListBox = false;
  }

  ngOnDestroy() {
    this.valueChanges.unsubscribe();
    this.state.unsubscribe();
    this.box && this.box.unsubscribe();
  }

  confettiRun() {
    const canvas = this.renderer.createElement('canvas');
    this.renderer.appendChild(this.confettiContainer.nativeElement, canvas);

    let myConfetti = confetti.create(canvas, { resize: true });

    myConfetti({
      origin: {
        x: 0.5,
        y: 1,
      },
    });
  }
}
