<div *ngIf="state$ | async as state" class="mt-3 text-black dark:!text-white relative">
    <a routerLink="/" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>
    <h4 class="text-lg font-bold text-center mb-2 mt-3">
        Which landmark is in {{ state.country }}?
    </h4>
    <div [hidden]="!state.landmarks.zoom" class="relative p-2 mb-2">
        <!-- <img class="rounded shadow-md" [src]="state.landmarks.zoomImage"> -->
        <app-image [imgSrc]="state.landmarks.zoomImage" class="w-full"></app-image>
        <a class="cursor-pointer" (click)="zoom(1)">
            <img src="/assets/decrease.png" class="invert absolute right-4 top-4 p-2 w-12 h-12 opacity-80">
        </a>
    </div>
    <div class="w-full grid grid-cols-2 mb-2" [hidden]="state.landmarks.zoom">
        <div *ngFor="let landmark of state.landmarks.locations; let i = index" class="m-1"
            [ngClass]="{'shake' : landmark.shake}">
            <div class="relative">
                <a [ngClass]="{ 'cursor-pointer' : !landmark.isGuessed}" (click)="showButtons(landmark, i)">

                    <img class="shadow-md cursor-pointer" [ngSrc]="landmark.imgSrc" width="1240" height="900"
                        [ngClass]="{
                                'grayscale' : !landmark.isCorrect && landmark.isGuessed
                            }">
                    <p *ngIf="landmark.isCorrect && landmark.isGuessed" class="text-3xl absolute z-10 bottom-3 right-4">
                        ✅</p>
                </a>
                <a (click)="zoom(landmark)" class="cursor-pointer">
                    <img src="/assets/increase.png" class="invert absolute right-0 top-0 p-3 w-12 h-12 opacity-80">
                </a>
                <div [hidden]="!state.landmarks.buttons[i]"
                    class="absolute z-10 bottom-4 w-full flex justify-center gap-2">
                    <button (click)="guess(landmark, i)"
                        class="rounded font-bold px-2 font-0 py-1 flex gap-2 border bg-green-600 hover:bg-green-500 active:bg-green-700 text-sm text-white justify-center items-center">Confirm</button>
                    <button (click)="hideButtons(i)"
                        class="rounded font-bold px-2 font-0 py-1 flex gap-2 border bg-red-600 hover:bg-red-500 active:bg-red-700 text-sm text-white justify-center items-center">
                        <img class="invert w-4 h-auto" ngSrc="/assets/close.svg" width="256" height="256">
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div #secondQuestion *ngIf="state.landmarksGuess.showRound">
        <app-landmark-guess></app-landmark-guess>
    </div>
    <div class="confetti pointer-events-none"
      #confettiContainer
      [ngClass]="{
        'first-question' : !state.landmarksGuess.showRound,
        'second-question' : state.landmarksGuess.showRound
      }"
      ></div>
    <app-progress></app-progress>
    <div *ngIf="!state.landmarks.roundOver" class="mt-2">
        <p class="text-center">
            Click on a landmark. You have <span>{{ state.landmarks.guessesRemaining }}</span> guess<span
                *ngIf="state.landmarks.guessesRemaining >= 2 || state.landmarks.guessesRemaining <=0">es</span>
            remaining.
        </p>
    </div>
    <div *ngIf="state.landmarksGuess.showRound && !state.landmarksGuess.roundOver" class="mt-2">
        <p class="text-center">
            Click on a landmark name. You have <span>{{ state.landmarksGuess.guessesRemaining }}</span> guess<span
                *ngIf="state.landmarksGuess.guessesRemaining >= 2 || state.landmarksGuess.guessesRemaining <=0">es</span>
            remaining.
        </p>
    </div>
    <div #bonusRound *ngIf="state.landmarksGuess.roundOver">
        <p *ngIf="state.landmarks.guessed && state.landmarksGuess.guessed
        " class="text-center mt-3 font-bold">
            Nice one! You got both correct today!
        </p>
        <p *ngIf="state.landmarks.guessed && !state.landmarksGuess.guessed ||
            !state.landmarks.guessed && state.landmarksGuess.guessed
        " class="text-center mt-3 font-bold">
            You got one out of two correct today. You just missed out on a star.
        </p>
        <p *ngIf="!state.landmarks.guessed && !state.landmarksGuess.guessed" class="text-center mt-3 font-bold">Oh no,
            you didn't get either landmark question today! 😢</p>
        <div class="p-2 bg-gray-300 dark:bg-slate-700 rounded text-center flex flex-col justify-center items-center gap-1 mt-4"
            *ngIf="true">
            <p class="font-bold text-black dark:!text-white">
                <span class="text-orange-700">★</span> Bonus Round ~ 2/3 - Map <span class="text-orange-700">★</span>
            </p>
            <button class="!bg-green-600 text-white mb-2 w-full rounded py-1" routerLink="/map">
                <b>🎁 Play the bonus round</b>
            </button>
        </div>
        <app-share-buttons></app-share-buttons>
      </div>
      <app-end-mobile-ad></app-end-mobile-ad>
</div>

<!-- <pre>
    {{ (state$ | async)?.landmarks | json }}
</pre> -->
