import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }

  isSameUtcDay(refreshTimeString: string): boolean {
    // Parse the refreshTime as a Date object
    const refreshDate = new Date(refreshTimeString);

    // Get the current date in UTC
    const currentDate = new Date();

    // Compare the year, month, and day
    return refreshDate.getUTCFullYear() === currentDate.getUTCFullYear()
      && refreshDate.getUTCMonth() === currentDate.getUTCMonth()
      && refreshDate.getUTCDate() === currentDate.getUTCDate();
  }

    // Function that takes in a photoURL and checks whether it is a valid photo
    checkPhotoValidity(photoURL: string): Promise<boolean> {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = photoURL;
        image.onload = () => resolve(true);
        image.onerror = () => resolve(false);
      });
    }

    isSameDay(dateString1: string, dateString2: string): boolean {
      const date1 = new Date(dateString1);
      const date2 = new Date(dateString2);

      return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
    }
}
