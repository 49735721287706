import { Component, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { StatsUpdateService } from 'src/app/services/stats-update.service';

@Component({
  selector: 'app-change-stats',
  templateUrl: './change-stats.component.html',
  styleUrls: ['./change-stats.component.scss']
})
export class ChangeStatsComponent {

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.passwordEntered();
  }

  input = new UntypedFormControl('');
  showAlert: boolean = false;

  constructor(private changeStats: StatsUpdateService, private router: Router) {}

  passwordEntered() {
    const hash = this.hashCode(this.input.value);
    console.log(hash);
    if (hash === 457716897) {
      this.showAlert = false;
      this.changeStats.updatePassword();
      this.router.navigate(['/fix-stats/change'])
    } else {
      this.showAlert = true;
    }
  }

  hashCode(str: string) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return hash;
  }
}

// s7UbRoThl@rorUK8che9
// BeautifulWorld