<mat-dialog-content>
    <div class="text-black dark:!text-white" *ngIf="state$ | async as state">
        <h4 class="font-bold text-center mt-3 mb-3">
            Here's the photo of {{ state.landmarks.answer }}
        </h4>
        <img class="shadow-md" [ngSrc]="state.landmarks.answerSrc" width="992" height="720">
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="w-full flex justify-end items-end">
        <button mat-button mat-dialog-close
            class="button-blue text-white font-bold py-2 px-4 rounded">Close</button>
    </div>
</mat-dialog-actions>
