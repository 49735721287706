import { Country } from '../interfaces/interfaces';

export const countries: Country[] = [
  {
    name: 'Afghanistan',
    code: 'AF',
    capital: 'Kabul',
    lat: 33,
    long: 65,
    cities: [
      'Jalalabad',
      'Ghazni',
      'Kunduz',
      'Kandahar',
      'Mazar-i-Sharif',
      'Kabul',
      'Herat',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: 'Buddhas of Bamiyan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/TLKCx6mVIHo',
          wikiLink: 'https://en.wikipedia.org/wiki/Buddhas_of_Bamiyan',
        },
        landmark: {
          name: 'Hazrat Ali Mazar',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Hazrat_Ali_Mazar#/media/File:Blue_Mosque_in_the_northern_Afghan_city_in_2012.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hazrat_Ali_Mazar',
          hasLandmark: true,
        },
        city: {
          name: 'Kandahar',
          photographer: '',
          imageLink:
            'https://pixnio.com/miscellaneous/laying-asphalt-over-culvets-on-kabul-kandahar-road',
          wikiLink: 'https://en.wikipedia.org/wiki/Kandahar',
        },
        landmarksRound: [
          { code: 'BA', number: 1 },
          { code: 'GT', number: 1 },
          { code: 'PF', number: 1 },
        ],
        landmarkPlaces: [
          'Belem Tower',
          'House of the Black Heads',
          'Grytviken Church',
          'Fort Sao Sebastiao',
          'Sky Tower',
          'CN Tower',
          'Kim Il Sung Square',
        ],
        uniqueId: 'NJFsp2rM',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/afghanistan-houses-homes-buildings-79493/',
          wikiLink: 'https://en.wikipedia.org/wiki/Afghanistan',
        },
        landmark: {
          name: 'Herat Citadel',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Herat_Citadel#/media/File:View_of_Herat_Citadel_from_atop_the_premises.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Herat_Citadel',
          hasLandmark: true,
        },
        city: {
          name: 'Jalalabad',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Jalalabad#/media/File:Aerial_view_of_Jalalabad_in_2012.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Jalalabad',
        },
        landmarksRound: [
          { code: 'GH', number: 2 },
          { code: 'CD', number: 2 },
          { code: 'RO', number: 2 },
        ],
        landmarkPlaces: [
          "St. Tryphon's Cathedral",
          'Saqsayhuaman',
          'Seven Colored Earths',
          'Trevi Fountain',
          'Pigeon Rocks',
          'Castillo San Cristobal',
          'Monteverde Cloud Forest',
        ],
        uniqueId: 'Jlgcp5qR',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kabul',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/72ccNLMJ-sU',
          wikiLink: 'https://en.wikipedia.org/wiki/Afghanistan',
        },
        landmark: {
          name: 'Band-e Amir National Park',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/P28_NVphFFU',
          wikiLink: 'https://en.wikipedia.org/wiki/Band-e_Amir_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Mazar-i-Sharif',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mazar-i-Sharif#/media/File:Mazar_sharif_street.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mazar-i-Sharif',
        },
        landmarksRound: [
          { code: 'BD', number: 1 },
          { code: 'BO', number: 1 },
          { code: 'CD', number: 1 },
        ],
        landmarkPlaces: [
          'Narikala Fortress',
          'Olavinlinna',
          'Christmas Island National Park',
          'Great Blue Hole',
          "Diocletian's Palace",
          'Valle de Cocora',
          'California Lighthouse',
        ],
        uniqueId: 'w3q62s9l',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Afghanistan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8A7M2u4G4d0',
          wikiLink: 'https://en.wikipedia.org/wiki/Afghanistan',
        },
        landmark: {
          name: 'Qargha Reservoir',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/C_50ln67RWY',
          wikiLink: 'https://en.wikipedia.org/wiki/Qargha_Reservoir',
          hasLandmark: true,
        },
        city: {
          name: 'Kabul',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9UZ8sOnrnrs',
          wikiLink: 'https://en.wikipedia.org/wiki/Kabul',
        },
        landmarksRound: [
          { code: 'BB', number: 1 },
          { code: 'CL', number: 1 },
          { code: 'CR', number: 1 },
        ],
        landmarkPlaces: [
          'Salomons Atoll',
          'Gravensteen',
          'One Foot Island',
          'La Moneda Palace',
          'Cameroon Reunification',
          'Valle de Cocora',
          'Notre Dame of Bangui Cathedral',
        ],
        uniqueId: 'ys87T7hg',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Qargha',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-surrounded-by-mountains-lSalVcf9-IU',
          wikiLink: 'https://en.wikipedia.org/wiki/Qargha',
        },
        landmark: {
          name: 'Herat Citadel',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/pthread/4072198579/',
          wikiLink: 'https://en.wikipedia.org/wiki/Herat_Citadel',
          hasLandmark: true,
        },
        city: {
          name: 'Kabul',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-mountains-in-the-background-VkwhhTLyhi0',
          wikiLink: 'https://en.wikipedia.org/wiki/Kabul',
        },
        landmarksRound: [
          { code: 'PF', number: 2 },
          { code: 'LI', number: 3 },
          { code: 'KZ', number: 3 },
        ],
        landmarkPlaces: [
          'Ban Gioc–Detian Falls',
          "Fa'aruma'i Waterfalls",
          'Morne Seychellois',
          'Arahoho Blowhole',
          'Baiterek',
          'Charyn Canyon',
          'Matobo National Park',
        ],
        uniqueId: 'glxMlzfp',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bamyan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-mountains-are-covered-with-snow-in-the-desert-TLKCx6mVIHo',
          wikiLink: 'https://en.wikipedia.org/wiki/Bamyan',
        },
        landmark: {
          name: 'Khost Mosque',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/khost/1569050810/in/photolist-8cyTLT-8NjyXS-3oDN9b-7b3nnA-7aYy34-7aYy5x-ai4j9f-7aamse-3MdihV-8XZvpR-6tpyfM-9szFML-He2UNp',
          wikiLink: 'https://en.wikipedia.org/wiki/Khost_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Herat',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/pthread/4072193513/',
          wikiLink: 'https://en.wikipedia.org/wiki/Herat',
        },
        landmarksRound: [
          { code: 'YT', number: 1 },
          { code: 'AI', number: 3 },
          { code: 'CX', number: 4 },
        ],
        landmarkPlaces: [
          'CN Tower',
          'Uluru',
          'Omar Ali Saifuddien Mosque',
          'Palace of Peace and Reconciliation',
          'Detwah Lagoon',
          'Virgin of El Panecillo',
          'Kpatawee Waterfall',
        ],
        uniqueId: 'zttLwbpy',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Aland Islands',
    code: 'AX',
    capital: 'Mariehamn',
    lat: 60.116667,
    long: 19.9,
    cities: [
      'Vårdö',
      'Eckerö',
      'Sund',
      'Lemland',
      'Saltvik',
      'Finström',
      'Mariehamn',
      'Jomala',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Kumlinge,_%C3%85land_Islands_-_panoramio_%289%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kumlinge',
        },
        landmark: {
          name: 'Kastelholm Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kastelholm_Castle#/media/File:KasterholmsCastel.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kastelholm_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Mariehamn',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mariehamn#/media/File:Mariehamn_harbor_5.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mariehamn',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'l3VCCR3D',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Sea_landscape,_%C3%85land.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land',
        },
        landmark: {
          name: 'Musemship Pommern',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Pommern,_%C3%85land_Maritime_Museum,_2019_%2801%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Museum_ship',
          hasLandmark: false,
        },
        city: {
          name: 'Sund',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sund,_%C3%85land#/media/File:Haraldsby,_%C3%85land.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sund,_%C3%85land',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '2kYHbxyZ',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Aland Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/FabtG4enGZQ',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land',
        },
        landmark: {
          name: 'Kastelholm Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kastelholm_Castle#/media/File:Kastelholm2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kastelholm_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Vårdö',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/V%C3%A5rd%C3%B6#/media/File:V%C3%A5rd%C3%B6_church_and_cemetry.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/V%C3%A5rd%C3%B6',
        },
        landmarksRound: [
          { code: 'IO', number: 2 },
          { code: 'CF', number: 2 },
          { code: 'CL', number: 2 },
        ],
        landmarkPlaces: [
          'Charles Bridge',
          'Chimi Lhakhang',
          'Cameroon Reunification',
          'Oriental Pearl TV Tower',
          'Queen Emma Bridge',
          'Natural Pool',
          'Pedro St. James Castle',
        ],
        uniqueId: 'wJclhV6T',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Aland Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/UVboVXvk3Yc',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land',
        },
        landmark: {
          name: 'Museum ship Pommern',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Pommern_(ship,_1903)#/media/File:Pommern3.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pommern_(ship)',
          hasLandmark: true,
        },
        city: {
          name: 'Eckerö',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ecker%C3%B6#/media/File:18-08-26-%C3%85land_RRK6836.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ecker%C3%B6',
        },
        landmarksRound: [
          { code: 'BI', number: 1 },
          { code: 'CK', number: 1 },
          { code: 'CF', number: 1 },
        ],
        landmarkPlaces: [
          'Christ the Redeemer',
          'Easter Island',
          'Amber Palace',
          'Grandvalira',
          'Timgad',
          'Sao Filipe Royale Fortress',
          'Basilica del Voto Nacional',
        ],
        uniqueId: 'HdcTFntw',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Aland Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-surrounded-by-trees-M-mupIfaVbU',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land',
        },
        landmark: {
          name: 'Kastelholm Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/maria_rok/42934043445/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kastelholm_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Mariehamn',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/45859117@N07/49723730523/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mariehamn',
        },
        landmarksRound: [
          { code: 'KZ', number: 3 },
          { code: 'MH', number: 4 },
          { code: 'EC', number: 3 },
        ],
        landmarkPlaces: [
          'Red Square',
          'Milwane Wildlife Sanctuary',
          'Day Forest National Park',
          'The Blue Eye',
          'Cameroon Reunification',
          'Independence Monument',
          'Valle de Cocora',
        ],
        uniqueId: '8cMLxh4s',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Aland Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-red-building-with-a-flag-on-top-of-it-faVHb5ezd8g',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land',
        },
        landmark: {
          name: 'Åland Maritime Museum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/tlindfors/17124961887/',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%85land_Maritime_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Jomala',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/jerryhendricks/52220772838/in/photostream/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jomala',
        },
        landmarksRound: [
          { code: 'VG', number: 3 },
          { code: 'CU', number: 3 },
          { code: 'BW', number: 3 },
        ],
        landmarkPlaces: [
          'Lake Lalolalo',
          'Tjibaou Cultural Center',
          'Seven Colored Earths',
          'Mount Wilhelm',
          'Liwonde National Park',
          'Amboseli National Park, Kenya',
          'Rundetaarn',
        ],
        uniqueId: '2T3Zv3MF',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Albania',
    code: 'AL',
    capital: 'Tirana',
    lat: 41,
    long: 20,
    cities: [
      'Durres',
      'Elbasan',
      'Tirana',
      'Berat',
      'Korçë',
      'Shkodër',
      'Gjirokastra',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/mUATQHolSMg',
          wikiLink: 'https://en.wikipedia.org/wiki/Rozafa_Castle',
        },
        landmark: {
          name: 'The Blue Eye',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Blue_Eye,_Albania,_2017_01.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Blue_Eye,_Albania',
          hasLandmark: true,
        },
        city: {
          name: 'Berat',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/berat-albania-balkan-mangalem-old-3707970/',
          wikiLink: 'https://en.wikipedia.org/wiki/Berat',
        },
        landmarksRound: [
          { code: 'BR', number: 1 },
          { code: 'TH', number: 2 },
          { code: 'MG', number: 1 },
        ],
        landmarkPlaces: [
          'Monastery of St. Naum',
          'Galle Fort',
          'Machu Picchu',
          'South Pole (ceremonial)',
          'Tegucigalpa Cathedral',
          'Vianden Castle',
          'Fort Christian',
        ],
        uniqueId: 'rWjwfjvt',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cruise-cruise-ship-sea-water-3703999/',
          wikiLink: 'https://en.wikipedia.org/wiki/Albania',
        },
        landmark: {
          name: 'Berat Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Berat_Castle#/media/File:Berat_-_Festung_2a_Haupttor.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Berat_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Durres',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-sunset-albania-city-lights-6577142/',
          wikiLink: 'https://en.wikipedia.org/wiki/Durr%C3%ABs',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Pzz8k7k4',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Tirana',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-TUYQ8vF0Ks',
          wikiLink: 'https://en.wikipedia.org/wiki/Tirana',
        },
        landmark: {
          name: 'Muzeu Historik Kombëtar',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/SwnU0BlZiBA',
          wikiLink:
            'https://sq.wikipedia.org/wiki/Muzeu_Historik_Komb%C3%ABtar',
          hasLandmark: true,
        },
        city: {
          name: 'Korçë',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Rl7Cx961X-M',
          wikiLink: 'https://en.wikipedia.org/wiki/Kor%C3%A7%C3%AB',
        },
        landmarksRound: [
          { code: 'DZ', number: 1 },
          { code: 'CL', number: 2 },
          { code: 'CU', number: 2 },
        ],
        landmarkPlaces: [
          'Noravank Monastery',
          'Great Blue Hole',
          'St. Alexander Nevsky Cathedral',
          'Hazrat Ali Mazar',
          'Tsodilo Hills',
          'Deception Island',
          'Buenos Aires Obelisk',
        ],
        uniqueId: 'ZWKjh2dy',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Albania',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/2lR4FexYPvA',
          wikiLink: 'https://en.wikipedia.org/wiki/Albania',
        },
        landmark: {
          name: 'Lake Koman',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/0hSua1F0-P0',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Koman',
          hasLandmark: true,
        },
        city: {
          name: 'Shkodër',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/16XPtTvxg10',
          wikiLink: 'https://en.wikipedia.org/wiki/Shkod%C3%ABr',
        },
        landmarksRound: [
          { code: 'BW', number: 1 },
          { code: 'AX', number: 4 },
          { code: 'VG', number: 1 },
        ],
        landmarkPlaces: [
          'Grandvalira',
          'Great Blue Hole',
          'Porte du Non Retour',
          'Chimi Lhakhang',
          'Pulu Keeling National Park',
          'Christ of Havana',
          'English Harbour',
        ],
        uniqueId: 'xH3jxjtB',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Shkodër',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/river-between-green-trees-and-mountains-under-blue-sky-and-white-clouds-during-daytime-65vi3vAzAAY',
          wikiLink: 'https://en.wikipedia.org/wiki/Shkod%C3%ABr',
        },
        landmark: {
          name: 'Pyramid of Tirana',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rayordanov/26955076754/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pyramid_of_Tirana',
          hasLandmark: true,
        },
        city: {
          name: 'Gjirokastra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-mountains-in-the-background-xPSJoNviexI',
          wikiLink: 'https://en.wikipedia.org/wiki/Gjirokast%C3%ABr',
        },
        landmarksRound: [
          { code: 'BJ', number: 1 },
          { code: 'TC', number: 4 },
          { code: 'MW', number: 1 },
        ],
        landmarkPlaces: [
          'Ulu Temburong National Park',
          "People's Palace",
          'Tafawa Balewa Square',
          'Mausoleum of Khoja Ahmed Yasawi',
          'Gediminas Castle Tower',
          'Chronicle of Georgia',
          "N'Djamena Grand Mosque",
        ],
        uniqueId: 'lsGlXzXy',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Sarandë',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-next-to-a-body-of-water-JrnMqKyyoHw',
          wikiLink: 'https://en.wikipedia.org/wiki/Sarand%C3%AB',
        },
        landmark: {
          name: "Et'hem Bey Mosque",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/atilla2008/4190020626/',
          wikiLink: 'https://en.wikipedia.org/wiki/Et%27hem_Bey_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Tirana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-people-walking-down-a-street-next-to-tall-buildings--fb95rZXvU8',
          wikiLink: 'https://en.wikipedia.org/wiki/Tirana',
        },
        landmarksRound: [
          { code: 'AU', number: 2 },
          { code: 'PY', number: 1 },
          { code: 'MW', number: 3 },
        ],
        landmarkPlaces: [
          'Chamarel Waterfall',
          'Fort Napoléon des Saintes',
          "Lac 'Assal",
          'National Pantheon',
          'Hiroshima Peace Memorial',
          'Pierre Savorgnan de Brazza Memorial',
          'Rabban Hormizd Monastery',
        ],
        uniqueId: 'vdbLwGPC',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Algeria',
    code: 'DZ',
    capital: 'Algiers',
    lat: 28,
    long: 3,
    cities: [
      'Constantine',
      'Oran',
      'Setif',
      'Annaba',
      'Batna',
      'Béjaïa',
      'Algiers',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-sOS_rLimpQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Constantine,_Algeria',
        },
        landmark: {
          name: 'Timgad',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/timgad-batna-algeria-3079403/',
          wikiLink: 'https://en.wikipedia.org/wiki/Timgad',
          hasLandmark: true,
        },
        city: {
          name: 'Constantine',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bridge-elevator-constantine-algeria-3583955/',
          wikiLink: 'https://en.wikipedia.org/wiki/Constantine,_Algeria',
        },
        landmarksRound: [
          { code: 'GQ', number: 1 },
          { code: 'KP', number: 1 },
          { code: 'DE', number: 1 },
        ],
        landmarkPlaces: [
          'Kittiwake Shipwreck',
          'Gyeongbukgong Palace',
          'Soroca Fort',
          '',
          'Big Ben',
          'Monteverde Cloud Forest',
          'Arahoho Blowhole',
        ],
        uniqueId: 'MzkLbQXq',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/algeria-hoggar-assekrem-volcano-673777/',
          wikiLink: 'https://en.wikipedia.org/wiki/Algeria',
        },
        landmark: {
          name: "Notre-Dame d'Afrique",
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Notre-Dame_d%27Afrique#/media/File:Notre_Dame_d'Afrique.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Notre-Dame_d%27Afrique',
          hasLandmark: true,
        },
        city: {
          name: 'Oran',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/architecture-travel-sky-city-3200537/',
          wikiLink: 'https://en.wikipedia.org/wiki/Oran',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'l5RhgCB6',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Algeria',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/dyuN7GgEyB8',
          wikiLink: 'https://simple.wikipedia.org/wiki/Algeria',
        },
        landmark: {
          name: "Tassili n'Ajjer",
          photographer: '',
          imageLink: 'https://unsplash.com/photos/X5BZugJDmYw',
          wikiLink: 'https://simple.wikipedia.org/wiki/Tassili_n%27Ajjer',
          hasLandmark: true,
        },
        city: {
          name: 'Béjaïa',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8Bw3FnqPmp8',
          wikiLink: 'https://simple.wikipedia.org/wiki/B%C3%A9ja%C3%AFa',
        },
        landmarksRound: [
          { code: 'BI', number: 2 },
          { code: 'BE', number: 2 },
          { code: 'BO', number: 2 },
        ],
        landmarkPlaces: [
          "Devil's Bridge",
          'Qargha Reservoir',
          'Sydney Opera House',
          'Unfinished Church',
          'Ouagadougou Cathedral',
          'Arenal Volcano',
          'Herat Citadel',
        ],
        uniqueId: 'hzwDpVRM',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Constantine',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/sZcGzjl9moU',
          wikiLink: 'https://simple.wikipedia.org/wiki/Constantine,_Algeria',
        },
        landmark: {
          name: 'Roman ruins in Batna',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/m1HBvp3Cl-E',
          wikiLink: 'https://simple.wikipedia.org/wiki/Batna_Province',
          hasLandmark: true,
        },
        city: {
          name: 'Oran',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ZBSMnivFVrY',
          wikiLink: 'https://simple.wikipedia.org/wiki/Oran',
        },
        landmarksRound: [
          { code: 'AS', number: 2 },
          { code: 'BJ', number: 2 },
          { code: 'BA', number: 2 },
        ],
        landmarkPlaces: [
          'Unfinished Church',
          'Zaisan Memorial',
          'Sukhbaatar Square',
          'Bellapais Abbey',
          'Christmas Island National Park',
          'Great Blue Hole',
          'Hofburg Palace',
        ],
        uniqueId: 'n76VC3Ky',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Bordj Bou Arreridj',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-building-near-green-trees-during-daytime-QEupqcxTmY0',
          wikiLink: 'https://en.wikipedia.org/wiki/Bordj_Bou_Arr%C3%A9ridj',
        },
        landmark: {
          name: "Notre-Dame d'Afrique",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/berview/49248093407/',
          wikiLink: 'https://en.wikipedia.org/wiki/Notre-Dame_d%27Afrique',
          hasLandmark: true,
        },
        city: {
          name: 'Constantine',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bridge-over-a-river-with-buildings-on-either-side-of-it-fvqCkzsWv-4',
          wikiLink: 'https://en.wikipedia.org/wiki/Constantine,_Algeria',
        },
        landmarksRound: [
          { code: 'BG', number: 3 },
          { code: 'BN', number: 3 },
          { code: 'CR', number: 2 },
        ],
        landmarkPlaces: [
          'Krka National Park',
          'Loango National Park',
          'Old Panama',
          'South Pole',
          'Henderson Island',
          'Cathedral Our Lady of the Rosary',
          'Abu Simbel Temples',
        ],
        uniqueId: 'F3s9gNKQ',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Seraïdi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-white-buildings-with-blue-windows-neecKxRZLs8',
          wikiLink: 'https://en.wikipedia.org/wiki/Sera%C3%AFdi',
        },
        landmark: {
          name: 'Maqam Echahid',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tall-building-with-a-tower-ESkI6T_Ef5A',
          wikiLink: 'https://en.wikipedia.org/wiki/Maqam_Echahid',
          hasLandmark: true,
        },
        city: {
          name: 'Annaba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-mountains-in-the-background-k9i2BhVniqw',
          wikiLink: 'https://en.wikipedia.org/wiki/Annaba',
        },
        landmarksRound: [
          { code: 'VI', number: 4 },
          { code: 'BE', number: 1 },
          { code: 'LY', number: 2 },
        ],
        landmarkPlaces: [
          'Fouta Djallon',
          'Palace of Peace and Reconciliation',
          'Lope National Park',
          'Regina Mundi Cathedral',
          'Floralis Generica',
          'Lychakiv Cemetery',
          'Muzeu Historik Kombëtar',
        ],
        uniqueId: 'x4FLxJWw',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'American Samoa',
    code: 'AS',
    capital: 'Pago Pago',
    lat: -14.33333333,
    long: -170,
    cities: ['Pago Pago', 'Fagatogo', 'Tafuna', 'Alofau', 'Leone', 'Faleniu'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:American_Samoa_tramway_remains.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/American_Samoa',
        },
        landmark: {
          name: 'Alega Beach',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Alega#/media/File:Beach_near_Tisa's_Barefoot_Bar_-_panoramio.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Alega',
          hasLandmark: true,
        },
        city: {
          name: 'Pago Pago',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Pago_Pago#/media/File:Pagopago_amerika_s%C4%81moa.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pago_Pago',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'jF2W39pz',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Vatia_from_the_National_Park_of_American_Samoa.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_Park_of_American_Samoa',
        },
        landmark: {
          name: 'Mount Alava',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Mount_%CA%BBAlava#/media/File:Mount_'Alava.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_%CA%BBAlava',
          hasLandmark: true,
        },
        city: {
          name: 'Tafuna',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Pago_Pago_International_Airport_from_A%27oloau.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tafuna,_American_Samoa',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'v5LHMr3l',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'American Samoa',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:American_Samoa#/media/File:American_Samoa_tramway_remains.jpg',
          wikiLink: 'https://simple.wikipedia.org/wiki/American_Samoa',
        },
        landmark: {
          name: 'National Park of American Samoa',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:American_Samoa#/media/File:Vatia_from_the_National_Park_of_American_Samoa.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_Park_of_American_Samoa',
          hasLandmark: true,
        },
        city: {
          name: 'Pago Pago',
          photographer: '',
          imageLink:
            'https://flickr.com/photos/60348236@N07/52994194888/in/photolist-2oJV6Rj-eRLqC-2nrJhQG-2og2MfN-2mbo6fn-2maTw1d-2jdv2po-2nCHnwA-nKs9eJ-2nnNuzK-26EE1jj-nv1cDu-nhftrV-LARrU2-2iFUqU4-nPhbDg-2iSEpbe-cr6J9L-edGY54-2ij2wff-2oLqpiu-jLZQco-2oA8vKE-eRHtp-RY3dag-8WNYe7-s9ntWk-n1FaRa-dy2akF-2nZGTia-8WuJsz-n1FTrK-2oJzXe3-n1Fup2-2PJVyA-2PJVoG-n1FWEP-n1HCo5-2mkzMLf-Lye2sG-n1FzqK-8WNF2u-8WLvZv-n1FkMT-8WL3sx-n1FyLR-tPv24n-2PErW4-8WKzAD-n1FqGn',
          wikiLink: 'https://simple.wikipedia.org/wiki/Pago_Pago',
        },
        landmarksRound: [
          { code: 'BT', number: 1 },
          { code: 'AQ', number: 1 },
          { code: 'AL', number: 1 },
        ],
        landmarkPlaces: [
          'English Harbour',
          'Xunatunich Mayan Ruins',
          'Floralis Generica',
          'White Bay Bar',
          'Salomons Atoll',
          'Boali',
          'Plaza Murillo',
        ],
        uniqueId: 'JwNkj4R8',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'American Samoa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/5214165316',
          wikiLink: 'https://simple.wikipedia.org/wiki/American_Samoa',
        },
        landmark: {
          name: 'Matafao Peak',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/bychu/8746666397',
          wikiLink: 'https://en.wikipedia.org/wiki/Matafao_Peak',
          hasLandmark: true,
        },
        city: {
          name: 'Fagatogo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fagatogo#/media/File:Line5318_-_Flickr_-_NOAA_Photo_Library.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fagatogo',
        },
        landmarksRound: [
          { code: 'BI', number: 2 },
          { code: 'CL', number: 2 },
          { code: 'FI', number: 1 },
        ],
        landmarkPlaces: [
          'Kravica Waterfall',
          "Diocletian's Palace",
          'Ouagadougou Cathedral',
          'Arenal Volcano',
          'Herat Citadel',
          'Kastelholm Castle',
          'Bahrain Fort',
        ],
        uniqueId: '86GvQDwM',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'American Samoa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/roger_pringle/6131448556/',
          wikiLink: 'https://simple.wikipedia.org/wiki/American_Samoa',
        },
        landmark: {
          name: 'National Park of American Samoa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/60348236@N07/52972842229/',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_Park_of_American_Samoa',
          hasLandmark: true,
        },
        city: {
          name: 'Pago Pago',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/33535417@N05/13791272655/',
          wikiLink: 'https://simple.wikipedia.org/wiki/Pago_Pago',
        },
        landmarksRound: [
          { code: 'GU', number: 1 },
          { code: 'MG', number: 3 },
          { code: 'EG', number: 2 },
        ],
        landmarkPlaces: [
          'Point Venus',
          'Sagrada Família',
          'Cameroon Reunification',
          'Dubai Frame',
          'Sibebe Rock',
          'Chomoni Beach',
          'Vaduz Castle',
        ],
        uniqueId: 'xDSxtdr3',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'American Samoa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/benmiller23/2296800057/',
          wikiLink: 'https://simple.wikipedia.org/wiki/American_Samoa',
        },
        landmark: {
          name: 'Matafao Peak',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/55575164@N05/44877421382/',
          wikiLink: 'https://en.wikipedia.org/wiki/Matafao_Peak',
          hasLandmark: true,
        },
        city: {
          name: 'Fagatogo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/larrymyhre/36375035023/',
          wikiLink: 'https://simple.wikipedia.org/wiki/Fagatogo',
        },
        landmarksRound: [
          { code: 'WF', number: 2 },
          { code: 'AI', number: 2 },
          { code: 'LT', number: 4 },
        ],
        landmarkPlaces: [
          'Funafala',
          'Columbus Park',
          'Sultan Abdul Samad Building',
          'Durrell Wildlife Park',
          'Abu Simbel Temples',
          'Oceanographic Museum of Monaco',
          'Cane Garden Bay',
        ],
        uniqueId: 'LYh6JRMQ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Andorra',
    code: 'AD',
    capital: 'Andorra la Vella',
    lat: 42.5,
    long: 1.5,
    cities: [
      'Andorra la Vella',
      'Encamp',
      'Escaldes-Engordany',
      'Ordino',
      'Canillo',
      'El Pas de la Casa',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/3gYKw3uAOwU',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra_la_Vella',
        },
        landmark: {
          name: 'Grandvalira',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Grandvalira_ski_resort,_Andorra4.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Soldeu',
          hasLandmark: true,
        },
        city: {
          name: 'Encamp',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Encamp#/media/File:Vista_d'Encamp_-_Andorra.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Encamp',
        },
        landmarksRound: [
          { code: 'BB', number: 2 },
          { code: 'ET', number: 1 },
          { code: 'TH', number: 1 },
        ],
        landmarkPlaces: [
          'Cathedral of Brasilica',
          'Chinguetti',
          'Marina Bay Sands',
          'George Washington House',
          'Panfilov Park',
          'Christ of Havana',
          'Heydar Aliyev Center',
        ],
        uniqueId: 'm88gdlQg',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/andorra-pyrenees-mountains-3501173/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pyrenees',
        },
        landmark: {
          name: 'La Noblesse Du Temps',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:%D0%A1%D0%BA%D1%83%D0%BB%D1%8C%D0%BF%D1%82%D1%83%D1%80%D0%B0_%D0%A1%D0%B0%D0%BB%D1%8C%D0%B2%D0%B0%D0%B4%D0%BE%D1%80%D0%B0_%D0%94%D0%B0%D0%BB%D0%B8_%C2%AB%D0%91%D0%BB%D0%B0%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE_%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8%C2%BB_-_panoramio.jpg',
          wikiLink:
            'https://www.inyourpocket.com/andorra-la-vella/la-noblesse-du-temps_160690v',
          hasLandmark: false,
        },
        city: {
          name: 'Andorra la Vella',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/la-vella-andorra-mountains-pyrenees-2196560/',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra_la_Vella',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'PW2hhzTQ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Andorra',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/P2LtiQlU2Vg',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra',
        },
        landmark: {
          name: 'Madriu-Perafita-Claror Valley',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vI-WMpaSFoA',
          wikiLink:
            'https://en.wikipedia.org/wiki/Madriu-Perafita-Claror_Valley',
          hasLandmark: true,
        },
        city: {
          name: 'Canillo',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/kcKJodlQSxg',
          wikiLink: 'https://en.wikipedia.org/wiki/Canillo',
        },
        landmarksRound: [
          { code: 'AS', number: 1 },
          { code: 'BY', number: 2 },
          { code: 'DZ', number: 4 },
        ],
        landmarkPlaces: [
          'Noravank Monastery',
          'Turtle Cove',
          'Castillo De Los Tres Reyes Del Morro',
          'Kittiwake Shipwreck',
          'Sukhbaatar Square',
          "Notre-Dame d'Afrique",
          'Basilica del Voto Nacional',
        ],
        uniqueId: 'RfsWfnmP',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Andorra',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Xrcx2VINjP8',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra',
        },
        landmark: {
          name: 'Casa de la Vall',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Casa_de_la_Vall#/media/File:Casa_de_la_Vall_2015-10.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Casa_de_la_Vall',
          hasLandmark: true,
        },
        city: {
          name: 'El Pas de la Casa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/El_Pas_de_la_Casa#/media/File:Pas_de_la_case.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Pas_de_la_Casa',
        },
        landmarksRound: [
          { code: 'BW', number: 2 },
          { code: 'EC', number: 1 },
          { code: 'AQ', number: 2 },
        ],
        landmarkPlaces: [
          'National Park of American Samoa',
          'Qargha Reservoir',
          'Turku Castle',
          'Xunatunich Mayan Ruins',
          'Pula Arena',
          'One Foot Island',
          'Pedro St. James Castle',
        ],
        uniqueId: '8fCXTHVG',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Andorra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-mountains-covered-in-snow-under-a-cloudy-sky-TrJthE2ExsU',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra',
        },
        landmark: {
          name: 'Mirador Roc Del Quer | Canillo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-mountains-during-daytime-rEr4mjCtj88',
          wikiLink: 'https://ca.wikipedia.org/wiki/Mirador_del_Roc_del_Quer',
          hasLandmark: true,
        },
        city: {
          name: 'Andorra la Vella',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-near-buildings-during-night-time-JFWi_xJJxM8',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra_la_Vella',
        },
        landmarksRound: [
          { code: 'PL', number: 1 },
          { code: 'HM', number: 1 },
          { code: 'SI', number: 4 },
        ],
        landmarkPlaces: [
          'Rabban Hormizd Monastery',
          'Brandenburg Gate',
          'Quadiriki Caves',
          'Buenos Aires Obelisk',
          'Boudhanath',
          'English Harbour',
          'Ostrog Monastery',
        ],
        uniqueId: 'pyh68HTz',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Andorra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-winding-road-in-the-mountains-eFWINfwiZes',
          wikiLink: 'https://en.wikipedia.org/wiki/Andorra',
        },
        landmark: {
          name: 'Sant Joan de Caselles',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/66451146@N08/52588335545/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sant_Joan_de_Caselles',
          hasLandmark: true,
        },
        city: {
          name: 'Escaldes-Engordany',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-in-the-mountains-FmEnFQ5APLQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Escaldes%E2%80%93Engordany',
        },
        landmarksRound: [
          { code: 'AS', number: 4 },
          { code: 'TZ', number: 2 },
          { code: 'SK', number: 1 },
        ],
        landmarkPlaces: [
          'Belau National Museum',
          'Cathédrale Notre-Dame',
          'Sagrada Família',
          'Pedro St. James Castle',
          'Jardin de Balata',
          "Ruins of St. Paul's",
          'Palacio Salvo',
        ],
        uniqueId: 'MHvVVxSS',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Angola',
    code: 'AO',
    capital: 'Luanda',
    lat: -12.5,
    long: 18.5,
    cities: [
      'Lubango',
      'Luanda',
      'Huambo',
      'Benguela',
      'Kuito',
      'Lobito',
      'Muxima',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Tri_u7qeqGw',
          wikiLink: 'https://en.wikipedia.org/wiki/Muxima',
        },
        landmark: {
          name: 'Fortress of São Miguel',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Luanda-SMiguelFort1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fortress_of_S%C3%A3o_Miguel',
          hasLandmark: true,
        },
        city: {
          name: 'Luanda',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/angola-luanda-africa-5139571/',
          wikiLink: 'https://en.wikipedia.org/wiki/Luanda',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '3QTSt3Wn',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/angola-landscape-sierra-da-leba-509598/',
          wikiLink: 'https://en.wikipedia.org/wiki/Angola',
        },
        landmark: {
          name: 'Kalandula Falls',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kalandula_Falls#/media/File:Kalandula_waterfalls_of_the_Lucala-River_in_Malange,_Angola.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Kalandula_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Lubango',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Lubango_sight_%28cropped%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lubango',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'pXbcQsBB',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Angola',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/TKsDclZ3i4o',
          wikiLink: 'https://en.wikipedia.org/wiki/Angola',
        },
        landmark: {
          name: 'Viewpoint of the Moon',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/aLXA3hpX35Y',
          wikiLink: 'https://pt.wikipedia.org/wiki/Miradouro_da_Lua',
          hasLandmark: true,
        },
        city: {
          name: 'Lubango',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/aerial-view-of-city-buildings-4548010/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lubango',
        },
        landmarksRound: [
          { code: 'CA', number: 1 },
          { code: 'AD', number: 3 },
          { code: 'CR', number: 2 },
        ],
        landmarkPlaces: [
          "St. Stephen's Cathedral",
          'Porte du Non Retour',
          'Buenos Aires Obelisk',
          'Cameroon Reunification',
          'Museum ship Pommern',
          'Oceania House',
          'Christiansborg Palace',
        ],
        uniqueId: 'cqM3YWj5',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Huíla Province',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/aerial-view-of-rocky-mountain-5745888/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hu%C3%ADla_Province',
        },
        landmark: {
          name: 'National Museum of Slavery',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Museu_da_Escravatura_Luanda#/media/File:Museu_da_Escravatura_Luanda.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/National_Museum_of_Slavery',
          hasLandmark: true,
        },
        city: {
          name: 'Luanda',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/aerial-photo-of-buildings-near-coast-2767923/',
          wikiLink: 'https://en.wikipedia.org/wiki/Luanda',
        },
        landmarksRound: [
          { code: 'AD', number: 3 },
          { code: 'CZ', number: 2 },
          { code: 'BF', number: 2 },
        ],
        landmarkPlaces: [
          'Kittiwake Shipwreck',
          'Rila Monastery',
          'Maiden Tower',
          'Timgad',
          'Qargha Reservoir',
          'Okavango Delta',
          'Kittiwake Shipwreck',
        ],
        uniqueId: 'gdfLK52M',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Luanda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-high-rise-building-glhVDncQmhE',
          wikiLink: 'https://en.wikipedia.org/wiki/Luanda',
        },
        landmark: {
          name: 'Kalandula Falls',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/wjzhen/435505026/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kalandula_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Luanda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-empty-street-in-a-city-with-tall-buildings-1C88rLU4nkI',
          wikiLink: 'https://en.wikipedia.org/wiki/Luanda',
        },
        landmarksRound: [
          { code: 'LI', number: 4 },
          { code: 'SC', number: 1 },
          { code: 'VN', number: 4 },
        ],
        landmarkPlaces: [
          'Vaduz Cathedral',
          'Temple of Literature',
          'Qargha Reservoir',
          'Herat Citadel',
          "Fa'aruma'i Waterfalls",
          'Castle Vaduz',
          'Blue Eye Cave',
        ],
        uniqueId: 'HKNCv8FW',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Benfica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-people-on-shore-EkI95WwwMjo',
          wikiLink: 'https://en.wikipedia.org/wiki/Benfica,_Luanda',
        },
        landmark: {
          name: 'Tundavala Gap',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/tsiklonaut/7083434533/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tundavala_Gap',
          hasLandmark: true,
        },
        city: {
          name: 'Muxima',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-houses-near-body-of-water-Tri_u7qeqGw',
          wikiLink: 'https://en.wikipedia.org/wiki/Muxima',
        },
        landmarksRound: [
          { code: 'TZ', number: 2 },
          { code: 'DK', number: 3 },
          { code: 'SS', number: 2 },
        ],
        landmarkPlaces: [
          'Rock Islands',
          'Mlilwane Wildlife Sanctuary',
          'Peel Castle',
          'Angel Falls',
          'Omar Ali Saifuddien Mosque',
          'Great Mosque of Niamey',
          'Leshan Giant Buddha',
        ],
        uniqueId: '69fglDKb',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Anguilla',
    code: 'AI',
    capital: 'The Valley',
    lat: 18.25,
    long: -63.16666666,
    cities: [
      'Blowing Point',
      'The Valley',
      'Sandy Ground Village',
      'The Farrington',
      'The Quarter',
      'Island Harbour',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/mmGdZmFvnrE',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
        landmark: {
          name: 'Shoal Bay',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Anguilla_Shoal_Bay_is_the_BEST_beach_in_the_Caribbean._-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Geography_of_Anguilla',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'WstYksH8',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bay-beach-jetty-boat-anguilla-7233153/',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
        landmark: {
          name: 'Little Bay',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/aturkus/2246044292',
          wikiLink: 'https://en.wikipedia.org/wiki/Geography_of_Anguilla',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'yrRSZm4l',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Anguilla',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/qVlMhkyp9l8',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
        landmark: {
          name: 'Wallblake House',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Wallblake_House#/media/File:Wallblake_House,_The_Valley.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallblake_House',
          hasLandmark: true,
        },
        city: {
          name: 'Blowing Point',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Blowing_Point,_Anguilla#/media/File:Anguilla-aerial_view_western_portion.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Blowing_Point,_Anguilla',
        },
        landmarksRound: [
          { code: 'CL', number: 1 },
          { code: 'VG', number: 1 },
          { code: 'CO', number: 1 },
        ],
        landmarkPlaces: [
          'Monteverde Cloud Forest',
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Christiansborg Palace',
          'Narikala Fortress',
          'Notre Dame of Bangui Cathedral',
          "Diocletian's Palace",
          'Amalienborg',
        ],
        uniqueId: '2pdCgMsL',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Anguilla',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/DoSGBUN4A9k',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
        landmark: {
          name: 'Anguilla Arch',
          photographer: '',
          imageLink:
            'https://www.tripadvisor.co.nz/Attraction_Review-g671490-d19715513-Reviews-Anguilla_Arch-West_End_Village_Anguilla.html#/media-atf/19715513/693338438:p/?albumid=-160&type=0&category=-160',
          wikiLink: 'https://en.wikipedia.org/wiki/Geology_of_Anguilla',
          hasLandmark: true,
        },
        city: {
          name: 'The Valley',
          photographer: '',
          imageLink: 'https://www.paxgaea.com/images/The_Valley.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Valley,_Anguilla',
        },
        landmarksRound: [
          { code: 'AQ', number: 2 },
          { code: 'CC', number: 1 },
          { code: 'BG', number: 1 },
        ],
        landmarkPlaces: [
          'Xunatunich Mayan Ruins',
          'Church of Saint Lazarus',
          "Diocletian's Palace",
          'Maiden Tower',
          'Pulu Keeling National Park',
          'Cathedral of Brasilica',
          'Spittal Pond',
        ],
        uniqueId: 'lRCtZ9rG',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Anguilla',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-swimming-pool-with-a-view-of-the-ocean-XuI5512ta3g',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
        landmark: {
          name: 'St. Gerard Church',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/up70mm/15427797494/in/photolist-pu81X1-2iZ85af-ogjzDd-2jpiHPY-pviuGw',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Gerard_Church,_The_Valley',
          hasLandmark: true,
        },
        city: {
          name: 'The Valley',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/37063914@N04/16589886297/',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Valley,_Anguilla',
        },
        landmarksRound: [
          { code: 'PE', number: 2 },
          { code: 'PT', number: 2 },
          { code: 'NC', number: 1 },
        ],
        landmarkPlaces: [
          'Frogner Park',
          'Chamarel Waterfall',
          'Lake Yoa',
          'St. Peter the Apostle Cathedral',
          'Monte Carlo Casino',
          'Noravank Monastery',
          'Golden Bridge ',
        ],
        uniqueId: 'nZJcjHjk',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Anguilla',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-lawn-chairs-sitting-on-top-of-a-sandy-beach-rkl4Es1T9gI',
          wikiLink: 'https://en.wikipedia.org/wiki/Anguilla',
        },
        landmark: {
          name: 'Sandy Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/congochris/2209641875/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sandy_Island_(Anguilla)',
          hasLandmark: true,
        },
        city: {
          name: 'Blowing Point',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/davidcjones/36640474806/',
          wikiLink: 'https://en.wikipedia.org/wiki/Blowing_Point,_Anguilla',
        },
        landmarksRound: [
          { code: 'HN', number: 1 },
          { code: 'GQ', number: 4 },
          { code: 'BO', number: 3 },
        ],
        landmarkPlaces: [
          'Sultan Abdul Samad Building',
          'Tombs of the Kings (Paphos)',
          'Sibebe Rock',
          'Queen Emma Bridge',
          'Deadvlei',
          'Fortaleza de São José da Amura',
          'Serengeti',
        ],
        uniqueId: 'tHZft9pM',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Antarctica',
    code: 'AQ',
    capital: 'Antarctica',
    lat: -75.250973,
    long: -0.071389,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/LysuRGQjMwo',
          wikiLink: 'https://en.wikipedia.org/wiki/Antarctica',
        },
        landmark: {
          name: 'South Pole (ceremonial)',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/South_Pole#/media/File:Ceremonial_South_Pole.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Pole',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'MA', number: 1 },
          { code: 'BZ', number: 2 },
          { code: 'BE', number: 2 },
        ],
        landmarkPlaces: [
          'Animal Flower Cave',
          'Villa Folio',
          'Tikal National Park',
          'Machu Picchu',
          'Pamir Mountains',
          'Herat Citadel',
          'Szechenyi Thermal Bath',
        ],
        uniqueId: 'CjRyN2lF',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/iceberg-antarctica-polar-ice-sea-404966/',
          wikiLink: 'https://en.wikipedia.org/wiki/Antarctica',
        },
        landmark: {
          name: 'Deception Island',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:1_Deception_Island.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Deception_Island',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'IN', number: 2 },
          { code: 'AZ', number: 2 },
          { code: 'YE', number: 1 },
        ],
        landmarkPlaces: [
          'Sao Filipe Royale Fortress',
          'Lotto World',
          'Great Blue Hole',
          'Christiansborg Palace',
          'Aleppo Citadel',
          'Trevi Fountain',
          'Devin Castle',
        ],
        uniqueId: '3Jq3yzVB',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Antarctica',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NaQMJ-xNDWI',
          wikiLink: 'https://en.wikipedia.org/wiki/Antarctica',
        },
        landmark: {
          name: 'Elephant Island',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/YL5JVy9rL_E',
          wikiLink: 'https://en.wikipedia.org/wiki/Elephant_Island',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'DK', number: 1 },
          { code: 'CK', number: 2 },
          { code: 'AF', number: 3 },
        ],
        landmarkPlaces: [
          'Lake Koman',
          'Berat Castle',
          'Tsodilo Hills',
          'Chomoni Beach',
          'Okavango Delta',
          'Pulu Keeling National Park',
          'Band-e Amir National Park',
        ],
        uniqueId: 'dX55gPsF',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Antarctica',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/3Xd5j9-drDA',
          wikiLink: 'https://en.wikipedia.org/wiki/Antarctica',
        },
        landmark: {
          name: 'South Pole',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WWVD4wXRX38',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Pole',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'BA', number: 2 },
          { code: 'BI', number: 1 },
          { code: 'AI', number: 4 },
        ],
        landmarkPlaces: [
          'Turku Castle',
          'Fortress of São Miguel',
          'Oriental Pearl TV Tower',
          'Mir Castle',
          'White Bay Bar',
          'Amberd',
          'Silver Pagoda',
        ],
        uniqueId: 'cc6wNDnv',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Anvers Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-green-grass-field-during-daytime-GJQUr0SgKi0',
          wikiLink: 'https://en.wikipedia.org/wiki/Anvers_Island',
        },
        landmark: {
          name: 'Deception Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/peteread/37472168746/',
          wikiLink: 'https://en.wikipedia.org/wiki/Deception_Island',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'IM', number: 4 },
          { code: 'SV', number: 1 },
          { code: 'BN', number: 3 },
        ],
        landmarkPlaces: [
          "Dunn's River Falls",
          'Maluti Mountains',
          'Annaberg plantation',
          'Cheshire Hall Plantation',
          'Lake Niamawi',
          'Morne Seychellois',
          'Taputapuatea marae',
        ],
        uniqueId: 'lc3DjfxB',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Antarctica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-very-tall-tower-sitting-on-top-of-a-snow-covered-slope-4kOWpHOxCrQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Antarctica',
        },
        landmark: {
          name: 'Chapel of the Snows',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/alan_light/5909436317/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chapel_of_the_Snows',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'TJ', number: 1 },
          { code: 'GF', number: 2 },
          { code: 'ID', number: 3 },
        ],
        landmarkPlaces: [
          'Two Lovers Point',
          'Temple of Literature',
          'Red Square',
          'Pipeline Mosque',
          'Peel Castle',
          "Jacob's Ladder",
          'Little Bay',
        ],
        uniqueId: 'NppfC6SH',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    capital: "Saint John's",
    lat: 17.05,
    long: -61.8,
    cities: [
      "Saint John's",
      'Bolands',
      'All Saints',
      'Piggotts',
      'Swetes',
      'Codrington',
      'Saint John',
      'English Harbour',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9CJTrF-HnlU',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua_and_Barbuda',
        },
        landmark: {
          name: 'English Harbour',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Antigua_and_Barbuda,_English_Harbour_%2824%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/English_Harbour',
          hasLandmark: true,
        },
        city: {
          name: "Saint John's",
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/St._John%27s,_Antigua_and_Barbuda#/media/File:St_John's,_Antigua_and_Barbuda_-_panoramio_(11).jpg",
          wikiLink:
            'https://en.wikipedia.org/wiki/St._John%27s,_Antigua_and_Barbuda',
        },
        landmarksRound: [
          { code: 'TR', number: 1 },
          { code: 'SA', number: 2 },
          { code: 'BR', number: 1 },
        ],
        landmarkPlaces: [
          'Great Blue Hole',
          'Arc de Triomphe',
          'Alhambra',
          'Sky Tower',
          'Somapura Mahavihara',
          'Registan Square',
          'George Washington House',
        ],
        uniqueId: 'ghJqrqJN',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/devil-s-bridge-sea-barbuda-antigua-7155984/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Devil%27s_Bridge,_Antigua_and_Barbuda',
        },
        landmark: {
          name: "Devil's Bridge",
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Devils_Bridge,_Antigua.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Devil%27s_Bridge,_Antigua_and_Barbuda',
          hasLandmark: true,
        },
        city: {
          name: 'Bolands',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bolands#/media/File:Antigua_-_Bolands_%E2%80%93_Jennings_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolands',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'qwPlSz3b',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Antigua',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-4itEczXe3U',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua',
        },
        landmark: {
          name: 'Fort James',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Fort_James,_Antigua_and_Barbuda#/media/File:FORT_JAMES,_ST._JOHN'S,_ANTIGUA.jpg",
          wikiLink:
            'https://en.wikipedia.org/wiki/Fort_James,_Antigua_and_Barbuda',
          hasLandmark: true,
        },
        city: {
          name: "Saint John's",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/St._John%27s,_Saint_John#/media/File:St_Johns_Antigua_2012.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/St._John%27s,_Saint_John',
        },
        landmarksRound: [
          { code: 'CU', number: 2 },
          { code: 'BF', number: 1 },
          { code: 'BB', number: 2 },
        ],
        landmarkPlaces: [
          'Matafao Peak',
          'Monteverde Cloud Forest',
          'Emi Koussi',
          'Hato Caves',
          'Amalienborg',
          'Heydar Aliyev Center',
          'Spittal Pond',
        ],
        uniqueId: 'XGbtL94w',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Antigua and Barbuda',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9CJTrF-HnlU',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua_and_Barbuda',
        },
        landmark: {
          name: "St. John's Cathedral",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/St._John%27s_Cathedral_(Antigua_and_Barbuda)#/media/File:Stjohnscathedralantigua.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._John%27s_Cathedral_(Antigua_and_Barbuda)',
          hasLandmark: true,
        },
        city: {
          name: 'Codrington',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Codrington,_Barbuda#/media/File:Top_Mission_Street,_Codrington,_Barbuda.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Codrington,_Barbuda',
        },
        landmarksRound: [
          { code: 'BF', number: 1 },
          { code: 'HR', number: 2 },
          { code: 'KY', number: 2 },
        ],
        landmarkPlaces: [
          'Narikala Fortress',
          'Vardzia',
          'Uyuni Salt Flat',
          'George Washington House',
          'Lake Koman',
          'Lake Kivu',
          'Hazrat Ali Mazar',
        ],
        uniqueId: '3M3RHHyv',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Antigua',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-body-of-water-during-daytime-9CJTrF-HnlU',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua',
        },
        landmark: {
          name: 'Shirley Heights',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-blue-sea-under-blue-sky-during-daytime-Odmu1Fli6Fw',
          wikiLink:
            'https://en.wikipedia.org/wiki/Shirley_Heights,_Antigua_and_Barbuda',
          hasLandmark: true,
        },
        city: {
          name: 'Saint John',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-photograph-mfGkaQC5j4E',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_John,_Antigua_and_Barbuda',
        },
        landmarksRound: [
          { code: 'BA', number: 1 },
          { code: 'JO', number: 3 },
          { code: 'MD', number: 2 },
        ],
        landmarkPlaces: [
          'Gorkhi-Terelj National Park',
          'Kampong Kianggeh',
          'Sule Pagoda',
          'Sagrada Família',
          'Baobab Avenue',
          'Mosquée Al-Hamoudi',
          'Fouta Djallon',
        ],
        uniqueId: 'KCMytngv',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Antigua',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-mountain-and-houses--4itEczXe3U',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua',
        },
        landmark: {
          name: "St. John's Cathedral",
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-near-green-trees-under-blue-sky-during-daytime-uAzuA2DhS1M',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._John%27s_Cathedral_(Antigua_and_Barbuda)',
          hasLandmark: true,
        },
        city: {
          name: 'English Harbour',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-palm-tree-casts-a-shadow-on-a-dirt-road-wC2mVuF2D2w',
          wikiLink: 'https://en.wikipedia.org/wiki/English_Harbour',
        },
        landmarksRound: [
          { code: 'KI', number: 2 },
          { code: 'BB', number: 1 },
          { code: 'NC', number: 1 },
        ],
        landmarkPlaces: [
          'Singapore Flyer',
          'Heydar Aliyev Center',
          'Svalbard Church',
          'Sibebe Rock',
          'Patuxai',
          'Emi Koussi',
          'Langkawi Sky Bridge',
        ],
        uniqueId: 'fvrFVPbV',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Argentina',
    code: 'AR',
    capital: 'Buenos Aires',
    lat: -34,
    long: -64,
    cities: [
      'Cordoba',
      'Rosario',
      'Buenos Aires',
      'Merlo',
      'La Rioja',
      'Comodoro Rivadavia',
      'Villa Alpina',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/HGMYZ4scFkY',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Boca',
        },
        landmark: {
          name: 'Buenos Aires Obelisk',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:ObeliscoBA2017.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Obelisco_de_Buenos_Aires',
          hasLandmark: true,
        },
        city: {
          name: 'Cordoba',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/argentina-city-urban-architecture-7111640/',
          wikiLink: 'https://en.wikipedia.org/wiki/C%C3%B3rdoba,_Argentina',
        },
        landmarksRound: [
          { code: 'MO', number: 1 },
          { code: 'IS', number: 2 },
          { code: 'AQ', number: 2 },
        ],
        landmarkPlaces: [
          'The Blue Eye',
          'Taj Mahal',
          'Mir Castle',
          'Chillon Castle',
          'Maiden Tower',
          'Senso-ji',
          'Sistine Chapel',
        ],
        uniqueId: 'Lv685Mwz',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/landscape-truck-andes-dessert-74572/',
          wikiLink: 'https://en.wikipedia.org/wiki/Andes',
        },
        landmark: {
          name: 'Floralis Generica',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Floralis_Generica.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Floralis_Gen%C3%A9rica',
          hasLandmark: true,
        },
        city: {
          name: 'Rosario',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Rosario,_Santa_Fe#/media/File:Rosario_National_Flag_Memorial_View-20110510-RM-144455.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Rosario,_Santa_Fe',
        },
        landmarksRound: [
          { code: 'LS', number: 1 },
          { code: 'IM', number: 1 },
          { code: 'SG', number: 1 },
        ],
        landmarkPlaces: [
          'Blue Grotto',
          'Pigeon Rocks',
          'Charles Bridge',
          'Trevi Fountain',
          'Amber Palace',
          'Grand Ducal Palace',
          'Great Blue Hole',
        ],
        uniqueId: 'Xj59mp6C',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Córdoba',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/2hFmlbMFD14',
          wikiLink: 'https://en.wikipedia.org/wiki/C%C3%B3rdoba,_Argentina',
        },
        landmark: {
          name: 'Perito Moreno Glacier',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/tZpmdFfU5gQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Perito_Moreno_Glacier',
          hasLandmark: true,
        },
        city: {
          name: 'Buenos Aires',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/i3A-_KjB1Io',
          wikiLink: 'https://en.wikipedia.org/wiki/Buenos_Aires',
        },
        landmarksRound: [
          { code: 'AL', number: 1 },
          { code: 'CD', number: 2 },
          { code: 'AU', number: 1 },
        ],
        landmarkPlaces: [
          'Deception Island',
          'English Harbour',
          'Brest Fortress',
          'Shoal Bay',
          'Regina Mundi Cathedral',
          'Oriental Pearl TV Tower',
          'Mount Alava',
        ],
        uniqueId: 'myK7W5LP',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Río Negro Province',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/cUL8U0kAxrg',
          wikiLink: 'https://en.wikipedia.org/wiki/R%C3%ADo_Negro_Province',
        },
        landmark: {
          name: 'Les Eclaireurs Lighthouse',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/F_zec7P_OwA',
          wikiLink: 'https://en.wikipedia.org/wiki/Les_Eclaireurs_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Comodoro Rivadavia',
          photographer: '',
          imageLink:
            'https://en.wikivoyage.org/wiki/Comodoro_Rivadavia#/media/File:Comodoro_Rivadavia,_Chubut_Province,_Argentina_-_panoramio_(1).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Comodoro_Rivadavia',
        },
        landmarksRound: [
          { code: 'BD', number: 2 },
          { code: 'CC', number: 2 },
          { code: 'DZ', number: 1 },
        ],
        landmarkPlaces: [
          'Sukhbaatar Square',
          'Old Town Square',
          'Queen Emma Bridge',
          'National Museum of Slavery',
          'Brest Fortress',
          'Natural Pool',
          'Ulu Temburong National Park',
        ],
        uniqueId: 'fGwXTSmR',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Buenos Aires',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-under-cloudy-sky-during-daytime-z0A_IsZYXfM',
          wikiLink: 'https://en.wikipedia.org/wiki/Buenos_Aires',
        },
        landmark: {
          name: 'Iguazu Falls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/waterfalls-under-blue-sky-during-daytime-_h9nJNZiyr0',
          wikiLink: 'https://en.wikipedia.org/wiki/Iguazu_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Buenos Aires',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/woman-crossing-pedestrian-lane-between-tall-buildings-VynkVKneWJA',
          wikiLink: 'https://en.wikipedia.org/wiki/Buenos_Aires',
        },
        landmarksRound: [
          { code: 'EH', number: 1 },
          { code: 'FK', number: 2 },
          { code: 'DE', number: 3 },
        ],
        landmarkPlaces: [
          'Notre Dame of Bangui Cathedral',
          'National Park of American Samoa',
          'Clock Tower',
          'Marovo Lagoon',
          "Fisherman's Bastion",
          'Belvedere Lookout',
          'National Shrine Basilica of our Lady of Las Lajas',
        ],
        uniqueId: 'q8g4xtfs',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'San Carlos de Bariloche',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/river-in-the-middle-of-mountain-under-sky-yLyDmpfivus',
          wikiLink: 'https://es.wikipedia.org/wiki/San_Carlos_de_Bariloche',
        },
        landmark: {
          name: "Women's Bridge",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/cuandopintalafotografia/6995917484/in/photolist-MAumm-Fc9r6e-XVjxXg-RoiprN-26W9pFy-2cVm7Av-K3kt2J-2b7WFvF-CpVjdu-bEcUwY-Zjmo11-vFLsfw-SXKfj3-22hLVGP-Q971a9-bBDstf-abGkmf-vEJreS-9F7EoD-HsoPTQ-e7YxcX-24EvAxX-oXVUt9-P3GtgT-s1B6xd-5uzrRT-uKLqcS-k85hPc-eeC6gg-bQW39c-bBWn1j-3BoBCw-7FgqRb-Qw66w8-MkFH28-9DC5vw-KAFWmN-Cvx8zJ-dzU2CJ-YArLbB-dnfzbB-d49z4m-cNDh6E-Y9UJLb-v8XLTr-Gw5t2Z-GuNUfo-8E1wQV-DgUbXy-EmmNYU',
          wikiLink: 'https://en.wikipedia.org/wiki/Puente_de_la_Mujer',
          hasLandmark: true,
        },
        city: {
          name: 'Villa Alpina',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-field-at-daytime-ChdSbyxq8yc',
          wikiLink: 'https://es.wikipedia.org/wiki/Villa_Alpina_(Argentina)',
        },
        landmarksRound: [
          { code: 'IL', number: 4 },
          { code: 'SH', number: 2 },
          { code: 'GH', number: 4 },
        ],
        landmarkPlaces: [
          'Blue Lagoon',
          'Egyptian Museum',
          'Tazumal',
          'Maralgöl',
          'Pukapuka',
          'Langkawi Sky Bridge',
          'House of Slaves',
        ],
        uniqueId: 'gWWtP5nF',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Armenia',
    code: 'AM',
    capital: 'Yerevan',
    lat: 40,
    long: 45,
    cities: ['Gyumri', 'Yerevan', 'Vanadzor', 'Abovyan', 'Armavir', 'Kapan'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/SmueverK-sg',
          wikiLink: 'https://en.wikipedia.org/wiki/Temple_of_Garni',
        },
        landmark: {
          name: 'Noravank Monastery',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Noravank#/media/File:Noravank12.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Noravank',
          hasLandmark: true,
        },
        city: {
          name: 'Gyumri',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Gyumri#/media/File:View_over_Gyumri_rooftops_to_Mount_Aragats.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gyumri',
        },
        landmarksRound: [
          { code: 'SJ', number: 1 },
          { code: 'AE', number: 2 },
          { code: 'HN', number: 1 },
        ],
        landmarkPlaces: [
          'Macau Tower',
          'Monteverde Cloud Forest',
          'Baalbek',
          'Ulu Temburong National Park',
          'Castillo San Cristobal',
          'CN Tower',
          'Chillon Castle',
        ],
        uniqueId: '7CyYfl7x',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/armenia-yerevan-ararat-city-3721418/',
          wikiLink: 'https://en.wikipedia.org/wiki/Yerevan',
        },
        landmark: {
          name: 'Amberd',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Amberd#/media/File:Amberd_2015.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Amberd',
          hasLandmark: true,
        },
        city: {
          name: 'Yerevan',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/armenia-yerevan-ararat-city-3721418/',
          wikiLink: 'https://en.wikipedia.org/wiki/Yerevan',
        },
        landmarksRound: [
          { code: 'PL', number: 2 },
          { code: 'MO', number: 1 },
          { code: 'MZ', number: 1 },
        ],
        landmarkPlaces: [
          'Karnak',
          'Bran Castle',
          'Kasubi Royal Tombs',
          'Queen Emma Bridge',
          'Middle of the World City',
          'Ostrog Monastery',
          'Timgad',
        ],
        uniqueId: 'vMKGwKVJ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Syunik Province',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/h5TdClJhDMc',
          wikiLink: 'https://en.wikipedia.org/wiki/Syunik_Province',
        },
        landmark: {
          name: 'Temple of Garni',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/SmueverK-sg',
          wikiLink: 'https://en.wikipedia.org/wiki/Temple_of_Garni',
          hasLandmark: true,
        },
        city: {
          name: 'Yerevan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/_EpaiWp5yC8',
          wikiLink: 'https://en.wikipedia.org/wiki/Yerevan',
        },
        landmarksRound: [
          { code: 'BO', number: 1 },
          { code: 'AZ', number: 2 },
          { code: 'AS', number: 3 },
        ],
        landmarkPlaces: [
          'Al Fateh Grand Mosque',
          'Sukhbaatar Square',
          'Forbidden City',
          "Diocletian's Palace",
          'Christ of Havana',
          'Chimi Lhakhang',
          'Mostar Old Bridge',
        ],
        uniqueId: 'xbf4mzhB',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Yerevan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/AjnjJNgD15A',
          wikiLink: 'https://en.wikipedia.org/wiki/Yerevan',
        },
        landmark: {
          name: 'Tsitsernakaberd, Armenian Genocide Memorial',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/jfiiIAegN74',
          wikiLink: 'https://en.wikipedia.org/wiki/Tsitsernakaberd',
          hasLandmark: true,
        },
        city: {
          name: 'Gyumri',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/1btXtr2_ixA',
          wikiLink: 'https://en.wikipedia.org/wiki/Gyumri',
        },
        landmarksRound: [
          { code: 'AR', number: 2 },
          { code: 'CD', number: 2 },
          { code: 'AL', number: 3 },
        ],
        landmarkPlaces: [
          'Timgad',
          'Charles Bridge',
          'Roman ruins in Batna',
          'Amber Palace',
          'Source du Nil',
          'Pendjari National Park',
          'Elephant Island',
        ],
        uniqueId: 'fnQTBKFt',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Armenia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-a-village-during-daytime-VQ07UveM3-U',
          wikiLink: 'https://en.wikipedia.org/wiki/Armenia',
        },
        landmark: {
          name: 'Republic Square',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/josaugust/51926309403/',
          wikiLink: 'https://en.wikipedia.org/wiki/Republic_Square,_Yerevan',
          hasLandmark: true,
        },
        city: {
          name: 'Yerevan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-snow-covered-mountain-under-blue-sky-during-daytime-Mfyur8pZSMg',
          wikiLink: 'https://en.wikipedia.org/wiki/Yerevan',
        },
        landmarksRound: [
          { code: 'MV', number: 4 },
          { code: 'NO', number: 1 },
          { code: 'UA', number: 2 },
        ],
        landmarkPlaces: [
          "Martyr's Memorial",
          'Ocean Park',
          'Charles Bridge',
          'Iskanderkul',
          'Topkapı Palace',
          'Sukhbaatar Square',
          'Taj Mahal',
        ],
        uniqueId: 'GPkgjhKL',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Lori Province',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-grass-field-during-daytime-ilBjvU0wxH4',
          wikiLink: 'https://en.wikipedia.org/wiki/Lori_Province',
        },
        landmark: {
          name: 'Khor Virap Monastery',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-old-castle-in-the-middle-of-a-field-1iCcIYpwHHo',
          wikiLink: 'https://en.wikipedia.org/wiki/Khor_Virap',
          hasLandmark: true,
        },
        city: {
          name: 'Vanadzor',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/hscapture/45000009812/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanadzor',
        },
        landmarksRound: [
          { code: 'GA', number: 3 },
          { code: 'ET', number: 1 },
          { code: 'GN', number: 4 },
        ],
        landmarkPlaces: [
          'Lotus Temple',
          'Okavango Delta',
          'Rotunda of Mosta',
          'A-Ma Temple',
          'Unfinished Church',
          'Boali',
          'Soufrière Hills volcano',
        ],
        uniqueId: 'JHDLClVY',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Aruba',
    code: 'AW',
    capital: 'Oranjestad',
    lat: 12.5,
    long: -69.96666666,
    cities: [
      'Oranjestad',
      'San Nicolaas',
      'Santa Cruz',
      'Paradera',
      'Noord',
      'Savaneta',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Ui99Hiv3Ezg',
          wikiLink: 'https://en.wikipedia.org/wiki/Palm_Beach,_Aruba',
        },
        landmark: {
          name: 'California Lighthouse',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/California_Lighthouse#/media/File:CaliforniaLight.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/California_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Oranjestad',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/aruba-oranjestad-caribbean-antilles-4881217/',
          wikiLink: 'https://en.wikipedia.org/wiki/Oranjestad,_Aruba',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '2gbyk5Hf',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/beach-aruba-sandy-beach-sea-26011/',
          wikiLink: 'https://en.wikipedia.org/wiki/Aruba',
        },
        landmark: {
          name: 'Natural Pool',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Natural_Pool_(Aruba)#/media/File:Aruba-Natural-Pool-2013.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Natural_Pool_(Aruba)',
          hasLandmark: true,
        },
        city: {
          name: 'Noord',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Berea_di_Piscado,_Noord,_Aruba_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Noord',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'BVtGT2bn',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Seroe Colorado',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/t_dfcMDZ4T8',
          wikiLink: 'https://en.wikipedia.org/wiki/Seroe_Colorado',
        },
        landmark: {
          name: 'Quadiriki Caves',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WCxqZdoWn3Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Quadiriki_Caves',
          hasLandmark: true,
        },
        city: {
          name: 'Oranjestad',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/oqhc6lz-nD4',
          wikiLink: 'https://en.wikipedia.org/wiki/Oranjestad,_Aruba',
        },
        landmarksRound: [
          { code: 'KM', number: 2 },
          { code: 'CK', number: 2 },
          { code: 'BY', number: 1 },
        ],
        landmarkPlaces: [
          'Olavinlinna',
          'Elephant Island',
          'Lake Koman',
          'Xunatunich Mayan Ruins',
          'Dolly Beach',
          'Zaisan Memorial',
          'Pendjari National Park',
        ],
        uniqueId: '4NBLxcw8',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Aruba',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/rfJDRWkHmKs',
          wikiLink: 'https://en.wikipedia.org/wiki/Aruba',
        },
        landmark: {
          name: 'Donkey Sanctuary',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Aruba_Donkey_Sanctuary_22_27_42_220000.jpeg',
          wikiLink: 'https://www.aruba.com/us/explore/donkey-sanctuary',
          hasLandmark: true,
        },
        city: {
          name: 'San Nicolaas',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/cMzHApTAapk',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Nicolaas',
        },
        landmarksRound: [
          { code: 'CN', number: 2 },
          { code: 'AD', number: 1 },
          { code: 'DK', number: 2 },
        ],
        landmarkPlaces: [
          'Little Bay',
          'Elephant Island',
          'Brest Fortress',
          'Angkor Wat',
          'Qargha Reservoir',
          "Tassili n'Ajjer",
          "Diocletian's Palace",
        ],
        uniqueId: 'SpwrZnkR',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Aruba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-daytime-1o7_YwVkms8',
          wikiLink: 'https://en.wikipedia.org/wiki/Aruba',
        },
        landmark: {
          name: 'Alto Vista Chapel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-and-beige-houe-c67b4PVvUPk',
          wikiLink: 'https://en.wikipedia.org/wiki/Alto_Vista_Chapel',
          hasLandmark: true,
        },
        city: {
          name: 'Oranjestad',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-pink-and-white-building-with-a-palm-tree-in-front-of-it-9mdVy93KNgM',
          wikiLink: 'https://en.wikipedia.org/wiki/Oranjestad,_Aruba',
        },
        landmarksRound: [
          { code: 'AT', number: 3 },
          { code: 'VC', number: 2 },
          { code: 'VE', number: 3 },
        ],
        landmarkPlaces: [
          'Great Siege Tunnels',
          'Kunta Kinteh Island',
          'Kaieteur Falls',
          'Merry Cemetery',
          'Bayon Temple',
          'Frida Kahlo Museum',
          'Spittal Pond',
        ],
        uniqueId: 'BFQpFywc',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Aruba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-birds-eye-view-of-a-beach-and-resort-D5WueT15KGw',
          wikiLink: 'https://en.wikipedia.org/wiki/Aruba',
        },
        landmark: {
          name: 'California Lighthouse',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/maddox/38443691/',
          wikiLink: 'https://en.wikipedia.org/wiki/California_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'San Nicolaas',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/peterpaw/34156020/',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Nicolaas',
        },
        landmarksRound: [
          { code: 'AU', number: 4 },
          { code: 'BA', number: 4 },
          { code: 'VI', number: 4 },
        ],
        landmarkPlaces: [
          'Okavango Delta',
          'Christ Church Cathedral',
          'Sagrada Família',
          'Palazzo Pubblico',
          'Mount Everest',
          'Aleppo Citadel',
          'Dolly Beach',
        ],
        uniqueId: 'sdjLYPGp',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Australia',
    code: 'AU',
    capital: 'Canberra',
    lat: -27,
    long: 133,
    cities: [
      'Perth',
      'Brisbane',
      'Canberra',
      'Sydney',
      'Melbourne',
      'Adelaide',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WEtXkeIlMoM',
          wikiLink: 'https://en.wikipedia.org/wiki/Uluru',
        },
        landmark: {
          name: 'Sydney Opera House',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sydney-opera-house-building-363244/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sydney_Opera_House',
          hasLandmark: true,
        },
        city: {
          name: 'Perth',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/perth-western-australia-city-1368337/',
          wikiLink: 'https://en.wikipedia.org/wiki/Perth',
        },
        landmarksRound: [
          { code: 'PF', number: 1 },
          { code: 'NL', number: 2 },
          { code: 'GQ', number: 1 },
        ],
        landmarkPlaces: [
          'Kaaba',
          'Chichen Itza',
          'Pyramids of Meroe',
          'Fort Fincastle',
          'Stonehenge',
          'Hato Caves',
          'The Marble Mountains',
        ],
        uniqueId: 'xjmMwGPK',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/australia-uluru-ayers-rock-mountain-630219/',
          wikiLink: 'https://en.wikipedia.org/wiki/Uluru',
        },
        landmark: {
          name: 'Twelve Apostles',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/twelve-apostles-12-apostles-302024/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Twelve_Apostles_Marine_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Brisbane',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/beach-city-southbank-swimmers-sand-1222080/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brisbane',
        },
        landmarksRound: [
          { code: 'CN', number: 2 },
          { code: 'TJ', number: 1 },
          { code: 'KN', number: 2 },
        ],
        landmarkPlaces: [
          'Tavarua',
          'Borobudur Temple',
          "St. Tryphon's Cathedral",
          'Ostrog Monastery',
          'Mount Kenya',
          'Bled Castle',
          'Grytviken Church',
        ],
        uniqueId: 'SbjWCgrr',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Australia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-and-trees-under-blue-sky-during-daytime-EccMAZtkS7g',
          wikiLink: 'https://en.wikipedia.org/wiki/Australia',
        },
        landmark: {
          name: 'Blue Mountains',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/IGMABomGhr8',
          wikiLink:
            'https://en.wikipedia.org/wiki/Blue_Mountains_(New_South_Wales)',
          hasLandmark: true,
        },
        city: {
          name: 'Sydney',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/7Zb7kUyQg1E',
          wikiLink: 'https://en.wikipedia.org/wiki/Sydney',
        },
        landmarksRound: [
          { code: 'AF', number: 4 },
          { code: 'BR', number: 2 },
          { code: 'MN', number: 1 },
        ],
        landmarkPlaces: [
          'South Pole',
          'Niagara Falls',
          'Herat Citadel',
          'Grandvalira',
          'Cameroon Reunification',
          "Notre-Dame d'Afrique",
          'Tsitsernakaberd, Armenian Genocide Memorial',
        ],
        uniqueId: 'lHlnzqWl',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Flinders Street railway station',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/o3TZx8_j7FE',
          wikiLink:
            'https://en.wikipedia.org/wiki/Flinders_Street_railway_station',
        },
        landmark: {
          name: 'Uluru',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/1fuq-hNWyok',
          wikiLink: 'https://en.wikipedia.org/wiki/Uluru',
          hasLandmark: true,
        },
        city: {
          name: 'Adelaide',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/w-FyPdvcS2U',
          wikiLink: 'https://en.wikipedia.org/wiki/Adelaide',
        },
        landmarksRound: [
          { code: 'AT', number: 1 },
          { code: 'AO', number: 4 },
          { code: 'BG', number: 2 },
        ],
        landmarkPlaces: [
          'Perito Moreno Glacier',
          'Madriu-Perafita-Claror Valley',
          'Basilica del Voto Nacional',
          'Arenal Volcano',
          'Bellapais Abbey',
          'Brest Fortress',
          'St. Alexander Nevsky Cathedral',
        ],
        uniqueId: 'xhrJrtgG',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Sydney',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-concrete-buildings-and-bridge-beside-ocean-under-cloudy-sky-J61Sh5YrQho',
          wikiLink: 'https://en.wikipedia.org/wiki/Sydney',
        },
        landmark: {
          name: 'Sydney Opera House',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/sydney-opera-house-australia-jK9dT34TfuI',
          wikiLink: 'https://en.wikipedia.org/wiki/Sydney_Opera_House',
          hasLandmark: true,
        },
        city: {
          name: 'Sydney',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-bridge-Hao52Fu9-F8',
          wikiLink: 'https://en.wikipedia.org/wiki/Sydney',
        },
        landmarksRound: [
          { code: 'KW', number: 4 },
          { code: 'SI', number: 1 },
          { code: 'MX', number: 1 },
        ],
        landmarkPlaces: [
          'Tallinn Town Hall',
          'Shoes on the Danube Bank',
          'The 1763 Monument',
          'Pierre Savorgnan de Brazza Memorial',
          'Abu Simbel',
          'Jardin Botanique de Deshaies',
          'Lakes of Ounianga',
        ],
        uniqueId: 'YpQJVfG4',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Melbourne',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-bridge-across-city-buildings--gmtAa0Q5MI',
          wikiLink: 'https://en.wikipedia.org/wiki/Melbourne',
        },
        landmark: {
          name: 'The Pinnacles Desert',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/field-of-rocks-gF0IZVVKrD0',
          wikiLink:
            'https://en.wikipedia.org/wiki/The_Pinnacles_(Western_Australia)',
          hasLandmark: true,
        },
        city: {
          name: 'Brisbane',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-city-near-river-fgy0t-QHLcQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Brisbane',
        },
        landmarksRound: [
          { code: 'GE', number: 1 },
          { code: 'SA', number: 2 },
          { code: 'UY', number: 2 },
        ],
        landmarkPlaces: [
          'Emerald Pool',
          'Neuschwanstein Castle',
          'Pico Basile',
          'Church of Our Savior',
          'Mir Castle',
          'San Juan National Historic Site',
          'Gateway Arch',
        ],
        uniqueId: 'dhLtVKRb',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Austria',
    code: 'AT',
    capital: 'Vienna',
    lat: 47.33333333,
    long: 13.33333333,
    cities: ['Vienna', 'Salzburg', 'Innsbruck', 'Graz', 'Villach', 'Hallstatt'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/3wFRlwS91yk',
          wikiLink: 'https://en.wikipedia.org/wiki/Hallstatt',
        },
        landmark: {
          name: 'Hofburg Palace',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vienna-hofburg-imperial-palace-5419044/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hofburg',
          hasLandmark: true,
        },
        city: {
          name: 'Vienna',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/panorama-vienna-austria-city-view-427929/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vienna',
        },
        landmarksRound: [
          { code: 'EG', number: 2 },
          { code: 'AD', number: 1 },
          { code: 'KE', number: 1 },
        ],
        landmarkPlaces: [
          'The Blue Eye',
          'Liwonde National Park',
          'Salvo Palace',
          'Mount Yasur',
          'Hato Caves',
          'Golden Gate',
          'Chamarel Waterfall',
        ],
        uniqueId: 'n3GxRVdZ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/mountains-alps-trees-1244132/',
          wikiLink: 'https://en.wikipedia.org/wiki/Alps',
        },
        landmark: {
          name: "St. Stephen's Cathedral",
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vienna-st-stephen-s-cathedral-church-82261/',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Stephen%27s_Cathedral,_Vienna',
          hasLandmark: true,
        },
        city: {
          name: 'Salzburg',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/salzburg-city-night-lights-river-5964812/',
          wikiLink: 'https://en.wikipedia.org/wiki/Salzburg',
        },
        landmarksRound: [
          { code: 'AU', number: 1 },
          { code: 'PY', number: 1 },
          { code: 'US', number: 1 },
        ],
        landmarkPlaces: [
          'Floralis Generica',
          'Roman theatre',
          'Rila Monastery',
          'Al Alam Palace',
          'Palace of Versailles',
          'Christiansborg Palace',
          'Tokyo Skytree',
        ],
        uniqueId: 'nXr6HTPP',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Austria',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ilBSVoU1mGs',
          wikiLink: 'https://en.wikipedia.org/wiki/Austria',
        },
        landmark: {
          name: 'Schlossberg (Graz)',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Schlo%C3%9Fberg,_Graz#/media/File:16-07-06-Rathaus_Graz_Balkon-RR2_0191.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Schlossberg_(Graz)',
          hasLandmark: true,
        },
        city: {
          name: 'Hallstatt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/town-beside-body-of-water-and-mountains-9DgwO_ihqL0',
          wikiLink: 'https://en.wikipedia.org/wiki/Hallstatt',
        },
        landmarksRound: [
          { code: 'AM', number: 1 },
          { code: 'AQ', number: 1 },
          { code: 'KY', number: 2 },
        ],
        landmarkPlaces: [
          'Herat Citadel',
          'Heydar Aliyev Center',
          'Great Blue Hole',
          'Roman ruins in Batna',
          'Basilica del Voto Nacional',
          'The Blue Eye',
          'Olavinlinna',
        ],
        uniqueId: 'M36SlNYr',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Austria',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yWkeQZ_-IdU',
          wikiLink: 'https://en.wikipedia.org/wiki/Austria',
        },
        landmark: {
          name: 'Almsee',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-8JT7qjuM4A',
          wikiLink: 'https://en.wikipedia.org/wiki/Almsee',
          hasLandmark: true,
        },
        city: {
          name: 'Innsbruck',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/e4et737H4ow',
          wikiLink: 'https://en.wikipedia.org/wiki/Innsbruck',
        },
        landmarksRound: [
          { code: 'BA', number: 1 },
          { code: 'BW', number: 2 },
          { code: 'AU', number: 2 },
        ],
        landmarkPlaces: [
          'Les Eclaireurs Lighthouse',
          'Angkor Wat',
          'Museum ship Pommern',
          'Chimi Lhakhang',
          'Little Bay',
          'Christ of Havana',
          'Wallblake House',
        ],
        uniqueId: 'K84SmHDZ',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Austria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-body-of-water-8jp2a2hc5xs',
          wikiLink: 'https://en.wikipedia.org/wiki/Austria',
        },
        landmark: {
          name: 'Hundertwasserhaus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-very-tall-building-with-lots-of-plants-growing-on-it-uBGHV9176Ik',
          wikiLink: 'https://en.wikipedia.org/wiki/Hundertwasserhaus',
          hasLandmark: true,
        },
        city: {
          name: 'Hallstatt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-house-near-mountain-wt6bSGmcq4o',
          wikiLink: 'https://en.wikipedia.org/wiki/Hallstatt',
        },
        landmarksRound: [
          { code: 'VI', number: 1 },
          { code: 'ML', number: 4 },
          { code: 'BH', number: 4 },
        ],
        landmarkPlaces: [
          'The Basilica of Our Lady of Peace of Yamoussoukro',
          'Lily Beach',
          'Ocean Park',
          'Chichen Itza',
          'Uyuni Salt Flat',
          'Arch 22',
          'Million Dollar Point',
        ],
        uniqueId: '4JjzcpR5',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Hallstatt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photography-of-house-beside-body-of-water-tV06QVJXVxU',
          wikiLink: 'https://en.wikipedia.org/wiki/Hallstatt',
        },
        landmark: {
          name: 'Melk Abbey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-clock-tower-in-the-middle-of-it-bEZ0Ep4V7NI',
          wikiLink: 'https://en.wikipedia.org/wiki/Melk_Abbey',
          hasLandmark: true,
        },
        city: {
          name: 'Vienna',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-city-buildings-5SjAaqqCCmY',
          wikiLink: 'https://en.wikipedia.org/wiki/Vienna',
        },
        landmarksRound: [
          { code: 'GF', number: 2 },
          { code: 'FR', number: 2 },
          { code: 'AL', number: 2 },
        ],
        landmarkPlaces: [
          'Lake Kivu',
          'Näsinneula',
          "Ruins of St. Paul's",
          'Grand Ducal Palace',
          'Baalbek',
          'Cotton Tree',
          'Great Siege Tunnels',
        ],
        uniqueId: 'zLQ2twKY',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    capital: 'Baku',
    lat: 40.5,
    long: 47.5,
    cities: ['Baku', 'Ganja', 'Mingrecevir', 'Nakhchivan', 'Sheki', 'Sumgait'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/wX_9ZhIErpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Flame_Towers',
        },
        landmark: {
          name: 'Maiden Tower',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/maiden-tower-baku-azerbaijan-1997161/',
          wikiLink: 'https://en.wikipedia.org/wiki/Maiden_Tower_(Baku)',
          hasLandmark: true,
        },
        city: {
          name: 'Baku',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/baku-azerbaijan-panorama-sunset-4626962/',
          wikiLink: 'https://en.wikipedia.org/wiki/Baku',
        },
        landmarksRound: [
          { code: 'LU', number: 1 },
          { code: 'BG', number: 2 },
          { code: 'NP', number: 2 },
        ],
        landmarkPlaces: [
          'Herat Citadel',
          'Seven Colored Earths',
          'Trevi Fountain',
          'Tokyo Skytree',
          'Rila Monastery',
          'Grand Ducal Palace',
          'Old Dongola',
        ],
        uniqueId: 'mrKHBmW8',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/winter-azerbaijan-horse-landscape-2048629/',
          wikiLink: 'https://en.wikipedia.org/wiki/Azerbaijan',
        },
        landmark: {
          name: 'Heydar Aliyev Center',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Heydar_Aliyev_Center#/media/File:Heydar_Aliyev_Cultural_Center.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Heydar_Aliyev_Center',
          hasLandmark: true,
        },
        city: {
          name: 'Ganja',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ganja,_Azerbaijan#/media/File:Ganja_Azerbaijan.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ganja,_Azerbaijan',
        },
        landmarksRound: [
          { code: 'PL', number: 2 },
          { code: 'BB', number: 1 },
          { code: 'BR', number: 2 },
        ],
        landmarkPlaces: [
          'Langkawi Sky Bridge',
          'Turku Castle',
          "Memorial de l'Anse",
          'Al Fateh Grand Mosque',
          'Wat Xiengthong',
          'Badshahi Mosque',
          'Tenaru Falls',
        ],
        uniqueId: 'StGvbLLV',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Khizi District',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/D-Zz4om1dqQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Khizi_District',
        },
        landmark: {
          name: 'We Are Our Mountains',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/wH4pCUuKZTA',
          wikiLink: 'https://en.wikipedia.org/wiki/We_Are_Our_Mountains',
          hasLandmark: true,
        },
        city: {
          name: 'Baku',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/4KdSBKE71Bs',
          wikiLink: 'https://en.wikipedia.org/wiki/Baku',
        },
        landmarksRound: [
          { code: 'AQ', number: 2 },
          { code: 'IO', number: 2 },
          { code: 'BR', number: 2 },
        ],
        landmarkPlaces: [
          'Band-e Amir National Park',
          'Chomoni Beach',
          'Chimi Lhakhang',
          'Uyuni Salt Flat',
          'Fort James',
          'Taj Mahal',
          'Old Town Square',
        ],
        uniqueId: 'bGQvX4mj',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Baku',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/AOWi0OXBUQo',
          wikiLink: 'https://en.wikipedia.org/wiki/Baku',
        },
        landmark: {
          name: 'Maralgöl',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/5pGw6psjwjQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Maralg%C3%B6l',
          hasLandmark: true,
        },
        city: {
          name: 'Sumgait',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/seaside-and-the-pier-in-sumgait-azerbaijan-13220127/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sumgait',
        },
        landmarksRound: [
          { code: 'IO', number: 1 },
          { code: 'CK', number: 2 },
          { code: 'CX', number: 2 },
        ],
        landmarkPlaces: [
          'Lake Kivu',
          'Herat Citadel',
          'Quadiriki Caves',
          'Twelve Apostles',
          'Easter Island',
          'Mount Nyriagongo',
          'George Washington House',
        ],
        uniqueId: 'kykCYLHD',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Baku',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-large-body-of-water-and-a-ferris-wheel-ka5GnsV9CTk',
          wikiLink: 'https://en.wikipedia.org/wiki/Baku',
        },
        landmark: {
          name: 'Heydar Mosque',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/eric00000007/48237406532/',
          wikiLink: 'https://en.wikipedia.org/wiki/Heydar_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Baku',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-skyline-under-blue-sky-during-daytime-wX_9ZhIErpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Baku',
        },
        landmarksRound: [
          { code: 'SD', number: 2 },
          { code: 'CL', number: 4 },
          { code: 'LR', number: 2 },
        ],
        landmarkPlaces: [
          'Republic Square',
          'Castillo De Los Tres Reyes Del Morro',
          'Shwedagon Pagoda',
          'The Little Chapel',
          'Amedee Lighthouse',
          'Sistine Chapel',
          'Christ of Havana',
        ],
        uniqueId: 'TxJWxzfF',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Azerbaijan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-scenic-view-of-a-forested-area-with-mountains-in-the-distance-kk0Xu9ft6PE',
          wikiLink: 'https://en.wikipedia.org/wiki/Azerbaijan',
        },
        landmark: {
          name: 'Philharmonic Garden ',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/bims2008/49135953693/',
          wikiLink: 'https://en.wikipedia.org/wiki/Philharmonic_Garden',
          hasLandmark: true,
        },
        city: {
          name: 'Ganja',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-playing-a-game-of-chess-DX5bGsguElk',
          wikiLink: 'https://en.wikipedia.org/wiki/Ganja,_Azerbaijan',
        },
        landmarksRound: [
          { code: 'CW', number: 3 },
          { code: 'CD', number: 4 },
          { code: 'EG', number: 4 },
        ],
        landmarkPlaces: [
          'Fort Charlotte',
          'Panama Canal',
          'Muliaage',
          'Arc de Triomphe',
          'Tomb of Hafez',
          'Fort Napoléon des Saintes',
          'Capilano Suspension Bridge',
        ],
        uniqueId: 'P276WlNc',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Bahamas',
    code: 'BS',
    capital: 'Nassau',
    lat: 24.25,
    long: -76,
    cities: [
      'Freeport',
      'Dunmore Town',
      'George Town',
      'Coopers Town',
      'Nassau',
      'Lucaya',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/xa-fv2RQCPY',
          wikiLink: 'https://en.wikipedia.org/wiki/Pig_Beach',
        },
        landmark: {
          name: "Queen's Staircase",
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Queen%27s_Staircase_(Nassau)#/media/File:Queen's_staircase,_Nassau,_Bahamas.jpg",
          wikiLink:
            'https://en.wikipedia.org/wiki/Queen%27s_Staircase_(Nassau)',
          hasLandmark: true,
        },
        city: {
          name: 'Nassau',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bahamas-lighthouse-caribbean-sea-783799/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nassau,_Bahamas',
        },
        landmarksRound: [
          { code: 'CY', number: 1 },
          { code: 'NO', number: 1 },
          { code: 'BY', number: 1 },
        ],
        landmarkPlaces: [
          'Gangtey Monastery',
          'Buenos Aires Obelisk',
          'Hato Caves',
          'Castle Cornet',
          'Azadi Tower',
          'Christ Church Cathedral',
          'Easter Island',
        ],
        uniqueId: 'K7HPd6wy',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bahamas-lighthouse-caribbean-sea-783799/',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Bahamas',
        },
        landmark: {
          name: 'Fort Fincastle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort_Fincastle_(The_Bahamas)#/media/File:Fort_Fincastle.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fort_Fincastle_(The_Bahamas)',
          hasLandmark: true,
        },
        city: {
          name: 'Freeport',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Freeport,_Bahamas#/media/File:Lucayan_Harbor_JonWorth.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Freeport,_Bahamas',
        },
        landmarksRound: [
          { code: 'MM', number: 1 },
          { code: 'VA', number: 1 },
          { code: 'OM', number: 2 },
        ],
        landmarkPlaces: [
          'The Blue Mosque',
          'Timgad',
          'Rizal Park',
          'Hazrat Ali Mazar',
          'Red Square',
          'Sukhbaatar Square',
          "Fisherman's Bastion",
        ],
        uniqueId: 'bQnCnt7D',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Exuma',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/hpZh7Pvs9mk',
          wikiLink: 'https://en.wikipedia.org/wiki/Exuma',
        },
        landmark: {
          name: 'Paradise Island',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/XjKiG46fkQI',
          wikiLink: 'https://en.wikipedia.org/wiki/Paradise_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Nassau',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/rMaWin9-9Gk',
          wikiLink: 'https://en.wikipedia.org/wiki/Nassau,_Bahamas',
        },
        landmarksRound: [
          { code: 'HR', number: 1 },
          { code: 'KH', number: 1 },
          { code: 'EC', number: 2 },
        ],
        landmarkPlaces: [
          'Notre Dame of Bangui Cathedral',
          'Perito Moreno Glacier',
          'Sydney Opera House',
          'Alega Beach',
          'Middle of the World City',
          'Regina Mundi Cathedral',
          'Lake Kivu',
        ],
        uniqueId: 'P359Bf4H',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Bahamas',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/A6bX1G3zuMA',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Bahamas',
        },
        landmark: {
          name: 'Fort Montagu',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Fort_Montagu_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Montagu',
          hasLandmark: true,
        },
        city: {
          name: 'Lucaya',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lucaya,_Bahamas#/media/File:PortLucayaMarketplaceAndMarina.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lucaya,_Bahamas',
        },
        landmarksRound: [
          { code: 'AL', number: 3 },
          { code: 'DZ', number: 4 },
          { code: 'AS', number: 1 },
        ],
        landmarkPlaces: [
          'Little Bay',
          'Mount Karthala',
          'Plaza Murillo',
          'The Blue Eye',
          'Amalienborg',
          'Pukapuka',
          'Elephant Island',
        ],
        uniqueId: 'VgjL8JZC',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Bahamas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-lighthouse-on-brown-rock-formation-on-sea-during-daytime-T8mbim7bbOg',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Bahamas',
        },
        landmark: {
          name: 'Nassau Public Library',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/24736216@N07/7101910905/in/photolist-bPz9FK-GMNvC8-2eYLBaQ-3qGYzy-3qxvkM-HHpcrH-HB9oFi-euZvbi-7eLRh8-g2dTB2-68qq2p-68qqhc-68qpXB-4axaML-2o5e15F-ehVYw1-7eQKmY-29nrb2e-2jx1RdD-9gsZS2-9gw61Y-2o1PSaS-9gt1ex-ev3C5U-7LS8t7-2dE9N6K-CkxBWN-edLav9-5NCeA6-2nNYhng-4ZieiN-YbPLgf-SE6SnD-21643Pb-7eQKyU-TGoJZB-g2GYzx-rQKs5f-7umEpq-HEptJm-7eQKJ7-9kLJTJ-pyaa58-6nmEiC-2nqBC7H-pycdB5-p9rm6V-2aTAzQ5-6m84kS-qfrXCu',
          wikiLink: 'https://en.wikipedia.org/wiki/Nassau_Public_Library',
          hasLandmark: true,
        },
        city: {
          name: 'Nassau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-a-hill-pVg4rB43LDM',
          wikiLink: 'https://en.wikipedia.org/wiki/Nassau,_Bahamas',
        },
        landmarksRound: [
          { code: 'IO', number: 3 },
          { code: 'AL', number: 5 },
          { code: 'VA', number: 4 },
        ],
        landmarkPlaces: [
          'St. Peter the Apostle Cathedral',
          'St. Nicholas Abbey Heritage Railway',
          'Uganda National Mosque',
          'Te Rua Manga (The Needle)',
          'National Pantheon',
          'Million Dollar Point',
          'Tazumal',
        ],
        uniqueId: 'mZvf6H6l',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Berry Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-standing-on-white-and-blue-rock-formation-near-body-of-water-during-daytime-cRQYPtbLLew',
          wikiLink: 'https://en.wikipedia.org/wiki/Berry_Islands',
        },
        landmark: {
          name: "Queen's Staircase",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/andrewandsarahtravels/26234190423/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Queen%27s_Staircase_(Nassau)',
          hasLandmark: true,
        },
        city: {
          name: 'Freeport',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/59211217@N05/5424830725/',
          wikiLink: 'https://en.wikipedia.org/wiki/Freeport,_Bahamas',
        },
        landmarksRound: [
          { code: 'IL', number: 4 },
          { code: 'EH', number: 2 },
          { code: 'MK', number: 1 },
        ],
        landmarkPlaces: [
          'Maralgöl',
          'Tombs of the Kings (Paphos)',
          'El Capitolio',
          'Limu Pools',
          "Tassili n'Ajjer",
          'Brest Fortress',
          'Peel Castle',
        ],
        uniqueId: 'NDZZHf6B',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Bahrain',
    code: 'BH',
    capital: 'Manama',
    lat: 26,
    long: 50.55,
    cities: ['Muharraq', 'Manama', 'Riffa', "A'ali", 'Sitra', 'Hamad Town'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/S7FEiLILEuM',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain_World_Trade_Center',
        },
        landmark: {
          name: 'Bahrain Fort',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Qal%27at_al-Bahrain#/media/File:Dilmun06.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Qal%27at_al-Bahrain',
          hasLandmark: true,
        },
        city: {
          name: 'Muharraq',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/muharraq-manama-bahrain-gulf-3439937/',
          wikiLink: 'https://en.wikipedia.org/wiki/Muharraq',
        },
        landmarksRound: [
          { code: 'LK', number: 1 },
          { code: 'CH', number: 2 },
          { code: 'KZ', number: 2 },
        ],
        landmarkPlaces: [
          'Monteverde Cloud Forest',
          'Amalienborg',
          'Church of Saint Lazarus',
          'Acropolis',
          'Kelonia',
          'Gediminas Castle Tower',
          'Narikala Fortress',
        ],
        uniqueId: 'tzghy2QV',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sunset-sundown-bahrain-600769/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain',
        },
        landmark: {
          name: 'Al Fateh Grand Mosque',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Al_Fateh_Grand_Mosque#/media/File:Manama_al-Fateh_Grand_Mosque_Exterior_Norden_3.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Al_Fateh_Grand_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Manama',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-night-bahrain-skyline-evening-6758248/',
          wikiLink: 'https://en.wikipedia.org/wiki/Manama',
        },
        landmarksRound: [
          { code: 'KR', number: 2 },
          { code: 'TH', number: 1 },
          { code: 'UZ', number: 2 },
        ],
        landmarkPlaces: [
          'Gediminas Castle Tower',
          'Tokyo Skytree',
          'Kasubi Royal Tombs',
          'Cologne Cathedral',
          'Stone House',
          'Registan Square',
          'Tavarua',
        ],
        uniqueId: 'rr7tSNd3',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Bahrain',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/rkU1mK53RBc',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain',
        },
        landmark: {
          name: 'Bahrain Financial Harbour',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bahrain_Financial_Harbour#/media/File:MOW_RD3.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain_Financial_Harbour',
          hasLandmark: true,
        },
        city: {
          name: 'Manama',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/eyefdS3LTE0',
          wikiLink: 'https://en.wikipedia.org/wiki/Manama',
        },
        landmarksRound: [
          { code: 'AT', number: 2 },
          { code: 'CU', number: 2 },
          { code: 'AZ', number: 1 },
        ],
        landmarkPlaces: [
          "Queen's Staircase",
          "St. Stephen's Cathedral",
          "St. John's Cathedral",
          'Kalandula Falls',
          'Gangtey Monastery',
          'One Foot Island',
          'Notre Dame of Bangui Cathedral',
        ],
        uniqueId: 'PJv3WKJD',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Bahrain',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/eNzpsGEJr7g',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain',
        },
        landmark: {
          name: 'Bab Al Bahrain',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bab_Al_Bahrain#/media/File:Bab_al_bahrain.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Bab_Al_Bahrain',
          hasLandmark: true,
        },
        city: {
          name: 'Riffa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Riffa#/media/File:RiffaFort2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Riffa',
        },
        landmarksRound: [
          { code: 'BD', number: 1 },
          { code: 'BG', number: 2 },
          { code: 'KH', number: 1 },
        ],
        landmarkPlaces: [
          'Madriu-Perafita-Claror Valley',
          'Sukhbaatar Square',
          'National Museum of Slavery',
          'Amberd',
          'Les Eclaireurs Lighthouse',
          'Herat Citadel',
          'National Park of American Samoa',
        ],
        uniqueId: '5tQqqnnD',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Bahrain',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-riding-on-white-boat-on-sea-during-daytime-NSp34_xvNrA',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain',
        },
        landmark: {
          name: 'Riffa Fort',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Riffa_Riffa_Fort_Exterior_10.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Riffa_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Manama',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-tall-buildings-4PBfuJs-lrU',
          wikiLink: 'https://en.wikipedia.org/wiki/Manama',
        },
        landmarksRound: [
          { code: 'VG', number: 4 },
          { code: 'YE', number: 4 },
          { code: 'IQ', number: 4 },
        ],
        landmarkPlaces: [
          'Mount Karthala',
          "Vianden's Castle",
          'House of Slaves',
          'Pula Arena',
          'Golden Bridge ',
          'Pyramids of Meroe',
          'Nassau Public Library',
        ],
        uniqueId: '4BHd3nqC',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bahrain',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-blue-shirt-sitting-on-brown-wooden-dock-during-daytime-UiLznQavBkA',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahrain',
        },
        landmark: {
          name: 'Al Fateh Grand Mosque',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jacobs_cb/5204301553/',
          wikiLink: 'https://en.wikipedia.org/wiki/Al_Fateh_Grand_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Riffa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/28682226@N05/28714148856/',
          wikiLink: 'https://en.wikipedia.org/wiki/Riffa',
        },
        landmarksRound: [
          { code: 'MT', number: 1 },
          { code: 'IM', number: 4 },
          { code: 'EH', number: 2 },
        ],
        landmarkPlaces: [
          'Notre Dame of Bangui Cathedral',
          'Fort Montagu',
          'Lagoa Azul Lighthouse',
          'Rock of Cashel',
          'Dziani Dzaha',
          'Tikal Temple I',
          'Catedral da Sé',
        ],
        uniqueId: 'gwFrCSJz',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    capital: 'Dhaka',
    lat: 24,
    long: 90,
    cities: [
      'Chattogram',
      'Narayanganj',
      'Khulna',
      'Sylhet',
      'Rangpur',
      'Sonargaon',
      'Dhaka',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/DWoOJ2C2uns',
          wikiLink: 'https://en.wikipedia.org/wiki/Sonargaon',
        },
        landmark: {
          name: 'Somapura Mahavihara',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Somapura_Mahavihara#/media/File:%E0%A6%AA%E0%A6%BE%E0%A6%B9%E0%A6%BE%E0%A6%A1%E0%A6%BC%E0%A6%AA%E0%A7%81%E0%A6%B0_%E0%A6%AC%E0%A7%8C%E0%A6%A6%E0%A7%8D%E0%A6%A7_%E0%A6%AC%E0%A6%BF%E0%A6%B9%E0%A6%BE%E0%A6%B0_22.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Somapura_Mahavihara',
          hasLandmark: true,
        },
        city: {
          name: 'Chattogram',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Chittagong#/media/File:Shah_Amanat_763.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Chittagong',
        },
        landmarksRound: [
          { code: 'IT', number: 2 },
          { code: 'HU', number: 1 },
          { code: 'IR', number: 2 },
        ],
        landmarkPlaces: [
          'Burj Khalifa',
          'Dubai Frame',
          "Prince's Palace of Monaco",
          'Chillon Castle',
          'Baiterek',
          'Silver Pagoda',
          'Voortrekker Monument',
        ],
        uniqueId: 'c3SCD8f6',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bangladesh-lake-boat-green-boat-3543466/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bangladesh',
        },
        landmark: {
          name: 'Dhakeshwari Temple',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dhakeshwari_Temple#/media/File:Shiva_temples_Dhakeshwari_Mandir_2_by_Ragib_Hasan.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dhakeshwari_Temple',
          hasLandmark: true,
        },
        city: {
          name: 'Narayanganj',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Narayanganj#/media/File:Skyline_in_Narayanganj_(02).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Narayanganj',
        },
        landmarksRound: [
          { code: 'FI', number: 1 },
          { code: 'CH', number: 1 },
          { code: 'TH', number: 1 },
        ],
        landmarkPlaces: [
          'Cape of Good Hope',
          'Amber Palace',
          'Skopje Fortress',
          'St. Alexander Nevsky Cathedral',
          'Deadvlei',
          'Al Fateh Grand Mosque',
          'Merry Cemetery',
        ],
        uniqueId: '73xwgnhl',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Sirajganj District',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/AMqrW1Sxx3g',
          wikiLink: 'https://en.wikipedia.org/wiki/Sirajganj_District',
        },
        landmark: {
          name: 'Sundarbans',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/qay3lNDSHzc',
          wikiLink: 'https://en.wikipedia.org/wiki/Sundarbans',
          hasLandmark: true,
        },
        city: {
          name: 'Sonargaon',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/DWoOJ2C2uns',
          wikiLink: 'https://en.wikipedia.org/wiki/Sonargaon',
        },
        landmarksRound: [
          { code: 'AT', number: 2 },
          { code: 'AS', number: 4 },
          { code: 'BS', number: 4 },
        ],
        landmarkPlaces: [
          'Charles Bridge',
          'Christmas Island National Park',
          'Bellapais Abbey',
          'Noravank Monastery',
          'Atomium',
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Band-e Amir National Park',
        ],
        uniqueId: 'fzBGpWVc',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Chittagong District',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gc8IPK97-hE',
          wikiLink: 'https://en.wikipedia.org/wiki/Chittagong_District',
        },
        landmark: {
          name: 'University of Dhaka',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Zxx8wDTgia4',
          wikiLink: 'https://en.wikipedia.org/wiki/University_of_Dhaka',
          hasLandmark: true,
        },
        city: {
          name: 'Khulna',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/5XKdv7P4t08',
          wikiLink: 'https://en.wikipedia.org/wiki/Khulna',
        },
        landmarksRound: [
          { code: 'BT', number: 1 },
          { code: 'CR', number: 2 },
          { code: 'AW', number: 3 },
        ],
        landmarkPlaces: [
          'Pulu Keeling National Park',
          'Grand Mosque of Bobo-Dioulasso',
          'Fort Montagu',
          'Narikala Fortress',
          'Pendjari National Park',
          'Madriu-Perafita-Claror Valley',
          'Amber Palace',
        ],
        uniqueId: 'SmdTCMXv',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Dhaka',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-busy-city-street-with-lots-of-traffic-8yHHqi3d0pM',
          wikiLink: 'https://en.wikipedia.org/wiki/Dhaka',
        },
        landmark: {
          name: "National Martyrs' Memorial",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/abir82/4280983799/',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_Martyrs%27_Memorial',
          hasLandmark: true,
        },
        city: {
          name: 'Dhaka',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bunch-of-boats-that-are-sitting-in-the-water-XkEt-Kux9k0',
          wikiLink: 'https://en.wikipedia.org/wiki/Dhaka',
        },
        landmarksRound: [
          { code: 'LR', number: 4 },
          { code: 'CN', number: 3 },
          { code: 'AL', number: 1 },
        ],
        landmarkPlaces: [
          'Solis Theater',
          'Pico Basile',
          'The Pearl Monument',
          'Fort Sao Sebastiao',
          'Fiat Tagliero',
          'Saqsayhuaman',
          "St. Stephen's Cathedral",
        ],
        uniqueId: '9yPFZyLY',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Manpura Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-field-with-a-body-of-water-in-the-background-swAnrMQlGx8',
          wikiLink: 'https://en.wikipedia.org/wiki/Manpura_Island',
        },
        landmark: {
          name: 'Lalbagh Fort',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/blese/15225423607/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lalbagh_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Sonargaon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-man-walking-down-a-sidewalk-next-to-a-body-of-water-XGa79DnJwaI',
          wikiLink: 'https://en.wikipedia.org/wiki/Sonargaon',
        },
        landmarksRound: [
          { code: 'EG', number: 2 },
          { code: 'FI', number: 3 },
          { code: 'MW', number: 1 },
        ],
        landmarkPlaces: [
          'Atafu',
          'Tsingoni Mosque',
          'Grand Mosque of Bobo-Dioulasso',
          'Salomons Atoll',
          'Silver Pagoda',
          'Solis Theater',
          'Islamic Center',
        ],
        uniqueId: 'Kp2gTrjf',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Barbados',
    code: 'BB',
    capital: 'Bridgetown',
    lat: 13.16666666,
    long: -59.53333333,
    cities: [
      'Bridgetown',
      'Bathsheba',
      'Speightstown',
      'Holetown',
      'Oistins',
      'Saint John',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-76qup1BmIs',
          wikiLink: 'https://en.wikipedia.org/wiki/Barbados',
        },
        landmark: {
          name: 'Animal Flower Cave',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Animal_Flower_Cave#/media/File:ANIMAL_FLOWER_CAVE_-_BARBADOS.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Animal_Flower_Cave',
          hasLandmark: true,
        },
        city: {
          name: 'Bridgetown',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/barbados-bridgetown-3925817/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bridgetown',
        },
        landmarksRound: [
          { code: 'PF', number: 1 },
          { code: 'BS', number: 2 },
          { code: 'JO', number: 1 },
        ],
        landmarkPlaces: [
          'Cape Coast Castle',
          'Olavinlinna',
          'Niagara Falls',
          'Pamir Mountains',
          'Mount Kenya',
          'The Marble Mountains',
          'Predjama Castle',
        ],
        uniqueId: 'hWm3Jqtg',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/clearwater-villa-beach-barbados-1549544/',
          wikiLink: 'https://en.wikipedia.org/wiki/Barbados',
        },
        landmark: {
          name: 'George Washington House',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/George_Washington_House_(Barbados)#/media/File:GEORGE_WASHINGTON_HOUSE_-_BARBADOS.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/George_Washington_House_(Barbados)',
          hasLandmark: true,
        },
        city: {
          name: 'Bathsheba',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bathsheba,_Barbados#/media/File:Bathsheba,_Barbados_08.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bathsheba,_Barbados',
        },
        landmarksRound: [
          { code: 'ME', number: 1 },
          { code: 'EC', number: 1 },
          { code: 'AT', number: 1 },
        ],
        landmarkPlaces: [
          'Boudhanath',
          'Roman theatre',
          'Vaduz Cathedral',
          'Mount Kenya',
          'Patuxay Monument',
          'Shwedagon Pagoda',
          'Monday Falls',
        ],
        uniqueId: '6ZKpk5j9',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Bridgetown',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/qugWVn01mF4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bridgetown',
        },
        landmark: {
          name: 'Codrington College',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Codrington_College#/media/File:Codrington_College.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Codrington_College',
          hasLandmark: true,
        },
        city: {
          name: 'Holetown',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/t0p25Scs24I',
          wikiLink: 'https://en.wikipedia.org/wiki/Holetown',
        },
        landmarksRound: [
          { code: 'BN', number: 1 },
          { code: 'CY', number: 2 },
          { code: 'AD', number: 1 },
        ],
        landmarkPlaces: [
          'Mount Cameroon',
          'The Blue Eye',
          'Pulu Keeling National Park',
          'Chimi Lhakhang',
          'Wallblake House',
          'La Moneda Palace',
          'Valle de Cocora',
        ],
        uniqueId: 'DkZpRxQs',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Barbados',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/20_OGkT-gl8',
          wikiLink: 'https://en.wikipedia.org/wiki/Barbados',
        },
        landmark: {
          name: 'St. Nicholas Abbey Heritage Railway',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/St._Nicholas_Abbey_Heritage_Railway#/media/File:SNAHR-train-view.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Nicholas_Abbey_Heritage_Railway',
          hasLandmark: true,
        },
        city: {
          name: 'Bridgetown',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/OTNxOUyIcHA',
          wikiLink: 'https://en.wikipedia.org/wiki/Bridgetown',
        },
        landmarksRound: [
          { code: 'CU', number: 1 },
          { code: 'BG', number: 1 },
          { code: 'AG', number: 4 },
        ],
        landmarkPlaces: [
          'Tsodilo Hills',
          'Arenal Volcano',
          'Great Blue Hole',
          'Notre Dame of Bangui Cathedral',
          'Pula Arena',
          'Chimi Lhakhang',
          'Kastelholm Castle',
        ],
        uniqueId: 'yYKNnrXR',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Barbados',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-person-standing-in-an-alley-between-two-buildings-BZJSzvLsxuA',
          wikiLink: 'https://en.wikipedia.org/wiki/Barbados',
        },
        landmark: {
          name: 'Harrison Point Lighthouse',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-light-house-sitting-on-top-of-a-lush-green-field-sAGsqLqvlE8',
          wikiLink: 'https://en.wikipedia.org/wiki/Harrison_Point_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Bridgetown',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-blue-building-with-a-purple-door-and-window-qGodnfzznW0',
          wikiLink: 'https://en.wikipedia.org/wiki/Bridgetown',
        },
        landmarksRound: [
          { code: 'TR', number: 4 },
          { code: 'ME', number: 3 },
          { code: 'KZ', number: 3 },
        ],
        landmarkPlaces: [
          'Charles Bridge',
          'Valle de la Luna',
          'Lake Piso',
          'Easter Island',
          'The Blue Mosque',
          'High Knoll Fort',
          'Plaza Francia',
        ],
        uniqueId: 'TQvGtLgc',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Barbados',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-horses-standing-in-a-body-of-water-rV0nFGJQM2E',
          wikiLink: 'https://en.wikipedia.org/wiki/Barbados',
        },
        landmark: {
          name: 'Gun Hill Signal Station',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/vikingman/3043996543/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gun_Hill_Signal_Station',
          hasLandmark: true,
        },
        city: {
          name: 'Speightstown',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/jor-el226/2858713302/in/photolist-5mBDSo-arbX1m-9jjdde-ar9hMM-67iTCP-67iSYg-dQMgw6-9jnkFQ-9jnmxy-9jjdXF-67o5EQ-9jjeTz-9jnkyo-dQSLio-9jjdsk-Np7sao-arbW1W-MuATsY-9jnm8b-MZYCo7-ETcN7L-MZYBfq-Np7uqf-dQSMzG-MZYD95-7vRGdW-7vMT14-oXidrp-JhQizU-Np7sH7-95v1jD-MuASEf-S1uPCy-dy41sj-95v9gD-s8GC9Z-5CRtFj-95v1Lp-7DvC87-95ybaQ-95v2Dt-95y9Z5-dy42cs-95v5Y6-95y4yj-95y9Tm-95v944-95v2Vn-9jnmg5-Np7tbG',
          wikiLink: 'https://en.wikipedia.org/wiki/Speightstown',
        },
        landmarksRound: [
          { code: 'BD', number: 6 },
          { code: 'EH', number: 3 },
          { code: 'AE', number: 1 },
        ],
        landmarkPlaces: [
          'Juche Tower',
          'Cap 110',
          'Panfilov Park',
          "Notre-Dame d'Afrique",
          'Milwane Wildlife Sanctuary',
          'Colo-i-Suva Forest Reserve',
          'The Pinnacles Desert',
        ],
        uniqueId: 'JGFM5LBb',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Belarus',
    code: 'BY',
    capital: 'Minsk',
    lat: 53,
    long: 28,
    cities: ['Grodno', 'Minsk', 'Gomel', 'Mogilev', 'Pinsk', 'Vitebsk'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NxFNWUMxnGM',
          wikiLink: 'https://en.wikipedia.org/wiki/Minsk',
        },
        landmark: {
          name: 'Mir Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mir-castle-complex-belarus-castle-6823633/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mir_Castle_Complex',
          hasLandmark: true,
        },
        city: {
          name: 'Grodno',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/belarus-sunrise-grodno-city-7100477/',
          wikiLink: 'https://en.wikipedia.org/wiki/Grodno',
        },
        landmarksRound: [
          { code: 'AG', number: 1 },
          { code: 'CY', number: 1 },
          { code: 'NZ', number: 2 },
        ],
        landmarkPlaces: [
          'Rila Monastery',
          'Queen Emma Bridge',
          'Tikal National Park',
          'Angel Falls',
          'Island of Mozambique',
          'Tash Rabat',
          'Fort Fincastle',
        ],
        uniqueId: '2z8BC2jp',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vilino-belarus-landscape-scenic-107571/',
          wikiLink: 'https://en.wikipedia.org/wiki/Belarus',
        },
        landmark: {
          name: 'Brest Fortress',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Brest_Fortress#/media/File:Brest_Brest_Fortress_Kholm_Gate_9209_2150.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Brest_Fortress',
          hasLandmark: true,
        },
        city: {
          name: 'Minsk',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/minsk-belarus-dji-europe-4420398/',
          wikiLink: 'https://en.wikipedia.org/wiki/Minsk',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'WtQCk44t',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Belarus',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GfTJ0ElQ-3U',
          wikiLink: 'https://en.wikipedia.org/wiki/Belarus',
        },
        landmark: {
          name: 'Belovezhskaya Pushcha National Park',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Bielavie%C5%BEskaja_Pu%C5%A1%C4%8Da_National_Park#/media/File:BelarusBNP05.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Belovezhskaya_Pushcha_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Vitebsk',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/OKjprSD2zr0',
          wikiLink: 'https://en.wikipedia.org/wiki/Vitebsk',
        },
        landmarksRound: [
          { code: 'BG', number: 2 },
          { code: 'AR', number: 1 },
          { code: 'BH', number: 2 },
        ],
        landmarkPlaces: [
          'Queen Emma Bridge',
          'Kalandula Falls',
          'University of Dhaka',
          'Xunatunich Mayan Ruins',
          'Al Fateh Grand Mosque',
          'Oriental Pearl TV Tower',
          'One Foot Island',
        ],
        uniqueId: 'LQz29HFP',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Belarus',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/jxNWI-_tbf0',
          wikiLink: 'https://en.wikipedia.org/wiki/Belarus',
        },
        landmark: {
          name: 'Nesvizh Castle',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/M2SwuSYH2MQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Nesvizh_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Gomel',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/J_ejroGhNsE',
          wikiLink: 'https://en.wikipedia.org/wiki/Gomel',
        },
        landmarksRound: [
          { code: 'AL', number: 4 },
          { code: 'KH', number: 2 },
          { code: 'BB', number: 1 },
        ],
        landmarkPlaces: [
          'Oriental Pearl TV Tower',
          'Sukhbaatar Square',
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Grand Mosque of Bobo-Dioulasso',
          'One Foot Island',
          'Arenal Volcano',
          'Kalandula Falls',
        ],
        uniqueId: 'JvjHVF65',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Minsk',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-car-parked-in-front-of-a-building-P8IgjgxMNj4',
          wikiLink: 'https://en.wikipedia.org/wiki/Minsk',
        },
        landmark: {
          name: 'Mir Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/63377227@N05/35526779430/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mir_Castle_Complex',
          hasLandmark: true,
        },
        city: {
          name: 'Minsk',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/vehicles-on-road-near-buildings-OGFL6XiV4kg',
          wikiLink: 'https://en.wikipedia.org/wiki/Minsk',
        },
        landmarksRound: [
          { code: 'QA', number: 1 },
          { code: 'GE', number: 1 },
          { code: 'BF', number: 4 },
        ],
        landmarkPlaces: [
          'Jazeera Beach',
          'House of the Black Heads',
          'Sistine Chapel',
          'Mlilwane Wildlife Sanctuary',
          'National Museum of Costume',
          'English Harbour',
          'Rock Islands',
        ],
        uniqueId: 'fJzYkgCG',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Belarus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-train-station-during-daytime-MRMnkiJE7nA',
          wikiLink: 'https://en.wikipedia.org/wiki/Belarus',
        },
        landmark: {
          name: 'Church of Saints Simon and Helena',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/guinness_europe/21029433485/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Church_of_Saints_Simon_and_Helena',
          hasLandmark: true,
        },
        city: {
          name: 'Gomel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-a-river-running-through-it-EJAhqAUypNs',
          wikiLink: 'https://en.wikipedia.org/wiki/Gomel',
        },
        landmarksRound: [
          { code: 'WF', number: 1 },
          { code: 'CH', number: 1 },
          { code: 'TN', number: 3 },
        ],
        landmarkPlaces: [
          'Brandenburg Gate',
          'Valle de la Luna',
          'Pukapuka',
          'Tavarua',
          'Topkapı Palace',
          'Mosquée Al-Hamoudi',
          'Monteverde Cloud Forest',
        ],
        uniqueId: '7xQg2LbC',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Belgium',
    code: 'BE',
    capital: 'Brussels',
    lat: 50.83333333,
    long: 4,
    cities: [
      'Antwerp',
      'Ghent',
      'Leuven',
      'Namur',
      'Bruges',
      'Dinant',
      'Brussels',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/_BBlUZhRzjg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bruges',
        },
        landmark: {
          name: 'Atomium',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/atomium-brussels-travel-landmark-3590775/',
          wikiLink: 'https://en.wikipedia.org/wiki/Atomium',
          hasLandmark: true,
        },
        city: {
          name: 'Bruges',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/brugge-bruges-belgium-architecture-5278796/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bruges',
        },
        landmarksRound: [
          { code: 'MQ', number: 1 },
          { code: 'BH', number: 1 },
          { code: 'IN', number: 2 },
        ],
        landmarkPlaces: [
          'Rock of Cashel',
          'Sule Pagoda',
          'Predjama Castle',
          'Epupa Falls',
          'Wieliczka Salt Mine',
          'Erbil Citadel',
          'Vianden Castle',
        ],
        uniqueId: 'jnQRnDY6',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/brugge-bruges-belgium-architecture-5278796/',
          wikiLink: 'https://en.wikipedia.org/wiki/Belgium',
        },
        landmark: {
          name: 'Gravensteen',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/gent-belgium-ghent-flanders-castle-4778045/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gravensteen',
          hasLandmark: true,
        },
        city: {
          name: 'Antwerp',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/antwerp-belgium-clouds-architecture-1665194/',
          wikiLink: 'https://en.wikipedia.org/wiki/Antwerp',
        },
        landmarksRound: [
          { code: 'PK', number: 1 },
          { code: 'ES', number: 2 },
          { code: 'LA', number: 2 },
        ],
        landmarkPlaces: [
          "Dunn's River Falls",
          'Castle Rushden',
          'Olavinlinna',
          'Deadvlei',
          'Ulu Temburong National Park',
          'Borobudur Temple',
          'Neveh Shalom Synagogue',
        ],
        uniqueId: 'WykRxlk8',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Bruges',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/H6BtRELIN8U',
          wikiLink: 'https://en.wikipedia.org/wiki/Bruges',
        },
        landmark: {
          name: 'Cinquantenaire Arcade',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/m39OKAexaqo',
          wikiLink: 'https://en.wikipedia.org/wiki/Cinquantenaire_Arcade',
          hasLandmark: true,
        },
        city: {
          name: 'Dinant',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/3lxrM5yvkcI',
          wikiLink: 'https://en.wikipedia.org/wiki/Dinant',
        },
        landmarksRound: [
          { code: 'BS', number: 4 },
          { code: 'BD', number: 2 },
          { code: 'BW', number: 2 },
        ],
        landmarkPlaces: [
          'Mostar Old Bridge',
          'Qargha Reservoir',
          'Middle of the World City',
          'Turku Castle',
          "St. John's Cathedral",
          'Amalienborg',
          'Boali',
        ],
        uniqueId: 'QHZQvkxg',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Leuven',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ZPpC98Vus3k',
          wikiLink: 'https://en.wikipedia.org/wiki/Leuven',
        },
        landmark: {
          name: 'Villers Abbey',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/PKR3rqTXaCU',
          wikiLink: 'https://en.wikipedia.org/wiki/Villers_Abbey',
          hasLandmark: true,
        },
        city: {
          name: 'Ghent',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/bKYVb8IW_7U',
          wikiLink: 'https://en.wikipedia.org/wiki/Ghent',
        },
        landmarksRound: [
          { code: 'AR', number: 1 },
          { code: 'AX', number: 1 },
          { code: 'AX', number: 4 },
        ],
        landmarkPlaces: [
          'Maralgöl',
          'Mir Castle',
          'Basilica del Voto Nacional',
          'Bab Al Bahrain',
          'Casa de la Vall',
          'Elephant Island',
          'The Blue Eye',
        ],
        uniqueId: 'GFvdjZdL',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Brussels',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-and-black-vehicles-parked-beside-road-p0oC-D6rbWs',
          wikiLink: 'https://en.wikipedia.org/wiki/Brussels',
        },
        landmark: {
          name: "St Bavo's Cathedral, Ghent",
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-gray-church-PRvDgB3lg2w',
          wikiLink:
            'https://en.wikipedia.org/wiki/St_Bavo%27s_Cathedral,_Ghent',
          hasLandmark: true,
        },
        city: {
          name: 'Bruges',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-buildings-near-bodies-of-water-T6hnY1p4q3I',
          wikiLink: 'https://en.wikipedia.org/wiki/Bruges',
        },
        landmarksRound: [
          { code: 'BD', number: 6 },
          { code: 'KW', number: 2 },
          { code: 'TZ', number: 1 },
        ],
        landmarkPlaces: [
          'Basilica of Our Lady of Guadalupe',
          'Gangtey Monastery',
          'Turtle Cove',
          'Emerald Pool',
          'Kpatawee Waterfall',
          'Seven Coloured Earths',
          'Monte Carlo Casino',
        ],
        uniqueId: 'JQfPm9BN',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bruges',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-yellow-concrete-houses-at-daytime-GMpcFjGbnMQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Bruges',
        },
        landmark: {
          name: 'Walzin Castle ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-castle-on-cliff-lRkltgUJQ-Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Walzin_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Ghent',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-under-cloudy-sky-during-daytime-4pufSoNI4jw',
          wikiLink: 'https://en.wikipedia.org/wiki/Ghent',
        },
        landmarksRound: [
          { code: 'CD', number: 4 },
          { code: 'TR', number: 4 },
          { code: 'BB', number: 3 },
        ],
        landmarkPlaces: [
          'Emily Bay',
          'Valle de la Luna',
          'Kamianets-Podilskyi Castle',
          'Marigot Bay',
          'Mount Alava',
          'Ancient Theatre of Epidaurus',
          'Niagara Falls',
        ],
        uniqueId: 'zSzmXygr',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Belize',
    code: 'BZ',
    capital: 'Belmopan',
    lat: 17.25,
    long: -88.75,
    cities: [
      'Belize City',
      'San Ignacio',
      'Belmopan',
      'San Pedro',
      'Punta Gorda',
      'Dangriga',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/erKKEmRLuV0',
          wikiLink: 'https://en.wikipedia.org/wiki/Ambergris_Caye',
        },
        landmark: {
          name: 'Xunatunich Mayan Ruins',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Xunantunich#/media/File:Friese,_El_Castillo,_Xunantunich_(7981048802).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Xunantunich',
          hasLandmark: true,
        },
        city: {
          name: 'Belize City',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/truck-overloaded-belize-city-road-7469016/',
          wikiLink: 'https://en.wikipedia.org/wiki/Belize_City',
        },
        landmarksRound: [
          { code: 'CH', number: 2 },
          { code: 'AU', number: 1 },
          { code: 'AD', number: 1 },
        ],
        landmarkPlaces: [
          'Monument to the Heroes of the Restoration',
          'Baiterek',
          'Elmina Castle',
          'Liwonde National Park',
          'Neveh Shalom Synagogue',
          'Hato Caves',
          'Hazrat Ali Mazar',
        ],
        uniqueId: 'JckdBp6M',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/water-creek-landscape-flow-hdr-7726729/',
          wikiLink: 'https://en.wikipedia.org/wiki/Belize',
        },
        landmark: {
          name: 'Great Blue Hole',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Great_Blue_Hole#/media/File:Great_Blue_Hole.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Blue_Hole',
          hasLandmark: true,
        },
        city: {
          name: 'San Ignacio',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/San_Ignacio,_Belize#/media/File:Burns_Ave_in_San_Ignacio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Ignacio,_Belize',
        },
        landmarksRound: [
          { code: 'BT', number: 1 },
          { code: 'KY', number: 2 },
          { code: 'PE', number: 1 },
        ],
        landmarkPlaces: [
          'Tenaru Falls',
          'Monte Carlo Casino',
          'Lukan Longshan Temple',
          "Queen's Staircase",
          'Grytviken Church',
          'Mostar Old Bridge',
          'Katse Dam',
        ],
        uniqueId: '2Dg4fyW5',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Belize',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/qVNSANBjYdI',
          wikiLink: 'https://en.wikipedia.org/wiki/Belize',
        },
        landmark: {
          name: 'Xunantunich Mayan Ruins',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Zr_oG8UlmVU',
          wikiLink: 'https://en.wikipedia.org/wiki/Xunantunich',
          hasLandmark: true,
        },
        city: {
          name: 'San Ignacio',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/aZikfy-3P9A',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Ignacio,_Belize',
        },
        landmarksRound: [
          { code: 'IO', number: 2 },
          { code: 'AO', number: 1 },
          { code: 'BY', number: 4 },
        ],
        landmarkPlaces: [
          'Brest Fortress',
          'Les Eclaireurs Lighthouse',
          'Fort Montagu',
          'Codrington College',
          'Valle de Cocora',
          'Cinquantenaire Arcade',
          'Gravensteen',
        ],
        uniqueId: '8wdz7VPD',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Belize',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/RJEfkvKv2pE',
          wikiLink: 'https://en.wikipedia.org/wiki/Belize',
        },
        landmark: {
          name: 'The Belize Zoo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Belize_Zoo#/media/File:DSC02803BeliZooUnderEnJaguar.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Belize_Zoo',
          hasLandmark: true,
        },
        city: {
          name: 'Belmopan',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Belmopan#/media/File:Belmopan_Parliament.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Belmopan',
        },
        landmarksRound: [
          { code: 'TD', number: 1 },
          { code: 'AL', number: 4 },
          { code: 'BT', number: 2 },
        ],
        landmarkPlaces: [
          'Perito Moreno Glacier',
          'Mostar Old Bridge',
          'Elephant Island',
          'Hazrat Ali Mazar',
          'Narikala Fortress',
          'Almsee',
          'Paradise Island',
        ],
        uniqueId: 'Y5K3MK45',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Progreso',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-green-grass-field-xcjLPRGgZ1A',
          wikiLink: 'https://en.wikipedia.org/wiki/Progreso,_Belize',
        },
        landmark: {
          name: 'Altun Ha',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-under-blue-sky-during-daytime-FK1-4TktstM',
          wikiLink: 'https://en.wikipedia.org/wiki/Altun_Ha',
          hasLandmark: true,
        },
        city: {
          name: 'Belize City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-dock-on-sea-under-blue-sky-during-daytime-mgXtdWmgzl0',
          wikiLink: 'https://en.wikipedia.org/wiki/Belize_City?variant=zh-tw',
        },
        landmarksRound: [
          { code: 'MC', number: 4 },
          { code: 'CH', number: 2 },
          { code: 'IS', number: 2 },
        ],
        landmarkPlaces: [
          'Bay of Kotor',
          'Skansen',
          'Brest Fortress',
          'Abu Simbel',
          'Fort Nuestra Senora de la Soledad',
          'Cheshire Hall Plantation',
          'Loango National Park',
        ],
        uniqueId: 'nnTvKxkt',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Orange Walk Town',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-sitting-on-bench-under-green-tree-during-daytime-9Kxu9Odz4yA',
          wikiLink: 'https://en.wikipedia.org/wiki/Orange_Walk_Town',
        },
        landmark: {
          name: 'Great Blue Hole ',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/sully_aka__wstera2/4642337100/',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Blue_Hole',
          hasLandmark: true,
        },
        city: {
          name: 'San Ignacio',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/blue-and-white-bus-on-road-during-night-time-vGCa1UmYO0s',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Ignacio,_Belize',
        },
        landmarksRound: [
          { code: 'OM', number: 1 },
          { code: 'NI', number: 2 },
          { code: 'NO', number: 1 },
        ],
        landmarkPlaces: [
          'Arc de Triomphe',
          'Fort Antoine Theatre',
          'Hwange National Park',
          'Shell Beach',
          'Macau Tower',
          'House of Slaves',
          'Our Lady of Ardilliers Catholic Church',
        ],
        uniqueId: 'L4DFzdfd',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Benin',
    code: 'BJ',
    capital: 'Porto-Novo',
    lat: 9.5,
    long: 2.25,
    cities: [
      'Parakou',
      'Cotonou',
      'Porto-Novo',
      'Abomey',
      'Natitingou',
      'Djougou',
      'Ouidah',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/benign-africa-child-black-2223164/',
          wikiLink: 'https://en.wikipedia.org/wiki/Benin',
        },
        landmark: {
          name: 'Pendjari National Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Pendjari_National_Park#/media/File:Pendjari_fluss.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Pendjari_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Porto-Novo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Porto-Novo#/media/File:Grande_Mosquee_Porto-Novo_Benin_Joseph_Herve_Ahissou.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Porto-Novo',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'fYyfNZ8S',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/benin-lake-africa-water-boat-2222345/',
          wikiLink: 'https://en.wikipedia.org/wiki/Benin',
        },
        landmark: {
          name: 'Porte du Non Retour',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:The_Door_of_No_Return_Ouidah_%285%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Door_of_No_Return,_Ouidah',
          hasLandmark: true,
        },
        city: {
          name: 'Cotonou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cotonou#/media/File:B%C3%A9nin_Cotonou_Place_of_Red_Star.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cotonou',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '3d2Pz5BP',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Benin',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/eIkHhNyAqqQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Benin',
        },
        landmark: {
          name: 'Great Mosque of Porto-Novo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Great_Mosque_of_Porto-Novo#/media/File:Grande_mosquee_porto-novo.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Mosque_of_Porto-Novo',
          hasLandmark: true,
        },
        city: {
          name: 'Parakou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Parakou#/media/File:Parakouroundabout.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Parakou',
        },
        landmarksRound: [
          { code: 'AT', number: 2 },
          { code: 'AO', number: 3 },
          { code: 'CO', number: 2 },
        ],
        landmarkPlaces: [
          'Amberd',
          'Bellapais Abbey',
          'Kalandula Falls',
          'Sydney Opera House',
          'Spittal Pond',
          'Maiden Tower',
          "St. Stephen's Cathedral",
        ],
        uniqueId: 'NDCxLBTb',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Benin',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/d3btU_HGuAU',
          wikiLink: 'https://en.wikipedia.org/wiki/Benin',
        },
        landmark: {
          name: 'Door of No Return, Ouidah',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Porte_du_non-retour#/media/Fichier:The_Door_of_No_Return_Ouidah_(5).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Door_of_No_Return,_Ouidah',
          hasLandmark: true,
        },
        city: {
          name: 'Cotonou',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/uld8dW3INwE',
          wikiLink: 'https://en.wikipedia.org/wiki/Cotonou',
        },
        landmarksRound: [
          { code: 'BM', number: 1 },
          { code: 'CN', number: 1 },
          { code: 'CL', number: 2 },
        ],
        landmarkPlaces: [
          'Herat Citadel',
          'Castillo De Los Tres Reyes Del Morro',
          'English Harbour',
          'Ouagadougou Cathedral',
          'St. Nicholas Abbey Heritage Railway',
          'University of Dhaka',
          'Amalienborg',
        ],
        uniqueId: 'twZg7QCg',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Ganvie',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/map-WLblwP3mfWg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ganvie',
        },
        landmark: {
          name: 'Great Mosque of Porto-Novo\n',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/itravelanddance/32112609811/in/photolist-yoyfa5-yepvQW-AR12ov-z97Lq5-ywU6zC-R7a4ez-QVFw7p-QwXwCu-R3LhPA-PSyJAp-QVFkyR-PPQv65-PSyJHt-ymUKLk-R3LvPy-QwXz2h-xStjKY',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Mosque_of_Porto-Novo',
          hasLandmark: true,
        },
        city: {
          name: 'Ouidah',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-white-building-with-a-clock-on-the-front-of-it-pam8f6ATIgI',
          wikiLink: 'https://en.wikipedia.org/wiki/Ouidah',
        },
        landmarksRound: [
          { code: 'MS', number: 2 },
          { code: 'CL', number: 3 },
          { code: 'BM', number: 3 },
        ],
        landmarkPlaces: [
          "National Martyrs' Memorial",
          'Gangtey Monastery',
          'Seven Coloured Earths',
          "Devil's Throat",
          'Petra',
          'Wat Arun',
          'Sri Siva Subramaniya Temple',
        ],
        uniqueId: '9vpbR7Vv',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bopa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-bridge-on-sunrise-tqkzjhnI9i8',
          wikiLink: 'https://en.wikipedia.org/wiki/Bopa',
        },
        landmark: {
          name: 'Etoile rouge',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/8734876@N07/30205197957/',
          wikiLink:
            'https://fr.wikipedia.org/wiki/Place_de_l%27%C3%89toile_rouge',
          hasLandmark: true,
        },
        city: {
          name: 'Porto-Novo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-under-cloudy-sky-during-daytime-kWPNxQyAT6M',
          wikiLink: 'https://en.wikipedia.org/wiki/Porto-Novo',
        },
        landmarksRound: [
          { code: 'NL', number: 1 },
          { code: 'ES', number: 3 },
          { code: 'CH', number: 2 },
        ],
        landmarkPlaces: [
          "Jacob's Ladder",
          'Angkor Wat',
          'Plateau Mosque',
          'Gullfoss',
          'Lake Piso',
          'Bahrain Fort',
          'Juche Tower',
        ],
        uniqueId: 'lrphXN3S',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Bermuda',
    code: 'BM',
    capital: 'Hamilton',
    lat: 32.33333333,
    long: -64.75,
    cities: [
      'Hamilton',
      "St. George's",
      'Somerset Village',
      'Flatts Village',
      'Devonshire',
      'Cotonou',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/0a17lg30MtY',
          wikiLink: '',
        },
        landmark: {
          name: 'Spittal Pond',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Spittal_Pond_Nature_Reserve#/media/File:Spittal_Pond.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Spittal_Pond_Nature_Reserve',
          hasLandmark: true,
        },
        city: {
          name: "St. George's",
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/St._George%27s,_Bermuda#/media/File:Ordnance_Island,_St._George's_Town,_and_Fort_George,_Bermuda.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Bermuda',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'R5vSp9H7',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bermuda-pink-sand-beach-ocean-4096704/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bermuda',
        },
        landmark: {
          name: 'Unfinished Church',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Unfinished_Church_2.jpg',
          wikiLink: '',
          hasLandmark: true,
        },
        city: {
          name: 'Hamilton',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Hamilton,_Bermuda#/media/File:Front_Street,_Hamilton,_Bermuda.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamilton,_Bermuda',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'jW9HN3PX',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Bermuda',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Ule2K_7IlV4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bermuda',
        },
        landmark: {
          name: 'National Museum of Bermuda',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/ocean-bermuda-blue-sky-tourism-3401278/',
          wikiLink: 'https://en.wikipedia.org/wiki/National_Museum_of_Bermuda',
          hasLandmark: true,
        },
        city: {
          name: 'Hamilton',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Hamilton,_Bermuda#/media/File:Front_Street,_Hamilton,_Bermuda.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamilton,_Bermuda',
        },
        landmarksRound: [
          { code: 'AT', number: 4 },
          { code: 'AR', number: 4 },
          { code: 'AW', number: 4 },
        ],
        landmarkPlaces: [
          'Fort James',
          'Emi Koussi',
          "Diocletian's Palace",
          'Olavinlinna',
          'Uluru',
          'La Moneda Palace',
          'Heydar Aliyev Center',
        ],
        uniqueId: 'n9Ydklst',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Bermuda',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/eS8CVXBA3jU',
          wikiLink: 'https://en.wikipedia.org/wiki/Bermuda',
        },
        landmark: {
          name: 'Gibbs Hill Lighthouse',
          photographer: '',
          imageLink:
            "https://commons.wikimedia.org/wiki/Category:Gibbs_Hill_Lighthouse#/media/File:View_of_Gibbs_Hill_Lighthouse_from_St._Anne's_Road.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Gibbs_Hill_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: "St. George's",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/St._George%27s,_Bermuda#/media/File:Bermuda-Harbour_and_Town_of_St_George.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Bermuda',
        },
        landmarksRound: [
          { code: 'AI', number: 4 },
          { code: 'BG', number: 1 },
          { code: 'BY', number: 3 },
        ],
        landmarkPlaces: [
          'South Pole',
          'Matafao Peak',
          'Somapura Mahavihara',
          'Madriu-Perafita-Claror Valley',
          'Taj Mahal',
          'Oriental Pearl TV Tower',
          'Amber Palace',
        ],
        uniqueId: 'jlF2R8yk',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Bermuda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-sea-during-daytime-COsskg4ilTQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Bermuda',
        },
        landmark: {
          name: 'National Museum of Bermuda',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/96225726@N08/49549823878/',
          wikiLink: 'https://en.wikipedia.org/wiki/National_Museum_of_Bermuda',
          hasLandmark: true,
        },
        city: {
          name: 'Hamilton',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/duluoz_cats/49674484722/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamilton,_Bermuda',
        },
        landmarksRound: [
          { code: 'PA', number: 2 },
          { code: 'SA', number: 2 },
          { code: 'SB', number: 2 },
        ],
        landmarkPlaces: [
          "Tassili n'Ajjer",
          'Peel Castle',
          'Dziani Dzaha',
          'Santa Catalina Arch',
          'Niagara Falls',
          'Kamianets-Podilskyi Castle',
          'Sundarbans',
        ],
        uniqueId: 'w6KDDJbR',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bermuda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-sky-over-the-beach-apdbXDriykI',
          wikiLink: 'https://en.wikipedia.org/wiki/Bermuda',
        },
        landmark: {
          name: 'Gibbs Hill Lighthouse',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/vincegoudreault/32808335315/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gibbs_Hill_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: "St. George's",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/11086755@N00/52560464150/',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Bermuda',
        },
        landmarksRound: [
          { code: 'AM', number: 5 },
          { code: 'LU', number: 3 },
          { code: 'GA', number: 1 },
        ],
        landmarkPlaces: [
          'Sundarbans',
          'Muliaage',
          'National Museum of Montserrat',
          'Xunatunich Mayan Ruins',
          'Machu Picchu',
          'Christ the Redeemer',
          'Deception Island',
        ],
        uniqueId: 'LJZsvTlY',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Bhutan',
    code: 'BT',
    capital: 'Thimphu',
    lat: 27.5,
    long: 90.5,
    cities: [
      'Thimphu',
      'Paro',
      'Gelephu',
      'Punakha',
      'Wangdue Phodrang',
      'Jakar',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ZdwVvRdel8A',
          wikiLink: 'https://en.wikipedia.org/wiki/Paro_Taktsang',
        },
        landmark: {
          name: 'Chimi Lhakhang',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Chimi_Lhakhang#/media/File:Chimi_Lhakhang,_Bhutan_02.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Chimi_Lhakhang',
          hasLandmark: true,
        },
        city: {
          name: 'Thimphu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Thimphu#/media/File:Thimphu_view_080907.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Thimphu',
        },
        landmarksRound: [
          { code: 'IN', number: 2 },
          { code: 'HN', number: 1 },
          { code: 'NA', number: 2 },
        ],
        landmarkPlaces: [
          'Rizal Park',
          'Kim Il Sung Square',
          'South Pole (ceremonial)',
          'Masjid al-Haram',
          'Umayyad Mosque',
          'Stone House',
          'Kittiwake Shipwreck',
        ],
        uniqueId: '2zXT6jWH',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/temple-monastery-cliff-cliffside-171377/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bhutan',
        },
        landmark: {
          name: 'Gangtey Monastery',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Gangteng_Monastery#/media/File:Main_Temple_Gangte_Goemba.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gangteng_Monastery',
          hasLandmark: true,
        },
        city: {
          name: 'Paro',
          photographer: '',
          imageLink:
            'https://upload.wikimedia.org/wikipedia/commons/6/68/Paro%2C_Paro%2C_Bhutan_%288026008882%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Paro,_Bhutan',
        },
        landmarksRound: [
          { code: 'AZ', number: 1 },
          { code: 'GL', number: 1 },
          { code: 'DZ', number: 1 },
        ],
        landmarkPlaces: [
          "Dunn's River Falls",
          'Boudhanath',
          'Mount Kenya',
          'Chichen Itza',
          'Arecibo Observatory',
          'Tavarua',
          'Tian Tan Buddha',
        ],
        uniqueId: 'bW9BnWFG',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Bhutan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/TitqIqZPcIY',
          wikiLink: 'https://en.wikipedia.org/wiki/Bhutan',
        },
        landmark: {
          name: "Tiger's Nest",
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ZdwVvRdel8A',
          wikiLink: 'https://en.wikipedia.org/wiki/Paro_Taktsang',
          hasLandmark: true,
        },
        city: {
          name: 'Thimphu',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vd3_HFi29Fo',
          wikiLink: 'https://en.wikipedia.org/wiki/Thimphu',
        },
        landmarksRound: [
          { code: 'AW', number: 2 },
          { code: 'AL', number: 1 },
          { code: 'BD', number: 2 },
        ],
        landmarkPlaces: [
          'White Bay Bar',
          'Hofburg Palace',
          'Noravank Monastery',
          'Twelve Apostles',
          'We Are Our Mountains',
          'Roman ruins in Batna',
          'Source du Nil',
        ],
        uniqueId: 'JbVZXr6k',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Bhutan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/JoIx9FWaX38',
          wikiLink: 'https://en.wikipedia.org/wiki/Bhutan',
        },
        landmark: {
          name: 'Buddha Dordenma statue',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/VppPdgsIXks',
          wikiLink: 'https://en.wikipedia.org/wiki/Buddha_Dordenma_statue',
          hasLandmark: true,
        },
        city: {
          name: 'Punakha',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/MewkgjUKB9g',
          wikiLink: 'https://en.wikipedia.org/wiki/Punakha',
        },
        landmarksRound: [
          { code: 'AF', number: 2 },
          { code: 'BS', number: 1 },
          { code: 'CV', number: 1 },
        ],
        landmarkPlaces: [
          'Arenal Volcano',
          "Diocletian's Palace",
          'Lake Kivu',
          'National Park of American Samoa',
          'Nesvizh Castle',
          'Pendjari National Park',
          "Tassili n'Ajjer",
        ],
        uniqueId: 'VTfmYHWh',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Thimphu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-on-green-grass-field-under-white-clouds-during-daytime-AnH_qAkPl6s',
          wikiLink: 'https://en.wikipedia.org/wiki/Thimphu',
        },
        landmark: {
          name: 'Punakha Dzong',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-near-green-trees-and-lake-during-daytime-uMbGXBy7bKs',
          wikiLink: 'https://en.wikipedia.org/wiki/Punakha_Dzong',
          hasLandmark: true,
        },
        city: {
          name: 'Thimphu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-street-with-cars-parked-along-it-r4VN0MsSeRM',
          wikiLink: 'https://en.wikipedia.org/wiki/Thimphu',
        },
        landmarksRound: [
          { code: 'EE', number: 4 },
          { code: 'SO', number: 2 },
          { code: 'MR', number: 4 },
        ],
        landmarkPlaces: [
          'Saunders Island',
          'Jazeera Beach',
          'Iluissat Icefjord',
          'National Museum of Bermuda',
          'Singapore Flyer',
          'Seven Rile Lakes',
          'Jesuit Mission of the Holy Trinity',
        ],
        uniqueId: 'GpcQBxW9',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bhutan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-grass-field-during-daytime-BWud63Wxs5Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Bhutan',
        },
        landmark: {
          name: 'Dochula Pass',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/135214045@N08/49438519487/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dochula_Pass',
          hasLandmark: true,
        },
        city: {
          name: 'Punakha',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-dirt-road-in-front-of-a-row-of-buildings-R6R4_hEL7vs',
          wikiLink: 'https://en.wikipedia.org/wiki/Punakha',
        },
        landmarksRound: [
          { code: 'IT', number: 1 },
          { code: 'LT', number: 3 },
          { code: 'IN', number: 2 },
        ],
        landmarkPlaces: [
          'Baiterek',
          'Azadi Tower',
          'Neuschwanstein Castle',
          'Potala Palace',
          'Porte du Non Retour',
          'Fortaleza de São José da Amura',
          'National Park of American Samoa',
        ],
        uniqueId: 'gMhvg8wb',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Bolivia',
    code: 'BO',
    capital: 'Sucre',
    lat: -17,
    long: -65,
    cities: [
      'Sucre',
      'Santa Cruz de la Sierra',
      'La Paz',
      'El Alto',
      'Cochabamba',
      'Oruro',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Y_Z-ltvMjdg',
          wikiLink: 'https://en.wikipedia.org/wiki/Salar_de_Uyuni',
        },
        landmark: {
          name: 'Uyuni Salt Flat',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/salt-lake-salt-minerals-594609/',
          wikiLink: 'https://en.wikipedia.org/wiki/Salar_de_Uyuni',
          hasLandmark: true,
        },
        city: {
          name: 'Santa Cruz de la Sierra',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/santa-cruz-de-la-sierra-bolivia-4797349/',
          wikiLink: 'https://en.wikipedia.org/wiki/Santa_Cruz_de_la_Sierra',
        },
        landmarksRound: [
          { code: 'CO', number: 1 },
          { code: 'CY', number: 1 },
          { code: 'UY', number: 1 },
        ],
        landmarkPlaces: [
          'Vaduz Cathedral',
          'Leaning Tower of Pisa',
          "Memorial de l'Anse",
          'Mount Paget',
          'Xunatunich Mayan Ruins',
          'Wat Xiengthong',
          'Vianden Castle',
        ],
        uniqueId: 'l7bMnjXT',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/desert-landscape-sand-mountain-4388204/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolivia',
        },
        landmark: {
          name: 'Plaza Murillo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Plaza_Murillo#/media/File:Plaza_Murillo-Palacio_and_Catedral.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Plaza_Murillo',
          hasLandmark: true,
        },
        city: {
          name: 'La Paz',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/la-paz-bolivia-south-america-city-458029/',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Paz',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'dQrhDbx3',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Bolivia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8Fs2u-4SKrs',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolivia',
        },
        landmark: {
          name: 'Salar de Uyuni',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/qchr30s1R4c',
          wikiLink: 'https://en.wikipedia.org/wiki/Salar_de_Uyuni',
          hasLandmark: true,
        },
        city: {
          name: 'La Paz',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ahAHZzVEEjo',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Paz',
        },
        landmarksRound: [
          { code: 'BE', number: 3 },
          { code: 'AX', number: 4 },
          { code: 'AR', number: 1 },
        ],
        landmarkPlaces: [
          'Schlossberg (Graz)',
          'Bahrain Fort',
          'Natural Pool',
          'Dolly Beach',
          'Viewpoint of the Moon',
          'Lake Yoa',
          'Twelve Apostles',
        ],
        uniqueId: 'v2zkYDDW',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Bolivia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NGjDM-XBc-c',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolivia',
        },
        landmark: {
          name: 'Illimani',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/No6mIqzvq5o',
          wikiLink: 'https://en.wikipedia.org/wiki/Illimani',
          hasLandmark: true,
        },
        city: {
          name: 'Cochabamba',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cochabamba#/media/File:Vista_Aerea_de_la_Ciudad_de_Cochabamba.png',
          wikiLink: 'https://en.wikipedia.org/wiki/Cochabamba',
        },
        landmarksRound: [
          { code: 'BI', number: 2 },
          { code: 'TD', number: 1 },
          { code: 'BY', number: 3 },
        ],
        landmarkPlaces: [
          'Herat Citadel',
          'Kastelholm Castle',
          'Buddha Dordenma statue',
          'Fort Fincastle',
          "Devil's Bridge",
          "St. Stephen's Cathedral",
          'Great Mosque of Porto-Novo',
        ],
        uniqueId: 'rKRV6PRr',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Bolivia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-mountain-range-from-a-distance-frymIuncA34',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolivia',
        },
        landmark: {
          name: 'Salar de Uyuni',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/dry-soil-under-white-clouds-and-blue-sky-at-daytime-kp_87tExCwI',
          wikiLink: 'https://en.wikipedia.org/wiki/Salar_de_Uyuni',
          hasLandmark: true,
        },
        city: {
          name: 'La Paz',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-mountains-in-the-background-1Q8GECh3Ko8',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Paz',
        },
        landmarksRound: [
          { code: 'AI', number: 3 },
          { code: 'BS', number: 2 },
          { code: 'ER', number: 2 },
        ],
        landmarkPlaces: [
          'Wat Xiengthong',
          'The Grand Palace',
          'Detwah Lagoon',
          'Old Panama',
          'Madriu-Perafita-Claror Valley',
          'Republic Square',
          "Vianden's Castle",
        ],
        uniqueId: 'jqnB2PgP',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bolivia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-on-beach-pDgHGX0lxVs',
          wikiLink: 'https://en.wikipedia.org/wiki/Bolivia',
        },
        landmark: {
          name: 'Tiwanaku',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-under-blue-sky-during-daytime-UK8bUY8CN2s',
          wikiLink: 'https://en.wikipedia.org/wiki/Tiwanaku',
          hasLandmark: true,
        },
        city: {
          name: 'Cochabamba',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/phronemos/8191439427/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cochabamba',
        },
        landmarksRound: [
          { code: 'BG', number: 4 },
          { code: 'YT', number: 4 },
          { code: 'GD', number: 4 },
        ],
        landmarkPlaces: [
          'Kamianets-Podilskyi Castle',
          'The Little Chapel',
          'Cane Garden Bay',
          'Mount Rushmore',
          'Viewpoint of the Moon',
          'Skopje Fortress',
          'Malolotja National Park',
        ],
        uniqueId: 'bvYr7ZGb',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    capital: 'Sarajevo',
    lat: 44,
    long: 18,
    cities: [
      'Sarajevo',
      'Banja Luka',
      'Zenica',
      'Prijedor',
      'Višegrad',
      'Mostar',
      'Visoko',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GZjH4cVGgCs',
          wikiLink: 'https://en.wikipedia.org/wiki/Stari_Most',
        },
        landmark: {
          name: 'Kravica Waterfall',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/waterfall-travel-tourism-nature-6567498/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kravica_(waterfall)',
          hasLandmark: true,
        },
        city: {
          name: 'Sarajevo',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sarajevo-water-balkan-bridhe-2597608/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sarajevo',
        },
        landmarksRound: [
          { code: 'MY', number: 2 },
          { code: 'KG', number: 2 },
          { code: 'MG', number: 1 },
        ],
        landmarkPlaces: [
          'Boudhanath',
          "Diocletian's Palace",
          'Vardzia',
          'Ampitheater of El Jem',
          'Vaduz Cathedral',
          'Baiterek',
          'Chimi Lhakhang',
        ],
        uniqueId: 'GDw6c4vL',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mostar-bridge-travel-bosnia-1155672/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina',
        },
        landmark: {
          name: 'Mostar Old Bridge',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mostar-bosnia-bosnia-and-herzegovina-4596513/',
          wikiLink: 'https://en.wikipedia.org/wiki/Stari_Most',
          hasLandmark: true,
        },
        city: {
          name: 'Banja Luka',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Banja_Luka#/media/File:Banjalukaview000.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Banja_Luka',
        },
        landmarksRound: [
          { code: 'GG', number: 1 },
          { code: 'CZ', number: 2 },
          { code: 'HR', number: 1 },
        ],
        landmarkPlaces: [
          'Bahrain Fort',
          'Easter Island',
          'Mount Yasur',
          'Stone House',
          'Wat Xiengthong',
          'Wieliczka Salt Mine',
          'Trevi Fountain',
        ],
        uniqueId: '5LKm6zZ6',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Bosnia and Herzegovina',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yvlJyrU4Fq4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina',
        },
        landmark: {
          name: 'Tjentiste War Memorial',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/speT7Iohgps',
          wikiLink: 'https://en.wikipedia.org/wiki/Tjenti%C5%A1te',
          hasLandmark: true,
        },
        city: {
          name: 'Višegrad',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/EkjkHpDyVZI',
          wikiLink: 'https://en.wikipedia.org/wiki/Vi%C5%A1egrad',
        },
        landmarksRound: [
          { code: 'IO', number: 2 },
          { code: 'CF', number: 2 },
          { code: 'EC', number: 1 },
        ],
        landmarkPlaces: [
          'Kalandula Falls',
          "Notre-Dame d'Afrique",
          'Fort Montagu',
          'Elephant Island',
          'Olavinlinna',
          'Sydney Opera House',
          'Lake Kivu',
        ],
        uniqueId: 'kgPckXHr',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Bosnia and Herzegovina',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/b9pHmKun4YY',
          wikiLink: 'https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina',
        },
        landmark: {
          name: 'Blagaj Tekija',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/kbEkJbywAhs',
          wikiLink: 'https://en.wikipedia.org/wiki/Blagaj,_Mostar',
          hasLandmark: true,
        },
        city: {
          name: 'Mostar',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GZjH4cVGgCs',
          wikiLink: 'https://en.wikipedia.org/wiki/Mostar',
        },
        landmarksRound: [
          { code: 'CA', number: 2 },
          { code: 'AL', number: 2 },
          { code: 'BW', number: 1 },
        ],
        landmarkPlaces: [
          'Pukapuka',
          'Blue Eye Cave',
          'One Foot Island',
          'Pulu Keeling National Park',
          'St. Nicholas Abbey Heritage Railway',
          'Regina Mundi Cathedral',
          'Amber Palace',
        ],
        uniqueId: 'Gq2K6gJn',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Bosnia and Herzegovina',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-blue-sky-during-daytime-agZWht6xj-A',
          wikiLink: 'https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina',
        },
        landmark: {
          name: 'Old Bridge',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bridge-over-a-river-with-a-boat-on-it-T6CIC6zfBO0',
          wikiLink: 'https://en.wikipedia.org/wiki/Stari_Most',
          hasLandmark: true,
        },
        city: {
          name: 'Mostar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-river-running-through-a-lush-green-hillside-sYnmS74A30s',
          wikiLink: 'https://en.wikipedia.org/wiki/Mostar',
        },
        landmarksRound: [
          { code: 'BR', number: 1 },
          { code: 'NI', number: 2 },
          { code: 'AS', number: 5 },
        ],
        landmarkPlaces: [
          'Christ Church Cathedral',
          'National Museum of Bermuda',
          'Victoria Falls Bridge',
          'Two Lovers Point',
          "Notre-Dame d'Afrique",
          'Shirley Heights',
          'Grand Turk Lighthouse',
        ],
        uniqueId: 'fptRf5YZ',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bosnia and Herzegovina',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-river-and-trees-during-daytime-Bmf8Wi1VOh4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina',
        },
        landmark: {
          name: 'Baščaršija',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-around-a-town-square-OT8bxxMA3j0',
          wikiLink: 'https://en.wikipedia.org/wiki/Ba%C5%A1%C4%8Dar%C5%A1ija',
          hasLandmark: true,
        },
        city: {
          name: 'Visoko',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-river-running-through-a-city-QWmH7UHs6to',
          wikiLink: 'https://en.wikipedia.org/wiki/Visoko',
        },
        landmarksRound: [
          { code: 'IO', number: 4 },
          { code: 'GP', number: 3 },
          { code: 'RO', number: 2 },
        ],
        landmarkPlaces: [
          'El Yunque National Forest',
          'Silver Pagoda',
          'Škocjan Caves',
          'Montserrat Volcano Observatory',
          'Plage de Grande Anse',
          'St. Francis of Assisi Cathedral',
          'Leaning Tower of Pisa',
        ],
        uniqueId: 'dF5rcDRv',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Botswana',
    code: 'BW',
    capital: 'Gaborone',
    lat: -22,
    long: 24,
    cities: [
      'Francistown',
      'Gaborone',
      'Molepolole',
      'Maun',
      'Kanye',
      'Ghanzi',
      'Ghanzi ',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vcN7_lk91rM',
          wikiLink: 'https://en.wikipedia.org/wiki/Botswana',
        },
        landmark: {
          name: 'Okavango Delta',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/okavango-delta-river-landscape-5158380/',
          wikiLink: 'https://en.wikipedia.org/wiki/Okavango_Delta',
          hasLandmark: true,
        },
        city: {
          name: 'Gaborone',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/botswana-gaborone-architecture-970276/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gaborone',
        },
        landmarksRound: [
          { code: 'RS', number: 1 },
          { code: 'MD', number: 1 },
          { code: 'UY', number: 1 },
        ],
        landmarkPlaces: [
          'Machu Picchu',
          "Prince's Palace of Monaco",
          'Soroca Fort',
          'Old Panama',
          'Koutoubia',
          'Blue Grotto',
          'Deadvlei',
        ],
        uniqueId: 'qswpPGDV',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/botswana-baobab-baobab-tree-694485/',
          wikiLink: 'https://en.wikipedia.org/wiki/Botswana',
        },
        landmark: {
          name: 'Tsodilo Hills',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tsodilo#/media/File:Tsodilo_Hills_rock_paintings3.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tsodilo',
          hasLandmark: true,
        },
        city: {
          name: 'Francistown',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Francistown#/media/File:Photo_Submission_by_Aone_Mokwena_(4).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Francistown',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'jZb9Rm79',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Botswana',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vcN7_lk91rM',
          wikiLink: 'https://en.wikipedia.org/wiki/Botswana',
        },
        landmark: {
          name: 'Okavango River',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/4ZCA3xukIso',
          wikiLink: 'https://en.wikipedia.org/wiki/Okavango_River',
          hasLandmark: true,
        },
        city: {
          name: 'Gaborone',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/tyCcpbkgaR4',
          wikiLink: 'https://en.wikipedia.org/wiki/Gaborone',
        },
        landmarksRound: [
          { code: 'BZ', number: 3 },
          { code: 'BJ', number: 4 },
          { code: 'BG', number: 2 },
        ],
        landmarkPlaces: [
          'Bellapais Abbey',
          'Mir Castle',
          'Mount Alava',
          'Arenal Volcano',
          'Taj Mahal',
          'Elephant Island',
          'Quadiriki Caves',
        ],
        uniqueId: 'bYfGlMCt',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Botswana',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/0K_ZvuPzexI',
          wikiLink: 'https://en.wikipedia.org/wiki/Botswana',
        },
        landmark: {
          name: 'Tsodilo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tsodilo#/media/File:Tsodilo_Hills_rock_paintings4.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tsodilo',
          hasLandmark: true,
        },
        city: {
          name: 'Ghanzi',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ghanzi#/media/File:GantsiHighStreet.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ghanzi',
        },
        landmarksRound: [
          { code: 'AM', number: 4 },
          { code: 'CN', number: 1 },
          { code: 'AO', number: 3 },
        ],
        landmarkPlaces: [
          'Hazrat Ali Mazar',
          'Sundarbans',
          'Amberd',
          'Maiden Tower',
          'Christ the Redeemer',
          "Devil's Bridge",
          'Temple of Garni',
        ],
        uniqueId: 'jFspRzbg',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Okavango Delta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-river-running-through-a-lush-green-field-jk_2Ak2z3JY',
          wikiLink: 'https://en.wikipedia.org/wiki/Okavango_Delta',
        },
        landmark: {
          name: 'Christ the King Cathedral',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/littledutchboy/13898207798/in/photolist-nb8WYo',
          wikiLink:
            'https://en.wikipedia.org/wiki/Christ_the_King_Cathedral_(Gaborone)',
          hasLandmark: true,
        },
        city: {
          name: 'Gaborone',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-OjmO-dNF0lQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Gaborone',
        },
        landmarksRound: [
          { code: 'AS', number: 4 },
          { code: 'DM', number: 4 },
          { code: 'GG', number: 3 },
        ],
        landmarkPlaces: [
          'Mutanabbi Statue',
          'Bissau Cathedral',
          'Mount Yasur',
          'Yellowstone National Park',
          'Salar de Uyuni',
          'Basilique Sainte Anne du Congo',
          'Solis Theater',
        ],
        uniqueId: '4FLpNV6p',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Botswana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-flock-of-birds-standing-on-the-shore-of-a-lake-fG9ay5fcIYI',
          wikiLink: 'https://en.wikipedia.org/wiki/Botswana',
        },
        landmark: {
          name: 'Nxai Pan National Park',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/travelourplanet/48593386857/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nxai_Pan_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Ghanzi ',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/stolenart/30780284235/in/photolist-NTX18M-5uQMv5-pjGSm-2h37Xav-7j9mXi-p6RvBC-phLdw-2h8EWkq-2pdGxRL-NTX1j8-2gW2vGr-5hzFGV-5hzFWp-5hzDNZ-5hE2cJ-q1xvoS-5hE4JY-5hE2JQ-NHCgjU-bbdUF-74EuQX-pLdw4z-5uJavK-74EuXi-YGYHcu-232NJE-q3CYRK-7hs6Fx-p6RsV5-2gW3kDE-2h37fns-phLbR-5hDYdY-NHsDJ6-5hDZCQ-5hzAjK-2h8CokD-2igPKX1-5hDZgQ-2h1vFyv-Lh7Pk6-5hzBkF-phLe4-phLby-2hk3xE9-5yF8qY-74JoY3-5eJ2Br-5eNqvC-Y25Gvb',
          wikiLink: 'https://en.wikipedia.org/wiki/Ghanzi',
        },
        landmarksRound: [
          { code: 'UY', number: 1 },
          { code: 'LA', number: 4 },
          { code: 'KH', number: 3 },
        ],
        landmarkPlaces: [
          'Fort Karl',
          'St. James and Our Lady of the Pillar Cathedral',
          'Bikini Atoll',
          'Juche Tower',
          'Saint-Pierre Cathedral of Saint Pierre and Miquelon',
          'Floralis Generica',
          'Dubai Frame',
        ],
        uniqueId: '7YDrvxkC',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Bouvet Islands',
    code: 'BV',
    capital: 'TODO',
    lat: -54.423199,
    long: 3.413194,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Bouvet_island.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bouvet_Island',
        },
        landmark: {
          name: 'Olavtoppen',
          photographer: '',
          imageLink: '',
          wikiLink: 'https://en.wikipedia.org/wiki/Olavtoppen',
          hasLandmark: false,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'R8ybNCsp',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Bouvet_Island_west_coast_glacier.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bouvet_Island',
        },
        landmark: {
          name: 'Cape Circoncision',
          photographer: '',
          imageLink: '',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Circoncision',
          hasLandmark: false,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'jwWMCWm6',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Brazil',
    code: 'BR',
    capital: 'Brasília',
    lat: -10,
    long: -55,
    cities: [
      'Fortaleza',
      'Rio de Janeiro',
      'Manaus',
      'Recife',
      'São Paulo',
      'Brasília',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WjvrLSTkRck',
          wikiLink: 'https://en.wikipedia.org/wiki/Rio_de_Janeiro',
        },
        landmark: {
          name: 'Christ the Redeemer',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/brazil-rio-landscape-tourism-ocean-4809011/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Christ_the_Redeemer_(statue)',
          hasLandmark: true,
        },
        city: {
          name: 'São Paulo',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/s%c3%a3o-paulo-buildings-urban-1206160/',
          wikiLink: 'https://en.wikipedia.org/wiki/S%C3%A3o_Paulo',
        },
        landmarksRound: [
          { code: 'CH', number: 1 },
          { code: 'TJ', number: 2 },
          { code: 'KZ', number: 1 },
        ],
        landmarkPlaces: [
          'Taj Mahal',
          'Kaaba',
          'Okavango Delta',
          'The Grand Palace',
          'Rijksmuseum',
          'Old Town Square',
          'Rock of Cashel',
        ],
        uniqueId: '8ynvBntn',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rio-de-janeiro-brazil-travel-rio-4566312/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rio_de_Janeiro',
        },
        landmark: {
          name: 'Cathedral of Brasilica',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cathedral_of_Bras%C3%ADlia#/media/File:Catedral_Metropolitana_de_Brasilia.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cathedral_of_Bras%C3%ADlia',
          hasLandmark: true,
        },
        city: {
          name: 'Fortaleza',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cityscape-lights-night-dark-urban-4743866/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fortaleza',
        },
        landmarksRound: [
          { code: 'CN', number: 2 },
          { code: 'NL', number: 2 },
          { code: 'DE', number: 1 },
        ],
        landmarkPlaces: [
          'Anne Frank House',
          'South Pole (ceremonial)',
          'Erbil Citadel',
          'Castle Cornet',
          'Animal Flower Cave',
          'Pigeon Rocks',
          'Ostrog Monastery',
        ],
        uniqueId: 'HjYmDgpC',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Brazil',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/QaWSsUSJMbo',
          wikiLink: 'https://en.wikipedia.org/wiki/Brazil',
        },
        landmark: {
          name: 'Iguazu Falls',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/5rbQW61lOSU',
          wikiLink: 'https://en.wikipedia.org/wiki/Iguazu_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Rio de Janeiro',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/7F65HDP0-E0',
          wikiLink: 'https://en.wikipedia.org/wiki/Rio_de_Janeiro',
        },
        landmarksRound: [
          { code: 'BA', number: 4 },
          { code: 'BN', number: 2 },
          { code: 'AQ', number: 2 },
        ],
        landmarkPlaces: [
          'Valle de Cocora',
          'Maralgöl',
          'Notre Dame of Bangui Cathedral',
          'White Bay Bar',
          'Zaisan Memorial',
          'Pula Arena',
          'Hazrat Ali Mazar',
        ],
        uniqueId: 'M27y8CS3',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Brazil',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/V7jzq-Zgrvo',
          wikiLink: 'https://en.wikipedia.org/wiki/Brazil',
        },
        landmark: {
          name: 'Catedral da Sé',
          photographer: '',
          imageLink:
            'https://pt.wikipedia.org/wiki/Catedral_Metropolitana_de_S%C3%A3o_Paulo#/media/Ficheiro:Webysther_20190306143112_-_Catedral_Metropolitana_de_S%C3%A3o_Paulo.jpg',
          wikiLink:
            'https://pt.wikipedia.org/wiki/Catedral_Metropolitana_de_S%C3%A3o_Paulo',
          hasLandmark: true,
        },
        city: {
          name: 'São Paulo',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/s_7GuvuXufw',
          wikiLink: 'https://pt.wikipedia.org/wiki/S%C3%A3o_Paulo',
        },
        landmarksRound: [
          { code: 'GE', number: 2 },
          { code: 'CA', number: 2 },
          { code: 'BH', number: 2 },
        ],
        landmarkPlaces: [
          'Turtle Cove',
          'Okavango Delta',
          'We Are Our Mountains',
          'Brest Fortress',
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Bab Al Bahrain',
          'Qargha Reservoir',
        ],
        uniqueId: 't4dmvmlJ',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Brazil',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-photography-of-city-near-trees-JesYw3Z-vHk',
          wikiLink: 'https://en.wikipedia.org/wiki/Brazil',
        },
        landmark: {
          name: 'Cathedral of Brasília',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/heitorpb/48045938582/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cathedral_of_Bras%C3%ADlia',
          hasLandmark: true,
        },
        city: {
          name: 'Rio de Janeiro',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-mountains-lri4DUnAUrI',
          wikiLink: 'https://en.wikipedia.org/wiki/Rio_de_Janeiro',
        },
        landmarksRound: [
          { code: 'MZ', number: 2 },
          { code: 'CL', number: 1 },
          { code: 'DO', number: 2 },
        ],
        landmarkPlaces: [
          'Lily Beach',
          'University of Dhaka',
          "Notre-Dame d'Afrique",
          'Laxey Wheel',
          'Qargha Reservoir',
          'Roman ruins in Batna',
          'East Side Gallery',
        ],
        uniqueId: 'JHsLJpj9',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Rio de Janeiro',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-many-buildings-and-a-hill-in-the-background-rMJquL0_Qhk',
          wikiLink: 'https://en.wikipedia.org/wiki/Rio_de_Janeiro',
        },
        landmark: {
          name: 'Pedra da Gávea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/scenery-of-body-of-water-BNK525WM4BA',
          wikiLink: 'https://en.wikipedia.org/wiki/Pedra_da_G%C3%A1vea',
          hasLandmark: true,
        },
        city: {
          name: 'São Paulo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/empty-basketball-court-77pAlgB8v_E',
          wikiLink: 'https://en.wikipedia.org/wiki/S%C3%A3o_Paulo',
        },
        landmarksRound: [
          { code: 'TD', number: 4 },
          { code: 'BD', number: 3 },
          { code: 'AG', number: 2 },
        ],
        landmarkPlaces: [
          'St. Peter the Apostle Cathedral',
          'Maluti Mountains',
          'Blagaj Tekija',
          'Sule Pagoda',
          'Akanda National Park',
          'Notre Dame of Bangui Cathedral',
          'Vaduz Castle',
        ],
        uniqueId: 'KkWSkBVk',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    capital: 'Diego Garcia',
    lat: -6,
    long: 71.5,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Chagossians_visiting_the_British_Indian_Ocean_Territory_as_tourists.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
        },
        landmark: {
          name: 'Salomons Atoll',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Salomons_Atoll_in_the_Chagos.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Salomon_Islands',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'MDFDwjnQ',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:The_O_Club_on_Diego_Garcia.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Naval_Support_Facility_Diego_Garcia',
        },
        landmark: {
          name: 'Turtle Cove',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/33590535@N06/4165898282',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'mmsYzZ8v',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'British Indian Ocean Territory',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Scientist_Nick_Graham_does_a_fish_count,_British_Indian_Ocean_Territory_(7029059225).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
        },
        landmark: {
          name: 'Salomon Islands',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Church_at_Boddam_Island#/media/File:%C3%89glise_Boddam.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Salomon_Islands',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'BZ', number: 2 },
          { code: 'BT', number: 4 },
          { code: 'BY', number: 2 },
        ],
        landmarkPlaces: [
          'Niagara Falls',
          'Taj Mahal',
          'Mount Cameroon',
          'Buddha Dordenma statue',
          'Monteverde Cloud Forest',
          'Twelve Apostles',
          "Tiger's Nest",
        ],
        uniqueId: 'SNdlYWNB',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'British Indian Ocean Territory',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:US_Navy_100930-N-9402T-226_Sailors_engage_in_a_live-fire_training_exercise_at_the_British_Indian_Ocean_Territory_firing_range.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
        },
        landmark: {
          name: 'Diego Garcia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Diego_Garcia#/media/File:Diegogarcia.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Diego_Garcia',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'BS', number: 3 },
          { code: 'BD', number: 4 },
          { code: 'KH', number: 1 },
        ],
        landmarkPlaces: [
          'Belovezhskaya Pushcha National Park',
          'Boali',
          'Gangtey Monastery',
          'Sao Filipe Royale Fortress',
          'Xunantunich Mayan Ruins',
          'Band-e Amir National Park',
          'Old Town Square',
        ],
        uniqueId: '2GZZzSyy',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'British Indian Ocean Territory',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/thom_astro/51312819042/',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
        },
        landmark: {
          name: 'Naval Support Facility Diego Garcia',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/compacflt/48424261586/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Naval_Support_Facility_Diego_Garcia',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'AX', number: 1 },
          { code: 'NR', number: 2 },
          { code: 'MC', number: 2 },
        ],
        landmarkPlaces: [
          'Mount Rushmore',
          'Kampong Kianggeh',
          'Borobudur Temple',
          'Angkor Wat',
          'Te Rua Manga (The Needle)',
          'Senso-ji',
          'Gyeongbukgong Palace',
        ],
        uniqueId: 'Wtmc4gNM',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'British Indian Ocean Territory',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mattierocks/451469393/',
          wikiLink:
            'https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory',
        },
        landmark: {
          name: 'Diego Garcia',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/mattierocks/451469453/in/album-72157600057226292',
          wikiLink: 'https://en.wikipedia.org/wiki/Diego_Garcia',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'PW', number: 2 },
          { code: 'DJ', number: 2 },
          { code: 'MN', number: 4 },
        ],
        landmarkPlaces: [
          'Merry Cemetery',
          'Mutanabbi Statue',
          'Salvo Palace',
          'Souq Waqif',
          'Noravank Monastery',
          'Mont Ross',
          'The Pinnacles Desert',
        ],
        uniqueId: 'Qy4Xq8bb',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'British Virgin Islands',
    code: 'VG',
    capital: 'Road Town',
    lat: 18.431383,
    long: -64.62305,
    cities: ['Road Town', 'Spanish Town'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bay-british-virgin-islands-yacht-434934/',
          wikiLink: 'https://en.wikipedia.org/wiki/British_Virgin_Islands',
        },
        landmark: {
          name: 'The Baths',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/The_Baths#/media/File:Virgin_Gorda_Boulders2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Baths',
          hasLandmark: true,
        },
        city: {
          name: 'Road Town',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/road-town-tortola-2633026/',
          wikiLink: 'https://en.wikipedia.org/wiki/Road_Town',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Qv3fcVtm',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/road-town-tortola-2633026/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tortola',
        },
        landmark: {
          name: 'White Bay Bar',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:British_Virgin_Islands_%E2%80%94_Jost_van_Dyke_%E2%80%94_White_Bay_%28bar%29.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Jost_Van_Dyke',
          hasLandmark: true,
        },
        city: {
          name: 'Spanish Town',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Virgin_Gorda_Airstrip.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Spanish_Town,_British_Virgin_Islands',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'CcnKFhQQ',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'British Virgin Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/N4-ghgyy9-M',
          wikiLink: 'https://en.wikipedia.org/wiki/British_Virgin_Islands',
        },
        landmark: {
          name: 'Cane Garden Bay',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/6TCBPruGDW8',
          wikiLink: 'https://en.wikipedia.org/wiki/Tortola',
          hasLandmark: false,
        },
        city: {
          name: 'Road Town',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Road_Town#/media/File:Main_Street,_Road_Town,_Tortola.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Road_Town',
        },
        landmarksRound: [
          { code: 'CC', number: 1 },
          { code: 'BW', number: 3 },
          { code: 'BT', number: 2 },
        ],
        landmarkPlaces: [
          'Fort James',
          'Fort Montagu',
          'Lake Yoa',
          'Lake Kivu',
          'The Belize Zoo',
          'Dolly Beach',
          'Villers Abbey',
        ],
        uniqueId: 'NTKWsQdP',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tortola',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/BKFtc2QfiaY',
          wikiLink: 'https://en.wikipedia.org/wiki/Tortola',
        },
        landmark: {
          name: 'The Baths',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/UGZ4wJNeFlw',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Baths',
          hasLandmark: true,
        },
        city: {
          name: 'Spanish Town',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Virgin_Gorda#/media/File:Virgin_Gorda_North_Sound_2010.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Spanish_Town,_British_Virgin_Islands',
        },
        landmarksRound: [
          { code: 'BT', number: 2 },
          { code: 'BM', number: 2 },
          { code: 'AU', number: 4 },
        ],
        landmarkPlaces: [
          'Elephant Island',
          'Sydney Opera House',
          'South Pole',
          'Mount Cameroon',
          'Timgad',
          'Okavango River',
          'We Are Our Mountains',
        ],
        uniqueId: 'mbyGVDZn',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Tortola',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-standing-on-a-pier-next-to-a-body-of-water-BKFtc2QfiaY',
          wikiLink: 'https://en.wikipedia.org/wiki/Tortola',
        },
        landmark: {
          name: 'The Baths',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/52997991486/',
          wikiLink:
            "https://en.wikipedia.org/wiki/The_Baths#:~:text=The%20Baths%20is%20situated%20about,the%20BVI's%20major%20tourist%20destinations.",
          hasLandmark: true,
        },
        city: {
          name: 'Road Town',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/124651729@N04/50929804692/',
          wikiLink: 'https://en.wikipedia.org/wiki/Road_Town',
        },
        landmarksRound: [
          { code: 'BE', number: 2 },
          { code: 'BD', number: 1 },
          { code: 'ER', number: 1 },
        ],
        landmarkPlaces: [
          'Gibbs Hill Lighthouse',
          'Edinburgh Castle',
          'Animal Flower Cave',
          'Tsingy De Bemaraha',
          'Malolotja National Park',
          'Molinere Underwater Sculpture Park',
          'Deception Island',
        ],
        uniqueId: 'stMjwHmW',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'British Virgin Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-beach-with-trees-and-houses-Q8Ox8kojpZ0',
          wikiLink: 'https://en.wikipedia.org/wiki/British_Virgin_Islands',
        },
        landmark: {
          name: 'Copper Mine',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/vbvaphotos/44757440204/',
          wikiLink: 'https://en.wikipedia.org/wiki/Copper_Minee,_Virgin_Gorda',
          hasLandmark: true,
        },
        city: {
          name: 'Spanish Town',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/souloyster/4345064446/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Spanish_Town,_British_Virgin_Islands',
        },
        landmarksRound: [
          { code: 'AO', number: 1 },
          { code: 'JO', number: 3 },
          { code: 'ID', number: 2 },
        ],
        landmarkPlaces: [
          'Chinguetti',
          'Pedro St. James Castle',
          'Cheshire Hall Plantation',
          'Deadvlei',
          'Monument to the Heroes of the Restoration',
          'Molinere Underwater Sculpture Park',
          'Okavango Delta',
        ],
        uniqueId: 'FHzQQpJg',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Brunei',
    code: 'BN',
    capital: 'Bandar Seri Begawan',
    lat: 4.5,
    long: 114.6666667,
    cities: [
      'Kampung Sinarubai',
      'Bandar Seri Begawan',
      'Seria',
      'Napok',
      'Selayun',
      'Tutong',
      'Kampong Ayer',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/ship-junk-mosque-saifuddin-brunei-523587/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
        landmark: {
          name: 'Ulu Temburong National Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ulu_Temburong_National_Park#/media/File:Ulu_Ulu_Resort_Jetty.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ulu_Temburong_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Bandar Seri Begawan',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bandar_Seri_Begawan#/media/File:Panorama_Bandar_Seri_Begawan_19.05.2018.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bandar_Seri_Begawan',
        },
        landmarksRound: [
          { code: 'FI', number: 2 },
          { code: 'VI', number: 2 },
          { code: 'CZ', number: 1 },
        ],
        landmarkPlaces: [
          'Old Town Square',
          'Szechenyi Thermal Bath',
          'Soroca Fort',
          'Palace of Versailles',
          'Annandale Waterfall',
          'Marcus Aurelius Arch',
          'Dhakeshwari Temple',
        ],
        uniqueId: 'x3wDWrsv',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/brunei-empire-hotel-overseas-travel-2946137/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
        landmark: {
          name: "Jame' Asr Hassanil Bolkiah Mosque",
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Jame%27_Asr_Hassanil_Bolkiah_Mosque#/media/File:Jame'_Asri_Sultan_Hassanal_Bolkiah_(18404222579).jpg",
          wikiLink:
            'https://en.wikipedia.org/wiki/Jame%27_Asr_Hassanil_Bolkiah_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Kampung Sinarubai',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kampong_Sinarubai#/media/File:Brunei_9_August_2022_07.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kampong_Sinarubai',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'dSVnzhGP',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Brunei',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/the-famous-yayasan-shopping-mall-in-brunei-6613502/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
        landmark: {
          name: 'Omar Ali Saifuddien Mosque',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Omar_Ali_Saifuddien_Mosque#/media/File:Brunei.Sultan_Omar_Ali_Saifuddin_Mosque_(18640417629).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Omar_Ali_Saifuddien_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Bandar Seri Begawan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GBfpHcGhacc',
          wikiLink: 'https://en.wikipedia.org/wiki/Bandar_Seri_Begawan',
        },
        landmarksRound: [
          { code: 'KY', number: 2 },
          { code: 'BY', number: 4 },
          { code: 'AD', number: 1 },
        ],
        landmarkPlaces: [
          'CN Tower',
          'Blue Mountains',
          'The Baths',
          'Sydney Opera House',
          'Salomon Islands',
          'Band-e Amir National Park',
          'White Bay Bar',
        ],
        uniqueId: 'rBFzpjYr',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Brunei',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/facade-of-a-building-under-the-blue-sky-12587133/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
        landmark: {
          name: 'Kampong Kianggeh',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kampong_Kianggeh#/media/File:Tamu_(Open_Market)_(3).JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Kampong_Kianggeh',
          hasLandmark: true,
        },
        city: {
          name: 'Tutong',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tutong_District#/media/File:Hospital_pmmpmhamb_02.08.2018.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tutong_District',
        },
        landmarksRound: [
          { code: 'BH', number: 2 },
          { code: 'AT', number: 4 },
          { code: 'AT', number: 3 },
        ],
        landmarkPlaces: [
          'Timgad',
          'Diego Garcia',
          'Monteverde Cloud Forest',
          'Hato Caves',
          'Lake Koman',
          'Bahrain Fort',
          'Okavango Delta',
        ],
        uniqueId: 'bRBhQNqy',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Brunei',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-lot-of-windows-on-top-of-it-_hsVmtN4obQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
        landmark: {
          name: "Jame' Asr Hassanil Bolkiah Mosque",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/volvob12b/49710821097/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Jame%27_Asr_Hassanil_Bolkiah_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Kampong Ayer',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-small-boat-in-the-middle-of-a-body-of-water-zHyh97fOK9A',
          wikiLink: 'https://en.wikipedia.org/wiki/Kampong_Ayer',
        },
        landmarksRound: [
          { code: 'ES', number: 3 },
          { code: 'AI', number: 1 },
          { code: 'BJ', number: 3 },
        ],
        landmarkPlaces: [
          'Köl-Suu',
          'We Are Our Mountains',
          'Seven Coloured Earths',
          'Yellowstone National Park',
          'Blagaj Tekija',
          'Mont Ross',
          'Mount Karthala',
        ],
        uniqueId: 'bhcMspm8',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Brunei',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-street-with-palm-trees-and-buildings-in-the-background-zUvPhMmUIAc',
          wikiLink: 'https://en.wikipedia.org/wiki/Brunei',
        },
        landmark: {
          name: 'Billionth Barrel Monument',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/iqremix/18783325086/',
          wikiLink: 'https://en.wikipedia.org/wiki/Billionth_Barrel_Monument',
          hasLandmark: true,
        },
        city: {
          name: 'Bandar Seri Begawan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-person-with-an-umbrella-walking-under-a-chinese-arch-TzLTf29mFas',
          wikiLink: 'https://en.wikipedia.org/wiki/Bandar_Seri_Begawan',
        },
        landmarksRound: [
          { code: 'SO', number: 1 },
          { code: 'KM', number: 1 },
          { code: 'CN', number: 3 },
        ],
        landmarkPlaces: [
          'Mount Alava',
          'Peel Castle',
          'Boiling Lake',
          'Xunatunich Mayan Ruins',
          'Island of Mozambique',
          'Saint-Pierre Cathedral of Saint Pierre and Miquelon',
          'Seven Coloured Earths',
        ],
        uniqueId: 'KLWTgsCw',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    capital: 'Sofia',
    lat: 43,
    long: 25,
    cities: [
      'Plovdiv',
      'Sofia',
      'Varna',
      'Ruse',
      'Shumen',
      'Burgas',
      'Sozopol',
      'Provadia',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/UPoSvgmFKmo',
          wikiLink:
            'https://en.wikipedia.org/wiki/Alexander_Nevsky_Cathedral,_Sofia',
        },
        landmark: {
          name: 'Rila Monastery',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rila-monastery-church-4905350/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rila_Monastery',
          hasLandmark: true,
        },
        city: {
          name: 'Plovdiv',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/hill-sunset-city-dusk-travel-6977948/',
          wikiLink: 'https://en.wikipedia.org/wiki/Plovdiv',
        },
        landmarksRound: [
          { code: 'UA', number: 1 },
          { code: 'JM', number: 2 },
          { code: 'DK', number: 2 },
        ],
        landmarkPlaces: [
          'Roman theatre',
          "Fisherman's Bastion",
          'Red Square',
          'Floralis Generica',
          'Vianden Castle',
          'Noravank Monastery',
          'Arecibo Observatory',
        ],
        uniqueId: 'bpCWhWxg',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/scenic-mountain-landscape-blue-2014114/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bulgaria',
        },
        landmark: {
          name: 'St. Alexander Nevsky Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Alexander_Nevsky_Cathedral,_Sofia#/media/File:Anevski.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Alexander_Nevsky_Cathedral,_Sofia',
          hasLandmark: true,
        },
        city: {
          name: 'Sofia',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/sofia-city-tramway-3658934/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sofia',
        },
        landmarksRound: [
          { code: 'JP', number: 1 },
          { code: 'LU', number: 2 },
          { code: 'MM', number: 1 },
        ],
        landmarkPlaces: [
          'Animal Flower Cave',
          'Macau Tower',
          'Narikala Fortress',
          'Green Grotto Caves',
          'Souq Waqif',
          'Mount Nyriagongo',
          "Memorial de l'Anse",
        ],
        uniqueId: '9FC7q3j2',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kardzhali Reservoir',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/j1OVl4P_BQQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Kardzhali_Reservoir',
        },
        landmark: {
          name: 'Seven Rile Lakes',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/K7HvIvsPy8w',
          wikiLink: 'https://en.wikipedia.org/wiki/Seven_Rila_Lakes',
          hasLandmark: true,
        },
        city: {
          name: 'Varna',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/a02eB73Hc2c',
          wikiLink: 'https://en.wikipedia.org/wiki/Varna,_Bulgaria',
        },
        landmarksRound: [
          { code: 'BB', number: 3 },
          { code: 'AZ', number: 1 },
          { code: 'AF', number: 2 },
        ],
        landmarkPlaces: [
          'One Foot Island',
          'Mount Karthala',
          'National Museum of Bermuda',
          "St. John's Cathedral",
          'Little Bay',
          'Silver Pagoda',
          'California Lighthouse',
        ],
        uniqueId: 'JtVYsfcf',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Buzludzha monument',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gF8aHM445P4',
          wikiLink: 'https://en.wikipedia.org/wiki/Buzludzha_monument',
        },
        landmark: {
          name: "Devil's Throat",
          photographer: '',
          imageLink: 'https://unsplash.com/photos/FRf4S6iVTIs',
          wikiLink: 'https://en.wikipedia.org/wiki/Devil%27s_Throat_Cave',
          hasLandmark: true,
        },
        city: {
          name: 'Burgas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-a-high-point-of-view-yEUT4xwNDU0',
          wikiLink: 'https://en.wikipedia.org/wiki/Burgas',
        },
        landmarksRound: [
          { code: 'CX', number: 1 },
          { code: 'CO', number: 2 },
          { code: 'CN', number: 1 },
        ],
        landmarkPlaces: [
          'Great Mosque of Porto-Novo',
          'Blagaj Tekija',
          'Hofburg Palace',
          'Kittiwake Shipwreck',
          'Buenos Aires Obelisk',
          'Alega Beach',
          'Grand Mosque of Bobo-Dioulasso',
        ],
        uniqueId: '8cQMncFn',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Sofia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-blue-building-with-a-tree-in-front-of-it-SmmdNM3V8O0',
          wikiLink: 'https://en.wikipedia.org/wiki/Sofia',
        },
        landmark: {
          name: 'Buzludzha monument',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-building-on-green-grass-field-under-blue-sky-during-daytime-Hiz3TY3iaHI',
          wikiLink: 'https://en.wikipedia.org/wiki/Buzludzha_monument',
          hasLandmark: true,
        },
        city: {
          name: 'Sozopol',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-body-of-water-during-daytime-EFVuZbNYbG8',
          wikiLink: 'https://en.wikipedia.org/wiki/Sozopol',
        },
        landmarksRound: [
          { code: 'GN', number: 3 },
          { code: 'KM', number: 3 },
          { code: 'CI', number: 4 },
        ],
        landmarkPlaces: [
          'Mausoleum of Khoja Ahmed Yasawi',
          'Fasil Ghebbi',
          'St. Francis of Assisi Cathedral',
          'Fort Santiago',
          'Ouagadougou Cathedral',
          'Billionth Barrel Monument',
          'Arenal Volcano',
        ],
        uniqueId: '66HDRr8P',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bulgaria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-waterfall-in-the-middle-of-a-canyon-E2pdnRw0qaI',
          wikiLink: 'https://en.wikipedia.org/wiki/Bulgaria',
        },
        landmark: {
          name: 'National Gallery',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/selective-focus-phoography-of-house-Gnv6PEklyPc',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_Art_Gallery,_Bulgaria',
          hasLandmark: true,
        },
        city: {
          name: 'Provadia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-bridge-on-mountain-peak-near-cityscape-RRn2wkjQPzo',
          wikiLink: 'https://en.wikipedia.org/wiki/Provadia',
        },
        landmarksRound: [
          { code: 'DO', number: 2 },
          { code: 'BJ', number: 3 },
          { code: 'CZ', number: 1 },
        ],
        landmarkPlaces: [
          'Pulu Keeling National Park',
          'Hundertwasserhaus',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Lukan Longshan Temple',
          "Tassili n'Ajjer",
          'St. Francis of Assisi Cathedral',
          "King's Palace Museum",
        ],
        uniqueId: 'qRYHyzNq',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    capital: 'Ouagadougou',
    lat: 13,
    long: -2,
    cities: [
      'Ouagadougou',
      'Bobo-Dioulasso',
      'Koudougou',
      'Banfora',
      'Ouahigouya',
      'Dédougou',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/africa-afrique-burkina-faso-pottery-170206/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
        landmark: {
          name: 'Grand Mosque of Bobo-Dioulasso',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Grand_Mosque_of_Bobo-Dioulasso#/media/File:Moschee_von_Bobo-Dioulasso.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Grand_Mosque_of_Bobo-Dioulasso',
          hasLandmark: true,
        },
        city: {
          name: 'Bobo-Dioulasso',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bobo-Dioulasso#/media/File:BoboDioulasso-Market.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Bobo-Dioulasso',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'HPgZYP2H',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/africa-burkina-faso-homes-205218/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
        landmark: {
          name: 'Ouagadougou Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ouagadougou_Cathedral#/media/File:Cathedrale_Ouagadougou.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ouagadougou_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Ouagadougou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ouagadougou#/media/File:OUAGADOUGOU3.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Ouagadougou',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'qXKD3ypx',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Burkina Faso',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8wuOLdN77A4',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
        landmark: {
          name: 'Grand Mosque of Bobo-Dioulasso',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bobo-Dioulasso#/media/File:Moschee_von_Bobo-Dioulasso.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bobo-Dioulasso',
          hasLandmark: true,
        },
        city: {
          name: 'Ouagadougou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ouagadougou#/media/File:Assemblee_Nationale_Burkina_Faso.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ouagadougou',
        },
        landmarksRound: [
          { code: 'MN', number: 2 },
          { code: 'BJ', number: 4 },
          { code: 'CR', number: 2 },
        ],
        landmarkPlaces: [
          'National Park of American Samoa',
          'Gravensteen',
          'Animal Flower Cave',
          'St. Nicholas Abbey Heritage Railway',
          'Emi Koussi',
          'Mount Cameroon',
          'Herat Citadel',
        ],
        uniqueId: 'dZQmTRmV',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Burkina Faso',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/africa-afrique-burkina-faso-pottery-170206/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
        landmark: {
          name: 'Domes of Fabedougou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:Domes_de_Fabedougou.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fabedougou',
          hasLandmark: true,
        },
        city: {
          name: 'Bobo-Dioulasso',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bobo-Dioulasso#/media/File:BoboDioulasso-Market.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Bobo-Dioulasso',
        },
        landmarksRound: [
          { code: 'BA', number: 3 },
          { code: 'AU', number: 4 },
          { code: 'AD', number: 4 },
        ],
        landmarkPlaces: [
          'Animal Flower Cave',
          'Tjentiste War Memorial',
          'Mostar Old Bridge',
          'Great Blue Hole',
          'Tsodilo Hills',
          'Fort Montagu',
          "Tassili n'Ajjer",
        ],
        uniqueId: 'MN3hmQBz',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Burkina Faso',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/130288136@N06/32477596225/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
        landmark: {
          name: 'Grand Mosque of Bobo-Dioulasso',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/22130194@N04/49676125336/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Grand_Mosque_of_Bobo-Dioulasso',
          hasLandmark: true,
        },
        city: {
          name: 'Ouagadougou',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/christopher-jaumoulle/3808030423/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ouagadougou',
        },
        landmarksRound: [
          { code: 'MN', number: 4 },
          { code: 'SA', number: 1 },
          { code: 'GY', number: 4 },
        ],
        landmarkPlaces: [
          'Fongafale',
          'Republic Square',
          'The Ethnographic Open-Air Museum of Latvia',
          'Hazrat Ali Mazar',
          'Plitvice Lakes National Park',
          'Fort Frederick',
          'Donkey Sanctuary',
        ],
        uniqueId: 'nVZTJgvz',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Burkina Faso',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/leapingaroundtheworld/2176554472/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burkina_Faso',
        },
        landmark: {
          name: 'Pics de Sindou',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/williamhaun/37123023256/',
          wikiLink: 'https://fr.wikipedia.org/wiki/Pics_de_Sindou',
          hasLandmark: true,
        },
        city: {
          name: 'Bobo-Dioulasso',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/princeleo/5529252000/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bobo-Dioulasso',
        },
        landmarksRound: [
          { code: 'AG', number: 6 },
          { code: 'VU', number: 3 },
          { code: 'CW', number: 3 },
        ],
        landmarkPlaces: [
          'Republic Square',
          "Vianden's Castle",
          'Narikala Fortress',
          'Mont Choungui',
          "Jacob's Ladder",
          'Kerid Crater',
          'Tokyo Skytree',
        ],
        uniqueId: 'Nq8j3wJW',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Burundi',
    code: 'BI',
    capital: 'Bujumbura',
    lat: -3.5,
    long: 30,
    cities: [
      'Bujumbura',
      'Gitega',
      'Muyinga',
      'Ruyigi',
      'Ngozi',
      'Kayanza',
      'Muramvya',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/wilderness-africa-nature-scenic-2434470/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burundi',
        },
        landmark: {
          name: 'Source du Nil',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/pyramid-burundi-landscape-africa-6599068/',
          wikiLink: 'https://en.wikipedia.org/wiki/Musenyi',
          hasLandmark: true,
        },
        city: {
          name: 'Gitega',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:Gitega_city.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gitega',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'RK6rkM3k',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/burundi-african-gitega-6878378/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gitega',
        },
        landmark: {
          name: 'Regina Mundi Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Regina_Mundi_Cathedral,_Bujumbura#/media/File:Bujumbura_Cathedral.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Regina_Mundi_Cathedral,_Bujumbura',
          hasLandmark: true,
        },
        city: {
          name: 'Bujumbura',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bujumbura#/media/File:View_of_bujumbura.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Bujumbura',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'dc4nPltV',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cankuzo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-field-with-trees-and-mountains-in-the-background-ne5VdPhGRu0',
          wikiLink: 'https://en.wikipedia.org/wiki/Cankuzo_Province',
        },
        landmark: {
          name: 'Kagera waterfalls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-waterfall-with-a-tree-in-front-of-it-1pgtrM4GIKg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kagera_waterfalls',
          hasLandmark: true,
        },
        city: {
          name: 'Bujumbura',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/List_of_cities_in_Burundi#/media/File:BujumburaFromCathedral.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bujumbura',
        },
        landmarksRound: [
          { code: 'BH', number: 4 },
          { code: 'AT', number: 3 },
          { code: 'AT', number: 1 },
        ],
        landmarkPlaces: [
          'California Lighthouse',
          'Valle de Cocora',
          'Oceania House',
          'Cameroon Reunification',
          'Natural Pool',
          'Floralis Generica',
          'Paradise Island',
        ],
        uniqueId: 'tMmqFcFc',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Gitega',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-red-and-white-house-with-a-balcony-YlzfGexgBX0',
          wikiLink: 'https://en.wikipedia.org/wiki/Gitega',
        },
        landmark: {
          name: 'Living Museum of Bujumbura',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Living_Museum_of_Bujumbura#/media/File:Antilope_2_au_Mus%C3%A9e_vivant.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Living_Museum_of_Bujumbura',
          hasLandmark: true,
        },
        city: {
          name: 'Gitega',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Gitega#/media/File:BU_Gitega_(11).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gitega',
        },
        landmarksRound: [
          { code: 'AZ', number: 2 },
          { code: 'BG', number: 2 },
          { code: 'IN', number: 1 },
        ],
        landmarkPlaces: [
          'Band-e Amir National Park',
          'Blue Eye Cave',
          'Blue Mountains',
          'Almsee',
          'Deception Island',
          'Mount Nyriagongo',
          "Tiger's Nest",
        ],
        uniqueId: 'SsLlRfNw',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Burundi',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/counterculturecoffee/7487178892/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burundi',
        },
        landmark: {
          name: 'Kibira National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lush-green-forest-filled-with-lots-of-trees-NO0rl-65Tf4',
          wikiLink: 'https://en.wikipedia.org/wiki/Kibira_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Gitega',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-red-and-white-house-with-a-balcony-YlzfGexgBX0',
          wikiLink: 'https://en.wikipedia.org/wiki/Gitega',
        },
        landmarksRound: [
          { code: 'KI', number: 2 },
          { code: 'AF', number: 3 },
          { code: 'MG', number: 1 },
        ],
        landmarkPlaces: [
          'Gravensteen',
          'Emily Bay',
          'Church of Saint Lazarus',
          'Stone House',
          "Dunn's River Falls",
          'Chapel of the Snows',
          'Fort Nuestra Senora de la Soledad',
        ],
        uniqueId: 'rMhRkz74',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Muramvya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lush-green-hillside-covered-in-lots-of-trees-UA_RKy_75yM',
          wikiLink: 'https://en.wikipedia.org/wiki/Muramvya',
        },
        landmark: {
          name: 'Gishora Drum Sanctuary',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/16938270@N00/52805138084/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gishora',
          hasLandmark: true,
        },
        city: {
          name: 'Muramvya',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/31740399@N07/6286686885/',
          wikiLink: 'https://en.wikipedia.org/wiki/Muramvya',
        },
        landmarksRound: [
          { code: 'BF', number: 3 },
          { code: 'DO', number: 1 },
          { code: 'LV', number: 3 },
        ],
        landmarkPlaces: [
          'Aleppo Citadel',
          'Salomon Islands',
          'Oceanographic Museum of Monaco',
          'Belovezhskaya Pushcha National Park',
          'Cristo de La Misericordia',
          'Al Saleh Mosque',
          'Fort Charlotte',
        ],
        uniqueId: '4sd6S35w',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Cambodia',
    code: 'KH',
    capital: 'Phnom Penh',
    lat: 13,
    long: 105,
    cities: [
      'Siem Reap',
      'Phnom Penh',
      'Krong Battambang',
      'Samraong',
      'Krong Kracheh',
      'Battambang',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/OvEr7BwXxxg',
          wikiLink: 'https://en.wikipedia.org/wiki/Angkor_Wat',
        },
        landmark: {
          name: 'Angkor Wat',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/angkor-wat-temple-amazing-934094/',
          wikiLink: 'https://en.wikipedia.org/wiki/Angkor_Wat',
          hasLandmark: true,
        },
        city: {
          name: 'Siem Reap',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Siem_Reap#/media/File:Siem_Reap_Skyline_at_Night.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Siem_Reap',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'zHPNVrlx',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/travel-tourist-cambodia-island-6268605/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cambodia',
        },
        landmark: {
          name: 'Silver Pagoda',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/silver-pagoda-buddhist-temple-7149961/',
          wikiLink: 'https://en.wikipedia.org/wiki/Silver_Pagoda',
          hasLandmark: true,
        },
        city: {
          name: 'Phnom Penh',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/asia-modern-tower-district-5392799/',
          wikiLink: 'https://en.wikipedia.org/wiki/Phnom_Penh',
        },
        landmarksRound: [
          { code: 'CY', number: 2 },
          { code: 'HN', number: 1 },
          { code: 'GT', number: 1 },
        ],
        landmarkPlaces: [
          'Palace of Versailles',
          'Wat Arun',
          'Annandale Waterfall',
          'Tash Rabat',
          'Al Fateh Grand Mosque',
          'Independence Monument',
          'Wieliczka Salt Mine',
        ],
        uniqueId: 'yywQqlpZ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Angkor Wat',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9zXMb-E8pI0',
          wikiLink: 'https://en.wikipedia.org/wiki/Angkor_Wat',
        },
        landmark: {
          name: 'Terrace of the Elephants',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cambodia-angkor-temple-story-asia-603396/',
          wikiLink: 'https://en.wikipedia.org/wiki/Terrace_of_the_Elephants',
          hasLandmark: true,
        },
        city: {
          name: 'Phnom Penh',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/MndC6D5zNDs',
          wikiLink: 'https://en.wikipedia.org/wiki/Phnom_Penh',
        },
        landmarksRound: [
          { code: 'AZ', number: 1 },
          { code: 'BD', number: 1 },
          { code: 'CK', number: 1 },
        ],
        landmarkPlaces: [
          'Oriental Pearl TV Tower',
          'Qargha Reservoir',
          'Amberd',
          "St. John's Cathedral",
          'Kalandula Falls',
          'Perito Moreno Glacier',
          'Rila Monastery',
        ],
        uniqueId: 'MVlc2QvN',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Cambodia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-plants-on-brown-soil-under-white-sky-during-daytime-_A58yesiMCM',
          wikiLink: 'https://en.wikipedia.org/wiki/Cambodia',
        },
        landmark: {
          name: 'Bayon Temple',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/the-bayon-temple-in-siem-reap-5769435/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bayon',
          hasLandmark: true,
        },
        city: {
          name: 'Battambang',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Battambang#/media/File:Battambang_market._May_2000..jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Battambang',
        },
        landmarksRound: [
          { code: 'AM', number: 1 },
          { code: 'FI', number: 1 },
          { code: 'BO', number: 3 },
        ],
        landmarkPlaces: [
          'Almsee',
          'Okavango River',
          'Natural Pool',
          'Castillo De Los Tres Reyes Del Morro',
          'South Pole',
          'Unfinished Church',
          'Vardzia',
        ],
        uniqueId: 'S85ZL33D',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Kratié Province',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eyeview-photo-of-urban-houses-GaQzbTd4jO8',
          wikiLink: 'https://en.wikipedia.org/wiki/Krati%C3%A9_province',
        },
        landmark: {
          name: 'Ta Prohm ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/concrete-temple-with-tree-root-on-top-LAI8am-RdVM',
          wikiLink: 'https://en.wikipedia.org/wiki/Ta_Prohm',
          hasLandmark: true,
        },
        city: {
          name: 'Phnom Penh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-nighttime-3QcOZP97KHA',
          wikiLink: 'https://en.wikipedia.org/wiki/Phnom_Penh',
        },
        landmarksRound: [
          { code: 'TN', number: 1 },
          { code: 'EG', number: 4 },
          { code: 'NE', number: 1 },
        ],
        landmarkPlaces: [
          'Belau National Museum',
          'Europa Point Lighthouse',
          'The Blue Eye',
          'Jardin de Balata',
          'Narikala Fortress',
          'Pedra da Gávea',
          'Muzeu Historik Kombëtar',
        ],
        uniqueId: 'ZYMBYxHh',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Phnom Penh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-riding-motorcycles-down-a-street-QTwVrBpJ7ew',
          wikiLink: 'https://en.wikipedia.org/wiki/Phnom_Penh',
        },
        landmark: {
          name: 'Bayon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/grey-concrete-building-during-daytime-WT7wePyX8Ik',
          wikiLink: 'https://en.wikipedia.org/wiki/Bayon',
          hasLandmark: true,
        },
        city: {
          name: 'Battambang',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/montemare25/8585257870/',
          wikiLink: 'https://en.wikipedia.org/wiki/Battambang',
        },
        landmarksRound: [
          { code: 'MS', number: 3 },
          { code: 'TL', number: 2 },
          { code: 'BN', number: 1 },
        ],
        landmarkPlaces: [
          'Solis Theater',
          'Grand Mosque of Bobo-Dioulasso',
          'Loango National Park',
          'Anguilla Arch',
          'Baths of Antoninus',
          'Door to Hell',
          'Bellapais Abbey',
        ],
        uniqueId: 'ZwhNN4Mb',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Cameroon',
    code: 'CM',
    capital: 'Yaoundé',
    lat: 6,
    long: 12,
    cities: [
      'Douala',
      'Yaoundé',
      'Bamenda',
      'Bafoussam',
      'Garoua',
      'Maroua',
      'Lagdo',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/bZnciM7XIYw',
          wikiLink: 'https://en.wikipedia.org/wiki/Douala',
        },
        landmark: {
          name: 'Mount Cameroon',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mount-cameroon-africa-1489500/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Cameroon',
          hasLandmark: true,
        },
        city: {
          name: 'Douala',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Douala#/media/File:Ars&Urbis_International_Workshop_-_Emiliano_Gandolfi_36.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Douala',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'k39TTLXV',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/travel-nature-landscape-skirt-3269736/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cameroon',
        },
        landmark: {
          name: 'Cameroon Reunification',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Reunification_Monument#/media/File:Monument_Reunification_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Reunification_Monument',
          hasLandmark: true,
        },
        city: {
          name: 'Yaoundé',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Yaound%C3%A9#/media/File:Yaound%C3%A9_Palais_des_Congr%C3%A8s.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Yaound%C3%A9',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'x3zXwtt9',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Yaoundé',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/EllfOCOSDWA',
          wikiLink: 'https://en.wikipedia.org/wiki/Yaound%C3%A9',
        },
        landmark: {
          name: 'Basilique Marie-Reine-des-Apôtres',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Basilique_Marie-Reine-des-Ap%C3%B4tres#/media/File:Int%C3%A9rieur_Basilique_Marie-Reine_des_ap%C3%B4tres_de_Mvoly%C3%A9_2.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Basilique_Marie-Reine-des-Ap%C3%B4tres',
          hasLandmark: true,
        },
        city: {
          name: 'Douala',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/oTrwlvPvpVo',
          wikiLink: 'https://en.wikipedia.org/wiki/Douala',
        },
        landmarksRound: [
          { code: 'BE', number: 2 },
          { code: 'AO', number: 3 },
          { code: 'BZ', number: 3 },
        ],
        landmarkPlaces: [
          'Mir Castle',
          'Hofburg Palace',
          'Tsodilo',
          'Boali',
          'Illimani',
          'Lake Yoa',
          'Gravensteen',
        ],
        uniqueId: 'WTWT8t8H',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Cameroon',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/TdwJW7L5DXg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cameroon',
        },
        landmark: {
          name: 'Chutes de la Métché',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Chutes_de_la_M%C3%A9tch%C3%A9#/media/Fichier:Chute_de_Metche.jpg',
          wikiLink:
            'https://commons.wikimedia.org/wiki/Category:Waterfalls_in_Cameroon',
          hasLandmark: true,
        },
        city: {
          name: 'Yaoundé',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/YdHJrPZjRrY',
          wikiLink: 'https://en.wikipedia.org/wiki/Yaound%C3%A9',
        },
        landmarksRound: [
          { code: 'BF', number: 4 },
          { code: 'BT', number: 4 },
          { code: 'AF', number: 4 },
        ],
        landmarkPlaces: [
          'Sundarbans',
          'Gangtey Monastery',
          'Fort Montagu',
          'Blagaj Tekija',
          'Terrace of the Elephants',
          'Christmas Island National Park',
          'Herat Citadel',
        ],
        uniqueId: 'k2lC8FyV',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Cameroon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-small-town-from-a-hill-wqDNF4KcHuI',
          wikiLink: 'https://en.wikipedia.org/wiki/Cameroon',
        },
        landmark: {
          name: 'Reunification Monument',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/fischerfotos/10008325254/',
          wikiLink: 'https://en.wikipedia.org/wiki/Reunification_Monument',
          hasLandmark: true,
        },
        city: {
          name: 'Lagdo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-town-with-a-body-of-water-in-the-background-f6ruhwz9MFc',
          wikiLink: 'https://en.wikipedia.org/wiki/Lagdo',
        },
        landmarksRound: [
          { code: 'PY', number: 2 },
          { code: 'GD', number: 2 },
          { code: 'BA', number: 5 },
        ],
        landmarkPlaces: [
          'Iluissat Icefjord',
          'Gullfoss',
          'Independence Monument',
          'Saqsayhuaman',
          'Mount Yasur',
          'Kunta Kinteh Island',
          'Notre-Dame of Cap-Haitian',
        ],
        uniqueId: '5VlYylMb',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Cameroon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-dirt-road-with-trees-on-either-side-of-it-HVytxpsuIxg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cameroon',
        },
        landmark: {
          name: 'Basilica of Mary Queen of Apostles',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/franckmoudiki/18556100865/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Basilique_Marie-Reine-des-Ap%C3%B4tres',
          hasLandmark: true,
        },
        city: {
          name: 'Douala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-daytime-bZnciM7XIYw',
          wikiLink: 'https://ro.wikipedia.org/wiki/Douala',
        },
        landmarksRound: [
          { code: 'AT', number: 4 },
          { code: 'GQ', number: 4 },
          { code: 'LA', number: 1 },
        ],
        landmarkPlaces: [
          'Ostrog Monastery',
          'Sri Dalada Maligawa',
          'Plaza de España',
          'Two Lovers Point',
          'Oceanographic Museum of Monaco',
          'Bran Castle',
          'Mount Wilhelm',
        ],
        uniqueId: 'Qlq5jq9d',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Canada',
    code: 'CA',
    capital: 'Ottawa',
    lat: 60,
    long: -95,
    cities: [
      'Vancouver',
      'Toronto',
      'Quebec City',
      'Calgary',
      'Montreal',
      'Winnipeg',
      'Ottawa',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/uouH1bGxeeU',
          wikiLink: 'https://en.wikipedia.org/wiki/Ontario',
        },
        landmark: {
          name: 'CN Tower',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cn-tower-tower-toronto-sunset-7432218/',
          wikiLink: 'https://en.wikipedia.org/wiki/CN_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Vancouver',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/science-world-false-creek-vancouver-210775/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vancouver',
        },
        landmarksRound: [
          { code: 'TM', number: 1 },
          { code: 'ZA', number: 1 },
          { code: 'DK', number: 1 },
        ],
        landmarkPlaces: [
          "Diocletian's Palace",
          'Church of Our Savior',
          'Heydar Aliyev Center',
          'Blue Grotto',
          'Galle Fort',
          'Christ the Redeemer',
          'Taj Mahal',
        ],
        uniqueId: '3VC2ZKKc',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lighthouse-ocean-sea-coast-travel-1872998/',
          wikiLink: 'https://en.wikipedia.org/wiki/Canada',
        },
        landmark: {
          name: 'Niagara Falls',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/waterfall-boat-niagara-falls-canada-5050298/',
          wikiLink: 'https://en.wikipedia.org/wiki/Niagara_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Toronto',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/toronto-skyline-cityscape-164813/',
          wikiLink: 'https://en.wikipedia.org/wiki/Toronto',
        },
        landmarksRound: [
          { code: 'BS', number: 2 },
          { code: 'CH', number: 1 },
          { code: 'MA', number: 2 },
        ],
        landmarkPlaces: [
          'African Renaissance Monument',
          'Tegucigalpa Cathedral',
          'Registan Square',
          'Grandvalira',
          'Villa Folio',
          'Kathmandu Durbar Square',
          'Galle Fort',
        ],
        uniqueId: 'B5h6fBKN',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Lake Louise',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/iJTXWlMmoOg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Louise,_Alberta',
        },
        landmark: {
          name: 'Capilano Suspension Bridge',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/cnj0k9wYEAk',
          wikiLink: 'https://en.wikipedia.org/wiki/Capilano_Suspension_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Quebec City',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/p5XgTYTFEaU',
          wikiLink: 'https://en.wikipedia.org/wiki/Quebec_City',
        },
        landmarksRound: [
          { code: 'BN', number: 4 },
          { code: 'VG', number: 4 },
          { code: 'AW', number: 3 },
        ],
        landmarkPlaces: [
          'Elephant Island',
          'Matafao Peak',
          'Oceania House',
          "Diocletian's Palace",
          'Tsitsernakaberd, Armenian Genocide Memorial',
          'Great Blue Hole',
          'Anguilla Arch',
        ],
        uniqueId: 'Dk35JmWF',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Canada',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/MQlVnTc4OBg',
          wikiLink: 'https://en.wikipedia.org/wiki/Canada',
        },
        landmark: {
          name: 'Château Frontenac',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-tower-on-top-of-a-hill-mWcg6CsDS_g',
          wikiLink: 'https://en.wikipedia.org/wiki/Ch%C3%A2teau_Frontenac',
          hasLandmark: true,
        },
        city: {
          name: 'Winnipeg',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/RLtFZZa30vY',
          wikiLink: 'https://en.wikipedia.org/wiki/Winnipeg',
        },
        landmarksRound: [
          { code: 'BZ', number: 1 },
          { code: 'BY', number: 1 },
          { code: 'BZ', number: 4 },
        ],
        landmarkPlaces: [
          'Salar de Uyuni',
          'Fortress of São Miguel',
          'Ouagadougou Cathedral',
          'Pedro St. James Castle',
          'Hofburg Palace',
          'Monteverde Cloud Forest',
          'Kalandula Falls',
        ],
        uniqueId: '2cGc85zF',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Moraine Lake',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/scenery-of-mountain-oMneOBYhJxY',
          wikiLink: 'https://en.wikipedia.org/wiki/Moraine_Lake',
        },
        landmark: {
          name: 'Niagara Falls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/time-lapse-photography-of-waterfalls-wZLsZ9DRLSI',
          wikiLink: 'https://en.wikipedia.org/wiki/Niagara_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Vancouver',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-photography-of-city-2YRocbpCVeY',
          wikiLink: 'https://en.wikipedia.org/wiki/Vancouver',
        },
        landmarksRound: [
          { code: 'JM', number: 1 },
          { code: 'GM', number: 3 },
          { code: 'MR', number: 4 },
        ],
        landmarkPlaces: [
          'Bran Castle',
          'Two Lovers Point',
          'Guaita Tower',
          'Parque Nacional de Malabo',
          'Domes of Fabedougou',
          'Fort Montagu',
          'Mount Yasur',
        ],
        uniqueId: 'BxsvRhlF',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Canada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-island-with-bridge-Yc9h5SJdEzI',
          wikiLink: 'https://en.wikipedia.org/wiki/Canada',
        },
        landmark: {
          name: 'Casa Loma',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-castle-like-building-with-a-flag-flying-in-front-of-it-rf2CbLnl6xY',
          wikiLink: 'https://en.wikipedia.org/wiki/Casa_Loma',
          hasLandmark: true,
        },
        city: {
          name: 'Toronto',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/oriental-pearl-tower-beside-buildings-UG-m_ngzMFM',
          wikiLink: 'https://en.wikipedia.org/wiki/Toronto',
        },
        landmarksRound: [
          { code: 'CV', number: 2 },
          { code: 'LV', number: 4 },
          { code: 'HK', number: 1 },
        ],
        landmarkPlaces: [
          'Bissau Cathedral',
          'Tsingy De Bemaraha',
          'Pyramids of Meroe',
          'Cape Coast Castle',
          'Parthenon',
          'Bab Al Bahrain',
          'Villers Abbey',
        ],
        uniqueId: 'lfkDzSmf',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Cape Verde',
    code: 'CV',
    capital: 'Praia',
    lat: 16,
    long: -24,
    cities: [
      'Praia',
      'Mindelo',
      'Sao Filipe',
      'Assomada',
      'Sal Rei',
      'Santa Maria',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-1EKogAcUNc',
          wikiLink: 'https://en.wikipedia.org/wiki/Sal,_Cape_Verde',
        },
        landmark: {
          name: 'Sao Filipe Royale Fortress',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Cape_Verde_Santiago_Fort_Real_de_S_Filipe.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Forte_Real_de_S%C3%A3o_Filipe',
          hasLandmark: true,
        },
        city: {
          name: 'Mindelo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mindelo#/media/File:Cabo_2010_Monte_Cara.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mindelo',
        },
        landmarksRound: [
          { code: 'CD', number: 2 },
          { code: 'MT', number: 1 },
          { code: 'LT', number: 1 },
        ],
        landmarkPlaces: [
          'Motherland Monument',
          'Tomb of Hafez',
          'Tikal National Park',
          'Belem Tower',
          'Szechenyi Thermal Bath',
          'Kasubi Royal Tombs',
          'Matobo National Park',
        ],
        uniqueId: 'jLcTPXwX',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fisherman-sea-atlantic-island-678467/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Verde',
        },
        landmark: {
          name: 'Blue Eye Cave',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:2014_Cap_Verde._Sal._Blue_Eye.JPG',
          wikiLink: '',
          hasLandmark: true,
        },
        city: {
          name: 'Praia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Praia#/media/File:Praia_Ribeira_de_Palmarejo_(cropped).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Praia',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'mWzbXqFk',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cape Verde',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/lRowikzz4cw',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Verde',
        },
        landmark: {
          name: 'Dona Maria Pia Lighthouse',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dona_Maria_Pia_Lighthouse#/media/File:Dona_Maria_Pia_Lighthouse,_Praia_Santiago.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dona_Maria_Pia_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Sal Rei',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/pvrVbs1isIE',
          wikiLink: 'https://en.wikipedia.org/wiki/Sal_Rei',
        },
        landmarksRound: [
          { code: 'BD', number: 1 },
          { code: 'CZ', number: 2 },
          { code: 'CW', number: 1 },
        ],
        landmarkPlaces: [
          'Nesvizh Castle',
          'Xunantunich Mayan Ruins',
          'Amber Palace',
          'Lake Kivu',
          'Pendjari National Park',
          'Valle de Cocora',
          'Natural Pool',
        ],
        uniqueId: 'fyQBg2PR',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'São Vicente',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/7Zl9mnvMGxI',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Vicente,_Cape_Verde',
        },
        landmark: {
          name: 'Blue Eye Cave',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/darreni/5884045091',
          wikiLink: 'https://en.wikipedia.org/wiki/Buracona',
          hasLandmark: true,
        },
        city: {
          name: 'Santa Maria',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/XObsGoC25r0',
          wikiLink: 'https://en.wikipedia.org/wiki/Santa_Maria,_Cape_Verde',
        },
        landmarksRound: [
          { code: 'CO', number: 2 },
          { code: 'BR', number: 3 },
          { code: 'CC', number: 2 },
        ],
        landmarkPlaces: [
          'Tsitsernakaberd, Armenian Genocide Memorial',
          'Hofburg Palace',
          'Bahrain Fort',
          'Little Bay',
          'Amalienborg',
          'Pedro St. James Castle',
          'Matafao Peak',
        ],
        uniqueId: 'l636XTNG',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Cape Verde',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-standing-on-the-side-of-a-road-4zpXZBxOPkk',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Verde',
        },
        landmark: {
          name: 'Lighthouse Dona Maria Pia',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/100954775@N03/52169367394/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dona_Maria_Pia_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Sal Rei',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/50141659@N00/43266253845/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sal_Rei',
        },
        landmarksRound: [
          { code: 'PF', number: 2 },
          { code: 'AO', number: 5 },
          { code: 'AO', number: 1 },
        ],
        landmarkPlaces: [
          'Band-e Amir National Park',
          'National Museum of Slavery',
          'Morne Seychellois',
          'Matobo National Park',
          'Taputapuatea marae',
          'Victoria Falls Bridge',
          'Matafao Peak',
        ],
        uniqueId: 'DJgDtsFv',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Cape Verde',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-mountains-in-the-background-RucY9lINMsY',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Verde',
        },
        landmark: {
          name: 'Praia de Santa Maria',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ninamaia/49231380951/',
          wikiLink: 'https://en.wikipedia.org/wiki/Praia_de_Santa_Maria',
          hasLandmark: true,
        },
        city: {
          name: 'Mindelo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-standing-on-top-of-a-tarmac-p2zlP9aydfo',
          wikiLink: 'https://en.wikipedia.org/wiki/Mindelo',
        },
        landmarksRound: [
          { code: 'KM', number: 4 },
          { code: 'ZM', number: 4 },
          { code: 'YE', number: 1 },
        ],
        landmarkPlaces: [
          'Juche Tower',
          'Chishimba Falls',
          'Fiat Tagliero',
          'Curchi Monastery',
          'Fort James',
          'Al Alam Palace',
          'Prambanan Temple',
        ],
        uniqueId: '4txLNGjg',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    capital: 'George Town',
    lat: 19.5,
    long: -80.5,
    cities: [
      'George Town',
      'Bodden Town',
      'West Bay',
      'East End',
      'North Side',
      'West Bay',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/iTTiIKcV3-M',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
        landmark: {
          name: 'Pedro St. James Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Pedro_St._James_Castle#/media/File:Pedro_St._James_Castle_Restored.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pedro_St._James_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'West Bay',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:West_Bay,_Cayman_Islands_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/West_Bay,_Cayman_Islands',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'fWBDF5h6',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/grand-cayman-cayman-islands-2072631/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
        landmark: {
          name: 'Kittiwake Shipwreck',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/crdunn/9062785028/in/photostream/',
          wikiLink: 'https://en.wikipedia.org/wiki/USS_Kittiwake_(ASR-13)',
          hasLandmark: true,
        },
        city: {
          name: 'George Town',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/George_Town,_Cayman_Islands#/media/File:KY-Georgetown-01.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/George_Town,_Cayman_Islands',
        },
        landmarksRound: [
          { code: 'BS', number: 1 },
          { code: 'BE', number: 1 },
          { code: 'SI', number: 1 },
        ],
        landmarkPlaces: [
          'Narikala Fortress',
          'Hofburg Palace',
          'La Sagrada Familia',
          'Lukan Longshan Temple',
          'Boudhanath',
          'Pigeon Rocks',
          'Masjid al-Haram',
        ],
        uniqueId: 'sY7R2vvB',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cayman Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/N3WTjwUesdc',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
        landmark: {
          name: 'Hell',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Hell,_Grand_Cayman#/media/File:Hell_Grand_Cayman_(1).JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Hell,_Grand_Cayman',
          hasLandmark: true,
        },
        city: {
          name: 'George Town',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/George_Town,_Cayman_Islands#/media/File:Business_Area_of_George_Town.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/George_Town,_Cayman_Islands',
        },
        landmarksRound: [
          { code: 'BS', number: 4 },
          { code: 'CL', number: 1 },
          { code: 'CM', number: 1 },
        ],
        landmarkPlaces: [
          'Zaisan Memorial',
          'South Pole',
          'Animal Flower Cave',
          'English Harbour',
          'Boali',
          'We Are Our Mountains',
          'Vardzia',
        ],
        uniqueId: '4WnrsBs6',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Cayman Islands',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cayman-cayman-island-island-52328/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
        landmark: {
          name: 'Crystal Caves',
          photographer: '',
          imageLink: 'https://www.thingstodocayman.net/crystal-caves/',
          wikiLink: 'https://en.wikivoyage.org/wiki/Grand_Cayman',
          hasLandmark: true,
        },
        city: {
          name: 'Bodden Town',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Bodden_Town,_Cayman_Islands_-_panoramio_(1).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bodden_Town_(village)',
        },
        landmarksRound: [
          { code: 'BZ', number: 4 },
          { code: 'BA', number: 2 },
          { code: 'BY', number: 2 },
        ],
        landmarkPlaces: [
          'Mir Castle',
          'Dhakeshwari Temple',
          'Zaisan Memorial',
          'Emi Koussi',
          'Regina Mundi Cathedral',
          'Easter Island',
          'St. Nicholas Abbey Heritage Railway',
        ],
        uniqueId: '5tJ2Jg6W',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Cayman Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-boats-on-sea-during-daytime-BYBaMLHHsJc',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
        landmark: {
          name: 'Camana Bay',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/heatheronhertravels/32822932917/',
          wikiLink: 'https://en.wikipedia.org/wiki/Camana_Bay,_Cayman_Islands',
          hasLandmark: true,
        },
        city: {
          name: 'George Town',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-next-to-the-ocean-w2J8NFvI_Ao',
          wikiLink: 'https://en.wikipedia.org/wiki/George_Town,_Cayman_Islands',
        },
        landmarksRound: [
          { code: 'HT', number: 3 },
          { code: 'CR', number: 1 },
          { code: 'SN', number: 2 },
        ],
        landmarkPlaces: [
          'Great Mosque of Porto-Novo\n',
          'Philharmonic Garden ',
          "St. Peter's Basilica",
          'Merry Cemetery',
          'Blue Grotto',
          'We Are Our Mountains',
          'National Park of American Samoa',
        ],
        uniqueId: 'zRd7Bty5',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Cayman Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-palm-tree-in-front-of-a-row-of-white-buildings-2RL1CSvoVCo',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayman_Islands',
        },
        landmark: {
          name: 'Pedro St. James Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/andylatt/15892712587/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pedro_St._James_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Bodden Town',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/90848856@N02/8281121053/in/photolist-dBLUMc-dBLUdz-ny45Wc-dBLUzP-RP4qiV-pWzEaN-iBnra7-dzAonq-2nTT3E9-8uNEsG-9aSQnF-dU9N6G-fjgCHr-aTUBkT-pWzz57-GiGDC6-aUb5p8-qTvcS3-Pi9xa4-5SB5Qv-d5f6Kd-d5f3FY-rb3p4r-2q1P9Fd-qBa7g4-ngtfdh-aSd1j8-94pG9s-92f41V-qTqFLP-qRhQJm-iTwKD-A8iqbd-bWfp2T-iTwKu-qB1jcs-ngt5YV-qB9TYP-qTzKvD-qeeLVP-85XbKd-b3rx86-qTvjzb-GzMAN4-ddXLgE-pWNwTr-qRhNN7-iTwKH-qTvgXs-qAZzMY',
          wikiLink: 'https://en.wikipedia.org/wiki/Bodden_Town_(village)',
        },
        landmarksRound: [
          { code: 'BS', number: 3 },
          { code: 'AQ', number: 2 },
          { code: 'DO', number: 2 },
        ],
        landmarkPlaces: [
          'Škocjan Caves',
          'Fort Napoléon des Saintes',
          'Skansen',
          "Tiger's Nest",
          'Viru Gate',
          'Mosquée Al-Hamoudi',
          'Rhine Falls',
        ],
        uniqueId: '2hV2Q62C',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    capital: 'Bangui',
    lat: 7,
    long: 21,
    cities: [
      'Bangui',
      'Berbérati',
      'Bambari',
      'Bouar',
      'Kaga-Bandoro',
      'Bossangoa',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Central_African_Republic_1995.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
        landmark: {
          name: 'Notre Dame of Bangui Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cath%C3%A9drale_Notre-Dame_(Bangui)#/media/File:Une_foule_devant_la_Cath%C3%A9drale_de_Bangui,_le_7_mai_2018.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Cath%C3%A9drale_Notre-Dame_(Bangui)',
          hasLandmark: true,
        },
        city: {
          name: 'Berberati',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:R%C3%A9union_au_village.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Berb%C3%A9rati',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'QpCSzHRb',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/usarmyafrica/11382638006',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
        landmark: {
          name: 'Boali',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/eiti/4112150917',
          wikiLink: 'https://en.wikipedia.org/wiki/Boali',
          hasLandmark: true,
        },
        city: {
          name: 'Bangui',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bangui#/media/File:The_Bangui_City.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bangui',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'TWymSTlg',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Central African Republic',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Stoneworkers_in_the_Central_African_Republic_8.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
        landmark: {
          name: 'Manovo-Gounda St. Floris National Park',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/127284765@N06/15657724152/in/photostream/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Manovo-Gounda_St._Floris_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Bangui',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/afrikaforce/5243363609',
          wikiLink: 'https://en.wikipedia.org/wiki/Bangui',
        },
        landmarksRound: [
          { code: 'BF', number: 3 },
          { code: 'CN', number: 2 },
          { code: 'MN', number: 2 },
        ],
        landmarkPlaces: [
          'Quadiriki Caves',
          'Salomon Islands',
          'Spittal Pond',
          'Codrington College',
          'Château Frontenac',
          'Blue Eye Cave',
          "Diocletian's Palace",
        ],
        uniqueId: 'h8vGPxpb',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Central African Republic',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Central_African_Republic_1995.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
        landmark: {
          name: 'Chutes de Boali',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/buck_master/4332279921',
          wikiLink: 'https://en.wikipedia.org/wiki/Boali',
          hasLandmark: true,
        },
        city: {
          name: 'Berbérati',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/121515256@N06/23346120551/',
          wikiLink: 'https://en.wikipedia.org/wiki/Berb%C3%A9rati',
        },
        landmarksRound: [
          { code: 'CN', number: 1 },
          { code: 'EC', number: 1 },
          { code: 'AD', number: 4 },
        ],
        landmarkPlaces: [
          'Al Fateh Grand Mosque',
          'Blue Eye Cave',
          'Sydney Opera House',
          'Christmas Island National Park',
          'Hell',
          'Plaza Murillo',
          'Okavango River',
        ],
        uniqueId: 'Lk4qF3z5',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Central African Republic',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/gpforeducation/21630498240/',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
        landmark: {
          name: 'Chinko Nature Reserve',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/169885454@N05/50175983348/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chinko',
          hasLandmark: true,
        },
        city: {
          name: 'Bangui',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/48357015@N05/4433149467/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bangui',
        },
        landmarksRound: [
          { code: 'VC', number: 2 },
          { code: 'EE', number: 2 },
          { code: 'HK', number: 1 },
        ],
        landmarkPlaces: [
          'Saqsayhuaman',
          'Vajdahunyad Castle',
          'The Pinnacles Desert',
          'Joya De Ceren Archeological Site',
          "Memorial de l'Anse",
          'Cintra Bay',
          'Chamarel Waterfall',
        ],
        uniqueId: 'WBllkdpn',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Central African Republic',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/gpforeducation/21792402066/',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_African_Republic',
        },
        landmark: {
          name: 'Cathédrale Notre-Dame',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/142474034@N02/29541787663/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Cath%C3%A9drale_Notre-Dame_(Bangui)',
          hasLandmark: true,
        },
        city: {
          name: 'Berbérati',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/unminusca/53365768016/',
          wikiLink: 'https://en.wikipedia.org/wiki/Berb%C3%A9rati',
        },
        landmarksRound: [
          { code: 'FI', number: 2 },
          { code: 'UA', number: 3 },
          { code: 'GI', number: 1 },
        ],
        landmarkPlaces: [
          'Monument du 22 novembre',
          'Lake Piso',
          'Sule Pagoda',
          'Emily Bay',
          'Chamarel Waterfall',
          'Derinkuyu',
          'Philharmonic Garden ',
        ],
        uniqueId: 'hCQlTbCV',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Chad',
    code: 'TD',
    capital: "N'Djamena",
    lat: 15,
    long: 19,
    cities: ["N'Djamena", 'Abéché', 'Moundou', 'Sarh', 'Kelo', 'Lai'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caravan-desert-sahara-sand-camel-4483792/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sahara',
        },
        landmark: {
          name: 'Emi Koussi',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Emi_Koussi#/media/File:Emi_Koussi_inner_crater.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Emi_Koussi',
          hasLandmark: true,
        },
        city: {
          name: 'Moundou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Moundou#/media/File:La_ville_de_Moundou_4.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Moundou',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'q3S5lz6r',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/road-tree-africa-path-chad-696570/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chad',
        },
        landmark: {
          name: 'Lake Yoa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lake_Yoa#/media/File:Lac_Yoa2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Yoa',
          hasLandmark: true,
        },
        city: {
          name: "N'Djamena",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/kendoerr/15386229016',
          wikiLink: 'https://en.wikipedia.org/wiki/N%27Djamena',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'nm29lVpX',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Chad',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/isa-boutriau/8598444136/in/album-72157633005229113/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chad',
        },
        landmark: {
          name: 'Lakes of Ounianga',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/isa-boutriau/8747146365',
          wikiLink: 'https://en.wikipedia.org/wiki/Lakes_of_Ounianga',
          hasLandmark: true,
        },
        city: {
          name: "N'Djamena",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/8155146@N03/1388164417/in/photostream/',
          wikiLink: 'https://en.wikipedia.org/wiki/N%27Djamena',
        },
        landmarksRound: [
          { code: 'AL', number: 1 },
          { code: 'AZ', number: 4 },
          { code: 'CV', number: 4 },
        ],
        landmarkPlaces: [
          'Illimani',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Muzeu Historik Kombëtar',
          'Animal Flower Cave',
          'Quadiriki Caves',
          'Charles Bridge',
          'Queen Emma Bridge',
        ],
        uniqueId: 'tpTs9GCT',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Chad',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/isa-boutriau/8562596717/in/album-72157633005229113/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chad',
        },
        landmark: {
          name: "N'Djamena Grand Mosque",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/varlamov/9614486031',
          wikiLink: 'https://en.wikipedia.org/wiki/N%27Djamena_Grand_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Abéché',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ab%C3%A9ch%C3%A9#/media/File:Abech%C3%A91.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ab%C3%A9ch%C3%A9',
        },
        landmarksRound: [
          { code: 'BW', number: 3 },
          { code: 'BY', number: 3 },
          { code: 'AO', number: 2 },
        ],
        landmarkPlaces: [
          'Omar Ali Saifuddien Mosque',
          'Anguilla Arch',
          'Bahrain Fort',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Tsodilo',
          'Quadiriki Caves',
          'Mount Cameroon',
        ],
        uniqueId: '9H6mtQwM',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Chad',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/abstract-wallpaper-WDSeM4shYTA',
          wikiLink: 'https://en.wikipedia.org/wiki/Chad',
        },
        landmark: {
          name: "N'Djamena Grand Mosque",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/49384205@N04/4580172734/in/photolist-7KC3rH-7YJzMq-2ob135F-7yLzfX-2ob133X-7HeqB4',
          wikiLink: 'https://en.wikipedia.org/wiki/N%27Djamena_Grand_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Abéché',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/stopgenocidenow/2717637212/in/photolist-599AS1-9WHmZL-6VsPe6-DNsNt-DNth1-DNsXQ-vVudYw-DNthW-2oqL8vb-QYMqyM-SB3VhW-QYMkWR-QYMtpg-2oqNYYK-2dmpQCv-7R6k7B-248G73z-QYMhiD-2eK9a1K-2oqL8tc-RqcSwg-xMGnf-2eK9eEe-7oroHM-cgi3Gy-9Yqbdx-2ijkiRx-7ytEGM-xMGrR-ABZH2Y-ovhRJ3-ARxyVA-RnEKSu-BjK1zd-7rFPVv-BF5L8D-BknDmC-xMGiq-o7w58H-mxuxwE-7R9ANy-ofQM8g-n2cPBv-7sodfh-J4cZ7Y-4VyH56-xMGW3-QTCmvT-DNsi5-6bMUTP',
          wikiLink: 'https://en.wikipedia.org/wiki/Ab%C3%A9ch%C3%A9',
        },
        landmarksRound: [
          { code: 'PY', number: 1 },
          { code: 'TC', number: 1 },
          { code: 'AI', number: 3 },
        ],
        landmarkPlaces: [
          'Billionth Barrel Monument',
          'Champagne Beach',
          'Independence Monument',
          'Illimani',
          'Tatacoa Desert',
          'Petronas Towers',
          'Capilano Suspension Bridge',
        ],
        uniqueId: 'KZRyND9k',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: "N'Djamena",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/ismouz/4951716013/in/photolist-8xyQE2-8xC3vy-2ob1VYm-UUs5f-2oaW5cn-UUsjy-USRWz-UUzM3-USSNi-2ob123F-4QgzYk-2oc6xzp-21UYMRb-ivQ4Rr-2ocav1R-8xzA1v-8xCCuy-8xzAn8-8xzBnn-8xzBKr-6ax6pa-USSmv-UUtMy-aAhizp-aAhi9V-vJkM8-2dnqUnA-ab6wVs-USTzv-88sG8P-rQ5yYR-88wdEo-88wdSw-88sXx4-88vUZ1-2fy5JVV-88wdZ1-DNtHV-DNtBc-DNtTH-DNtUL-2n1F8mE-DNty4-DNu24-DNtDJ-66NVAQ-2hMZjvd-2n1FxXR-DNoKw-2oaYFY2',
          wikiLink: 'https://en.wikipedia.org/wiki/N%27Djamena',
        },
        landmark: {
          name: 'Aloba Arch',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/23532185684/in/photolist-BRsAXo-axuk3v-93aFAF-xncab-qGL4pE-ds7QPP-6PWqGT-dPCzJm-rehbSv-ds7QxV-ds8181-HaG7Rp-H8omjf-mvBZqx-de1sdH-mvDPMj-de1nKW-KqeMH-dZzaYX-mvBZYX-rkrhA8-rniP2K-rniNve-qGL1DQ-rniPxe-22xzHrq-2nr4QFC-2n9owkc-2naeKyf-2neKRT7-2nnynQF-2nhcw9k-2n9W1os-2nt6BKL-2nmHX2K-2pdCBwW-2noxFtZ-2nkULWb-2ncSrBc-2pdDFNM-2noDU8r-2npPdVp-2o1wKDJ-2nadmz6-2kmg972-rDEfnF-rniPTV-2nefpyQ-2nfWnkn-2nhiu71',
          wikiLink: 'https://en.wikipedia.org/wiki/Aloba_Arch',
          hasLandmark: true,
        },
        city: {
          name: 'Moundou',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/27216394@N05/4470682374/in/photolist-7P4q7A-7NZqFF-8yXh7E-4tjDBz-7P4nJo-7NZqFv-4tjDJ6-7P4nBG-cba3js-cba37S-4tjR4n-4toTVY-8JpBor-4tjR9B-8JpBUF-8JpBEi-dxB8ri-4tjRnD-4toGr9-4toGvU-7NZo12-4tjDLT-4qpJNH-4toGky-4qpJSt-mRiLc-4prSVh-4tjDDv-dfH3KC-5FnfQE-4tjDNR-4tR5Z3-4toTYm-e96Vjo-e97dXf-ebG8zq-e91DTp-4toTM1-4tjR7i-4toTHU-4qtPxC-e97gkd-26gCdD-4toU3u-dPpDne-4toGdW-4qpJQZ-2eJH4Mw-pcC58Y-26m66A',
          wikiLink: 'https://en.wikipedia.org/wiki/Moundou',
        },
        landmarksRound: [
          { code: 'BM', number: 4 },
          { code: 'GD', number: 4 },
          { code: 'CZ', number: 2 },
        ],
        landmarkPlaces: [
          'Bay of Kotor',
          "St. Stephen's Cathedral",
          'Fortress of São Miguel',
          'Annaberg Sugar Plantation',
          'Cathedral of Brasilica',
          "St. Tryphon's Cathedral",
          'Temple of Literature',
        ],
        uniqueId: 'P7p9WQJh',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Chile',
    code: 'CL',
    capital: 'Santiago',
    lat: -30,
    long: -71,
    cities: [
      'Vina del Mar',
      'Santiago',
      'Iquique',
      'Valparaíio',
      'La Serena',
      'Puerto Montt',
      'Valparaíso',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/H6KJ2D0LphU',
          wikiLink: 'https://en.wikipedia.org/wiki/Valpara%C3%ADso',
        },
        landmark: {
          name: 'Easter Island',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/chili-easter-island-moai-rapa-nui-1740813/',
          wikiLink: 'https://en.wikipedia.org/wiki/Easter_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Vina del Mar',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vi%c3%b1a-del-mar-chile-south-america-263227/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vi%C3%B1a_del_Mar',
        },
        landmarksRound: [
          { code: 'RO', number: 1 },
          { code: 'MZ', number: 1 },
          { code: 'BS', number: 2 },
        ],
        landmarkPlaces: [
          'Arahoho Blowhole',
          'Romney Manor',
          'Soroca Fort',
          'Senso-ji',
          'La Sagrada Familia',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Arecibo Observatory',
        ],
        uniqueId: 'RgHNqp7k',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/chile-mountains-lake-landscape-7430434/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chile',
        },
        landmark: {
          name: 'La Moneda Palace',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Palacio_de_La_Moneda_(Chile)#/media/File:Palacio_de_LaMoneda02.png',
          wikiLink:
            'https://en.wikipedia.org/wiki/Palacio_de_La_Moneda_(Chile)',
          hasLandmark: true,
        },
        city: {
          name: 'Santiago',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/santiago-chile-downtown-buildings-263235/',
          wikiLink: 'https://en.wikipedia.org/wiki/Santiago',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '5wypvvH9',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Chile',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GU02I9D60dQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Chile',
        },
        landmark: {
          name: 'Atacama Desert',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/HNOaMthcq0w',
          wikiLink: 'https://en.wikipedia.org/wiki/Atacama_Desert',
          hasLandmark: true,
        },
        city: {
          name: 'Valparaíio',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/KLwpGXi1FEI',
          wikiLink: 'https://en.wikipedia.org/wiki/Valpara%C3%ADso',
        },
        landmarksRound: [
          { code: 'TD', number: 1 },
          { code: 'CY', number: 2 },
          { code: 'AL', number: 4 },
        ],
        landmarkPlaces: [
          'Dolly Beach',
          'St. Alexander Nevsky Cathedral',
          'California Lighthouse',
          'Al Fateh Grand Mosque',
          'Turku Castle',
          'Emi Koussi',
          'Floralis Generica',
        ],
        uniqueId: 'XJMKHWDH',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Easter Island',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/anvAqsSbtms',
          wikiLink: 'https://en.wikipedia.org/wiki/Easter_Island',
        },
        landmark: {
          name: 'Valle de la Luna',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/M0hdMoA180k',
          wikiLink: 'https://en.wikipedia.org/wiki/Valle_de_la_Luna_(Chile)',
          hasLandmark: true,
        },
        city: {
          name: 'Iquique',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-a-body-of-water-in-the-background-JT0xFzx5auc',
          wikiLink: 'https://en.wikipedia.org/wiki/Iquique',
        },
        landmarksRound: [
          { code: 'BS', number: 1 },
          { code: 'VG', number: 1 },
          { code: 'BN', number: 1 },
        ],
        landmarkPlaces: [
          'Queen Emma Bridge',
          'Madriu-Perafita-Claror Valley',
          "Devil's Bridge",
          'National Shrine Basilica of our Lady of Las Lajas',
          'Bahrain Fort',
          'Château Frontenac',
          'Living Museum of Bujumbura',
        ],
        uniqueId: 'hqXBm3vY',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Las Condes',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-city-Y9Wp5keJsn8',
          wikiLink: 'https://en.wikipedia.org/wiki/Las_Condess',
        },
        landmark: {
          name: 'Easter Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-people-standing-on-rock-formation-during-sunset-thwOv7I363Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Easter_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Valparaíso',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-blue-and-yellow-houses-H6KJ2D0LphU',
          wikiLink: 'https://en.wikipedia.org/wiki/Valpara%C3%ADso',
        },
        landmarksRound: [
          { code: 'XK', number: 4 },
          { code: 'KH', number: 6 },
          { code: 'FI', number: 2 },
        ],
        landmarkPlaces: [
          'Shwedagon Pagoda',
          'Ruhyyet Palace',
          'Lahore Fort',
          'Billionth Barrel Monument',
          'Gibbs Hill Lighthouse',
          'Annaberg plantation',
          'Hofburg Palace',
        ],
        uniqueId: 'FXf9ZbVL',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'San Pedro de Atacama',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-grass-on-brown-soil-during-day-time-612hZnDoDE4',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Pedro_de_Atacama',
        },
        landmark: {
          name: 'Atacama Large Millimeter Array',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-array-of-satellite-dishes-sitting-on-top-of-a-sandy-beach-DYCU2M4wZ_E',
          wikiLink:
            'https://en.wikipedia.org/wiki/Atacama_Large_Millimeter_Array',
          hasLandmark: true,
        },
        city: {
          name: 'Santiago',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-city-buildings-5W8fDb8f89s',
          wikiLink: 'https://en.wikipedia.org/wiki/Santiago',
        },
        landmarksRound: [
          { code: 'CK', number: 4 },
          { code: 'BR', number: 6 },
          { code: 'ZM', number: 4 },
        ],
        landmarkPlaces: [
          'Rock of Gibraltar',
          'Point Udal',
          'Dragon and Tiger Pagodas',
          'Moroccan sand wall',
          'Argentine Military Cemetery',
          'Villa Folio',
          'Kokoda Track',
        ],
        uniqueId: 'MxTzFQ5D',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'China',
    code: 'CN',
    capital: 'Beijing',
    lat: 35,
    long: 105,
    cities: [
      'Nanjing',
      'Beijing',
      'Guangzhou',
      'Chengdu',
      'Shenzhen',
      'Shanghai',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Pnc2Uxb7PG0',
          wikiLink: 'https://en.wikipedia.org/wiki/Nanjing',
        },
        landmark: {
          name: 'Oriental Pearl TV Tower',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/shanghai-the-bund-2422329/',
          wikiLink: 'https://en.wikipedia.org/wiki/Oriental_Pearl_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Shanghai',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/night-city-shanghai-asia-east-4694750/',
          wikiLink: 'https://en.wikipedia.org/wiki/Shanghai',
        },
        landmarksRound: [
          { code: 'PK', number: 1 },
          { code: 'CA', number: 1 },
          { code: 'BR', number: 1 },
        ],
        landmarkPlaces: [
          'Ocean Park',
          'Ouzoud Falls',
          'George Washington House',
          'Church of Saint Lazarus',
          'Monument to the Heroes of the Restoration',
          'Big Ben',
          'Trevi Fountain',
        ],
        uniqueId: 'CBx73K7w',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/yellow-mountains-mountain-ranges-532857/',
          wikiLink: 'https://en.wikipedia.org/wiki/Huangshan',
        },
        landmark: {
          name: 'Forbidden City',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/china-beijing-tiananmen-4644682/',
          wikiLink: 'https://en.wikipedia.org/wiki/Forbidden_City',
          hasLandmark: true,
        },
        city: {
          name: 'Nanjing',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/architecture-photography-nanjing-2187968/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nanjing',
        },
        landmarksRound: [
          { code: 'AE', number: 2 },
          { code: 'BE', number: 2 },
          { code: 'RO', number: 2 },
        ],
        landmarkPlaces: [
          'Epupa Falls',
          'Sukhbaatar Square',
          'Hagia Sophia',
          'Angel Falls',
          'Voortrekker Monument',
          'Langkawi Sky Bridge',
          'Patuxay Monument',
        ],
        uniqueId: 'rLxVkKX2',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'China',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/zboO0K1WfY4',
          wikiLink: 'https://en.wikipedia.org/wiki/China',
        },
        landmark: {
          name: 'Potala Palace',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Lnrb3inbrVw',
          wikiLink: 'https://en.wikipedia.org/wiki/Potala_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Guangzhou',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8HzjyDDgbJg',
          wikiLink: 'https://en.wikipedia.org/wiki/Guangzhou',
        },
        landmarksRound: [
          { code: 'BG', number: 4 },
          { code: 'IO', number: 2 },
          { code: 'CM', number: 1 },
        ],
        landmarkPlaces: [
          'Sao Filipe Royale Fortress',
          'Paradise Island',
          'California Lighthouse',
          'Taj Mahal',
          'Lake Kivu',
          'Pendjari National Park',
          'Seven Rile Lakes',
        ],
        uniqueId: 'btgyB7G6',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Zhangjiajie',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gii7lF4y0WY',
          wikiLink:
            'https://en.wikipedia.org/wiki/Zhangjiajie_National_Forest_Park',
        },
        landmark: {
          name: 'Leshan Giant Buddha',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/sarmu/4864929555',
          wikiLink: 'https://en.wikipedia.org/wiki/Leshan_Giant_Buddha',
          hasLandmark: true,
        },
        city: {
          name: 'Beijing',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vEY7dhDe5Nk',
          wikiLink: 'https://en.wikipedia.org/wiki/Beijing',
        },
        landmarksRound: [
          { code: 'IO', number: 1 },
          { code: 'CV', number: 3 },
          { code: 'BR', number: 2 },
        ],
        landmarkPlaces: [
          'Natural Pool',
          'Boali',
          'Pedro St. James Castle',
          'Pendjari National Park',
          'Arenal Volcano',
          'Viewpoint of the Moon',
          'Pulu Keeling National Park',
        ],
        uniqueId: 'JCkVZsPm',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Fenghuang County',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-boat-on-body-of-water-near-buildings-Y-HwuSKt6gM',
          wikiLink: 'https://en.wikipedia.org/wiki/Fenghuang_County',
        },
        landmark: {
          name: 'CCTV Headquarters',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-and-gray-curtain-building-during-daytime-tMnfOM23wqk',
          wikiLink: 'https://en.wikipedia.org/wiki/CCTV_Headquarters',
          hasLandmark: true,
        },
        city: {
          name: 'Shanghai',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-high-rise-buildings-under-cloudy-sky-kDpUZy7PaXM',
          wikiLink: 'https://en.wikipedia.org/wiki/Shanghaii',
        },
        landmarksRound: [
          { code: 'IL', number: 3 },
          { code: 'IS', number: 2 },
          { code: 'SN', number: 2 },
        ],
        landmarkPlaces: [
          'Lake Koman',
          'Gediminas Castle Tower',
          'Mumbo Island',
          'Elephant Island',
          'Riffa Fort',
          'Blue Grotto',
          'Tokyo Skytree',
        ],
        uniqueId: 'Kv7Qr47w',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Namtso',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/mountain-near-on-body-of-water-photography-8BH_M-53CyA',
          wikiLink: 'https://en.wikipedia.org/wiki/Namtso',
        },
        landmark: {
          name: 'Forbidden City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-at-forbidden-city-in-china-during-daytime-yBroAF1cN3II',
          wikiLink: 'https://en.wikipedia.org/wiki/Forbidden_City',
          hasLandmark: true,
        },
        city: {
          name: 'Guangzhou',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-city-buildings-_yHk5OH6QyY',
          wikiLink: 'https://en.wikipedia.org/wiki/Guangzhou',
        },
        landmarksRound: [
          { code: 'FJ', number: 1 },
          { code: 'BD', number: 6 },
          { code: 'HM', number: 3 },
        ],
        landmarkPlaces: [
          'Chapel of the Snows',
          'Köl-Suu',
          'Fortaleza de São José da Amura',
          'Skopje Fortress',
          'Day Forest National Park',
          'Chamarel Waterfall',
          'Buada Lagoon',
        ],
        uniqueId: '2tB4nV7K',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    capital: 'Flying Fish Cove',
    lat: -10.5,
    long: 105.6666667,
    cities: ['Flying Fish Cove', 'The Settlement', ''],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gm5iCSYrLpQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island_red_crab',
        },
        landmark: {
          name: 'Christmas Island National Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Christmas_Island_National_Park#/media/File:Idyllic_Bathing_Pool_(25513646381).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Christmas_Island_National_Park',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'fYRCb42r',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Flying_Fish_Cove_at_Christmas_Island.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Flying_Fish_Cove',
        },
        landmark: {
          name: 'Dolly Beach',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/25232760539',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island#Beaches',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '7lkdDmkJ',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Christmas Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/kristianbell/10672861884',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island',
        },
        landmark: {
          name: 'Lily Beach',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Christmas_Island#/media/File:Lily-Beach-Christmas-Island-881x588.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Flying Fish Cove',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/103533263@N07/19412870832',
          wikiLink: 'https://en.wikipedia.org/wiki/Flying_Fish_Cove',
        },
        landmarksRound: [
          { code: 'CA', number: 4 },
          { code: 'CD', number: 1 },
          { code: 'CK', number: 1 },
        ],
        landmarkPlaces: [
          'Blue Eye Cave',
          'Amber Palace',
          'Alega Beach',
          'Atomium',
          'Bahrain Fort',
          'Omar Ali Saifuddien Mosque',
          'Perito Moreno Glacier',
        ],
        uniqueId: '3bkDQ5vg',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Christmas Island',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Christmas_Island#/media/File:The_first_dam_ever_built_on_Christmas_Island.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island',
        },
        landmark: {
          name: 'Ma Chor Nui Nui Temple',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rebeccabcli/3262094709',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'CA', number: 3 },
          { code: 'DK', number: 1 },
          { code: 'DZ', number: 1 },
        ],
        landmarkPlaces: [
          'Shoal Bay',
          'Regina Mundi Cathedral',
          'Vardzia',
          'Basilica del Voto Nacional',
          'Grand Mosque of Bobo-Dioulasso',
          'Queen Emma Bridge',
          'Leshan Giant Buddha',
        ],
        uniqueId: '8Pms5x5p',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Christmas Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/crabs-near-seashore-gm5iCSYrLpQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island',
        },
        landmark: {
          name: 'Flying Fish Cove',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/157514501@N07/50499304713/',
          wikiLink: 'https://en.wikipedia.org/wiki/Flying_Fish_Cove',
          hasLandmark: true,
        },
        city: {
          name: 'Flying Fish Cove',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/103533263@N07/19232799379/',
          wikiLink: 'https://en.wikipedia.org/wiki/Flying_Fish_Cove',
        },
        landmarksRound: [
          { code: 'BJ', number: 4 },
          { code: 'BA', number: 3 },
          { code: 'ZA', number: 2 },
        ],
        landmarkPlaces: [
          'Bay of Kotor',
          'Richat Structure',
          'Gidan Rumfa',
          'Larabanga Mosque',
          'Mosquee Al-Hamoudi',
          'Sibebe Rock',
          'Mount Cameroon',
        ],
        uniqueId: 'x3Fw65zq',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Christmas Island',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/geraintdraheim/19008368439/',
          wikiLink: 'https://en.wikipedia.org/wiki/Christmas_Island',
        },
        landmark: {
          name: 'Dolly Beach',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/dmunro100/28093823077/in/photolist-JNybPX-xMuKBD-yG4jh4-2dLkqey-xKj4tf-yCYQz3-ypPpi4-aeQjQV-Db8gD-x5YyEX-JNv9nt-St5G3f-St5GHo-apN5yx-hCpZXg-apMWBv-hFUYkp-hCod58-b7U1W2-6HY74y-hFWK9i-apQDrE-apQLUC-JuNQo-CdZKJL-27mUBRY-28o3Df7-WF9dHQ-27mUCmL-27mUBwQ-a7MC8v-28srAnx-8KX6CF-cRUGxh-28o3DM9-UQ9EAc-Db8gw-b64qCc-56E1Zc-cMUT1L-cMTJ9q-7TXR5b-T1gvF1-oTEtx5-CgiY2T-HnQadX-apkN7n-LJbFfh-2zzYsm-BiU2hX',
          wikiLink:
            'https://en.wikipedia.org/wiki/Christmas_Island_National_Park',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'CR', number: 3 },
          { code: 'MU', number: 4 },
          { code: 'GE', number: 2 },
        ],
        landmarkPlaces: [
          'Wieliczka Salt Mine',
          'Okavango River',
          'Hato Caves',
          'Kylemore Abbey',
          'Macau Tower',
          'Ribat of Sousse',
          'National Shrine Basilica of our Lady of Las Lajas',
        ],
        uniqueId: 'hLyGFM3N',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    capital: 'West Island',
    lat: -12.5,
    long: 96.83333333,
    cities: ['West Island', 'Bantam Village', ''],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Cocos_%28Keeling%29_Islands_2017_%2843%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
        landmark: {
          name: 'Pulu Keeling National Park',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Pulu_keeling_park.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Keeling',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'gpPWwTZc',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Cocos_%28Keeling%29_Islands_%2832206670676%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
        landmark: {
          name: 'Oceania House',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Oceania_House_%2824589304043%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Oceania_House',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Tg7LTdjv',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cocos (Keeling) Islands',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands#/media/File:Lagoon_Beach_(25152057091).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
        landmark: {
          name: 'Pulu Keeling National Park',
          photographer: '',
          imageLink:
            'https://en.wikivoyage.org/wiki/File:Pulu_Keeling_Nat_Park_banner.jpg#/media/File:North_Keeling_Island_01.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Keeling',
          hasLandmark: true,
        },
        city: {
          name: 'West Island',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/West_Island,_Cocos_(Keeling)_Islands#/media/File:Cocos_(Keeling)_Islands_Airport_-_RWY33.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/West_Island,_Cocos_(Keeling)_Islands',
        },
        landmarksRound: [
          { code: 'CO', number: 1 },
          { code: 'KY', number: 3 },
          { code: 'BS', number: 3 },
        ],
        landmarkPlaces: [
          'Villers Abbey',
          "Tiger's Nest",
          'Kalandula Falls',
          'Christ the Redeemer',
          'Cane Garden Bay',
          'Chutes de Boali',
          'Sao Filipe Royale Fortress',
        ],
        uniqueId: 'D5HSDqDT',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Cocos (Keeling) Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/24718365955',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
        landmark: {
          name: 'Oceania House',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Oceania_House#/media/File:Oceania_house.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Oceania_House',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'AU', number: 4 },
          { code: 'BG', number: 3 },
          { code: 'BG', number: 4 },
        ],
        landmarkPlaces: [
          'Fort Fincastle',
          'Mir Castle',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Blagaj Tekija',
          'National Museum of Bermuda',
          'Christmas Island National Park',
          'Plaza Murillo',
        ],
        uniqueId: '5DkbM4KT',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Cocos (Keeling) Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/gobblywink/8165988151/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
        landmark: {
          name: 'Oceania House ',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/heartstart/1883201433/',
          wikiLink: 'https://en.wikipedia.org/wiki/Oceania_House',
          hasLandmark: true,
        },
        city: {
          name: 'West Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/190303979@N02/50548937906/',
          wikiLink:
            'https://en.wikipedia.org/wiki/West_Island,_Cocos_(Keeling)_Islands',
        },
        landmarksRound: [
          { code: 'JO', number: 3 },
          { code: 'TD', number: 4 },
          { code: 'TC', number: 1 },
        ],
        landmarkPlaces: [
          'Basilique Marie-Reine-des-Apôtres',
          'Rabban Hormizd Monastery',
          "Dunn's River Falls",
          'California Lighthouse',
          'Jerash',
          'Timgad',
          'Château Frontenac',
        ],
        uniqueId: 'flHxkdpM',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Cocos (Keeling) Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/nevfarquah/1320290020/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands',
        },
        landmark: {
          name: 'North Keeling',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/category3design/6779669885/',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Keeling',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'BO', number: 6 },
          { code: 'GD', number: 2 },
          { code: 'RE', number: 1 },
        ],
        landmarkPlaces: [
          'Virgin of El Panecillo',
          'Leon Cathedral',
          'Patuxai',
          'Blagaj Tekija',
          'Tokyo Skytree',
          "Queen's Staircase",
          'Catedral da Sé',
        ],
        uniqueId: '25MrNS6G',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Colombia',
    code: 'CO',
    capital: 'Bogotá',
    lat: 4,
    long: -72,
    cities: [
      'Medellin',
      'Cali',
      'Bogotá',
      'Bucaramanga',
      'Barranquilla',
      'Cartagena',
      'Medellín',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/J0suKy48jfk',
          wikiLink: 'https://en.wikipedia.org/wiki/Cartagena,_Colombia',
        },
        landmark: {
          name: 'Valle de Cocora',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/colombia-el-bosque-de-palmas-4907636/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cocora_Valley',
          hasLandmark: true,
        },
        city: {
          name: 'Medellin',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/colombia-medellin-landscape-2722716/',
          wikiLink: 'https://en.wikipedia.org/wiki/Medell%C3%ADn',
        },
        landmarksRound: [
          { code: 'SN', number: 2 },
          { code: 'ME', number: 2 },
          { code: 'DZ', number: 1 },
        ],
        landmarkPlaces: [
          'Monday Falls',
          'Panfilov Park',
          'Motherland Monument',
          'Ocean Park',
          'Tjibaou Cultural Center',
          'Hazrat Ali Mazar',
          'Tash Rabat',
        ],
        uniqueId: '5Yx7G2n3',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-architecture-urban-bogota-4457801/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cundinamarca_Department',
        },
        landmark: {
          name: 'National Shrine Basilica of our Lady of Las Lajas',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/las-lajas-church-las-lajas-sanctuary-6611015/',
          wikiLink: 'https://en.wikipedia.org/wiki/Las_Lajas_Shrine',
          hasLandmark: true,
        },
        city: {
          name: 'Cali',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-cali-colombia-buildings-sky-3703330/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cali',
        },
        landmarksRound: [
          { code: 'BD', number: 1 },
          { code: 'VN', number: 2 },
          { code: 'EC', number: 1 },
        ],
        landmarkPlaces: [
          'Pyramiden',
          'Pyramids of Meroe',
          'Voortrekker Monument',
          'Senso-ji',
          "Memorial de l'Anse",
          'Tikal National Park',
          'Christiansborg Palace',
        ],
        uniqueId: 'QvrS2VrB',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Colombia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/smokey-mountain-with-coconut-palm-trees-XV4XUU7gWlk',
          wikiLink: 'https://en.wikipedia.org/wiki/Colombia',
        },
        landmark: {
          name: 'Tatacoa Desert',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/tatacoa-desert-villavieja-colombia-xGsg4K42lq8',
          wikiLink: 'https://en.wikipedia.org/wiki/Tatacoa_Desert',
          hasLandmark: true,
        },
        city: {
          name: 'Cartagena',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/multicolored-houses-L6T_6Rp2iEk',
          wikiLink: 'https://en.wikipedia.org/wiki/Cartagena,_Colombia',
        },
        landmarksRound: [
          { code: 'AS', number: 4 },
          { code: 'AQ', number: 3 },
          { code: 'BN', number: 2 },
        ],
        landmarkPlaces: [
          'Almsee',
          'Tsodilo',
          'National Museum of Bermuda',
          'Kastelholm Castle',
          'Catedral da Sé',
          'Cathedral of Brasilica',
          'Bellapais Abbey',
        ],
        uniqueId: 'HPMnClvF',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Guatapé',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-body-of-water-during-daytime-PRJWxwDmtsE',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatap%C3%A9',
        },
        landmark: {
          name: 'Castle San Felipe de Barajas',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/bzumwalt/24697659427',
          wikiLink:
            'https://en.wikipedia.org/wiki/Castle_San_Felipe_de_Barajas',
          hasLandmark: true,
        },
        city: {
          name: 'Bogotá',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/concrete-high-rise-buildings-at-daytime-QmxXYlyYgL8',
          wikiLink: 'https://en.wikipedia.org/wiki/Bogot%C3%A1',
        },
        landmarksRound: [
          { code: 'CF', number: 1 },
          { code: 'AR', number: 3 },
          { code: 'AM', number: 2 },
        ],
        landmarkPlaces: [
          'Donkey Sanctuary',
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Buenos Aires Obelisk',
          'Quadiriki Caves',
          'Oceania House',
          'Bayon Temple',
          'Almsee',
        ],
        uniqueId: 'bVqfQxtH',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Salento',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-beside-white-structure-ioLq-zmtITA',
          wikiLink: 'https://en.wikipedia.org/wiki/Salento,_Quind%C3%ADo',
        },
        landmark: {
          name: 'Castillo San Felipe de Barajas',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/eliasroviello/34801709211/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Castillo_San_Felipe_de_Barajas',
          hasLandmark: true,
        },
        city: {
          name: 'Cartagena',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-in-front-of-green-and-white-concrete-building-during-daytime-J0suKy48jfk',
          wikiLink: 'https://en.wikipedia.org/wiki/Cartagena,_Colombia',
        },
        landmarksRound: [
          { code: 'VI', number: 3 },
          { code: 'GY', number: 1 },
          { code: 'KH', number: 6 },
        ],
        landmarkPlaces: [
          'The Baths',
          "National Martyrs' Memorial",
          'Khost Mosque',
          'National Museum of Bermuda',
          'Hvalsey Church',
          'Sydney Opera House',
          'Sibebe Rock',
        ],
        uniqueId: 'HCb3CLYF',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Guatapé',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-surrounded-by-forest-1l6ntLyiUbI',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatap%C3%A9',
        },
        landmark: {
          name: 'Salt Cathedral of Zipaquirá',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/67529848@N04/27060629054/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Salt_Cathedral_of_Zipaquir%C3%A1',
          hasLandmark: true,
        },
        city: {
          name: 'Medellín',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-scraper-at-night-time-03gVOLHq9ec',
          wikiLink: 'https://en.wikipedia.org/wiki/Medell%C3%ADn',
        },
        landmarksRound: [
          { code: 'MD', number: 2 },
          { code: 'BM', number: 1 },
          { code: 'IT', number: 3 },
        ],
        landmarkPlaces: [
          'Pukapuka',
          'Castle Vaduz',
          'Leon Cathedral',
          'Basilica of Our Lady of Guadalupe',
          'Mount Fuji',
          'Bahrain Fort',
          'Notre Dame Auxiliatrice',
        ],
        uniqueId: '7trqMRKT',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Comoros',
    code: 'KM',
    capital: 'Moroni',
    lat: -12.16666666,
    long: 44.25,
    cities: [
      'Mutsamudu',
      'Moroni',
      'Domoni',
      'Ouani',
      'Ouellah',
      'Mitsamiouli',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Anjouan_-_Islands_of_Comoros.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Anjouan',
        },
        landmark: {
          name: 'Mount Karthala',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mount_Karthala#/media/File:Mount_Karthala_(11000398163).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Karthala',
          hasLandmark: true,
        },
        city: {
          name: 'Moroni',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Moroni_harbour_%282%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Moroni,_Comoros',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'JxcR5Y3r',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/dkeats/5975110268',
          wikiLink: 'https://en.wikipedia.org/wiki/Comoros',
        },
        landmark: {
          name: 'Chomoni Beach',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/10952257536',
          wikiLink: 'https://en.wikipedia.org/wiki/Comoros',
          hasLandmark: true,
        },
        city: {
          name: 'Mutsamudu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mutsamudu#/media/File:Mutsamudu_port1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mutsamudu',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'brxVPcm7',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Comoros',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/water4islands/29724351291/in/photostream/',
          wikiLink: 'https://en.wikipedia.org/wiki/Comoros',
        },
        landmark: {
          name: 'Mount Karthala',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mount_Karthala#/media/File:Karthala_volcano-Comoros.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Karthala',
          hasLandmark: true,
        },
        city: {
          name: 'Mutsamudu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/water4islands/29771335456/in/photostream/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mutsamudu',
        },
        landmarksRound: [
          { code: 'BJ', number: 2 },
          { code: 'AD', number: 4 },
          { code: 'AS', number: 4 },
        ],
        landmarkPlaces: [
          'Angkor Wat',
          'Lake Kivu',
          'Iguazu Falls',
          'Kastelholm Castle',
          'Lakes of Ounianga',
          'One Foot Island',
          'Christiansborg Palace',
        ],
        uniqueId: '6Vz34Vm5',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Ngazidja',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/23371486@N04/2501495107/in/photostream/',
          wikiLink: 'https://en.wikipedia.org/wiki/Grande_Comore',
        },
        landmark: {
          name: 'Lake Niamawi',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Lac_Niamawi#/media/Fichier:Old_volcanic_crater-Grande_Comore.jpg',
          wikiLink: 'https://fr.wikipedia.org/wiki/Lac_Niamawi',
          hasLandmark: true,
        },
        city: {
          name: 'Moroni',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Moroni,_Comoros#/media/File:Moroni-Harbour.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Moroni,_Comoros',
        },
        landmarksRound: [
          { code: 'AQ', number: 2 },
          { code: 'CC', number: 4 },
          { code: 'DZ', number: 4 },
        ],
        landmarkPlaces: [
          'Salomon Islands',
          'Spittal Pond',
          'Villers Abbey',
          'Gravensteen',
          'Seven Rile Lakes',
          'Gibbs Hill Lighthouse',
          'Tatacoa Desert',
        ],
        uniqueId: 'w7XGdh4k',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Comoros',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ipostcross/49138640558/',
          wikiLink:
            'https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://en.wikipedia.org/wiki/Comoros&ved=2ahUKEwjaosXXt-iHAxXl-QIHHZBlIQgQFnoECEYQAQ&usg=AOvVaw0Ybj0GIlqJBqE5thIeeNs_',
        },
        landmark: {
          name: 'Bouni Beach',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/8427686@N07/827278902/in/photolist-2g72cm',
          wikiLink: 'https://en.wikipedia.org/wiki/Bouni,_Comoros',
          hasLandmark: true,
        },
        city: {
          name: 'Moroni',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/hypermobility/29396708472/in/photolist-LMFPgf-LXhygV-4P6GJC-B1jzt-hyvuGK-B1nAg-L626p-XKLEpb-2W15bi-XKLEhY-4P6Dxj-41Xn33-EdfjJA-DgQ4Dk-24nnAD6-939k8q-2W15bD-XKLE3E-WK3Zv1-2dACr2S-2eBZeJ3-Syubm1-2eBZf2N-2dA2j6t-Syubz7-2eUf6p1-Syubaj-2dSU3ej-2468Cbt-2eUf7w1-2468C2a-2eUf5uA-2dSU5t9-2dACqLm-2468AQ2-QWf8jR-2dSU4GQ-2dACpP1-SyuatQ-2468AJv-XKLE9b-2eBZf6W-4GxqoN-2ei2dyE-QWfaAK-QWfahD-2W1nDt-2dACmEf-2eBZezW-2468Ecx',
          wikiLink: 'https://en.wikipedia.org/wiki/Moroni,_Comoros',
        },
        landmarksRound: [
          { code: 'TM', number: 2 },
          { code: 'AZ', number: 5 },
          { code: 'AM', number: 3 },
        ],
        landmarkPlaces: [
          'The Baths',
          'Pyramiden',
          'Henderson Island',
          'Blue Grotto',
          'Notre Dame of Bangui Cathedral',
          'Liwonde National Park',
          'Middle of the World City',
        ],
        uniqueId: 'JbxFGQlb',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Comoros',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/p_blonde/396139853/in/photolist-B1jzt-ARtUS-p4D9sJ-8e9efi-ARiBD-aahFDp-8YGvSN-B1nAg-L626p-B1J7u-B1nAa-L626X-8wwdov-B1J7w-w3EJmG-aDMob2-9st8qg-trEZDF-xKPPwc-ARiBG-4Ns6eS-hUhLKA-2mgSTr6-hUhLDw-AuhLQ6-4P8781-4GxpM5-L626T-hUhCW9-4P43cn-hUiuoc-rUQXbB-hUiyiM-hUhU1A-hUhaix-hUhNvN-hUit9i-hUhTG1-aDRgy1-hUhDZ1-21EJ2Ho-DgQ4Dk-4P2jo8-hUhrhM-mvqJVm-hUhMHL-hUhF2S-hUhPYh-2o7y4D2-4GxubY',
          wikiLink: 'https://en.wikipedia.org/wiki/Comoros',
        },
        landmark: {
          name: 'Mount Karthala',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/kbehrens/24351511251/in/photolist-hL4V8i-hG3wr2-7JspD3-nf34e1-D6RRUg-n1goDK-n4oqjB-n4qo7e-CgLx3P-mB34do-mGGmnP-mAYg1a-mGJMKP-mGFa6x-nf28bm-n4uoax-nf1TXN-mB27TT-nf1PRS-mQAaot-mQBPri-o2Vo34-n156Hm-mZXprR-mZWyJ1-qCFzD6-yw8fb-mZXPr5-BC2B5e-mZSRfM-DbX8pE-2nPruov',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Karthala',
          hasLandmark: true,
        },
        city: {
          name: 'Ouani',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/wawani007/8015900967/in/photolist-ddkA66-ddknWX-ddkJoW-PGzaVd-pykqM3-pwjPjL-ddkFzW-ddkKzB-ddkLik-ddkNnr-ddkGGp-ddkQTJ-ddkyXE-ddkph6-hQq4rQ-ev8FeV-ddkD1z-uhftqT-48o6Gc-ddkmGQ-QVxSz9-48otRM-48sweq-ddkEMQ-ddkfSe-ddkRet-ddkgVB-ddkirx-ddkE6f-ddke71-ddkevP-48o71a-ddkwrb-ddkCbF-ddkBNW-ddkUrr-ddkjXT-nxPEzy-ddkM1t-ddkxkq-dMTMu9-48o77i-novWCg-kbxjJ3-8ZwXSk-6d2WT8-6d2WXV-hxCPhW-8ZwXSg-8ZwXSe',
          wikiLink: 'https://en.wikipedia.org/wiki/Ouani',
        },
        landmarksRound: [
          { code: 'QA', number: 2 },
          { code: 'LY', number: 4 },
          { code: 'DM', number: 3 },
        ],
        landmarkPlaces: [
          'Atacama Large Millimeter Array',
          'Leptis Magna',
          'Pipeline Mosque',
          'Fort Antoine Theatre',
          'Matafao Peak',
          'Chinguetti',
          'Limu Pools',
        ],
        uniqueId: 'hksVFGrW',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    capital: 'Avarua',
    lat: -21.23333333,
    long: -159.7666667,
    cities: ['Arutanga', 'Avarua', 'Rarotonga', 'Tauhunu', 'Tupapa', 'Vaiaku'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.tripadvisor.com.au/Tourism-g294328-Cook_Islands-Vacations.html',
          wikiLink: 'https://en.wikipedia.org/wiki/Rarotonga',
        },
        landmark: {
          name: 'One Foot Island',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tapuaetai#/media/File:Aitutaki-Motu_Tapuaetai.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tapuaetai',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'pnWmLMgf',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/palms-tropical-beach-tropical-sand-3242342/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cook_Islands',
        },
        landmark: {
          name: 'Pukapuka',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Pukapuka#/media/File:Pukapuka_Aerial_efs_1280.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pukapuka',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'w5jqCKSf',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cook Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-on-boat-at-golden-hour-mz3-0G5VhrQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Cook_Islands',
        },
        landmark: {
          name: 'Te Rua Manga (The Needle)',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jefferyimages/4041360235',
          wikiLink: 'https://en.wikipedia.org/wiki/Te_Rua_Manga',
          hasLandmark: true,
        },
        city: {
          name: 'Arutanga',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/samandjo/5343466151',
          wikiLink: 'https://en.wikipedia.org/wiki/Arutanga',
        },
        landmarksRound: [
          { code: 'CU', number: 1 },
          { code: 'BM', number: 4 },
          { code: 'CV', number: 3 },
        ],
        landmarkPlaces: [
          'Bab Al Bahrain',
          'Pulu Keeling National Park',
          'Mount Karthala',
          'Mount Cameroon',
          'Salar de Uyuni',
          'Timgad',
          'Tsitsernakaberd, Armenian Genocide Memorial',
        ],
        uniqueId: 'TBNrpr9v',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Cook Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-daytime-PkgzxrkurRw',
          wikiLink: 'https://en.wikipedia.org/wiki/Cook_Islands',
        },
        landmark: {
          name: 'Rarotonga',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mho-online/22779775927',
          wikiLink: 'https://en.wikipedia.org/wiki/Rarotonga',
          hasLandmark: true,
        },
        city: {
          name: 'Avarua',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/machineman/6927517747',
          wikiLink: 'https://en.wikipedia.org/wiki/Avarua',
        },
        landmarksRound: [
          { code: 'BG', number: 1 },
          { code: 'BG', number: 4 },
          { code: 'BE', number: 2 },
        ],
        landmarkPlaces: [
          'Charles Bridge',
          'Pendjari National Park',
          'Casa de la Vall',
          'Gravensteen',
          'National Park of American Samoa',
          'Bab Al Bahrain',
          "Diocletian's Palace",
        ],
        uniqueId: 'bwqN2gCN',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Cook Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-palm-tree-with-a-half-moon-in-the-sky-ckc9ld5cOSU',
          wikiLink: 'https://en.wikipedia.org/wiki/Cook_Islands',
        },
        landmark: {
          name: 'Muri Lagoon',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/94469176@N07/31701907373/in/photolist-QioyzT-pU5jGX-2imxxLw-jk7Dzx-v6nba-BMNit-qtUcfM-3BwXU-vuZH7N-6TYy8c-QUXkCq-rAzZui-BMNif-2hv1zAJ-2o3PMiV-tdzGjQ-7uhpru-7uhoH1-7uhp2N-5qwZi1-7uduNR-7uhp9G-7udw7e-7uhoyb-7uhoM9-7uduwH-GgkSzS-7udwdD-7uhpDf-6FUAnY-2E9WW6-2o3NQuA-7udvSe-pqJaj8-VLYKBS-2hRRdhj-7udvdx-2o3PM9X-7uhomj-vdXtDd-2hRUHmE-rxGUhg-DBm6EQ-rvZmBf-rAtzJA-2o3Q91E-rvYR2T-2hR26zT-7Kru6D-2o3MhuN',
          wikiLink: 'https://en.wikipedia.org/wiki/Muri_Lagoon',
          hasLandmark: true,
        },
        city: {
          name: 'Arutanga',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/samandjo/5344074532/in/photolist-99eMb5-4nNani-6qodri-poU4GC-99bEjK-qaG9HX-cNqm4-4nN82v-4nN8Ce-4nN9bc-iNwp7H-6yusPk-6yyzKU-6yusUr-8b63ZY-8b2MTe-NLoEhq-srsz-P3cfWf-96vK9-PaVSw1-P3bs1w-P3Lefd-ALkyHQ-aphNm4-cNqjF-99vrXe-fvfW5k-fvgmVa-C92LYA-fvgg7P-fvvHoQ-8b2LxZ-P3L5nU-ALmpEh-apkwF5-aphNng-aphNmp-aphNja-apkwJ7-apkwEy-P3J3NL-P6fqdB-NLntAN-ECwinK-NLnRMb-P5JfjZ-P5FyGg-ALkjxA-24NTWHS',
          wikiLink: 'https://en.wikipedia.org/wiki/Arutanga',
        },
        landmarksRound: [
          { code: 'TK', number: 2 },
          { code: 'NC', number: 2 },
          { code: 'IO', number: 4 },
        ],
        landmarkPlaces: [
          'Karlštejn Castle',
          "Women's Bridge",
          'Donkey Sanctuary',
          'Herat Citadel',
          'Mount Nyriagongo',
          "Lac 'Assal",
          'Mount Karthala',
        ],
        uniqueId: 'G2TfZP3v',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Avarua',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/nullnfull/15857333430/in/photolist-ihXHYU-qanxrZ-iaCugT-i8A2WV-26Hqpyg-24aaPhf-2gTd9ZS-qafYLw-qrCSMP-qrKfYw-qrCQq4-qanjye-6yysxC-q9Qncw-q9Rfh9-q9RgsL-pupKGW-puPQ8S-qpx211-qrD9bp-qpwXud-q9Rpp7-qrkSGd-puq7DL-qp87vC-5RCqF4-6464fg-fyGecb-6HaHX9-2gSKQpm-6WqAof-GUxZke-2gSLpqc-rPTxVW-2pnLsft-2gSKueB-2gSKwBh-25oM7FG-2gSKRke-2oT35Gu-HNw7kM-qredAe-q9XEFr-q9Z85z-puPswW-6ef12D-4LJn7L-228uryW-vK2kN',
          wikiLink: 'https://en.wikipedia.org/wiki/Avarua',
        },
        landmark: {
          name: "St. Joseph's Cathedral",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/psyclistpaul/48254826051/in/photolist-2pvix29-akwGsZ-2gw7ABv',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Joseph%27s_Cathedral,_Avarua',
          hasLandmark: true,
        },
        city: {
          name: 'Tauhunu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/96541566@N06/51871040557/in/photolist-2hftCxY-N7GVHD-2oAQCeG-2n2EDct-5FYJ56',
          wikiLink: 'https://en.wikipedia.org/wiki/Tauhunu',
        },
        landmarksRound: [
          { code: 'CV', number: 3 },
          { code: 'BE', number: 3 },
          { code: 'ZM', number: 2 },
        ],
        landmarkPlaces: [
          'Basilica of Our Lady of Peace',
          "Devil's Bridge",
          'Blue Lagoon',
          'Ruhyyet Palace',
          'National Museum of Slavery',
          'Sundarbans',
          'Spittal Pond',
        ],
        uniqueId: 'jHPCYmXz',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    capital: 'San Jose',
    lat: 10,
    long: -84,
    cities: [
      'Cartago',
      'San Jose',
      'Alajuela',
      'Limon',
      'Puntarenas',
      'Heredia',
      'San José',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-TvZ6VB915M',
          wikiLink: 'https://en.wikipedia.org/wiki/Sloth',
        },
        landmark: {
          name: 'Monteverde Cloud Forest',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Monteverde_Cloud_Forest_Reserve#/media/File:Monteverde_puente.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Monteverde_Cloud_Forest_Reserve',
          hasLandmark: true,
        },
        city: {
          name: 'Cartago',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/moutains-cartago-costa-rica-2802462/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cartago,_Costa_Rica',
        },
        landmarksRound: [
          { code: 'FI', number: 1 },
          { code: 'VE', number: 2 },
          { code: 'ME', number: 2 },
        ],
        landmarkPlaces: [
          'Leaning Tower of Pisa',
          'The Grand Palace',
          'Akershus Fortress',
          'Easter Island',
          'Cathedral Our Lady of the Rosary',
          'Wieliczka Salt Mine',
          "St. Peter's Church",
        ],
        uniqueId: 'SPPXfFhh',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/volcano-eruption-costa-rica-arenal-718277/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nuevo_Arenal',
        },
        landmark: {
          name: 'Arenal Volcano',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/volcano-costa-rica-clouds-blue-sky-2355772/',
          wikiLink: 'https://en.wikipedia.org/wiki/Arenal_Volcano',
          hasLandmark: true,
        },
        city: {
          name: 'San Jose',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/San_Jos%C3%A9,_Costa_Rica#/media/File:San_Jose_2020_by_@caya_here-2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Jos%C3%A9,_Costa_Rica',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '5Wz4845y',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Costa Rica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-beside-blue-sea-xGhaXZtQb1s',
          wikiLink: 'https://en.wikipedia.org/wiki/Costa_Rica',
        },
        landmark: {
          name: 'Irazú Volcano',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/lake-at-the-center-of-mountain-at-daytime-wFNNzXgo0Do',
          wikiLink: 'https://en.wikipedia.org/wiki/Iraz%C3%BA_Volcano',
          hasLandmark: true,
        },
        city: {
          name: 'Limon',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/124651729@N04/42772726332',
          wikiLink: 'https://en.wikipedia.org/wiki/Lim%C3%B3n',
        },
        landmarksRound: [
          { code: 'BG', number: 4 },
          { code: 'AW', number: 1 },
          { code: 'BM', number: 1 },
        ],
        landmarkPlaces: [
          'Timgad',
          'Church of Saint Lazarus',
          'Lake Yoa',
          'Iguazu Falls',
          'Amber Palace',
          'Animal Flower Cave',
          'Living Museum of Bujumbura',
        ],
        uniqueId: 'nsmbfycP',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Costa Rica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-walking-on-hanging-bridge-surrounded-by-green-trees-during-daytime-RrAlOoykABQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Costa_Rica',
        },
        landmark: {
          name: 'Basilica of Our Lady of the Angels, Cartago',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_the_Angels,_Cartago#/media/File:Bas%C3%ADlica_Cartago.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_the_Angels,_Cartago',
          hasLandmark: true,
        },
        city: {
          name: 'Puntarenas',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mytravelphotos/11070455733',
          wikiLink: 'https://en.wikipedia.org/wiki/Puntarenas',
        },
        landmarksRound: [
          { code: 'CC', number: 1 },
          { code: 'BR', number: 1 },
          { code: 'BA', number: 3 },
        ],
        landmarkPlaces: [
          'Pulu Keeling National Park',
          'Seven Rile Lakes',
          'Mostar Old Bridge',
          'Villers Abbey',
          "N'Djamena Grand Mosque",
          'Hofburg Palace',
          'La Moneda Palace',
        ],
        uniqueId: 'mbQyt7BM',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Costa Rica',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/fifthfairway/2206624617/in/photolist-4mZwC2-7kjW2-2kP9ao3-feQapv-ff5t1J-feQiQX-ff5y8Q-feQ9UX-ff5sPu-feQbBH-ff5veq-ff5x77-feQbP8-feQb5v-feQdyg-feQcNe-feQak2-ff5xFU-ff5x33-feQaPr-ff5C3f-feQfsX-ebmoC3-ff5v7f-ff5vXh-feQdEX-7kj6i-feQiqP-ff5Bmy-feQhKK-feQk4P-ff5By5-23LqbRx-7iK8u-7rzNX-7tE2k-7sFB7-7kkhY-7sE7c-7rxt2-7tvhw-7sEH8-dLBUYT-7iGDa-7u1MW-7sEve-7tHCM-7kk7D-7tYfm-7rypf',
          wikiLink: 'https://en.wikipedia.org/wiki/Costa_Rica',
        },
        landmark: {
          name: 'Monteverde Cloud Forest Reserve',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/59238173@N07/51980293593/in/photolist-2ncjAjv-2iDudB3-2iDudAw-2jyJyUF-2iDudyh-2pDjQK9-2i1QdER-2i1Qd8o-2i1LCGH-2i1P3ZK-2i1P3TT-2i1LCpP-2i1QdAH-2i1Qeu6-2i1QcRg-2i1LDeV-2i1P38e-2i1P3D4-2i1QcL1-2i1QcBy-2i1P49N-2i1LC7Q-2i1LDrt-FWeT1M-4CmPr1-2nFnLeW-2q4DuVV-2oxV4Ek-2oBJVRS-2oxV4GK-2oy1e2C-2oBJVKQ-2oxYRNJ-2oxZXwg-2oBGzMk-2oBGzMv-2oBGzDj-2oBGzDu-2oxZXtW-2oBJyVR-2oxV4Ln-2oBJyP8-2oBHnEa-2oBJyPt-2oBHnvT-2oxYRNU-2oBGzHC-2oBJVB3-kfRQ2R-2q6btTp',
          wikiLink:
            'https://en.wikipedia.org/wiki/Monteverde_Cloud_Forest_Reserve',
          hasLandmark: true,
        },
        city: {
          name: 'San José',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/sulitskiy/49537203098/in/photolist-2itr7CA-26uqzPe-xsTsqY-2j4xCxJ-2otxehm-2itxeTy-dgZt2k-dgZsU5-dgZt7H-dgZsSC-dgZt42-zrHZX5-fSvFpU-XYpRbp-2itrbvP-4xWwBB-RSCW1d-2itUyKQ-a6LYU8-9ww5u6-9wz32h-2o36QUD-s3ByHr-9wz2NN-K1XTq7-2oukp7R-2n6Uakt-8mdptA-28PsUcx-9CwH-2o7L3Di-RFqmP1-2n8adt3-2pvz4B6-2kYC5e7-SDew39-PiWFqh-2otk8xs-Rg65NQ-8re5p7-WScQ11-EazjU4-2iRVXRL-9wyUh5-26zh7wV-2hKXbF-EgoYN-SPqht7-EJPPp3-2n53bKB',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Jos%C3%A9,_Costa_Rica',
        },
        landmarksRound: [
          { code: 'CL', number: 4 },
          { code: 'VG', number: 6 },
          { code: 'TG', number: 2 },
        ],
        landmarkPlaces: [
          'Hofburg Palace',
          'Neveh Shalom Synagogue',
          'Kamianets-Podilskyi Castle',
          'Lake Piso',
          'Ampitheater of El Jem',
          'Ceking Rice Terrace',
          'Pierre Savorgnan de Brazza Memorial',
        ],
        uniqueId: 'xJjYxzTg',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Costa Rica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-under-blue-sky-0cPWvLRhadE',
          wikiLink: 'https://en.wikipedia.org/wiki/Costa_Rica',
        },
        landmark: {
          name: 'National Museum of Costa Rica     ',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/sulitskiy/49537216161/in/photolist-2itrbvP-4VEPre-ybgURi-c3TMpQ-249a3WQ-S2QhUs-7o98R3-2q8w4Pf-yQy421-FPR7QH-WNUtsg-2G5QbW-2q8azm3-2eT3kwt-2oPB3a4-KbJ7AV-2oJdgt9-2kQPuDa-2oStVp6-2nVeQ32-2oHwtJG-XW5HhJ-FtsH9T-7o5eJr-7o994h-2o53sK8-2mLkRAj-2onMhZj-2nMRLFM-2oGZcJS-2nAgpZC-2jvucrv-2oy7QYC-2nTbBSB-2nkKXoB-2mMcu67-2oG5hW3-2oxCuGn-2nYntMs-2oN5hws-2nWguDy-2iFNYCE-2mDW19o-2oKbPyS-2jkfgKE-2njrifP-2nTC8tY-2n4vqoU-2oJsGXU-2nNzweX',
          wikiLink:
            'https://en.wikipedia.org/wiki/Museo_Nacional_de_Costa_Rica',
          hasLandmark: true,
        },
        city: {
          name: 'Cartago',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lush-green-forest-filled-with-lots-of-trees-rEn-JmJbp0M',
          wikiLink: 'https://en.wikipedia.org/wiki/Cartago,_Costa_Rica',
        },
        landmarksRound: [
          { code: 'CF', number: 3 },
          { code: 'ZM', number: 4 },
          { code: 'TC', number: 1 },
        ],
        landmarkPlaces: [
          'Talietumu',
          "King's Palace Museum",
          'Alega Beach',
          'Vardzia',
          'Lake Armor',
          'Bellapais Abbey',
          'Nasir al-Mulk Mosque',
        ],
        uniqueId: 'nrZbsgZn',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Croatia',
    code: 'HR',
    capital: 'Zagreb',
    lat: 45.16666666,
    long: 15.5,
    cities: [
      'Zagreb',
      'Dubrovnik',
      'Split',
      'Rijeka',
      'Pula',
      'Rovinj',
      'Osijek',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ohoBiu7_KNc',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubrovnik',
        },
        landmark: {
          name: "Diocletian's Palace",
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/split-croatia-easter-europe-sea-2274113/',
          wikiLink: 'https://en.wikipedia.org/wiki/Diocletian%27s_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Zagreb',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/skyline-city-croatia-cityscape-4637445/',
          wikiLink: 'https://en.wikipedia.org/wiki/Zagreb',
        },
        landmarksRound: [
          { code: 'BE', number: 2 },
          { code: 'LS', number: 1 },
          { code: 'KH', number: 2 },
        ],
        landmarkPlaces: [
          'Belvedere Lookout',
          'Tegucigalpa Cathedral',
          'Winter Palace',
          'Wawel Castle',
          'Kaaba',
          'Deception Island',
          'Chillon Castle',
        ],
        uniqueId: '5JXzM9CP',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/dubrovnik-croatia-kings-landing-512798/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubrovnik',
        },
        landmark: {
          name: 'Pula Arena',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/amphitheater-pula-arena-roman-261115/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pula_Arena',
          hasLandmark: true,
        },
        city: {
          name: 'Dubrovnik',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/dubrovnik-croatia-kings-landing-512798/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubrovnik',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'ycZ3vnYZ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Dubrovnik',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/village-and-cliffs-in-aerial-view-photography-ohoBiu7_KNc',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubrovnik',
        },
        landmark: {
          name: 'Krka National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-island-in-the-middle-of-a-lake-ktF1Rr8EBFY',
          wikiLink: 'https://en.wikipedia.org/wiki/Krka_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Rovinj',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-daytime-ejlgP2FkA4o',
          wikiLink: 'https://en.wikipedia.org/wiki/Rovinj',
        },
        landmarksRound: [
          { code: 'CU', number: 1 },
          { code: 'AO', number: 1 },
          { code: 'BD', number: 4 },
        ],
        landmarkPlaces: [
          'Blue Eye Cave',
          'Omar Ali Saifuddien Mosque',
          'Salar de Uyuni',
          'Viewpoint of the Moon',
          'Bayon Temple',
          'Kittiwake Shipwreck',
          'South Pole',
        ],
        uniqueId: 'TZvpSnl9',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Hvar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/concrete-house-beside-body-of-water-WS8UKXSHJaE',
          wikiLink: 'https://en.wikipedia.org/wiki/Hvar',
        },
        landmark: {
          name: 'Plitvice Lakes National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-river-between-rocky-mountains-during-daytime-3E2zM9IF-bU',
          wikiLink:
            'https://en.wikipedia.org/wiki/Plitvice_Lakes_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Split',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-building-near-body-of-water-HjHnI35B4YY',
          wikiLink: 'https://en.wikipedia.org/wiki/Split,_Croatia',
        },
        landmarksRound: [
          { code: 'AM', number: 4 },
          { code: 'AS', number: 1 },
          { code: 'CV', number: 3 },
        ],
        landmarkPlaces: [
          'Cane Garden Bay',
          'Sundarbans',
          'Mount Cameroon',
          'Shoal Bay',
          'Lakes of Ounianga',
          'Animal Flower Cave',
          'Cane Garden Bay',
        ],
        uniqueId: '5pqsLWlJ',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Croatia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-boats-on-pier-and-city-ybUdMTw7-0c',
          wikiLink: 'https://en.wikipedia.org/wiki/Croatia',
        },
        landmark: {
          name: 'Plitvice Lakes National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-stair-near-waterfalls-0F9V1JQ67cE',
          wikiLink:
            'https://en.wikipedia.org/wiki/Plitvice_Lakes_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Zagreb',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-green-trees-on-mountain-pqyJxtXFC2w',
          wikiLink: 'https://en.wikipedia.org/wiki/Zagreb',
        },
        landmarksRound: [
          { code: 'ZM', number: 2 },
          { code: 'CF', number: 5 },
          { code: 'CX', number: 3 },
        ],
        landmarkPlaces: [
          "Queen's Staircase",
          'Cao Grande Peak',
          'Kunta Kinteh Island',
          'Great Siege Tunnels',
          'Les Eclaireurs Lighthouse',
          'Zaisan Memorial',
          'Annaberg plantation',
        ],
        uniqueId: 'zCP2qCqq',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Zagreb',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-blue-trolley-with-a-red-top-on-a-city-street-eR60eyWRous',
          wikiLink: 'https://en.wikipedia.org/wiki/Zagreb',
        },
        landmark: {
          name: 'Lovrijenac',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/sequoya/23744229279/in/photolist-Cbco8r-2jAcfNL-2iPgJqm-5wDL16-2nvsJyx-5wDJ6a-DY65Qz-2nKfunq-2op6ouZ-2hQeoe1-AiS3cR-RjwEob-2m5zY3Q-2hSznux-2nUhoj2-2gT6xrQ-Hp8uWT-25kJSVB-5BWbVJ-pNokpo-6SqEN9-2n9ivVq-vvWTLG-uRxr4G-Q6XAqC-PvLdY3-qPs2RN-2akuH8M-PoQfSH-2ebR9qq-2keHNUi-AgJxNU-A84xSo-2mC9oyT-2nLGj1Z-zNMLEW-2iipVVD-2j43Ly6-MTQt7K-Njmzim-2kGEW36-PvLbYG-J3raBK-27C1LFN-zJNVYN-p3KmDC-e93p4P-r66bZ9-89zh1G-KWwrhb',
          wikiLink: 'https://en.wikipedia.org/wiki/Lovrijenac',
          hasLandmark: true,
        },
        city: {
          name: 'Osijek',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/hawk75/48317227322/in/photolist-2gBCqku-8WLsRq-2eamDVn-2e5gQEe-aAHygK-4g1yCe-8WHqfR-qgkmJ1-84K3WC-JiwLSr-2ftAbEo-84KbZ3-28YZNH4-7EuZK-8kXwE-DsUL92-nYQX9x-nWNqnQ-vbGmq-2j722RA-23biYmY-84GdVz-nGmApG-74WbSF-23biZbU-23F6s7q-84K5Lm-Ss2eRw-84K7rU-GM1kSx-2cC3r34-bubxMZ-6FmR1A-2iTgfhZ-2m5xHSd-FxoYYJ-DT4RmV-pmmMhi-24pmzUj-FWWvyw-E46ksJ-AWZNMm-NjzZ3N-2hV9JPh-2jxdiiX-2bpi2ro-2a61hfp-2oNrdDj-2oNgXGK-EZk5p5',
          wikiLink: 'https://en.wikipedia.org/wiki/Osijek',
        },
        landmarksRound: [
          { code: 'HK', number: 2 },
          { code: 'KE', number: 4 },
          { code: 'GH', number: 3 },
        ],
        landmarkPlaces: [
          'Nan Madol',
          "St Bavo's Cathedral, Ghent",
          'Deception Island',
          'Gergeti Trinity Church',
          'Terrace of the Elephants',
          'Castillo San Cristobal',
          'Quadiriki Caves',
        ],
        uniqueId: 'pdKM6tTG',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Cuba',
    code: 'CU',
    capital: 'Havana',
    lat: 21.5,
    long: -80,
    cities: [
      'Havana',
      'Santiago de Cuba',
      'Santa Clara',
      'Cienfuegos',
      'Trinidad',
      'Holguin',
      'Holguín',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/aZjw7xI3QAA',
          wikiLink: 'https://en.wikipedia.org/wiki/Vi%C3%B1ales',
        },
        landmark: {
          name: 'Christ of Havana',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/christ-statue-havana-cuba-1116434/',
          wikiLink: 'https://en.wikipedia.org/wiki/Christ_of_Havana',
          hasLandmark: true,
        },
        city: {
          name: 'Havana',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/havana-cuba-city-urban-sea-wharf-4027210/',
          wikiLink: 'https://en.wikipedia.org/wiki/Havana',
        },
        landmarksRound: [
          { code: 'BE', number: 1 },
          { code: 'MT', number: 1 },
          { code: 'AE', number: 2 },
        ],
        landmarkPlaces: [
          'Baalbek',
          'Amber Palace',
          'Ocean Park',
          'La Sagrada Familia',
          'Great Blue Hole',
          'Liwonde National Park',
          'Fasil Ghebbi',
        ],
        uniqueId: 'GJtltjRy',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cuba-antique-car-automobile-vehicle-1211907/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cuba',
        },
        landmark: {
          name: 'Castillo De Los Tres Reyes Del Morro',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fort-castle-santiago-de-cuba-cuba-1872566/',
          wikiLink: 'https://en.wikipedia.org/wiki/Morro_Castle_(Havana)',
          hasLandmark: true,
        },
        city: {
          name: 'Santiago de Cuba',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/road-alley-city-pavement-cuba-3076970/',
          wikiLink: 'https://en.wikipedia.org/wiki/Santiago_de_Cuba',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'LvSmCKWb',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cuba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-walking-on-walkway-while-holding-his-bicycle-PubtV8UJxB8',
          wikiLink: 'https://en.wikipedia.org/wiki/Cuba',
        },
        landmark: {
          name: 'Viñales Valley',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-brown-mountain-under-white-clouds-during-daytime-xxLVJine9Rw',
          wikiLink: 'https://en.wikipedia.org/wiki/Vi%C3%B1ales_Valley',
          hasLandmark: true,
        },
        city: {
          name: 'Havana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/top-view-of-buildings-under-cloudy-sky-ejIi27O6qlI',
          wikiLink: 'https://en.wikipedia.org/wiki/Havana',
        },
        landmarksRound: [
          { code: 'AD', number: 1 },
          { code: 'BS', number: 3 },
          { code: 'TD', number: 4 },
        ],
        landmarkPlaces: [
          'Manovo-Gounda St. Floris National Park',
          'Kittiwake Shipwreck',
          'Crystal Caves',
          "Diocletian's Palace",
          'Xunatunich Mayan Ruins',
          'Catedral da Sé',
          'Elephant Island',
        ],
        uniqueId: 'xktxFl82',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Havana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-and-black-sedan-traveling-on-road-during-daytime-2rKtspM-FNo',
          wikiLink: 'https://en.wikipedia.org/wiki/Havana',
        },
        landmark: {
          name: 'El Capitolio',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-a-dome-building-under-a-calm-blue-sky-5f5KBMOKbzI',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Capitolio',
          hasLandmark: true,
        },
        city: {
          name: 'Cienfuegos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-white-and-black-shirt-walking-in-front-if-store-TJGjTaT5jgg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cienfuegos',
        },
        landmarksRound: [
          { code: 'BR', number: 3 },
          { code: 'BD', number: 1 },
          { code: 'BJ', number: 2 },
        ],
        landmarkPlaces: [
          'Terrace of the Elephants',
          'CN Tower',
          'Matafao Peak',
          'Kastelholm Castle',
          'English Harbour',
          'Angkor Wat',
          'Rila Monastery',
        ],
        uniqueId: 'HbxfHRfV',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Cuba',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/pedrosz/33051350992/in/photolist-SmCPab-s8viDV-27dc9XU-rbwhfE-qDXSjV-rQVY6y-s8naVb-913g3R-rbGjqF-913nDk-rQTGqC-RrARkd-LcZq46-913qSH-2beRf4y-KVnCVw-pHmYLt-28A1r4P-qnzsQE-913dLx-qnyLmw-pHmUJR-pH8H1h-rQVa8o-rbuudw-qnztpW-s8noSJ-9USbDH-4AYq5C-qnHhUt-4AYnQu-21MN97g-qE4BXY-bPUDrn-rMadeG-21MN8tx-vqmHk-29SLu7v-rMhdpr-4AYr4N-916iVu-rQVK87-916uoC-ewWTAV-8WvRo2-Rky9pi-d4hteu-916iqu-916myG-KkpoFs',
          wikiLink: 'https://en.wikipedia.org/wiki/Cuba',
        },
        landmark: {
          name: 'National Capitol of Cuba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-sedan-parks-near-white-building-WpF7JpiKpTk',
          wikiLink: 'https://en.wikipedia.org/wiki/National_Capitol_of_Cuba',
          hasLandmark: true,
        },
        city: {
          name: 'Santiago de Cuba',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/46099877@N03/52844077233/in/photolist-2ovDH6T-5vsw4x-2kt1eab-2oNGg1Z-2kWG3U4-2ngKQLr-2j1zKCo-2iXNUVJ-2j4RFPb-2kfUzFQ-2oiV4K9-2dLperd-ZjFCmX-FyKyUN-2jHJoy6-LfFk4d-2kQg7tu-pm19av-9Hh2aM-6RgaJi-64xwSr-2nqkMev-2jyWVEZ-2dWqLfL-2hZ84jo-2hYMvA1-EtcaJP-2f1o826-Etc9rZ-Etc9Hk-2eLhP4Q-EtcayZ-22KXR8z-2jyMVMm-3cqKKk-2jLDQUR-Etcwrk-2kWKu4r-2iYR1xW-22KYbwz-EtcsxZ-FSZXZR-aetotD-6Rka8d-Etc7ov-6dgumV-233vkYm-EtcvyZ-233vmXW-QdCD9t',
          wikiLink: 'https://en.wikipedia.org/wiki/Santiago_de_Cuba',
        },
        landmarksRound: [
          { code: 'CC', number: 5 },
          { code: 'TF', number: 3 },
          { code: 'NP', number: 1 },
        ],
        landmarkPlaces: [
          'Rhine Falls',
          'Köl-Suu',
          'Al Saleh Mosque',
          'Doonagore Castle, Doolin, Ireland',
          'Golden Gate',
          'Marovo Lagoon',
          'Gergeti Trinity Church',
        ],
        uniqueId: '7P2pWB5V',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Havana',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/66001184@N00/49766850723/in/photolist-2iPJ7Sp-2j1TbWv-SPaaW5-St7i8o-SZmBss-2iQbDwU-RLr2Vm-T5obHx-St6yJ5-SP9TTU-RLsFyW-SZnmMo-SRBE92-SRBDcn-RP1Jnp-SRBExt-2nZpBMm-SZnnyo-SZnmyN-SPaXts-SZnj97-SRBCZD-SZnkLq-SZnna7-SZnk93-SZnkqW-Tp27fD-SRDV3M-RP1HVx-RP1Hve-RLrKLf-SRCHka-St6zAW-2piubRR-RP1H2D-St7kuh-SP9Ucj-SZmH9C-RP1GSk-St7gf5-SP9UEJ-RP1H7D-St7jnC-RLrL9Q-RLrJny-RLsYQu-SPa8ZE-UkE5KQ-SP9S6L-RLrLFG',
          wikiLink: 'https://en.wikipedia.org/wiki/Havana',
        },
        landmark: {
          name: 'Plaza de la Catedral',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/125206642@N03/30138705141/in/photolist-MVfK2g-4rpbFC-NVocc9-D5MPNM-2hGMRGQ-azrv1b-qPgL7G-UcLgEY-2kEY7d7-SSPA9q-23yuboN-DCiCxe-RHV1ut-9gVEad-skVkKE-F6a1Ad-tNheH-2hN6Jxz-HyAFXN-LdwpZG-CrVvXw-27CE9k5-DbtQs5-nWEALc-21RtAWT-QCLhQk-2iMQMgG-E8CqXe-296JA5-fnoaJ-2ef6ChQ-2ikGAqM-XcCNL8-JHhKyy-s91hd1-2sjny-bQtJWR-fnoeA-T7W3FJ-RNiBC8-fno1B-sodskX-9nnZay-2dhAWhZ-FDSJbC-KBJEd3-fnnqM-2g7Ybzg-2baPcUy-6cJHhH',
          wikiLink: 'https://en.wikipedia.org/wiki/Plaza_de_la_Catedral',
          hasLandmark: true,
        },
        city: {
          name: 'Holguín',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/gregumiecki/42627098145/in/photolist-27WP2b2-91NzGx-GCrYp-GCrXR-KwnzNT-24cuiq6-GCrXM-CcfGkC-GCrXD-VEkZ7w-VEkZ7m-VEkZ7G-M58rzh-26s6ajS-CtQ7RG-91NzGz-9ofKWZ-9vTzS3-9oiRtJ-GCrXB-9oiTt3-9ofFMT-CB4Wv5-FfTeH-5Ab5xP-2iCKC4P-ZnmKPW-2iCFyUr-2oyYhRN-64GZmn-2iCKEQF-2iCKEyU-DZDJqG-2gjUD65-2nPeLv2-2iCFyxe-9qw14F-2nnkAAA-f8Pj7B-f94w7o-2hN1Ptx-64GHqM-CLsWSC-78S3dD-fmTcrB-CB4Wvf-CcfGjL-2mH62NW-DFrcon-fdcZnR',
          wikiLink: 'https://en.wikipedia.org/wiki/Holgu%C3%ADn',
        },
        landmarksRound: [
          { code: 'GL', number: 2 },
          { code: 'MT', number: 4 },
          { code: 'AQ', number: 1 },
        ],
        landmarkPlaces: [
          'Timgad',
          'Boali',
          'Lotto World',
          'Macau Tower',
          'Ulu Temburong National Park',
          'Ancient Theatre of Epidaurus',
          'Marigot Bay',
        ],
        uniqueId: 'rbHtfWXg',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Curaçao',
    code: 'CW',
    capital: 'Willemstad',
    lat: 12.116667,
    long: -68.933333,
    cities: [
      'Willemstad',
      'Sint Michiel',
      'Sint Willibrordus',
      'Tera Kora',
      'Barber',
      'Otrobanda',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/cKbKFQa32Q4',
          wikiLink: 'https://en.wikipedia.org/wiki/Willemstad',
        },
        landmark: {
          name: 'Queen Emma Bridge',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Queen_Emma_Bridge#/media/File:View_of_Otrobanda,_Willemstad,_Cura%C3%A7ao_-_February_2020.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Queen_Emma_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Willemstad',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/curacao-willemstad-island-dutch-407320/',
          wikiLink: 'https://en.wikipedia.org/wiki/Willemstad',
        },
        landmarksRound: [
          { code: 'SR', number: 2 },
          { code: 'TR', number: 2 },
          { code: 'CA', number: 2 },
        ],
        landmarkPlaces: [
          'Kravica Waterfall',
          'Hagia Sophia',
          'Kelonia',
          'Cathedral Our Lady of the Rosary',
          'Blue Grotto',
          'Castle Rushden',
          'Panfilov Park',
        ],
        uniqueId: 'bnZPclnG',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Handelskade,_Willemstad,_Cura%C3%A7ao_%284383516303%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Willemstad',
        },
        landmark: {
          name: 'Hato Caves',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Hato_Caves#/media/File:HATO_CAVES_,_CURACAO.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hato_Caves',
          hasLandmark: true,
        },
        city: {
          name: 'Sint Michiel',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sint_Michiel#/media/File:Pano_Bluebay_front_Cropped.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sint_Michiel',
        },
        landmarksRound: [
          { code: 'PL', number: 2 },
          { code: 'KY', number: 2 },
          { code: 'FR', number: 2 },
        ],
        landmarkPlaces: [
          'Petronas Towers',
          'Iluissat Icefjord',
          'Cathedral Our Lady of the Rosary',
          'Elmina Castle',
          'Ouzoud Falls',
          'Al Alam Palace',
          'Acropolis',
        ],
        uniqueId: '8qbbwK2L',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Curaçao',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-person-lying-on-chaise-lounge-under-sunset-xQF1ZLPqXio',
          wikiLink: 'https://en.wikipedia.org/wiki/Cura%C3%A7ao',
        },
        landmark: {
          name: 'Fort Beekenburg',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/paul-mcclure/2220138892',
          wikiLink: 'https://nl.wikipedia.org/wiki/Fort_Beekenburg',
          hasLandmark: true,
        },
        city: {
          name: 'Willemstad',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/black-suv-nf37cy51Bo8',
          wikiLink: 'https://en.wikipedia.org/wiki/Willemstad',
        },
        landmarksRound: [
          { code: 'BZ', number: 4 },
          { code: 'HR', number: 1 },
          { code: 'BZ', number: 2 },
        ],
        landmarkPlaces: [
          'Tsodilo Hills',
          'Bayon Temple',
          'Hofburg Palace',
          'Belovezhskaya Pushcha National Park',
          "St. John's Cathedral",
          'Okavango Delta',
          'Twelve Apostles',
        ],
        uniqueId: '7vFZSTxJ',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Curaçao',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-near-body-of-water-during-daytime-I3-3_ZLKtTU',
          wikiLink: 'https://en.wikipedia.org/wiki/Cura%C3%A7ao',
        },
        landmark: {
          name: 'Queen Emma Bridge',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/168574573@N04/47933608366',
          wikiLink: 'https://en.wikipedia.org/wiki/Queen_Emma_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Sint Michiel',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Sint_Michiel#/media/File:Pano_Bluebay_front_Cropped.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sint_Michiel',
        },
        landmarksRound: [
          { code: 'BA', number: 1 },
          { code: 'AM', number: 1 },
          { code: 'KY', number: 3 },
        ],
        landmarkPlaces: [
          'Roman ruins in Batna',
          'Perito Moreno Glacier',
          'Spittal Pond',
          'Little Bay',
          'Bayon Temple',
          'Fort Fincastle',
          'Perito Moreno Glacier',
        ],
        uniqueId: '96ZpjzHK',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Curaçao',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-small-boat-floating-on-top-of-a-body-of-water-cnu_Cd1ACZk',
          wikiLink: 'https://en.wikipedia.org/wiki/Cura%C3%A7ao',
        },
        landmark: {
          name: 'Klein Curaçao',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-near-green-grass-field-under-blue-and-white-cloudy-sky-during-I0QatRGW_w0',
          wikiLink: 'https://en.wikipedia.org/wiki/Klein_Cura%C3%A7ao',
          hasLandmark: true,
        },
        city: {
          name: 'Willemstad',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-row-of-colorful-buildings-on-the-side-of-a-body-of-water-cKbKFQa32Q4',
          wikiLink: 'https://en.wikipedia.org/wiki/Willemstad',
        },
        landmarksRound: [
          { code: 'GI', number: 4 },
          { code: 'PK', number: 2 },
          { code: 'ET', number: 1 },
        ],
        landmarkPlaces: [
          'Annandale Waterfall',
          'Champagne Reef, Dominica',
          'Mosta Rotunda',
          'Catedral da Sé',
          'Golden Gate',
          'Uluru',
          'Köl-Suu',
        ],
        uniqueId: 'hWz2wkN9',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Curaçao',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-white-boat-on-sea-during-daytime-ceA-EdYxQec',
          wikiLink: 'https://en.wikipedia.org/wiki/Cura%C3%A7ao',
        },
        landmark: {
          name: 'Fort Beekenburg',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/chrismaki/8256952281/',
          wikiLink: 'https://nl.wikipedia.org/wiki/Fort_Beekenburg',
          hasLandmark: true,
        },
        city: {
          name: 'Sint Michiel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-riding-boat-on-lake-during-daytime-Pcq1W4QNKq4',
          wikiLink: 'https://en.wikipedia.org/wiki/Sint_Michiel',
        },
        landmarksRound: [
          { code: 'BD', number: 6 },
          { code: 'ZW', number: 4 },
          { code: 'CK', number: 2 },
        ],
        landmarkPlaces: [
          'Badshahi Mosque',
          'Lovrijenac',
          'Almsee',
          'Monumento al Divino Salvador del Mundo',
          'Marina Bay Sands',
          'Abuna Yemata Guh',
          'Shell Beach',
        ],
        uniqueId: 'VgkhNgSx',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Cyprus',
    code: 'CY',
    capital: 'Nicosia',
    lat: 35,
    long: 33,
    cities: [
      'Nicosia',
      'Larnaca',
      'Paralimni',
      'Limassol',
      'Kyrenia',
      'Paphos',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/i51lWwDYHzQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Larnaca',
        },
        landmark: {
          name: 'Bellapais Abbey',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jorge-11/4530565439',
          wikiLink: 'https://en.wikipedia.org/wiki/Bellapais_Abbey',
          hasLandmark: true,
        },
        city: {
          name: 'Larnaca',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cyprus-larnaca-square-travel-3936046/',
          wikiLink: 'https://en.wikipedia.org/wiki/Larnaca',
        },
        landmarksRound: [
          { code: 'MM', number: 2 },
          { code: 'NA', number: 1 },
          { code: 'CD', number: 2 },
        ],
        landmarkPlaces: [
          'Rijksmuseum',
          'Animal Flower Cave',
          'Hazrat Ali Mazar',
          'Epupa Falls',
          'Liwonde National Park',
          'Valle de Cocora',
          'Alhambra',
        ],
        uniqueId: 'm4kQlJnJ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cyprus-coast-cliff-cavo-greko-3184019/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Greco',
        },
        landmark: {
          name: 'Church of Saint Lazarus',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Church_of_Saint_Lazarus,_Larnaca#/media/File:Cyprus-lazarus-church_hg.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Church_of_Saint_Lazarus,_Larnaca',
          hasLandmark: true,
        },
        city: {
          name: 'Nicosia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nicosia#/media/File:Nicosia_panoramic_view_Cyprus_Tower_25_Jean_Nouvel.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nicosia',
        },
        landmarksRound: [
          { code: 'GS', number: 1 },
          { code: 'IR', number: 1 },
          { code: 'KG', number: 1 },
        ],
        landmarkPlaces: [
          'Atomium',
          'Panfilov Park',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Fort Christian',
          'Temple of Literature',
          'Kaaba',
          "Queen's Staircase",
        ],
        uniqueId: 'lKN4T8m7',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cyprus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-statue-of-a-man-and-a-woman-in-the-water-ewz1UASAhHA',
          wikiLink: 'https://en.wikipedia.org/wiki/Cyprus',
        },
        landmark: {
          name: 'Tombs of the Kings (Paphos)',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/azwegers/33344652068',
          wikiLink: 'https://en.wikipedia.org/wiki/Tombs_of_the_Kings_(Paphos)',
          hasLandmark: true,
        },
        city: {
          name: 'Limassol',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-pier-in-the-middle-of-a-large-body-of-water-rnNxuz6DFrE',
          wikiLink: 'https://en.wikipedia.org/wiki/Limassol',
        },
        landmarksRound: [
          { code: 'CL', number: 4 },
          { code: 'AF', number: 4 },
          { code: 'BM', number: 3 },
        ],
        landmarkPlaces: [
          'Cinquantenaire Arcade',
          'Codrington College',
          'Taj Mahal',
          'Tatacoa Desert',
          "Queen's Staircase",
          'Chutes de Boali',
          'Roman ruins in Batna',
        ],
        uniqueId: '7zSGG2Gf',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Cyprus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-pathway-between-walls-with-plants-JPSTXKbqUHw',
          wikiLink: 'https://en.wikipedia.org/wiki/Cyprus',
        },
        landmark: {
          name: 'Kyrenia Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/26034413@N04/14137879099',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyrenia_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Paphos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-near-body-of-water-during-daytime-y0EQ1ztE4BQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Paphos',
        },
        landmarksRound: [
          { code: 'AZ', number: 4 },
          { code: 'AO', number: 2 },
          { code: 'BI', number: 1 },
        ],
        landmarkPlaces: [
          "Notre-Dame d'Afrique",
          'Dhakeshwari Temple',
          'Salar de Uyuni',
          'Almsee',
          'Lake Niamawi',
          'Regina Mundi Cathedral',
          'Pukapuka',
        ],
        uniqueId: 'YPPtDTKq',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Cyprus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-birds-eye-view-of-a-sandy-beach-and-lagoon-Me8Lp4Fn87U',
          wikiLink: 'https://en.wikipedia.org/wiki/Cyprus',
        },
        landmark: {
          name: 'Paphos Archaeological Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/flem007_uk/22052336756/',
          wikiLink: 'https://en.wikipedia.org/wiki/Paphos_Archaeological_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Larnaca',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-buildings-during-daytime-UMDzG9SqH38',
          wikiLink: 'https://en.wikipedia.org/wiki/Larnaca',
        },
        landmarksRound: [
          { code: 'AS', number: 3 },
          { code: 'TF', number: 2 },
          { code: 'BI', number: 1 },
        ],
        landmarkPlaces: [
          'Mount Everest',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Okavango River',
          "People's Palace",
          'Chinguetti',
          'Erbil Citadel',
          'Lake Lalolalo',
        ],
        uniqueId: '64mHbFSm',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Larnaca',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-daytime-PDoWMD5cy9I',
          wikiLink: 'https://en.wikipedia.org/wiki/Larnaca',
        },
        landmark: {
          name: 'Church of Saint Lazarus',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/rubenholthuijsen/53328056736/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Church_of_Saint_Lazarus,_Larnaca',
          hasLandmark: true,
        },
        city: {
          name: 'Limassol',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-orange-building-Dv2n1Tv_WcI',
          wikiLink: 'https://en.wikipedia.org/wiki/Limassol',
        },
        landmarksRound: [
          { code: 'TC', number: 3 },
          { code: 'GD', number: 1 },
          { code: 'ET', number: 1 },
        ],
        landmarkPlaces: [
          'Altun Ha',
          'Semuc Champey',
          'Codrington College',
          "Ruins of St. Paul's",
          'Castle San Felipe de Barajas',
          'A-Ma Temple',
          'Fakaofo',
        ],
        uniqueId: 'P9Vyj8d2',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Czechia',
    code: 'CZ',
    capital: 'Prague',
    lat: 49.75,
    long: 15.5,
    cities: [
      'Brno',
      'Pilsen',
      'Ostrava',
      'Karlovy Vary',
      'Liberec',
      'Olomouc',
      'Prague',
      'České Budějovice',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/1ak3Z7ZmtQA',
          wikiLink: 'https://en.wikipedia.org/wiki/Charles_Bridge',
        },
        landmark: {
          name: 'Charles Bridge',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/prague-czech-republic-sunset-city-3540876/',
          wikiLink: 'https://en.wikipedia.org/wiki/Charles_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Brno',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cathedral-church-brno-moravia-dom-7481427/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brno',
        },
        landmarksRound: [
          { code: 'MN', number: 1 },
          { code: 'AD', number: 1 },
          { code: 'CA', number: 1 },
        ],
        landmarkPlaces: [
          'Chamarel Waterfall',
          'CN Tower',
          'Middle of the World City',
          'Maiden Tower',
          'Al Alam Palace',
          'Alexander Nevsky Cathedral',
          'Fasil Ghebbi',
        ],
        uniqueId: 'Nw5jGPmC',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bridges-prague-czechia-water-city-3337124/',
          wikiLink: 'https://en.wikipedia.org/wiki/Prague',
        },
        landmark: {
          name: 'Old Town Square',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/prague-old-town-czech-cafe-tourist-2229156/',
          wikiLink: 'https://en.wikipedia.org/wiki/Old_Town_Square',
          hasLandmark: true,
        },
        city: {
          name: 'Pilsen',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/pilsen-czech-republic-city-6472695/',
          wikiLink: 'https://en.wikipedia.org/wiki/Plze%C5%88',
        },
        landmarksRound: [
          { code: 'LA', number: 2 },
          { code: 'KH', number: 2 },
          { code: 'PK', number: 1 },
        ],
        landmarkPlaces: [
          'Dubai Frame',
          'Castillo San Cristobal',
          'Anne Frank House',
          'Salvo Palace',
          'Trevi Fountain',
          'Chichen Itza',
          'Amalienborg',
        ],
        uniqueId: 'x97DcHgV',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Český Krumlov',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-1eShNpNUB1U',
          wikiLink: 'https://en.wikipedia.org/wiki/%C4%8Cesk%C3%BD_Krumlov',
        },
        landmark: {
          name: 'Karlštejn Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/castle-surrounded-by-greenery-zAIWojYvRuQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Karl%C5%A1tejn',
          hasLandmark: true,
        },
        city: {
          name: 'Karlovy Vary',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-between-houses-and-buildings-during-daytime-ZCuz12FHHMQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Karlovy_Vary',
        },
        landmarksRound: [
          { code: 'BE', number: 2 },
          { code: 'CW', number: 4 },
          { code: 'DK', number: 1 },
        ],
        landmarkPlaces: [
          'Mount Karthala',
          'Turku Castle',
          'Chutes de Boali',
          'Brest Fortress',
          'Christ of Havana',
          'Uluru',
          'Notre Dame of Bangui Cathedral',
        ],
        uniqueId: '24PKJgdg',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Prague',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-during-night-time-Ay2lw6vKZyU',
          wikiLink: 'https://en.wikipedia.org/wiki/Prague',
        },
        landmark: {
          name: 'Prague astronomical clock',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-clock-on-a-building-U8FnXV4pRj8',
          wikiLink: 'https://en.wikipedia.org/wiki/Prague_astronomical_clock',
          hasLandmark: true,
        },
        city: {
          name: 'Olomouc',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-tower-TogLSxuccKc',
          wikiLink: 'https://en.wikipedia.org/wiki/Olomouc',
        },
        landmarksRound: [
          { code: 'CX', number: 1 },
          { code: 'AM', number: 3 },
          { code: 'BS', number: 3 },
        ],
        landmarkPlaces: [
          'Salomon Islands',
          'Middle of the World City',
          'Dhakeshwari Temple',
          'Deception Island',
          'Catedral da Sé',
          'Shoal Bay',
          'Terrace of the Elephants',
        ],
        uniqueId: 'jWy7Zjz4',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Prague',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-people-that-are-walking-down-a-street-UdowKikI1LY',
          wikiLink: 'https://en.wikipedia.org/wiki/Prague',
        },
        landmark: {
          name: 'Charles Bridge',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/brown-bridge-ghGyYXOzqGI',
          wikiLink: 'https://en.wikipedia.org/wiki/Charles_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Prague',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-tall-buildings-ekrarB5LR6Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Prague',
        },
        landmarksRound: [
          { code: 'DZ', number: 4 },
          { code: 'BI', number: 3 },
          { code: 'DE', number: 2 },
        ],
        landmarkPlaces: [
          'Pics de Sindou',
          'Burj Khalifa',
          'Frogner Park',
          'Arch 22',
          'Fort Sao Sebastiao',
          'Kome Caves',
          'House of Slaves',
        ],
        uniqueId: 'qdnqhmRv',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Prague',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-during-daytime--5bKUmWpuSY',
          wikiLink: 'https://en.wikipedia.org/wiki/Prague',
        },
        landmark: {
          name: 'Dancing House',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/afarmer/35575534571/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dancing_House',
          hasLandmark: true,
        },
        city: {
          name: 'České Budějovice',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-benches-sitting-on-top-of-a-lush-green-field-TtfbCx29wFU',
          wikiLink:
            'https://en.wikipedia.org/wiki/%C4%8Cesk%C3%A9_Bud%C4%9Bjovice',
        },
        landmarksRound: [
          { code: 'IE', number: 2 },
          { code: 'DZ', number: 6 },
          { code: 'MK', number: 2 },
        ],
        landmarkPlaces: [
          'University of Dhaka',
          'Trafalgar Falls',
          'Richat Structure',
          'Leaning Tower of Pisa',
          "Fa'aruma'i Waterfalls",
          'Irazú Volcano',
          'Fort Christian',
        ],
        uniqueId: 'tgY5MP6f',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Democratic Republic of Congo',
    code: 'CD',
    capital: 'Kinshasa',
    lat: 0,
    long: 25,
    cities: [
      'Kisangani',
      'Kinshasa',
      'Bukavu',
      'Kananga',
      'Goma',
      'Lubumbashi',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/river-trading-4215417/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
        landmark: {
          name: 'Lake Kivu',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lake-lake-kivu-africa-sunset-7345240/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Kivu',
          hasLandmark: true,
        },
        city: {
          name: 'Kisangani',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kisangani#/media/File:Kisangani_rond-point_Cath%C3%A9drale_et_Congo_Palace.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kisangani',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Q9vpH5tF',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixnio.com/travel/classrooms-at-the-lusakela-agriculture-station-democratic-republic-of-congo-africa',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
        landmark: {
          name: 'Mount Nyriagongo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mount_Nyiragongo#/media/File:Lava_Lake_Nyiragongo_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Nyiragongo',
          hasLandmark: true,
        },
        city: {
          name: 'Kinshasa',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/buildings-road-city-cars-stree-7122158/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kinshasa',
        },
        landmarksRound: [
          { code: 'PY', number: 1 },
          { code: 'FO', number: 2 },
          { code: 'AM', number: 2 },
        ],
        landmarkPlaces: [
          'Tenaru Falls',
          'Tavarua',
          'St. Alexander Nevsky Cathedral',
          'Chichen Itza',
          'Kaaba',
          'Chimi Lhakhang',
          'Alhambra',
        ],
        uniqueId: 'T6n4fNQP',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Democratic Republic of Congo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-under-blue-sky-and-white-clouds-during-daytime-StJWD4ci8wY',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
        landmark: {
          name: 'Virunga National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/gridarendal/32052566106',
          wikiLink: 'https://en.wikipedia.org/wiki/Virunga_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Kinshasa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kinshasa#/media/File:La_ville_de_Kinshasa.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kinshasa',
        },
        landmarksRound: [
          { code: 'BE', number: 4 },
          { code: 'BA', number: 4 },
          { code: 'BE', number: 2 },
        ],
        landmarkPlaces: [
          'Tsodilo',
          'California Lighthouse',
          "Notre-Dame d'Afrique",
          'Pulu Keeling National Park',
          'Sao Filipe Royale Fortress',
          'Tjentiste War Memorial',
          'Bahrain Fort',
        ],
        uniqueId: 'GYQcfdfq',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Democratic Republic of Congo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/55055975@N07/10346895643',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
        landmark: {
          name: "People's Palace",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Palais_du_Peuple_(Kinshasa)#/media/File:Palais_de_la_nation_Kinshasa_(8756656913).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Palais_du_Peuple_(Kinshasa)',
          hasLandmark: true,
        },
        city: {
          name: 'Goma',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Goma#/media/File:PXL_20221125_133918358~2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Goma',
        },
        landmarksRound: [
          { code: 'BD', number: 4 },
          { code: 'BD', number: 4 },
          { code: 'CF', number: 3 },
        ],
        landmarkPlaces: [
          'Bab Al Bahrain',
          'Buenos Aires Obelisk',
          'Blue Eye Cave',
          'Salomons Atoll',
          'El Capitolio',
          'Qargha Reservoir',
          'Fort James',
        ],
        uniqueId: 's4ZFpQkj',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Democratic Republic of Congo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-boat-filled-with-lots-of-logs-ukiYtB4MX8c',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
        landmark: {
          name: 'Réserve du Mont Fouari',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-wooden-house-near-body-of-water-during-daytime-VuXRueqh6Vg',
          wikiLink: 'https://fr.wikipedia.org/wiki/R%C3%A9serve_du_Mont_Fouari',
          hasLandmark: true,
        },
        city: {
          name: 'Kinshasa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-cars-parked-in-front-of-a-building-R0JfRflRO-A',
          wikiLink: 'https://en.wikipedia.org/wiki/Kinshasa',
        },
        landmarksRound: [
          { code: 'KH', number: 2 },
          { code: 'SG', number: 1 },
          { code: 'AS', number: 1 },
        ],
        landmarkPlaces: [
          'Tafawa Balewa Square',
          'The Pinnacles Desert',
          'Dziani Dzaha',
          'Ten Thousand Buddhas Monastery',
          'Villers Abbey',
          'Villa Folio',
          'Mir Castle',
        ],
        uniqueId: 'BR8XCQp8',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Democratic Republic of Congo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bus-parked-in-front-of-a-building-dJ8uDfnaZFc',
          wikiLink:
            'https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo',
        },
        landmark: {
          name: 'Palais de la Nation',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/european_parliament/5228254451/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Palais_de_la_Nation_(Kinshasa)',
          hasLandmark: true,
        },
        city: {
          name: 'Lubumbashi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-near-building-during-day-0uhXHQYEbzM',
          wikiLink: 'https://en.wikipedia.org/wiki/Lubumbashi',
        },
        landmarksRound: [
          { code: 'GL', number: 1 },
          { code: 'WF', number: 4 },
          { code: 'BZ', number: 6 },
        ],
        landmarkPlaces: [
          'National Pantheon',
          'Palais de Congres',
          "National Martyrs' Memorial",
          'Qalito Island',
          'Sans-Soucis Palace',
          'Karlštejn Castle',
          'Nassau Public Library',
        ],
        uniqueId: '2XQ277v6',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Denmark',
    code: 'DK',
    capital: 'Copenhagen',
    lat: 56,
    long: 10,
    cities: [
      'Aarhus',
      'Copenhagen',
      'Odense',
      'Esbjerg',
      'Roskilde',
      'Aalborg',
      'Vejle',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/imLsDPLnr7Y',
          wikiLink: 'https://en.wikipedia.org/wiki/L%C3%B8kken,_Denmark',
        },
        landmark: {
          name: 'Amalienborg',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/amalienborg-palace-copenhagen-954884/',
          wikiLink: 'https://en.wikipedia.org/wiki/Amalienborg',
          hasLandmark: true,
        },
        city: {
          name: 'Aalborg',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cityscape-skyline-buildings-city-5587226/',
          wikiLink: 'https://en.wikipedia.org/wiki/Aalborg',
        },
        landmarksRound: [
          { code: 'BR', number: 1 },
          { code: 'MQ', number: 1 },
          { code: 'BG', number: 1 },
        ],
        landmarkPlaces: [
          'Stone House',
          'Bran Castle',
          'Soroca Fort',
          'Rila Monastery',
          'Zaisan Memorial',
          'Bahrain Fort',
          'Hagia Sophia',
        ],
        uniqueId: 'NVNtMM48',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lighthouse-landmark-coast-tower-5606162/',
          wikiLink: 'https://en.wikipedia.org/wiki/Skagen_Lighthouse',
        },
        landmark: {
          name: 'Christiansborg Palace',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/christiansborg-palace-7032184/',
          wikiLink: 'https://en.wikipedia.org/wiki/Christiansborg_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Aarhus',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/aarhus-%c3%a5en-city-life-river-side-949700/',
          wikiLink: 'https://en.wikipedia.org/wiki/Aarhus',
        },
        landmarksRound: [
          { code: 'MQ', number: 1 },
          { code: 'US', number: 1 },
          { code: 'GL', number: 2 },
        ],
        landmarkPlaces: [
          'Bellapais Abbey',
          'Frogner Park',
          'Castle Cornet',
          'Christ of Havana',
          'Blue Lagoon',
          'Chichen Itza',
          'Arecibo Observatory',
        ],
        uniqueId: 'YKFW9Gdd',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Løkken',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/top-view-of-mountains-with-houses-imLsDPLnr7Y',
          wikiLink: 'https://en.wikipedia.org/wiki/L%C3%B8kken,_Denmark',
        },
        landmark: {
          name: 'Rosenborg Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-sitting-on-green-grass-field-near-brown-concrete-building-during-daytime-34-7uheVUbM',
          wikiLink: 'https://en.wikipedia.org/wiki/Rosenborg_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Copenhagen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-bridge-during-daytime-b6yjyWmBStA',
          wikiLink: 'https://en.wikipedia.org/wiki/Copenhagen',
        },
        landmarksRound: [
          { code: 'AW', number: 1 },
          { code: 'BS', number: 1 },
          { code: 'AG', number: 1 },
        ],
        landmarkPlaces: [
          'Pulu Keeling National Park',
          'Lily Beach',
          'Silver Pagoda',
          'Fort Beekenburg',
          'Christ of Havana',
          'Al Fateh Grand Mosque',
          'Quadiriki Caves',
        ],
        uniqueId: 'vkDXpFlX',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Denmark',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-sea-under-white-sky-during-daytime-R2fzDVNkJ6I',
          wikiLink: 'https://en.wikipedia.org/wiki/Denmark',
        },
        landmark: {
          name: 'Rundetaarn',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/41202847@N02/3798104918',
          wikiLink: 'https://en.wikipedia.org/wiki/Rundetaarn',
          hasLandmark: true,
        },
        city: {
          name: 'Odense',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-black-concrete-building-during-daytime-lbiZvtbwwhY',
          wikiLink: 'https://en.wikipedia.org/wiki/Odense',
        },
        landmarksRound: [
          { code: 'BR', number: 4 },
          { code: 'AG', number: 4 },
          { code: 'VG', number: 1 },
        ],
        landmarkPlaces: [
          "People's Palace",
          'Door of No Return, Ouidah',
          'Fort Fincastle',
          'Grand Mosque of Bobo-Dioulasso',
          'Salar de Uyuni',
          'Olavinlinna',
          'Hato Caves',
        ],
        uniqueId: 'D6XRVGY2',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Nyhavn',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-gray-and-black-boats-near-dock-3_ZGrsirryY',
          wikiLink: 'https://en.wikipedia.org/wiki/Nyhavn',
        },
        landmark: {
          name: 'Frederiksborg Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-brick-building-near-river-under-cloudy-sky-during-daytime-BbCsTlrmmX0',
          wikiLink: 'https://en.wikipedia.org/wiki/Frederiksborg_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Copenhagen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-in-canal-in-denmark-during-daytime-tcE9zSc2SU8',
          wikiLink: 'https://en.wikipedia.org/wiki/Copenhagen',
        },
        landmarksRound: [
          { code: 'BM', number: 4 },
          { code: 'KZ', number: 3 },
          { code: 'MY', number: 3 },
        ],
        landmarkPlaces: [
          'Taputapuatea marae',
          'Kiritimati',
          'Loango National Park',
          'Jesuit Mission of the Holy Trinity',
          'Szechenyi Thermal Bath',
          'Kunta Kinteh Island',
          'National Museum of Bermuda',
        ],
        uniqueId: 'yNj9C4HK',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Denmark',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-lots-of-buildings-5sYjHfS1AJE',
          wikiLink: 'https://en.wikipedia.org/wiki/Denmark',
        },
        landmark: {
          name: 'The Little Mermaid ',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/aram_bagdasaryan/32137683960/',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Little_Mermaid_(statue)',
          hasLandmark: true,
        },
        city: {
          name: 'Vejle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-red-brick-building-with-a-windmill-in-the-background-0lCX76Bb-BM',
          wikiLink: 'https://en.wikipedia.org/wiki/Vejle',
        },
        landmarksRound: [
          { code: 'CX', number: 4 },
          { code: 'CV', number: 3 },
          { code: 'BO', number: 6 },
        ],
        landmarkPlaces: [
          "N'Djamena Grand Mosque",
          'English Harbour',
          'Cameroon Reunification',
          'Heydar Mosque',
          'Solis Theater',
          'Valle de la Luna',
          'Karlštejn Castle',
        ],
        uniqueId: 'HxyCpwkt',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    capital: 'Djibouti City',
    lat: 11.5,
    long: 43,
    cities: [
      'Djibouti City',
      'Ali Sabieh',
      'Dikhil',
      'Tadjoura',
      'Arta',
      'Obock',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vmfKwrqkOkA',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Abbe',
        },
        landmark: {
          name: 'Mosquee Al-Hamoudi',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Hamoudi_Mosque#/media/File:Hamoudi_Mosque_(210510633).jpeg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamoudi_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Tadjourah',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tadjoura#/media/File:Tagore.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Assal_(Djibouti)',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'gLLHKCdq',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/djibouti-africa-ras-bir-beach-sea-143451/',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti',
        },
        landmark: {
          name: "Lac 'Assal",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lake_Assal_(Djibouti)#/media/File:Lake_Assal_3-Djibouti.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Assal_(Djibouti)',
          hasLandmark: true,
        },
        city: {
          name: 'Djibouti City',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:Djibplamar.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti_City',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'NRtDFrPX',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Lac Abbe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/herd-of-goat-near-mountains-during-day-vmfKwrqkOkA',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti',
        },
        landmark: {
          name: 'Day Forest National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/benoit871/4464488552',
          wikiLink: 'https://en.wikipedia.org/wiki/Day_Forest_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Djibouti City',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/52978719939',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti_City',
        },
        landmarksRound: [
          { code: 'UY', number: 1 },
          { code: 'MS', number: 1 },
          { code: 'TN', number: 2 },
        ],
        landmarkPlaces: [
          'Acropolis',
          "Prince's Palace of Monaco",
          'Castle Rushden',
          'Obelisk of Axum',
          'Bled Castle',
          'Victoria Falls Bridge',
          'Kunta Kinteh Island',
        ],
        uniqueId: 'z5P68rxM',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Djibouti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-wallpaper-QGEqzZ7VWqs',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti',
        },
        landmark: {
          name: 'Mosquée Al-Hamoudi',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/fran001/25125332386',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamoudi_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Ali Sabieh',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ali_Sabieh#/media/File:View_of_Ali_Sabieh.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Ali_Sabieh',
        },
        landmarksRound: [
          { code: 'GB', number: 1 },
          { code: 'LA', number: 2 },
          { code: 'MS', number: 2 },
        ],
        landmarkPlaces: [
          "St. Peter's Basilica",
          'Dubai Frame',
          'Metropolitan Cathedral of San Salvador',
          'Soufriere Hills',
          'Macau Tower',
          'Peel Castle',
          'Boudhanath',
        ],
        uniqueId: 'c9FMRKKx',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Djibouti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lone-tree-in-the-middle-of-a-rocky-area-4nU4ztBhXmM',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti',
        },
        landmark: {
          name: 'Lake Assal ',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/96601864@N06/9562653966/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Assal_(Djibouti)',
          hasLandmark: true,
        },
        city: {
          name: 'Djibouti City',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/52972714697/',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti_City',
        },
        landmarksRound: [
          { code: 'CO', number: 1 },
          { code: 'CY', number: 6 },
          { code: 'AU', number: 2 },
        ],
        landmarkPlaces: [
          'The Ethnographic Open-Air Museum of Latvia',
          'Plitvice Lakes National Park',
          'Kaieteur Falls',
          'Bled Castle',
          'Cristo Rei',
          'Mount Rushmore',
          'Queen Emma Bridge',
        ],
        uniqueId: 'HQJ6Gxq7',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Djibouti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-rocky-landscape-with-a-cloudy-sky-Vph5Trg5XW8',
          wikiLink: 'https://en.wikipedia.org/wiki/Djibouti',
        },
        landmark: {
          name: 'Hamoudi Mosque',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/fran001/24856003400/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamoudi_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Ali Sabieh',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/benoit871/4445229304/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ali_Sabieh',
        },
        landmarksRound: [
          { code: 'KH', number: 1 },
          { code: 'CU', number: 6 },
          { code: 'TW', number: 1 },
        ],
        landmarkPlaces: [
          'Fort Sao Sebastiao',
          'Great Mosque of Porto-Novo',
          'Sandy Island',
          'Plaza Murillo',
          'Kiritimati',
          'Kallur Lighthouse',
          'Mount Yasur',
        ],
        uniqueId: 'pDfBVFh4',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Dominica',
    code: 'DM',
    capital: 'Roseau',
    lat: 15.41666666,
    long: -61.33333333,
    cities: [
      'Roseau',
      'Portsmouth',
      'Marigot',
      'Salisbury',
      'Soufrière',
      'Vieille Case',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/xQmCJOklax4',
          wikiLink: 'https://en.wikipedia.org/wiki/Roseau',
        },
        landmark: {
          name: 'Trafalgar Falls',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Trafalgar_Falls.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominica',
          hasLandmark: true,
        },
        city: {
          name: 'Portsmouth',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Portsmouth,_Dominica#/media/File:Portsmouth_(Dominica).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Portsmouth,_Dominica',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Y7QFCcGL',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/dominica-roseau-caribbean-holiday-4645317/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominica',
        },
        landmark: {
          name: 'Emerald Pool',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Emerald_Pool,_Dominica.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Emerald_Pool',
          hasLandmark: true,
        },
        city: {
          name: 'Roseau',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/dominica-roseau-caribbean-holiday-4645317/',
          wikiLink: 'https://en.wikipedia.org/wiki/Roseau',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'QkVTVWKw',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Grand Bay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-old-building-with-arches-and-a-clock-on-the-wall-WSBMYT9tXJQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Bay,_Dominica',
        },
        landmark: {
          name: 'Champagne Reef, Dominica',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/travel_thing/16110452360',
          wikiLink: 'https://en.wikipedia.org/wiki/Champagne_Beach_(Dominica)',
          hasLandmark: true,
        },
        city: {
          name: 'Roseau',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Roseau#/media/File:Roseau_in_the_Morning2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Roseau',
        },
        landmarksRound: [
          { code: 'ME', number: 1 },
          { code: 'US', number: 1 },
          { code: 'VA', number: 1 },
        ],
        landmarkPlaces: [
          'Burj Khalifa',
          'Milwane Wildlife Sanctuary',
          'Temple of Literature',
          'Annaberg Sugar Plantation',
          'Joya De Ceren Archeological Site',
          'Peel Castle',
          'Mosquee Al-Hamoudi',
        ],
        uniqueId: 'HdGQ7VCw',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Rosalie Bay Resort',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/ocean-waves-near-forest-p_NYMvnIo4w',
          wikiLink: 'https://en.wikipedia.org/wiki/Rosalie,_Dominica',
        },
        landmark: {
          name: 'Boiling Lake',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/discover_dominica/6244508896',
          wikiLink: 'https://en.wikipedia.org/wiki/Boiling_Lake',
          hasLandmark: true,
        },
        city: {
          name: 'Portsmouth',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Portsmouth,_Dominica#/media/File:Portsmouth_(Dominica).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Portsmouth,_Dominica',
        },
        landmarksRound: [
          { code: 'PF', number: 1 },
          { code: 'MX', number: 2 },
          { code: 'US', number: 2 },
        ],
        landmarkPlaces: [
          'Tomb of Hafez',
          'The Marble Mountains',
          'Iluissat Icefjord',
          'Katse Dam',
          'Grand Ducal Palace',
          'Plateau Mosque',
          'Sans-Soucis Palace',
        ],
        uniqueId: 'P39FmkzF',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Dominica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-with-trees-in-front-of-it-3yJEoZai94o',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominica',
        },
        landmark: {
          name: 'Boiling Lake',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/craigzone/16312833852/',
          wikiLink: 'https://en.wikipedia.org/wiki/Boiling_Lake',
          hasLandmark: true,
        },
        city: {
          name: 'Roseau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-cruise-ship-is-docked-in-the-water-near-a-city-xQmCJOklax4',
          wikiLink: 'https://en.wikipedia.org/wiki/Roseau',
        },
        landmarksRound: [
          { code: 'SM', number: 1 },
          { code: 'CU', number: 1 },
          { code: 'KZ', number: 2 },
        ],
        landmarkPlaces: [
          'Frederiksborg Castle',
          'Arenal Volcano',
          'Voortrekker Monument',
          'Island of Mozambique',
          'Tash Rabat',
          'Talietumu',
          'Cathédrale Notre-Dame',
        ],
        uniqueId: '9gXVmjqj',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Dominica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/waterfalls-and-grass-mountain-during-day-vu5Lhk35MRg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominica',
        },
        landmark: {
          name: 'Fort Shirley',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/faun070/52888335207/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Shirley_(Dominica))',
          hasLandmark: true,
        },
        city: {
          name: 'Portsmouth',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/badhorsie/8303815266/',
          wikiLink: 'https://en.wikipedia.org/wiki/Portsmouth,_Dominica',
        },
        landmarksRound: [
          { code: 'IL', number: 4 },
          { code: 'BH', number: 5 },
          { code: 'AD', number: 4 },
        ],
        landmarkPlaces: [
          'Hagia Sophia',
          'Mir Castle',
          'Plaza Murillo',
          'Morne Seychellois',
          'Patuxay Monument',
          'Fortaleza de São José da Amura',
          'Marigot Bay',
        ],
        uniqueId: 'GG8SDXHq',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    capital: 'Santo Domingo',
    lat: 19,
    long: -70.66666666,
    cities: [
      'Santo Domingo',
      'Santiago De Los Caballeros',
      'Puerto Plata',
      'Higuey',
      'San Pedro De Macoris',
      'Santiago',
      'Boca Chica',
      'San Pedro de Macorís',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/r-YpH_-pd34',
          wikiLink:
            'https://en.wikipedia.org/wiki/Ciudad_Colonial_(Santo_Domingo)',
        },
        landmark: {
          name: 'Columbus Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Parque_Colon#/media/File:Gente_CCSD_07_2018_0662.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Parque_Colon',
          hasLandmark: true,
        },
        city: {
          name: 'Santo Domingo',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/market-shops-street-urban-business-6389041/',
          wikiLink: 'https://en.wikipedia.org/wiki/Santo_Domingo',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'khPvl7nn',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/dominican-republic-caribbean-sea-321214/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominican_Republic',
        },
        landmark: {
          name: 'Monument to the Heroes of the Restoration',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Monumento_de_Santiago#/media/File:Monumento_a_los_H%C3%A9roes_de_la_Restauraci%C3%B3n.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Monumento_de_Santiago',
          hasLandmark: true,
        },
        city: {
          name: 'Santiago De Los Caballeros',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Santiago_de_los_Caballeros#/media/File:Santiago-edit.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Santiago_de_los_Caballeros',
        },
        landmarksRound: [
          { code: 'MC', number: 1 },
          { code: 'SY', number: 2 },
          { code: 'MN', number: 2 },
        ],
        landmarkPlaces: [
          'Kittiwake Shipwreck',
          'Church of Our Savior',
          'Dhakeshwari Temple',
          'Temple of Literature',
          'Wawel Castle',
          'Hato Caves',
          'Romney Manor',
        ],
        uniqueId: 'vHCmxXdd',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Zona Colonial',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-buildings-under-white-clouds-during-daytime-r-YpH_-pd34',
          wikiLink:
            'https://en.wikipedia.org/wiki/Ciudad_Colonial_(Santo_Domingo)',
        },
        landmark: {
          name: 'Columbus Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/dennisk/3041112140',
          wikiLink: 'https://en.wikipedia.org/wiki/Parque_Colon',
          hasLandmark: true,
        },
        city: {
          name: 'Santo Domingo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Santo_Domingo#/media/File:Columbus-Park-Dominican-Republic.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Santo_Domingo',
        },
        landmarksRound: [
          { code: 'SI', number: 1 },
          { code: 'LR', number: 2 },
          { code: 'MS', number: 2 },
        ],
        landmarkPlaces: [
          'Laferriere Citadel',
          'Bissau Cathedral',
          'Ocean Park',
          'Sibebe Rock',
          'Abu Simbel Temples',
          'Ducie Island',
          'Republic Square',
        ],
        uniqueId: 'jj5NkRGx',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Santo Domingo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-fruit-on-black-metal-cart-4l1hEB6netE',
          wikiLink: 'https://en.wikipedia.org/wiki/Santo_Domingo',
        },
        landmark: {
          name: 'Alcázar de Colón',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/33195170432',
          wikiLink: 'https://en.wikipedia.org/wiki/Alc%C3%A1zar_de_Col%C3%B3n',
          hasLandmark: true,
        },
        city: {
          name: 'Santiago',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Santiago_de_los_Caballeros#/media/File:Santiago_Northeast.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Santiago_de_los_Caballeros',
        },
        landmarksRound: [
          { code: 'FO', number: 1 },
          { code: 'LU', number: 2 },
          { code: 'ZW', number: 2 },
        ],
        landmarkPlaces: [
          'The Little Chapel',
          'Akanda National Park',
          'Magnus Cathedral',
          'Tavarua',
          "Lac 'Assal",
          'Lake Armor',
          'Prambanan Temple',
        ],
        uniqueId: 'Lp8v7vr5',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Dominican Republic',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lush-green-hillside-with-a-house-on-top-of-it-dGr89KhNelg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominican_Republic',
        },
        landmark: {
          name: 'Parque Colón',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-pedestrian-lane-near-green-trees-during-daytime-19fWyBO2A_E',
          wikiLink: 'https://en.wikipedia.org/wiki/Parque_Col%C3%B3n',
          hasLandmark: true,
        },
        city: {
          name: 'Boca Chica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-road-next-to-a-body-of-water-3El0hM43_9k',
          wikiLink: 'https://en.wikipedia.org/wiki/Boca_Chica',
        },
        landmarksRound: [
          { code: 'SJ', number: 1 },
          { code: 'GW', number: 1 },
          { code: 'KH', number: 1 },
        ],
        landmarkPlaces: [
          'Khost Mosque',
          'Chichen Itza, Mexico',
          'Sydney Opera House',
          'Mumbo Island',
          'Charles Bridge',
          'Temppeliaukio Church',
          "St. Stephen's Cathedral",
        ],
        uniqueId: 'nYqdGWJM',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Dominican Republic',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-white-beach-A49CnofKUTo',
          wikiLink: 'https://en.wikipedia.org/wiki/Dominican_Republic',
        },
        landmark: {
          name: 'Altos de Chavón',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/pau-a100/6778986852/',
          wikiLink: 'https://en.wikipedia.org/wiki/Altos_de_Chav%C3%B3n',
          hasLandmark: true,
        },
        city: {
          name: 'San Pedro de Macorís',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-an-old-building-in-a-city-q-XJCJD1ndM',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Pedro_de_Macor%C3%ADs',
        },
        landmarksRound: [
          { code: 'BS', number: 6 },
          { code: 'JE', number: 4 },
          { code: 'HK', number: 1 },
        ],
        landmarkPlaces: [
          'Fort Zeelandia',
          'Little Bay',
          'Cape Coast Castle',
          'Luis I Bridge',
          'Plitvice Lakes National Park',
          'Amberd',
          'Door to Hell',
        ],
        uniqueId: 'lbqs4kTt',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Ecuador',
    code: 'EC',
    capital: 'Quito',
    lat: -2,
    long: -77.5,
    cities: ['Quito', 'Guayaquil', 'Cuenca', 'Ambato', 'Manta', 'Salinas'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Gz-ZBb6-mrE',
          wikiLink: 'https://en.wikipedia.org/wiki/Marine_iguana',
        },
        landmark: {
          name: 'Middle of the World City',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ciudad_Mitad_del_Mundo#/media/File:Mitad_del_Mundo,_Quito,_Ecuador,_2015-07-22,_DD_15.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Ciudad_Mitad_del_Mundo',
          hasLandmark: true,
        },
        city: {
          name: 'Quito',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bas%C3%ADlica_del_Voto_Nacional#/media/File:Bas%C3%ADlica_del_Voto_Nacional_from_El_Panecillo.jpeg',
          wikiLink: 'https://en.wikipedia.org/wiki/Quito',
        },
        landmarksRound: [
          { code: 'NO', number: 1 },
          { code: 'KN', number: 2 },
          { code: 'FR', number: 2 },
        ],
        landmarkPlaces: [
          'Timgad',
          'Rhine Falls',
          'Temple of Literature',
          'Acropolis',
          "St. Tryphon's Cathedral",
          'Arecibo Observatory',
          'La Sagrada Familia',
        ],
        uniqueId: 'kC8Dyk2h',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lake-wetland-mountains-landscape-6791971/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ecuador',
        },
        landmark: {
          name: 'Basilica del Voto Nacional',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bas%C3%ADlica_del_Voto_Nacional#/media/File:Bas%C3%ADlica_del_Voto_Nacional_-_Clock_towers.jpeg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Bas%C3%ADlica_del_Voto_Nacional',
          hasLandmark: true,
        },
        city: {
          name: 'Guayaquil',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bella-vista-guayaquil-ecuador-7456957/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guayaquil',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'n7M9B5Z7',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cotopaxi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-black-mountain-under-blue-sky-during-daytime--mXlubcOD58',
          wikiLink: 'https://en.wikipedia.org/wiki/Cotopaxi',
        },
        landmark: {
          name: 'Virgin of El Panecillo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/quitoturismo/14814049039',
          wikiLink: 'https://en.wikipedia.org/wiki/Virgin_of_El_Panecillo',
          hasLandmark: true,
        },
        city: {
          name: 'Cuenca',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-car-parked-in-front-of-white-concrete-building-during-daytime-GWpqLP2OfV0',
          wikiLink: 'https://en.wikipedia.org/wiki/Cuenca,_Ecuador',
        },
        landmarksRound: [
          { code: 'BB', number: 3 },
          { code: 'CM', number: 4 },
          { code: 'AX', number: 3 },
        ],
        landmarkPlaces: [
          'Bab Al Bahrain',
          'Wallblake House',
          'Silver Pagoda',
          'Ouagadougou Cathedral',
          'Kyrenia Castle',
          'Valle de la Luna',
          'Ma Chor Nui Nui Temple',
        ],
        uniqueId: 'ycdyd26k',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Ecuador',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-people-walking-up-a-hill-with-a-scarf-F3BBLFml5Hw',
          wikiLink: 'https://en.wikipedia.org/wiki/Ecuador',
        },
        landmark: {
          name: 'Quilotoa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-lake-near-mountain-under-blue-sky-during-daytime-ifR_v_GWliQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Cotopaxi',
          hasLandmark: true,
        },
        city: {
          name: 'Salinas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-boats-on-sea-near-city-buildings-during-daytime-2zZ-0iFjojs',
          wikiLink: 'https://en.wikipedia.org/wiki/Salinas,_Ecuador',
        },
        landmarksRound: [
          { code: 'KH', number: 3 },
          { code: 'DZ', number: 2 },
          { code: 'CA', number: 4 },
        ],
        landmarkPlaces: [
          'Bab Al Bahrain',
          'White Bay Bar',
          "Diocletian's Palace",
          'Mir Castle',
          'South Pole',
          'Bahrain Financial Harbour',
          'Fort Beekenburg',
        ],
        uniqueId: 'Rtww8nyw',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Ecuador',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/train-on-railway-between-green-mountains-during-daytime-qP2bR5Ky3JE',
          wikiLink: 'https://en.wikipedia.org/wiki/Ecuador',
        },
        landmark: {
          name: 'Ciudad Mitad del Mundo',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/eliasroviello/49196857601/in/photolist-2hXmKLM-2hXg9gk-2hXjZhE-2hXgmog-2hXj7Pr-2hXk1Qj-2hXiM7o-2hXnGKa-2hXj7Ko-2hXnGB4-qkRY6G-2hXj7wn-2hYAJDY-23e38FV-2hXj4vY-2hXmMMk-2hXj8ho-2hXj7Cz-2ifpBpa-2hXgr1s-2hXiVh1-2i1zSxV-2hXjNBw-2prcBkV-xhB8pg-2n7QABU-2j3wiC3-ErPdm8-2nc9nDh-2nc7Wp5-2nndHT1-2mJyF7A-24JEeXp-2nSfMij-2kdnueJ-2naNZJ2-2omSVJt-KmPFc1-4waeJC-2j9KBhY-2nc9oxX-dAchzo-a1mYCL-2mHRPaN-2mJbj2D-TsneZ9-7J24t2-2nc6vn5-oB7YRe-MkADmx',
          wikiLink: 'https://en.wikipedia.org/wiki/Ciudad_Mitad_del_Mundo',
          hasLandmark: true,
        },
        city: {
          name: 'Cuenca',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-standing-around-tables-with-umbrellas-jb6CdUUAR4g',
          wikiLink: 'https://en.wikipedia.org/wiki/Cuenca,_Ecuador',
        },
        landmarksRound: [
          { code: 'ZW', number: 3 },
          { code: 'TZ', number: 1 },
          { code: 'CX', number: 1 },
        ],
        landmarkPlaces: [
          'Annaberg plantation',
          'Mumbo Island',
          'Agadez Grand Mosque',
          'Trafalgar Falls',
          'Alcázar de Colón',
          'Kpatawee Falls',
          'Museum ship Pommern',
        ],
        uniqueId: 'QgFrKGkW',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Quito',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/53888266@N02/6971569230/in/photolist-bC47Du-bQXMRV-bC459G-bQXLog-bQXPeR-2iYFn5s-2mQwtS3-2iYCBTZ-bC45xo-bQXLyF-bC46RJ-bQXKjP-bC46g7-bQXKs4-bQXKWz-2iYCBLz-5bJ4gT-T857z9-2iYCye9-kdz4BK-2mQAGUB-T8591L-2DZLyU-2mQE1Dm-2mPZEaD-2mQ744Z-2mR3cpR-2mQfP8H-2ksvjjB-2ife5Fa-HryUD4-HrxSxH-212Y4se-22oh6Yi-Hrzyo2-212XXCZ-Hrzqoz-CJLPV8-Hrzzs6-212Y5V4-Hrzt6P-21i21ms-EfwF5d-21i1tU5-HrxZGc-HrzxJB-21i2Y37-21i2Xvf-21i1ukf-HrzsDM',
          wikiLink: 'https://en.wikipedia.org/wiki/Quito',
        },
        landmark: {
          name: 'New Cathedral of Cuenca',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/48166939@N06/24514824682/in/photolist-DmhTi5-CytGcc-2aqepxu-jhopeV-SMYFbG-bdQu7Z-Frd2NC-hVWsrZ-hVYVmB-iAjnk4-bieXtZ-iAk9xg-cBUPWs-2bvVKat-gCx7Vm-6ms27D-oAd6ze-6F6jkK-oArX7A-fj9e4T-wy6fL7-9Kkbve-iAjnzn-FCwts3-pzdmW-Z39WdX-2jUKeMX-2opjBPh-2hZq59T-2oqLB61-2pHqk96-2hZNazD-2osR5jv-orZQ7Q-cDE4FG-4bvEbS-7Tb17d-TmhFbi-V2LfNp-UooGoe-9f8vDk-TKfrAQ-UYee31-9f8p3Z-UriSiA-47E9Xn-9f8tYT-9fbtiQ-9f8n7g-2hZq5Ap',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Cathedral_of_Cuenca',
          hasLandmark: true,
        },
        city: {
          name: 'Guayaquil',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-down-a-street-8BV6Tb5MjZ4',
          wikiLink: 'https://en.wikipedia.org/wiki/Guayaquil',
        },
        landmarksRound: [
          { code: 'CW', number: 4 },
          { code: 'SR', number: 2 },
          { code: 'TL', number: 1 },
        ],
        landmarkPlaces: [
          'Leshan Giant Buddha',
          'Amber Palace',
          'Golden Gate',
          'Casa de la Vall',
          'Sans-Souci Palace',
          'Floralis Generica',
          'Leptis Magna',
        ],
        uniqueId: 'YrwJq5V7',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Egypt',
    code: 'EG',
    capital: 'Cairo',
    lat: 27,
    long: 30,
    cities: ['Giza', 'Cairo', 'Luxor', 'Aswan', 'Alexandria', 'Faiyum'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/pwMbtwA9LRc',
          wikiLink:
            'https://en.wikipedia.org/wiki/Mosque-Madrasa_of_Sultan_Hassan',
        },
        landmark: {
          name: 'Karnak',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Karnak#/media/File:Temple_de_Louxor_68.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Karnak',
          hasLandmark: true,
        },
        city: {
          name: 'Alexandria',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/poor-crowded-living-washing-day-942712/',
          wikiLink: 'https://en.wikipedia.org/wiki/Alexandria',
        },
        landmarksRound: [
          { code: 'TM', number: 2 },
          { code: 'AT', number: 1 },
          { code: 'MM', number: 1 },
        ],
        landmarkPlaces: [
          'Frogner Park',
          'Dubai Frame',
          'Chorsu Bazaar',
          'The Marble Mountains',
          'Predjama Castle',
          'Narikala Fortress',
          'Bled Castle',
        ],
        uniqueId: 'ZG2dbkqn',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/felucca-nile-sunset-flow-sailboat-7237715/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nile',
        },
        landmark: {
          name: 'Abu Simbel Temples',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/abu-simbel-temple-egypt-luxor-2703666/',
          wikiLink: 'https://en.wikipedia.org/wiki/Abu_Simbel',
          hasLandmark: true,
        },
        city: {
          name: 'Giza',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sahara-tourism-travel-vista-6870770/',
          wikiLink: 'https://en.wikipedia.org/wiki/Giza',
        },
        landmarksRound: [
          { code: 'MR', number: 1 },
          { code: 'OM', number: 1 },
          { code: 'GD', number: 1 },
        ],
        landmarkPlaces: [
          'Chillon Castle',
          'Easter Island',
          'Christ Church Cathedral',
          'Angel Falls',
          'Pulhapanzak Waterfalls',
          'Belem Tower',
          "St. Olaf's Church",
        ],
        uniqueId: 'LWZblL35',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cairo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-beige-temple-pwMbtwA9LRc',
          wikiLink: 'https://en.wikipedia.org/wiki/Cairo',
        },
        landmark: {
          name: 'Abu Simbel',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/amthomson/38086446594',
          wikiLink: 'https://en.wikipedia.org/wiki/Abu_Simbel',
          hasLandmark: true,
        },
        city: {
          name: 'Cairo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-beige-temple-pwMbtwA9LRc',
          wikiLink: 'https://en.wikipedia.org/wiki/Cairo',
        },
        landmarksRound: [
          { code: 'DJ', number: 2 },
          { code: 'HM', number: 2 },
          { code: 'IT', number: 2 },
        ],
        landmarkPlaces: [
          'Panfilov Park',
          'Elmina Castle',
          'Pico Basile',
          'Kasubi Royal Tombs',
          'Grace Bay Beach',
          'Plage de Grande Anse',
          'Arahoho Blowhole',
        ],
        uniqueId: 'XJLKVVYS',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Egypt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-sailboat-on-water-under-blue-cloudy-sky-during-daytime-photo-Si1MFhSLNWY',
          wikiLink: 'https://en.wikipedia.org/wiki/Egypt',
        },
        landmark: {
          name: 'Egyptian Museum',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Egyptian_Museum#/media/File:The_Egyptian_Museum.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Egyptian_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Alexandria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-boats-on-sea-near-city-buildings-under-blue-sky-during-daytime-zpmvpEXM_Qc',
          wikiLink: 'https://en.wikipedia.org/wiki/Alexandria',
        },
        landmarksRound: [
          { code: 'GW', number: 1 },
          { code: 'ZM', number: 2 },
          { code: 'UA', number: 1 },
        ],
        landmarkPlaces: [
          'Monte Carlo Casino',
          'Basilica of Our Lady of Guadalupe',
          'Palace of Versailles',
          'Kathmandu Durbar Square',
          'Stephen the Great Monument',
          'House of the Black Heads',
          'Baiterek',
        ],
        uniqueId: 'xLt683B3',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Egypt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/five-persons-riding-camels-walking-on-sand-beside-pyramid-of-egypt-boyXZfqpwpU',
          wikiLink: 'https://en.wikipedia.org/wiki/Egypt',
        },
        landmark: {
          name: 'Great Pyramid of Giza',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-black-and-white-photo-of-the-great-pyramid-of-giza-TGg5TABe-aA',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Pyramid_of_Giza',
          hasLandmark: true,
        },
        city: {
          name: 'Luxor',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-wall-with-art-on-it-vVqDav15TBk',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxor',
        },
        landmarksRound: [
          { code: 'FO', number: 4 },
          { code: 'IN', number: 2 },
          { code: 'IO', number: 1 },
        ],
        landmarkPlaces: [
          'Gishora Drum Sanctuary',
          'Demerara Bridge',
          'Palais de Congres',
          'Matafao Peak',
          'Rhine Falls',
          'Elmina Castle',
          'Charles Bridge',
        ],
        uniqueId: 'KNH7qZNh',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Cairo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-pyramids-of-egypt-rxv2qwYPe6s',
          wikiLink: 'https://en.wikipedia.org/wiki/Cairo',
        },
        landmark: {
          name: 'Luxor Temple',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-statue-of-a-man-sitting-in-front-of-two-tall-pillars-gac57ESW630',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxor_Temple',
          hasLandmark: true,
        },
        city: {
          name: 'Alexandria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-ruins-of-a-roman-city-with-palm-trees-in-the-background-t40qcD5JDVQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Alexandria',
        },
        landmarksRound: [
          { code: 'MT', number: 1 },
          { code: 'KG', number: 3 },
          { code: 'VA', number: 3 },
        ],
        landmarkPlaces: [
          'Epupa Falls',
          'Rarotonga',
          'Christ the King Cathedral',
          'The Belize Zoo',
          'Plaza Francia',
          'The Grand Palace',
          'Ban Gioc–Detian Falls',
        ],
        uniqueId: 'wX2kpqNw',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'El Salvador',
    code: 'SV',
    capital: 'San Salvador',
    lat: 13.83333333,
    long: -88.91666666,
    cities: [
      'San Salvador',
      'Santa Ana',
      'San Miguel',
      'Soyapango',
      'Apopa',
      'San Miguel',
      'Mejicanos',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gLysUycgcF4',
          wikiLink: 'https://en.wikipedia.org/wiki/Plaza_Gerardo_Barrios',
        },
        landmark: {
          name: 'Metropolitan Cathedral of San Salvador',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/San_Salvador_Cathedral#/media/File:Fachada_de_Catedral_Metropolitana_de_San_Salvador.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Salvador_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Santa Ana',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Santa_Ana,_El_Salvador#/media/File:ES_Palacio_Municipal_Santa_Ana_05_2012_1597.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Santa_Ana,_El_Salvador',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'rCr9QmgC',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/el-salvador-cerro-el-pimp-moutains-1820097/',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Salvador',
        },
        landmark: {
          name: 'Joya De Ceren Archeological Site',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Joya_de_Cer%C3%A9n#/media/File:ES_Estructura_1_Area_1_Joya_Ceren_05_2012_1513.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Joya_de_Cer%C3%A9n',
          hasLandmark: true,
        },
        city: {
          name: 'San Miguel',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/San_Miguel,_El_Salvador#/media/File:Hospital_Sn_Francisco.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Miguel,_El_Salvador',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'JBpVfTyk',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Los Planes de Renderos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-trees-near-body-of-water-bK-SRSgPWz8',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Salvador',
        },
        landmark: {
          name: 'Monumento al Divino Salvador del Mundo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Monumento_al_Divino_Salvador_del_Mundo#/media/File:Monumento_al_Salvador_del_Mundo_1.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Monumento_al_Divino_Salvador_del_Mundo',
          hasLandmark: true,
        },
        city: {
          name: 'San Salvador',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-pedestrian-lane-during-daytime-gLysUycgcF4',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Salvador',
        },
        landmarksRound: [
          { code: 'LV', number: 1 },
          { code: 'AE', number: 2 },
          { code: 'LB', number: 1 },
        ],
        landmarkPlaces: [
          'Champagne Reef, Dominica',
          'Karnak',
          'Jardin de Balata',
          'Boudhanath',
          'Azadi Tower',
          'Ocean Park',
          'Vaduz Cathedral',
        ],
        uniqueId: 'jxQ2thCN',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'La Paz Department',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-body-of-water-under-blue-sky-during-daytime-YT4pUNOa5wI',
          wikiLink:
            'https://en.wikipedia.org/wiki/La_Paz_Department_(El_Salvador)',
        },
        landmark: {
          name: 'Tazumal',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tazumal#/media/File:ES_Tazumal_06_2011_2236.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tazumal',
          hasLandmark: true,
        },
        city: {
          name: 'Santa Ana',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Santa_Ana,_El_Salvador#/media/File:ES_Palacio_Municipal_Santa_Ana_05_2012_1597.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Santa_Ana,_El_Salvador',
        },
        landmarksRound: [
          { code: 'LA', number: 2 },
          { code: 'MG', number: 1 },
          { code: 'IT', number: 2 },
        ],
        landmarkPlaces: [
          'Saunders Island',
          'Fortaleza de São José da Amura',
          'Tegucigalpa Cathedral',
          'Chorsu Bazaar',
          'Alhambra',
          'Dziani Dzaha',
          'Blue Lagoon',
        ],
        uniqueId: 'GH8cjS4v',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'El Salvador',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-mountain-range-with-trees-and-mountains-in-the-background-OJa2mbeN4p8',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Salvador',
        },
        landmark: {
          name: 'Monumento al Divino Salvador del Mundo',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/199244240@N06/53754418924/in/photolist-8cfTpu-8ccxna-8q7c7R-8q7bNZ-8cfT1o-qJPwi6-qFA3Ri-634XVq-62YPiV-qFKLm8-62YNPv-ds5vtK-dtqyvv-ds5AYq-qpkmgR-dtw7BG-qFKJAK-6qFmxH-93BAfZ-93Bzfa-4mHU3a-8q7c1p-ppYGNj-dtw7w9-dtqyDH-3bnhP3-qFA6aX-qpkjjK-pJYYAF-93Bz7g-dtqywg-93EEFh-93BAcv-93Bzmi-93Bzsv-dtw7wN-dtqywX-vK4fs-QH2ihD-dtw7u7-dtqyui-93EEVs-93EERo-PqNvJb-2pU6rYh',
          wikiLink:
            'https://en.wikipedia.org/wiki/Monumento_al_Divino_Salvador_del_Mundo',
          hasLandmark: true,
        },
        city: {
          name: 'Mejicanos',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/worldmarch/4184146049/in/photolist-5RaKME-8d3BDV-5R6JxT-gj1Ssu-gfQWFN-5Rbbb3-4UMWbN-4wXrC8-6chjnx-9HeUG1-5RaS3Q-5RbbUQ-bh93T8-7i1srH-5JmooN-5R6ByH-9tumrX-5MvnUk-6y3p18-5KgSvv-5R6sUM-69u1G8-67L5i5-67G4sv-67G622-5R6tir-5R6LBR-BgsB7q-7nKEdv-7nKD7M-67GtXB-67FV1V-5RaT5m-7nJPTx-67GrPc-7nJQR8-67FWcx-5Rb2mU-BgsBXd-6Qc11H-6Qc4cg-6QgfG9-6QgdvG-6Qc658-2mLhLDr-2mLoeXv-2mLmNL3-2mLmMpv-67LBfY',
          wikiLink: 'https://en.wikipedia.org/wiki/Mejicanos',
        },
        landmarksRound: [
          { code: 'VI', number: 4 },
          { code: 'SM', number: 1 },
          { code: 'CZ', number: 1 },
        ],
        landmarkPlaces: [
          'Donkey Sanctuary',
          'Cao Grande Peak',
          'Fort Antoine Theatre',
          'Mount Cameroon',
          'Dubai Frame',
          'La Soufriere',
          'National Shrine Basilica of our Lady of Las Lajas',
        ],
        uniqueId: 'DxcMvv4V',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'San Salvador',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-pedestrian-lane-during-daytime-gLysUycgcF4',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Salvador',
        },
        landmark: {
          name: 'Metropolitan Cathedral of San Salvador',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/miquelfabre/45189001185/in/photolist-2bRcrG6-2hxdu5M-2q5PaUm-2o6c5fc-kD9vFs-QTBjCg-7SzHSJ-bWXjqL-e65A4r-gh7Jp7-dN8QY6-e6bdEu-fDChvH-2ax4SAn-2kdDsJr-k4jB8c-2kGEn6y-nkjcTw-RaAbtM-27MkbXw-QTBkYH-QTBj8P-2n3yzgd-2n3Dy3R-QTBmnP-cT4h19-hC5RHQ-dczG8R-jFobWy-nfqi1a-dK19c1-2eiPDRb-eeuzp5-fuX9hR-fssBCF-o7puui-i6q2Bi-zTKyob-hjM6yz-2h8jsSU-ehuxyw-akMNsu-2h8jsUY-n1pN4y-cjqqeN-DiE22g-DoDKNL-DqX1qe-CSqoQT-sF8ahc',
          wikiLink:
            'https://en.wikipedia.org/wiki/Metropolitan_Cathedral_of_San_Salvador',
          hasLandmark: true,
        },
        city: {
          name: 'Santa Ana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-mountain-with-clouds-in-the-sky-LoFu__cLvUc',
          wikiLink: 'https://en.wikipedia.org/wiki/Santa_Ana,_El_Salvador',
        },
        landmarksRound: [
          { code: 'AE', number: 2 },
          { code: 'JM', number: 4 },
          { code: 'TJ', number: 1 },
        ],
        landmarkPlaces: [
          'Equatoria Tower',
          'Philharmonic Garden ',
          'Somapura Mahavihara',
          'Obelisk of Axum',
          'Saint-Pierre Cathedral of Saint Pierre and Miquelon',
          'Basilica of Mary Queen of Apostles',
          'Lahore Fort',
        ],
        uniqueId: 'FDKtl3Y3',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    capital: 'Malabo',
    lat: 2,
    long: 10,
    cities: ['Malabo', 'Bata', 'Luba', 'Mongomo', 'Evinayong', 'Aconibe'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/equatorial_guinea/4641618625',
          wikiLink: 'https://en.wikipedia.org/wiki/Equatorial_Guinea',
        },
        landmark: {
          name: 'Pico Basile',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Pico_Basil%C3%A9#/media/File:Pico_Basil%C3%A9.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pico_Basil%C3%A9',
          hasLandmark: true,
        },
        city: {
          name: 'Bata',
          photographer: '',
          imageLink:
            'https://commons.m.wikimedia.org/wiki/File:Bata,_2015-02_-i---i-_%2816513134635%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bata,_Equatorial_Guinea',
        },
        landmarksRound: [
          { code: 'TH', number: 2 },
          { code: 'AL', number: 1 },
          { code: 'FI', number: 1 },
        ],
        landmarkPlaces: [
          'Bran Castle',
          'Tikal National Park',
          'Karnak',
          'Voortrekker Monument',
          'Forbidden City',
          'Skopje Fortress',
          'Fort Sao Sebastiao',
        ],
        uniqueId: '6ppRjRfT',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/equatorial_guinea/5238701955',
          wikiLink: 'https://en.wikipedia.org/wiki/Malabo',
        },
        landmark: {
          name: 'Torre de la Libertad',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Torre_de_La_Libertad#/media/File:Torre_de_la_Libertad.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Torre_de_La_Libertad',
          hasLandmark: true,
        },
        city: {
          name: 'Malabo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/wapster/3297513032',
          wikiLink: 'https://en.wikipedia.org/wiki/Malabo',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'RKNFrhY2',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Bioko Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rjroberts/26168766041',
          wikiLink: 'https://en.wikipedia.org/wiki/Equatorial_Guinea',
        },
        landmark: {
          name: 'St. James and Our Lady of the Pillar Cathedral',
          photographer: '',
          imageLink:
            'https://es.wikipedia.org/wiki/Catedral_de_Santiago_y_Nuestra_Se%C3%B1ora_del_Pilar_(Bata)#/media/Archivo:Bata,_2015-02_-i---i-_(15890592774).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._James_and_Our_Lady_of_the_Pillar_Cathedral,_Bata',
          hasLandmark: true,
        },
        city: {
          name: 'Bata',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bata,_Equatorial_Guinea#/media/File:Torre_de_la_Libertad.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bata,_Equatorial_Guinea',
        },
        landmarksRound: [
          { code: 'UA', number: 1 },
          { code: 'ZM', number: 2 },
          { code: 'MC', number: 2 },
        ],
        landmarkPlaces: [
          'Vianden Castle',
          'Kiritimati',
          'Iluissat Icefjord',
          'Ampitheater of El Jem',
          'Grace Bay Beach',
          'Arecibo Observatory',
          'Christ Church Cathedral',
        ],
        uniqueId: 'vTNkKnsr',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'National University of Equatorial Guinea',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/equatorial_guinea/4727255945',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_University_of_Equatorial_Guinea',
        },
        landmark: {
          name: 'Parque Nacional de Malabo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/chemalcaraz/46531655692',
          wikiLink: 'https://en.wikipedia.org/wiki/Malabo',
          hasLandmark: true,
        },
        city: {
          name: 'Malabo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Malabo#/media/File:Malabo_(16486980296).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Malabo',
        },
        landmarksRound: [
          { code: 'HU', number: 1 },
          { code: 'MD', number: 2 },
          { code: 'MV', number: 1 },
        ],
        landmarkPlaces: [
          'Egyptian Museum',
          'Blue Grotto',
          'Tikal Temple I',
          'Fort Nuestra Senora de la Soledad',
          'Prambanan Temple',
          'Annandale Waterfall',
          'Green Grotto Caves',
        ],
        uniqueId: '8XhG7qnP',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Malabo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rbcav2003/530802424/',
          wikiLink: 'https://en.wikipedia.org/wiki/Malabo',
        },
        landmark: {
          name: 'St. James and Our Lady of the Pillar Cathedral',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/melanieandjohn/1479165932/',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._James_and_Our_Lady_of_the_Pillar_Cathedral,_Bata',
          hasLandmark: true,
        },
        city: {
          name: 'Malabo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rbcav2003/530910095/',
          wikiLink: 'https://en.wikipedia.org/wiki/Malabo',
        },
        landmarksRound: [
          { code: 'ZM', number: 3 },
          { code: 'TM', number: 3 },
          { code: 'AU', number: 1 },
        ],
        landmarkPlaces: [
          'Marina Bay Sands',
          'Porte du Non Retour',
          'Maralgöl',
          'The Basilica of Our Lady of Peace of Yamoussoukro',
          'Frida Kahlo Museum',
          'Gergeti Trinity Church',
          'The Pinnacles Desert',
        ],
        uniqueId: 's26nqk5K',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Equatorial Guinea',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/emekab/16012940600/',
          wikiLink: 'https://en.wikipedia.org/wiki/Equatorial_Guinea',
        },
        landmark: {
          name: "St. Elizabeth's Cathedral",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/equatorial_guinea/4711314217/',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Elizabeth%27s_Cathedral,_Malabo',
          hasLandmark: true,
        },
        city: {
          name: 'Bata',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/equatorial_guinea/4688166410/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bata,_Equatorial_Guinea',
        },
        landmarksRound: [
          { code: 'HT', number: 1 },
          { code: 'LY', number: 3 },
          { code: 'PA', number: 2 },
        ],
        landmarkPlaces: [
          'Souq Waqif',
          'Timgad',
          'Point Venus',
          'National Park of American Samoa',
          'Chutes de la Métché',
          'Kelonia',
          'Sagrada Família',
        ],
        uniqueId: 'zTPhmbyB',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Eritrea',
    code: 'ER',
    capital: 'Asmara',
    lat: 15,
    long: 39,
    cities: ['Massawa', 'Asmara', 'Mitsiwa', 'Assab', 'Adi Ugri', 'Keren'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/eritrea-landscape-tents-huts-105081/',
          wikiLink: 'https://en.wikipedia.org/wiki/Eritrea',
        },
        landmark: {
          name: 'Fiat Tagliero',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fiat_Tagliero_Building#/media/File:Fiat_tagliero,_08.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiat_Tagliero_Building',
          hasLandmark: true,
        },
        city: {
          name: 'Massawa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Massawa#/media/File:Massawa_Housing_Complex_(8527948059).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Massawa',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '2w6BBTdD',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/eritrea-mountains-valley-landscape-114822/',
          wikiLink: 'https://en.wikipedia.org/wiki/Eritrea',
        },
        landmark: {
          name: 'Cathedral Our Lady of the Rosary',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Church_of_Our_Lady_of_the_Rosary,_Asmara#/media/File:Asmara,_cattedrale_cattolica,_01.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Church_of_Our_Lady_of_the_Rosary,_Asmara',
          hasLandmark: true,
        },
        city: {
          name: 'Asmara',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Asmara#/media/File:Asmara_panorama,_Eritrea.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Asmara',
        },
        landmarksRound: [
          { code: 'NP', number: 1 },
          { code: 'MQ', number: 1 },
          { code: 'ZA', number: 2 },
        ],
        landmarkPlaces: [
          'Mount Paget',
          'Monument to the Heroes of the Restoration',
          "Queen's Staircase",
          'Tsingy De Bemaraha',
          'Jardin de Balata',
          'Christ of Havana',
          'Buenos Aires Obelisk',
        ],
        uniqueId: 'tgcXkSqh',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Asmara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-man-during-sunset-t85pKpB5Zf4',
          wikiLink: 'https://en.wikipedia.org/wiki/Asmara',
        },
        landmark: {
          name: 'Church of Our Lady of the Rosary',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Church_of_Our_Lady_of_the_Rosary,_Asmara#/media/File:Asmara,_cattedrale_cattolica,_01.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Church_of_Our_Lady_of_the_Rosary,_Asmara',
          hasLandmark: true,
        },
        city: {
          name: 'Asmara',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Asmara#/media/File:Asmara,_bar_zilli_02.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Asmara',
        },
        landmarksRound: [
          { code: 'US', number: 1 },
          { code: 'MY', number: 2 },
          { code: 'DJ', number: 3 },
        ],
        landmarkPlaces: [
          'Petra',
          'Ruhyyet Palace',
          'Cliffs of Moher',
          'Mumbo Island',
          'Victoria Falls Bridge',
          'Vianden Castle',
          'Dubai Frame',
        ],
        uniqueId: 'c9Fzk8vq',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Glas',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/danielzolli/28671702110',
          wikiLink: 'https://en.wikipedia.org/wiki/Eritrea',
        },
        landmark: {
          name: 'Great Mosque of Asmara',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Great_Mosque_of_Asmara#/media/File:Great_Mosque_(8351473351).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Mosque_of_Asmara',
          hasLandmark: true,
        },
        city: {
          name: 'Keren',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Keren,_Eritrea#/media/File:Keren_-_2008-11-01.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Keren,_Eritrea',
        },
        landmarksRound: [
          { code: 'HM', number: 1 },
          { code: 'YT', number: 1 },
          { code: 'GY', number: 1 },
        ],
        landmarkPlaces: [
          'Erbil Citadel',
          'Mosta Rotunda',
          "St. Olaf's Church",
          'Joya De Ceren Archeological Site',
          'Gateway Arch',
          'Chamarel Waterfall',
          'Ostrog Monastery',
        ],
        uniqueId: 'JkqwwsKF',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Eritrea',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/slackadventure/2494677970/',
          wikiLink: 'https://en.wikipedia.org/wiki/Eritrea',
        },
        landmark: {
          name: 'Fiat Tagliero Building',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/gurui/4362915599/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiat_Tagliero_Building',
          hasLandmark: true,
        },
        city: {
          name: 'Massawa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/tjiama/233787278/',
          wikiLink: 'https://en.wikipedia.org/wiki/Massawa',
        },
        landmarksRound: [
          { code: 'MU', number: 2 },
          { code: 'RO', number: 2 },
          { code: 'RU', number: 1 },
        ],
        landmarkPlaces: [
          'Marina Bay Sands',
          'Monumento al Divino Salvador del Mundo',
          'Ten Thousand Buddhas Monastery',
          'Manovo-Gounda St. Floris National Park',
          'Valle de la Luna',
          'Virgin of El Panecillo',
          'Victoria Falls',
        ],
        uniqueId: 'wtfpxMQJ',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Eritrea',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/slackadventure/2494674744/',
          wikiLink: 'https://en.wikipedia.org/wiki/Eritrea',
        },
        landmark: {
          name: 'Church of Our Lady of the Rosary',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/8351474779/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Church_of_Our_Lady_of_the_Rosary,_Asmara',
          hasLandmark: true,
        },
        city: {
          name: 'Asmara',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/qatarairways/14718573450/',
          wikiLink: 'https://ro.wikipedia.org/wiki/Asmara',
        },
        landmarksRound: [
          { code: 'MK', number: 2 },
          { code: 'CI', number: 1 },
          { code: 'IL', number: 4 },
        ],
        landmarkPlaces: [
          'Vaduz Castle',
          'Plitvice Lakes National Park',
          'Kagera waterfalls',
          'Cité de Carcassonne',
          'Múlafossur Waterfall',
          'George Washington House',
          'Villers Abbey',
        ],
        uniqueId: 'pFzYgXQV',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Estonia',
    code: 'EE',
    capital: 'Tallinn',
    lat: 59,
    long: 26,
    cities: ['Tartu', 'Tallinn', 'Narva', 'Haapsalu', 'Parnu', 'Viljandi'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/2OSEWkQHiGI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn_Old_Town',
        },
        landmark: {
          name: "St. Olaf's Church",
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Tallinn,_St._Olaf%27s_Church.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Olaf%27s_Church,_Tallinn',
          hasLandmark: true,
        },
        city: {
          name: 'Tartu',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/river-city-cityscape-estonia-tartu-7764765/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tartu',
        },
        landmarksRound: [
          { code: 'GS', number: 1 },
          { code: 'LY', number: 2 },
          { code: 'PE', number: 2 },
        ],
        landmarkPlaces: [
          'Annandale Waterfall',
          'Saint Sava Temple',
          'Chorsu Bazaar',
          'Blue Grotto',
          'Acropolis',
          'Tokyo Skytree',
          'Mulafossur Waterfall',
        ],
        uniqueId: '6glKjrwF',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/estonia-tallinn-roofs-architecture-912315/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn',
        },
        landmark: {
          name: 'Alexander Nevsky Cathedral',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/estonia-tallinn-3731467/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Alexander_Nevsky_Cathedral,_Tallinn',
          hasLandmark: true,
        },
        city: {
          name: 'Tallinn',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/estonia-tallinn-historic-center-3737128/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn',
        },
        landmarksRound: [
          { code: 'SY', number: 2 },
          { code: 'ER', number: 2 },
          { code: 'CW', number: 2 },
        ],
        landmarkPlaces: [
          'Al Fateh Grand Mosque',
          'Tian Tan Buddha',
          'Wat Xiengthong',
          'Umayyad Mosque',
          'English Harbour',
          'Gyeongbukgong Palace',
          "Ruins of St. Paul's",
        ],
        uniqueId: 'VHT27WRH',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kaberneeme',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-body-of-water-during-daytime-xgNDOT9F2l8',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaberneeme',
        },
        landmark: {
          name: 'Viru Gate',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/137294100@N08/45758703212',
          wikiLink: 'https://en.wikipedia.org/wiki/Walls_of_Tallinn',
          hasLandmark: true,
        },
        city: {
          name: 'Tallinn',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-city-building-NyIBNMueUQI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn',
        },
        landmarksRound: [
          { code: 'EG', number: 4 },
          { code: 'JO', number: 2 },
          { code: 'CI', number: 2 },
        ],
        landmarkPlaces: [
          "St. Peter's Church",
          'House of the Black Heads',
          'Independence Monument',
          'Belvedere Lookout',
          "Memorial de l'Anse",
          'Islamic Center',
          'Fasil Ghebbi',
        ],
        uniqueId: 'MYzPNsFr',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tallinn',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-near-brown-concrete-building-during-nighttime-Xy5yA42GHvQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn',
        },
        landmark: {
          name: 'Tallinn Town Hall',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jlascar/23251761200',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn_Town_Hall',
          hasLandmark: true,
        },
        city: {
          name: 'Tartu',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/empty-road-06O0-VoNEj4',
          wikiLink: 'https://en.wikipedia.org/wiki/Tartu',
        },
        landmarksRound: [
          { code: 'GL', number: 2 },
          { code: 'KE', number: 1 },
          { code: 'IM', number: 2 },
        ],
        landmarkPlaces: [
          'Karnak',
          'Great Mosque of Asmara',
          "Saint Michael's Cave",
          'The Little Chapel',
          'Motherland Monument',
          'Milwane Wildlife Sanctuary',
          'Kunta Kinteh Island',
        ],
        uniqueId: 'W6TRlvrt',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Tallinn Old Town',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-narrow-cobblestone-street-in-an-old-european-city-twzONVjsWbw',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn_Old_Town',
        },
        landmark: {
          name: 'Kadriorg Palace',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-and-white-concrete-building-near-brown-trees-during-daytime-5ArCvQhlvb4',
          wikiLink: 'https://en.wikipedia.org/wiki/Kadriorg_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Tallinn',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-at-sunset-from-a-high-rise-0mR6KB4eDqM',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn',
        },
        landmarksRound: [
          { code: 'MC', number: 2 },
          { code: 'GY', number: 2 },
          { code: 'BO', number: 5 },
        ],
        landmarkPlaces: [
          'Mount Alava',
          'Basilica of Our Lady of the Angels, Cartago',
          'La Moneda Palace',
          'Turku Castle',
          'Temple of Literature',
          'Cedars of God',
          'Stone House',
        ],
        uniqueId: 'Ll9P6sCR',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Estonia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/purple-flower-field-near-road-during-daytime-JDAS7shUy-Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Tallinn',
        },
        landmark: {
          name: 'Linnahall',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/pegpilot/9930868423/',
          wikiLink: 'https://en.wikipedia.org/wiki/Linnahall',
          hasLandmark: true,
        },
        city: {
          name: 'Tartu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bridge-over-a-body-of-water-with-a-building-in-the-background-so8uO4LBvVk',
          wikiLink: 'https://en.wikipedia.org/wiki/Tartu',
        },
        landmarksRound: [
          { code: 'ME', number: 4 },
          { code: 'NG', number: 2 },
          { code: 'SI', number: 3 },
        ],
        landmarkPlaces: [
          'Múlafossur Waterfall',
          'Victoria Falls Bridge',
          'Kastelholm Castle',
          "Christian's Cave",
          'Fort Charlotte',
          'Monastery of St. Naum',
          'Sao Filipe Royale Fortress',
        ],
        uniqueId: 'kBVwJbGD',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Eswatini',
    code: 'SZ',
    capital: 'Lobamba',
    lat: -26.5,
    long: 31.5,
    cities: [
      'Siteki',
      'Nhlangano',
      'Mbabane',
      'Manzini',
      'Big Bend',
      'Malkerns',
      'Lobamba',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/swaziland-africa-village-swazi-2035749/',
          wikiLink: 'https://en.wikipedia.org/wiki/Eswatini',
        },
        landmark: {
          name: 'Sibebe Rock',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sibebe#/media/File:Granite_monolith.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sibebe',
          hasLandmark: true,
        },
        city: {
          name: 'Manzini',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Manzini,_Eswatini#/media/File:SZ-manzini-zufahrt.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Manzini,_Eswatini',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'l5TX9zwZ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:Central_Bank_of_Eswatini.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Central_Bank_of_Eswatini',
        },
        landmark: {
          name: 'Milwane Wildlife Sanctuary',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mlilwane_Wildlife_Sanctuary#/media/File:Zabras_in_Mlilwane_Wildlife_Sanctuary.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mlilwane_Wildlife_Sanctuary',
          hasLandmark: true,
        },
        city: {
          name: 'Mbabane',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-mbabane-landscape-town-3862466/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mbabane',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'tMdYMcZT',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Mbabane',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-a-large-building-_-qgwT7a2WM',
          wikiLink: 'https://en.wikipedia.org/wiki/Mbabane',
        },
        landmark: {
          name: 'Malolotja National Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Malolotja_National_Park#/media/File:Matumi_imported_from_iNaturalist_photo_63998588.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Malolotja_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Mbabane',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-a-large-building-_-qgwT7a2WM',
          wikiLink: 'https://en.wikipedia.org/wiki/Mbabane',
        },
        landmarksRound: [
          { code: 'VE', number: 1 },
          { code: 'YE', number: 2 },
          { code: 'MO', number: 2 },
        ],
        landmarkPlaces: [
          'Cape Coast Castle',
          'Patuxay Monument',
          "St. Peter's Church",
          'Plateau Mosque',
          'Azadi Tower',
          'Ruhyyet Palace',
          'Torre de la Libertad',
        ],
        uniqueId: '7WYqrHG3',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Mlilwane Wildlife Sanctuary',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-greenhouses-in-a-field-p3xW6mqxhzs',
          wikiLink: 'https://en.wikipedia.org/wiki/Mlilwane_Wildlife_Sanctuary',
        },
        landmark: {
          name: 'Mlilwane Wildlife Sanctuary',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mlilwane_Wildlife_Sanctuary#/media/File:Zabras_in_Mlilwane_Wildlife_Sanctuary.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mlilwane_Wildlife_Sanctuary',
          hasLandmark: true,
        },
        city: {
          name: 'Manzini',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Manzini,_Eswatini#/media/File:SZ-manzini-zufahrt.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Manzini',
        },
        landmarksRound: [
          { code: 'PN', number: 1 },
          { code: 'GY', number: 2 },
          { code: 'VI', number: 2 },
        ],
        landmarkPlaces: [
          'Cliffs of Moher',
          'Bled Castle',
          "Prince's Palace of Monaco",
          'Iluissat Icefjord',
          'Sistine Chapel',
          'Tallinn Town Hall',
          "Devil's Island",
        ],
        uniqueId: 'Ghl6DbWz',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Eswatini',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lone-tree-in-a-field-with-mountains-in-the-background-tjzLXXCpxdw',
          wikiLink: 'https://en.wikipedia.org/wiki/Eswatini',
        },
        landmark: {
          name: 'Mlilwane Wildlife Sanctuary',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/gjofili/33326792513/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mlilwane_Wildlife_Sanctuary',
          hasLandmark: true,
        },
        city: {
          name: 'Mbabane',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-a-blue-sky-V3on26KENgo',
          wikiLink: 'https://en.wikipedia.org/wiki/Mbabane',
        },
        landmarksRound: [
          { code: 'PW', number: 1 },
          { code: 'LU', number: 3 },
          { code: 'AW', number: 2 },
        ],
        landmarkPlaces: [
          'The Baths',
          'Cintra Bay',
          'Boudhanath',
          'Walzin Castle ',
          'Jal Mahal',
          'Atlas Cove',
          'Winter Palace',
        ],
        uniqueId: 'dTgtftTq',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Eswatini',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-dirt-road-with-a-hill-in-the-background-7UElMY_jVKE',
          wikiLink: 'https://en.wikipedia.org/wiki/Eswatini',
        },
        landmark: {
          name: 'Hlane Royal National Park',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/talesfromthemagiccarpet/8024504921/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hlane_Royal_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Manzini',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/piggspeak/4913654686/',
          wikiLink: 'https://en.wikipedia.org/wiki/Manzini',
        },
        landmarksRound: [
          { code: 'GW', number: 3 },
          { code: 'GH', number: 4 },
          { code: 'VI', number: 4 },
        ],
        landmarkPlaces: [
          'Irazú Volcano',
          'Little Bay',
          'Näsinneula',
          'Al Alam Palace',
          "St. Peter's Church",
          'Big Ben',
          'La Moneda Palace',
        ],
        uniqueId: 'J4DWn4Dd',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    capital: 'Addis Ababa',
    lat: 8,
    long: 38,
    cities: [
      'Dire Dawa',
      'Addis Ababa',
      'Gondar',
      'Adama',
      'Bahir Dar',
      'Hawassa',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/TgaK_57wRWU',
          wikiLink: 'https://en.wikipedia.org/wiki/Danakil_Desert',
        },
        landmark: {
          name: 'Fasil Ghebbi',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Fasilides_Palace_02.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fasil_Ghebbi',
          hasLandmark: true,
        },
        city: {
          name: 'Dire Dawa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dire_Dawa#/media/File:ET-Dire_Dawa,_Ethiopia_(13).JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Dire_Dawa',
        },
        landmarksRound: [
          { code: 'TJ', number: 1 },
          { code: 'SG', number: 1 },
          { code: 'AG', number: 1 },
        ],
        landmarkPlaces: [
          'Charles Bridge',
          'Ocean Park',
          'Temple of Literature',
          'Turku Castle',
          'Roman theatre',
          'Mir Castle',
          'Bratislava Castle',
        ],
        uniqueId: 'mmd9kktk',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tricycle-road-hills-wet-road-5700034/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ethiopia',
        },
        landmark: {
          name: 'Obelisk of Axum',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Obelisk_of_Axum#/media/File:Rome_Stele.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Obelisk_of_Axum',
          hasLandmark: true,
        },
        city: {
          name: 'Addis Ababa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Addis_Ababa#/media/File:Addis_Ababa_Skyline_2023.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Addis_Ababa',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'zJpVv4dq',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Ethiopia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-people-standing-on-top-of-hill-E0gtyLcD4NE',
          wikiLink: 'https://en.wikipedia.org/wiki/Ethiopia',
        },
        landmark: {
          name: 'Abuna Yemata Guh',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/9478402@N03/13260954495',
          wikiLink: 'https://en.wikipedia.org/wiki/Abuna_Yemata_Guh',
          hasLandmark: true,
        },
        city: {
          name: 'Addis Ababa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/buildings-during-night-time-MUcM3GCCQPk',
          wikiLink: 'https://en.wikipedia.org/wiki/Addis_Ababa',
        },
        landmarksRound: [
          { code: 'DJ', number: 4 },
          { code: 'IT', number: 2 },
          { code: 'KI', number: 1 },
        ],
        landmarkPlaces: [
          'Sistine Chapel',
          'Chamarel Waterfall',
          'Saunders Island',
          'Chorsu Bazaar',
          'Vianden Castle',
          'Tazumal',
          'Stone House',
        ],
        uniqueId: 'lljtgy7T',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Lalibela',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-in-white-dress-in-front-of-pink-building-6SgfEwkA02Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Lalibela',
        },
        landmark: {
          name: 'Fasil Ghebbi',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fasil_Ghebbi#/media/File:Fasilides_Palace_01.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fasil_Ghebbi',
          hasLandmark: true,
        },
        city: {
          name: 'Dire Dawa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dire_Dawa#/media/File:Dire_Dawa_Station_(cropped).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dire_Dawa',
        },
        landmarksRound: [
          { code: 'KG', number: 1 },
          { code: 'ES', number: 2 },
          { code: 'SV', number: 2 },
        ],
        landmarkPlaces: [
          'Mount Kenya',
          'Laferriere Citadel',
          "Devil's Island",
          'Mumbo Island',
          'Temple of Literature',
          'Champagne Reef, Dominica',
          'Soroca Fort',
        ],
        uniqueId: 'fr3BzpMT',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Ethiopia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-herd-of-cattle-walking-down-a-dirt-road-F07E5ak-Im8',
          wikiLink: 'https://en.wikipedia.org/wiki/Ethiopia',
        },
        landmark: {
          name: 'Fasil Ghebbi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-near-green-trees-during-daytime-vSpuHmEGxcI',
          wikiLink: 'https://en.wikipedia.org/wiki/Fasil_Ghebbi',
          hasLandmark: true,
        },
        city: {
          name: 'Addis Ababa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-down-a-street-next-to-tall-buildings-KIWZefyQG3g',
          wikiLink: 'https://en.wikipedia.org/wiki/Addis_Ababa',
        },
        landmarksRound: [
          { code: 'BI', number: 1 },
          { code: 'GY', number: 4 },
          { code: 'RW', number: 1 },
        ],
        landmarkPlaces: [
          'We Are Our Mountains',
          'Kokoda Track',
          'Bahrain Fort',
          'Grand Turk Lighthouse',
          'Cape of Good Hope',
          'Anse Lazio',
          'Castillo De Los Tres Reyes Del Morro',
        ],
        uniqueId: 'XrsQlKdr',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Ethiopia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/a-city-at-night-hbRzMp8jl6c',
          wikiLink: 'https://en.wikipedia.org/wiki/Ethiopia',
        },
        landmark: {
          name: 'Erta Ale',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-with-red-flame-Rj21YHC1CIY',
          wikiLink: 'https://en.wikipedia.org/wiki/Erta_Ale',
          hasLandmark: true,
        },
        city: {
          name: 'Bahir Dar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-trees-and-building-during-daytime-a-qwp5gwUzc',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahir_Dar',
        },
        landmarksRound: [
          { code: 'AQ', number: 6 },
          { code: 'AF', number: 4 },
          { code: 'MT', number: 1 },
        ],
        landmarkPlaces: [
          'Atomium',
          'Notre Dame Auxiliatrice',
          'National Museum of Bermuda',
          'Natural Pool',
          'Souq Waqif',
          'House of Slaves',
          'Arch 22',
        ],
        uniqueId: 'zrMVl2Bw',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Falkland Islands',
    code: 'FK',
    capital: 'Stanley',
    lat: -51.75,
    long: -59,
    cities: [
      'Goose Green',
      'Ajax Bay',
      'Stanley',
      'Port San Carlos',
      'Port Howard',
      'Hill Cove',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/KMLTYWDi0GA',
          wikiLink: 'https://en.wikipedia.org/wiki/Rockhopper_penguin',
        },
        landmark: {
          name: 'Saunders Island',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Saunders_Island,_Falkland_Islands#/media/File:Necksaundersisland2.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saunders_Island,_Falkland_Islands',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '4BmPQsgS',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cruise-falkland-islands-voyage-4709457/',
          wikiLink: 'https://en.wikipedia.org/wiki/Falkland_Islands',
        },
        landmark: {
          name: 'Christ Church Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Christ_Church_Cathedral_(Falkland_Islands)#/media/File:FAL-2016-Stanley,_Falkland_Islands%E2%80%93Christ_Church_Cathedral.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Christ_Church_Cathedral_(Falkland_Islands)',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'MZ', number: 1 },
          { code: 'CN', number: 2 },
          { code: 'SY', number: 2 },
        ],
        landmarkPlaces: [
          'Magnus Cathedral',
          'Noravank Monastery',
          'Lukan Longshan Temple',
          'Bratislava Castle',
          'Arc de Triomphe',
          'Narikala Fortress',
          'Trevi Fountain',
        ],
        uniqueId: 'BgGYK5Cd',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Falkland Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ocean-waves-UMC5sfWci78',
          wikiLink: 'https://en.wikipedia.org/wiki/Falkland_Islands',
        },
        landmark: {
          name: 'Christ Church Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Christ_Church_Cathedral_(Falkland_Islands)#/media/File:FAL-2016-Stanley,_Falkland_Islands%E2%80%93Christ_Church_Cathedral.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Christ_Church_Cathedral_(Falkland_Islands)',
          hasLandmark: true,
        },
        city: {
          name: 'Stanley',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Stanley,_Falkland_Islands#/media/File:Aerial_photo_Port_Stanley.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Stanley,_Falkland_Islands',
        },
        landmarksRound: [
          { code: 'MO', number: 2 },
          { code: 'MR', number: 1 },
          { code: 'MR', number: 2 },
        ],
        landmarkPlaces: [
          'The Marble Mountains',
          'Erbil Citadel',
          'Bled Castle',
          'Iluissat Icefjord',
          'Mont Choungui',
          'Vaduz Castle',
          'Tian Tan Buddha',
        ],
        uniqueId: 'YrNVd9PS',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Falkland Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-daytime-zNmv3qkEtcE',
          wikiLink: 'https://en.wikipedia.org/wiki/Falkland_Islands',
        },
        landmark: {
          name: 'Argentine Military Cemetery',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Argentine_Military_Cemetery#/media/File:Cementerio_Argentino_de_Puerto_Darwin.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Argentine_Military_Cemetery',
          hasLandmark: true,
        },
        city: {
          name: 'Stanley',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Stanley,_Falkland_Islands#/media/File:Falkland-Islands-Company.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Stanley,_Falkland_Islands',
        },
        landmarksRound: [
          { code: 'YE', number: 1 },
          { code: 'EH', number: 2 },
          { code: 'MG', number: 1 },
        ],
        landmarkPlaces: [
          'Lake Armor',
          'Blue Lagoon',
          'Tazumal',
          'Golden Gate Bridge',
          'Chishimba Falls',
          'Pico Basile',
          'Rock of Cashel',
        ],
        uniqueId: 'TPBvxxq2',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Falkland Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-field-with-houses-and-a-body-of-water-in-the-distance-BWq-vP2D_FY',
          wikiLink: 'https://en.wikipedia.org/wiki/Falkland_Islands',
        },
        landmark: {
          name: 'Argentine Military Cemetery',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/intrepidexplorer82/51060654971/',
          wikiLink: 'https://en.wikipedia.org/wiki/Argentine_Military_Cemetery',
          hasLandmark: true,
        },
        city: {
          name: 'Stanley',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/31155442@N03/31374642481/',
          wikiLink: 'https://en.wikipedia.org/wiki/Stanley,_Falkland_Islands',
        },
        landmarksRound: [
          { code: 'SV', number: 1 },
          { code: 'DM', number: 1 },
          { code: 'SB', number: 2 },
        ],
        landmarkPlaces: [
          'Cacheu Fort',
          'Palacio Salvo',
          'Ten Thousand Buddhas Monastery',
          'Ribat of Sousse',
          'Frederiksborg Castle',
          'Cologne Cathedral',
          'Kittiwake Shipwreck',
        ],
        uniqueId: 'LmFdP3Ng',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Falkland Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-the-ocean-from-a-rocky-cliff-vmJwkT_YJ4w',
          wikiLink: 'https://en.wikipedia.org/wiki/Falkland_Islands',
        },
        landmark: {
          name: 'Christ Church Cathedral ',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/blueheronco/12161315416/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Christ_Church_Cathedral_(Falkland_Islands)',
          hasLandmark: true,
        },
        city: {
          name: 'Stanley',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/pegpilot/31426307552/',
          wikiLink: 'https://en.wikipedia.org/wiki/Stanley,_Falkland_Islands',
        },
        landmarksRound: [
          { code: 'CI', number: 4 },
          { code: 'KG', number: 2 },
          { code: 'ER', number: 1 },
        ],
        landmarkPlaces: [
          'Villers Abbey',
          'Virgin of El Panecillo',
          'Boudhanath',
          'Chomoni Beach',
          'Kittiwake Shipwreck',
          'Sultan Abdul Samad Building',
          'Apia Town Clock Tower',
        ],
        uniqueId: 'd4lTQzbN',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    capital: 'Torshavn',
    lat: 62,
    long: -7,
    cities: [
      'Torshavn',
      'Hoyvik',
      'Klaksvik',
      'Sandavagur',
      'Tórshavn',
      'Klaksvík',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NrP9I1utqug',
          wikiLink: 'https://en.wikipedia.org/wiki/G%C3%A1sadalur',
        },
        landmark: {
          name: 'Magnus Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Magnus_Cathedral#/media/File:Kirkjub%C3%B8ur,_Faroe_Islands.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Magnus_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Hoyvik',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Hoyv%C3%ADk#/media/File:Faroe_Islands,_Streymoy,_T%C3%B3rshavn_(3).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hoyv%C3%ADk',
        },
        landmarksRound: [
          { code: 'AF', number: 2 },
          { code: 'UY', number: 1 },
          { code: 'YE', number: 1 },
        ],
        landmarkPlaces: [
          'Villa Folio',
          'Mount Kenya',
          'Hagia Sophia',
          'Mount Yasur',
          'The Grand Palace',
          'Amalienborg',
          'Liwonde National Park',
        ],
        uniqueId: 'G74GrBSc',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sea-ocean-rocks-faroe-islands-7233669/',
          wikiLink: 'https://en.wikipedia.org/wiki/Faroe_Islands',
        },
        landmark: {
          name: 'Mulafossur Waterfall',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mulafossur-g%c3%a1sadalur-gasadalur-3632137/',
          wikiLink: 'https://en.wikipedia.org/wiki/Faroe_Islands',
          hasLandmark: true,
        },
        city: {
          name: 'Torshavn',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:Tinganes_57.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/T%C3%B3rshavn',
        },
        landmarksRound: [
          { code: 'DK', number: 2 },
          { code: 'AQ', number: 1 },
          { code: 'PA', number: 1 },
        ],
        landmarkPlaces: [
          'Petronas Towers',
          'Skopje Fortress',
          'Senso-ji',
          'Brandenburg Gate',
          'Ostrog Monastery',
          'Palace of Versailles',
          'Tsingy De Bemaraha',
        ],
        uniqueId: 'LKy2NT5R',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Mykines',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/lighthouse-near-body-of-water-Iay5RmQZo8Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Mykines,_Faroe_Islands',
        },
        landmark: {
          name: 'Múlafossur Waterfall',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/132939648@N06/49080010627',
          wikiLink: 'https://www.wikidata.org/wiki/Q56322370',
          hasLandmark: true,
        },
        city: {
          name: 'Tórshavn',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-houses-near-body-of-water-during-sunset-ifq2QdEVLro',
          wikiLink: 'https://en.wikipedia.org/wiki/T%C3%B3rshavn',
        },
        landmarksRound: [
          { code: 'DO', number: 3 },
          { code: 'ES', number: 2 },
          { code: 'HM', number: 2 },
        ],
        landmarkPlaces: [
          'Cintra Bay',
          "St. Peter's Church",
          'Independence Monument',
          'Tallinn Town Hall',
          "Dunn's River Falls",
          'Mount Yasur',
          'Hagia Sophia',
        ],
        uniqueId: 'VZSzJW5h',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Faroe Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-house-near-green-mountain-during-daytime-nHUWlHLMLZ8',
          wikiLink: 'https://en.wikipedia.org/wiki/Faroe_Islands',
        },
        landmark: {
          name: 'Kallur Lighthouse',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/100629421@N07/51704877282',
          wikiLink: 'https://www.wikidata.org/wiki/Q28374928',
          hasLandmark: true,
        },
        city: {
          name: 'Klaksvík',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-black-concrete-building-on-grass-field-mA_pzTEvFyM',
          wikiLink: 'https://en.wikipedia.org/wiki/Klaksv%C3%ADk',
        },
        landmarksRound: [
          { code: 'SV', number: 2 },
          { code: 'JM', number: 1 },
          { code: 'IQ', number: 1 },
        ],
        landmarkPlaces: [
          'Golden Gate Bridge',
          'Rock of Cashel',
          'Grand Ducal Palace',
          'Alcázar de Colón',
          'Azadi Tower',
          'Kiritimati',
          'Petronas Towers',
        ],
        uniqueId: 'wHgLQkmb',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Faroe Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/calm-body-of-water-and-mountain-1UdiEckjJmM',
          wikiLink: 'https://en.wikipedia.org/wiki/Faroe_Islands',
        },
        landmark: {
          name: 'Skansin',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/130467353@N06/30668928092/',
          wikiLink: 'https://en.wikipedia.org/wiki/Skansin',
          hasLandmark: true,
        },
        city: {
          name: 'Klaksvík',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-mountains-and-body-of-water-LGnt79hVSck',
          wikiLink: 'https://en.wikipedia.org/wiki/Klaksv%C3%ADk',
        },
        landmarksRound: [
          { code: 'BF', number: 4 },
          { code: 'PG', number: 2 },
          { code: 'ID', number: 3 },
        ],
        landmarkPlaces: [
          "Women's Bridge",
          'Sans-Souci Palace',
          'Mir Castle',
          'Sandy Island',
          'Luis I Bridge',
          'California Lighthouse',
          'Ta Prohm ',
        ],
        uniqueId: 'ZyT94GCB',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Gjogv',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/top-view-photography-of-village-UH0-PfiCIaE',
          wikiLink: 'https://en.wikipedia.org/wiki/Gj%C3%B3gv',
        },
        landmark: {
          name: 'Christianskirkjan',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/nordoy/2924245143/',
          wikiLink: 'https://en.wikipedia.org/wiki/Christianskirkjan',
          hasLandmark: true,
        },
        city: {
          name: 'Tórshavn',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-houses-near-body-of-water-during-sunset-ifq2QdEVLro',
          wikiLink: 'https://en.wikipedia.org/wiki/T%C3%B3rshavn',
        },
        landmarksRound: [
          { code: 'IL', number: 3 },
          { code: 'VN', number: 4 },
          { code: 'AL', number: 1 },
        ],
        landmarkPlaces: [
          'Patuxay Monument',
          'Copán',
          'Old Dongola',
          'Fort Shirley',
          'Xunatunich Mayan Ruins',
          'Milwane Wildlife Sanctuary',
          'Soufriere Hills',
        ],
        uniqueId: 'cHTv3Hbn',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Fiji',
    code: 'FJ',
    capital: 'Suva',
    lat: -18,
    long: 175,
    cities: ['Suva', 'Labasa', 'Ba', 'Lautoka', 'Levuka', 'Nadi'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Gg2VVz2ycAc',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuata',
        },
        landmark: {
          name: 'Tavarua',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tavarua#/media/File:Tavarua_Island,_Fiji.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Tavarua',
          hasLandmark: true,
        },
        city: {
          name: 'Nadi',
          photographer: '',
          imageLink: 'https://en.wikipedia.org/wiki/Nadi#/media/File:Nadi.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nadi',
        },
        landmarksRound: [
          { code: 'CL', number: 1 },
          { code: 'SD', number: 2 },
          { code: 'CO', number: 1 },
        ],
        landmarkPlaces: [
          'Tokyo Skytree',
          'Grytviken Church',
          'Amberd',
          "Queen's Staircase",
          'Monte Carlo Casino',
          'Atomium',
          'Cape Coast Castle',
        ],
        uniqueId: 'bXnnLn6J',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fiji-south-seas-vacations-island-3839354/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiji',
        },
        landmark: {
          name: 'Qalito Island',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:Castaway.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Castaway_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Suva',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Suva#/media/File:Suva,_Fiji_76.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Suva',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '4Wk9y4nL',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kuata',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-boat-on-ocean-Gg2VVz2ycAc',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuata',
        },
        landmark: {
          name: 'Colo-i-Suva Forest Reserve',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/31379287@N00/387128566',
          wikiLink: 'https://en.wikipedia.org/wiki/Colo-i-Suva_Forest_Reserve',
          hasLandmark: true,
        },
        city: {
          name: 'Suva',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Suva#/media/File:Suva,_Fiji_77.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Suva',
        },
        landmarksRound: [
          { code: 'EH', number: 2 },
          { code: 'GU', number: 1 },
          { code: 'KW', number: 2 },
        ],
        landmarkPlaces: [
          'Cape Coast Castle',
          'Gateway Arch',
          'Fasil Ghebbi',
          'Kasubi Royal Tombs',
          'Rock of Cashel',
          'Fort Nuestra Senora de la Soledad',
          'Mutanabbi Statue',
        ],
        uniqueId: 'hfx9qfb4',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Fiji',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-houses-and-trees-MJ7yhD3kz7o',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiji',
        },
        landmark: {
          name: 'Sri Siva Subramaniya Temple',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/yaranaika/19081373481',
          wikiLink: 'https://en.wikipedia.org/wiki/Sri_Siva_Subramaniya_Temple',
          hasLandmark: true,
        },
        city: {
          name: 'Nadi',
          photographer: '',
          imageLink: 'https://en.wikipedia.org/wiki/Nadi#/media/File:Nadi.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nadi',
        },
        landmarksRound: [
          { code: 'KE', number: 2 },
          { code: 'GU', number: 1 },
          { code: 'YT', number: 1 },
        ],
        landmarkPlaces: [
          'Monument to the Heroes of the Restoration',
          'Monte Carlo Casino',
          'Church of Our Lady of the Rosary',
          'Mosta Rotunda',
          'Annaberg Sugar Plantation',
          'Mount Kenya',
          'Sistine Chapel',
        ],
        uniqueId: 'DXmpVcqW',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Fiji',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-house-on-a-rocky-island-hoq8NaDGS64',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiji',
        },
        landmark: {
          name: 'Sri Siva Subramaniya Temple',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tall-tower-with-a-clock-on-top-of-it-PdtI70j0f_Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Sri_Siva_Subramaniya_Temple',
          hasLandmark: true,
        },
        city: {
          name: 'Suva',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-boats-on-calm-body-of-water-during-sunset-MhILndAJlw8',
          wikiLink: 'https://en.wikipedia.org/wiki/Suva',
        },
        landmarksRound: [
          { code: 'MV', number: 3 },
          { code: 'AL', number: 1 },
          { code: 'CO', number: 5 },
        ],
        landmarkPlaces: [
          'Pedra da Gávea',
          'Basilique Marie-Reine-des-Apôtres',
          'Pipeline Mosque',
          'Seven Rile Lakes',
          'Chutes de Boali',
          "Saint Michael's Cave",
          'Tadrart Acacus',
        ],
        uniqueId: 'GCPPrML9',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Fiji',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-view-of-island-b8RQN7Wtu34',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiji',
        },
        landmark: {
          name: 'Fiji Museum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/192409751@N04/51014437961/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fiji_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Nadi',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/19272853@N00/37750700611/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nadi',
        },
        landmarksRound: [
          { code: 'TR', number: 4 },
          { code: 'AM', number: 1 },
          { code: 'GY', number: 2 },
        ],
        landmarkPlaces: [
          "Devil's Throat",
          'Umayyad Mosque',
          'Rotunda of Mosta',
          'Burj Khalifa',
          'Berat Castle',
          'Castillo San Felipe de Barajas',
          'Golubac Fortress',
        ],
        uniqueId: 'Fx9tfdl9',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Finland',
    code: 'FI',
    capital: 'Helsinki',
    lat: 64,
    long: 26,
    cities: ['Tampere', 'Helsinki', 'Turku', 'Espoo', 'Oulu', 'Kuopio'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/I9SWvZ9sO2U',
          wikiLink: 'https://en.wikipedia.org/wiki/Aleksanterinkatu',
        },
        landmark: {
          name: 'Turku Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/architecture-old-building-gothic-3316484/',
          wikiLink: 'https://en.wikipedia.org/wiki/Turku_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Tampere',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/night-dark-city-the-dark-sky-winter-2126404/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tampere',
        },
        landmarksRound: [
          { code: 'AU', number: 1 },
          { code: 'SK', number: 1 },
          { code: 'TM', number: 1 },
        ],
        landmarkPlaces: [
          'Island of Mozambique',
          'Kittiwake Shipwreck',
          'Santa Catalina Arch',
          'Deception Island',
          'Cliffs of Moher',
          'Tomb of Hafez',
          'Angel Falls',
        ],
        uniqueId: 'myF2CXDV',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lake-forest-nature-landscape-water-5045059/',
          wikiLink: 'https://en.wikipedia.org/wiki/Finland',
        },
        landmark: {
          name: 'Olavinlinna',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/castle-olavinlinna-savonlinna-6940332/',
          wikiLink: 'https://en.wikipedia.org/wiki/Olavinlinna',
          hasLandmark: true,
        },
        city: {
          name: 'Helsinki',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/helsinki-cathedral-cathedral-church-4189824/',
          wikiLink: 'https://en.wikipedia.org/wiki/Helsinki',
        },
        landmarksRound: [
          { code: 'CW', number: 1 },
          { code: 'MU', number: 1 },
          { code: 'TW', number: 2 },
        ],
        landmarkPlaces: [
          'Timgad',
          'Somapura Mahavihara',
          'Ampitheater of El Jem',
          'Stone House',
          'Elmina Castle',
          'Christiansborg Palace',
          'Deadvlei',
        ],
        uniqueId: '65sfmwGC',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Nuuksio',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-and-white-canoe-on-lake-near-green-trees-under-white-clouds-during-daytime-DyDR8oOzuNA',
          wikiLink: 'https://en.wikipedia.org/wiki/Nuuksio',
        },
        landmark: {
          name: 'Näsinneula',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/N%C3%A4sinneula#/media/File:N%C3%A4sinneula_from_Lent%C3%A4v%C3%A4nniemi.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/N%C3%A4sinneula',
          hasLandmark: true,
        },
        city: {
          name: 'Helsinki',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-street-at-night-covered-with-snow-w6KSwwnQyaE',
          wikiLink: 'https://en.wikipedia.org/wiki/Helsinki',
        },
        landmarksRound: [
          { code: 'CY', number: 2 },
          { code: 'AQ', number: 1 },
          { code: 'AS', number: 3 },
        ],
        landmarkPlaces: [
          'Mount Cameroon',
          'Grandvalira',
          'Spittal Pond',
          'Stone House',
          'We Are Our Mountains',
          'Crystal Caves',
          'Living Museum of Bujumbura',
        ],
        uniqueId: 'qR7WTtpW',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Jyväskylä',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/sunset-painting-1nBvotYiZQI',
          wikiLink: 'https://en.wikipedia.org/wiki/Jyv%C3%A4skyl%C3%A4',
        },
        landmark: {
          name: 'Temppeliaukio Church',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/31155442@N03/50025088833',
          wikiLink: 'https://en.wikipedia.org/wiki/Temppeliaukio_Church',
          hasLandmark: true,
        },
        city: {
          name: 'Espoo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/super-highway-during-golden-hour-jmvzOHl_w7g',
          wikiLink: 'https://en.wikipedia.org/wiki/Espoo',
        },
        landmarksRound: [
          { code: 'AM', number: 4 },
          { code: 'AF', number: 3 },
          { code: 'BW', number: 4 },
        ],
        landmarkPlaces: [
          'Fort Montagu',
          'Belovezhskaya Pushcha National Park',
          'Emi Koussi',
          'Emi Koussi',
          'Crystal Caves',
          'Chutes de Boali',
          'Maiden Tower',
        ],
        uniqueId: 'JrD9V8jl',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Lapland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-tree-and-mountain-covered-by-snow-MRX-unYU1dc',
          wikiLink: 'https://en.wikipedia.org/wiki/Lapland_(Finland)',
        },
        landmark: {
          name: 'Helsinki Cathedral',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-mosque-near-body-of-water-bpDJvls-h-0',
          wikiLink: 'https://en.wikipedia.org/wiki/Helsinki_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Helsinki',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-person-walking-across-street-near-parked-cars-x02clw6vZLI',
          wikiLink: 'https://en.wikipedia.org/wiki/Helsinki',
        },
        landmarksRound: [
          { code: 'ST', number: 2 },
          { code: 'PY', number: 1 },
          { code: 'PA', number: 1 },
        ],
        landmarkPlaces: [
          'Lake Assal ',
          'Kasubi Royal Tombs',
          'White Bay Bar',
          'Sri Dalada Maligawa',
          'Xunatunich Mayan Ruins',
          'Cap 110',
          'Detwah Lagoon',
        ],
        uniqueId: 'GHmpx6CL',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Finland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-and-yellow-city-tram-I9SWvZ9sO2U',
          wikiLink: 'https://en.wikipedia.org/wiki/Finland',
        },
        landmark: {
          name: 'Olavinlinna',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/163906908@N03/52468602350/',
          wikiLink: 'https://en.wikipedia.org/wiki/Olavinlinna',
          hasLandmark: true,
        },
        city: {
          name: 'Tampere',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-red-boat-on-water-near-building-during-daytime-oXEfEJ-hE7Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Tampere',
        },
        landmarksRound: [
          { code: 'TO', number: 2 },
          { code: 'HT', number: 4 },
          { code: 'SZ', number: 6 },
        ],
        landmarkPlaces: [
          'Parque Colón',
          'One Foot Island',
          'Roman theatre',
          'Molinere Underwater Sculpture Park',
          'Maluti Mountains',
          'Palace of Versailles',
          'Azadi Tower',
        ],
        uniqueId: 'fP4mnz8z',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'France',
    code: 'FR',
    capital: 'Paris',
    lat: 46,
    long: 2,
    cities: [
      'Marseille',
      'Nice',
      'Paris',
      'Bordeaux',
      'Toulouse',
      'Lyon',
      'Arles',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ZtdNFSpugQE',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%89tretat',
        },
        landmark: {
          name: 'Arc de Triomphe',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/arc-de-triomphe-paris-landmark-5432392/',
          wikiLink: 'https://en.wikipedia.org/wiki/Arc_de_Triomphe',
          hasLandmark: true,
        },
        city: {
          name: 'Marseille',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/marseille-cathedral-the-major-1334093/',
          wikiLink: 'https://en.wikipedia.org/wiki/Marseille',
        },
        landmarksRound: [
          { code: 'JM', number: 1 },
          { code: 'MN', number: 1 },
          { code: 'MK', number: 2 },
        ],
        landmarkPlaces: [
          'Abu Simbel Temples',
          'Ocean Park',
          'Pigeon Rocks',
          'CN Tower',
          'Wat Arun',
          'Chinguetti',
          "Saint Michael's Cave",
        ],
        uniqueId: 'cl33yTgt',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vineyard-field-rural-burgundy-6399505/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burgundy',
        },
        landmark: {
          name: 'Palace of Versailles',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/castle-palace-fortress-building-6356704/',
          wikiLink: 'https://en.wikipedia.org/wiki/Palace_of_Versailles',
          hasLandmark: true,
        },
        city: {
          name: 'Nice',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/nice-city-sea-france-panorama-4646272/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nice',
        },
        landmarksRound: [
          { code: 'AL', number: 1 },
          { code: 'ET', number: 1 },
          { code: 'KZ', number: 2 },
        ],
        landmarkPlaces: [
          'Devin Castle',
          'Stone House',
          'Turku Castle',
          'Burj Khalifa',
          'Church of Our Savior',
          'Stonehenge',
          'Mostar Old Bridge',
        ],
        uniqueId: 'Lnl5JlcH',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Étretat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-mountain-ZtdNFSpugQE',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%89tretat',
        },
        landmark: {
          name: 'Cité de Carcassonne',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Cit%C3%A9_de_Carcassonne#/media/File:Carcassonne_-_Pont_Neuf_-_View_SSE_on_Old_Bridge_&_Citadel_-_Porte_d'Aude.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Cit%C3%A9_de_Carcassonne',
          hasLandmark: true,
        },
        city: {
          name: 'Paris',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-bridge-during-sunset-sp5PLUws3a0',
          wikiLink: 'https://en.wikipedia.org/wiki/Paris',
        },
        landmarksRound: [
          { code: 'ER', number: 3 },
          { code: 'DM', number: 3 },
          { code: 'UG', number: 2 },
        ],
        landmarkPlaces: [
          'Registan Square',
          'Dziani Dzaha',
          'Palace of Peace and Reconciliation',
          'Soufriere Hills',
          'Maluti Mountains',
          'Rock of Cashel',
          'Ducie Island',
        ],
        uniqueId: 'DYMftBWP',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Bonifacio',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-on-top-of-mountain-SYBLkjltGRo',
          wikiLink: 'https://fr.wikipedia.org/wiki/Bonifacio',
        },
        landmark: {
          name: 'Pont du Gard',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Pont_du_Gard#/media/File:Pont_du_Gard_BLS.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pont_du_Gard',
          hasLandmark: true,
        },
        city: {
          name: 'Marseille',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-boats-at-a-dock--jGQaEA3YC0',
          wikiLink: 'https://en.wikipedia.org/wiki/Marseille',
        },
        landmarksRound: [
          { code: 'NP', number: 2 },
          { code: 'DO', number: 3 },
          { code: 'SZ', number: 4 },
        ],
        landmarkPlaces: [
          'Sri Siva Subramaniya Temple',
          'Victoria Falls Bridge',
          'Predjama Castle',
          'Big Ben',
          "St. Tryphon's Cathedral",
          'Uganda National Mosque',
          'La Sagrada Familia',
        ],
        uniqueId: 'Q2j8RVPt',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Paris',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-bridge-timelapse-photography-mEfIhOTH27w',
          wikiLink: 'https://en.wikipedia.org/wiki/Paris',
        },
        landmark: {
          name: 'Château de Chambord',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-white-and-brown-castle-Yui3DZiX7yM',
          wikiLink: 'https://en.wikipedia.org/wiki/Ch%C3%A2teau_de_Chambord',
          hasLandmark: true,
        },
        city: {
          name: 'Nice',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-near-building-2rsK_rdiDJ8',
          wikiLink: 'https://en.wikipedia.org/wiki/Nice',
        },
        landmarksRound: [
          { code: 'IQ', number: 4 },
          { code: 'TC', number: 2 },
          { code: 'FK', number: 5 },
        ],
        landmarkPlaces: [
          'Champagne Beach',
          'Hiroshima Peace Memorial',
          'George Washington House',
          'Tsunami Monument',
          'Fort Beekenburg',
          'Olavinlinna',
          'Columbus Park',
        ],
        uniqueId: 'DzmsYj7B',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Chamonix',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/snowy-mountain-JVtcrWcbj1c',
          wikiLink: 'https://en.wikipedia.org/wiki/Chamonix',
        },
        landmark: {
          name: 'Sainte-Chapelle',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/gold-interior-K0Htzfvy2zI',
          wikiLink: 'https://en.wikipedia.org/wiki/Sainte-Chapelle',
          hasLandmark: true,
        },
        city: {
          name: 'Arles',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-blue-and-pink-painted-house-nF66LmjQclI',
          wikiLink: 'https://ro.wikipedia.org/wiki/Arles',
        },
        landmarksRound: [
          { code: 'AD', number: 6 },
          { code: 'MN', number: 1 },
          { code: 'KY', number: 1 },
        ],
        landmarkPlaces: [
          'Belovezhskaya Pushcha National Park',
          "Fisherman's Bastion",
          'Santa Catalina Arch',
          'Sans-Soucis Palace',
          'Temple of Garni',
          'Mutanabbi Statue',
          'Pico Basile',
        ],
        uniqueId: 'pgJSpHXb',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'French Guiana',
    code: 'GF',
    capital: 'Cayenne',
    lat: 4,
    long: -53,
    cities: ['Mana', 'Apatou', 'Saint-Laurent', 'Cayenne', 'Matoury', 'Kourou'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sea-shore-orla-cayenne-2060138/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayenne',
        },
        landmark: {
          name: "Devil's Island",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/amanderson/16118324033',
          wikiLink: 'https://en.wikipedia.org/wiki/Devil%27s_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Matoury',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Matoury#/media/File:Larivot.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Matoury',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'QDCDMm2y',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/french-guiana-rainforest-forest-1207208/',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Guiana',
        },
        landmark: {
          name: 'Fort Ceperou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort_C%C3%A9p%C3%A9rou#/media/File:Cayenne_Fort_C%C3%A9p%C3%A9rou_2013.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_C%C3%A9p%C3%A9rou',
          hasLandmark: true,
        },
        city: {
          name: 'Cayenne',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cayenne#/media/File:Cayenne_city_(8525272038).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayenne',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'p8sKFFFR',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cayenne',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/44584468@N05/6864847932',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Guiana',
        },
        landmark: {
          name: 'Fort Cépérou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort_C%C3%A9p%C3%A9rou#/media/File:Fort_C%C3%A9p%C3%A9rou_02.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_C%C3%A9p%C3%A9rou',
          hasLandmark: true,
        },
        city: {
          name: 'Saint-Laurent',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Saint-Laurent-du-Maroni#/media/File:Saint-Laurent-du-Maroni_(15789323958).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint-Laurent-du-Maroni',
        },
        landmarksRound: [
          { code: 'DE', number: 2 },
          { code: 'TM', number: 2 },
          { code: 'GB', number: 2 },
        ],
        landmarkPlaces: [
          'Monument to the Heroes of the Restoration',
          'The Basilica of Our Lady of Peace of Yamoussoukro',
          "Prince's Palace of Monaco",
          'Leptis Magna',
          'Rock of Gibraltar',
          'Liwonde National Park',
          'Soroca Fort',
        ],
        uniqueId: '4ChHyqPT',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'French Guiana',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/nasawebbtelescope/51584920253',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Guiana',
        },
        landmark: {
          name: 'Bagne des Annamites',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Camp_Crique_Anguille#/media/Fichier:Camp_Crique_Anguille_3.JPG',
          wikiLink: 'https://fr.wikipedia.org/wiki/Camp_Crique_Anguille',
          hasLandmark: true,
        },
        city: {
          name: 'Cayenne',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cayenne#/media/File:Cayenne_place_des_palmistes_rue_de_Remire_2013.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayenne',
        },
        landmarksRound: [
          { code: 'FK', number: 2 },
          { code: 'FR', number: 4 },
          { code: 'SZ', number: 1 },
        ],
        landmarkPlaces: [
          'Sibebe Rock',
          'Kiritimati',
          'Stone House',
          "St. Olaf's Church",
          'Day Forest National Park',
          'Belvedere Lookout',
          'Tegucigalpa Cathedral',
        ],
        uniqueId: 'MWQXpPCc',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'French Guiana',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/michaelfowler/31278785236/',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Guiana',
        },
        landmark: {
          name: 'St. Lawrence Church',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/nicholaslaughlin/3468897857/',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Lawrence_Church,_Saint-Laurent-du-Maroni',
          hasLandmark: true,
        },
        city: {
          name: 'Cayenne',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/amanderson/16149082043/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cayenne',
        },
        landmarksRound: [
          { code: 'KE', number: 3 },
          { code: 'AI', number: 4 },
          { code: 'TV', number: 3 },
        ],
        landmarkPlaces: [
          'Solis Theater',
          "Notre-Dame d'Afrique",
          'Bissau Cathedral',
          'Peel Castle',
          "Martyr's Memorial",
          'Nxai Pan National Park',
          'Dhakeshwari Temple',
        ],
        uniqueId: 'jyH2T2Fl',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'French Guiana',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/amanderson/16582857709/',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Guiana',
        },
        landmark: {
          name: 'Fort Cépérou',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/portfolio_visions/26311421921/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_C%C3%A9p%C3%A9rou',
          hasLandmark: true,
        },
        city: {
          name: 'Saint-Laurent',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rousseaufamily/192789566/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint-Laurent-du-Maroni',
        },
        landmarksRound: [
          { code: 'MG', number: 3 },
          { code: 'BD', number: 4 },
          { code: 'RU', number: 2 },
        ],
        landmarkPlaces: [
          'Bratislava Castle',
          'Palace of Versailles',
          'National Gallery',
          'North Keeling',
          'Fasil Ghebbi',
          'Equatoria Tower',
          'Oceanographic Museum of Monaco',
        ],
        uniqueId: 'Qs5RNRGG',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    capital: 'Papeete',
    lat: -15,
    long: -140,
    cities: ['Papeete', 'Vaitape', 'Papara', "Pa'ea", "Puna'auia", 'Faaa'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ru_MKgg0sws',
          wikiLink: 'https://en.wikipedia.org/wiki/Mo%27orea',
        },
        landmark: {
          name: 'Belvedere Lookout',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/michaelrperry/6597138321',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Polynesia',
          hasLandmark: true,
        },
        city: {
          name: "Puna'auia",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Punaauia#/media/File:Papeete_-_Marina_Taina.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Punaauia',
        },
        landmarksRound: [
          { code: 'MN', number: 1 },
          { code: 'LB', number: 1 },
          { code: 'IS', number: 1 },
        ],
        landmarkPlaces: [
          'Villa Folio',
          'Mount Yasur',
          'Machu Picchu',
          'Tenaru Falls',
          'Amalienborg',
          'Liwonde National Park',
          'Olavinlinna',
        ],
        uniqueId: 'H8FjylSw',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/moorea-french-polynesia-tropical-680033/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tahiti',
        },
        landmark: {
          name: 'Arahoho Blowhole',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ruggybear/48033965818',
          wikiLink: 'https://en.wikipedia.org/wiki/French_Polynesia',
          hasLandmark: true,
        },
        city: {
          name: 'Papeete',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Papeete#/media/File:FP-papeete-hafen-1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Papeete',
        },
        landmarksRound: [
          { code: 'TJ', number: 2 },
          { code: 'ES', number: 2 },
          { code: 'GS', number: 2 },
        ],
        landmarkPlaces: [
          'Zaisan Memorial',
          'Al Alam Palace',
          'Acropolis',
          'Somapura Mahavihara',
          'Blue Lagoon',
          'Cologne Cathedral',
          'The Blue Eye',
        ],
        uniqueId: 'hkGlsC3W',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Bora-Bora',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-houses-on-water-near-green-mountain-under-blue-sky-during-daytime-tF0izq7URoo',
          wikiLink: 'https://en.wikipedia.org/wiki/Bora_Bora',
        },
        landmark: {
          name: 'Taputapuatea marae',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Taputapuatea_marae#/media/File:Marae,_Raiatea_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Taputapuatea_marae',
          hasLandmark: true,
        },
        city: {
          name: 'Papeete',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Papeete#/media/File:Papeete_-_Marina_Taina.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Papeete',
        },
        landmarksRound: [
          { code: 'WF', number: 2 },
          { code: 'DE', number: 1 },
          { code: 'HM', number: 2 },
        ],
        landmarkPlaces: [
          'Brandenburg Gate',
          "Devil's Island",
          'Hagia Sophia',
          'Gediminas Castle Tower',
          'Annandale Waterfall',
          'Chishimba Falls',
          'Tsingy De Bemaraha',
        ],
        uniqueId: 'vkqY5T9y',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tahiti',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yellow-bus-diRXmdG0wkc',
          wikiLink: 'https://en.wikipedia.org/wiki/Tahiti',
        },
        landmark: {
          name: "Fa'aruma'i Waterfalls",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/126250149@N08/16160609957/',
          wikiLink: 'https://www.wikidata.org/wiki/Q111449032',
          hasLandmark: true,
        },
        city: {
          name: 'Faaa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fa%CA%BBa%CA%BB%C4%81#/media/File:Sofitel_Tahiti_Maeva_Beach_Resort_-_panoramio_(12).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Faaa',
        },
        landmarksRound: [
          { code: 'VI', number: 1 },
          { code: 'GG', number: 2 },
          { code: 'MX', number: 1 },
        ],
        landmarkPlaces: [
          'Christ Church Cathedral',
          'Milwane Wildlife Sanctuary',
          'Fasil Ghebbi',
          'Cape Coast Castle',
          'Tikal Temple I',
          'Obelisk of Axum',
          'Elmina Castle',
        ],
        uniqueId: '7Sxh3L7c',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Moorea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-tropical-island-with-palm-trees-5SOTm1RlCmg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mo%CA%BBorea',
        },
        landmark: {
          name: 'Point Venus',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jpwchi/8525766589/',
          wikiLink: 'https://en.wikipedia.org/wiki/Point_Venus',
          hasLandmark: true,
        },
        city: {
          name: 'Faaa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/adamreeder/27585762611/',
          wikiLink: 'https://en.wikipedia.org/wiki/Faaa',
        },
        landmarksRound: [
          { code: 'AF', number: 4 },
          { code: 'AO', number: 1 },
          { code: 'AO', number: 4 },
        ],
        landmarkPlaces: [
          'Anse Lazio',
          'Matobo National Park',
          'Band-e Amir National Park',
          'Temple of Literature',
          'The Marble Mountains',
          'Palace of Peace and Reconciliation',
          'Blue Eye Cave',
        ],
        uniqueId: '3ZNF6w3k',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bora-Bora',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-metal-railing-and-huts-near-body-of-water-oDcbtrD3Rrc',
          wikiLink: 'https://en.wikipedia.org/wiki/Bora_Bora',
        },
        landmark: {
          name: 'Taputapuatea marae',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/111314495@N05/52987057992/',
          wikiLink: 'https://en.wikipedia.org/wiki/Taputapuatea_marae',
          hasLandmark: true,
        },
        city: {
          name: 'Papeete',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-patio-with-tables-and-chairs-I9jfdhtXQrQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Papeete',
        },
        landmarksRound: [
          { code: 'DO', number: 3 },
          { code: 'CX', number: 5 },
          { code: 'MG', number: 4 },
        ],
        landmarkPlaces: [
          'Marina Bay Sands',
          'Castillo San Felipe de Barajas',
          'Atacama Desert',
          'Boiling Lake',
          'Heydar Mosque',
          'Columbus Park',
          'Palace of Peace and Reconciliation',
        ],
        uniqueId: 'jy6Mzw6L',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Gabon',
    code: 'GA',
    capital: 'Libreville',
    lat: -1,
    long: 11.75,
    cities: [
      'Owendo',
      'Libreville',
      'Oyem',
      'Port-Gentil',
      'Franceville',
      'Ntoum',
      'Lambaréné',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/75Q_rXHFLXs',
          wikiLink: 'https://en.wikipedia.org/wiki/Gabon',
        },
        landmark: {
          name: 'Lope National Park',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Elephants_in_Lop%C3%A9_National_Park.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Lop%C3%A9_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Port-Gentil',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Port-Gentil#/media/File:POG-From_The_Air.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port-Gentil',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'ZTq9TJsf',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/africa-gabon-people-travels-379378/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gabon',
        },
        landmark: {
          name: 'Akanda National Park',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/gabon-sunset-color-sea-ocean-3871786/',
          wikiLink: 'https://en.wikipedia.org/wiki/Akanda_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Libreville',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Libreville#/media/File:Portlbv.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Libreville',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'BRXX6CmP',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Lastoursville',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-statue-near-green-trees-under-blue-sky-during-daytime-Bma9U3Vx1lw',
          wikiLink: 'https://en.wikipedia.org/wiki/Lastoursville',
        },
        landmark: {
          name: 'Loango National Park',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/wildlifepictures/28688152770/',
          wikiLink: 'https://en.wikipedia.org/wiki/Loango_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Libreville',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Libreville#/media/File:Libreville.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Libreville',
        },
        landmarksRound: [
          { code: 'GY', number: 1 },
          { code: 'ID', number: 1 },
          { code: 'GF', number: 3 },
        ],
        landmarkPlaces: [
          'Kunta Kinteh Island',
          'Pico Basile',
          'Soufriere Hills',
          'Mont Ross',
          'Stephen the Great Monument',
          'Petra',
          'Great Mosque of Asmara',
        ],
        uniqueId: 'g25bWvks',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Lope',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-fence-near-green-mountain-under-blue-sky-during-daytime-75Q_rXHFLXs',
          wikiLink: 'https://en.wikipedia.org/wiki/Ogoou%C3%A9-Ivindo_Province',
        },
        landmark: {
          name: 'National Museum of Arts, Rites and Traditions',
          photographer: '',
          imageLink:
            "https://fr.wikipedia.org/wiki/Mus%C3%A9e_national_des_Arts,_Rites_et_Traditions_du_Gabon#/media/Fichier:Vue_sur_l'entr%C3%A9e_du_mus%C3%A9e_national_des_arts_et_traditions_du_Gabon.jpg",
          wikiLink:
            'https://fr.wikipedia.org/wiki/Mus%C3%A9e_national_des_Arts,_Rites_et_Traditions_du_Gabon',
          hasLandmark: true,
        },
        city: {
          name: 'Port-Gentil',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Port-Gentil#/media/File:POG-Street_5.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port-Gentil',
        },
        landmarksRound: [
          { code: 'VE', number: 2 },
          { code: 'GQ', number: 4 },
          { code: 'KG', number: 2 },
        ],
        landmarkPlaces: [
          'Magnus Cathedral',
          'Vaduz Cathedral',
          'Sibebe Rock',
          'Ruhyyet Palace',
          'Church of Our Savior',
          'Fortaleza de São José da Amura',
          'Uganda National Mosque',
        ],
        uniqueId: 'ZCMsW6VJ',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Gabon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-lake-surrounded-by-trees-hiPUsUsq8BM',
          wikiLink: 'https://en.wikipedia.org/wiki/Gabon',
        },
        landmark: {
          name: 'Notre Dame de Lourdes',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/45258637345/',
          wikiLink: 'https://en.wikipedia.org/wiki/Notre_Dame_de_Lourdes',
          hasLandmark: true,
        },
        city: {
          name: 'Lambaréné',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-surrounded-by-lush-green-trees-5c4bq606DME',
          wikiLink: 'https://en.wikipedia.org/wiki/Lambar%C3%A9n%C3%A9',
        },
        landmarksRound: [
          { code: 'AZ', number: 1 },
          { code: 'HN', number: 2 },
          { code: 'DM', number: 2 },
        ],
        landmarkPlaces: [
          'Day Forest National Park',
          "Queen's Staircase",
          'Cathédrale Notre-Dame',
          'Singapore Flyer',
          'Ta Prohm ',
          'Oceanographic Museum of Monaco',
          'Harrison Point Lighthouse',
        ],
        uniqueId: 'MPpW9Gmc',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Gabon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/rock-formation-on-seashore-NEGV5U_UZio',
          wikiLink: 'https://en.wikipedia.org/wiki/Gabon',
        },
        landmark: {
          name: 'Lopé National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/bear_in_va/3703097608/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lop%C3%A9_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Libreville',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/fran001/53591083599/',
          wikiLink: 'https://en.wikipedia.org/wiki/Libreville',
        },
        landmarksRound: [
          { code: 'MY', number: 3 },
          { code: 'IQ', number: 2 },
          { code: 'AI', number: 3 },
        ],
        landmarkPlaces: [
          "Devil's Bridge",
          'El Capitolio',
          'Juche Tower',
          'Souq Waqif',
          'Lake Niamawi',
          'Senso-ji',
          'Jazeera Beach',
        ],
        uniqueId: 'QhD5s6Fp',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Gambia',
    code: 'GM',
    capital: 'Banjul',
    lat: 13.46666666,
    long: -16.56666666,
    cities: ['Banjul', 'Abuko', 'Serrekunda', 'Bansang', 'Brikama', 'Bakau'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GygPFmXGD1o',
          wikiLink: 'https://en.wikipedia.org/wiki/Gambia_River',
        },
        landmark: {
          name: 'Kunta Kinteh Island',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/island-river-boat-tourism-africa-2657237/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kunta_Kinteh_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Brikama',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Brikama#/media/File:Brikama_Nyambai_Road.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Brikama',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'GCLYFD4X',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/river-scene-gambia-fishing-village-2667905/',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Gambia',
        },
        landmark: {
          name: 'Arch 22',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Arch_22#/media/File:Gambia_banjul_arch22.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Arch_22',
          hasLandmark: true,
        },
        city: {
          name: 'Serekunda',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Serekunda_market.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Serekunda',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Yg9t4NK6',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Gambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-boat-on-water-near-houses-during-daytime-uOgB_xCBT4M',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Gambia',
        },
        landmark: {
          name: 'Pipeline Mosque',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Pipeline_Mosque#/media/File:Gambia_Kanifing_Municipal_2020-04-16_085_-_Mapillary_(KuVEMVusr0T1Z8k3_Xr1-w)_(cropped).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pipeline_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Banjul',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Banjul#/media/File:Banjul-King-Fahad-Mosque-2007.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Banjul',
        },
        landmarksRound: [
          { code: 'GF', number: 3 },
          { code: 'YT', number: 1 },
          { code: 'MU', number: 2 },
        ],
        landmarkPlaces: [
          'Columbus Park',
          'Mosquée Al-Hamoudi',
          'Big Ben',
          'Cintra Bay',
          'Cliffs of Moher',
          'Lake Armor',
          'Laferriere Citadel',
        ],
        uniqueId: 'wqppKZ2f',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Gambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-mountain-GygPFmXGD1o',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Gambia',
        },
        landmark: {
          name: 'Kunta Kinteh Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mrusschen/4505403898',
          wikiLink: 'https://en.wikipedia.org/wiki/Kunta_Kinteh_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Serrekunda',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Serekunda#/media/File:Serekunda_0001.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Serrekunda',
        },
        landmarksRound: [
          { code: 'KG', number: 1 },
          { code: 'IE', number: 1 },
          { code: 'TR', number: 2 },
        ],
        landmarkPlaces: [
          'Tegucigalpa Cathedral',
          'Sri Siva Subramaniya Temple',
          'Laferriere Citadel',
          'Emerald Pool',
          'Taputapuatea marae',
          'Motherland Monument',
          'Vianden Castle',
        ],
        uniqueId: 'DhwWqks5',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Gambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/beach-near-houses-ELHzK-UFabY',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Gambia',
        },
        landmark: {
          name: 'Kunta Kinteh Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/8703922@N02/14505938639/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kunta_Kinteh_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Banjul',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/199170576@N05/53793523620/',
          wikiLink: 'https://en.wikipedia.org/wiki/Banjul',
        },
        landmarksRound: [
          { code: 'BF', number: 4 },
          { code: 'HR', number: 6 },
          { code: 'GL', number: 2 },
        ],
        landmarkPlaces: [
          'Linnahall',
          'Sibebe Rock',
          'Gorkhi-Terelj National Park',
          "Diocletian's Palace",
          'Plaza de España',
          'Deception Island',
          'Tian Tan Buddha',
        ],
        uniqueId: 'LSWHmxgW',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Kanilai',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-standing-around-a-herd-of-animals-6vIOlt3ijoA',
          wikiLink: 'https://en.wikipedia.org/wiki/Kanilai',
        },
        landmark: {
          name: 'Arch 22',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/easy_traveler/4948609034/',
          wikiLink: 'https://en.wikipedia.org/wiki/Arch_22',
          hasLandmark: true,
        },
        city: {
          name: 'Serrekunda',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/melanie-jane/2357783705/',
          wikiLink: 'https://en.wikipedia.org/wiki/Serrekunda',
        },
        landmarksRound: [
          { code: 'DZ', number: 2 },
          { code: 'BZ', number: 5 },
          { code: 'GU', number: 2 },
        ],
        landmarkPlaces: [
          'Amboseli National Park, Kenya',
          'Masjid al-Haram',
          'Heydar Aliyev Center',
          'Our Lady of Ardilliers Catholic Church',
          'Cheshire Hall Plantation',
          'Vardzia',
          'Great Blue Hole',
        ],
        uniqueId: 'M8qfTK8K',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Georgia',
    code: 'GE',
    capital: 'Tbilisi',
    lat: 42,
    long: 43.5,
    cities: ['Tbilisi', 'Batumi', 'Poti', 'Kutaisi', 'Rustavi', 'Zugdidi'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/5iQWgow3_S0',
          wikiLink: 'https://en.wikipedia.org/wiki/Gergeti_Trinity_Church',
        },
        landmark: {
          name: 'Narikala Fortress',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tbilisi-georgia-travels-fortress-3643368/',
          wikiLink: 'https://en.wikipedia.org/wiki/Narikala',
          hasLandmark: true,
        },
        city: {
          name: 'Tbilisi',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/church-tbilisi-architecture-georgia-4580337/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tbilisi',
        },
        landmarksRound: [
          { code: 'BG', number: 1 },
          { code: 'PE', number: 1 },
          { code: 'SR', number: 2 },
        ],
        landmarkPlaces: [
          'Burj Khalifa',
          'Liwonde National Park',
          'Monument to the Heroes of the Restoration',
          'Huka Falls',
          'Panfilov Park',
          'Nizwa Fort',
          'Blue Grotto',
        ],
        uniqueId: 'sWpnwnZJ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caucasus-mountains-georgia-country-3110386/',
          wikiLink: 'https://en.wikipedia.org/wiki/Georgia_(country)',
        },
        landmark: {
          name: 'Vardzia',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vardzia-cave-city-landscape-5187928/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vardzia',
          hasLandmark: true,
        },
        city: {
          name: 'Batumi',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/batumi-georgia-adjara-view-resort-4926134/',
          wikiLink: 'https://en.wikipedia.org/wiki/Batumi',
        },
        landmarksRound: [
          { code: 'TH', number: 1 },
          { code: 'CN', number: 1 },
          { code: 'LY', number: 2 },
        ],
        landmarkPlaces: [
          'Tokyo Skytree',
          'Seven Colored Earths',
          'Senso-ji',
          'Gravensteen',
          'Karnak',
          'Hazrat Ali Mazar',
          'Tikal National Park',
        ],
        uniqueId: '9qYHpHbZ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Georgia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-paragliding-on-mountain-cliff-during-daytime-Edx0NpJ29fQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Georgia_(country)',
        },
        landmark: {
          name: 'Gergeti Trinity Church',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-building-on-hill-5iQWgow3_S0',
          wikiLink: 'https://en.wikipedia.org/wiki/Gergeti_Trinity_Church',
          hasLandmark: true,
        },
        city: {
          name: 'Kutaisi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-steel-electric-post-5ZlGf6FKJnE',
          wikiLink: 'https://en.wikipedia.org/wiki/Kutaisi',
        },
        landmarksRound: [
          { code: 'AS', number: 1 },
          { code: 'AD', number: 1 },
          { code: 'BE', number: 3 },
        ],
        landmarkPlaces: [
          'Twelve Apostles',
          'El Capitolio',
          'Okavango Delta',
          'Muzeu Historik Kombëtar',
          'Bab Al Bahrain',
          'Sao Filipe Royale Fortress',
          'Krka National Park',
        ],
        uniqueId: 'VJPVjJbR',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tbilisi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-under-cloudy-sky-during-daytime-fp-f0-reO7U',
          wikiLink: 'https://en.wikipedia.org/wiki/Tbilisi',
        },
        landmark: {
          name: 'Chronicle of Georgia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-building-on-green-grass-field-under-white-clouds-during-daytime-E9U4ZS9XuYw',
          wikiLink: 'https://en.wikipedia.org/wiki/Chronicle_of_Georgia',
          hasLandmark: true,
        },
        city: {
          name: 'Zugdidi',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/136827178@N08/30663971841/in/photolist-NHESvF-7zQcgT-nEKKSf-db6sVk-NHETgZ-NNcNom-ec5LnL-nMevyG-MTiE48-MTB1j5-e89zR5-Gfuiw-2oSBziM-Xeg6BU-nR4Cce-2nPBKpH-NF93yS-2cgv77a-2baqVWA-2oSB9Kf-Q8pToh-2nPBKkz-2oSBBuq-kXwitu-b71n1H-UxowYN-mH54DV-JrUDni-RbVAvy-XJiVm-2h3GhSz-2bZPS8a-2p1hVio-2p98PGr-2j7jT76-PHfEqV-2gWM7ct-2dcEsqK-2h3GYQM-2h3Gihc-2h4JjWj-2h4K8h6-2h2ZkkZ-2h2UWKk-2dgnztn-REXCdQ-PRLM3K-2oXhjiK-2h4K82G-k9wNWa',
          wikiLink: 'https://en.wikipedia.org/wiki/Zugdidi',
        },
        landmarksRound: [
          { code: 'CF', number: 3 },
          { code: 'CM', number: 1 },
          { code: 'VG', number: 3 },
        ],
        landmarkPlaces: [
          'Chomoni Beach',
          'Château Frontenac',
          "N'Djamena Grand Mosque",
          'Mir Castle',
          'Amalienborg',
          'Mount Nyriagongo',
          'Plitvice Lakes National Park',
        ],
        uniqueId: 'DNghvR22',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Tbilisi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-houses-b-eGDk5_gPo',
          wikiLink: 'https://en.wikipedia.org/wiki/Tbilisi',
        },
        landmark: {
          name: 'Holy Trinity Cathedral',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-clock-tower-on-top-of-it-ctCT-UL342A',
          wikiLink:
            'https://en.wikipedia.org/wiki/Holy_Trinity_Cathedral_of_Tbilisi',
          hasLandmark: true,
        },
        city: {
          name: 'Batumi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-at-night-from-the-top-of-a-building-QpbRz2YvN_k',
          wikiLink: 'https://en.wikipedia.org/wiki/Batumi',
        },
        landmarksRound: [
          { code: 'FJ', number: 6 },
          { code: 'AU', number: 1 },
          { code: 'KY', number: 2 },
        ],
        landmarkPlaces: [
          'Viñales Valley',
          'Al Bastakiya',
          'Shwedagon Pagoda',
          'Bled Castle',
          'English Harbour',
          'Chutes de Boali',
          'Salomon Islands',
        ],
        uniqueId: '6fSbts5Z',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Ushguli',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-houses-beside-rocky-mountains-during-daytime-DOhXyRS3_n0',
          wikiLink: 'https://en.wikipedia.org/wiki/Ushguli',
        },
        landmark: {
          name: 'Tybee Island Light',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/143374514@N03/30111067662/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tybee_Island_Light',
          hasLandmark: true,
        },
        city: {
          name: 'Tbilisi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-cars-parked-on-the-side-of-a-road-NRbn73A3Rbg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tbilisi',
        },
        landmarksRound: [
          { code: 'TV', number: 4 },
          { code: 'KM', number: 1 },
          { code: 'BH', number: 3 },
        ],
        landmarkPlaces: [
          'Gullfoss',
          "Tiger's Nest",
          'Sydney Opera House',
          'Badshahi Mosque',
          'Gorkhi-Terelj National Park',
          'Verona Arena',
          'Vianden Castle',
        ],
        uniqueId: 'NqN23fkt',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Germany',
    code: 'DE',
    capital: 'Berlin',
    lat: 51,
    long: 9,
    cities: [
      'Munich',
      'Hamburg',
      'Berlin',
      'Cologne',
      'Dusseldorf',
      'Frankfurt',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/QICQFkZp0Aw',
          wikiLink: 'https://en.wikipedia.org/wiki/Neuschwanstein_Castle',
        },
        landmark: {
          name: 'Brandenburg Gate',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/brand-front-of-the-brandenburg-gate-5117579/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brandenburg_Gate',
          hasLandmark: true,
        },
        city: {
          name: 'Munich',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/munich-olympic-stadium-tv-tower-2516492/',
          wikiLink: 'https://en.wikipedia.org/wiki/Munich',
        },
        landmarksRound: [
          { code: 'SY', number: 1 },
          { code: 'SK', number: 2 },
          { code: 'PH', number: 1 },
        ],
        landmarkPlaces: [
          'Anne Frank House',
          'Castillo San Cristobal',
          'Jardin de Balata',
          'Tikal National Park',
          'Xunatunich Mayan Ruins',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Seven Colored Earths',
        ],
        uniqueId: 'rP8tG7cH',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bastei-bridge-rock-formation-3014467/',
          wikiLink: 'https://en.wikipedia.org/wiki/Elbe_Sandstone_Mountains',
        },
        landmark: {
          name: 'Cologne Cathedral',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cologne-cathedral-night-time-5512117/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cologne_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Hamburg',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/hamburg-speicherstadt-channel-2976711/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamburg',
        },
        landmarksRound: [
          { code: 'GS', number: 1 },
          { code: 'DZ', number: 1 },
          { code: 'DO', number: 2 },
        ],
        landmarkPlaces: [
          'Winter Palace',
          'Hofburg Palace',
          'The Grand Palace',
          'Church of Our Savior',
          'Oriental Pearl TV Tower',
          'Heydar Aliyev Center',
          'Abu Simbel Temples',
        ],
        uniqueId: 'VyVbxKYC',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Rothenburg ob der Tauber',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-pink-petaled-flowers-on-metal-fence-near-concrete-houses-and-tower-at-daytime-g_gwdpsCVAY',
          wikiLink: 'https://en.wikipedia.org/wiki/Rothenburg_ob_der_Tauber',
        },
        landmark: {
          name: 'East Side Gallery',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-black-suit-jacket-2CkIpl-LhR4',
          wikiLink: 'https://en.wikipedia.org/wiki/East_Side_Gallery',
          hasLandmark: true,
        },
        city: {
          name: 'Berlin',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-near-brown-concrete-building-during-daytime-nzWLmc0aAXk',
          wikiLink: 'https://en.wikipedia.org/wiki/Berlin',
        },
        landmarksRound: [
          { code: 'SV', number: 2 },
          { code: 'GW', number: 1 },
          { code: 'MG', number: 2 },
        ],
        landmarkPlaces: [
          'Cité de Carcassonne',
          'Palace of Versailles',
          'Pico Basile',
          'Registan Square',
          'Pipeline Mosque',
          'Fort Cépérou',
          'Victoria Falls Bridge',
        ],
        uniqueId: 'ZHczPGtz',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Kochel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-mountain-under-white-clouds-during-daytime-XOVv1zbD-1U',
          wikiLink: 'https://en.wikipedia.org/wiki/Kochel',
        },
        landmark: {
          name: 'Neuschwanstein Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-gray-castle-on-top-of-hill-hMDRIo93xb0',
          wikiLink: 'https://en.wikipedia.org/wiki/Neuschwanstein_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Hamburg',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-buildings-near-the-road-during-sunset-2EszXp-o_oY',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamburg',
        },
        landmarksRound: [
          { code: 'IR', number: 1 },
          { code: 'ER', number: 2 },
          { code: 'KZ', number: 2 },
        ],
        landmarkPlaces: [
          'Borobudur Temple',
          "People's Liberation Army Museum",
          'Fortaleza de São José da Amura',
          'Monument to the Heroes of the Restoration',
          'Gateway Arch',
          'Laferriere Citadel',
          "Prince's Palace of Monaco",
        ],
        uniqueId: 'SrwJ2KpD',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Germany',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-highway-with-a-lot-of-traffic-rmeGDP2mAis',
          wikiLink: 'https://en.wikipedia.org/wiki/Germany',
        },
        landmark: {
          name: 'Eltz Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-castle-surrounded-by-trees-under-white-sky-l8mNql_6uRA',
          wikiLink: 'https://en.wikipedia.org/wiki/Eltz_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Hamburg',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/river-between-brown-concrete-buildings-PdnseHuDFZU',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamburg',
        },
        landmarksRound: [
          { code: 'AQ', number: 6 },
          { code: 'AX', number: 6 },
          { code: 'AQ', number: 4 },
        ],
        landmarkPlaces: [
          'Great Mosque of Porto-Novo',
          "Ha'amonga'a Maui Trilithon",
          'Tsingoni Mosque',
          'Fasil Ghebbi',
          'Muliaage',
          'Klein Curaçao',
          'Mir Castle',
        ],
        uniqueId: 'XCmQQhZ8',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Hamburg',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/train-running-on-rail-near-buildings-during-daytime-AWyj7_t8pj0',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamburg',
        },
        landmark: {
          name: 'Neuschwanstein Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-castle-near-the-mountain-L1DksU0Mrd4',
          wikiLink: 'https://en.wikipedia.org/wiki/Neuschwanstein_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Berlin',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/long-exposure-photo-of-high-rise-buildings-s99_mCWi7GA',
          wikiLink: 'https://en.wikipedia.org/wiki/Berlin',
        },
        landmarksRound: [
          { code: 'DZ', number: 1 },
          { code: 'LU', number: 4 },
          { code: 'BJ', number: 1 },
        ],
        landmarkPlaces: [
          'Atafu',
          'Victoria Falls Bridge',
          'Pyramiden',
          'Independence Monument',
          'Mausoleum of Khoja Ahmed Yasawi',
          'Deception Island',
          'Chomoni Beach',
        ],
        uniqueId: 'TtqcCkQC',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Ghana',
    code: 'GH',
    capital: 'Accra',
    lat: 8,
    long: -2,
    cities: [
      'Accra',
      'Kumasi',
      'Tamale',
      'Cape Coast',
      'Sunyani',
      'Tema',
      'Weija',
      'Obuasi',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/a0BGODzhmqg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ada_Foah',
        },
        landmark: {
          name: 'Elmina Castle',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/ghana-elmina-castle-3787972/',
          wikiLink: 'https://en.wikipedia.org/wiki/Elmina_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Kumasi',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Downtown_Kumasi,_Ghana.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kumasi',
        },
        landmarksRound: [
          { code: 'LU', number: 2 },
          { code: 'SA', number: 1 },
          { code: 'ES', number: 2 },
        ],
        landmarkPlaces: [
          'Green Grotto Caves',
          'Church of Saint Lazarus',
          'Sukhbaatar Square',
          'Taj Mahal',
          'Senso-ji',
          'Vardzia',
          'Gyeongbukgong Palace',
        ],
        uniqueId: 'yXRvQ8nF',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/ghana-west-africa-africa-village-1927853/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ghana',
        },
        landmark: {
          name: 'Cape Coast Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/castle-cape-coast-ghana-2419575/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Coast_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Tamale',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:Road,_Tamale.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tamale,_Ghana',
        },
        landmarksRound: [
          { code: 'TN', number: 1 },
          { code: 'RU', number: 2 },
          { code: 'AQ', number: 2 },
        ],
        landmarkPlaces: [
          'Ampitheater of El Jem',
          'The Marble Mountains',
          'The Grand Palace',
          'Pico Basile',
          'Iluissat Icefjord',
          'Golden Gate',
          'Rock of Cashel',
        ],
        uniqueId: 'WTY8nvPl',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Ada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-boat-on-body-of-water-near-green-palm-trees-during-daytime-zCAh6tOWFt8',
          wikiLink: 'https://en.wikipedia.org/wiki/Ghana',
        },
        landmark: {
          name: 'Larabanga Mosque',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Larabanga_Mosque#/media/File:Larabanga_Mosque_Ghana.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Larabanga_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Accra',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:A_drone_footage_of_Accra_central,_Ghana.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Accra',
        },
        landmarksRound: [
          { code: 'KZ', number: 1 },
          { code: 'MX', number: 1 },
          { code: 'LS', number: 1 },
        ],
        landmarkPlaces: [
          'Taputapuatea marae',
          'Registan Square',
          'Iluissat Icefjord',
          'Malolotja National Park',
          'Leptis Magna',
          'Victoria Falls Bridge',
          'Monumento al Divino Salvador del Mundo',
        ],
        uniqueId: 'pqHGfkbS',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Ghana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-standing-on-boulder-QqMy7OBBJjE',
          wikiLink: 'https://en.wikipedia.org/wiki/Ghana',
        },
        landmark: {
          name: 'Cape Coast Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/zug55/1919501764',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Coast_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Kumasi',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Kumasi#/media/File:St_Peter's_Cathedral_Basilica_Kumasi_(4).jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Kumasi',
        },
        landmarksRound: [
          { code: 'HM', number: 1 },
          { code: 'EG', number: 2 },
          { code: 'ET', number: 3 },
        ],
        landmarkPlaces: [
          'Azadi Tower',
          'Cité de Carcassonne',
          'Alhambra',
          'Senso-ji',
          'Chinguetti',
          'Baobab Avenue',
          'Liwonde National Park',
        ],
        uniqueId: 'xrrv3hC4',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Aburi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-house-with-a-dirt-path-in-front-of-it-AcAnos3euXM',
          wikiLink: 'https://en.wikipedia.org/wiki/Aburi#',
        },
        landmark: {
          name: 'Kwame Nkrumah Mausoleum',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/21738707240/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kwame_Nkrumah_Mausoleum',
          hasLandmark: true,
        },
        city: {
          name: 'Weija',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-lots-of-houses-jMZEZ08e9j8',
          wikiLink: 'https://en.wikipedia.org/wiki/Weija',
        },
        landmarksRound: [
          { code: 'SJ', number: 1 },
          { code: 'TN', number: 3 },
          { code: 'CO', number: 5 },
        ],
        landmarkPlaces: [
          "St. Peter's Basilica",
          'St. Francis of Assisi Cathedral',
          'Hundertwasserhaus',
          'National Museum of Bermuda',
          'La Moneda Palace',
          'Fortress of São Miguel',
          'Holy Trinity Cathedral',
        ],
        uniqueId: 'Y7RgZ6z8',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Volta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-chair-on-seashore-during-daytime-9g_rafKQKpY',
          wikiLink: 'https://en.wikipedia.org/wiki/Volta_Region',
        },
        landmark: {
          name: 'Elmina Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/theopendoor/14225582866/',
          wikiLink: 'https://en.wikipedia.org/wiki/Elmina_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Obuasi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-houses-_oRX5BCQTLw',
          wikiLink: 'https://en.wikipedia.org/wiki/Obuasi',
        },
        landmarksRound: [
          { code: 'AW', number: 4 },
          { code: 'CZ', number: 5 },
          { code: 'DK', number: 5 },
        ],
        landmarkPlaces: [
          'Moroccan sand wall',
          'Salvo Palace',
          'Turku Castle',
          'Soufriere Hills',
          'Jesuit Mission of the Holy Trinity',
          'Olavinlinna',
          'Stonehenge',
        ],
        uniqueId: '2b6mCTR9',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    capital: 'Gibraltar',
    lat: 36.13333333,
    long: -5.35,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/dVoNU7j3o_0',
          wikiLink: 'https://en.wikipedia.org/wiki/Ibrahim-al-Ibrahim_Mosque',
        },
        landmark: {
          name: "Saint Michael's Cave",
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/St._Michael%27s_Cave#/media/File:The_stage_inside_St._Michael's_Cave.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/St._Michael%27s_Cave',
          hasLandmark: true,
        },
        city: {
          name: 'Westside Gibraltar',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Gibraltar_%2839472230260%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Westside,_Gibraltar',
        },
        landmarksRound: [
          { code: 'VU', number: 1 },
          { code: 'NP', number: 2 },
          { code: 'KP', number: 1 },
        ],
        landmarkPlaces: [
          'Marcus Aurelius Arch',
          'Castle Cornet',
          "St. Peter's Church",
          'Easter Island',
          'Castillo San Cristobal',
          'Tikal National Park',
          'Pulhapanzak Waterfalls',
        ],
        uniqueId: '7RHhrvg5',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/gibraltar-mediterranean-sea-horn-50863/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gibraltar',
        },
        landmark: {
          name: 'Rock of Gibraltar',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/gibraltar-english-rock-coast-3509369/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rock_of_Gibraltar',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'z5dlnmfK',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'The Rock',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/moutanin-and-city-scenery-Fz3vBE8XoHc',
          wikiLink: 'https://en.wikipedia.org/wiki/Rock_of_Gibraltar',
        },
        landmark: {
          name: 'Europa Point Lighthouse',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/pink-flowers-across-white-and-red-lighthouse-during-daytime-T5ePvSRL5Hg',
          wikiLink: 'https://en.wikipedia.org/wiki/Europa_Point_Lighthouse',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'CI', number: 1 },
          { code: 'DO', number: 1 },
          { code: 'TC', number: 2 },
        ],
        landmarkPlaces: [
          'Arch 22',
          'Christ Church Cathedral',
          'Uganda National Mosque',
          'Cliffs of Moher',
          "People's Liberation Army Museum",
          'Tokyo Skytree',
          'Tash Rabat',
        ],
        uniqueId: '5nxpXm8v',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Gibraltar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/pink-flowers-across-white-and-red-lighthouse-during-daytime-T5ePvSRL5Hg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gibraltar',
        },
        landmark: {
          name: 'Great Siege Tunnels',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Great_Siege_Tunnels#/media/File:Siege_Tunnel_canon_embrasures,_Gibraltar_4.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Siege_Tunnels',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'DM', number: 4 },
          { code: 'FO', number: 4 },
          { code: 'IQ', number: 1 },
        ],
        landmarkPlaces: [
          "St. Tryphon's Cathedral",
          'Cintra Bay',
          "Lac 'Assal",
          'Chishimba Falls',
          'Pico Basile',
          'Baobab Avenue',
          'Vilnius Cathedral',
        ],
        uniqueId: 'lVxDlY24',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Gibraltar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-city-skyline-by-the-sea-during-daytime-lJkO8GGrIro',
          wikiLink: 'https://en.wikipedia.org/wiki/Gibraltar',
        },
        landmark: {
          name: 'Europa Point',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/196600477@N02/52391741951/',
          wikiLink: 'https://en.wikipedia.org/wiki/Europa_Point',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'AO', number: 2 },
          { code: 'DZ', number: 2 },
          { code: 'TD', number: 2 },
        ],
        landmarkPlaces: [
          'Notre Dame Auxiliatrice',
          'High Knoll Fort',
          'Magnus Cathedral',
          'African Renaissance Monument',
          'Gishora Drum Sanctuary',
          'Ta Prohm ',
          'Abu Simbel Temples',
        ],
        uniqueId: 'xWmsXTmR',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Gibraltar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cliff-beside-seashore-RjJiCLfMzLU',
          wikiLink: 'https://en.wikipedia.org/wiki/Gibraltar',
        },
        landmark: {
          name: 'Ibrahim-al-Ibrahim Mosque',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rocky-mountain-beside-beach-dVoNU7j3o_0',
          wikiLink: 'https://en.wikipedia.org/wiki/Ibrahim-al-Ibrahim_Mosque',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'XK', number: 4 },
          { code: 'CL', number: 6 },
          { code: 'GL', number: 3 },
        ],
        landmarkPlaces: [
          'Christ Church Cathedral',
          'Akanda National Park',
          'Island of Mozambique',
          'The Baths',
          'Skansen',
          'Paradise Island',
          'Islamic Center',
        ],
        uniqueId: '5bfPn2SN',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Greece',
    code: 'GR',
    capital: 'Athens',
    lat: 39,
    long: 22,
    cities: [
      'Thessaloniki',
      'Patras',
      'Athens',
      'Nafplion',
      'Heraklion',
      'Rhodes',
      'Chania',
      'Antiparos',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/x-HCoTDGgZw',
          wikiLink: 'https://en.wikipedia.org/wiki/Parga',
        },
        landmark: {
          name: 'Acropolis',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/athens-sunset-acropolis-greece-7402123/',
          wikiLink: 'https://en.wikipedia.org/wiki/Acropolis_of_Athens',
          hasLandmark: true,
        },
        city: {
          name: 'Thessaloniki',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/greece-thessaloniki-city-europe-2736426/',
          wikiLink: 'https://en.wikipedia.org/wiki/Thessaloniki',
        },
        landmarksRound: [
          { code: 'BA', number: 1 },
          { code: 'GE', number: 2 },
          { code: 'CA', number: 2 },
        ],
        landmarkPlaces: [
          'Iluissat Icefjord',
          'Shwedagon Pagoda',
          'Lukan Longshan Temple',
          'Frogner Park',
          '',
          'Rizal Park',
          'Skopje Fortress',
        ],
        uniqueId: 'fMnN2lgn',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/santorini-greece-buildings-houses-416135/',
          wikiLink: 'https://en.wikipedia.org/wiki/Santorini',
        },
        landmark: {
          name: 'Parthenon',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/acropolis-athens-greece-ancient-2725910/',
          wikiLink: 'https://en.wikipedia.org/wiki/Parthenon',
          hasLandmark: true,
        },
        city: {
          name: 'Patras',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Patras_Castle#/media/File:Patra_and_the_castle.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Patras',
        },
        landmarksRound: [
          { code: 'GD', number: 1 },
          { code: 'CW', number: 2 },
          { code: 'BB', number: 2 },
        ],
        landmarkPlaces: [
          'Saint Sava Temple',
          'Monastery of St. Naum',
          'Baalbek',
          'Fort Fincastle',
          'Niagara Falls',
          'Sky Tower',
          'Kim Il Sung Square',
        ],
        uniqueId: 'mhsQ5p8s',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Santorini',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/santorini-greece-_vA2q0-NroU',
          wikiLink: 'https://en.wikipedia.org/wiki/Santorini',
        },
        landmark: {
          name: 'Ancient Theatre of Epidaurus',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/saltonline/35276912672',
          wikiLink:
            'https://en.wikipedia.org/wiki/Ancient_Theatre_of_Epidaurus',
          hasLandmark: true,
        },
        city: {
          name: 'Athens',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-daytime-Ly1DHTvjSvs',
          wikiLink: 'https://en.wikipedia.org/wiki/Athens',
        },
        landmarksRound: [
          { code: 'HU', number: 2 },
          { code: 'FK', number: 4 },
          { code: 'HM', number: 2 },
        ],
        landmarkPlaces: [
          'Petra',
          'Argentine Military Cemetery',
          'Abu Simbel Temples',
          'Cité de Carcassonne',
          'Patuxay Monument',
          'Senso-ji',
          'Church of Our Savior',
        ],
        uniqueId: 'dTZRQtl4',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Zakynthos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/rocky-and-grass-mountain-island-during-day-bFdC-dVtFT8',
          wikiLink: 'https://en.wikipedia.org/wiki/Zakynthos',
        },
        landmark: {
          name: 'Meteora',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Meteora#/media/File:Meteora's_monastery_2.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Meteora',
          hasLandmark: true,
        },
        city: {
          name: 'Thessaloniki',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-through-a-market-uMiA5oKYQV8',
          wikiLink: 'https://en.wikipedia.org/wiki/Thessaloniki',
        },
        landmarksRound: [
          { code: 'DO', number: 2 },
          { code: 'MD', number: 2 },
          { code: 'IS', number: 2 },
        ],
        landmarkPlaces: [
          'Akanda National Park',
          'Trafalgar Falls',
          'Great Siege Tunnels',
          "St. Peter's Church",
          "Two Lover's Point",
          'Pico Basile',
          'Cape Coast Castle',
        ],
        uniqueId: '647NkcVQ',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Rhodes',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-buildings-on-mountain-by-the-sea-during-daytime-uInLh1mVAWg',
          wikiLink: 'https://en.wikipedia.org/wiki/Rhodes',
        },
        landmark: {
          name: 'Acropolis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-under-blue-sky-during-daytime-yqBvJJ8jGBQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Acropolis_of_Athens',
          hasLandmark: true,
        },
        city: {
          name: 'Chania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/rattan-armchairs-outside-blue-doors-VbqBM3D-gzE',
          wikiLink: 'https://en.wikipedia.org/wiki/Chania',
        },
        landmarksRound: [
          { code: 'TZ', number: 2 },
          { code: 'BG', number: 5 },
          { code: 'KE', number: 4 },
        ],
        landmarkPlaces: [
          'Fort Cépérou',
          'Tsodilo',
          'Tower of David',
          'Palacio Salvo',
          'Tjibaou Cultural Center',
          'Leshan Giant Buddha',
          'Metropolitan Cathedral of San Salvador',
        ],
        uniqueId: 'zmnfDZGM',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Oia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/buildings-near-ocean-dM_e5u-SM0U',
          wikiLink: 'https://en.wikipedia.org/wiki/Oia,_Greece',
        },
        landmark: {
          name: 'Palace of the Grand Master of the Knights of Rhodes',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/cpskelton/30338631510/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Palace_of_the_Grand_Master_of_the_Knights_of_Rhodes',
          hasLandmark: true,
        },
        city: {
          name: 'Antiparos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/pink-and-white-flower-tree-near-blue-and-white-concrete-house-during-daytime-vg2KSKsRA8c',
          wikiLink: 'https://en.wikipedia.org/wiki/Antiparos',
        },
        landmarksRound: [
          { code: 'CH', number: 1 },
          { code: 'FR', number: 5 },
          { code: 'WF', number: 4 },
        ],
        landmarkPlaces: [
          'Ciudad Mitad del Mundo',
          'Champagne Beach',
          'Silver Pagoda',
          'Tsodilo Hills',
          'Great Pyramid of Giza',
          'Tadrart Acacus',
          'Plaza de la Catedral',
        ],
        uniqueId: 'xXgxxV5r',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Greenland',
    code: 'GL',
    capital: 'Nuuk',
    lat: 72,
    long: -40,
    cities: [
      'Ilulissat',
      'Nuuk',
      'Tasiilaq',
      'Sisimiut',
      'Paamiut',
      'Qaqortoq',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/EM_G_WFK9z4',
          wikiLink: 'https://en.wikipedia.org/wiki/Aasiaat',
        },
        landmark: {
          name: 'Church of Our Savior',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Church_of_Our_Saviour_(Qaqortoq)#/media/File:Le_temple_%C3%A0_Qaqortoq,_Groenland.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Church_of_Our_Saviour_(Qaqortoq)',
          hasLandmark: true,
        },
        city: {
          name: 'Ilulissat',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ilulissat#/media/File:The_colors_from_Ilulissat_-_Greenland._-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ilulissat',
        },
        landmarksRound: [
          { code: 'LA', number: 2 },
          { code: 'SA', number: 2 },
          { code: 'GI', number: 1 },
        ],
        landmarkPlaces: [
          'Cologne Cathedral',
          'Forbidden City',
          'Epupa Falls',
          'Church of Saint Lazarus',
          'Dhakeshwari Temple',
          'Akershus Fortress',
          'Pamir Mountains',
        ],
        uniqueId: '4XgbDrNb',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mountains-snow-glacier-sea-arctic-5559241/',
          wikiLink: 'https://en.wikipedia.org/wiki/Greenland',
        },
        landmark: {
          name: 'Iluissat Icefjord',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ilulissat_Icefjord#/media/File:Scenic_view_of_Greenland_icebergs_in_Baffin_Bay_in_Disko_Bay_-_Buiobuione_photo_13.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ilulissat_Icefjord',
          hasLandmark: true,
        },
        city: {
          name: 'Nuuk',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/greenland-nuuk-town-arctic-1226101/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nuuk',
        },
        landmarksRound: [
          { code: 'SN', number: 2 },
          { code: 'AM', number: 2 },
          { code: 'BA', number: 2 },
        ],
        landmarkPlaces: [
          "St. Peter's Church",
          'Castillo San Cristobal',
          'Kravica Waterfall',
          'Senso-ji',
          'Epupa Falls',
          'Nizwa Fort',
          'The Grand Palace',
        ],
        uniqueId: 'n7K2T8JG',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Aasiaat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/view-photography-of-assorted-color-houses-near-pond-during-daytime-EM_G_WFK9z4',
          wikiLink: 'https://en.wikipedia.org/wiki/Aasiaat',
        },
        landmark: {
          name: 'Ilulissat Icefjord',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/deutschroemer/11503302696',
          wikiLink: 'https://en.wikipedia.org/wiki/Ilulissat_Icefjord',
          hasLandmark: true,
        },
        city: {
          name: 'Nuuk',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nuuk#/media/File:Qernertunnguit.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nuuk',
        },
        landmarksRound: [
          { code: 'GR', number: 1 },
          { code: 'GF', number: 2 },
          { code: 'EG', number: 1 },
        ],
        landmarkPlaces: [
          "Fa'aruma'i Waterfalls",
          "Fisherman's Bastion",
          'Vilnius Cathedral',
          'Alhambra',
          'Emerald Pool',
          'Kallur Lighthouse',
          'Christ Church Cathedral',
        ],
        uniqueId: '66Hsp783',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Ittoqqortoormiit',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-blue-wooden-houses-v7DzvSEcNks',
          wikiLink: 'https://en.wikipedia.org/wiki/Ittoqqortoormiit',
        },
        landmark: {
          name: 'Hvalsey Church',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Hvalsey_Church#/media/File:Hvalsey_Church.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hvalsey_Church',
          hasLandmark: true,
        },
        city: {
          name: 'Sisimiut',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sisimiut#/media/File:Sisimiut-centrum.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sisimiut',
        },
        landmarksRound: [
          { code: 'MT', number: 1 },
          { code: 'GW', number: 1 },
          { code: 'IQ', number: 2 },
        ],
        landmarkPlaces: [
          'Erbil Citadel',
          'Chamarel Waterfall',
          'Cape Coast Castle',
          'Trevi Fountain',
          'Arch 22',
          'Panfilov Park',
          'Grand Ducal Palace',
        ],
        uniqueId: 'q9Q5rq28',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Paamiut',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-houses--Yfj5PLaFk8',
          wikiLink: 'https://en.wikipedia.org/wiki/Paamiut',
        },
        landmark: {
          name: 'Hvalsey Church',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/144773827@N08/42537250230/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hvalsey_Church',
          hasLandmark: true,
        },
        city: {
          name: 'Sisimiut',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-field-with-houses-and-mountains-in-the-background-_vHnFM9pP84',
          wikiLink: 'https://en.wikipedia.org/wiki/Sisimiut',
        },
        landmarksRound: [
          { code: 'DM', number: 2 },
          { code: 'SZ', number: 3 },
          { code: 'IT', number: 2 },
        ],
        landmarkPlaces: [
          'Chorsu Bazaar',
          'Equatoria Tower',
          'Buddha Dordenma statue',
          'Chutes de Boali',
          'Uyuni Salt Flat',
          'Tombs of the Kings (Paphos)',
          'Okavango River',
        ],
        uniqueId: 'VgNxzBy4',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Greenland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tent-pitched-up-on-top-of-a-mountain-9wgSViip524',
          wikiLink: 'https://en.wikipedia.org/wiki/Greenland',
        },
        landmark: {
          name: 'Nuuk Cathedral',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-small-red-church-on-a-snowy-hill-UMj8PgFi6eE',
          wikiLink: 'https://en.wikipedia.org/wiki/Nuuk_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Nuuk',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-near-body-of-water-during-daytime-4PLVrOZ09Ng',
          wikiLink: 'https://en.wikipedia.org/wiki/Nuuk',
        },
        landmarksRound: [
          { code: 'FK', number: 2 },
          { code: 'AO', number: 6 },
          { code: 'GT', number: 2 },
        ],
        landmarkPlaces: [
          'Island of Mozambique',
          'National Park of American Samoa',
          'Alcázar de Colón',
          'St. Nicholas Abbey Heritage Railway',
          'La Sagrada Familia',
          'Cinquantenaire Arcade',
          "St. Joseph's Cathedral",
        ],
        uniqueId: 'HhHyY2yk',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Grenada',
    code: 'GD',
    capital: "Saint George's",
    lat: 12.11666666,
    long: -61.66666666,
    cities: [
      "Saint George's",
      'Gouyave',
      'Sauteurs',
      'Saint Davids',
      "St. George's",
      'Grenville',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/c9yXt2dL1JI',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
        },
        landmark: {
          name: 'Annandale Waterfall',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Willis,_Grenada#/media/File:Annandale_Waterfalls.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Willis,_Grenada',
          hasLandmark: true,
        },
        city: {
          name: "Saint George's",
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:The_Carenage_C_IM0448.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
        },
        landmarksRound: [
          { code: 'GG', number: 1 },
          { code: 'LY', number: 2 },
          { code: 'BE', number: 1 },
        ],
        landmarkPlaces: [
          'Epupa Falls',
          'Somapura Mahavihara',
          'Romney Manor',
          'Floralis Generica',
          'Monday Falls',
          'Kittiwake Shipwreck',
          'Olavinlinna',
        ],
        uniqueId: 'TyMNnSZs',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caribbean-island-tropical-harbor-1934661/',
          wikiLink: 'https://en.wikipedia.org/wiki/Grenada',
        },
        landmark: {
          name: 'Fort Frederick',
          photographer: '',
          imageLink:
            'https://commons.m.wikimedia.org/wiki/File:Fort_Frederick,_Grenada_%2840001365693%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Grenada',
          hasLandmark: true,
        },
        city: {
          name: 'Gouyave',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Gouyave#/media/File:Gouyave_town_aerial_view.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gouyave',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'VsGXyLmj',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint George',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-city-buildings-under-blue-and-white-sunny-cloudy-sky-during-daytime-0pBWK1yGweU',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
        },
        landmark: {
          name: 'Molinere Underwater Sculpture Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/markatwell/4409412598',
          wikiLink:
            'https://en.wikipedia.org/wiki/Molinere_Underwater_Sculpture_Park',
          hasLandmark: true,
        },
        city: {
          name: "St. George's",
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/St._George%27s,_Grenada#/media/File:2009_07-28_St._George's_Bay_(4).JPG",
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
        },
        landmarksRound: [
          { code: 'KE', number: 1 },
          { code: 'ER', number: 1 },
          { code: 'YT', number: 1 },
        ],
        landmarkPlaces: [
          'Grace Bay Beach',
          'Vaduz Cathedral',
          'Solis Theater',
          'Detwah Lagoon',
          'Magnus Cathedral',
          'Szechenyi Thermal Bath',
          'Champagne Reef, Dominica',
        ],
        uniqueId: 'tXTfZbBF',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: "St George's",
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-cars-parked-in-front-of-a-building-Q5ax4B2CgAM',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
        },
        landmark: {
          name: 'Fort Frederick',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/124651729@N04/49757133706/in/pool-941150@N25/',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
          hasLandmark: true,
        },
        city: {
          name: 'Grenville',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/sail_sweet_caroline/4389773545',
          wikiLink: 'https://en.wikipedia.org/wiki/Grenville,_Grenada',
        },
        landmarksRound: [
          { code: 'LS', number: 2 },
          { code: 'KW', number: 1 },
          { code: 'GF', number: 3 },
        ],
        landmarkPlaces: [
          'Iluissat Icefjord',
          'Pipeline Mosque',
          'Montserrat Volcano Observatory',
          'Stone House',
          'Columbus Park',
          'Angel Falls',
          'Qalito Island',
        ],
        uniqueId: 'P3LgxJt5',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Grenada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-small-town-by-the-ocean-NntB-p1ov8k',
          wikiLink: 'https://en.wikipedia.org/wiki/Grenada',
        },
        landmark: {
          name: 'Molinere Underwater Sculpture Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/28172347@N05/53376384453/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Molinere_Underwater_Sculpture_Park',
          hasLandmark: true,
        },
        city: {
          name: "St. George's",
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-surrounded-by-buildings-kqFrE_BmrdU',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
        },
        landmarksRound: [
          { code: 'KY', number: 3 },
          { code: 'BH', number: 1 },
          { code: 'AF', number: 3 },
        ],
        landmarkPlaces: [
          'Huka Falls',
          'Soufrière Hills volcano',
          'Golubac Fortress',
          'Rizal Park',
          'Grandvalira',
          'Deception Island',
          'Stone House',
        ],
        uniqueId: 'n5RtTB8y',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Grenada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-next-to-a-body-of-water-lHXfXXvh0lw',
          wikiLink: 'https://en.wikipedia.org/wiki/Grenada',
        },
        landmark: {
          name: 'Fort Frederick',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/fredrte/2139322070/',
          wikiLink: 'https://en.wikipedia.org/wiki/St._George%27s,_Grenada',
          hasLandmark: true,
        },
        city: {
          name: 'Grenville',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/luisperezcontreras/6115941994/',
          wikiLink: 'https://en.wikipedia.org/wiki/Grenville,_Grenada',
        },
        landmarksRound: [
          { code: 'MY', number: 2 },
          { code: 'TN', number: 2 },
          { code: 'GL', number: 5 },
        ],
        landmarkPlaces: [
          'Unfinished Church',
          'Frogner Park',
          'Trevi Fountain',
          'Oceanographic Museum of Monaco',
          'Tsodilo',
          'Gergeti Trinity Church',
          'Arch 22',
        ],
        uniqueId: '9SKplzyX',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    capital: 'Basse-Terre',
    lat: 16.25,
    long: -61.583333,
    cities: [
      'Baillif',
      'Basse-Terre',
      'Lamentin',
      'Pointe-à-Pitre',
      'Les Abymes',
      'Baie-Mahault',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/y32dchsQ-Gk',
          wikiLink: 'https://en.wikipedia.org/wiki/Terre-de-Haut_Island',
        },
        landmark: {
          name: 'Plage de Grande Anse',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Plage_de_la_Grande_Anse_%C3%A0_Deshaies.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Deshaies',
          hasLandmark: true,
        },
        city: {
          name: 'Pointe-a-Pitre',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Port_de_de_Pointe-%C3%A0-Pitre.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Pointe-%C3%A0-Pitre',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '3CvXdnkK',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caribbean-sea-sand-island-holiday-1656604/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guadeloupe',
        },
        landmark: {
          name: 'Jardin Botanique de Deshaies',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Jardin_botanique_de_Deshaies#/media/File:Jardin_Botanique_-_%C3%A9tang_aux_n%C3%A9nuphars.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Jardin_botanique_de_Deshaies',
          hasLandmark: true,
        },
        city: {
          name: 'Basse-Terre',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/pom-angers/50806203587',
          wikiLink: 'https://en.wikipedia.org/wiki/Basse-Terre',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'bMbnkQLT',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Guadeloupe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-island-with-coconut-trees-under-gray-skies-im43wgQ_81g',
          wikiLink: 'https://en.wikipedia.org/wiki/Guadeloupe',
        },
        landmark: {
          name: 'Sanctuaire de Notre-Dame des Larmes',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Sanctuaire_de_Notre-Dame_des_Larmes#/media/Fichier:Sanctuaire_Notre-Dame_des_Larmes_(photo_compl%C3%A8te).jpg',
          wikiLink:
            'https://fr.wikipedia.org/wiki/Sanctuaire_de_Notre-Dame_des_Larmes',
          hasLandmark: true,
        },
        city: {
          name: 'Basse-Terre',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Basse-Terre#/media/File:Rond-Point_central_de_Basse-Terre.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Basse-Terre',
        },
        landmarksRound: [
          { code: 'LI', number: 1 },
          { code: 'JO', number: 1 },
          { code: 'HU', number: 2 },
        ],
        landmarkPlaces: [
          'East Side Gallery',
          "St. Peter's Church",
          'Larabanga Mosque',
          'Victoria Falls Bridge',
          'Fort Ceperou',
          'Christ Church Cathedral',
          "Christian's Cave",
        ],
        uniqueId: 'bcSWjlVm',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Guadeloupe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-near-island-during-day-3NCI3Ld2uXc',
          wikiLink: 'https://en.wikipedia.org/wiki/Guadeloupe',
        },
        landmark: {
          name: 'Fort Napoléon des Saintes',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort_Napol%C3%A9on_des_Saintes#/media/File:Fort-Napol%C3%A9on-des-Saintes.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fort_Napol%C3%A9on_des_Saintes',
          hasLandmark: true,
        },
        city: {
          name: 'Pointe-à-Pitre',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Pointe-%C3%A0-Pitre#/media/File:Ancien_h%C3%B4tel_de_ville_de_Pointe-%C3%A0-Pitre_01.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Pointe-%C3%A0-Pitre',
        },
        landmarksRound: [
          { code: 'MS', number: 1 },
          { code: 'PR', number: 2 },
          { code: 'GF', number: 2 },
        ],
        landmarkPlaces: [
          "Christian's Cave",
          'Matobo National Park',
          'Jardin de Balata',
          'Abu Simbel Temples',
          "Ruins of St. Paul's",
          'Pico Basile',
          'Trafalgar Falls',
        ],
        uniqueId: 'l8zXbgyv',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Chameau ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-trees-and-body-of-water-during-daytime-y32dchsQ-Gk',
          wikiLink: 'https://fr.wikipedia.org/wiki/Chameau_(Guadeloupe)',
        },
        landmark: {
          name: 'Moulin Bézard',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/tresordesiles/3908047382/',
          wikiLink: 'https://fr.wikipedia.org/wiki/Moulin_B%C3%A9zard',
          hasLandmark: true,
        },
        city: {
          name: 'Pointe-à-Pitre',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/hbdev/46600193865/',
          wikiLink: 'https://fr.wikipedia.org/wiki/Pointe-%C3%A0-Pitre',
        },
        landmarksRound: [
          { code: 'EG', number: 6 },
          { code: 'KM', number: 3 },
          { code: 'LV', number: 3 },
        ],
        landmarkPlaces: [
          'The Pitcairn Museum',
          'Basilique Marie-Reine-des-Apôtres',
          'Lotus Temple',
          'California Lighthouse',
          'Lake Assal ',
          'Katse Dam',
          'Kelonia',
        ],
        uniqueId: 'lLD3kbgT',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Guadeloupe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/galvanize-iron-house-WW9DVUmBbIA',
          wikiLink: 'https://en.wikipedia.org/wiki/Guadeloupe',
        },
        landmark: {
          name: 'Jardin botanique de Deshaies',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/154286744@N06/43513339724/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Jardin_botanique_de_Deshaies',
          hasLandmark: true,
        },
        city: {
          name: 'Basse-Terre',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/prof_tournesol/52097274737/',
          wikiLink: 'https://en.wikipedia.org/wiki/Basse-Terre',
        },
        landmarksRound: [
          { code: 'BR', number: 2 },
          { code: 'FJ', number: 5 },
          { code: 'CK', number: 1 },
        ],
        landmarkPlaces: [
          'Queen Emma Bridge',
          'Angel Falls',
          'Little Chapel',
          'Viñales Valley',
          'Ouagadougou Cathedral',
          'Forbidden City',
          'Cathedral of Brasilica',
        ],
        uniqueId: 'JFT89xx4',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Guam',
    code: 'GU',
    capital: 'Hagåtña',
    lat: 13.46666666,
    long: 144.7833333,
    cities: [
      'Hagåtña',
      'Sinajana',
      'Dededo',
      'Yigo Village',
      'Mangilao Village',
      'Piti',
      'Hagatna',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/guam-landscape-scenic-bay-harbor-107281/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
        landmark: {
          name: 'Fort Nuestra Senora de la Soledad',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort_Nuestra_Se%C3%B1ora_de_la_Soledad#/media/File:Fort_Nuestra_Se%C3%B1ora_de_la_Soled%C3%A5d_-_Umatac,_Guam_-_DSC00948.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fort_Nuestra_Se%C3%B1ora_de_la_Soledad',
          hasLandmark: true,
        },
        city: {
          name: 'Dededo',
          photographer: '',
          imageLink:
            'https://simple.wikipedia.org/wiki/Dededo,_Guam#/media/File:Dededo_Flea_Market1.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Dededo',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'NJK8fMkJ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/guam-sea-ocean-water-rocks-rocky-102374/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
        landmark: {
          name: "Two Lover's Point",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Two_Lovers_Point#/media/File:Two_lovers_point_(80103943).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Two_Lovers_Point',
          hasLandmark: true,
        },
        city: {
          name: 'Mangilao',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Campus_view_-_University_of_Guam_-_DSC00995.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Mangilao,_Guam',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'nWFdtPzh',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Guam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-on-body-of-water-under-blue-sky-during-daytime-Y6Pl62E6lrU',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
        landmark: {
          name: 'Plaza de España',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Plaza_de_Espa%C3%B1a_(Hag%C3%A5t%C3%B1a)#/media/File:Chocolate_House_in_Hagatna.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Plaza_de_Espa%C3%B1a_(Hag%C3%A5t%C3%B1a)',
          hasLandmark: true,
        },
        city: {
          name: 'Hagåtña',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Hag%C3%A5t%C3%B1a,_Guam#/media/File:Cope_North_2022_fighter_formation_over_Andersen.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hag%C3%A5t%C3%B1a,_Guam',
        },
        landmarksRound: [
          { code: 'LT', number: 1 },
          { code: 'IM', number: 2 },
          { code: 'DE', number: 3 },
        ],
        landmarkPlaces: [
          'Akanda National Park',
          'Lope National Park',
          'Elmina Castle',
          'National Museum of Arts, Rites and Traditions',
          'Mulafossur Waterfall',
          'Prambanan Temple',
          'Fasil Ghebbi',
        ],
        uniqueId: 'pT5hyMzR',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Guam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-black-shirt-sitting-on-white-sand-near-palm-tree-during-daytime-bjKoa34Iv_I',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
        landmark: {
          name: 'Two Lovers Point',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/cappuccino_iv/794660935/',
          wikiLink: 'https://en.wikipedia.org/wiki/Two_Lovers_Point',
          hasLandmark: true,
        },
        city: {
          name: 'Dededo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dededo#/media/File:Micronesia_Mall1.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Dededo',
        },
        landmarksRound: [
          { code: 'IS', number: 2 },
          { code: 'DM', number: 2 },
          { code: 'FO', number: 1 },
        ],
        landmarkPlaces: [
          'Viru Gate',
          'Molinere Underwater Sculpture Park',
          'Detwah Lagoon',
          'Columbus Park',
          'Plaza Francia',
          'Ruhyyet Palace',
          'Bled Castle',
        ],
        uniqueId: 'KPqf8857',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Guam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-traffic-light-sitting-on-the-side-of-a-road-GGnr9_IQx9k',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
        landmark: {
          name: 'Fort Nuestra Señora de la Soledad',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/wozniaka/24954972650/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fort_Nuestra_Se%C3%B1ora_de_la_Soledad',
          hasLandmark: true,
        },
        city: {
          name: 'Dededo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/miyakosan/8564083738/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dededo',
        },
        landmarksRound: [
          { code: 'BS', number: 5 },
          { code: 'TN', number: 2 },
          { code: 'HM', number: 4 },
        ],
        landmarkPlaces: [
          'Tjentiste War Memorial',
          'Viñales Valley',
          'Sydney Opera House',
          'Lake Bled',
          'Bissau Cathedral',
          'Palacio Salvo',
          'Sandy Island',
        ],
        uniqueId: 'XqQkmBM8',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Guam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-surrounded-by-lush-green-hills-ZM34LGY-LXQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Guam',
        },
        landmark: {
          name: 'Two Lovers Point',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/imme/11867448256',
          wikiLink: 'https://en.wikipedia.org/wiki/Two_Lovers_Point',
          hasLandmark: true,
        },
        city: {
          name: 'Hagatna',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/michael_from_berlin/53333967381/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hag%C3%A5t%C3%B1a,_Guam',
        },
        landmarksRound: [
          { code: 'CX', number: 4 },
          { code: 'IQ', number: 4 },
          { code: 'FI', number: 6 },
        ],
        landmarkPlaces: [
          'Amber Palace',
          'Grand Mosque of Bobo-Dioulasso',
          'St. Francis of Assisi Cathedral',
          'Château Frontenac',
          'Sundarbans',
          'Fort Beekenburg',
          'Lake Armor',
        ],
        uniqueId: '5Yvk8j33',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Guatemala',
    code: 'GT',
    capital: 'Guatemala City',
    lat: 15.5,
    long: -90.25,
    cities: [
      'Villa Nueva',
      'Antigua Guatemala',
      'Guatemala City',
      'Quetzaltenango',
      'Escuintla',
      'Chichicastenango',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/antigua-guatemala-sunrise-guatemala-2652478/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatemala',
        },
        landmark: {
          name: 'Tikal Temple I',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/pyramid-maya-temple-ruins-prales-5408476/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tikal_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Villa Nueva',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Villa_Nueva,_Guatemala#/media/File:Zona_12_de_Villa_Nueva.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Villa_Nueva,_Guatemala',
        },
        landmarksRound: [
          { code: 'IR', number: 1 },
          { code: 'HU', number: 2 },
          { code: 'SY', number: 1 },
        ],
        landmarkPlaces: [
          'Masjid al-Haram',
          'Zaisan Memorial',
          'Gyeongbukgong Palace',
          'Arecibo Observatory',
          'Dhakeshwari Temple',
          'Souq Waqif',
          'Maiden Tower',
        ],
        uniqueId: 'RgrdSwmV',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/N4gc_5rZ9dM',
          wikiLink: 'https://en.wikipedia.org/wiki/Tikal_Temple_I',
        },
        landmark: {
          name: 'Santa Catalina Arch',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Arco_de_Santa_Catalina#/media/File:Santa_Catalina_Arch_-_Antigua_Guatemala_Feb_2020.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Arco_de_Santa_Catalina',
          hasLandmark: true,
        },
        city: {
          name: 'Antigua Guatemala',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/view-city-panorama-cross-statue-4987453/',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua_Guatemala',
        },
        landmarksRound: [
          { code: 'DE', number: 2 },
          { code: 'MN', number: 2 },
          { code: 'MA', number: 1 },
        ],
        landmarkPlaces: [
          'Patuxay Monument',
          'Gyeongbukgong Palace',
          'Gravensteen',
          "Prince's Palace of Monaco",
          'Queen Emma Bridge',
          'Atomium',
          'Bellapais Abbey',
        ],
        uniqueId: '52cczBHK',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Guatemala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-under-white-clouds-and-blue-sky-during-daytime-_T7cYIPPu9w',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatemala',
        },
        landmark: {
          name: 'Semuc Champey',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/gameoflight/8795081549',
          wikiLink: 'https://en.wikipedia.org/wiki/Semuc_Champey',
          hasLandmark: true,
        },
        city: {
          name: 'Guatemala City',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Guatemala_City#/media/File:Catedral_Metropolitana_-_Guatemala_City_-_Air_View.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatemala_City',
        },
        landmarksRound: [
          { code: 'LT', number: 1 },
          { code: 'GP', number: 3 },
          { code: 'KZ', number: 2 },
        ],
        landmarkPlaces: [
          'Chichen Itza',
          'Bagne des Annamites',
          'Plateau Mosque',
          'Green Grotto Caves',
          'Fort Napoléon des Saintes',
          'Lake Piso',
          'Pulhapanzak Waterfalls',
        ],
        uniqueId: 'vC5DV496',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Guatemala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-near-mountain--97MyV1TOJk',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatemala',
        },
        landmark: {
          name: 'Tikal National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/visitguatemala/5244821212/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tikal_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Quetzaltenango',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-dome-building-IY8eGMDCqJc',
          wikiLink: 'https://en.wikipedia.org/wiki/Quetzaltenango',
        },
        landmarksRound: [
          { code: 'DE', number: 1 },
          { code: 'ER', number: 2 },
          { code: 'GA', number: 3 },
        ],
        landmarkPlaces: [
          'Castle Cornet',
          'Egyptian Museum',
          'Lope National Park',
          'Mosquée Al-Hamoudi',
          "St. Olaf's Church",
          'Plateau Mosque',
          'Bagne des Annamites',
        ],
        uniqueId: 'ZZ4Wc8kG',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Quetzaltenango',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-sitting-on-the-side-of-a-road-aOSLrM6doeU',
          wikiLink: 'https://en.wikipedia.org/wiki/Quetzaltenangoo',
        },
        landmark: {
          name: 'Arco de Santa Catalina',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-near-brown-concrete-building-during-daytime-Vq-NkZ42I24',
          wikiLink: 'https://en.wikipedia.org/wiki/Arco_de_Santa_Catalina',
          hasLandmark: true,
        },
        city: {
          name: 'Guatemala City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-street-with-a-sign-on-the-side-of-it-VWLBV523Iw4',
          wikiLink: 'https://en.wikipedia.org/wiki/Guatemala_City',
        },
        landmarksRound: [
          { code: 'MU', number: 3 },
          { code: 'VU', number: 1 },
          { code: 'KH', number: 4 },
        ],
        landmarkPlaces: [
          'Boiling Lake',
          'Serengeti',
          'Khost Mosque',
          'Atlas Cove',
          'Crystal Caves',
          'Cathédrale Notre-Dame',
          'Great Pyramid of Giza',
        ],
        uniqueId: 'syLwjPWn',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'San Juan La Laguna',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-narrow-street-with-umbrellas-hanging-from-the-ceiling-xqvkvj37EcY',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan_La_Laguna',
        },
        landmark: {
          name: 'Tikal National Park',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/i_can_see_for_miles/29351356687/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tikal_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Antigua Guatemala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-street-with-cars-parked-on-both-sides-and-a-mountain-in-the-background-bOOBKbjFepc',
          wikiLink: 'https://en.wikipedia.org/wiki/Antigua_Guatemala',
        },
        landmarksRound: [
          { code: 'ES', number: 2 },
          { code: 'NE', number: 1 },
          { code: 'SI', number: 3 },
        ],
        landmarkPlaces: [
          'Christ Church Cathedral',
          'Moroccan sand wall',
          'Old Bridge',
          'Pitons',
          'Salomons Atoll',
          'Castle Rushden',
          'Kunta Kinteh Island',
        ],
        uniqueId: 'LSCPCHdb',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Guernsey',
    code: 'GG',
    capital: 'Saint Peter Port',
    lat: 49.46666666,
    long: -2.58333333,
    cities: [
      'Saint Peter Port',
      'Saint Andrew',
      'Lore City',
      'Quaker City',
      'Old Washington',
      '',
      'St. Sampson',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/N5ZHXIdy-io',
          wikiLink: 'https://en.wikipedia.org/wiki/Guernsey',
        },
        landmark: {
          name: 'The Little Chapel',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Little_Chapel#/media/File:Little_Chapel_op_Guernsey.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Little_Chapel',
          hasLandmark: true,
        },
        city: {
          name: 'Saint Peter Port',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:St_Peter_Port_Guernsey.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Peter_Port',
        },
        landmarksRound: [
          { code: 'SA', number: 1 },
          { code: 'MA', number: 2 },
          { code: 'KP', number: 1 },
        ],
        landmarkPlaces: [
          'Devin Castle',
          'Christ of Havana',
          'Aleppo Citadel',
          'Mir Castle',
          'Al Fateh Grand Mosque',
          'Soroca Fort',
          'Oriental Pearl TV Tower',
        ],
        uniqueId: 'b6h64Pwg',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/guernsey-landscape-dusk-2722964/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guernsey',
        },
        landmark: {
          name: 'Castle Cornet',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Guernsey_Castle_Cornet.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Castle_Cornet',
          hasLandmark: true,
        },
        city: {
          name: 'Saint Andrew',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:The_Little_Chapel,_Guernsey.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Andrew,_Guernsey',
        },
        landmarksRound: [
          { code: 'NL', number: 2 },
          { code: 'SG', number: 1 },
          { code: 'DO', number: 2 },
        ],
        landmarkPlaces: [
          'Turku Castle',
          'Devin Castle',
          'Pigeon Rocks',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Animal Flower Cave',
          'Winter Palace',
          'Bahrain Fort',
        ],
        uniqueId: 'nfb8dcVj',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Guernsey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-buildings-near-body-of-water-during-daytime-N5ZHXIdy-io',
          wikiLink: 'https://en.wikipedia.org/wiki/Guernsey',
        },
        landmark: {
          name: 'Little Chapel',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/heatheronhertravels/7338691040',
          wikiLink: 'https://en.wikipedia.org/wiki/Little_Chapel',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'FO', number: 4 },
          { code: 'SV', number: 2 },
          { code: 'ME', number: 1 },
        ],
        landmarkPlaces: [
          'Patuxay Monument',
          'Fasil Ghebbi',
          'Alhambra',
          'Tian Tan Buddha',
          'Cape Coast Castle',
          'Chamarel Waterfall',
          "Prince's Palace of Monaco",
        ],
        uniqueId: 'w4P5yCNK',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'La Forêt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/watch-tower-on-island-under-blue-skies-xy9Nc9DX2gw',
          wikiLink: 'https://en.wikipedia.org/wiki/Forest,_Guernsey',
        },
        landmark: {
          name: 'Castle Cornet',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Castle_Cornet#/media/File:Castle_Cornet,_Guernsey.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Castle_Cornet',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'IR', number: 1 },
          { code: 'LA', number: 1 },
          { code: 'IE', number: 2 },
        ],
        landmarkPlaces: [
          'Fort Frederick',
          "St. Tryphon's Cathedral",
          'Katse Dam',
          'Motherland Monument',
          'Cintra Bay',
          'East Side Gallery',
          "Prince's Palace of Monaco",
        ],
        uniqueId: 'yl2rlqNZ',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Guernsey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-on-a-street-between-buildings-_T0omPc-qzQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Guernsey',
        },
        landmark: {
          name: 'Castle Cornet',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-buildings--OddQFrDucA',
          wikiLink: 'https://en.wikipedia.org/wiki/Castle_Cornet',
          hasLandmark: true,
        },
        city: {
          name: 'Saint Peter Port',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-houses-near-body-of-water-during-daytime-RNW2XXNC3SM',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Peter_Port',
        },
        landmarksRound: [
          { code: 'LT', number: 3 },
          { code: 'CK', number: 4 },
          { code: 'TT', number: 1 },
        ],
        landmarkPlaces: [
          'Tarawa',
          'Madriu-Perafita-Claror Valley',
          'Omar Ali Saifuddien Mosque',
          'Jazeera Beach',
          'Kigali Genocide Memorial',
          'Joya De Ceren Archeological Site',
          'Azadi Tower',
        ],
        uniqueId: 'v33kGGZ8',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Guernsey',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/white-boats-xH2EwrttERY',
          wikiLink: 'https://en.wikipedia.org/wiki/Guernsey',
        },
        landmark: {
          name: 'Little Chapel',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/14836724@N00/53074669225/',
          wikiLink: 'https://en.wikipedia.org/wiki/Little_Chapel',
          hasLandmark: true,
        },
        city: {
          name: 'St. Sampson',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/piipriker/15731746977/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Sampson,_Guernsey',
        },
        landmarksRound: [
          { code: 'BM', number: 1 },
          { code: 'BY', number: 6 },
          { code: 'HN', number: 4 },
        ],
        landmarkPlaces: [
          'Leptis Magna',
          "Saint Michael's Cave",
          'Camana Bay',
          'Argentine Military Cemetery',
          'Derinkuyu',
          'Bran Castle',
          'Cameroon Reunification',
        ],
        uniqueId: 'N2J87d7n',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Guinea',
    code: 'GN',
    capital: 'Conakry',
    lat: 11,
    long: -10,
    cities: [
      'Conakry',
      'Nzérékoré',
      'Guéckédou',
      'Kissidougou',
      'Kankan',
      'Mamou',
      'Kindia',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Guinea#/media/File:Fouta_Djallon_(14582291826).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea',
        },
        landmark: {
          name: "Bride's Veil Waterfall",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Guinea#/media/File:Le_%22Voile%22_de_la_mari%C3%A9e_%C3%A0_Kindia.png',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea',
          hasLandmark: false,
        },
        city: {
          name: 'Conakry',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Guinea#/media/File:Conakry.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Conakry',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'rVw47nfy',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/woods-fog-guinea-mist-africa-2357567/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea',
        },
        landmark: {
          name: 'Grand Mosque of Conakry',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Grand_Mosque_of_Conakry#/media/File:Mosqu%C3%A9e_Faycal.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Mosque_of_Conakry',
          hasLandmark: false,
        },
        city: {
          name: 'Nzerekore',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Nz%C3%A9r%C3%A9kor%C3%A9_%2814603266964%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nz%C3%A9r%C3%A9kor%C3%A9',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'hPRb4n8g',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kindia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-green-grass-field-FjE-FyxDmlw',
          wikiLink: 'https://en.wikipedia.org/wiki/Kindia',
        },
        landmark: {
          name: 'Fouta Djallon',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/51937301@N03/8108284550/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fouta_Djallon',
          hasLandmark: true,
        },
        city: {
          name: 'Conakry',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-building-near-body-of-water-during-daytime-5Hy1HNPJjcU',
          wikiLink: 'https://en.wikipedia.org/wiki/Conakry',
        },
        landmarksRound: [
          { code: 'DO', number: 2 },
          { code: 'HM', number: 1 },
          { code: 'DO', number: 1 },
        ],
        landmarkPlaces: [
          'Fort Frederick',
          "St. Peter's Basilica",
          'Two Lovers Point',
          'Sanctuaire de Notre-Dame des Larmes',
          'Ben Amera',
          'Angel Falls',
          'Molinere Underwater Sculpture Park',
        ],
        uniqueId: 'YM9rV4FD',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Conakry',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-building-near-body-of-water-during-daytime-5Hy1HNPJjcU',
          wikiLink: 'https://en.wikipedia.org/wiki/Conakry',
        },
        landmark: {
          name: 'Monument du 22 novembre',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/137244110@N07/28362749542/',
          wikiLink:
            'https://fr.wikipedia.org/wiki/Monument_du_22_novembre_1970_(Conakry)',
          hasLandmark: true,
        },
        city: {
          name: 'Nzérékoré',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Nz%C3%A9r%C3%A9kor%C3%A9#/media/File:N'Z%C3%A9r%C3%A9kor%C3%A9.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Nz%C3%A9r%C3%A9kor%C3%A9',
        },
        landmarksRound: [
          { code: 'UZ', number: 1 },
          { code: 'VI', number: 1 },
          { code: 'MT', number: 1 },
        ],
        landmarkPlaces: [
          'Fort Ceperou',
          'Ampitheater of El Jem',
          'Plage de Grande Anse',
          'Champagne Reef, Dominica',
          'Registan Square',
          'Kuwait Towers',
          'Stephen the Great Monument',
        ],
        uniqueId: 'YCYYMZHf',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Kaloum',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-trees-and-beach-wVoZjM8ZYPs',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaloum',
        },
        landmark: {
          name: 'Grand Mosque',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/dawidk/3354990504/',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Mosque_of_Conakry',
          hasLandmark: true,
        },
        city: {
          name: 'Kindia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-green-grass-field-FjE-FyxDmlw',
          wikiLink: 'https://en.wikipedia.org/wiki/Kindia',
        },
        landmarksRound: [
          { code: 'IS', number: 3 },
          { code: 'UG', number: 1 },
          { code: 'TV', number: 4 },
        ],
        landmarkPlaces: [
          'Trafalgar Falls',
          'Buenos Aires Obelisk',
          'Solis Theater',
          'Lukan Longshan Temple',
          'Senso-ji',
          'Hofburg Palace',
          'Fort Frederick',
        ],
        uniqueId: 'PkqC3PtK',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Guinea',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ovong/48799992051/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea',
        },
        landmark: {
          name: 'Fouta Djallon',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/maartenvdbent/14625360573/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fouta_Djallon',
          hasLandmark: true,
        },
        city: {
          name: 'Conakry',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/lightning-during-nighttime-HdDw9foglbo',
          wikiLink: 'https://en.wikipedia.org/wiki/Conakry',
        },
        landmarksRound: [
          { code: 'CF', number: 5 },
          { code: 'BI', number: 6 },
          { code: 'MD', number: 2 },
        ],
        landmarkPlaces: [
          'The Pitcairn Museum',
          'Krka National Park',
          'Parthenon',
          'The Pearl Monument',
          'Luis I Bridge',
          'Karnak',
          'Copper Mine',
        ],
        uniqueId: 'NVWh8C6H',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    capital: 'Bissau',
    lat: 12,
    long: -15,
    cities: ['Canchungo', 'Buba', 'Bissau', 'Bafatá', 'Gabu', 'Farim'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/village-africa-guinea-bissau-431731/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea-Bissau',
        },
        landmark: {
          name: 'Fortaleza de São José da Amura',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fortaleza_de_S%C3%A3o_Jos%C3%A9_da_Amura#/media/File:Interior_da_Fortaleza_de_S%C3%A3o_Jos%C3%A9_de_Amura,_Bissau.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fortaleza_de_S%C3%A3o_Jos%C3%A9_da_Amura',
          hasLandmark: true,
        },
        city: {
          name: 'Gabu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Gabu_(town)#/media/File:Gabumainstreet.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gabu_(town)',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'RgxCjZsW',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/boat-island-sea-costa-ocean-water-434919/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea-Bissau',
        },
        landmark: {
          name: 'Bissau Cathedral',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Catedral_de_Bissau_%282%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bissau_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Bissau',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bissau#/media/File:Avenida_dos_Combatentes_da_Liberdade_da_P%C3%A1tria,_Bissau_(1).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bissau',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '9XtqnHlM',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Bissau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-riding-motorcycle-on-road-during-daytime-h781CadBmuQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea-Bissau',
        },
        landmark: {
          name: 'Cacheu Fort',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cacheu_Fort#/media/File:Forte_de_Cacheu_4.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cacheu_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Bissau',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bissau#/media/File:Bissau-Stroossenzeen--w.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bissau',
        },
        landmarksRound: [
          { code: 'DM', number: 4 },
          { code: 'VI', number: 2 },
          { code: 'IQ', number: 1 },
        ],
        landmarkPlaces: [
          'Macau Tower',
          'Lake Piso',
          'Christ Church Cathedral',
          'Angel Falls',
          'Senso-ji',
          'Champagne Beach',
          'Gateway Arch',
        ],
        uniqueId: 'sbCrYgfm',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Bissau',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jbdodane/9102589195',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea-Bissau',
        },
        landmark: {
          name: 'Memorial da Escravatura e do Tráfico Negreiro',
          photographer: '',
          imageLink:
            'https://pt.wikipedia.org/wiki/Memorial_da_Escravatura_e_do_Tr%C3%A1fico_Negreiro#/media/Ficheiro:Museu_Cacheu_06.jpg',
          wikiLink:
            'https://pt.wikipedia.org/wiki/Memorial_da_Escravatura_e_do_Tr%C3%A1fico_Negreiro',
          hasLandmark: true,
        },
        city: {
          name: 'Bafatá',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bafat%C3%A1#/media/File:20130612-DSC_9043_(9290801773)_(2).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bafat%C3%A1',
        },
        landmarksRound: [
          { code: 'MD', number: 2 },
          { code: 'VE', number: 1 },
          { code: 'MY', number: 1 },
        ],
        landmarkPlaces: [
          'Fort Napoléon des Saintes',
          'Victoria Falls',
          "Devil's Island",
          'Ruhyyet Palace',
          'Hvalsey Church',
          'Champagne Beach',
          'Roman theatre',
        ],
        uniqueId: 'xMzym2RC',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Guinea-Bissau',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/53849161706/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guinea-Bissau',
        },
        landmark: {
          name: 'Bissagos Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/satpalda/8680665366/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bissagos_Islands',
          hasLandmark: true,
        },
        city: {
          name: 'Bissau',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/41528106@N00/4325828680/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bissau',
        },
        landmarksRound: [
          { code: 'TC', number: 1 },
          { code: 'BW', number: 1 },
          { code: 'GP', number: 6 },
        ],
        landmarkPlaces: [
          'Abu Simbel',
          "People's Palace",
          'Charles Bridge',
          "Devil's Island",
          'Leshan Giant Buddha',
          'Sanctuaire de Notre-Dame des Larmes',
          'Marina Bay Sands',
        ],
        uniqueId: 'ZdFHRjvL',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bafatá',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/brettnbutter/33200073206/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bafat%C3%A1',
        },
        landmark: {
          name: 'Cacheu Fort',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Forte_de_Cacheu_4.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cacheu_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Bafatá',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/brettnbutter/32397618084/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bafat%C3%A1',
        },
        landmarksRound: [
          { code: 'JM', number: 4 },
          { code: 'DK', number: 4 },
          { code: 'HM', number: 1 },
        ],
        landmarkPlaces: [
          'Lake Lalolalo',
          'Marina Bay Sands',
          'Emerald Pool',
          'Mount Kilimanjaro',
          'Pierre Savorgnan de Brazza Memorial',
          'Curchi Monastery',
          'Stephen the Great Monument',
        ],
        uniqueId: 'rDrcsBVh',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Guyana',
    code: 'GY',
    capital: 'Georgetown',
    lat: 5,
    long: -59,
    cities: [
      'Georgetown',
      'Linden',
      'Bartica',
      'New Amsterdam',
      'Anna Regina',
      'Lethem',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/pZyD9-XReQQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Georgetown,_Guyana',
        },
        landmark: {
          name: 'Kaieteur Falls',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/waterfalls-a-single-drop-guyana-5089714/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaieteur_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Linden',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Linden,_Guyana#/media/File:Linden_Bauxite_Company_-_panoramio_-_Marco_Farouk_Basir.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Linden,_Guyana',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'rcsGlCMG',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/guyana-sky-clouds-lake-stream-80836/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guyana',
        },
        landmark: {
          name: 'Demerara Bridge',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Demerara_Harbour_Bridge#/media/File:20191122_Guyana_0171_Georgetown_sRGB_(49295935467).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Demerara_Harbour_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Georgetown',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Georgetown,_Guyana#/media/File:20191122_Guyana_0082_Georgetown_sRGB_(49295764796).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Georgetown,_Guyana',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'cXtKSgkM',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Guyana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-bridge-over-river-during-daytime-kvOJU3tGexs',
          wikiLink: 'https://en.wikipedia.org/wiki/Guyana',
        },
        landmark: {
          name: 'The 1763 Monument',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/nezkapix/4712179631',
          wikiLink: 'https://en.wikipedia.org/wiki/Coffy_(person)',
          hasLandmark: true,
        },
        city: {
          name: 'Georgetown',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Georgetown,_Guyana#/media/File:Guyana_Parliament_Building.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Georgetown,_Guyana',
        },
        landmarksRound: [
          { code: 'IM', number: 1 },
          { code: 'YE', number: 1 },
          { code: 'FK', number: 2 },
        ],
        landmarkPlaces: [
          'Jardin Botanique de Deshaies',
          'Annandale Waterfall',
          "St. Peter's Basilica",
          'Ducie Island',
          'Taputapuatea marae',
          'Marcus Aurelius Arch',
          'Burj Khalifa',
        ],
        uniqueId: 'wVjCqWRC',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Guyana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-house-ez9RfX6DLUk',
          wikiLink: 'https://en.wikipedia.org/wiki/Guyana',
        },
        landmark: {
          name: 'Fort Zeelandia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort_Zeelandia_(Guyana)#/media/File:20191123_Guyana_0200_Fort_Island_sRGB_(49295237188).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Zeelandia_(Guyana)',
          hasLandmark: true,
        },
        city: {
          name: 'Georgetown',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Georgetown,_Guyana#/media/File:Stabroek_Market_Clock_by_Khirsah1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Georgetown,_Guyana',
        },
        landmarksRound: [
          { code: 'GF', number: 1 },
          { code: 'NP', number: 1 },
          { code: 'GP', number: 3 },
        ],
        landmarkPlaces: [
          'Dubai Frame',
          'Blue Grotto',
          'Milwane Wildlife Sanctuary',
          'Stone House',
          'Two Lovers Point',
          "Fisherman's Bastion",
          'National Museum of Arts, Rites and Traditions',
        ],
        uniqueId: 'qmq2pJwX',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Guyana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-white-concrete-building-during-daytime-pZyD9-XReQQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Guyana',
        },
        landmark: {
          name: 'Fort Zeelandia ',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/vilseckxc/6577353605/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Zeelandia_(Guyana)',
          hasLandmark: true,
        },
        city: {
          name: 'Georgetown',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mattshea/30779046956/',
          wikiLink: 'https://en.wikipedia.org/wiki/Georgetown,_Guyana',
        },
        landmarksRound: [
          { code: 'KH', number: 3 },
          { code: 'BY', number: 6 },
          { code: 'EG', number: 1 },
        ],
        landmarkPlaces: [
          'Grand Mosque of Bobo-Dioulasso',
          'Baobab Avenue',
          'El Capitolio',
          'Salomon Islands',
          "N'Djamena Grand Mosque",
          'Charles Bridge',
          'Lotus Temple',
        ],
        uniqueId: '6gnRlzhC',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Guyana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-grass-field-1TnaUrrcm88',
          wikiLink: 'https://en.wikipedia.org/wiki/Guyana',
        },
        landmark: {
          name: 'Umana Yana',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/hhyeo/2581061404/',
          wikiLink: 'https://en.wikipedia.org/wiki/Umana_Yana',
          hasLandmark: true,
        },
        city: {
          name: 'New Amsterdam',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/madmack/1074559045/',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Amsterdam,_Guyana',
        },
        landmarksRound: [
          { code: 'CD', number: 5 },
          { code: 'VG', number: 3 },
          { code: 'BZ', number: 3 },
        ],
        landmarkPlaces: [
          'Fortress of São Miguel',
          'Green Grotto Caves',
          'Roman theatre',
          'Taj Mahal',
          'Palace of the Grand Master of the Knights of Rhodes',
          'Basilica of Our Lady of Guadalupe',
          'Fort Nuestra Senora de la Soledad',
        ],
        uniqueId: '2c438dKY',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Haiti',
    code: 'HT',
    capital: 'Port-au-Prince',
    lat: 19,
    long: -72.41666666,
    cities: [
      'Port-au-Prince',
      'Cap-Haïtien',
      'Pétion-Ville',
      'Delmas',
      'Carrefour',
      'Croix-des-Bouquets',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/HlseIzr_6fI',
          wikiLink: 'https://en.wikipedia.org/wiki/Cap-Ha%C3%AFtien',
        },
        landmark: {
          name: 'Laferriere Citadel',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Citadelle_Laferri%C3%A8re#/media/File:Citadelle_Laferri%C3%A8re.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Citadelle_Laferri%C3%A8re',
          hasLandmark: true,
        },
        city: {
          name: 'Petion-Ville',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/P%C3%A9tion-Ville#/media/File:Hills_of_Petion-Ville,_Haiti.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/P%C3%A9tion-Ville',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'r2ClY2wZ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/haiti-mountains-landscape-steep-79646/',
          wikiLink: 'https://en.wikipedia.org/wiki/Haiti',
        },
        landmark: {
          name: 'Sans-Soucis Palace',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sans-Souci_Palace#/media/File:Sans-Souci_Palace,_National_History_Park,_Haiti.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sans-Souci_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Port-au-Prince',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Port-au-Prince#/media/File:US_Navy_100221-N-5787K-002_An_aerial_view_of_the_logistical_area_near_the_port_in_Port-au-Prince_(cropped).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port-au-Prince',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'FMSHYbml',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Port-au-Prince',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-houses-near-green-trees-during-daytime-P8ZZ0aofrXI',
          wikiLink: 'https://en.wikipedia.org/wiki/Port-au-Prince',
        },
        landmark: {
          name: 'Notre-Dame of Cap-Haitian',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/haiti_med_relief_mission_2010/4520480697/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Our_Lady_of_the_Assumption_Cathedral,_Cap-Ha%C3%AFtien',
          hasLandmark: true,
        },
        city: {
          name: 'Port-au-Prince',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-boat-in-a-body-of-water-obclvUEjR9k',
          wikiLink: 'https://en.wikipedia.org/wiki/Port-au-Prince',
        },
        landmarksRound: [
          { code: 'MQ', number: 2 },
          { code: 'MC', number: 2 },
          { code: 'FJ', number: 3 },
        ],
        landmarkPlaces: [
          'Mutanabbi Statue',
          'Kathmandu Durbar Square',
          'Abuna Yemata Guh',
          "Fisherman's Bastion",
          'Molinere Underwater Sculpture Park',
          'Baobab Avenue',
          'Larabanga Mosque',
        ],
        uniqueId: '4HSgYCPg',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Jacmel Bay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-white-clouds-during-daytime-1kCf62phlgU',
          wikiLink: 'https://en.wikipedia.org/wiki/Jacmel',
        },
        landmark: {
          name: 'Sans-Souci Palace',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sans-Souci_Palace#/media/File:Palais_du_sans-soucis.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Sans-Souci_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Cap-Haïtien',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-beside-brown-concrete-building-during-daytime-HlseIzr_6fI',
          wikiLink: 'https://en.wikipedia.org/wiki/Cap-Ha%C3%AFtien',
        },
        landmarksRound: [
          { code: 'GW', number: 2 },
          { code: 'EG', number: 3 },
          { code: 'GA', number: 4 },
        ],
        landmarkPlaces: [
          "Fa'aruma'i Waterfalls",
          'Gateway Arch',
          'Great Siege Tunnels',
          'Tash Rabat',
          'Fort Nuestra Senora de la Soledad',
          'Cité de Carcassonne',
          'Lope National Park',
        ],
        uniqueId: 'qkvBVLrM',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Haiti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-rocky-beach-with-trees-and-a-body-of-water-KOLEBgHxzcI',
          wikiLink: 'https://en.wikipedia.org/wiki/Haiti',
        },
        landmark: {
          name: 'Cathédrale Sainte-Anne',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-white-church-with-a-steeple-surrounded-by-trees-3Ac7zj0A2PM',
          wikiLink:
            'https://fr.wikipedia.org/wiki/Cath%C3%A9drale_Sainte-Anne_d%27Anse-%C3%A0-Veau',
          hasLandmark: true,
        },
        city: {
          name: 'Pétion-Ville',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-during-daytime-U3Sdt2phkmA',
          wikiLink: 'https://en.wikipedia.org/wiki/P%C3%A9tion-Ville',
        },
        landmarksRound: [
          { code: 'AG', number: 1 },
          { code: 'VI', number: 4 },
          { code: 'HN', number: 2 },
        ],
        landmarkPlaces: [
          'Our Lady of Ardilliers Catholic Church',
          'Grace Bay Beach',
          'Doonagore Castle, Doolin, Ireland',
          'Hill of Crosses',
          'Catedral da Sé',
          'Rotunda of Mosta',
          'Monteverde Cloud Forest Reserve',
        ],
        uniqueId: '83y9WqLy',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Montrouis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-body-of-water-under-cloudy-sky-during-daytime-apS0gdKWG7E',
          wikiLink: 'https://en.wikipedia.org/wiki/Montrouis',
        },
        landmark: {
          name: 'Laferriere Citadel',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/sulitskiy/53908798002/',
          wikiLink: 'https://en.wikipedia.org/wiki/Citadelle_Laferri%C3%A8re',
          hasLandmark: true,
        },
        city: {
          name: 'Port-au-Prince',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/newshour/4783085594/',
          wikiLink: 'https://en.wikipedia.org/wiki/Port-au-Prince',
        },
        landmarksRound: [
          { code: 'DO', number: 6 },
          { code: 'BT', number: 6 },
          { code: 'BY', number: 3 },
        ],
        landmarkPlaces: [
          'Madriu-Perafita-Claror Valley',
          'Vilnius Cathedral',
          'Cheshire Hall Plantation',
          'La Soufriere',
          "Dunn's River Falls",
          'Old Panama',
          'Etoile rouge',
        ],
        uniqueId: '599phkwy',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Heard Island and McDonald Islands',
    code: 'HM',
    capital: '',
    lat: -53.08181,
    long: 73.504158,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Heard_Island_%28cropped%29.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
        landmark: {
          name: 'Mawson Peak',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/laikolosse/33754828666',
          wikiLink: 'https://en.wikipedia.org/wiki/Mawson_Peak',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'rvpGq8xp',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Heard_Island_by_Sentinel-2_Cloudless.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
        landmark: {
          name: 'Big Ben',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/laikolosse/33829886965',
          wikiLink: 'https://en.wikipedia.org/wiki/Big_Ben_(Heard_Island)',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '9VNJ2ljl',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Heard Island and McDonald Islands',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands#/media/File:Heard_Island_(cropped).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
        landmark: {
          name: 'Mawson Peak',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/29261553@N08/32034147640',
          wikiLink: 'https://en.wikipedia.org/wiki/Mawson_Peak',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'DM', number: 4 },
          { code: 'HK', number: 1 },
          { code: 'IR', number: 1 },
        ],
        landmarkPlaces: [
          'National Museum of Arts, Rites and Traditions',
          'Bagne des Annamites',
          'Baobab Avenue',
          'Monumento al Divino Salvador del Mundo',
          'Castle Cornet',
          'Fort Frederick',
          'Brandenburg Gate',
        ],
        uniqueId: 'ztvsS8yg',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Heard Island and McDonald Islands',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Heard_Island_and_McDonald_Islands#/media/File:Heard_Island.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
        landmark: {
          name: 'Atlas Cove',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/statelibraryofnsw/6433879023',
          wikiLink: 'https://en.wikipedia.org/wiki/Atlas_Cove',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'LT', number: 1 },
          { code: 'IE', number: 1 },
          { code: 'YT', number: 1 },
        ],
        landmarkPlaces: [
          'Mumbo Island',
          'Castle Cornet',
          'Vianden Castle',
          'Metropolitan Cathedral of San Salvador',
          'Belvedere Lookout',
          'Gateway Arch',
          'Elmina Castle',
        ],
        uniqueId: 'Yv5nDKks',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Heard Island and McDonald Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/29261553@N08/32257973995/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
        landmark: {
          name: 'Mawson Peak',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/madcityjudy/3453202329/in/photolist-7HYhxB-6g9yKc',
          wikiLink: 'https://en.wikipedia.org/wiki/Mawson_Peak',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'TN', number: 1 },
          { code: 'CD', number: 3 },
          { code: 'DM', number: 4 },
        ],
        landmarkPlaces: [
          'Forbidden City',
          'Alte Rheinbrücke',
          'Baščaršija',
          'Camana Bay',
          'Church of Saint Lazarus',
          'Shiwa Ngandu',
          'Illimani',
        ],
        uniqueId: 'RfT7T6PN',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Heard Island and McDonald Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/peche-pays-basque/4374498324/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands',
        },
        landmark: {
          name: 'Atlas Cove',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/laikolosse/33743067645/',
          wikiLink: 'https://en.wikipedia.org/wiki/Atlas_Cove',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'GQ', number: 6 },
          { code: 'GQ', number: 4 },
          { code: 'AS', number: 5 },
        ],
        landmarkPlaces: [
          'Cedars of God',
          'Deadvlei',
          'Green Grotto Caves',
          'Door of No Return, Ouidah',
          'Mumbo Island',
          'Atafu',
          'Ocean Park',
        ],
        uniqueId: 'JcBtcCHG',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Honduras',
    code: 'HN',
    capital: 'Tegucigalpa',
    lat: 15,
    long: -86.5,
    cities: [
      'Tegucigalpa',
      'Comayagua',
      'La Ceiba',
      'Choloma',
      'Choluteca',
      'San Pedro Sula',
      'El Porvenir',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WFFmZ0qIUYU',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Peter_the_Apostle_Cathedral,_San_Pedro_Sula',
        },
        landmark: {
          name: 'Tegucigalpa Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tegucigalpa_Cathedral#/media/File:Barrio_La_Hoya,_Tegucigalpa,_Honduras_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tegucigalpa_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'San Pedro Sula',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/san-pedro-sula-city-road-honduras-6647765/',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Pedro_Sula',
        },
        landmarksRound: [
          { code: 'RE', number: 2 },
          { code: 'GH', number: 2 },
          { code: 'NP', number: 2 },
        ],
        landmarkPlaces: [
          'Szechenyi Thermal Bath',
          'Ulu Temburong National Park',
          'Tomb of Hafez',
          'Alhambra',
          'Gangtey Monastery',
          'Independence Monument',
          'Wawel Castle',
        ],
        uniqueId: '2cQrSxJM',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/roatan-honduras-port-architecture-1358175/',
          wikiLink: 'https://en.wikipedia.org/wiki/Honduras',
        },
        landmark: {
          name: 'Pulhapanzak Waterfalls',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Cataratas_de_Pulhapanzak_%28III%29.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Tourism_in_Honduras',
          hasLandmark: true,
        },
        city: {
          name: 'Tegucigalpa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tegucigalpa#/media/File:Tegucigalpa,_Honduras_Long_Exposure_from_Humuya_Inn.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tegucigalpa',
        },
        landmarksRound: [
          { code: 'AU', number: 1 },
          { code: 'TR', number: 2 },
          { code: 'MW', number: 1 },
        ],
        landmarkPlaces: [
          'Devin Castle',
          'Macau Tower',
          'Sukhbaatar Square',
          'Monastery of St. Naum',
          'Golden Gate Bridge',
          'Mount Yasur',
          "St. Peter's Church",
        ],
        uniqueId: 'jpLw8PZF',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Santa Cruz de Yojoa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-and-river-under-white-clouds-during-daytime-IbNsWIgGyIY',
          wikiLink: 'https://en.wikipedia.org/wiki/Santa_Cruz_de_Yojoa',
        },
        landmark: {
          name: 'St. Peter the Apostle Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/St._Peter_the_Apostle_Cathedral,_San_Pedro_Sula#/media/File:San_Pedro_Sula_Cathedral.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Peter_the_Apostle_Cathedral,_San_Pedro_Sula',
          hasLandmark: true,
        },
        city: {
          name: 'Tegucigalpa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-during-daytime-4fZIF-cXiag',
          wikiLink: 'https://en.wikipedia.org/wiki/Tegucigalpa',
        },
        landmarksRound: [
          { code: 'GQ', number: 4 },
          { code: 'MW', number: 1 },
          { code: 'GL', number: 4 },
        ],
        landmarkPlaces: [
          'Alhambra',
          'Taputapuatea marae',
          'Roman theatre',
          'Rock of Gibraltar',
          'Tallinn Town Hall',
          "Fisherman's Bastion",
          'Abu Simbel Temples',
        ],
        uniqueId: 'SDjZ3QHk',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Honduras',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-people-at-market-h2kR-y1xUek',
          wikiLink: 'https://en.wikipedia.org/wiki/Honduras',
        },
        landmark: {
          name: 'Copán',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rafaeldorantes/4869028397',
          wikiLink: 'https://en.wikipedia.org/wiki/Cop%C3%A1n',
          hasLandmark: true,
        },
        city: {
          name: 'San Pedro Sula',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/San_Pedro_Sula#/media/File:Palaciomunicipal_San_Pedro_Sula.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Pedro_Sula',
        },
        landmarksRound: [
          { code: 'SZ', number: 4 },
          { code: 'WF', number: 2 },
          { code: 'TR', number: 1 },
        ],
        landmarkPlaces: [
          'Arecibo Observatory',
          'Plaza Francia',
          'Stone House',
          'Baalbek',
          'Cape Coast Castle',
          'Cacheu Fort',
          "People's Liberation Army Museum",
        ],
        uniqueId: 'zfhSPxx2',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Utila',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-small-island-in-the-middle-of-the-ocean-yCwiR0wxxoU',
          wikiLink: 'https://en.wikipedia.org/wiki/Utila',
        },
        landmark: {
          name: 'Basílica de Suyapa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/153927857@N03/43776294475/',
          wikiLink: 'https://es.wikipedia.org/wiki/Bas%C3%ADlica_de_Suyapa',
          hasLandmark: true,
        },
        city: {
          name: 'El Porvenir',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-a-neighborhood-K-NHEjG--9E',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Porvenir,_Atl%C3%A1ntida',
        },
        landmarksRound: [
          { code: 'GL', number: 2 },
          { code: 'FJ', number: 4 },
          { code: 'BW', number: 3 },
        ],
        landmarkPlaces: [
          'Mount Karthala',
          'Fort Zeelandia',
          'Quadiriki Caves',
          'Notre Dame Auxiliatrice',
          'Te Rua Manga (The Needle)',
          'Casa Loma',
          'Fouta Djallon',
        ],
        uniqueId: 'X2RqSjT8',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Utila',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-row-of-three-wheelers-parked-next-to-each-other-on-a-pier-d_X-Usz596Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Utila',
        },
        landmark: {
          name: 'Fuerte San Cristóbal',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/crystyna/4357193257/',
          wikiLink:
            'https://es.wikipedia.org/wiki/Fuerte_San_Crist%C3%B3bal_(Gracias)',
          hasLandmark: true,
        },
        city: {
          name: 'San Pedro Sula',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-car-parked-in-front-of-a-house-with-purple-flowers-D99HeblrPQk',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Pedro_Sula',
        },
        landmarksRound: [
          { code: 'UG', number: 2 },
          { code: 'LT', number: 1 },
          { code: 'BD', number: 1 },
        ],
        landmarkPlaces: [
          'Bab Al Bahrain',
          'Chutes de Boali',
          'Chillon Castle',
          'Jardin Botanique de Deshaies',
          'Hvalsey Church',
          'Mirador Roc Del Quer | Canillo',
          'Richat Structure',
        ],
        uniqueId: 'j3BH9tCQ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Hong Kong',
    code: 'HK',
    capital: 'City of Victoria',
    lat: 22.267,
    long: 114.188,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WMSvsWzhM0g',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
        landmark: {
          name: 'Tian Tan Buddha',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tian-tan-buddha-bronze-hongkong-958763/',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Big_Buddha_(Hong_Kong)',
          hasLandmark: true,
        },
        city: {
          name: 'Tsuen Wan',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tsuen_Wan#/media/File:TsuenWan_atNight.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tsuen_Wan',
        },
        landmarksRound: [
          { code: 'SK', number: 1 },
          { code: 'CD', number: 2 },
          { code: 'ES', number: 1 },
        ],
        landmarkPlaces: [
          'The Blue Mosque',
          'Marina Bay Sands',
          'Katse Dam',
          'Lukan Longshan Temple',
          'Bellapais Abbey',
          'Voortrekker Monument',
          'Tash Rabat',
        ],
        uniqueId: '5NH4nJ2n',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/the-peak-hong-kong-scenic-romantic-243269/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
        landmark: {
          name: 'Ocean Park',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/hong-kong-ocean-park-the-cable-car-1100890/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ocean_Park_Hong_Kong',
          hasLandmark: true,
        },
        city: {
          name: 'Kowloon',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/hong-kong-kowloon-city-evening-6603261/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kowloon',
        },
        landmarksRound: [
          { code: 'SG', number: 1 },
          { code: 'YE', number: 1 },
          { code: 'FJ', number: 1 },
        ],
        landmarkPlaces: [
          'Katse Dam',
          'Maiden Tower',
          'Hofburg Palace',
          'The Blue Mosque',
          'Al Fateh Grand Mosque',
          'Dhakeshwari Temple',
          'Winter Palace',
        ],
        uniqueId: 'CyyCsWNj',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Hong Kong',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-during-day-time-kCABKZBt4Gk',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
        landmark: {
          name: 'Clock Tower',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Clock_Tower,_Hong_Kong#/media/File:Former_Kowloon-Canton_Railway_Clock_Tower,_Tsim_Sha_Tsui_(Hong_Kong).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Clock_Tower,_Hong_Kong',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'GU', number: 4 },
          { code: 'ZM', number: 2 },
          { code: 'ID', number: 1 },
        ],
        landmarkPlaces: [
          'Cité de Carcassonne',
          'Fort Cépérou',
          'Plaza de España',
          'Sans-Soucis Palace',
          'Senso-ji',
          'Liberation Tower',
          "St. Peter's Church",
        ],
        uniqueId: 'C8cRwqKK',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Hong Kong',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-side-of-road-DdQkVaQbSsg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
        landmark: {
          name: 'Ten Thousand Buddhas Monastery',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ten_Thousand_Buddhas_Monastery#/media/File:10,000_Buddhas_Monastery_IMG_4866.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Ten_Thousand_Buddhas_Monastery',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'YE', number: 1 },
          { code: 'LI', number: 2 },
          { code: 'GD', number: 4 },
        ],
        landmarkPlaces: [
          'Annaberg Sugar Plantation',
          'Green Grotto Caves',
          'Plateau Mosque',
          'Cheshire Hall Plantation',
          'Castle Rushden',
          'Christ Church Cathedral',
          'Chishimba Falls',
        ],
        uniqueId: 'BmVMY5QN',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Hong Kong',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-water-fountain-33IMGbXx7-4',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
        landmark: {
          name: 'The Big Buddha',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-statue-under-blue-sky-during-daytime-4ObUiIlzfmY',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Big_Buddha_(Hong_Kong)',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'LS', number: 4 },
          { code: 'SY', number: 2 },
          { code: 'BB', number: 6 },
        ],
        landmarkPlaces: [
          'Molinere Underwater Sculpture Park',
          'Sagrada Família',
          'Atafu',
          'Hell',
          'Big Ben',
          'Mosta Rotunda',
          'Sistine Chapel',
        ],
        uniqueId: 'DV4Dbp6S',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Hong Kong',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-cityscape-qpWRtUL6NQ4',
          wikiLink: 'https://en.wikipedia.org/wiki/Hong_Kong',
        },
        landmark: {
          name: 'Po Lin Monastery ',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/cuernavaca10/37949470452/',
          wikiLink: 'https://en.wikipedia.org/wiki/Po_Lin_Monastery',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'IL', number: 3 },
          { code: 'CZ', number: 6 },
          { code: 'CW', number: 1 },
        ],
        landmarkPlaces: [
          'National Museum of Montserrat',
          'Great Mosque of Niamey',
          'Palace of Versailles',
          'Pula Arena',
          'Holy Trinity Cathedral',
          'Azadi Tower',
          'Uganda National Mosque',
        ],
        uniqueId: 'WFFfFrXk',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Hungary',
    code: 'HU',
    capital: 'Budapest',
    lat: 47,
    long: 20,
    cities: ['Budapest', 'Debrecen', 'Pecs', 'Szeged', 'Sopron', 'Gyor'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/qnUg1rdnUlk',
          wikiLink:
            'https://en.wikipedia.org/wiki/Hungarian_Parliament_Building',
        },
        landmark: {
          name: "Fisherman's Bastion",
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fisherman-s-bastion-landmark-7502270/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fisherman%27s_Bastion',
          hasLandmark: true,
        },
        city: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/river-budapest-hungary-architecture-5978743/',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
        landmarksRound: [
          { code: 'HN', number: 2 },
          { code: 'CN', number: 1 },
          { code: 'BY', number: 1 },
        ],
        landmarkPlaces: [
          'Tikal National Park',
          'Rhine Falls',
          'Hagia Sophia',
          'The Grand Palace',
          'Monteverde Cloud Forest',
          'Angel Falls',
          'Oriental Pearl TV Tower',
        ],
        uniqueId: 'pmB7JJ4R',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/szechenyi-chain-bridge-1758196/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sz%C3%A9chenyi_Chain_Bridge',
        },
        landmark: {
          name: 'Szechenyi Thermal Bath',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/szechenyi-spa-budapest-hungary-1333668/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sz%C3%A9chenyi_thermal_bath',
          hasLandmark: true,
        },
        city: {
          name: 'Debrecen',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Debrecen,_Hungary_-_panoramio_%286%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Debrecen',
        },
        landmarksRound: [
          { code: 'BN', number: 1 },
          { code: 'CA', number: 1 },
          { code: 'SK', number: 2 },
        ],
        landmarkPlaces: [
          "Dunn's River Falls",
          'Bled Castle',
          'Golden Gate',
          'Ulu Temburong National Park',
          'Golubac Fortress',
          'Pigeon Rocks',
          'Chimi Lhakhang',
        ],
        uniqueId: 'VNqQbXHR',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-sidewalk-near-white-concrete-building-during-daytime-iVhIhU5SH6w',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
        landmark: {
          name: 'Shoes on the Danube Bank',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/albertlugosi/32269125151',
          wikiLink: 'https://en.wikipedia.org/wiki/Shoes_on_the_Danube_Bank',
          hasLandmark: true,
        },
        city: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-buildings-viewing-bridge-and-sea-during-daytime-VPETME4zVWM',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
        landmarksRound: [
          { code: 'TM', number: 2 },
          { code: 'NP', number: 2 },
          { code: 'FK', number: 1 },
        ],
        landmarkPlaces: [
          'National Museum of Arts, Rites and Traditions',
          'Viru Gate',
          'The Blue Mosque',
          'Victoria Falls Bridge',
          'Santa Catalina Arch',
          'Seven Colored Earths',
          "St. Peter's Church",
        ],
        uniqueId: 'ncTCrRmj',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/yellow-and-white-train-on-rail-tracks-0K4Fo6xZz4Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
        landmark: {
          name: 'Vajdahunyad Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Vajdahunyad_Castle#/media/File:Vajdahunyad_Castle_In_Budapest.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Vajdahunyad_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Debrecen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-very-tall-building-in-the-middle-of-a-foggy-day-6V7umK9clcc',
          wikiLink: 'https://en.wikipedia.org/wiki/Debrecen',
        },
        landmarksRound: [
          { code: 'UG', number: 1 },
          { code: 'GR', number: 3 },
          { code: 'GQ', number: 2 },
        ],
        landmarkPlaces: [
          'The Marble Mountains',
          'Gateway Arch',
          'Dubai Frame',
          'Church of Our Savior',
          'Soroca Fort',
          'Stonehenge',
          'Castillo San Cristobal',
        ],
        uniqueId: 'xytLtpMN',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-bridge-with-lights-kDP_lvDzh2c',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
        landmark: {
          name: "Fisherman's Bastion",
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-establishment-during-daytime-qoE5TQpx6zk',
          wikiLink: 'https://en.wikipedia.org/wiki/Fisherman%27s_Bastion',
          hasLandmark: true,
        },
        city: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-beside-brown-concrete-building-during-daytime-Wlx1_x5w3Qg',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
        landmarksRound: [
          { code: 'CA', number: 4 },
          { code: 'MX', number: 1 },
          { code: 'ZM', number: 1 },
        ],
        landmarkPlaces: [
          'Champagne Beach',
          'Wallblake House',
          'Kaaba',
          'Pedro St. James Castle',
          'Church of Saint Lazarus',
          'Shiwa Ngandu',
          'Abu Simbel Temples',
        ],
        uniqueId: 'dgjY2rtD',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Budapest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-yellow-and-white-train-traveling-down-train-tracks-3kx_20rKFQM',
          wikiLink: 'https://en.wikipedia.org/wiki/Budapest',
        },
        landmark: {
          name: 'Hungarian Parliament Building',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-OG5cZHYMa-Y',
          wikiLink:
            'https://en.wikipedia.org/wiki/Hungarian_Parliament_Building',
          hasLandmark: true,
        },
        city: {
          name: 'Debrecen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-around-a-plaza-in-a-city-u7FNf3tX4mQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Debrecen',
        },
        landmarksRound: [
          { code: 'BR', number: 2 },
          { code: 'AU', number: 6 },
          { code: 'IN', number: 2 },
        ],
        landmarkPlaces: [
          "National Martyrs' Memorial",
          'Château de Chambord',
          'Boiling Lake',
          'Sultan Abdul Samad Building',
          'The Little Chapel',
          'Prambanan Temple',
          'Villa Folio',
        ],
        uniqueId: 'HY23gF4q',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Iceland',
    code: 'IS',
    capital: 'Reykjavik',
    lat: 65,
    long: -18,
    cities: [
      'Reykjavik',
      'Akureyri',
      'Husavik',
      'Isafjordir',
      'Selfoss',
      'Seydisfjordur',
      'Reykjavík',
      'Hafnarfjörður',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/4ThcC1DtspE',
          wikiLink: 'https://unsplash.com/photos/4ThcC1DtspE',
        },
        landmark: {
          name: 'Blue Lagoon',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/blue-lagoon-thermal-pool-4535986/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Blue_Lagoon_(geothermal_spa)',
          hasLandmark: true,
        },
        city: {
          name: 'Reykjavik',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/reykjavik-iceland-city-urban-1988082/',
          wikiLink: 'https://en.wikipedia.org/wiki/Reykjav%C3%ADk',
        },
        landmarksRound: [
          { code: 'CA', number: 1 },
          { code: 'BB', number: 2 },
          { code: 'GQ', number: 1 },
        ],
        landmarkPlaces: [
          'Cathedral Our Lady of the Rosary',
          'Hofburg Palace',
          'Tsingy De Bemaraha',
          'Arecibo Observatory',
          'Liwonde National Park',
          'Ulu Temburong National Park',
          'Marcus Aurelius Arch',
        ],
        uniqueId: '7rd2cmhz',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/seljalandsfoss-waterfalls-iceland-1751463/',
          wikiLink: 'https://en.wikipedia.org/wiki/Seljalandsfoss',
        },
        landmark: {
          name: 'Gullfoss',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/gullfoss-iceland-water-waterfall-1421642/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gullfoss',
          hasLandmark: true,
        },
        city: {
          name: 'Akureyri',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/iceland-akureyri-snow-mountains-4511994/',
          wikiLink: 'https://en.wikipedia.org/wiki/Akureyri',
        },
        landmarksRound: [
          { code: 'CY', number: 1 },
          { code: 'PF', number: 2 },
          { code: 'MM', number: 2 },
        ],
        landmarkPlaces: [
          'Dhakeshwari Temple',
          'Vaduz Cathedral',
          'Villa Folio',
          'Ostrog Monastery',
          'Wieliczka Salt Mine',
          'Fasil Ghebbi',
          'Santa Catalina Arch',
        ],
        uniqueId: 'Np4nBZMk',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Iceland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-sand-near-body-of-water-under-the-cloudy-sky-during-daytime-MLKrf51NV8w',
          wikiLink: 'https://en.wikipedia.org/wiki/Iceland',
        },
        landmark: {
          name: 'Jökulsárlón',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/J%C3%B6kuls%C3%A1rl%C3%B3n#/media/File:Vatnaj%C3%B6kull_glacier.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/J%C3%B6kuls%C3%A1rl%C3%B3n',
          hasLandmark: true,
        },
        city: {
          name: 'Reykjavik',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-and-blue-concrete-building-e9JmMFgiw5I',
          wikiLink: 'https://en.wikipedia.org/wiki/Reykjavik',
        },
        landmarksRound: [
          { code: 'MQ', number: 2 },
          { code: 'GQ', number: 4 },
          { code: 'GI', number: 2 },
        ],
        landmarkPlaces: [
          'Meteora',
          'Fort Frederick',
          'Petra',
          'Cliffs of Moher',
          'Erbil Citadel',
          'Cologne Cathedral',
          'Fort Zeelandia',
        ],
        uniqueId: '6xRVvL7x',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Iceland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-standing-on-the-edge-of-a-cliff-f3uWi9G-lus',
          wikiLink: 'https://en.wikipedia.org/wiki/Iceland',
        },
        landmark: {
          name: 'Kerid Crater',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Keri%C3%B0#/media/File:A_girl_standing_on_top_of_Keri%C3%B0.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Keri%C3%B0',
          hasLandmark: true,
        },
        city: {
          name: 'Akureyri',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-during-daytime-p852LruOKnw',
          wikiLink: 'https://en.wikipedia.org/wiki/Akureyri',
        },
        landmarksRound: [
          { code: 'GI', number: 1 },
          { code: 'DM', number: 3 },
          { code: 'EG', number: 4 },
        ],
        landmarkPlaces: [
          'Notre-Dame of Cap-Haitian',
          'Salvo Palace',
          'La Sagrada Familia',
          'Tsingy De Bemaraha',
          'Jardin de Balata',
          'Tarawa',
          "Lac 'Assal",
        ],
        uniqueId: 'Z4mdtxbv',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Gullfoss',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-water-falls-at-daytime-n1nDUvnffMc',
          wikiLink: 'https://en.wikipedia.org/wiki/Gullfoss',
        },
        landmark: {
          name: 'Hof',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-house-surrounded-with-green-trees-under-gray-clouds-eXoVCy7BhCg',
          wikiLink: 'https://en.wikipedia.org/wiki/Hof,_Iceland',
          hasLandmark: true,
        },
        city: {
          name: 'Reykjavík',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photography-of-white-swan-floating-on-water-body-WvPQYDd-3Ow',
          wikiLink: 'https://en.wikipedia.org/wiki/Reykjav%C3%ADk',
        },
        landmarksRound: [
          { code: 'ET', number: 3 },
          { code: 'IT', number: 4 },
          { code: 'PY', number: 2 },
        ],
        landmarkPlaces: [
          'South Pole',
          'Buada Lagoon',
          'Mount Cameroon',
          'Tsavo East National Park',
          'Rock of Gibraltar',
          'Matobo National Park',
          'Europa Point',
        ],
        uniqueId: 'SwZQf4rv',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Iceland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photography-of-five-assorted-color-horses-on-grass-field-in-front-of-mountain-diMBLU4FzDQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Iceland',
        },
        landmark: {
          name: 'Seljalandsfoss',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/waterfalls-at-daytime-zNN6ubHmruI',
          wikiLink: 'https://en.wikipedia.org/wiki/Seljalandsfoss',
          hasLandmark: true,
        },
        city: {
          name: 'Hafnarfjörður',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/purblind/30435142861/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hafnarfj%C3%B6r%C3%B0ur',
        },
        landmarksRound: [
          { code: 'MT', number: 1 },
          { code: 'GF', number: 3 },
          { code: 'NZ', number: 2 },
        ],
        landmarkPlaces: [
          'A-Ma Temple',
          "Christian's Cave",
          'Grand Mosque of Kuwait',
          'Laferriere Citadel',
          'Anibare Bay',
          'Atacama Desert',
          'Belovezhskaya Pushcha National Park',
        ],
        uniqueId: '4LgprDZ9',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'India',
    code: 'IN',
    capital: 'New Delhi',
    lat: 20,
    long: 77,
    cities: [
      'Jaipur',
      'Kolkata',
      'Chennai',
      'Lucknow',
      'Hyderabad',
      'Bengaluru',
      'New Delhi',
      'Rajasthan',
      'Udaipur',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/nE2gf1scItI',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Memorial,_Kolkata',
        },
        landmark: {
          name: 'Amber Palace',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/india-amber-fortress-palace-1545838/',
          wikiLink: 'https://en.wikipedia.org/wiki/Amber_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Jaipur',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jaipur-city-india-top-view-indian-166512/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jaipur',
        },
        landmarksRound: [
          { code: 'KG', number: 2 },
          { code: 'MO', number: 2 },
          { code: 'MZ', number: 1 },
        ],
        landmarkPlaces: [
          'Hazrat Ali Mazar',
          'Nizwa Fort',
          'Azadi Tower',
          'Tokyo Skytree',
          'Hato Caves',
          'Castillo San Cristobal',
          'Easter Island',
        ],
        uniqueId: 'SnGQxrW8',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/india-varinasia-ganges-boats-1309206/',
          wikiLink: 'https://en.wikipedia.org/wiki/India',
        },
        landmark: {
          name: 'Taj Mahal',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/taj-mahal-india-monument-building-4109110/',
          wikiLink: 'https://en.wikipedia.org/wiki/Taj_Mahal',
          hasLandmark: true,
        },
        city: {
          name: 'Kolkata',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/traffic-city-road-travel-dusk-3331559/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kolkata',
        },
        landmarksRound: [
          { code: 'KN', number: 2 },
          { code: 'AZ', number: 2 },
          { code: 'GB', number: 1 },
        ],
        landmarkPlaces: [
          'Stone House',
          'Kim Il Sung Square',
          'Liwonde National Park',
          'Pulhapanzak Waterfalls',
          'Boudhanath',
          'Chillon Castle',
          'Floralis Generica',
        ],
        uniqueId: 'K4zFpdy4',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kolkata',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-castle-near-body-of-water-nE2gf1scItI',
          wikiLink: 'https://en.wikipedia.org/wiki/Kolkata',
        },
        landmark: {
          name: 'Lotus Temple',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-near-body-of-water-during-daytime-PnlNR95vYNs',
          wikiLink: 'https://en.wikipedia.org/wiki/Lotus_Temple',
          hasLandmark: true,
        },
        city: {
          name: 'Hyderabad',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-buildings-near-gray-asphalt-road-during-daytime-IMvkGVG2wAM',
          wikiLink: 'https://en.wikipedia.org/wiki/Hyderabad',
        },
        landmarksRound: [
          { code: 'AW', number: 2 },
          { code: 'BR', number: 1 },
          { code: 'CN', number: 2 },
        ],
        landmarkPlaces: [
          'Plitvice Lakes National Park',
          'Mir Castle',
          "St. Stephen's Cathedral",
          'One Foot Island',
          'Turtle Cove',
          'Crystal Caves',
          'Kastelholm Castle',
        ],
        uniqueId: 'GFlLKRCH',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Alappuzha',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-riding-on-boat-during-daytime-MvkLKGtBcUA',
          wikiLink: 'https://en.wikipedia.org/wiki/Alappuzha',
        },
        landmark: {
          name: 'Jal Mahal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-flying-over-the-lake-during-daytime-3P1G67zNyCI',
          wikiLink: 'https://en.wikipedia.org/wiki/Jal_Mahal',
          hasLandmark: true,
        },
        city: {
          name: 'Bengaluru',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-red-train-on-rail-road-during-daytime-F_3qCaaSadc',
          wikiLink: 'https://en.wikipedia.org/wiki/Bangalore',
        },
        landmarksRound: [
          { code: 'BO', number: 4 },
          { code: 'BR', number: 1 },
          { code: 'BF', number: 4 },
        ],
        landmarkPlaces: [
          'The Baths',
          "People's Palace",
          'Blue Eye Cave',
          'Narikala Fortress',
          'La Moneda Palace',
          'Chronicle of Georgia',
          'Amberd',
        ],
        uniqueId: 'RdnVZlPT',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Tamil Nadu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-mountain-during-daytime-E5egsk4eUQ0',
          wikiLink: 'https://en.wikipedia.org/wiki/Tamil_Nadu',
        },
        landmark: {
          name: 'India Gate',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-stone-arch-with-a-clock-on-it-GcLgZ3BN4GA',
          wikiLink: 'https://en.wikipedia.org/wiki/India_Gate',
          hasLandmark: true,
        },
        city: {
          name: 'Rajasthan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-sitting-on-top-of-a-body-of-water-df9uYGbvKmo',
          wikiLink: 'https://en.wikipedia.org/wiki/Rajasthan',
        },
        landmarksRound: [
          { code: 'SS', number: 1 },
          { code: 'NR', number: 1 },
          { code: 'CD', number: 4 },
        ],
        landmarkPlaces: [
          'Shirley Heights',
          'Castillo San Felipe de Barajas',
          'Joya De Ceren Archeological Site',
          'Memorial da Escravatura e do Tráfico Negreiro',
          'Ruhyyet Palace',
          'Chishimba Falls',
          'Parthenon',
        ],
        uniqueId: 'pd7Fmwsh',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Agra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-people-in-temple-at-daytime-s34iTpkwnF0',
          wikiLink: 'https://en.wikipedia.org/wiki/Agra',
        },
        landmark: {
          name: 'Hawa Mahal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-white-and-green-long-sleeve-shirt-standing-near-brown-concrete-building-during-daytime-6rDbvXzIVpQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Hawa_Mahal',
          hasLandmark: true,
        },
        city: {
          name: 'Udaipur',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/flock-of-birds-during-daytime-KkDUWOKHOCk',
          wikiLink: 'https://en.wikipedia.org/wiki/Udaipur',
        },
        landmarksRound: [
          { code: 'GY', number: 3 },
          { code: 'AT', number: 1 },
          { code: 'GP', number: 2 },
        ],
        landmarkPlaces: [
          'Bellapais Abbey',
          'Mawson Peak',
          'Sant Joan de Caselles',
          'Ampitheater of El Jem',
          'The Basilica of Our Lady of Peace of Yamoussoukro',
          "Women's Bridge",
          'Cathedral Our Lady of the Rosary',
        ],
        uniqueId: 'KZy5kGVC',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Indonesia',
    code: 'ID',
    capital: 'Jakarta',
    lat: -5,
    long: 120,
    cities: [
      'Bandung',
      'Jakarta',
      'Semarang',
      'Bekasi City',
      'Yogyakarta',
      'Surabaya',
      'Samarinda City',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8o4W9LZv6eo',
          wikiLink: 'https://en.wikipedia.org/wiki/Kebumen_Regency',
        },
        landmark: {
          name: 'Borobudur Temple',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/borobudur-temple-indonesia-historic-743263/',
          wikiLink: 'https://en.wikipedia.org/wiki/Borobudur',
          hasLandmark: true,
        },
        city: {
          name: 'Bandung',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/morning-bandung-city-mountain-urban-7341522/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bandung',
        },
        landmarksRound: [
          { code: 'LV', number: 2 },
          { code: 'JP', number: 1 },
          { code: 'NO', number: 1 },
        ],
        landmarkPlaces: [
          'Kim Il Sung Square',
          "Memorial de l'Anse",
          'Mir Castle',
          'Neveh Shalom Synagogue',
          'Narikala Fortress',
          'Blue Grotto',
          'Iskanderkul',
        ],
        uniqueId: 'tpTpd3BF',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sea-mangrove-before-sunrise-mystery-6328687/',
          wikiLink: 'https://en.wikipedia.org/wiki/Halmahera',
        },
        landmark: {
          name: 'Prambanan Temple',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/temple-candi-prambanan-598969/',
          wikiLink: 'https://en.wikipedia.org/wiki/Prambanan',
          hasLandmark: true,
        },
        city: {
          name: 'Jakarta',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jakarta-city-indonesia-urban-4660555/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jakarta',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Hfg4MPvW',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kebumen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/three-person-riding-bikes-on-green-grass-field-8o4W9LZv6eo',
          wikiLink: 'https://en.wikipedia.org/wiki/Kebumen_Regency',
        },
        landmark: {
          name: 'Pura Ulun Danu Bratan',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/toffiloff/7775085906/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pura_Ulun_Danu_Bratan',
          hasLandmark: true,
        },
        city: {
          name: 'Jakarta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/park-with-fountain-near-buildings-mAxA2OmTmKA',
          wikiLink: 'https://en.wikipedia.org/wiki/Jakarta',
        },
        landmarksRound: [
          { code: 'MR', number: 1 },
          { code: 'GH', number: 3 },
          { code: 'EG', number: 2 },
        ],
        landmarkPlaces: [
          'Great Mosque of Asmara',
          'Big Ben',
          'Chichen Itza',
          'Arahoho Blowhole',
          'Pont du Gard',
          'Tian Tan Buddha',
          'Victoria Falls',
        ],
        uniqueId: 'SZYqDSfD',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Padar Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/woman-standing-on-mountain-near-body-of-water-Sq8rpq2KB7U',
          wikiLink: 'https://en.wikipedia.org/wiki/Padar,_Indonesia',
        },
        landmark: {
          name: 'Ceking Rice Terrace',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/7522153@N03/8069517979/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tegallalang',
          hasLandmark: true,
        },
        city: {
          name: 'Surabaya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-riding-motor-scooter-3tQbU_kqYlA',
          wikiLink: 'https://en.wikipedia.org/wiki/Surabaya',
        },
        landmarksRound: [
          { code: 'UG', number: 1 },
          { code: 'GW', number: 4 },
          { code: 'CI', number: 2 },
        ],
        landmarkPlaces: [
          'Lake Armor',
          'Tian Tan Buddha',
          'Fouta Djallon',
          'Pulhapanzak Waterfalls',
          'Fasil Ghebbi',
          'Egyptian Museum',
          'Obelisk of Axum',
        ],
        uniqueId: 'ldLWY6dL',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Indonesia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-coconut-trees-EFuCATqfblI',
          wikiLink: 'https://en.wikipedia.org/wiki/Indonesia',
        },
        landmark: {
          name: 'Mount Bromo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-photo-volcanoes-during-golden-hour-aB46yUmsMp0',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Bromo',
          hasLandmark: true,
        },
        city: {
          name: 'Jakarta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-street-filled-with-lots-of-traffic-at-night-WccVB225sd8',
          wikiLink: 'https://en.wikipedia.org/wiki/Jakarta',
        },
        landmarksRound: [
          { code: 'VC', number: 1 },
          { code: 'CM', number: 5 },
          { code: 'CK', number: 5 },
        ],
        landmarkPlaces: [
          'Mutanabbi Statue',
          'Lighthouse Dona Maria Pia',
          'Lahore Fort',
          'National Capitol of Cuba',
          'Blue Eye Cave',
          'Great Mosque of Djenné',
          'Marovo Lagoon',
        ],
        uniqueId: 'thtrnmGK',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Padar Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-view-of-body-of-water-and-island-m7eb02LR9eA',
          wikiLink: 'https://en.wikipedia.org/wiki/Padar,_Indonesia',
        },
        landmark: {
          name: 'Tegallalang Rice Terrace',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/rice-terraces-on-mountain-side-under-blue-sky-at-daytime-yAz5rCf87H8',
          wikiLink: 'https://en.wikipedia.org/wiki/Tegallalang',
          hasLandmark: true,
        },
        city: {
          name: 'Samarinda City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-palm-tree-in-front-of-a-large-building-aAnnsMLcxUg',
          wikiLink: 'https://en.wikipedia.org/wiki/Samarinda',
        },
        landmarksRound: [
          { code: 'MU', number: 1 },
          { code: 'SI', number: 4 },
          { code: 'ME', number: 1 },
        ],
        landmarkPlaces: [
          'Château de Chambord',
          'Easter Island',
          'Republic Square',
          'Paphos Archaeological Park',
          'Peel Castle',
          'The Grand Palace',
          'Cacheu Fort',
        ],
        uniqueId: 'r6BYFTCQ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Iran',
    code: 'IR',
    capital: 'Tehran',
    lat: 32,
    long: 53,
    cities: [
      'Kermanshah',
      'Mashhad',
      'Tehran',
      'Tabriz',
      'Isfahan',
      'Yazd',
      'Namakab Rud',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mbiddulph/31233739431/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nasir-ol-Molk_Mosque',
        },
        landmark: {
          name: 'Azadi Tower',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Azadi_Tower#/media/File:Azadi_Tower_(29358497718).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Azadi_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Kermanshah',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Kermanshah_Photos_M8.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kermanshah',
        },
        landmarksRound: [
          { code: 'GI', number: 1 },
          { code: 'EE', number: 2 },
          { code: 'VU', number: 1 },
        ],
        landmarkPlaces: [
          'Panfilov Park',
          'Rila Monastery',
          'Umayyad Mosque',
          'Mount Paget',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Fort Sao Sebastiao',
          'Sistine Chapel',
        ],
        uniqueId: 'BlWmcyRR',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mountain-summit-snow-winter-6734031/',
          wikiLink: 'https://en.wikipedia.org/wiki/Iran',
        },
        landmark: {
          name: 'Tomb of Hafez',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tomb_of_Hafez#/media/File:Hafez_880714_095.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tomb_of_Hafez',
          hasLandmark: true,
        },
        city: {
          name: 'Mashhad',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/town-holy-mashhad-ardehal-6780263/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mashhad',
        },
        landmarksRound: [
          { code: 'IQ', number: 1 },
          { code: 'TH', number: 1 },
          { code: 'SD', number: 1 },
        ],
        landmarkPlaces: [
          'Marcus Aurelius Arch',
          'Patuxay Monument',
          'Cape Coast Castle',
          'Bled Castle',
          'Machu Picchu',
          'Zaisan Memorial',
          "St. Tryphon's Cathedral",
        ],
        uniqueId: '5vcxpXzr',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Tehran',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-rG41gpS2FKI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tehran',
        },
        landmark: {
          name: 'Nasir al-Mulk Mosque',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/multicolored-concrete-hallway-AxhfHp6fJ2M',
          wikiLink: 'https://en.wikipedia.org/wiki/Nasir-ol-Molk_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Tehran',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/city-photography-ShZzflXymPI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tehran',
        },
        landmarksRound: [
          { code: 'VE', number: 2 },
          { code: 'VN', number: 2 },
          { code: 'ES', number: 1 },
        ],
        landmarkPlaces: [
          'Taputapuatea marae',
          'Arch 22',
          'Laferriere Citadel',
          'Parthenon',
          'Leptis Magna',
          'Tegucigalpa Cathedral',
          'Cologne Cathedral',
        ],
        uniqueId: 'Lpch5Mzb',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Abyaneh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/woman-sitting-on-brown-concrete-chair-sjFWv9FnU2Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Abyaneh',
        },
        landmark: {
          name: 'Allahverdi Khan Bridge',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/iranpic/1996528024',
          wikiLink: 'https://en.wikipedia.org/wiki/Si-o-se-pol',
          hasLandmark: true,
        },
        city: {
          name: 'Mashhad',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-skyline-under-blue-sky-during-daytime--I39NprDplQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Mashhad',
        },
        landmarksRound: [
          { code: 'FR', number: 1 },
          { code: 'PF', number: 4 },
          { code: 'VI', number: 1 },
        ],
        landmarkPlaces: [
          'Palace of Peace and Reconciliation',
          'Obelisk of Axum',
          'Castillo San Cristobal',
          'Tsavo East National Park',
          'Mont Choungui',
          'Akanda National Park',
          'Argentine Military Cemetery',
        ],
        uniqueId: '2FqdYYrB',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Tehran',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/multicolored-lion-art-on-buildings-wall-at-daytime-TxBeyjstOSg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tehran',
        },
        landmark: {
          name: 'Vakil Mosque',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-close-up-photography-P_Ne56WEe5s',
          wikiLink: 'https://en.wikipedia.org/wiki/Vakil_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Tehran',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/shanghair-oriental-pearl-tower-china-c4pe3HEhnig',
          wikiLink: 'https://en.wikipedia.org/wiki/Tehran',
        },
        landmarksRound: [
          { code: 'TM', number: 3 },
          { code: 'HM', number: 1 },
          { code: 'UA', number: 3 },
        ],
        landmarkPlaces: [
          'Gun Hill Signal Station',
          'Liberian National Museum',
          'Matafao Peak',
          'Million Dollar Point',
          "People's Palace",
          'Rotunda of Mosta',
          'Trevi Fountain',
        ],
        uniqueId: 'jDfW4nnn',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Tehran',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-wn6jWSxWDLI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tehran',
        },
        landmark: {
          name: 'Azadi Tower',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-near-brown-concrete-structures-zKlFiUcqdZQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Azadi_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Namakab Rud',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/red-cable-cars-zE5bEtieYMc',
          wikiLink: 'https://en.wikipedia.org/wiki/Namakab_Rud',
        },
        landmarksRound: [
          { code: 'GB', number: 2 },
          { code: 'MG', number: 3 },
          { code: 'AX', number: 1 },
        ],
        landmarkPlaces: [
          'Christ the Redeemer',
          'Trakai Island Castle',
          'Helsinki Cathedral',
          'Victoria Falls',
          'Åland Maritime Museum',
          'Marcus Aurelius Arch',
          'Al Fateh Grand Mosque',
        ],
        uniqueId: 'YkjxYG4J',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Iraq',
    code: 'IQ',
    capital: 'Baghdad',
    lat: 33,
    long: 44,
    cities: [
      'Mosul',
      'Erbil',
      'Baghdad',
      'Basrah',
      'Hillah',
      'Najaf',
      'Sulaymaniyah',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8eJTLS3lIiw',
          wikiLink: 'https://en.wikipedia.org/wiki/Erbil',
        },
        landmark: {
          name: 'Erbil Citadel',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Citadel_of_Erbil#/media/File:27._Part_of_the_wall_of_Erbil_Citadel,_Hawler,_Erbil_Governorate,_Iraq.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Citadel_of_Erbil',
          hasLandmark: true,
        },
        city: {
          name: 'Mosul',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:Mosul_-_Iraq.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mosul',
        },
        landmarksRound: [
          { code: 'PR', number: 1 },
          { code: 'MA', number: 1 },
          { code: 'SD', number: 1 },
        ],
        landmarkPlaces: [
          'Queen Emma Bridge',
          'Monastery of St. Naum',
          'Skopje Fortress',
          'Kim Il Sung Square',
          'Lukan Longshan Temple',
          'Hazrat Ali Mazar',
          'George Washington House',
        ],
        uniqueId: 'S8DGCrnv',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/hillah-iraq-landscape-desert-80384/',
          wikiLink: 'https://en.wikipedia.org/wiki/Iraq',
        },
        landmark: {
          name: 'Mutanabbi Statue',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mutanabbi_Street#/media/File:Al-Mutanabbi_Statue_in_Baghdad(Cropped).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mutanabbi_Street',
          hasLandmark: true,
        },
        city: {
          name: 'Erbil',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Citadel_of_Erbil#/media/File:Hawler_Castle.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Erbil',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '7NpScmTC',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Iraq',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-daytime-ysMnsNEf670',
          wikiLink: 'https://en.wikipedia.org/wiki/Iraq',
        },
        landmark: {
          name: 'Rabban Hormizd Monastery',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Rabban_Hormizd_Monastery#/media/File:Rabban_Hormizd_Monastery_-_view_from_below_(1).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Rabban_Hormizd_Monastery',
          hasLandmark: true,
        },
        city: {
          name: 'Baghdad',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-8eJTLS3lIiw',
          wikiLink: 'https://en.wikipedia.org/wiki/Baghdad',
        },
        landmarksRound: [
          { code: 'IS', number: 4 },
          { code: 'UZ', number: 2 },
          { code: 'IR', number: 1 },
        ],
        landmarkPlaces: [
          'Ben Amera',
          'Chamarel Waterfall',
          'Victoria Falls',
          'Semuc Champey',
          'Pipeline Mosque',
          'Grand Ducal Palace',
          'Mumbo Island',
        ],
        uniqueId: '3fCfBgHj',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Iraq',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-trees-and-mountains-during-daytime-Cl0Zn8Jt2BE',
          wikiLink: 'https://en.wikipedia.org/wiki/Iraq',
        },
        landmark: {
          name: "Martyr's Memorial",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/43119069@N00/325489383',
          wikiLink: 'https://en.wikipedia.org/wiki/Al-Shaheed_Monument',
          hasLandmark: true,
        },
        city: {
          name: 'Mosul',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-crossing-a-bridge-over-a-body-of-water-CYELoVcHvPo',
          wikiLink: 'https://en.wikipedia.org/wiki/Mosul',
        },
        landmarksRound: [
          { code: 'HN', number: 4 },
          { code: 'IT', number: 2 },
          { code: 'GT', number: 2 },
        ],
        landmarkPlaces: [
          "Two Lover's Point",
          'Pulhapanzak Waterfalls',
          'Stephen the Great Monument',
          'Chinguetti',
          'Day Forest National Park',
          'Sri Siva Subramaniya Temple',
          'Mata-Utu Cathedral',
        ],
        uniqueId: 'RKlFTR9t',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Samawah',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-and-trees-near-river-during-daytime-sn00xBZqYaw',
          wikiLink: 'https://en.wikipedia.org/wiki/Samawah',
        },
        landmark: {
          name: 'Imam Husayn Shrine',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-sunset-view-of-a-city-with-tall-buildings-E0RryWDcsWw',
          wikiLink:
            'https://en.wikipedia.org/wiki/Imam_Husayn_Shrine#:~:text=The%20Imam%20Husayn%20Shrine%20(Arabic,the%20city%20of%20Karbala%2C%20Iraq.',
          hasLandmark: true,
        },
        city: {
          name: 'Sulaymaniyah',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-with-high-rise-buildings-under-blue-sky-during-daytime-XM7KjbJ6Ggw',
          wikiLink: 'https://en.wikipedia.org/wiki/Sulaymaniyah',
        },
        landmarksRound: [
          { code: 'HN', number: 2 },
          { code: 'ME', number: 4 },
          { code: 'DZ', number: 3 },
        ],
        landmarkPlaces: [
          'Kome Caves',
          'Sukhbaatar Square',
          'Sao Filipe Royale Fortress',
          'Cheshire Hall Plantation',
          'Leaning Tower of Pisa',
          'Frederiksborg Castle',
          "St. Stephen's Cathedral",
        ],
        uniqueId: 'TGWJ8tRP',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Karbala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-sun-is-setting-over-a-city-with-tall-buildings-eHUkCuolVX4',
          wikiLink: 'https://en.wikipedia.org/wiki/Karbala',
        },
        landmark: {
          name: 'Great Mosque of Kufa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/bchoi9999/26441892242/',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Mosque_of_Kufa',
          hasLandmark: true,
        },
        city: {
          name: 'Najaf',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-cement-benches-sitting-next-to-each-other-9n_aKGnlXF8',
          wikiLink: 'https://en.wikipedia.org/wiki/Najaf',
        },
        landmarksRound: [
          { code: 'WF', number: 2 },
          { code: 'TC', number: 4 },
          { code: 'KR', number: 2 },
        ],
        landmarkPlaces: [
          'Basilica of Mary Queen of Apostles',
          'Bikini Atoll',
          'Richat Structure',
          'A-Ma Temple',
          'Semuc Champey',
          'National Pantheon',
          'Palace of the Grand Master of the Knights of Rhodes',
        ],
        uniqueId: 'XcTmSjMV',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Ireland',
    code: 'IE',
    capital: 'Dublin',
    lat: 53,
    long: -8,
    cities: [
      'Galway',
      'Dublin',
      'Waterford',
      'Ennis',
      'Kilkenny',
      'Cork',
      'Limerick',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/FxMR4Dp9rl0',
          wikiLink: 'https://en.wikipedia.org/wiki/Glendalough_Upper_Lake',
        },
        landmark: {
          name: 'Cliffs of Moher',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cliff-of-moher-cliff-ireland-4520630/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cliffs_of_Moher',
          hasLandmark: true,
        },
        city: {
          name: 'Cork',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cork_(city)#/media/File:Patrick_Street,_Cork,_January_2018.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cork_(city)',
        },
        landmarksRound: [
          { code: 'JM', number: 2 },
          { code: 'CO', number: 1 },
          { code: 'JO', number: 1 },
        ],
        landmarkPlaces: [
          'Lukan Longshan Temple',
          'Wat Arun',
          'Mir Castle',
          'Temple of Literature',
          'Panfilov Park',
          'The Blue Eye',
          'Bahrain Fort',
        ],
        uniqueId: 'w6mMbZLy',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/beach-lonely-hike-ireland-sea-5043569/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ireland',
        },
        landmark: {
          name: 'Rock of Cashel',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rock-or-cashel-ireland-2633048/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rock_of_Cashel',
          hasLandmark: true,
        },
        city: {
          name: 'Galway',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/galway-ireland-galway-city-city-5194111/',
          wikiLink: 'https://en.wikipedia.org/wiki/Galway',
        },
        landmarksRound: [
          { code: 'UA', number: 1 },
          { code: 'LK', number: 1 },
          { code: 'DK', number: 1 },
        ],
        landmarkPlaces: [
          'The Blue Mosque',
          'Vaduz Cathedral',
          'Amberd',
          'Pamir Mountains',
          'Soroca Fort',
          'Golubac Fortress',
          'Registan Square',
        ],
        uniqueId: 'Jf4RH6ds',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cliffs of Moher',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/cliff-near-sea-ci9HzWTIVas',
          wikiLink: 'https://en.wikipedia.org/wiki/Cliffs_of_Moher',
        },
        landmark: {
          name: 'Doonagore Castle, Doolin, Ireland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-castle-tower-on-mountain-shore-during-day-4Oi1756LtF4',
          wikiLink: 'https://en.wikipedia.org/wiki/Doonagore_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Dublin',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-heading-towards-church-KT4dOfvtZSg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dublin',
        },
        landmarksRound: [
          { code: 'GF', number: 2 },
          { code: 'SZ', number: 1 },
          { code: 'TN', number: 2 },
        ],
        landmarkPlaces: [
          'Church of Our Lady of the Rosary',
          'Hvalsey Church',
          'Semuc Champey',
          'Katse Dam',
          'Viru Gate',
          'Abu Simbel Temples',
          'Stone House',
        ],
        uniqueId: 'N4SKM5zD',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Dingle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-red-lighthouse-near-green-grass-field-under-blue-sky-during-daytime-M87gaY34Ac4',
          wikiLink: 'https://en.wikipedia.org/wiki/Dingle',
        },
        landmark: {
          name: 'Kylemore Abbey',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/49299654427',
          wikiLink: 'https://en.wikipedia.org/wiki/Kylemore_Abbey',
          hasLandmark: true,
        },
        city: {
          name: 'Cork',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-skyline-during-night-time-Nc0BZAOF-10',
          wikiLink: 'https://en.wikipedia.org/wiki/Cork',
        },
        landmarksRound: [
          { code: 'MC', number: 2 },
          { code: 'GI', number: 1 },
          { code: 'GR', number: 4 },
        ],
        landmarkPlaces: [
          'Kunta Kinteh Island',
          'Brandenburg Gate',
          'St. Peter the Apostle Cathedral',
          'Sibebe Rock',
          'Fort Ceperou',
          'Ocean Park',
          "Lac 'Assal",
        ],
        uniqueId: '7BChgsk2',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Fintown',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-house-beside-fence-and-tree-during-daytime-BUkgxEF2q7c',
          wikiLink: 'https://en.wikipedia.org/wiki/Fintown',
        },
        landmark: {
          name: 'Doonagore Castle, Doolin, Ireland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-structure-surrounded-by-green-trees-d6u2_u02FIo',
          wikiLink: 'https://en.wikipedia.org/wiki/Doonagore_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Dublin',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-houses-tnzzr8HpLhs',
          wikiLink: 'https://en.wikipedia.org/wiki/Dublin',
        },
        landmarksRound: [
          { code: 'TN', number: 2 },
          { code: 'UY', number: 2 },
          { code: 'FR', number: 2 },
        ],
        landmarkPlaces: [
          'Basilique Sainte Anne du Congo',
          'Cristo de La Misericordia',
          'Amberd',
          'Regina Mundi Cathedral',
          'Erta Ale',
          'Terrace of the Elephants',
          'Mumbo Island',
        ],
        uniqueId: 'tkrKdZZW',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Ireland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-green-leafed-trees-8X19catOuNI',
          wikiLink: 'https://en.wikipedia.org/wiki/Ireland',
        },
        landmark: {
          name: 'Cliffs of Moher',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-rock-formation-with-body-of-water-ndyYf_qU5Zk',
          wikiLink: 'https://en.wikipedia.org/wiki/Cliffs_of_Moher',
          hasLandmark: true,
        },
        city: {
          name: 'Limerick',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bridge-over-a-river-TSdGLQ7Z5YI',
          wikiLink: 'https://en.wikipedia.org/wiki/Limerick',
        },
        landmarksRound: [
          { code: 'LU', number: 3 },
          { code: 'WF', number: 3 },
          { code: 'BW', number: 3 },
        ],
        landmarkPlaces: [
          'Cheshire Hall Plantation',
          'Two Lovers Point',
          "Tassili n'Ajjer",
          'Matafao Peak',
          'El Yunque National Forest',
          'Lake Assal ',
          'CCTV Headquarters',
        ],
        uniqueId: 'z6VNn9sW',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Isle of Man',
    code: 'IM',
    capital: 'Douglas',
    lat: 54.25,
    long: -4.5,
    cities: ['Douglas', 'Port Erin', 'Castletown', 'Ramsey', 'Laxey', 'Peel'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/UF-F4mSV8VA',
          wikiLink: 'https://en.wikipedia.org/wiki/Bradda_Head',
        },
        landmark: {
          name: 'Castle Rushden',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Castle_Rushen#/media/File:Castle_Rushen.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Castle_Rushen',
          hasLandmark: true,
        },
        city: {
          name: 'Port Erin',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Port_Erin,_Isle_of_Man,_May,_2017-2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Erin',
        },
        landmarksRound: [
          { code: 'IQ', number: 1 },
          { code: 'KG', number: 2 },
          { code: 'EG', number: 1 },
        ],
        landmarkPlaces: [
          'Motherland Monument',
          'Fasil Ghebbi',
          'Predjama Castle',
          'Koutoubia',
          'South Pole (ceremonial)',
          'Grandvalira',
          'Tegucigalpa Cathedral',
        ],
        uniqueId: 'LWRJV2Qk',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sunrise-snow-mountains-sunlight-5882167/',
          wikiLink: 'https://en.wikipedia.org/wiki/Isle_of_Man',
        },
        landmark: {
          name: 'Peel Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Peel_Castle#/media/File:Peel_Castle_cathedral.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Peel_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Castletown',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/brighton/3886816038',
          wikiLink: 'https://en.wikipedia.org/wiki/Castletown,_Isle_of_Man',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'kqYDVWqW',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Isle of Man',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-and-brown-rock-formation-near-body-of-water-during-daytime-SFHB4DPU134',
          wikiLink: 'https://en.wikipedia.org/wiki/Isle_of_Man',
        },
        landmark: {
          name: 'Peel Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Peel_Castle#/media/File:Isle_of_Man_Peel_Castle.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Peel_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Douglas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/timelapse-photo-of-fireworks-EshO8jMo3hI',
          wikiLink: 'https://en.wikipedia.org/wiki/Douglas,_Isle_of_Man',
        },
        landmarksRound: [
          { code: 'GQ', number: 2 },
          { code: 'GY', number: 4 },
          { code: 'KG', number: 2 },
        ],
        landmarkPlaces: [
          'Saunders Island',
          'Stephen the Great Monument',
          'Sistine Chapel',
          'Cintra Bay',
          'Liwonde National Park',
          'Cape Coast Castle',
          'Rock of Gibraltar',
        ],
        uniqueId: '8vKKrk7W',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Douglas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-light-house-sitting-on-top-of-a-lush-green-hillside-8FNdV_g7MXI',
          wikiLink: 'https://en.wikipedia.org/wiki/Douglas,_Isle_of_Man',
        },
        landmark: {
          name: 'Laxey Wheel',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Laxey_Wheel#/media/File:Laxey_Wheel,_September_2015.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Laxey_Wheel',
          hasLandmark: true,
        },
        city: {
          name: 'Peel',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Peel,_Isle_of_Man#/media/File:IOM_Peel_by_malost.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Peel,_Isle_of_Man',
        },
        landmarksRound: [
          { code: 'FJ', number: 2 },
          { code: 'UG', number: 2 },
          { code: 'ME', number: 1 },
        ],
        landmarkPlaces: [
          'Allahverdi Khan Bridge',
          'Abuna Yemata Guh',
          'Islamic Center',
          'Sibebe Rock',
          'Grand Ducal Palace',
          'Vilnius Cathedral',
          "Ruins of St. Paul's",
        ],
        uniqueId: 'MHwprvtn',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Peel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/anchor-on-grass-shore-during-day-fXzcEptNAmQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Peel,_Isle_of_Man',
        },
        landmark: {
          name: 'Bradda Head',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-small-tower-on-a-hill-with-stars-in-the-sky-vLBRrzxUDHY',
          wikiLink: 'https://en.wikipedia.org/wiki/Bradda_Head',
          hasLandmark: true,
        },
        city: {
          name: 'Peel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-near-body-of-water-during-daytime-0ubIurK59MA',
          wikiLink: 'https://en.wikipedia.org/wiki/Peel,_Isle_of_Man',
        },
        landmarksRound: [
          { code: 'CA', number: 3 },
          { code: 'BT', number: 5 },
          { code: 'NP', number: 3 },
        ],
        landmarkPlaces: [
          'Niagara Falls',
          'Ilulissat Icefjord',
          'Shiwa Ngandu',
          'Amberd',
          'Etoile rouge',
          'Allahverdi Khan Bridge',
          'Souq Waqif',
        ],
        uniqueId: 'f3BYyxfz',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Isle of Man',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-bridge-during-daytime-_t-8ters_eg',
          wikiLink: 'https://en.wikipedia.org/wiki/Isle_of_Man',
        },
        landmark: {
          name: 'Point of Ayre Lighthouse',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/zacerin/35960545653/',
          wikiLink: 'https://en.wikipedia.org/wiki/Point_of_Ayre_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Castletown',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-red-boat-on-water-near-gray-concrete-building-during-daytime-YAjZgdre_LI',
          wikiLink: 'https://en.wikipedia.org/wiki/Castletown,_Isle_of_Man',
        },
        landmarksRound: [
          { code: 'SI', number: 4 },
          { code: 'DE', number: 2 },
          { code: 'LI', number: 1 },
        ],
        landmarkPlaces: [
          'Egyptian Museum',
          'Phoenix Islands Protected Area',
          'Blue Lagoon',
          'Crystal Caves',
          'Qalito Island',
          'Basilique Marie-Reine-des-Apôtres',
          'Durrell Wildlife Park',
        ],
        uniqueId: 'm48XBPvr',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Israel',
    code: 'IL',
    capital: 'Jerusalem',
    lat: 31.47,
    long: 35.13,
    cities: [
      'Haifa',
      'Tel Aviv',
      'Beersheba',
      'Ashdod',
      'Netanya',
      'Rehovot',
      'Jerusalem',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yBJuiaMHlsk',
          wikiLink: 'https://en.wikipedia.org/wiki/Tel_Aviv',
        },
        landmark: {
          name: 'Western Wall and the Dome of the Rock',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jerusalem-western-wall-temple-4592574/',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Wall',
          hasLandmark: false,
        },
        city: {
          name: 'Tel Aviv',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tel-aviv-city-beach-coast-sea-7626789/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tel_Aviv',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'RcgPjFdT',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/israel-jerusalem-jewish-palestine-4426385/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerusalem',
        },
        landmark: {
          name: '',
          photographer: '',
          imageLink: '',
          wikiLink: '',
          hasLandmark: false,
        },
        city: {
          name: 'Haifa',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/stairs-park-rise-staircase-2999277/',
          wikiLink: 'https://en.wikipedia.org/wiki/Haifa',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '8TJMr92D',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Masada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-during-sunset-Nh8bXxdP0DI',
          wikiLink: 'https://en.wikipedia.org/wiki/Masada',
        },
        landmark: {
          name: 'Tower of David',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tower_of_David#/media/File:DavidTower.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tower_of_David',
          hasLandmark: true,
        },
        city: {
          name: 'Haifa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-photo-of-city-aOTWuZN9eoc',
          wikiLink: 'https://en.wikipedia.org/wiki/Haifa',
        },
        landmarksRound: [
          { code: 'GM', number: 1 },
          { code: 'FO', number: 3 },
          { code: 'HT', number: 2 },
        ],
        landmarkPlaces: [
          'Metropolitan Cathedral of San Salvador',
          'Cathedral Our Lady of the Rosary',
          'Mawson Peak',
          'Tarawa',
          'Grace Bay Beach',
          'Columbus Park',
          'Cape Coast Castle',
        ],
        uniqueId: '6R8HPhXd',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Israel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/yellow-petaled-flower-field-at-daytime-g_Abgn1slAM',
          wikiLink: 'https://en.wikipedia.org/wiki/Israel',
        },
        landmark: {
          name: 'Baháʼí Terraces',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Terraces_(Bah%C3%A1%CA%BC%C3%AD)#/media/File:TerracesBenGurion2.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Terraces_(Bah%C3%A1%CA%BC%C3%AD)',
          hasLandmark: true,
        },
        city: {
          name: 'Tel Aviv',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-sitting-on-green-grass-near-body-of-water-during-daytime-hnxba71v7T8',
          wikiLink: 'https://en.wikipedia.org/wiki/Tel_Aviv',
        },
        landmarksRound: [
          { code: 'HN', number: 4 },
          { code: 'DO', number: 3 },
          { code: 'CI', number: 1 },
        ],
        landmarkPlaces: [
          'Vilnius Cathedral',
          'Palace of Peace and Reconciliation',
          'Tavarua',
          "St. Peter's Church",
          'Tsingy De Bemaraha',
          'Tokyo Skytree',
          'Grace Bay Beach',
        ],
        uniqueId: 'J3Q3hysP',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Israel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-near-sea-at-daytime-tArR7ys2F3Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Israel',
        },
        landmark: {
          name: 'Ein Gedi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-and-white-body-of-water-near-forest-gFSqwxMTYXw',
          wikiLink: 'https://en.wikipedia.org/wiki/Ein_Gedi',
          hasLandmark: true,
        },
        city: {
          name: 'Jerusalem',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-mosquie-ozxwRFG4aD8',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerusalem',
        },
        landmarksRound: [
          { code: 'BO', number: 1 },
          { code: 'UG', number: 3 },
          { code: 'HR', number: 6 },
        ],
        landmarkPlaces: [
          'Qalito Island',
          'Les Eclaireurs Lighthouse',
          'Talietumu',
          'Dolly Beach',
          'The Grand Palace',
          'Tsodilo Hills',
          'Cape Coast Castle',
        ],
        uniqueId: '6YNb4Tjq',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Jerusalem',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-veiw-photography-of-dome-of-the-rock-jerusalem-QsU8GRwyS3k',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerusalem',
        },
        landmark: {
          name: 'Capernaum',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/concrete-structure-with-black-texts-gWrxqChFf1Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Capernaum',
          hasLandmark: true,
        },
        city: {
          name: 'Tel Aviv',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-seashore-under-gray-sky-during-daytime-Molhe1qQRWI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tel_Aviv',
        },
        landmarksRound: [
          { code: 'SG', number: 1 },
          { code: 'GE', number: 3 },
          { code: 'VI', number: 1 },
        ],
        landmarkPlaces: [
          'Al Bastakiya',
          'Koutoubia',
          'Trevi Fountain',
          'Molinere Underwater Sculpture Park',
          'Basilica of Our Lady of the Angels, Cartago',
          'Kastelholm Castle',
          'Gun Hill Signal Station',
        ],
        uniqueId: 'HxBWZmbr',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Italy',
    code: 'IT',
    capital: 'Rome',
    lat: 42.83333333,
    long: 12.83333333,
    cities: [
      'Milan',
      'Venice',
      'Naples',
      'Verona',
      'Florence',
      'Bologna',
      'Rome',
      'Parma',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/r9RW20TrQ0Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuscany',
        },
        landmark: {
          name: 'Trevi Fountain',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/trevi-fountain-fontana-di-trevi-19688/',
          wikiLink: 'https://en.wikipedia.org/wiki/Trevi_Fountain',
          hasLandmark: true,
        },
        city: {
          name: 'Florence',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/italy-florence-church-tuscany-4256018/',
          wikiLink: 'https://en.wikipedia.org/wiki/Florence',
        },
        landmarksRound: [
          { code: 'MZ', number: 2 },
          { code: 'MY', number: 2 },
          { code: 'IE', number: 2 },
        ],
        landmarkPlaces: [
          'Xunatunich Mayan Ruins',
          'Golden Gate Bridge',
          'Hagia Sophia',
          'Ruhyyet Palace',
          'Ampitheater of El Jem',
          'Registan Square',
          'Temple of Literature',
        ],
        uniqueId: 'xflMyCVg',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/village-sea-coast-coastline-279013/',
          wikiLink: 'https://en.wikipedia.org/wiki/Liguria',
        },
        landmark: {
          name: 'Leaning Tower of Pisa',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/pisa-leaning-tower-unintended-tilt-1056568/',
          wikiLink: 'https://en.wikipedia.org/wiki/Leaning_Tower_of_Pisa',
          hasLandmark: true,
        },
        city: {
          name: 'Milan',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-center-street-milan-italy-705879/',
          wikiLink: 'https://en.wikipedia.org/wiki/Milan',
        },
        landmarksRound: [
          { code: 'BR', number: 1 },
          { code: 'AQ', number: 2 },
          { code: 'KH', number: 2 },
        ],
        landmarkPlaces: [
          'Voortrekker Monument',
          'Magnus Cathedral',
          'Roman theatre',
          'Tian Tan Buddha',
          'Chichen Itza',
          'Kathmandu Durbar Square',
          'Kittiwake Shipwreck',
        ],
        uniqueId: '5gyQN8RJ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Portofino',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-house-near-sea-during-daytime-EZT6qusWOBQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Portofino',
        },
        landmark: {
          name: 'Roman Forum',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Roman_Forum#/media/File:Forum_romanum_6k_(5760x2097).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Roman_Forum',
          hasLandmark: true,
        },
        city: {
          name: 'Milan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/reflective-photography-of-string-lights-above-river--YzMZYqwoH4',
          wikiLink: 'https://en.wikipedia.org/wiki/Milan',
        },
        landmarksRound: [
          { code: 'SZ', number: 4 },
          { code: 'LT', number: 2 },
          { code: 'LT', number: 1 },
        ],
        landmarkPlaces: [
          'Molinere Underwater Sculpture Park',
          'Prambanan Temple',
          'Cliffs of Moher',
          'Argentine Military Cemetery',
          'Mont Choungui',
          'Santa Catalina Arch',
          'Tsavo East National Park',
        ],
        uniqueId: '4xzJgkfm',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Italy',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/road-between-green-grass-field-near-mountains-under-blue-and-brown-sky-at-golden-hour-r9RW20TrQ0Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Italy',
        },
        landmark: {
          name: 'Verona Arena',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Verona_Arena#/media/File:Arena_Anfiteatro.XE3F1912a.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Verona_Arena',
          hasLandmark: true,
        },
        city: {
          name: 'Bologna',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-photography-of-city-vXpcpTl2Tt4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bologna',
        },
        landmarksRound: [
          { code: 'IS', number: 3 },
          { code: 'LV', number: 1 },
          { code: 'KZ', number: 1 },
        ],
        landmarkPlaces: [
          'Doonagore Castle, Doolin, Ireland',
          'Múlafossur Waterfall',
          'Arc de Triomphe',
          'Solis Theater',
          'Salvo Palace',
          'Detwah Lagoon',
          'Plateau Mosque',
        ],
        uniqueId: 'XDdXW593',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Venice',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/venice-grand-canal-italy-1naE8177_bI',
          wikiLink: 'https://en.wikipedia.org/wiki/Venice',
        },
        landmark: {
          name: 'Florence Cathedral',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-dome-building-during-daytime-pRU-VnBVJMQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Florence_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Parma',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/door-house-and-windows-closed-at-daytime-FDNNXesr-GY',
          wikiLink: 'https://en.wikipedia.org/wiki/Parma',
        },
        landmarksRound: [
          { code: 'TO', number: 1 },
          { code: 'IQ', number: 4 },
          { code: 'SM', number: 2 },
        ],
        landmarkPlaces: [
          'Uyuni Salt Flat',
          'Fiat Tagliero',
          'Church of Our Lady of the Rosary',
          'Big Ben',
          'Lotus Temple',
          'Baiterek',
          "St. Joseph's Cathedral",
        ],
        uniqueId: '3FZm3LDW',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Tuscany',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/house-in-middle-of-grass-field-M0OIyN5u8ZM',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuscany',
        },
        landmark: {
          name: 'Roman Forum',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-t6BTXRe5BRc',
          wikiLink: 'https://en.wikipedia.org/wiki/Roman_Forum',
          hasLandmark: true,
        },
        city: {
          name: 'Florence',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-holding-umbrella-walking-on-road-fo1O6INiE2Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Florence',
        },
        landmarksRound: [
          { code: 'FI', number: 4 },
          { code: 'BI', number: 5 },
          { code: 'VE', number: 3 },
        ],
        landmarkPlaces: [
          'Ducie Island',
          'Vatican Necropolis',
          'The Basilica of Our Lady of Peace of Yamoussoukro',
          'Sagrada Família',
          'Stone House',
          'Christ the Redeemer',
          'Kyrenia Castle',
        ],
        uniqueId: 'Vmtfj3Kj',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Ivory Coast',
    code: 'CI',
    capital: 'Yamoussoukro',
    lat: 8,
    long: -5,
    cities: ['Abidjan', 'Bouaké', 'Yamoussoukro', 'Abobo', 'Daloa', 'Duekoue'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/africa-ivory-coast-lakes-6289446/',
          wikiLink: 'https://en.wikipedia.org/wiki/Yamoussoukro',
        },
        landmark: {
          name: 'The Basilica of Our Lady of Peace of Yamoussoukro',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Peace#/media/File:Notre_dame_de_la_paix_yamoussoukro_by_felix_krohn.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Peace',
          hasLandmark: true,
        },
        city: {
          name: 'Abidjan',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/abidjan-plateau-city-road-taxi-4700184/',
          wikiLink: 'https://en.wikipedia.org/wiki/Abidjan',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'W3tdRtTk',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/children-african-portrait-youth-5445501/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ivory_Coast',
        },
        landmark: {
          name: 'Plateau Mosque',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:MosquePlateauAbidjan2016_1.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Plateau,_Ivory_Coast',
          hasLandmark: true,
        },
        city: {
          name: 'Bouake',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Bouak%C3%A9_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bouak%C3%A9',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'v2BDQLTG',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Ivory Coast',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-van-at-road-during-daytime-NpDn3goZGRs',
          wikiLink: 'https://en.wikipedia.org/wiki/Ivory_Coast',
        },
        landmark: {
          name: 'Basilica of Our Lady of Peace',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Peace#/media/File:Notre_dame_de_la_paix_yamoussoukro_by_felix_krohn.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Peace',
          hasLandmark: true,
        },
        city: {
          name: 'Abidjan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-2-aMoIuTXd4',
          wikiLink: 'https://en.wikipedia.org/wiki/Abidjan',
        },
        landmarksRound: [
          { code: 'LA', number: 2 },
          { code: 'GM', number: 2 },
          { code: 'HK', number: 4 },
        ],
        landmarkPlaces: [
          'Arch 22',
          'Baiterek',
          'Two Lovers Point',
          'Basilica of Our Lady of Guadalupe',
          'The Little Chapel',
          "Fa'aruma'i Waterfalls",
          'Day Forest National Park',
        ],
        uniqueId: 'T4gKt5lT',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Ivory Coast',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-red-polo-shirt-holding-yellow-banana-bf7m8Et8ZW8',
          wikiLink: 'https://en.wikipedia.org/wiki/Ivory_Coast',
        },
        landmark: {
          name: 'National Museum of Costume',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/brennwald/52044810811/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Mus%C3%A9e_national_du_costume_de_Grand-Bassam',
          hasLandmark: true,
        },
        city: {
          name: 'Bouaké',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Bouak%C3%A9#/media/File:Bouak%C3%A9_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bouak%C3%A9',
        },
        landmarksRound: [
          { code: 'GD', number: 2 },
          { code: 'SZ', number: 3 },
          { code: 'GY', number: 2 },
        ],
        landmarkPlaces: [
          'Mont Choungui',
          'Champagne Reef, Dominica',
          "Dunn's River Falls",
          'Fort Napoléon des Saintes',
          'Shoes on the Danube Bank',
          'Lope National Park',
          'Gateway Arch',
        ],
        uniqueId: 'wZGLSQ7T',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Ivory Coast',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/abqbiopark/49358662271/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ivory_Coast',
        },
        landmark: {
          name: 'Basilica of Our Lady of Peace',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-and-woman-walking-on-gray-concrete-pathway-during-daytime-inyplWQ_P-8',
          wikiLink:
            'https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Peace',
          hasLandmark: true,
        },
        city: {
          name: 'Abidjan',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/villagestoryteller/48865960892/',
          wikiLink: 'https://en.wikipedia.org/wiki/Abidjan',
        },
        landmarksRound: [
          { code: 'GT', number: 4 },
          { code: 'MX', number: 2 },
          { code: 'FR', number: 2 },
        ],
        landmarkPlaces: [
          'Rarotonga',
          'Sundarbans',
          'Fort Santiago',
          "St. Elizabeth's Cathedral",
          'English Harbour',
          'Kim Il Sung Square',
          'The Belize Zoo',
        ],
        uniqueId: 'lkLzWpGk',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Ivory Coast',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/johnveerkamp/50233577818/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ivory_Coast',
        },
        landmark: {
          name: "St. Paul's Cathedral",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/villagestoryteller/48865762851/',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Paul%27s_Cathedral,_Abidjan',
          hasLandmark: true,
        },
        city: {
          name: 'Bouaké',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/fchmksfkcb/53506070444/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bouak%C3%A9',
        },
        landmarksRound: [
          { code: 'CF', number: 1 },
          { code: 'GE', number: 5 },
          { code: 'MN', number: 1 },
        ],
        landmarkPlaces: [
          'Juba Bridge',
          'Castle Rushden',
          'Lopé National Park',
          'Temppeliaukio Church',
          "Memorial de l'Anse",
          'St. Francis of Assisi Cathedral',
          "Fisherman's Bastion",
        ],
        uniqueId: 'k2QNJtc5',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Jamaica',
    code: 'JM',
    capital: 'Kingston',
    lat: 18.25,
    long: -77.5,
    cities: [
      'Montego Bay',
      'Port Antonio',
      'Spanish Town',
      'Portmore',
      'Mandeville',
      'Kingston',
      'Ocho Rios',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/wGyHvtLcW_U',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamaica',
        },
        landmark: {
          name: "Dunn's River Falls",
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jamaica-dunn-s-river-falls-to-travel-2695087/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dunn%27s_River_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Kingston',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Northern_suburbs_of_Kingston,_Jamaica.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kingston,_Jamaica',
        },
        landmarksRound: [
          { code: 'SD', number: 2 },
          { code: 'GG', number: 2 },
          { code: 'BH', number: 1 },
        ],
        landmarkPlaces: [
          "St. Peter's Church",
          'Tsingy De Bemaraha',
          'Aleppo Citadel',
          'Annandale Waterfall',
          'Luis I Bridge',
          'Matobo National Park',
          'Tomb of Hafez',
        ],
        uniqueId: '6scPkgm7',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jamaica-negril-beach-boat-2409533/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamaica',
        },
        landmark: {
          name: 'Green Grotto Caves',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Green_Grotto_Caves_-_Jamaica.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Green_Grotto_Caves',
          hasLandmark: true,
        },
        city: {
          name: 'Montego Bay',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Montego_Bay#/media/File:Montego_bay-1001.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Montego_Bay',
        },
        landmarksRound: [
          { code: 'BZ', number: 2 },
          { code: 'ZA', number: 1 },
          { code: 'MC', number: 1 },
        ],
        landmarkPlaces: [
          'Gangtey Monastery',
          'Registan Square',
          'Szechenyi Thermal Bath',
          'Katse Dam',
          'Monday Falls',
          'Kaaba',
          'English Harbour',
        ],
        uniqueId: 'wHnTvftT',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Jamaica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-bus-near-palm-tree-under-blue-sky-during-daytime-wGyHvtLcW_U',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamaica',
        },
        landmark: {
          name: 'Blue Mountains',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Blue_Mountains_(Jamaica)#/media/File:Blue_Mountains,_Jamaica.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Blue_Mountains_(Jamaica)',
          hasLandmark: true,
        },
        city: {
          name: 'Kingston',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Kingston,_Jamaica#/media/File:JM_Kingston_1010_(13)_(16634559824).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kingston,_Jamaica',
        },
        landmarksRound: [
          { code: 'LB', number: 2 },
          { code: 'FK', number: 4 },
          { code: 'DM', number: 4 },
        ],
        landmarkPlaces: [
          'Mosta Rotunda',
          'Sanctuaire de Notre-Dame des Larmes',
          'Saunders Island',
          'Fort Cépérou',
          'Registan Square',
          'East Side Gallery',
          'Meteora',
        ],
        uniqueId: 'QG4hDpPT',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Jamaica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-sunset-K6ig8dtGqPU',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamaica',
        },
        landmark: {
          name: "Dunn's River Falls",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dunn%27s_River_Falls#/media/File:Dunns_River_Falls_climb.JPG',
          wikiLink: "https://en.wikipedia.org/wiki/Dunn's_River_Falls",
          hasLandmark: true,
        },
        city: {
          name: 'Montego Bay',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Montego_Bay#/media/File:Montego_Bay_Photo_D_Ramey_Logan.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Montego_Bay',
        },
        landmarksRound: [
          { code: 'UG', number: 1 },
          { code: 'UZ', number: 2 },
          { code: 'GP', number: 3 },
        ],
        landmarkPlaces: [
          'Kpatawee Falls',
          'Allahverdi Khan Bridge',
          'Viru Gate',
          'Mont Choungui',
          'Mata-Utu Cathedral',
          'Blue Grotto',
          'Liberation Tower',
        ],
        uniqueId: 'fLDsJSzX',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Negril',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-black-shirt-riding-bicycle-near-palm-tree-during-daytime-gksePWvWcWc',
          wikiLink: 'https://en.wikipedia.org/wiki/Negril',
        },
        landmark: {
          name: 'Blue Mountains',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountain-under-blue-sky-BXk-N2fPLYo',
          wikiLink: 'https://en.wikipedia.org/wiki/Blue_Mountains_(Jamaica)',
          hasLandmark: true,
        },
        city: {
          name: 'Ocho Rios',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-beach-during-daytime-WAP2_qkTe18',
          wikiLink: 'https://en.wikipedia.org/wiki/Ocho_Rios',
        },
        landmarksRound: [
          { code: 'HT', number: 2 },
          { code: 'DZ', number: 2 },
          { code: 'BM', number: 4 },
        ],
        landmarkPlaces: [
          'Reunification Monument',
          'Plage de Grande Anse',
          'Chamarel Waterfall',
          'Nizwa Fort',
          'Fiji Museum',
          'Pula Arena',
          'Mlilwane Wildlife Sanctuary',
        ],
        uniqueId: 'qfYDhf4p',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Jamaica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-docked-beside-trees-on-river-jIk3I_e16uw',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamaica',
        },
        landmark: {
          name: 'Rose Hall',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/catzrule/52006032606/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rose_Hall,_Montego_Bay',
          hasLandmark: true,
        },
        city: {
          name: 'Montego Bay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-lights-at-night-VoxYYcQ3QlU',
          wikiLink: 'https://en.wikipedia.org/wiki/Montego_Bay',
        },
        landmarksRound: [
          { code: 'HM', number: 2 },
          { code: 'LS', number: 1 },
          { code: 'EG', number: 6 },
        ],
        landmarkPlaces: [
          'La Moneda Palace',
          'Mawson Peak',
          'The Baths',
          'South Pole',
          'Valle de Cocora',
          'Kim Il Sung Square',
          'Ten Thousand Buddhas Monastery',
        ],
        uniqueId: 'FfPLJw2m',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Japan',
    code: 'JP',
    capital: 'Tokyo',
    lat: 36,
    long: 138,
    cities: [
      'Osaka',
      'Tokyo',
      'Yokohama',
      'Sapporo',
      'Fukuoka',
      'Kyoto',
      'Taitō',
      'Shibuya',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8sOZJ8JF0S8',
          wikiLink: 'https://en.wikipedia.org/wiki/Meguro_River',
        },
        landmark: {
          name: 'Tokyo Skytree',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tokyo-sky-tree-japan-cityscape-2086596/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokyo_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Kyoto',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/street-road-buildings-japan-kyoto-7717463/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyoto',
        },
        landmarksRound: [
          { code: 'ID', number: 1 },
          { code: 'PR', number: 1 },
          { code: 'GG', number: 1 },
        ],
        landmarkPlaces: [
          'Panfilov Park',
          'Marina Bay Sands',
          'Stone House',
          'Bellapais Abbey',
          'Hagia Sophia',
          'Christ the Redeemer',
          'Bahrain Fort',
        ],
        uniqueId: 'pC9jkvMf',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rice-terraces-sunset-mountains-2389023/',
          wikiLink: 'https://en.wikipedia.org/wiki/Japan',
        },
        landmark: {
          name: 'Senso-ji',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sens%c5%8d-ji-temple-kyoto-japan-1437734/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sens%C5%8D-ji',
          hasLandmark: true,
        },
        city: {
          name: 'Osaka',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/japan-osaka-pedestrians-crossing-2014616/',
          wikiLink: 'https://en.wikipedia.org/wiki/Osaka',
        },
        landmarksRound: [
          { code: 'CH', number: 2 },
          { code: 'KZ', number: 1 },
          { code: 'MU', number: 1 },
        ],
        landmarkPlaces: [
          'Ulu Temburong National Park',
          'Bahrain Fort',
          'Vilnius Cathedral',
          'Turku Castle',
          "Dunn's River Falls",
          'Panfilov Park',
          'Badshahi Mosque',
        ],
        uniqueId: 'DmvHrrSp',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Tokyo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-road-near-well-lit-buildings-layMbSJ3YOE',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokyo',
        },
        landmark: {
          name: 'Mount Fuji',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-mountain-u27Rrbs9Dwc',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Fuji',
          hasLandmark: true,
        },
        city: {
          name: 'Tokyo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-city-building-rD28m9BmXVs',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokyo',
        },
        landmarksRound: [
          { code: 'HT', number: 4 },
          { code: 'UY', number: 1 },
          { code: 'WF', number: 2 },
        ],
        landmarkPlaces: [
          'Plage de Grande Anse',
          'Torre de la Libertad',
          'Tazumal',
          'Fort Napoléon des Saintes',
          "St. Tryphon's Cathedral",
          'St. James and Our Lady of the Pillar Cathedral',
          'Fasil Ghebbi',
        ],
        uniqueId: 'kW7QGycV',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tokyo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-on-body-of-water-824OwkP7sgk',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokyo',
        },
        landmark: {
          name: 'Hiroshima Peace Memorial',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-with-a-dome-on-top-DO_UCDkM94U',
          wikiLink: 'https://en.wikipedia.org/wiki/Hiroshima_Peace_Memorial',
          hasLandmark: true,
        },
        city: {
          name: 'Yokohama',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-view-of-city-beside-wide-body-of-water-during-daytime-TLIJVDES_9U',
          wikiLink: 'https://en.wikipedia.org/wiki/Yokohama',
        },
        landmarksRound: [
          { code: 'SZ', number: 1 },
          { code: 'IQ', number: 1 },
          { code: 'DE', number: 3 },
        ],
        landmarkPlaces: [
          'Montserrat Volcano Observatory',
          'Soufriere Hills',
          'Annaberg Sugar Plantation',
          'Semuc Champey',
          'Day Forest National Park',
          'Basilica of Our Lady of Peace',
          'Arecibo Observatory',
        ],
        uniqueId: 'N26D2vWk',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Japan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/canal-between-cherry-blossom-trees-8sOZJ8JF0S8',
          wikiLink: 'https://en.wikipedia.org/wiki/Japan',
        },
        landmark: {
          name: 'Japanese pagoda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/pagoda-temple-surrounded-by-trees-aqZ3UAjs_M4',
          wikiLink: 'https://en.wikipedia.org/wiki/Japanese_pagoda',
          hasLandmark: true,
        },
        city: {
          name: 'Taitō',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-house-9sz0RKcPAQw',
          wikiLink: 'https://en.wikipedia.org/wiki/Tait%C5%8D',
        },
        landmarksRound: [
          { code: 'SK', number: 1 },
          { code: 'JM', number: 2 },
          { code: 'MX', number: 4 },
        ],
        landmarkPlaces: [
          "Mapu'a Vaea Blowholes",
          'Church of Saints Simon and Helena',
          'Al Saleh Mosque',
          'Bunce Island',
          'Basilica of Our Lady of Peace',
          'Château Frontenac',
          "Ruins of St. Paul's",
        ],
        uniqueId: 'Zfv8rg7j',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Kyoto',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/pagoda-surrounded-by-trees-E_eWwM29wfU',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyoto',
        },
        landmark: {
          name: 'Nara Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-deer-under-white-and-gray-arch-tEOhkLuqmUI',
          wikiLink: 'https://en.wikipedia.org/wiki/Nara_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Shibuya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-near-buildings-at-night-DpPutJwgyW8',
          wikiLink: 'https://en.wikipedia.org/wiki/Shibuya',
        },
        landmarksRound: [
          { code: 'AU', number: 2 },
          { code: 'BJ', number: 6 },
          { code: 'TR', number: 2 },
        ],
        landmarkPlaces: [
          'Sundarbans',
          'Plitvice Lakes National Park',
          'Saunders Island',
          'Trafalgar Falls',
          'Motherland Monument',
          'St. James and Our Lady of the Pillar Cathedral',
          'Mata-Utu Cathedral',
        ],
        uniqueId: 'hbltBJzZ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Jersey',
    code: 'JE',
    capital: 'Saint Helier',
    lat: 49.25,
    long: -2.16666666,
    cities: [
      'Grouville',
      'Saint John',
      'Saint Helier',
      'St Brelade',
      'Saint Mary',
      'Trinity',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9gWighoa5kA',
          wikiLink: 'https://en.wikipedia.org/wiki/Mont_Orgueil',
        },
        landmark: {
          name: 'Mont Orgueil Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mont_Orgueil#/media/File:Gorey_village_with_Harbour_and_Mont_Orguell_Castle_-_Island_of_Jersey_-_Foto_2017_Wolfgang_Pehlemann_DSC05012.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mont_Orgueil',
          hasLandmark: false,
        },
        city: {
          name: 'St Helier',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jersey-st-helier-capital-city-1705794/',
          wikiLink: 'https://en.wikipedia.org/wiki/St_Helier',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'S3ctmFLj',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/gorey-jersey-harbour-castle-island-1035943/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jersey',
        },
        landmark: {
          name: 'Elizabeth Castle',
          photographer: '',
          imageLink: 'https://en.wikipedia.org/wiki/Elizabeth_Castle',
          wikiLink: 'https://en.wikipedia.org/wiki/Elizabeth_Castle',
          hasLandmark: false,
        },
        city: {
          name: 'St Brelade',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Saint_Brelade#/media/File:Saint_Brelade's_Bay_Jersey.JPG",
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Brelade',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '2Lhp9Dhw',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Jersey',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/brown-building-9gWighoa5kA',
          wikiLink: 'https://en.wikipedia.org/wiki/Jersey',
        },
        landmark: {
          name: 'Mont Orgueil Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/historystack/33808303883',
          wikiLink: 'https://en.wikipedia.org/wiki/Mont_Orgueil',
          hasLandmark: true,
        },
        city: {
          name: 'Saint Helier',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/St_Helier#/media/File:Elizabeth_Castle.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Helier',
        },
        landmarksRound: [
          { code: 'ID', number: 4 },
          { code: 'GL', number: 2 },
          { code: 'FR', number: 3 },
        ],
        landmarkPlaces: [
          'Vilnius Cathedral',
          'Tsingy De Bemaraha',
          'Ruhyyet Palace',
          'Temple of Literature',
          'Liberation Tower',
          'Fort Ceperou',
          'Christ Church Cathedral',
        ],
        uniqueId: 'XKCSWKD7',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Jersey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-dock-on-blue-sea-under-blue-sky-during-daytime-S0MBtiMoi9I',
          wikiLink: 'https://en.wikipedia.org/wiki/Jersey',
        },
        landmark: {
          name: 'Durrell Wildlife Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Jersey_Zoo#/media/File:Jersey_Zoological_Park-24Sept2008.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Durrell_Wildlife_Park',
          hasLandmark: true,
        },
        city: {
          name: 'St Brelade',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/St_Brelade#/media/File:New_Les_Quennevais.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/St_Brelade#',
        },
        landmarksRound: [
          { code: 'ET', number: 3 },
          { code: 'MT', number: 1 },
          { code: 'GL', number: 3 },
        ],
        landmarkPlaces: [
          'Verona Arena',
          'Mosquee Al-Hamoudi',
          'Ancient Theatre of Epidaurus',
          'Great Mosque of Asmara',
          'Patuxay Monument',
          'Fort Zeelandia',
          'Liberation Tower',
        ],
        uniqueId: 'cl9Pw8Bd',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Jersey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-during-sunset-FuqVWStF2Qw',
          wikiLink: 'https://en.wikipedia.org/wiki/Jersey',
        },
        landmark: {
          name: 'Mont Orgueil',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/brown-building-9gWighoa5kA',
          wikiLink: 'https://en.wikipedia.org/wiki/Mont_Orgueil',
          hasLandmark: true,
        },
        city: {
          name: 'Saint Helier',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/laurent2302/48995548097/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Helier',
        },
        landmarksRound: [
          { code: 'AX', number: 1 },
          { code: 'IR', number: 2 },
          { code: 'GR', number: 4 },
        ],
        landmarkPlaces: [
          'Pula Arena',
          'Brimstone Hill Fortress',
          'Ten Thousand Buddhas Monastery',
          'Xunatunich Mayan Ruins',
          'The Blue Eye',
          'Palacio Salvo',
          'Tatacoa Desert',
        ],
        uniqueId: '8pTs2g4f',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Jersey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-standing-on-top-of-a-sandy-beach-b2_xvOxjDdo',
          wikiLink: 'https://en.wikipedia.org/wiki/Jersey',
        },
        landmark: {
          name: 'La Corbière',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/rock-formation-under-cloudy-sky-nmCMWp3IJhQ',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Corbi%C3%A8re',
          hasLandmark: true,
        },
        city: {
          name: 'St Brelade',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/gi0rtn/52245833892/',
          wikiLink: 'https://en.wikipedia.org/wiki/St_Brelade#',
        },
        landmarksRound: [
          { code: 'GG', number: 4 },
          { code: 'TC', number: 1 },
          { code: 'AE', number: 3 },
        ],
        landmarkPlaces: [
          'Arc de Triomphe',
          'Fort Sao Sebastiao',
          'St. Nicholas Abbey Heritage Railway',
          'Tjentiste War Memorial',
          'The Baths',
          'Palacio Salvo',
          'Verona Arena',
        ],
        uniqueId: 'V9wLsXFx',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Jordan',
    code: 'JO',
    capital: 'Amman',
    lat: 31,
    long: 36,
    cities: ['Zarqa', 'Amman', 'Aqaba', 'As-Salt', 'Al-Mafraq', 'Irbid'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/eoZTnFtusDs',
          wikiLink: 'https://en.wikipedia.org/wiki/Petra',
        },
        landmark: {
          name: 'Roman theatre',
          photographer: '',
          imageLink: 'https://en.wikipedia.org/wiki/Roman_theatre_(structure)',
          wikiLink:
            'https://en.wikipedia.org/wiki/Roman_theatre_(structure)#/media/File:Roman_theater_of_Amman_01.jpg',
          hasLandmark: true,
        },
        city: {
          name: 'Zarqa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Zarqa#/media/File:%D8%A7%D9%84%D8%B2%D8%B1%D9%82%D8%A7%D8%A1_%D8%AE%D9%84%D8%A7%D9%84_%D8%AD%D8%B8%D8%B1_%D8%A7%D9%84%D8%AA%D8%AC%D9%88%D9%84_%D9%86%D9%8A%D8%B3%D8%A7%D9%86_%D9%A2%D9%A0%D9%A2%D9%A1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Zarqa',
        },
        landmarksRound: [
          { code: 'NL', number: 1 },
          { code: 'MR', number: 1 },
          { code: 'FR', number: 2 },
        ],
        landmarkPlaces: [
          'Chorsu Bazaar',
          'Grytviken Church',
          'Tokyo Skytree',
          'Skopje Fortress',
          'Brandenburg Gate',
          'Silver Pagoda',
          'Sistine Chapel',
        ],
        uniqueId: 'XGjbHPQW',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/petra-ad-deir-world-heritage-6294051/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jordan',
        },
        landmark: {
          name: 'Petra',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/petra-ad-deir-world-heritage-6294051/',
          wikiLink: 'https://en.wikipedia.org/wiki/Petra',
          hasLandmark: true,
        },
        city: {
          name: 'Amman',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/amman-jordan-city-urban-buildings-7130516/',
          wikiLink: 'https://en.wikipedia.org/wiki/Amman',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'F2YsxBs2',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Petra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-on-brown-rocky-mountain-during-daytime-5_Bu25SV6X8',
          wikiLink: 'https://en.wikipedia.org/wiki/Petra',
        },
        landmark: {
          name: 'Jerash',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-ancient-coliseum-with-many-people-VW9Qw9DYre8',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerash',
          hasLandmark: true,
        },
        city: {
          name: 'Amman',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-near-city-buildings-during-daytime-VqLwsabp1eY',
          wikiLink: 'https://en.wikipedia.org/wiki/Amman',
        },
        landmarksRound: [
          { code: 'VA', number: 1 },
          { code: 'YT', number: 2 },
          { code: 'ER', number: 3 },
        ],
        landmarkPlaces: [
          'Baobab Avenue',
          'Mawson Peak',
          'Basilica of Our Lady of Peace',
          "St. Peter's Basilica",
          'Pico Basile',
          'Sans-Soucis Palace',
          'Stonehenge',
        ],
        uniqueId: 'vH26JMc2',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Jordan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-stadium-during-daytime-zrUAX7Qbg-g',
          wikiLink: 'https://en.wikipedia.org/wiki/Jordan',
        },
        landmark: {
          name: 'Petra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-on-brown-rocky-mountain-during-daytime-5_Bu25SV6X8',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerash',
          hasLandmark: true,
        },
        city: {
          name: 'Zarqa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Zarqa#/media/File:%D8%A7%D9%84%D8%B2%D8%B1%D9%82%D8%A7%D8%A1_%D8%AE%D9%84%D8%A7%D9%84_%D8%AD%D8%B8%D8%B1_%D8%A7%D9%84%D8%AA%D8%AC%D9%88%D9%84_%D9%86%D9%8A%D8%B3%D8%A7%D9%86_%D9%A2%D9%A0%D9%A2%D9%A1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Zarqa',
        },
        landmarksRound: [
          { code: 'GQ', number: 4 },
          { code: 'GG', number: 2 },
          { code: 'PF', number: 3 },
        ],
        landmarkPlaces: [
          'Kerid Crater',
          'Cape Coast Castle',
          'Castle Cornet',
          'Two Lovers Point',
          'Rock of Gibraltar',
          'Bissau Cathedral',
          'Salvo Palace',
        ],
        uniqueId: 'VVb4m5TL',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Jordan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-mountains-and-sky--EJEaytR9fw',
          wikiLink: 'https://en.wikipedia.org/wiki/Jordan',
        },
        landmark: {
          name: 'Petra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/petra-jordan-during-daytime-sYMgkKkHpGI',
          wikiLink: 'https://en.wikipedia.org/wiki/Petra',
          hasLandmark: true,
        },
        city: {
          name: 'Amman',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-lots-of-tall-buildings-imCH-7u0PN8',
          wikiLink: 'https://en.wikipedia.org/wiki/Amman',
        },
        landmarksRound: [
          { code: 'BS', number: 1 },
          { code: 'FI', number: 1 },
          { code: 'KI', number: 4 },
        ],
        landmarkPlaces: [
          "Ruins of St. Paul's",
          "Christian's Cave",
          'Basilica of Our Lady of Guadalupe',
          'Kamianets-Podilskyi Castle',
          'Schlossberg (Graz)',
          'Umayyad City Ruins',
          'Dolly Beach',
        ],
        uniqueId: 'rn9mnqhX',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Jordan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-empty-road-surrounded-by-trees-on-a-hillside-jFwKauZD4sw',
          wikiLink: 'https://en.wikipedia.org/wiki/Jordan',
        },
        landmark: {
          name: 'Amman Citadel',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-ruins-of-the-ancient-city-of-palmyrate-NXVunN5P9oI',
          wikiLink: 'https://en.wikipedia.org/wiki/Amman_Citadel',
          hasLandmark: true,
        },
        city: {
          name: 'Aqaba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-with-mountains-in-the-background-7acyTW3WqQ4',
          wikiLink: 'https://en.wikipedia.org/wiki/Aqaba',
        },
        landmarksRound: [
          { code: 'CN', number: 5 },
          { code: 'JM', number: 6 },
          { code: 'ME', number: 1 },
        ],
        landmarkPlaces: [
          'Atlas Cove',
          'Tenaru Falls',
          'Pont du Gard',
          'Lake Yoa',
          "National Martyrs' Memorial",
          'Krka National Park',
          'Mont Choungui',
        ],
        uniqueId: 'RvHt3l9y',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    capital: 'Astana',
    lat: 48,
    long: 68,
    cities: ['Astana', 'Shymkent', 'Shymkent', 'Aktau', 'Nur-Sultan', 'Almaty'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/YtYdNGabzFo',
          wikiLink: 'https://en.wikipedia.org/wiki/Almaty_Region',
        },
        landmark: {
          name: 'Baiterek',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/astana-kazakhstan-nur-sultan-hotel-4977790/',
          wikiLink: 'https://en.wikipedia.org/wiki/Baiterek_(monument)',
          hasLandmark: true,
        },
        city: {
          name: 'Astana',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/astana-kazakhstan-left-coast-river-3725230/',
          wikiLink: 'https://en.wikipedia.org/wiki/Astana',
        },
        landmarksRound: [
          { code: 'BA', number: 2 },
          { code: 'RE', number: 1 },
          { code: 'LV', number: 1 },
        ],
        landmarkPlaces: [
          'Rijksmuseum',
          'Bratislava Castle',
          'Ocean Park',
          'Akershus Fortress',
          'The Marble Mountains',
          'Old Panama',
          'Gediminas Castle Tower',
        ],
        uniqueId: 'ghhsCLzw',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/canyon-mountains-river-kazakhstan-1740973/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kazakhstan',
        },
        landmark: {
          name: 'Palace of Peace and Reconciliation',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Palace_of_Peace_and_Reconciliation#/media/File:%D0%90%D1%81%D1%82%D0%B0%D0%BD%D0%B0,_%D0%94%D0%B2%D0%BE%D1%80%D0%B5%D1%86_%D0%BC%D0%B8%D1%80%D0%B0_%D0%B8_%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D1%8F_-_panoramio.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Palace_of_Peace_and_Reconciliation',
          hasLandmark: true,
        },
        city: {
          name: 'Shymkent',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Shymkent#/media/File:Ordabassy-Square-Shymkent-Kazakhstan.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Shymkent',
        },
        landmarksRound: [
          { code: 'MO', number: 1 },
          { code: 'MZ', number: 2 },
          { code: 'VA', number: 1 },
        ],
        landmarkPlaces: [
          'Valle de Cocora',
          'Lotto World',
          'House of the Black Heads',
          'Merry Cemetery',
          'Senso-ji',
          'Herat Citadel',
          "St. Olaf's Church",
        ],
        uniqueId: 'C5j4xLnG',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Almaty Lake',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/lake-surrounded-with-mountains-during-daytime-ToJNyWpDn9I',
          wikiLink: 'https://en.wikipedia.org/wiki/Big_Almaty_Lake',
        },
        landmark: {
          name: 'Charyn Canyon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-the-mountains-from-a-high-point-of-view-9Iw9UUGb2U8',
          wikiLink: 'https://en.wikipedia.org/wiki/Charyn_Canyon',
          hasLandmark: true,
        },
        city: {
          name: 'Nur-Sultan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-in-the-middle-of-a-city-9Z_on4I7aak',
          wikiLink: 'https://en.wikipedia.org/wiki/Nur-Sultan',
        },
        landmarksRound: [
          { code: 'KG', number: 1 },
          { code: 'AE', number: 1 },
          { code: 'TC', number: 2 },
        ],
        landmarkPlaces: [
          'Soroca Fort',
          'Pigeon Rocks',
          'Nasir al-Mulk Mosque',
          'Basilica of Our Lady of Peace',
          'Golden Gate',
          'Cape Coast Castle',
          'Fort Christian',
        ],
        uniqueId: 'pGtvVDcv',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Kazakhstan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-riding-horse-dL-NYHFuwJI',
          wikiLink: 'https://en.wikipedia.org/wiki/Kazakhstan',
        },
        landmark: {
          name: 'Mausoleum of Khoja Ahmed Yasawi',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/96197879@N08/21376189774/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Mausoleum_of_Khoja_Ahmed_Yasawi',
          hasLandmark: true,
        },
        city: {
          name: 'Almaty',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-rise-buildings-beside-green-trees-during-daytime-ECRotWQ6T-M',
          wikiLink: 'https://en.wikipedia.org/wiki/Almaty',
        },
        landmarksRound: [
          { code: 'LR', number: 2 },
          { code: 'GT', number: 3 },
          { code: 'LV', number: 2 },
        ],
        landmarkPlaces: [
          'Gediminas Castle Tower',
          'Ilulissat Icefjord',
          'Ceking Rice Terrace',
          'Colo-i-Suva Forest Reserve',
          'Bissau Cathedral',
          'Iluissat Icefjord',
          'Great Mosque of Asmara',
        ],
        uniqueId: 'fbzPX2T8',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Burabay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-rock-with-trees-and-mountains-in-the-background-zS08OeVWgoE',
          wikiLink: 'https://en.wikipedia.org/wiki/Burabay',
        },
        landmark: {
          name: 'Baiterek',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tall-tower-with-a-golden-dome-in-the-middle-of-a-city-Bo8krugxuCU',
          wikiLink: 'https://en.wikipedia.org/wiki/Baiterek_(monument)',
          hasLandmark: true,
        },
        city: {
          name: 'Astana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-white-structure-sitting-in-the-middle-of-a-body-of-water-Lk6k4nDCOD8',
          wikiLink: 'https://en.wikipedia.org/wiki/Astana',
        },
        landmarksRound: [
          { code: 'VN', number: 1 },
          { code: 'CV', number: 1 },
          { code: 'SC', number: 1 },
        ],
        landmarkPlaces: [
          'Alte Rheinbrücke',
          'Herat Citadel',
          'Hazrat Ali Mazar',
          'Castle Vaduz',
          'Sao Filipe Royale Fortress',
          'Point Venus',
          'Belvedere Lookout',
        ],
        uniqueId: '7NFCYjK5',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Almaty',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-mountain-range-with-houses-on-it-Dxwf7I2MPKU',
          wikiLink: 'https://en.wikipedia.org/wiki/Almaty',
        },
        landmark: {
          name: 'Charyn Canyon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-rocky-landscape-with-mountains-in-the-background-uj4TyuvM72w',
          wikiLink: 'https://en.wikipedia.org/wiki/Charyn_Canyon',
          hasLandmark: true,
        },
        city: {
          name: 'Almaty',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-during-daytime-23QMEhgPOBc',
          wikiLink: 'https://en.wikipedia.org/wiki/Almaty',
        },
        landmarksRound: [
          { code: 'VI', number: 4 },
          { code: 'GM', number: 2 },
          { code: 'KE', number: 4 },
        ],
        landmarkPlaces: [
          'Temple of Garni',
          'Villa Folio',
          'Elmina Castle',
          'Cape Coast Castle',
          'Christ the King Cathedral',
          'Al Alam Palace',
          'Mount Kenya',
        ],
        uniqueId: 'hb9hBh8r',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Kenya',
    code: 'KE',
    capital: 'Nairobi',
    lat: 1,
    long: 38,
    cities: ['Mombasa', 'Nairobi', 'Eldoret', 'Malindi', 'Kisumu', 'Nakuru'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/T-LfvX-7IVg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tsavo_East_National_Park',
        },
        landmark: {
          name: 'Mount Kenya',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mount-kenya-sunset-mountain-peak-7377792/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Kenya',
          hasLandmark: true,
        },
        city: {
          name: 'Mombasa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mombasa#/media/File:Downtown_Mombasa.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mombasa',
        },
        landmarksRound: [
          { code: 'TM', number: 2 },
          { code: 'ES', number: 2 },
          { code: 'AU', number: 2 },
        ],
        landmarkPlaces: [
          'Turku Castle',
          'Island of Mozambique',
          'South Pole (ceremonial)',
          'Cathedral Our Lady of the Rosary',
          'Saint Sava Temple',
          'Voortrekker Monument',
          'Sukhbaatar Square',
        ],
        uniqueId: '4YJvkpCX',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/safari-elephant-landscape-nature-4043090/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenya',
        },
        landmark: {
          name: 'Tsavo East National Park',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/elephant-africa-safari-tsavo-east-4478695/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tsavo_East_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Nairobi',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/nairobi-kenya-street-crowded-2770345/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nairobi',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'WsktzlFb',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kenya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-brown-grass-field-under-white-clouds-and-blue-sky-during-daytime-1jlJrr4XGkU',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenya',
        },
        landmark: {
          name: 'Amboseli National Park, Kenya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-leaf-tree-near-mountain-covered-by-snow-at-daytime-DWXR-nAbxCk',
          wikiLink: 'https://en.wikipedia.org/wiki/Amboseli_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Nairobi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-skyline-under-white-sky-during-daytime-LTh5pGyvKAM',
          wikiLink: 'https://en.wikipedia.org/wiki/Nairobi',
        },
        landmarksRound: [
          { code: 'GQ', number: 2 },
          { code: 'MC', number: 2 },
          { code: 'GA', number: 1 },
        ],
        landmarkPlaces: [
          'Cacheu Fort',
          'Viru Gate',
          "St. Peter's Church",
          'Elmina Castle',
          'Monument to the Heroes of the Restoration',
          'Jökulsárlón',
          'Milwane Wildlife Sanctuary',
        ],
        uniqueId: 'HvnH3PSL',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Kenya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-hot-air-balloons-in-the-wild-89IBtfoz3Vw',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenya',
        },
        landmark: {
          name: 'Mount Kenya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-gazelles-at-the-field-during-day-neMPqxWWO0w',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Kenya',
          hasLandmark: true,
        },
        city: {
          name: 'Mombasa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mombasa#/media/File:Moi_Avenue_Mombasa.png',
          wikiLink: 'https://en.wikipedia.org/wiki/Mombasa',
        },
        landmarksRound: [
          { code: 'HM', number: 3 },
          { code: 'GH', number: 3 },
          { code: 'GP', number: 1 },
        ],
        landmarkPlaces: [
          'Kallur Lighthouse',
          'Sibebe Rock',
          'Montserrat Volcano Observatory',
          'Santa Catalina Arch',
          'Akanda National Park',
          'Macau Tower',
          'Golden Gate Bridge',
        ],
        uniqueId: '6BrnSgNT',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Kenya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-brown-field-during-daytime-iyg24BviAoM',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenya',
        },
        landmark: {
          name: 'Tsavo East National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/tree-between-green-land-during-golden-hour-T-LfvX-7IVg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tsavo_East_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Nairobi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bridge-with-a-city-in-the-background-ESmWYVFII9I',
          wikiLink: 'https://en.wikipedia.org/wiki/Nairobi',
        },
        landmarksRound: [
          { code: 'IT', number: 5 },
          { code: 'SK', number: 2 },
          { code: 'CV', number: 2 },
        ],
        landmarkPlaces: [
          'Jökulsárlón',
          'Montserrat Volcano Observatory',
          'Salar de Uyuni',
          'Turtle Cove',
          'Great Mosque of Djenné',
          'Alcázar de Colón',
          'Church of Our Lady of the Rosary',
        ],
        uniqueId: 'b7Vzthqc',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Kenya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-white-clouds-xDxiO7sldis',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenya',
        },
        landmark: {
          name: 'Maasai Mara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-and-yellow-hot-air-balloon-over-field-with-zebras-kjOBqwMUnWw',
          wikiLink: 'https://en.wikipedia.org/wiki/Maasai_Mara',
          hasLandmark: true,
        },
        city: {
          name: 'Mombasa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-island-in-the-middle-of-a-body-of-water-Ix3F7K0uvds',
          wikiLink: 'https://en.wikipedia.org/wiki/Mombasa',
        },
        landmarksRound: [
          { code: 'QA', number: 2 },
          { code: 'BN', number: 2 },
          { code: 'TV', number: 4 },
        ],
        landmarkPlaces: [
          'Bab Al Bahrain',
          'Fort Charlotte',
          'Tegucigalpa Cathedral',
          'Åland Maritime Museum',
          'Ouzoud Falls',
          'Silver Pagoda',
          'Argentine Military Cemetery',
        ],
        uniqueId: '7tPhq73T',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Kerguelen Islands',
    code: 'TF',
    capital: 'Port-aux-Francais',
    lat: -49.280366,
    long: 69.348557,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Volcan_du_Diable_%28Kerguelen_islands%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Volcan_du_Diable',
        },
        landmark: {
          name: 'Mont Ross',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Ross_Mountain_seen_from_Diablo_Lake_overlook.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mont_Ross',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'CqwJs5Bs',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Kerguelen_CookGlacier.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Cook_Ice_Cap',
        },
        landmark: {
          name: 'Lake Armor',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lake_Armor#/media/File:Lac_Armor_%C3%A0_droite_mont%C3%A9e_Volcan_du_Diable.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Armor',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'QdZY6lL7',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kerguelen Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/dominique_filippi/3534835906',
          wikiLink: 'https://en.wikipedia.org/wiki/Kerguelen_Islands',
        },
        landmark: {
          name: 'Lake Armor',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lake_Armor#/media/File:Lac_Armor_%C3%A0_droite_mont%C3%A9e_Volcan_du_Diable.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Armor',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'GF', number: 2 },
          { code: 'IM', number: 1 },
          { code: 'ET', number: 3 },
        ],
        landmarkPlaces: [
          'The Blue Mosque',
          'Christ Church Cathedral',
          'Jökulsárlón',
          'Tokyo Skytree',
          'Soroca Fort',
          'Tower of David',
          'Lope National Park',
        ],
        uniqueId: 'G8HjjVdX',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Kerguelen Islands',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kerguelen_Islands#/media/File:Kerguelen_CookGlacier.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Kerguelen_Islands',
        },
        landmark: {
          name: 'Mont Ross',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mont_Ross#/media/File:Kerguelen_MontRoss.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Mont_Ross',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'GG', number: 2 },
          { code: 'IR', number: 3 },
          { code: 'TC', number: 2 },
        ],
        landmarkPlaces: [
          'Roman theatre',
          'Church of Our Lady of the Rosary',
          'Santa Catalina Arch',
          'Golden Gate Bridge',
          'Tash Rabat',
          'Arch 22',
          'Emerald Pool',
        ],
        uniqueId: 'ydT4Vq36',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Kerguelen Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/seeschloss/5223155784/in/album-72157625358299925',
          wikiLink: 'https://en.wikipedia.org/wiki/Kerguelen_Islands',
        },
        landmark: {
          name: 'Baie Larose',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/195000988@N07/52803534202/',
          wikiLink: 'https://en.wikipedia.org/wiki/Baie_Larose',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'VI', number: 4 },
          { code: 'KH', number: 2 },
          { code: 'FJ', number: 2 },
        ],
        landmarkPlaces: [
          'Helsinki Cathedral',
          'Stonehenge',
          'Cacheu Fort',
          'Pedra da Gávea',
          'Talietumu',
          'Salar de Uyuni',
          'Palace of the Grand Master of the Knights of Rhodes',
        ],
        uniqueId: 'JCmx7kNV',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Kerguelen Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/seeschloss/5207019791/in/album-72157625358299925',
          wikiLink: 'https://en.wikipedia.org/wiki/Kerguelen_Islands',
        },
        landmark: {
          name: 'Port-aux-Français',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/seeschloss/5294196620/in/album-72157625358299925',
          wikiLink: 'https://en.wikipedia.org/wiki/Port-aux-Fran%C3%A7ais',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'CF', number: 3 },
          { code: 'BO', number: 6 },
          { code: 'EH', number: 1 },
        ],
        landmarkPlaces: [
          'Gateway Arch',
          'Mount Alava',
          'Lopé National Park',
          'Mawson Peak',
          'Doonagore Castle, Doolin, Ireland',
          'La Mano de Punta del Este',
          'Tsingy De Bemaraha',
        ],
        uniqueId: '8DSHRw3p',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Kiribati',
    code: 'KI',
    capital: 'South Tarawa',
    lat: 1.41666666,
    long: 173,
    cities: [
      'Tarawa',
      'Betio',
      'Bariki',
      'Abaokoro',
      'South Tarawa',
      'Eita',
      'London',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fanning-island-kiribati-beach-1663125/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tabuaeran',
        },
        landmark: {
          name: 'Tarawa',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:South_Tarawa_from_the_air.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tarawa',
          hasLandmark: true,
        },
        city: {
          name: 'Eita',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Impacts_of_coastal_erosion_and_drought_on_coconut_palms_in_Eita,_Tarawa,_Kiribati.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Tarawa',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'KTBvngTz',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:South_Tarawa_from_the_air.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Tarawa',
        },
        landmark: {
          name: 'Kiritimati',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kiritimati#/media/File:London,_Kiritimati.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kiritimati',
          hasLandmark: true,
        },
        city: {
          name: 'London',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:London,_Kiritimati.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/London,_Kiribati',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'bzr4Rc9Q',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kiribati',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ds355/957735286',
          wikiLink: 'https://en.wikipedia.org/wiki/Kiribati',
        },
        landmark: {
          name: 'Tarawa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tarawa#/media/File:Line5304_-_Flickr_-_NOAA_Photo_Library.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tarawa',
          hasLandmark: true,
        },
        city: {
          name: 'Tarawa',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Tarawa_Atoll#/media/File:Maneaba_in_South_Tarawa,_Kiribati.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tarawa',
        },
        landmarksRound: [
          { code: 'TR', number: 2 },
          { code: 'ME', number: 1 },
          { code: 'ET', number: 3 },
        ],
        landmarkPlaces: [
          'Stone House',
          'Hiroshima Peace Memorial',
          'Brandenburg Gate',
          'Pura Ulun Danu Bratan',
          'Chamarel Waterfall',
          'Mulafossur Waterfall',
          'Jökulsárlón',
        ],
        uniqueId: '6NvMFWm3',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Kiribati',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/iloasiapacific/9181440338',
          wikiLink: 'https://en.wikipedia.org/wiki/Kiribati',
        },
        landmark: {
          name: 'Phoenix Islands Protected Area',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Phoenix_Islands_Protected_Area#/media/File:Rotjan_-_Enderbury_Day_1_-_2nd_half_(62).JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Phoenix_Islands_Protected_Area',
          hasLandmark: true,
        },
        city: {
          name: 'Betio',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Betio#/media/File:Kiribati_Bairiki_Betio_Causeway.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Betio',
        },
        landmarksRound: [
          { code: 'KG', number: 1 },
          { code: 'GW', number: 3 },
          { code: 'GQ', number: 4 },
        ],
        landmarkPlaces: [
          'Lake Armor',
          'Tsavo East National Park',
          'Kerid Crater',
          'Emerald Pool',
          'Sibebe Rock',
          'Rock of Gibraltar',
          'Mount Yasur',
        ],
        uniqueId: 'K6QDnCYQ',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Kiribati',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/hillschmill/2955863933/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kiribati',
        },
        landmark: {
          name: 'House of Assembly',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/69234295@N04/6298135431/',
          wikiLink:
            'https://en.wikipedia.org/wiki/House_of_Assembly_(Kiribati)',
          hasLandmark: true,
        },
        city: {
          name: 'Betio',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/manuhuiabarcham/5633693365/',
          wikiLink: 'https://en.wikipedia.org/wiki/Betio',
        },
        landmarksRound: [
          { code: 'AE', number: 3 },
          { code: 'ZA', number: 2 },
          { code: 'DE', number: 4 },
        ],
        landmarkPlaces: [
          'Maqam Echahid',
          'Monument du 22 novembre',
          'House of the Blackheads',
          'Fort Santiago',
          'Hato Caves',
          'Buddha Park',
          'Jökulsárlón',
        ],
        uniqueId: 'prX4tG9W',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Butaritari',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/58644897@N08/6184297164/',
          wikiLink: 'https://en.wikipedia.org/wiki/Butaritarii',
        },
        landmark: {
          name: 'Bonriki',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/69234295@N04/6298685948/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bonriki',
          hasLandmark: true,
        },
        city: {
          name: 'Tarawa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/victory_one/41167390990/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tarawa',
        },
        landmarksRound: [
          { code: 'IR', number: 2 },
          { code: 'CO', number: 1 },
          { code: 'VG', number: 5 },
        ],
        landmarkPlaces: [
          'Philharmonic Garden ',
          'Chinko Nature Reserve',
          'Karnak',
          'Richat Structure',
          'Arco de Santa Catalina',
          'Zaisan Memorial',
          "Jame' Asr Hassanil Bolkiah Mosque",
        ],
        uniqueId: 'MgQTzqGn',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Kosovo',
    code: 'XK',
    capital: 'Pristina',
    lat: 42.666667,
    long: 21.166667,
    cities: ['Pristina', 'Prizren', 'Mitrovica', 'Ferizaj', 'Gjilan', 'Klina'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/bwSYcD0okkM',
          wikiLink: 'https://en.wikipedia.org/wiki/Prizren',
        },
        landmark: {
          name: 'Prizeren Fortress',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Prizren_Fortress#/media/File:Prizren_Fortress_(2021).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Prizren_Fortress',
          hasLandmark: false,
        },
        city: {
          name: 'Mitrovica',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Mitrovica_gjat%C3%AB_mbr%C3%ABmjes.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mitrovica,_Kosovo',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'hPKd33BM',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/prizren-kosovo-city-view-town-3430989/',
          wikiLink: 'https://en.wikipedia.org/wiki/Prizren',
        },
        landmark: {
          name: 'Newborn Monument',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Newborn_Monument_Pristina.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Newborn_monument',
          hasLandmark: false,
        },
        city: {
          name: 'Pristina',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rainrannu/2700887728',
          wikiLink: 'https://en.wikipedia.org/wiki/Pristina',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '8PQXYFDc',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kosovo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-green-mountain-under-blue-sky-during-daytime-9ut57M5CsUQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Kosovo',
        },
        landmark: {
          name: 'Gracanica Monastery',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/pauldiming/18566388491',
          wikiLink: 'https://en.wikipedia.org/wiki/Gra%C4%8Danica',
          hasLandmark: true,
        },
        city: {
          name: 'Pristina',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:Prishtina_seen_from_Mother_Theresa_Cathedral.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pristina',
        },
        landmarksRound: [
          { code: 'DO', number: 3 },
          { code: 'VA', number: 1 },
          { code: 'ID', number: 2 },
        ],
        landmarkPlaces: [
          'Sans-Souci Palace',
          "St. Peter's Church",
          'Cologne Cathedral',
          'Vajdahunyad Castle',
          'Angel Falls',
          'Dubai Frame',
          'Brandenburg Gate',
        ],
        uniqueId: 'RHyFM8MC',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Treska',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-clouds-over-green-mountain-bHDns51fR7I',
          wikiLink: 'https://en.wikipedia.org/wiki/Kosovo',
        },
        landmark: {
          name: 'Gazimestan',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/54929601@N02/5224214750',
          wikiLink: 'https://en.wikipedia.org/wiki/Gazimestan',
          hasLandmark: true,
        },
        city: {
          name: 'Prizren',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Prizren#/media/File:Qendra_historike_e_Prizrenit,_2015.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Prizren',
        },
        landmarksRound: [
          { code: 'SI', number: 1 },
          { code: 'VA', number: 2 },
          { code: 'MR', number: 1 },
        ],
        landmarkPlaces: [
          'Stephen the Great Monument',
          'Stone House',
          'Meteora',
          'Copán',
          'Santa Catalina Arch',
          'Emerald Pool',
          'Vajdahunyad Castle',
        ],
        uniqueId: 'prGlWtBX',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Kosovo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-hill-with-trees-on-it-j_tT1s0MCa4',
          wikiLink: 'https://en.wikipedia.org/wiki/Kosovo',
        },
        landmark: {
          name: 'Cathedral of Saint Mother Teresa',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/andrewmilligansumo/48652700428/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Cathedral_of_Saint_Mother_Teresa',
          hasLandmark: true,
        },
        city: {
          name: 'Pristina',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-the-top-of-a-hill-PyQA8Ep-xHU',
          wikiLink: 'https://en.wikipedia.org/wiki/Pristina',
        },
        landmarksRound: [
          { code: 'CK', number: 4 },
          { code: 'AO', number: 3 },
          { code: 'BH', number: 1 },
        ],
        landmarkPlaces: [
          'Fort Frederick',
          'House of the Blackheads',
          'Parque Colón',
          'Amalienborg',
          'Plaza Murillo',
          'Khor Virap Monastery',
          'Ben Amera',
        ],
        uniqueId: 'BCy49BHg',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Prizren',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-white-building-with-a-black-bench-in-front-of-it-pW3yssrTMmk',
          wikiLink: 'https://en.wikipedia.org/wiki/Prizren',
        },
        landmark: {
          name: 'Visoki Dečani',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/47678913@N04/41120076715/',
          wikiLink: 'https://en.wikipedia.org/wiki/Visoki_De%C4%8Dani',
          hasLandmark: true,
        },
        city: {
          name: 'Prizren',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-building-under-blue-sky-during-daytime-bwSYcD0okkM',
          wikiLink: 'https://en.wikipedia.org/wiki/Prizren',
        },
        landmarksRound: [
          { code: 'ZM', number: 2 },
          { code: 'TM', number: 2 },
          { code: 'MV', number: 4 },
        ],
        landmarkPlaces: [
          "St. Peter's Church",
          'Mawson Peak',
          'Little Bay',
          'Easter Island',
          'Skopje Fortress',
          'Lukan Longshan Temple',
          'Castle Rushden',
        ],
        uniqueId: 'H93g7KB7',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Kuwait',
    code: 'KW',
    capital: 'Kuwait City',
    lat: 29.5,
    long: 45.75,
    cities: [
      'Fahaheel',
      'Kuwait City',
      'Salmiya',
      'Mishrif',
      'Al Ahmadi',
      'Mangaf',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ICJkwztWGcI',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait_Towers',
        },
        landmark: {
          name: 'Kuwait Towers',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kuwait-towers-landmarks-kuwait-blue-520621/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait_Towers',
          hasLandmark: true,
        },
        city: {
          name: 'Fahaheel',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/kendoerr/5220651016',
          wikiLink: 'https://en.wikipedia.org/wiki/Fahaheel',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'rdVdx6pQ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kuwait-ruins-sea-house-coast-1824090/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait',
        },
        landmark: {
          name: 'Liberation Tower',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Liberation_Tower_(Kuwait)#/media/File:Liberation_Tower_(245936909).jpeg',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberation_Tower_(Kuwait)',
          hasLandmark: true,
        },
        city: {
          name: 'Kuwait City',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/street-cars-city-downtown-kuwait-5363692/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait_City',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'MQLf8cxY',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kuwait',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-rise-buildings-during-sunrise--XAphu7d5t0',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait',
        },
        landmark: {
          name: 'Holy Family Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Holy_Family_Co-Cathedral,_Kuwait#/media/File:Holy_Family_Cathedral_(Kuwait).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Holy_Family_Co-Cathedral,_Kuwait',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'IT', number: 4 },
          { code: 'GT', number: 4 },
          { code: 'VN', number: 1 },
        ],
        landmarkPlaces: [
          'Joya De Ceren Archeological Site',
          'Big Ben',
          'Columbus Park',
          'Lake Lalolalo',
          'Seven Colored Earths',
          'Hvalsey Church',
          'Patuxay Monument',
        ],
        uniqueId: 'qzH62db4',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Kuwait',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-daytime-EyV6qxS3kl0',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait',
        },
        landmark: {
          name: 'Grand Mosque of Kuwait',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/cajie/2050617770',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Mosque_of_Kuwait',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'TF', number: 2 },
          { code: 'HK', number: 1 },
          { code: 'MU', number: 1 },
        ],
        landmarkPlaces: [
          'Church of Our Lady of the Rosary',
          'Karnak',
          'Charyn Canyon',
          'National Museum of Arts, Rites and Traditions',
          'Azadi Tower',
          'Colo-i-Suva Forest Reserve',
          "Christian's Cave",
        ],
        uniqueId: 'Ldjq8L8W',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Kuwait',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-building-through-a-fence-at-night-3EpdvGspiDM',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait',
        },
        landmark: {
          name: 'Holy Family Co-Cathedral',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/16983197@N06/6846210117/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Holy_Family_Co-Cathedrall,_Kuwait',
          hasLandmark: true,
        },
        city: {
          name: 'Kuwait City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-buildings-under-blue-sky-during-daytime-fGXh33tc3BY',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait_City',
        },
        landmarksRound: [
          { code: 'CR', number: 6 },
          { code: 'DM', number: 6 },
          { code: 'DZ', number: 3 },
        ],
        landmarkPlaces: [
          'Amalienborg',
          'Billionth Barrel Monument',
          'Lake Yoa',
          'Krka National Park',
          'Tadrart Acacus',
          'Seven Coloured Earths',
          'Leptis Magna',
        ],
        uniqueId: '58tFg73z',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Kuwait',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/rock-formations-133ajKnAgU8',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuwait',
        },
        landmark: {
          name: 'Liberation Tower',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jowmaco/415437078/',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberation_Tower_(Kuwait)',
          hasLandmark: true,
        },
        city: {
          name: 'Salmiya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-near-high-rise-buildings-during-daytime-c9z1NMLF32E',
          wikiLink: 'https://en.wikipedia.org/wiki/Salmiyaa',
        },
        landmarksRound: [
          { code: 'LK', number: 2 },
          { code: 'IL', number: 6 },
          { code: 'BZ', number: 4 },
        ],
        landmarkPlaces: [
          'Cape Coast Castle',
          'Tenaru Falls',
          'Fort Christian',
          'Fort Sao Sebastiao',
          'Kallur Lighthouse',
          'Old Dongola',
          'Borobudur Temple',
        ],
        uniqueId: 'yT7Z6rLH',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    capital: 'Bishkek',
    lat: 41,
    long: 75,
    cities: ['Bishkek', 'Osh', 'Jalal-Abad', 'Tokmok', 'Naryn', 'Karakol'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/jTO7daIRh0U',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyrgyzstan',
        },
        landmark: {
          name: 'Panfilov Park',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Statue_of_Ivan_Panfilov_in_Bishkek.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Panfilov_Park,_Bishkek',
          hasLandmark: true,
        },
        city: {
          name: 'Osh',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Osh#/media/File:Osh_03-2016_img27_view_from_Sulayman_Mountain.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Osh',
        },
        landmarksRound: [
          { code: 'CH', number: 2 },
          { code: 'ES', number: 2 },
          { code: 'AU', number: 2 },
        ],
        landmarkPlaces: [
          'Alhambra',
          'Vaduz Cathedral',
          'Tsingy De Bemaraha',
          'Ulu Temburong National Park',
          'Soroca Fort',
          'Deadvlei',
          'Red Square',
        ],
        uniqueId: 'gqY6DWLQ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kyrgyzstan-toktogul-dam-dam-naryn-4765706/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyrgyzstan',
        },
        landmark: {
          name: 'Tash Rabat',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kyrgyzstan-tash-rabat-sightseeing-3738065/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tash_Rabat',
          hasLandmark: true,
        },
        city: {
          name: 'Bishkek',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mountains-winter-offspring-bishkek-7589047/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bishkek',
        },
        landmarksRound: [
          { code: 'BB', number: 1 },
          { code: 'BE', number: 2 },
          { code: 'PT', number: 1 },
        ],
        landmarkPlaces: [
          "St. Tryphon's Cathedral",
          'Tjibaou Cultural Center',
          'Wawel Castle',
          'Castle Rushden',
          'Island of Mozambique',
          'Abu Simbel Temples',
          'Ulu Temburong National Park',
        ],
        uniqueId: '9LymcYJ7',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kyrgyzstan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-house-on-green-grass-field-near-mountain-during-daytime-jTO7daIRh0U',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyrgyzstan',
        },
        landmark: {
          name: 'Köl-Suu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/K%C3%B6l-Suu#/media/File:Kol-Suu.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/K%C3%B6l-Suu',
          hasLandmark: true,
        },
        city: {
          name: 'Bishkek',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-gr6b9bkh2Kw',
          wikiLink: 'https://en.wikipedia.org/wiki/Bishkek',
        },
        landmarksRound: [
          { code: 'GF', number: 4 },
          { code: 'ER', number: 4 },
          { code: 'LR', number: 1 },
        ],
        landmarkPlaces: [
          'Lope National Park',
          'Kasubi Royal Tombs',
          'Blue Mountains',
          'Grand Mosque of Kuwait',
          'Kathmandu Durbar Square',
          'Sri Siva Subramaniya Temple',
          'Macau Tower',
        ],
        uniqueId: '39xP4BWg',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Kyrgyzstan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-mountains-under-white-clouds-during-daytime-4dZnL_Da9gk',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyrgyzstan',
        },
        landmark: {
          name: 'Burana Tower',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Burana_Tower#/media/File:2015-09-06-112644_-_Balasagun_-_Burana-Turm.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Burana_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Osh',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Osh#/media/File:Osh_-_Ak-Buura_river_(2)_(30507316675).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Osh',
        },
        landmarksRound: [
          { code: 'JE', number: 4 },
          { code: 'DJ', number: 4 },
          { code: 'AE', number: 2 },
        ],
        landmarkPlaces: [
          'Borobudur Temple',
          'Green Grotto Caves',
          'Cape Coast Castle',
          'Tsavo East National Park',
          'Emerald Pool',
          'Parque Nacional de Malabo',
          'Two Lovers Point',
        ],
        uniqueId: 'hml79vCF',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Bishkek',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-large-building-at-night-Tu0kZSXygUU',
          wikiLink:
            'https://en.wikipedia.org/wiki/Bishkek#:~:text=Bishkek%2C%20formerly%20known%20as%20Pishpek,of%201%2C074%2C075%2C%20as%20of%202021.',
        },
        landmark: {
          name: 'Rukh Ordo',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/schmaltz_nicolas/48712389366/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rukh_Ordo',
          hasLandmark: true,
        },
        city: {
          name: 'Bishkek',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-tall-buildings-9dtNSvk2kFQ',
          wikiLink:
            'https://en.wikipedia.org/wiki/Bishkek#:~:text=Bishkek%2C%20formerly%20known%20as%20Pishpek,of%201%2C074%2C075%2C%20as%20of%202021.',
        },
        landmarksRound: [
          { code: 'BM', number: 6 },
          { code: 'NC', number: 1 },
          { code: 'BW', number: 3 },
        ],
        landmarkPlaces: [
          'Plaza Francia',
          'Mausoleum of Khoja Ahmed Yasawi',
          'South Pole (ceremonial)',
          'Alega Beach',
          'Columbus Park',
          'Gishora Drum Sanctuary',
          'Temppeliaukio Church',
        ],
        uniqueId: 'mzb92MYf',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Kegeti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-under-blue-sky-during-daytime-hJbRHIzTvto',
          wikiLink: 'https://en.wikipedia.org/wiki/Kegeti',
        },
        landmark: {
          name: 'State History Museum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/9508280@N07/16072021569/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Kyrgyz_State_Historical_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Osh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-the-top-of-a-mountain-V1Hn6dPKOyw',
          wikiLink: 'https://en.wikipedia.org/wiki/Osh',
        },
        landmarksRound: [
          { code: 'TM', number: 2 },
          { code: 'PA', number: 2 },
          { code: 'NL', number: 2 },
        ],
        landmarkPlaces: [
          'Kiritimati',
          'Boudhanath',
          'Rock of Gibraltar',
          'Cape Coast Castle',
          'Shoes on the Danube Bank',
          'Copán',
          'Grand Mosque of Bobo-Dioulasso',
        ],
        uniqueId: '7LbNY8Qg',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Laos',
    code: 'LA',
    capital: 'Vientiane',
    lat: 18,
    long: 105,
    cities: [
      'Vientiane',
      'Luang Prabang',
      'Savannakhet',
      'Thakhek',
      'Phonsavan',
      'Pakse',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yJ2SHIpNb9M',
          wikiLink: 'https://en.wikipedia.org/wiki/Vang_Vieng',
        },
        landmark: {
          name: 'Wat Xiengthong',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Wat_Xieng_Thong#/media/File:Temple_Wat_Xieng_Thong_-_Luang_Prabang_-_Laos.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Wat_Xieng_Thong',
          hasLandmark: true,
        },
        city: {
          name: 'Pakse',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/river-buidlings-trees-coast-shore-6956330/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pakse',
        },
        landmarksRound: [
          { code: 'LU', number: 1 },
          { code: 'RO', number: 2 },
          { code: 'LS', number: 1 },
        ],
        landmarkPlaces: [
          'Koutoubia',
          'Mount Nyriagongo',
          "Dunn's River Falls",
          'Grand Ducal Palace',
          'Villa Folio',
          'Vaduz Cathedral',
          'Mostar Old Bridge',
        ],
        uniqueId: '2xRW7Y9W',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/road-luang-prabang-street-city-4878453/',
          wikiLink: 'https://en.wikipedia.org/wiki/Laos',
        },
        landmark: {
          name: 'Patuxay Monument',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/patuxai-laos-vientiane-monument-1532500/',
          wikiLink: 'https://en.wikipedia.org/wiki/Patuxai',
          hasLandmark: true,
        },
        city: {
          name: 'Vientiane',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Vientiane#/media/File:20171118_Vientiane_3220_DxO.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Vientiane',
        },
        landmarksRound: [
          { code: 'NZ', number: 2 },
          { code: 'KZ', number: 1 },
          { code: 'ZW', number: 2 },
        ],
        landmarkPlaces: [
          'Old Town Square',
          'Buenos Aires Obelisk',
          'Chimi Lhakhang',
          'Parthenon',
          'Koutoubia',
          'Twelve Apostles',
          'Matobo National Park',
        ],
        uniqueId: 'wQlspKJR',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Vientiane',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-iIhIidVC4UU',
          wikiLink: 'https://en.wikipedia.org/wiki/Vientiane',
        },
        landmark: {
          name: 'Buddha Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Buddha_Park#/media/File:Buddha_Park.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Buddha_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Vientiane',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-daytime-DmG3yNfEd5Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Vientiane',
        },
        landmarksRound: [
          { code: 'KW', number: 2 },
          { code: 'ET', number: 1 },
          { code: 'DO', number: 3 },
        ],
        landmarkPlaces: [
          'Hagia Sophia',
          'Stonehenge',
          'Senso-ji',
          'The Blue Mosque',
          'Petronas Towers',
          'Fasil Ghebbi',
          'Gediminas Castle Tower',
        ],
        uniqueId: 'FZthYHGf',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Laos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-boats-near-body-of-water-BMXTDz-wZT4',
          wikiLink: 'https://en.wikipedia.org/wiki/Vientiane',
        },
        landmark: {
          name: 'Patuxai',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Patuxai#/media/File:Vientiane_-_Patuxai_-_0003.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Patuxai',
          hasLandmark: true,
        },
        city: {
          name: 'Savannakhet',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Savannakhet#/media/File:Savannakhet1.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Savannakhet',
        },
        landmarksRound: [
          { code: 'GI', number: 4 },
          { code: 'LY', number: 2 },
          { code: 'GG', number: 4 },
        ],
        landmarkPlaces: [
          'Kylemore Abbey',
          'Two Lovers Point',
          'Grand Mosque of Kuwait',
          'Angel Falls',
          'National Museum of Arts, Rites and Traditions',
          'Neuschwanstein Castle',
          'Vaduz Castle',
        ],
        uniqueId: 'Z6VHWdBm',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Laos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-mountain-range-with-a-valley-in-the-foreground-IPhX8UIAQn0',
          wikiLink: 'https://en.wikipedia.org/wiki/Laos',
        },
        landmark: {
          name: 'Kuang Si Falls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-waterfall-in-the-middle-of-a-forest-dIlYvdQQIrI',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuang_Si_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Luang Prabang',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-town-from-a-hill-hrgdvIinISI',
          wikiLink: 'https://en.wikipedia.org/wiki/Luang_Prabang',
        },
        landmarksRound: [
          { code: 'DO', number: 2 },
          { code: 'SD', number: 2 },
          { code: 'AL', number: 1 },
        ],
        landmarkPlaces: [
          'Daallo Mountain',
          'Mont Choungui',
          'Church of Our Lady of the Rosary',
          'Vajdahunyad Castle',
          'Boudhanath',
          'Burana Tower',
          'Little Bay',
        ],
        uniqueId: '2DbLKJxC',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Vientiane province',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-rice-field-during-daytime-g53FHDFECRQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Vientiane_province',
        },
        landmark: {
          name: 'Pha That Luang',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-many-buildings-NyMqkY7BCBc',
          wikiLink: 'https://en.wikipedia.org/wiki/Pha_That_Luang',
          hasLandmark: true,
        },
        city: {
          name: 'Vientiane',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-woman-walking-down-a-street-next-to-parked-cars-Uxr6PWHRSwk',
          wikiLink: 'https://en.wikipedia.org/wiki/Vientiane',
        },
        landmarksRound: [
          { code: 'DM', number: 2 },
          { code: 'TM', number: 4 },
          { code: 'GU', number: 3 },
        ],
        landmarkPlaces: [
          'Kagera waterfalls',
          'Emily Bay',
          'A-Ma Temple',
          'Big Ben',
          'The Ethnographic Open-Air Museum of Latvia',
          'National Museum of Arts, Rites and Traditions',
          'Lake Armor',
        ],
        uniqueId: 'B36vRnwP',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Latvia',
    code: 'LV',
    capital: 'Riga',
    lat: 57,
    long: 25,
    cities: ['Liepaja', 'Riga', 'Kuldiga', 'Cesis', 'Jelgava', 'Daugavpils'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/jFqUJacYIsw',
          wikiLink: 'https://en.wikipedia.org/wiki/Riga',
        },
        landmark: {
          name: 'House of the Black Heads',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/House_of_the_Blackheads_(Riga)#/media/File:House_of_Blackheads_at_Dusk_3,_Riga,_Latvia_-_Diliff.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/House_of_the_Blackheads_(Riga)',
          hasLandmark: true,
        },
        city: {
          name: 'Liepaja',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Liep%C4%81ja#/media/File:Liepaja_Blick_von_der_evangelischen_Dreifaltigkeitskathedrale_2.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Liep%C4%81ja',
        },
        landmarksRound: [
          { code: 'CH', number: 2 },
          { code: 'MU', number: 1 },
          { code: 'MG', number: 1 },
        ],
        landmarkPlaces: [
          'Sule Pagoda',
          'Monument to the Heroes of the Restoration',
          'Green Grotto Caves',
          '',
          'Chillon Castle',
          'Island of Mozambique',
          'Oriental Pearl TV Tower',
        ],
        uniqueId: '6yyz4p4L',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/latvia-riga-daugava-flow-3725546/',
          wikiLink: 'https://en.wikipedia.org/wiki/Latvia',
        },
        landmark: {
          name: "St. Peter's Church",
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/St._Peter%27s_Church,_Riga#/media/File:St._Peter's_Church_facade,_Riga,_Latvia_-_Diliff.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/St._Peter%27s_Church,_Riga',
          hasLandmark: true,
        },
        city: {
          name: 'Riga',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/riga-aerial-view-night-traffic-2935039/',
          wikiLink: 'https://en.wikipedia.org/wiki/Riga',
        },
        landmarksRound: [
          { code: 'BW', number: 1 },
          { code: 'FO', number: 2 },
          { code: 'FI', number: 2 },
        ],
        landmarkPlaces: [
          'Fort Sao Sebastiao',
          'Kathmandu Durbar Square',
          'Pico Basile',
          'English Harbour',
          'Monte Carlo Casino',
          'Petronas Towers',
          'Grytviken Church',
        ],
        uniqueId: 'N6nZby9X',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Latvia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-green-trees-during-daytime-DKL2nwMzT1E',
          wikiLink: 'https://en.wikipedia.org/wiki/Latvia',
        },
        landmark: {
          name: 'The Ethnographic Open-Air Museum of Latvia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/The_Ethnographic_Open-Air_Museum_of_Latvia#/media/File:Bergi_Ethnografisches_Museum_Bergi_Kurzeme_28.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/The_Ethnographic_Open-Air_Museum_of_Latvia',
          hasLandmark: true,
        },
        city: {
          name: 'Riga',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-RNf05GAbUTU',
          wikiLink: 'https://en.wikipedia.org/wiki/Riga',
        },
        landmarksRound: [
          { code: 'FO', number: 3 },
          { code: 'DJ', number: 1 },
          { code: 'FK', number: 3 },
        ],
        landmarkPlaces: [
          'Azadi Tower',
          'Gracanica Monastery',
          "St. Peter's Basilica",
          'National Museum of Costume',
          'Obelisk of Axum',
          'Katse Dam',
          'Plaza Francia',
        ],
        uniqueId: 'pzBl9mqM',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Latvia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-3GVX2-dWttU',
          wikiLink: 'https://en.wikipedia.org/wiki/Latvia',
        },
        landmark: {
          name: 'House of the Blackheads',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/House_of_the_Blackheads_(Riga)#/media/File:House_of_Blackheads_at_Dusk_3,_Riga,_Latvia_-_Diliff.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/House_of_the_Blackheads_(Riga)',
          hasLandmark: true,
        },
        city: {
          name: 'Daugavpils',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-during-sunset-RcG2dFkRX5c',
          wikiLink: 'https://en.wikipedia.org/wiki/Daugavpils',
        },
        landmarksRound: [
          { code: 'GD', number: 1 },
          { code: 'SV', number: 2 },
          { code: 'GW', number: 1 },
        ],
        landmarkPlaces: [
          'St. Peter the Apostle Cathedral',
          'Mount Kenya',
          'Szechenyi Thermal Bath',
          'Tallinn Town Hall',
          'Sibebe Rock',
          'Patuxai',
          "Two Lover's Point",
        ],
        uniqueId: 'bFXG4z6y',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Latvia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lake-surrounded-by-snow-covered-trees-and-a-forest-zxjtINsoA4A',
          wikiLink: 'https://en.wikipedia.org/wiki/Latvia',
        },
        landmark: {
          name: "St. Peter's Church",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/zug55/15275407834/',
          wikiLink: 'https://en.wikipedia.org/wiki/St._Peter%27s_Church,_Riga',
          hasLandmark: true,
        },
        city: {
          name: 'Riga',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-crossing-a-street-in-front-of-a-building-1svUl0r5-Rc',
          wikiLink: 'https://en.wikipedia.org/wiki/Riga',
        },
        landmarksRound: [
          { code: 'PG', number: 2 },
          { code: 'MG', number: 2 },
          { code: 'OM', number: 1 },
        ],
        landmarkPlaces: [
          'The Blue Eye',
          'Temple of Literature',
          'Anse Lazio',
          'Kastelholm Castle',
          'Regina Mundi Cathedral',
          'Source du Nil',
          'Umayyad City Ruins',
        ],
        uniqueId: 'PHspq5Sk',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Latvia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/grey-concrete-house-lot-uft-MemToy0',
          wikiLink: 'https://en.wikipedia.org/wiki/Latvia',
        },
        landmark: {
          name: 'Turaida Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/101999551@N07/26773107550/',
          wikiLink: 'https://en.wikipedia.org/wiki/Turaida_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Daugavpils',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-7pVUZ__AUUo',
          wikiLink: 'https://en.wikipedia.org/wiki/Daugavpils',
        },
        landmarksRound: [
          { code: 'UA', number: 2 },
          { code: 'BH', number: 2 },
          { code: 'MD', number: 1 },
        ],
        landmarkPlaces: [
          'Mount Alava',
          'Lake Lalolalo',
          'Grace Bay Beach',
          'Baie Larose',
          'Ulu Temburong National Park',
          'Borobudur Temple',
          'Riffa Fort',
        ],
        uniqueId: '4z3z4zRd',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Lebanon',
    code: 'LB',
    capital: 'Beirut',
    lat: 33.83333333,
    long: 35.83333333,
    cities: [
      'Tripoli',
      'Beirut',
      'Tyre',
      'Byblos',
      'Sidon',
      'Baalbek',
      'Mansourieh',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/KqEYj1VHA_o',
          wikiLink: 'https://en.wikipedia.org/wiki/Beirut',
        },
        landmark: {
          name: 'Pigeon Rocks',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Pigeon%27s_Rock_Beirut_Lebanon.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Raouch%C3%A9',
          hasLandmark: true,
        },
        city: {
          name: 'Tripoli',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tripoli,_Lebanon#/media/File:The_city_of_Tripoli,_Lebanon.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tripoli,_Lebanon',
        },
        landmarksRound: [
          { code: 'MD', number: 1 },
          { code: 'RU', number: 1 },
          { code: 'MU', number: 1 },
        ],
        landmarkPlaces: [
          'Burj Khalifa',
          'Predjama Castle',
          "Ruins of St. Paul's",
          "St. Stephen's Cathedral",
          'Kittiwake Shipwreck',
          'Buenos Aires Obelisk',
          'Twelve Apostles',
        ],
        uniqueId: 'HjMwTJtC',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/landscape-village-church-catholic-3663721/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lebanon',
        },
        landmark: {
          name: 'Baalbek',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-roman-temple-antique-3659506/',
          wikiLink: 'https://en.wikipedia.org/wiki/Baalbek',
          hasLandmark: true,
        },
        city: {
          name: 'Beirut',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cityscape-city-town-town-hall-3696637/',
          wikiLink: 'https://en.wikipedia.org/wiki/Beirut',
        },
        landmarksRound: [
          { code: 'QA', number: 1 },
          { code: 'PL', number: 1 },
          { code: 'KZ', number: 1 },
        ],
        landmarkPlaces: [
          'Ostrog Monastery',
          'Sydney Opera House',
          'Patuxay Monument',
          'Senso-ji',
          'Ouzoud Falls',
          'Christ Church Cathedral',
          'The Blue Mosque',
        ],
        uniqueId: 'jLpx8zdj',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Lebanon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-near-body-of-water-during-daytime-h8appowEHs8',
          wikiLink: 'https://en.wikipedia.org/wiki/Lebanon',
        },
        landmark: {
          name: 'Cedars of God',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cedars_of_God#/media/File:Forest_of_The_cedars_of_God.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cedars_of_God',
          hasLandmark: true,
        },
        city: {
          name: 'Beirut',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-during-daytime-V5jxkMKxv_0',
          wikiLink: 'https://en.wikipedia.org/wiki/Beirut',
        },
        landmarksRound: [
          { code: 'TF', number: 2 },
          { code: 'LV', number: 3 },
          { code: 'CI', number: 1 },
        ],
        landmarkPlaces: [
          'Santa Catalina Arch',
          "Memorial de l'Anse",
          'Arc de Triomphe',
          'Registan Square',
          'The Blue Mosque',
          'Roman Forum',
          'Lake Piso',
        ],
        uniqueId: 'YwXmWkky',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Lebanon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-desk-mBcY97cXOTs',
          wikiLink: 'https://en.wikipedia.org/wiki/Lebanon',
        },
        landmark: {
          name: 'Umayyad City Ruins',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Anjar,_Lebanon#/media/File:Anjar_-_Cardo_vu_du_nord_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Anjar,_Lebanon',
          hasLandmark: true,
        },
        city: {
          name: 'Tripoli',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tripoli,_Lebanon#/media/File:TripoliNahrAbuAli.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tripoli,_Lebanon',
        },
        landmarksRound: [
          { code: 'GI', number: 2 },
          { code: 'FR', number: 1 },
          { code: 'UY', number: 2 },
        ],
        landmarkPlaces: [
          'Fort Ceperou',
          'Kallur Lighthouse',
          'Mount Yasur',
          'Abu Simbel',
          "St. Peter's Basilica",
          'Metropolitan Cathedral of San Salvador',
          'Bissau Cathedral',
        ],
        uniqueId: 'hpppf8tc',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Byblos Port',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-beside-dock-Bc2EPt6bM5w',
          wikiLink: 'https://en.wikipedia.org/wiki/Byblos_Port',
        },
        landmark: {
          name: 'Temple of Bacchus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/beige-stone-temple-ruin-Fg_qZqZkyFk',
          wikiLink: 'https://en.wikipedia.org/wiki/Temple_of_Bacchus',
          hasLandmark: true,
        },
        city: {
          name: 'Beirut',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-skyline-across-body-of-water-during-night-time-wXc9kgXuzBc',
          wikiLink: 'https://en.wikipedia.org/wiki/Beirut',
        },
        landmarksRound: [
          { code: 'EE', number: 6 },
          { code: 'FK', number: 4 },
          { code: 'NZ', number: 2 },
        ],
        landmarkPlaces: [
          'Näsinneula',
          'Thaba Bosiu',
          'Chamarel Waterfall',
          'Brandenburg Gate',
          'Tallinn Town Hall',
          'Kagera waterfalls',
          'La Mano de Punta del Este',
        ],
        uniqueId: 'lBdcBK95',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Mansourieh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-on-green-mountain-under-white-clouds-during-daytime-zLHmIfJs0Fk',
          wikiLink: 'https://en.wikipedia.org/wiki/Mansourieh,_Lebanon',
        },
        landmark: {
          name: 'Anjar',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/brennwald/47605555922/',
          wikiLink: 'https://en.wikipedia.org/wiki/Anjar,_Lebanon',
          hasLandmark: true,
        },
        city: {
          name: 'Mansourieh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-city-l6TDXUai1S4',
          wikiLink: 'https://en.wikipedia.org/wiki/Mansourieh,_Lebanon',
        },
        landmarksRound: [
          { code: 'YT', number: 1 },
          { code: 'WF', number: 1 },
          { code: 'HR', number: 6 },
        ],
        landmarkPlaces: [
          'Tegallalang Rice Terrace',
          'Dolly Beach',
          'Kigali Genocide Memorial',
          'Church of Saint Lazarus',
          'Magnus Cathedral',
          "St. Elizabeth's Cathedral",
          'The 1763 Monument',
        ],
        uniqueId: 'FFyZycyp',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Lesotho',
    code: 'LS',
    capital: 'Maseru',
    lat: -29.5,
    long: 28.5,
    cities: [
      'Maseru',
      'Teyateyaneng',
      'Qachas Nek',
      'Mafeteng',
      'Butha Buthe',
      'Quthing',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/wg4Yd9iK0ho',
          wikiLink: 'https://en.wikipedia.org/wiki/Maloti_Mountains',
        },
        landmark: {
          name: 'Katse Dam',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Katse_Dam#/media/File:Katse_Dam.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Katse_Dam',
          hasLandmark: true,
        },
        city: {
          name: 'Maseru',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Maseru#/media/File:Maseru_from_Parliament_Hill.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Maseru',
        },
        landmarksRound: [
          { code: 'BR', number: 1 },
          { code: 'BZ', number: 1 },
          { code: 'CY', number: 1 },
        ],
        landmarkPlaces: [
          'Chimi Lhakhang',
          'Tash Rabat',
          'Monteverde Cloud Forest',
          'Rhine Falls',
          'Big Ben',
          'Vardzia',
          'Liwonde National Park',
        ],
        uniqueId: 'JjHC6s22',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lesotho-mountain-village-927577/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lesotho',
        },
        landmark: {
          name: 'Maluti Mountains',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Maloti_Mountains#/media/File:Snow_Capped_Maloti_Mountains_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Maloti_Mountains',
          hasLandmark: true,
        },
        city: {
          name: 'Teyateyaneng',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Ha_Mohapi_Village_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Teyateyaneng',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'X95c8QPf',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Lesotho',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-holding-beige-horse-URroWgc_x2w',
          wikiLink: 'https://en.wikipedia.org/wiki/Lesotho',
        },
        landmark: {
          name: 'Thaba Bosiu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Thaba_Bosiu#/media/File:685_Thaba_Bosiu.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Thaba_Bosiu',
          hasLandmark: true,
        },
        city: {
          name: 'Maseru',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-many-buildings--YtkaWPrJQM',
          wikiLink: 'https://en.wikipedia.org/wiki/Maseru',
        },
        landmarksRound: [
          { code: 'JO', number: 1 },
          { code: 'DO', number: 3 },
          { code: 'SZ', number: 2 },
        ],
        landmarkPlaces: [
          'Mount Fuji',
          'Allahverdi Khan Bridge',
          "Christian's Cave",
          'Taputapuatea marae',
          'Elmina Castle',
          'Senso-ji',
          'Cathedral Our Lady of the Rosary',
        ],
        uniqueId: 'NdN6GxfG',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Lesotho',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-near-lake-during-daytime-5wtBRWXCQp8',
          wikiLink: 'https://en.wikipedia.org/wiki/Lesotho',
        },
        landmark: {
          name: 'Kome Caves',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kome_Caves#/media/File:Kome_Cave_Dwellings_(Ha_Kome)_in_Lesotho_-_2382.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kome_Caves',
          hasLandmark: true,
        },
        city: {
          name: 'Teyateyaneng',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/33493806@N08/3113586845',
          wikiLink: 'https://en.wikipedia.org/wiki/Teyateyaneng',
        },
        landmarksRound: [
          { code: 'LT', number: 1 },
          { code: 'LT', number: 2 },
          { code: 'SZ', number: 2 },
        ],
        landmarkPlaces: [
          'Tavarua',
          'Charyn Canyon',
          'Belvedere Lookout',
          'Sri Siva Subramaniya Temple',
          'Palace of Versailles',
          'Parque Nacional de Malabo',
          'Independence Monument',
        ],
        uniqueId: 'jCY7QCdz',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Lesotho',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-walking-on-green-grass-field-BtCaXJ8BJjY',
          wikiLink: 'https://en.wikipedia.org/wiki/Lesotho',
        },
        landmark: {
          name: 'Thaba Bosiu',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/kalmenias/24129256492/',
          wikiLink: 'https://en.wikipedia.org/wiki/Thaba_Bosiu',
          hasLandmark: true,
        },
        city: {
          name: 'Maseru',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/96040147@N03/14335237915/',
          wikiLink: 'https://en.wikipedia.org/wiki/Maseru',
        },
        landmarksRound: [
          { code: 'GP', number: 1 },
          { code: 'HK', number: 1 },
          { code: 'MD', number: 3 },
        ],
        landmarkPlaces: [
          'Holy Trinity Cathedral',
          'Kpatawee Waterfall',
          'Regina Mundi Cathedral',
          'Panama Canal',
          'Tjentiste War Memorial',
          'Cliffs of Moher',
          'Million Dollar Point',
        ],
        uniqueId: 'fHm7kfPP',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Lesotho',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-near-body-of-water-under-white-clouds-during-daytime-6raP_K5VQXw',
          wikiLink: 'https://en.wikipedia.org/wiki/Lesotho',
        },
        landmark: {
          name: 'Maletsunyane Falls',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rajarajaraja/26949749492/',
          wikiLink: 'https://en.wikipedia.org/wiki/Maletsunyane_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Teyateyaneng',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/camara/4203042657/',
          wikiLink: 'https://en.wikipedia.org/wiki/Teyateyaneng',
        },
        landmarksRound: [
          { code: 'KM', number: 5 },
          { code: 'IT', number: 6 },
          { code: 'LU', number: 3 },
        ],
        landmarkPlaces: [
          'Nxai Pan National Park',
          'Buddha Dordenma statue',
          'Tatacoa Desert',
          'Bradda Head',
          'Lotto World',
          'Köl-Suu',
          'Rarotonga',
        ],
        uniqueId: 'HN3Cwdp4',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Liberia',
    code: 'LR',
    capital: 'Monrovia',
    lat: 6.5,
    long: -9.5,
    cities: [
      'Monrovia',
      'Gbarnga',
      'Zwedru',
      'Buchanan',
      'Voinjama',
      'Kakata',
      'Harper',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Liberia,_Africa_-_panoramio_%28255%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
        },
        landmark: {
          name: 'Kpatawee Falls',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/55953988@N00/5578860617',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
          hasLandmark: true,
        },
        city: {
          name: 'Kakata',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kakata#/media/File:City_of_Kakata,_photograph_of_main_road_near_BWI,_May_2012.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kakata',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '6k3M667m',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/6831730706',
          wikiLink: 'https://en.wikipedia.org/wiki/Mesurado_River',
        },
        landmark: {
          name: 'Lake Piso',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Another_view_of_Lake_Piso,_Bomi_County.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Piso',
          hasLandmark: true,
        },
        city: {
          name: 'Monrovia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Monrovia#/media/File:Liberia,_Africa_-_panoramio_(256).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Monrovia',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'gZ9gZ8Wk',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Liberia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/time-lapse-photography-of-thunderbolt-6ACcbfIycEg',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
        },
        landmark: {
          name: 'Liberian National Museum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/9815220@N03/4652210915',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberian_National_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Monrovia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Monrovia#/media/File:Liberia,_Africa_-_panoramio_(256).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Monrovia',
        },
        landmarksRound: [
          { code: 'GT', number: 1 },
          { code: 'TF', number: 1 },
          { code: 'MC', number: 1 },
        ],
        landmarkPlaces: [
          'Rock of Cashel',
          'Memorial da Escravatura e do Tráfico Negreiro',
          'Mont Ross',
          'Castle Rushden',
          'Larabanga Mosque',
          'Petronas Towers',
          'Umayyad City Ruins',
        ],
        uniqueId: 'yth8hvmP',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Liberia',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/unmil/14064974957',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
        },
        landmark: {
          name: 'Kpatawee Waterfall',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/itravelanddance/33630050541',
          wikiLink: 'https://www.wikidata.org/wiki/Q107126405',
          hasLandmark: true,
        },
        city: {
          name: 'Gbarnga',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/brettnbutter/33136311581',
          wikiLink: 'https://en.wikipedia.org/wiki/Gbarnga',
        },
        landmarksRound: [
          { code: 'KI', number: 3 },
          { code: 'MG', number: 1 },
          { code: 'VI', number: 1 },
        ],
        landmarkPlaces: [
          'Champagne Beach',
          'Tash Rabat',
          'Pipeline Mosque',
          'Tomb of Hafez',
          'Neuschwanstein Castle',
          'Islamic Center',
          "Saint Michael's Cave",
        ],
        uniqueId: 'zFzH2lL7',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Liberia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-tree-sitting-in-the-middle-of-a-park-wVxjXHTZi5Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
        },
        landmark: {
          name: 'Lake Piso',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/makgobokgobo/50778424873/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Piso',
          hasLandmark: true,
        },
        city: {
          name: 'Harper',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/59574265@N02/5450579696/',
          wikiLink: 'https://en.wikipedia.org/wiki/Harper,_Liberia',
        },
        landmarksRound: [
          { code: 'HK', number: 6 },
          { code: 'TZ', number: 2 },
          { code: 'GF', number: 1 },
        ],
        landmarkPlaces: [
          'Basilica of Our Lady of Guadalupe',
          'Kamianets-Podilskyi Castle',
          'Les Eclaireurs Lighthouse',
          'Lotus Temple',
          'Fort Zeelandia ',
          'Cinquantenaire Arcade',
          'Viru Gate',
        ],
        uniqueId: '75CpLBw9',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Liberia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-hill-with-palm-trees-SjPb6diu0Y4',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberia',
        },
        landmark: {
          name: 'Liberian National Museum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/trucka26/6739115191/',
          wikiLink: 'https://en.wikipedia.org/wiki/Liberian_National_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Monrovia',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/54942409@N08/51040562278/',
          wikiLink: 'https://en.wikipedia.org/wiki/Monrovia',
        },
        landmarksRound: [
          { code: 'PR', number: 4 },
          { code: 'SE', number: 1 },
          { code: 'VE', number: 1 },
        ],
        landmarkPlaces: [
          'Chillon Castle',
          'Angkor Wat',
          'Basilique Sainte Anne du Congo',
          'Argentine Military Cemetery',
          'Dragon and Tiger Pagodas',
          'Fort Zeelandia',
          'Great Mosque of Kufa',
        ],
        uniqueId: 'cVllKZHB',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Libya',
    code: 'LY',
    capital: 'Tripoli',
    lat: 25,
    long: 17,
    cities: [
      'Tripoli',
      'Benghazi',
      'Sirte',
      'Khoms',
      'Al Bayda',
      'Sabratah',
      'Ajdabiya ',
      'Al-Khums',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ci3Eot3SyEQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Sabratha',
        },
        landmark: {
          name: 'Leptis Magna',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Leptis_Magna_%2829%29_%288288918733%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Leptis_Magna',
          hasLandmark: true,
        },
        city: {
          name: 'Tripoli',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tripoli,_Libya#/media/File:Tripoli_Central_Business_District_from_Oea_Park.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Tripoli,_Libya',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'kts5RHn2',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/oasis-libya-desert-palm-trees-67549/',
          wikiLink: 'https://en.wikipedia.org/wiki/Libya',
        },
        landmark: {
          name: 'Marcus Aurelius Arch',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Arch_of_Marcus_Aurelius_-_libya_-_%D9%82%D9%88%D8%B3_%D9%85%D8%A7%D8%B1%D9%83%D9%88%D8%B3_%D8%A3%D9%88%D8%B1%D9%8A%D9%84%D9%8A%D9%88%D8%B32_-_%D9%84%D9%8A%D8%A8%D9%8A%D8%A7.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Arch_of_Marcus_Aurelius',
          hasLandmark: true,
        },
        city: {
          name: 'Benghazi',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Benghazi#/media/File:Al_Daawa_alIslamiyah2.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Benghazi',
        },
        landmarksRound: [
          { code: 'HK', number: 1 },
          { code: 'MT', number: 1 },
          { code: 'CH', number: 2 },
        ],
        landmarkPlaces: [
          'Senso-ji',
          'Heydar Aliyev Center',
          'Erbil Citadel',
          'Great Blue Hole',
          'Tian Tan Buddha',
          'Baalbek',
          'Skopje Fortress',
        ],
        uniqueId: 'FzNfYyZ8',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Tripoli',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-near-palm-trees-during-daytime-edecsA5q9xM',
          wikiLink: 'https://en.wikipedia.org/wiki/Tripoli,_Libya',
        },
        landmark: {
          name: 'Tadrart Acacus',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/michael_from_berlin/8726163539',
          wikiLink: 'https://en.wikipedia.org/wiki/Acacus_Mountains',
          hasLandmark: true,
        },
        city: {
          name: 'Tripoli',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-at-night-fWyXwlTEQew',
          wikiLink: 'https://en.wikipedia.org/wiki/Tripoli',
        },
        landmarksRound: [
          { code: 'TF', number: 2 },
          { code: 'FJ', number: 2 },
          { code: 'FJ', number: 1 },
        ],
        landmarkPlaces: [
          'Pont du Gard',
          'Mlilwane Wildlife Sanctuary',
          'Little Chapel',
          'National Museum of Costume',
          'Rabban Hormizd Monastery',
          'Fort Napoléon des Saintes',
          'Mausoleum of Khoja Ahmed Yasawi',
        ],
        uniqueId: 'nG6RWm3H',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Murzuq‎',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/desert-sand-during-dawn-Ggvj8lq_Fs8',
          wikiLink: 'https://en.wikipedia.org/wiki/Murzuq_District',
        },
        landmark: {
          name: 'Leptis Magna',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Leptis_Magna#/media/File:Leptis_Magna_(29)_(8288918733).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Leptis_Magna',
          hasLandmark: true,
        },
        city: {
          name: 'Benghazi',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Benghazi#/media/File:Al_Daawa_alIslamiyah2.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Benghazi',
        },
        landmarksRound: [
          { code: 'JP', number: 2 },
          { code: 'LI', number: 1 },
          { code: 'IL', number: 3 },
        ],
        landmarkPlaces: [
          'Victoria Falls',
          'Ocean Park',
          'Karnak',
          'Church of Our Lady of the Rosary',
          'The Basilica of Our Lady of Peace of Yamoussoukro',
          'Petronas Towers',
          'Big Ben',
        ],
        uniqueId: 'frqmXgML',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Libya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-car-on-desert-during-daytime-ttojS7IURi0',
          wikiLink: 'https://en.wikipedia.org/wiki/Libya',
        },
        landmark: {
          name: "Martyrs' Square",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/bensutherland/6343030875/in/photolist-aEvG6i-91kxe-dtBAUk-dAxoQp-7JAPtk-ai1MXj-7bswHw-aiUL4a-7boJ1n-2kAmadh-ahXRGZ-6PKE82-hKVDV1-bvzMwR-5oENfV-ahXPbk-2kAqih4-amLcjw-amHqSZ-i9Dju3-awpq2J-awmGB4-awppGq-jC4SqX-bv8qnx-hKVmV1-6vnV4a-mJvxbw-gUisGC-bv7vwa-bjBjQ5-aSYhap-7hSQqC-dVEqB1-2kAmad2-ahXU3M-cZ9wGW-bvnAUa-dVhp34-az2QSb-HRX2K3-awqa8y-azMXJr-awq9R1-amHjMK-bhYv8c-awq7US-bvuJYv-awnnQg-ag5xYQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Martyrs%27_Square,_Tripoli',
          hasLandmark: true,
        },
        city: {
          name: 'Ajdabiya ',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/societyforlibyanstudies/51810549814/in/photolist-2mWjBpC-a4T9fL-9SXYXP-a4Qh9V-9T1MYL-9MCZ9e-ZuD1KJ-a4T8Q5-9T1N3C-9uw5xy-9ut5k8-9T1MTu-9ut4jM-9ut3mX-9MFLwA-9ut3K8-9ut4QT-9ut49p-9uw4Q1-9uw4rQ-9ut4YM-9BnrEA-9uw3vq-9ut2gc-9ut4vK-9ut1Ge-9ut2NM-9ut5aD-9oYkaj-9uw3Vd-ZyFiWB-9ut3cB-9oVfng-9oVfiZ-9uw6qU-9uw3jb-9uw6Am-ackDVG-nuHW24-9ut5Zz-9BjyvK-9xF66q-9T1MMy-9MFLtW-9SXYWD-9usZXT-9uw1XW-9uw2Jd-9usZP8-9uw2mA',
          wikiLink: 'https://en.wikipedia.org/wiki/Ajdabiya',
        },
        landmarksRound: [
          { code: 'FJ', number: 3 },
          { code: 'FJ', number: 2 },
          { code: 'WS', number: 1 },
        ],
        landmarkPlaces: [
          'Angel Falls',
          'Berat Castle',
          'Acropolis',
          'Fortaleza de São José da Amura',
          'Cristo Rei',
          'Curchi Monastery',
          'Pedra da Gávea',
        ],
        uniqueId: 'xwsFy99N',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Tripoli ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-lit-up-at-night-with-a-clock-on-top-59atuKxvhXI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tripoli,_Libya',
        },
        landmark: {
          name: 'Arch of Marcus Aurelius',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/36338186@N05/6541010461/in/photolist-aY1owr-223wyCc-9eCtMd-34sY9H-yJSWw-JiV5d-dsMNNh-9ubthw-du9K2o-27uWvx-73bzgt-6MQLiK-9ezonK-7zDQLn-ayGeJC-9LudA8-7zHATs-6u1Yrj-4qSnN8-9LwYZ7-6AcbHS-6hPYiz-31ckb4-4asbVT-5PNEff-3VXDRH-4XMeQ7-372gz8-7wNsYJ-9R6rFW-yZ962-4LAC4D-ZpUVB-4XH2AZ-4LwGHt-rn6Mzs-5Ath5U-6hU7Tj-7D65X1-75T4CW-73g3eb-4qSjQ6-5wEaFm-9R6q2Y-5Ytpzk-63QZzG-aYpT1k-2kApETS-5Y7s3u-4A5HiE',
          wikiLink:
            'https://en.wikipedia.org/wiki/Arch_of_Marcus_Aurelius_(Tripoli)',
          hasLandmark: true,
        },
        city: {
          name: 'Al-Khums',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-and-brown-land-near-body-of-water-during-daytime-wVpY6W477xo',
          wikiLink: 'https://en.wikipedia.org/wiki/Al-Khums',
        },
        landmarksRound: [
          { code: 'GF', number: 4 },
          { code: 'CR', number: 3 },
          { code: 'GL', number: 2 },
        ],
        landmarkPlaces: [
          "Kabaka's Palace",
          'Christ Church Cathedral ',
          'Queen Emma Bridge',
          'Karlštejn Castle',
          'East Side Gallery',
          'Kibira National Park',
          'Kalandula Falls',
        ],
        uniqueId: 'dNKQnkV8',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    capital: 'Vaduz',
    lat: 47.26666666,
    long: 9.53333333,
    cities: ['Triesenberg', 'Vaduz', 'Triesen', 'Balzers', 'Mauren', 'Schaan'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/4CKnbVZ4Tew',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz_Castle',
        },
        landmark: {
          name: 'Vaduz Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Vaduz_Cathedral#/media/File:Vaduz_Kathedrale_St._Florin_01.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Triesenberg',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Oben_am_jungen_Rhein.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Triesenberg',
        },
        landmarksRound: [
          { code: 'SI', number: 1 },
          { code: 'TW', number: 2 },
          { code: 'CZ', number: 1 },
        ],
        landmarkPlaces: [
          'Cathedral of Brasilica',
          'Gangtey Monastery',
          'Old Dongola',
          'Sukhbaatar Square',
          'Grandvalira',
          'Castle Rushden',
          'Tsingy De Bemaraha',
        ],
        uniqueId: 'mHmsjwMH',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/liechtenstein-city-architecture-176116/',
          wikiLink: 'https://en.wikipedia.org/wiki/Liechtenstein',
        },
        landmark: {
          name: 'Vaduz Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/castle-fortress-forest-fog-trees-5704327/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Vaduz',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/liechtenstein-r%c3%a4tikon-vaduz-7145060/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '2cPYSLKm',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Vaduz',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-near-building-during-daytime-5aJlZgteKzc',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz',
        },
        landmark: {
          name: 'Castle Vaduz',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/castle-on-mountain-surrounded-by-trees-4CKnbVZ4Tew',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Vaduz',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-town-in-front-of-a-mountain-qdT890J2Rgw',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz',
        },
        landmarksRound: [
          { code: 'GQ', number: 4 },
          { code: 'CI', number: 4 },
          { code: 'GR', number: 2 },
        ],
        landmarkPlaces: [
          'Lope National Park',
          'Chichen Itza',
          'Magnus Cathedral',
          'Akanda National Park',
          'The 1763 Monument',
          'Katse Dam',
          'Burj Khalifa',
        ],
        uniqueId: 'rsnvf7Vt',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Liechtenstein',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/four-brown-wooden-house-on-snow-covered-field-T9yKWV4xdUs',
          wikiLink: 'https://en.wikipedia.org/wiki/Liechtenstein',
        },
        landmark: {
          name: 'Alte Rheinbrücke',
          photographer: '',
          imageLink:
            'https://de.wikipedia.org/wiki/Alte_Rheinbr%C3%BCcke_Vaduz%E2%80%93Sevelen#/media/Datei:Alte_Rheinbr%C3%BCcke.jpg',
          wikiLink:
            'https://de.wikipedia.org/wiki/Alte_Rheinbr%C3%BCcke_Vaduz%E2%80%93Sevelen',
          hasLandmark: true,
        },
        city: {
          name: 'Schaan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-zg71yHR8Sh4',
          wikiLink: 'https://en.wikipedia.org/wiki/Schaan',
        },
        landmarksRound: [
          { code: 'IQ', number: 1 },
          { code: 'MC', number: 1 },
          { code: 'GQ', number: 1 },
        ],
        landmarkPlaces: [
          'Petronas Towers',
          "Lac 'Assal",
          'Alexander Nevsky Cathedral',
          'Sri Siva Subramaniya Temple',
          'Peel Castle',
          'Ostrog Monastery',
          'Copán',
        ],
        uniqueId: 'fnVRRmZP',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Liechtenstein',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photography-of-mountain-covered-with-snow-4WyiTpCB2SY',
          wikiLink: 'https://en.wikipedia.org/wiki/Liechtenstein',
        },
        landmark: {
          name: 'Vaduz Castle',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/wkratz/15198315128/in/photolist-98uBZt-r5XT8N-mEocEV-pa2kA1-75iz6Y-XHTHgF-qCFmWn-FBP3F7-CcFgfZ-Zd36iU-FcAxf4-eoSr4U-XpzGMn-nFrL81-o7mQzC-BhU5ZJ-4ipraB-VANPAn-NhGzoX-2aEeLoz-2aEeJPn-VVWPhm-a8uWcQ-Ks8cRP-NhGxk8-pSEk4g-XSCwrw-dk5j9H-WP1BCE-WP1Dpf-aNnogk-bAU8iK-WP1Coh-7o797T-CFT3Qa-e1n7jf-JNxEZg-Xadiey-XvaPe9-pH8L8E-6LxKzm-25pRA2Y-ghMwAu-8XZJrD-p1NcsL-oe4hrm-9Ugr7J-yzHbvA-2cUSprj',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Balzers',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/61295848@N02/36361495696/in/photolist-Xp9aRA-2iwjK4G-atvpVW-YcdbsE-8FxPmF-BFtpd-BFtpi-y3Tiv6-2hYDzdp-21xL1c-avLWnb-2iM233Y-2iLWztp-2iLZmHm-2iLWzT2-2iM23fS-2iM23dh-2iLWzvo-2iLWzLd-2iLZmFn-2iM235G-2iM23hR-5MNaZh-L4cNL-5v5ox2-9YSYfv-dBhuok-feeARE-5v9HNd-5v9HLU-brdMg4-91rt18-pXVpKh-dq4o3n-nFb4ze-5v9HKq-aHTDmM-2nSsCVb-aSdDaa-pXBAzD-pVFUiy-pXBChB-5v9HRQ-pFp4ts-brdHHt-h3Uhud-5v9HUb-D9cAF-e1Sc8V-pFp3Dm',
          wikiLink: 'https://en.wikipedia.org/wiki/Balzers',
        },
        landmarksRound: [
          { code: 'VN', number: 3 },
          { code: 'PF', number: 5 },
          { code: 'AO', number: 4 },
        ],
        landmarkPlaces: [
          'Herat Citadel',
          'Taputapuatea marae',
          'Ban Gioc–Detian Falls',
          'Nesbitt Castle',
          'Anse Lazio',
          'Kalandula Falls',
          'The Marble Mountains',
        ],
        uniqueId: 'WWdVhtTV',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Vaduz',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-during-daytime-r7xZNCuVMUs',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz',
        },
        landmark: {
          name: 'Cathedral of St. Florin',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/linuxdogm/4290992249/in/photolist-7xbsuZ-NAgBuS-vWLCpe-vEarBb-vEh9N8-AA9MCa-2oWXv2u-2oWYziS-2oWUssD-AnZzrD-2oWXv4D-4QPJep-MFD75b-Xo48qi-feTsG5-2kH3m9x-BcNRCa-a9kcru-2oXgoaA-2oWZx6p-8v4upH-2oWZRQP-8v4uKc-2hBjNwb-2hBjNEN-2hBiUz4-dk5Djr-AxR51g-AxQSeG-8v4uuB-8v4vcF-8v7zcs-8v7xGd-8v7xSh-8v7xqE-8v7ycy-BaBj9U-6qBcgo-2p3kXj6-DekT9C-2oWY5gV-5GH9Uf-2oX8M4n-2oWYPgL-2oWYqAR-6zLL2L-2oWZknY-2oWZ3Pu-2oWUaAx-2oX8aUf',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaduz_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Triesen',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/liechtenstein_tourismus/8634292366/in/photolist-e9Z51G-e9Tp8Z-e9Z1iL-DmyUaA-juFoqh-2iyELt7-DmUeak-DmUo42-DSaMaL-DmyXcN-DSaTF1-DmUoRz-E8R3Cy-Eb45MB-E8R5By-DmUtMM-DKNx7c-E8QTgh-DmyJ6C-DmUsQM-DmyJFq-DmyGVG-E8Rbah-Eb3ZUv-Eb3ZEn-2iyEKeP-2q7QCkj-2q7ViEY-2q7X4n3-2nZ6pxT-7UtJ65-Yxq7WN-2pVXsoA-4J8kKX-ZVr5SY-9hnogN-Eb4d5H-DKNtCi-DKNvuz-DmyTD5-EgY6GS-Eb41DB-B9vCXN-9EmvYN-AA9G1D-BaBd4s-2GyVrF-2q7QCjT-ZgRNyC-Jf9BdM',
          wikiLink: 'https://en.wikipedia.org/wiki/Triesen',
        },
        landmarksRound: [
          { code: 'VA', number: 4 },
          { code: 'ER', number: 2 },
          { code: 'IE', number: 2 },
        ],
        landmarkPlaces: [
          'Oceania House ',
          'Borobudur Temple',
          'Wat Arun',
          'Cape of Good Hope',
          'Sydney Opera House',
          "N'Djamena Grand Mosque",
          'Bled Castle',
        ],
        uniqueId: '2psvWJQn',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Lithuania',
    code: 'LT',
    capital: 'Vilnius',
    lat: 56,
    long: 24,
    cities: [
      'Kaunas',
      'Klaipeda',
      'Palanga',
      'Kedainiai',
      'Druskininkai',
      'Vilnius',
      'Klaipėda',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NMV4RIqrP-A',
          wikiLink: 'https://en.wikipedia.org/wiki/Trakai',
        },
        landmark: {
          name: 'Gediminas Castle Tower',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Gediminas%27_Tower#/media/File:Gedimino_pilis_by_Augustas_Didzgalvis.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gediminas%27_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Vilnius',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-vilnius-capital-europe-2764129/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vilnius',
        },
        landmarksRound: [
          { code: 'DE', number: 1 },
          { code: 'CH', number: 2 },
          { code: 'BH', number: 1 },
        ],
        landmarkPlaces: [
          'Grytviken Church',
          'Amberd',
          'Pulhapanzak Waterfalls',
          'Bratislava Castle',
          'Middle of the World City',
          'Tikal National Park',
          'Pico Basile',
        ],
        uniqueId: 'Sc3t5V5T',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vilnius-lithuania-eastern-europe-1029634/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lithuania',
        },
        landmark: {
          name: 'Vilnius Cathedral',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lithuania-vilnius-cathedral-912362/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vilnius_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Kaunas',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lithuania-kaunas-city-3721840/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaunas',
        },
        landmarksRound: [
          { code: 'VA', number: 1 },
          { code: 'TJ', number: 2 },
          { code: 'FO', number: 1 },
        ],
        landmarkPlaces: [
          'Silver Pagoda',
          'Ostrog Monastery',
          'Macau Tower',
          'Deception Island',
          'Kelonia',
          'Amber Palace',
          'Fort Sao Sebastiao',
        ],
        uniqueId: 'LJy3wfXT',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Lithuania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/view-of-dock-at-river-bank-xGltPaX1FeY',
          wikiLink: 'https://en.wikipedia.org/wiki/Lithuania',
        },
        landmark: {
          name: 'Hill of Crosses',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Hill_of_Crosses#/media/File:Colina_de_las_Cruces,_Lituania,_2012-08-09,_DD_12.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Hill_of_Crosses',
          hasLandmark: true,
        },
        city: {
          name: 'Vilnius',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-down-a-street-next-to-tall-buildings-yMyFR4UggNI',
          wikiLink: 'https://en.wikipedia.org/wiki/Vilnius',
        },
        landmarksRound: [
          { code: 'GA', number: 2 },
          { code: 'GA', number: 4 },
          { code: 'US', number: 2 },
        ],
        landmarkPlaces: [
          'Tash Rabat',
          "People's Liberation Army Museum",
          'Molinere Underwater Sculpture Park',
          'Maluti Mountains',
          'Fort Zeelandia',
          'Rock of Cashel',
          "St. Olaf's Church",
        ],
        uniqueId: 'YY6khVLR',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Lithuania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-hot-air-balloon-flying-over-a-large-body-of-water-NMV4RIqrP-A',
          wikiLink: 'https://en.wikipedia.org/wiki/Lithuania',
        },
        landmark: {
          name: 'Trakai Island Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Trakai_Island_Castle#/media/File:Traku_pilis_by_Augustas_Didzgalvis.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Trakai_Island_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Kaunas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-at-night-from-a-hill-dE45iUwsCOI',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaunas',
        },
        landmarksRound: [
          { code: 'HT', number: 1 },
          { code: 'GT', number: 3 },
          { code: 'LY', number: 1 },
        ],
        landmarkPlaces: [
          'Grand Ducal Palace',
          "Memorial de l'Anse",
          'House of the Blackheads',
          'Day Forest National Park',
          'Stonehenge',
          'Loango National Park',
          'Mlilwane Wildlife Sanctuary',
        ],
        uniqueId: 'YpCh7Gvt',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Lithuania',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/elenakurlaviciute/22711828872/in/photolist-AAY4sq-2nZkVDu-2mWJ8dY-2iVyC5e-2mNvU4g-ACayEi-zF8Je1-QPjvB4-zxuhBe-2nkFD1e-cMYRwU-7JhtKe-adPzAc-2c1iWLw-5vmzbX-5vrkK3-2n841cQ-2n8MvQ5-2iVyNmg-21zp71o-ew1ao2-2n8Muvg-4KJvtF-2ksHBAM-G2NdnT-fGRwYc-adPxk4-zF8NPd-2mPv3et-21UuKdr-Bs23WC-62Yshn-2hXqXGQ-9r4tPk-e2J9vN-2iWjsJj-PEdXn2-2n84q25-ehrcCo-Rc1XzF-ae1W1n-AAY73A-adVJMU-PmMVcZ-9r7cKo-K3MUZA-V5Upsk-ae4KX7-3ur6BG-bstdkG',
          wikiLink: 'https://en.wikipedia.org/wiki/Lithuania',
        },
        landmark: {
          name: 'Trakai Island Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-brick-building-sitting-next-to-a-body-of-water-F_2AWWUZJ9E',
          wikiLink: 'https://en.wikipedia.org/wiki/Trakai_Island_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Kaunas',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/158901045@N05/52190586071/in/photolist-2nvUoWT-Yhp2bw-HWtkmv-xxd4E-ortTJS-ojssL8-oHP3eb-oJei7i-oJEdeL-2nCfcqX-oY9JTd-2nktyJX-2nksqNa-2nkspRf-2nktx2d-2nksnpd-2nkn247-2nkuQ6H-2nkspy6-Q3J3fe-2nMwPhJ-nGLBn-oA3yGf-oCJ4bi-6khCBx-R47why-9KFK1n-PZXomQ-RmB5qt-QsCQyo-MErFte-R47wUq-2nktxsU-2nktxps-2nYZSLF-y3E3rJ-MR4y68-2i4uXSp-2i4uXYB-qxUhxr-69PWd6-pZbyMB-2i4tZpi-2dJ381x-2nkuPGw-8WAasM-2n7KSNK-2i4uYaJ-pTmgV1-QCpRLJ/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaunas',
        },
        landmarksRound: [
          { code: 'DK', number: 1 },
          { code: 'VE', number: 4 },
          { code: 'BB', number: 5 },
        ],
        landmarkPlaces: [
          'Mount Fuji',
          "Kabaka's Palace",
          'The Grand Palace',
          'Mont Ross',
          'Blagaj Tekija',
          'Emi Koussi',
          'Azadi Tower',
        ],
        uniqueId: 'tvhPcsDH',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Vilnius',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-black-and-white-photo-of-a-building-S6FCH5FqBfE',
          wikiLink: 'https://en.wikipedia.org/wiki/Vilnius',
        },
        landmark: {
          name: 'Vilnius Cathedral',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-church-with-a-steeple-and-a-clock-tower-AUSJJQoWQH8',
          wikiLink: 'https://en.wikipedia.org/wiki/Vilnius_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Klaipėda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-person-riding-a-bike-down-a-dirt-road-POiRyPpwLSQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Klaip%C4%97da',
        },
        landmarksRound: [
          { code: 'MX', number: 1 },
          { code: 'VI', number: 1 },
          { code: 'TF', number: 3 },
        ],
        landmarkPlaces: [
          'Molinere Underwater Sculpture Park',
          'Tsingoni Mosque',
          'Devin Castle',
          'Day Forest National Park',
          'Edinburgh Castle',
          'Church of Our Savior',
          'Catedral da Sé',
        ],
        uniqueId: 'gQ3NBj2M',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    capital: 'Luxembourg City',
    lat: 49.75,
    long: 6.16666666,
    cities: [
      'Echternach',
      'Luxembourg City',
      'Clervaux',
      'Wiltz',
      'Esch-sur-Alzette',
      '',
      'Diekirch',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/FPdyoYNdvaM',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg_City',
        },
        landmark: {
          name: 'Vianden Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vianden-castle-luxembourg-landmark-1137019/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vianden_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Echternach',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/echternach-luxembourg-hiking-6133761/',
          wikiLink: 'https://en.wikipedia.org/wiki/Echternach',
        },
        landmarksRound: [
          { code: 'MZ', number: 2 },
          { code: 'BD', number: 2 },
          { code: 'MM', number: 2 },
        ],
        landmarkPlaces: [
          'Cathedral Our Lady of the Rosary',
          'Middle of the World City',
          "St. Stephen's Cathedral",
          'Saqsayhuaman',
          'Iluissat Icefjord',
          'Old Panama',
          'Acropolis',
        ],
        uniqueId: '7KHqqZTd',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/luxembourg-luxembourg-city-landscape-1164663/',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg',
        },
        landmark: {
          name: 'Grand Ducal Palace',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Grand_Ducal_Palace,_Luxembourg#/media/File:Palacio_Gran_Ducal_de_Luxemburgo.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Grand_Ducal_Palace,_Luxembourg',
          hasLandmark: true,
        },
        city: {
          name: 'Luxembourg City',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/luxembourg-basic-historic-center-2354945/',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg_City',
        },
        landmarksRound: [
          { code: 'SI', number: 1 },
          { code: 'PT', number: 1 },
          { code: 'IS', number: 1 },
        ],
        landmarkPlaces: [
          'Easter Island',
          'Christiansborg Palace',
          'Amber Palace',
          'Macau Tower',
          'Pyramiden',
          'Pulhapanzak Waterfalls',
          'Chillon Castle',
        ],
        uniqueId: 'YjNr6txQ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Luxembourg City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-building-0mY01y72UKo',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg_City',
        },
        landmark: {
          name: "Vianden's Castle",
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-castle-surrounded-by-green-trees-under-white-clouds-and-blue-sky-during-daytime-PWEhm7nNqKE',
          wikiLink: 'https://en.wikipedia.org/wiki/Vianden_Castle',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'MS', number: 1 },
          { code: 'LB', number: 3 },
          { code: 'GN', number: 3 },
        ],
        landmarkPlaces: [
          'Arahoho Blowhole',
          'Akanda National Park',
          'Egyptian Museum',
          'Fort Ceperou',
          'Ampitheater of El Jem',
          'Sistine Chapel',
          'Mumbo Island',
        ],
        uniqueId: 'glSpvYnS',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Luxembourg',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-river-under-cloudy-sky-during-daytime-8YYeRlD86uk',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg',
        },
        landmark: {
          name: 'Cathédrale Notre-Dame',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Cath%C3%A9drale_Notre-Dame_de_Luxembourg#/media/Fichier:Luxembourg,_G%C3%ABlle_Fra_full_moon_(101).jpg',
          wikiLink:
            'https://fr.wikipedia.org/wiki/Cath%C3%A9drale_Notre-Dame_de_Luxembourg',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'GU', number: 2 },
          { code: 'IS', number: 4 },
          { code: 'SV', number: 3 },
        ],
        landmarkPlaces: [
          'Rabban Hormizd Monastery',
          'Hagia Sophia',
          'Gateway Arch',
          'Demerara Bridge',
          'Castle Vaduz',
          'Vaduz Castle',
          'Jerash',
        ],
        uniqueId: 'YtRBCctp',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Luxembourg',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-palace-on-forest-X30mNjC_oOY',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg',
        },
        landmark: {
          name: 'Grand Ducal Palace',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/heatheronhertravels/21987290032/in/photolist-BGcq1s-zevhr6-zuWBis-vRHPbA-gXNBmx-mzff9R-d3hNhf-zvfgUm-ovonfi-cnV1bE-8BHxot-G7vtRC-cBpsmd-zxcnEx-8JNfAP-f7i8Yg-d6qF5Y-8BLEks-3fsxse-DR9How-o1PMo5-nG2z6M-8NyHzd-nJe6Rd-7RbTr5-Q6TKj9-zk54ne-8s6BN8-LK7iuR-5FM5fy-Cxu2H4-5FHcQn-CxrzXB-zjYS9m-7vuFHD-8DPUmc-Q6TJNE-ovhwXZ-aedLNd-C6mrYA-QERq2H-abC28Q-2mQ8DQv-2ofwo9d-2mQ3yf3-265XZ7s-2pE1gbG-2iyJS5a-5VGy4i-2jtyuY9/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Grand_Ducal_Palace,_Luxembourg',
          hasLandmark: true,
        },
        city: {
          name: 'Diekirch',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/glanerbrug/25290526521/in/photolist-EwQyqH-E7BAZZ-EuDw55-oPbyKC-2iN1FSk-2iMXUTo-2iMXUUq-2iMXUSb-2iN3npa-9xYykT-46WpD-tbAxG-tbzHe-tbAxD-tbzHn-tbzHk-tbzHi-tbzHj-tbAxE-tbzHp-8a512A-2iN1FRd-r9vNXp-r9vPjM-s6bihQ-fuXhc-fuXhd-2jD6JPE-2onLDXP-2q5BB42-2nRBVyT-2nujsGj-PKnXJK-ezyPMS-a6zD1a-a6zCZM-a6zCZ6-a6zCZe-2nRp6VL-2pK5VmJ-a6zCYX-eysonn-o44hNe-2jGwAAY-U3uD4N-s6jdY6-fHPeF5-DZGTtJ-2nYwby-8devw2',
          wikiLink: 'https://en.wikipedia.org/wiki/Diekirch',
        },
        landmarksRound: [
          { code: 'TO', number: 2 },
          { code: 'MX', number: 4 },
          { code: 'SV', number: 5 },
        ],
        landmarkPlaces: [
          'Cathedral Our Lady of the Rosary',
          'George Washington House',
          'Copán',
          'Hungarian Parliament Building',
          'Cologne Cathedral',
          'Old Panama',
          'Torre de la Libertad',
        ],
        uniqueId: 'GZYvbGQ9',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Luxembourg City',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/97088698@N06/52509906808/in/photolist-2o17ZSQ-2mKfaxn-2og5SF2-2hRocid-QQx45f-2k5Ry3w-XG4Rrd-vdpjtH-o4Bje5-2gZR5hW-2h9uA8W-2dpHqFP-YrUGrz-2gVPobD-2hn9KA5-2iGfQeW-2hsXMGE-2ogkEsP-RbWkWp-FnuVu5-PMkg3q-2hm69E1-2nPnQkY-2gXxhWa-2hMt6bs-UefiQU-nbP9Eq-2j5wcGU-2i8pzwc-2nnPZ8N-2q7qF7u-2dwEaNX-SbvTik-2o3DN6m-VVz4o1-2iKyDpA-2f5uQJu-PMk3Qu-pfBdv3-2hKzBEx-25Vbsq5-2e1dUew-2g3729U-2gYQ23r-AdBAs1-NKhskT-2og8qWn-RzPDYi-27C2d3S-25vfziq',
          wikiLink: 'https://en.wikipedia.org/wiki/Luxembourg_City',
        },
        landmark: {
          name: 'Adolphe Bridge',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/154254464@N08/38214069664/in/photolist-21dR6y5-3fsgeT-7cPr6D-8n6T61-2KAmcT-dcMUoh-3ePwbH-koJfDg-bK36h8-84naF7-29Xvu3c-gxY7iN-dwSyPz-ehiFPA-abzbGr-e7zx8Y-9ehsK3-ccRfnf-nn6kgv-sXXdPJ-aE6w9M-8ZHwkE-e7tUaB-6rf5YB-248rtCa-QgoHTt-2cz2LQ1-2c8tHCH-Z2o2AA-24v2b6N-pHJEZG-2aY3rNj-Ebb6vT-o74EZ6-26eeLPn-25HW1To-9TATN5-aPNzg4-HGBqeF-aGyE4n-e7x4CH-cHVofJ-z5Bu3-22r9jXn-25ad9De-av2m6f-av2m4b-RSt3Rc-MZdcCG-bVkeHF',
          wikiLink: 'https://en.wikipedia.org/wiki/Adolphe_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Esch-sur-Alzette',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/claudiusbinoche/15846572740/in/photolist-q9iPZo-2mY8mzo-2nxN9Sx-9feSQx-EBvNV-EBvJL-EBvHA-EBvxm-EBvUx-EBvL3-EBvQz-EBvME-EBvzu-EBvM7-NXzEKH-EBvwE-EBvJD-EBvGQ-2oHFHbH-2oEpjzz-2nQHoCu-2nzUVWs-Af1gQE-2pPYqqP-2oEnJ7V-2NKsr1-EBvV2-2j8mKoK-2nPk3Y4-2oHmn6B-21T2wZJ-2pC6TWW-2oH873r-EBvCL-EBvuY-2NF9Ea-EBvCp-EBvRS-EBvWm-EBw45-2NF9gn-2NKqfL-EBvZj-EBw2y-2NKkTN-2NKrQU-EBvXX-2NKnih-2NKnQb-EBvYC',
          wikiLink: 'https://en.wikipedia.org/wiki/Esch-sur-Alzette',
        },
        landmarksRound: [
          { code: 'GT', number: 4 },
          { code: 'BF', number: 2 },
          { code: 'KH', number: 1 },
        ],
        landmarkPlaces: [
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Villers Abbey',
          'Annaberg Sugar Plantation',
          'National Museum of Bermuda',
          'Trakai Island Castle',
          'Champagne Reef, Dominica',
          'Gishora Drum Sanctuary',
        ],
        uniqueId: 'C4yWvYlr',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Macau',
    code: 'MO',
    capital: 'Macau',
    lat: 22.198745,
    long: 113.543873,
    cities: ['Santo António', 'São Lázaro', 'Macau'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/dZbJOsTl4PM',
          wikiLink: 'https://en.wikipedia.org/wiki/Casino_Lisboa_(Macau)',
        },
        landmark: {
          name: "Ruins of St. Paul's",
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/macau-macao-asia-china-tourism-5149823/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ruins_of_Saint_Paul%27s',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'PR', number: 1 },
          { code: 'BO', number: 1 },
          { code: 'UA', number: 1 },
        ],
        landmarkPlaces: [
          'Romney Manor',
          'Kaaba',
          'Cape of Good Hope',
          'Al Fateh Grand Mosque',
          'Anne Frank House',
          "Dunn's River Falls",
          'Angel Falls',
        ],
        uniqueId: 'cTDXm5xb',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/macao-macau-china-architecture-4769469/',
          wikiLink: 'https://en.wikipedia.org/wiki/Macau',
        },
        landmark: {
          name: 'Macau Tower',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/macau-tower-nanwan-lake-panoramic-1730546/',
          wikiLink: 'https://en.wikipedia.org/wiki/Macau_Tower',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'US', number: 1 },
          { code: 'LT', number: 1 },
          { code: 'BR', number: 2 },
        ],
        landmarkPlaces: [
          'Chamarel Waterfall',
          'Aleppo Citadel',
          'Palace of Versailles',
          'Wat Arun',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Somapura Mahavihara',
          'Gangtey Monastery',
        ],
        uniqueId: 'zlclbnL4',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Macau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-street-during-daytime-ziUS6LTwWDc',
          wikiLink: 'https://en.wikipedia.org/wiki/Macau',
        },
        landmark: {
          name: 'A-Ma Temple',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/A-Ma_Temple#/media/File:The_Prayer_Hall_(A-Ma_Temple).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/A-Ma_Temple',
          hasLandmark: true,
        },
        city: {
          name: 'Macau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-street-during-daytime-ziUS6LTwWDc',
          wikiLink: 'https://en.wikipedia.org/wiki/Macau',
        },
        landmarksRound: [
          { code: 'LU', number: 2 },
          { code: 'DE', number: 3 },
          { code: 'SZ', number: 4 },
        ],
        landmarkPlaces: [
          'Molinere Underwater Sculpture Park',
          'Castillo San Cristobal',
          'Lake Piso',
          'Grace Bay Beach',
          'Sibebe Rock',
          'Montserrat Volcano Observatory',
          'Abu Simbel Temples',
        ],
        uniqueId: 'SDqtClqC',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Macau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-near-brown-concrete-building-during-daytime-TFS_MtsZHaE',
          wikiLink: 'https://en.wikipedia.org/wiki/Macau',
        },
        landmark: {
          name: 'Ruínas de São Paulo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ruins_of_Saint_Paul%27s#/media/File:%E5%A4%A7%E4%B8%89%E5%B7%B4%E7%89%8C%E5%9D%8A.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ruins_of_Saint_Paul%27s',
          hasLandmark: true,
        },
        city: {
          name: 'Taipa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:Taipa_Building_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Taipa',
        },
        landmarksRound: [
          { code: 'MW', number: 1 },
          { code: 'GQ', number: 3 },
          { code: 'IT', number: 1 },
        ],
        landmarkPlaces: [
          'Fort Cépérou',
          'Cathedral Our Lady of the Rosary',
          'Abu Simbel Temples',
          'Abuna Yemata Guh',
          'Petronas Towers',
          'Joya De Ceren Archeological Site',
          'Castle Vaduz',
        ],
        uniqueId: 'Z2Cs54Lp',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Macau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-down-a-street-next-to-tall-buildings-Kl46uyMmu3g',
          wikiLink: 'https://en.wikipedia.org/wiki/Macau',
        },
        landmark: {
          name: 'Senado Square',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/beaversdam/4807512898/in/photolist-8jPL6q-2WLGes-4DDfZh-9bED8a-o6wJtb-5M5iTw-51sWzo-6VcnyG-5M5jHU-5M16oV-vfmDN-6PN1hY-5M5iwd-5M17yn-8xJQZy-6PN1bW-vfmMm-5M15ZH-cbDc7u-5M15DK-ppnu5-7t2AKU-ybFn8y-bxMYr-49hTwu-5M5h3Q-vfmFh-5M5gFL-2ja5JKT-qSb7ik-2pMDQ2c-2pMyg54-2pMyg9x-2pMF1Zu-2pMExrh-2pMDQ6a-7t2ATW-6GTTyK-aVj8uX-6HkxwC-o6q6hJ-nP3tFB-aVj9wD-6GXXWQ-o8iXBT-21RbtgA-8vvyVe-oPVen1-22J9RmY-oPCJb7',
          wikiLink: 'https://en.wikipedia.org/wiki/Senado_Square',
          hasLandmark: true,
        },
        city: {
          name: 'Santo António',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/28432918@N04/52975779876/in/photolist-2oHhHH7-QP33PP-2ptZZ99-2mfgiFy-V2jRF2-25Qoip1-V2jSXa-58y6Gw-ULXczf-28sV1Er-ULXdRo-2ctvkyP-2aEaRAw-2jjTEUh-2kwnWVV-2ctvjwP-UPBtBM-V2k7o8-2ph5zWf-ULXgEQ-V2k5Jr-pzavFH-V2jShH-V2k2Fn-UPBBGz-V2jRY6-TMM8vM-UPBjCz-V2k4qK-ULXxdo-Qs2uW3-ULXfd1-V2jSBv-4MaCCi-ULXhPo-2mTD2je-2d7X3EV-2j6vfv4-2bJoGyF-2fX8HXt-2kgUphN-28AnxSn-2gj82N1-TJQbhG-wfCCbp-2bnrZey-TMM9Zi-UPBaEK-TJQ8Yo-2gucHKq',
          wikiLink: 'https://en.wikipedia.org/wiki/Santo_Ant%C3%B3nio,_Macau',
        },
        landmarksRound: [
          { code: 'CA', number: 3 },
          { code: 'MT', number: 4 },
          { code: 'EC', number: 1 },
        ],
        landmarkPlaces: [
          'Ocean Park',
          'Kallur Lighthouse',
          'Kibira National Park',
          'Cacheu Fort',
          'Christiansborg Palace',
          'Gun Hill Signal Station',
          'Mlilwane Wildlife Sanctuary',
        ],
        uniqueId: 'xfHLfnpl',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Nossa Senhora de Fátima',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/146831310@N07/45516090314/in/photolist-e3rZUN-2cm6RTQ-2nPnSiL-2onBm4g-2mQ9jcf-2pMVUDo-NrpkYS-WDUuBC-2pVX5Am-2mDGQ9H-WPfTA9-2juZRSZ-r1WFfN-26Mvnwa-84YNZs-84VFt4-qLuqmm-r2m6wQ-81Vq4j-WRxtNU-2hVj6qR-2id59hJ-dzwjhb-25p2uUY-26Mvnsx-2mGWtAk-YGu2kf-2m5QpvL-2juRCv7-2ogA9QF-BZJJky-jQerkL-2px9oMr-2px8xAU-fDA5mR-H6T4Y6-2juZRTv-fDA5dt-k2JDDi-fDSDHd-qGQH6Y-2mpdncn-rFKRFw-rmpAQn-4LALmZ-2j1Af1x-eUktk8-cDTEQN/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Nossa_Senhora_de_F%C3%A1tima,_Macau',
        },
        landmark: {
          name: 'Galaxy Macau',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/30424020@N05/5783948132/in/photolist-c9fVgj-9P7fgE-aFUHB8-27pikf1-cTjyGU-aFTDqM-aFUK5v-b1ZRpZ-kjfn5k-kjh5SN-kjfmjn-aFTDtV-aFTDs6-kjfk4M-c9fYZQ-aFUJWB-aFUHGa-2ibfVrx-b1ZQoR-Qc2GKd-2q8fvR3-bBNz1n-broAFH-2jS8PCz-B37ZFr-acyBDN-jZSKhP-bqtFwD-cT1yvo-ai1HxH-2nz9i4i-hJ8xyb-MSiVd4-9ThUQc-b1ZSAR-owfyBi-9Qwsyt-aFUJcZ-aFUJN4-aFUJsT-bXKTnC-9QbEJa-nPgPb1-bvmeEB-nPgNMB-oAEqk5-2kZoRJ6-bVc4jw-aFTERe-oT8jZ9/',
          wikiLink: 'https://en.wikipedia.org/wiki/Galaxy_Macau',
          hasLandmark: true,
        },
        city: {
          name: 'São Lázaro',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/elangel/33455439678/in/photolist-2ekEfcw-6CZeiV-6D4okJ-nfnJby-rMZSdG-2i2ujW8-SYkSDu-hDi82f-2i2ujEw-hDi8sL-2i2ujoj/',
          wikiLink: 'https://en.wikipedia.org/wiki/S%C3%A3o_L%C3%A1zaro',
        },
        landmarksRound: [
          { code: 'NP', number: 4 },
          { code: 'AG', number: 1 },
          { code: 'UG', number: 1 },
        ],
        landmarkPlaces: [
          'Pulu Keeling National Park',
          'Walzin Castle ',
          'House of Slaves',
          'Château Frontenac',
          'Kunta Kinteh Island',
          'Detwah Lagoon',
          'Registan Square',
        ],
        uniqueId: '4bct3bg5',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Madagascar',
    code: 'MG',
    capital: 'Antananarivo',
    lat: -20,
    long: 47,
    cities: [
      'Antananarivo',
      'Toamasina',
      'Mahajanga',
      'Toliara',
      'Antisiranana',
      'Antsirabe',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yPSbirjJWzs',
          wikiLink: 'https://en.wikipedia.org/wiki/Adansonia_grandidieri',
        },
        landmark: {
          name: 'Tsingy De Bemaraha',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tsingy_de_Bemaraha_National_Park#/media/File:Tsingy_de_Bemaraha.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Tsingy_de_Bemaraha_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Antananarivo',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/buildings-houses-roofs-palm-trees-6117067/',
          wikiLink: 'https://en.wikipedia.org/wiki/Antananarivo',
        },
        landmarksRound: [
          { code: 'MK', number: 1 },
          { code: 'BD', number: 1 },
          { code: 'VE', number: 2 },
        ],
        landmarkPlaces: [
          'Liwonde National Park',
          'Koutoubia',
          'Boudhanath',
          'Temple of Literature',
          'Pyramiden',
          'Kathmandu Durbar Square',
          'Amber Palace',
        ],
        uniqueId: 'jSQgyWsh',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/baobab-madagascar-tree-nature-5030877/',
          wikiLink: 'https://en.wikipedia.org/wiki/Madagascar',
        },
        landmark: {
          name: 'Baobab Avenue',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Avenue_of_the_Baobabs#/media/File:Adansonia_grandidieri_Pat_Hooper.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Avenue_of_the_Baobabs#/media/File:Adansonia_grandidieri_Pat_Hooper.jpg',
          hasLandmark: true,
        },
        city: {
          name: 'Toamasina',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Toamasina#/media/File:Port_Toamasina_Madagascar.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Toamasina',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Xtb4QXfL',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Madagascar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/trees-near-pathway-during-daytime-yPSbirjJWzs',
          wikiLink: 'https://en.wikipedia.org/wiki/Madagascar',
        },
        landmark: {
          name: 'Baobab Avenue',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Avenue_of_the_Baobabs#/media/File:Walking_the_Avenue_of_the_Baobabs.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Avenue_of_the_Baobabs',
          hasLandmark: true,
        },
        city: {
          name: 'Antananarivo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-a-city-skyline-7uvHtSn5a90',
          wikiLink: 'https://en.wikipedia.org/wiki/Antananarivo',
        },
        landmarksRound: [
          { code: 'ZM', number: 1 },
          { code: 'JM', number: 1 },
          { code: 'FO', number: 2 },
        ],
        landmarkPlaces: [
          "Saint Michael's Cave",
          'Marcus Aurelius Arch',
          'Independence Monument',
          'Dubai Frame',
          'Chinguetti',
          'Mawson Peak',
          'Amboseli National Park, Kenya',
        ],
        uniqueId: 'Fsf9G2y8',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Madagascar',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/green-field-VQdgOEC6Tgc',
          wikiLink: 'https://en.wikipedia.org/wiki/Madagascar',
        },
        landmark: {
          name: 'Tsingy Rouge',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rajarajaraja/10197086686/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tsingy_Rouge',
          hasLandmark: true,
        },
        city: {
          name: 'Toamasina',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Toamasina#/media/File:Tamatave_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Toamasina',
        },
        landmarksRound: [
          { code: 'JP', number: 3 },
          { code: 'EH', number: 1 },
          { code: 'GA', number: 3 },
        ],
        landmarkPlaces: [
          'Ostrog Monastery',
          'Fouta Djallon',
          'Castle Cornet',
          'Kunta Kinteh Island',
          'Golden Gate Bridge',
          'Trevi Fountain',
          'Fort Nuestra Senora de la Soledad',
        ],
        uniqueId: '8D2LShs6',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Madagascar',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/luc/393874958/in/photolist-ANHiy-b25xic-b25wqv-p31x2f-ANQHx-2nYFPR2-b25yV6-b25zpV-b25Aac-ANQtB-ANQjg-b25ytB-b25AsT-b25yFk-ANM2e-b25yak-b25x2K-2nYJxb6-ANHer-AXKyHH-A27Swz-ANM4X-ANLFo-ANHvR-ANHxu-ANLXn-2nYFRQx-2nYM7Q8-2nYMasT-AHGqHP-Aajwr3-AVx4Ds-2nYLGWd-2nYJvyU-2nRgNNW-Ak7AGK-2nYG6f8-2nYKMnt-AYZXEM-AvKgtm-ANQNZ-Bjpxue-AmrYbp-B4XRcL-AKXYpH-B8fNd2-Atncuz-4Hiviy-AQhidC-ANLPK',
          wikiLink: 'https://ro.wikipedia.org/wiki/Madagascar',
        },
        landmark: {
          name: 'Isalo National Park',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/lonqueta/51906259177/in/photolist-2n5M9t4-2oEkkuU-2fXJLtR-S8CuN2-2hxgy9y-MSbJGo-2hxgvtU-9RNKQk-2n66MGH-2hxfDFx-2pkz1US-2kMcce1-2n5oqih-2psnhQU-gB7p4Y-2pkasmk-2n5rd2x-2psemCz-2pkKSzV-2pkqpEQ-2psuo2f-2pYczwM-2kEbnfn-2ptzzgB-2pjEghz-2n3RzTC-2n337Di-2kqBCv7-2iRGgop-2prNFck-d263Td-2n6jmqx-2kqBCJ3-J22PeK-2o3ti6V-2pg9Rpu-cU7hvy-2pcdFuD-2kqB7oN-2n4QwBK-2o3Ddzf-2ph3kxH-bdRjfP-2pkLhTQ-2n4aKZJ-2pgDi4b-26Jma49-2gFAsAg-2pgR3kP-cTAUcd',
          wikiLink: 'https://en.wikipedia.org/wiki/Isalo_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Toamasina',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/124213566@N02/14318709973/in/photolist-nPi8BT-LSXyh1-2nJjhkC-3MEvAJ-emRs9w-7uLqG2-7uQgBm-7uQfYN-7uLqAD-7uLqkc-gBNKGR-bAdLG3-bAdLny-bP8q3M-bAdLwS-2dPi7yR-KMjmss-KnYzHB-24gwCxV-2jj8SxW-2iMM9QD-26h1BJL-2mjxZYK-2bJ4Kwx-KdtoPN-qvotJk-2pjpguo-2j9GUDh-JBshdV-2jeVXHp-2jHS91Y-2pjqP57-27wdZdw-bUCB6r-KwF7f4-Af9a8s-mSmLS9-arbB7-PhA9n1-ruKe4F-rMEDNe-JzgbVf-F4iwGG-qFGCFk-2feAFZP-3KM8Pg-9yVU1X-nv5cwh-9yYVdJ-3KRtcj',
          wikiLink: 'https://ro.wikipedia.org/wiki/Toamasina',
        },
        landmarksRound: [
          { code: 'LT', number: 2 },
          { code: 'AD', number: 1 },
          { code: 'BB', number: 5 },
        ],
        landmarkPlaces: [
          'Klein Curaçao',
          "N'Djamena Grand Mosque",
          'Cathedral of Brasilica',
          'Lotus Temple',
          'Château de Chambord',
          'Cathédrale Sainte-Anne',
          'Tsingoni Mosque',
        ],
        uniqueId: 'GxKgC2BP',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Antananarivo',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/103417/49962754336/in/photolist-2j83bbU-2dv726c-RnjdHQ-2cEfJnt-Rdt8DN-NU2ntg-2cFiVeX-NWLSCk-qTsgmK-rxEsXd-2nyYm41-oWPWwy-4ZGM8b-rxFy21-oWAeuD-6STfzj-prKqCv-AYg2kZ-MDYXz8-AW6btG-A1ffru-oEmYL1-7mzrV6-63SUXG-fYVdz9-6STcRU-6SPciB-6STeY9-6SPcXK-iBDC5i-6SP9He-6SPaqc-6SPcuV-8kUdp-8kUdq-6STfn5-2hUTA1b-6SPaAr-6SPaYH-6SPcRZ-6SPbGP-6SPb4r-6SPcFX-8kUdk-6SP9Ba-6STekQ-K11Huk-oEntvJ-6SPbTp-4TYM1E',
          wikiLink: 'https://en.wikipedia.org/wiki/Antananarivo',
        },
        landmark: {
          name: 'Rova of Antananarivo',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/hugo90/83098244/in/photolist-8kUdm-2pGpSUH-2pJJ1nL-2pJywoV-2pHJDvr-2pFG6PK-2pJQqTa-2pHWFLz-2pJR1Zc-2pJxpUc-2pHoqCP-2kLAep5-2pFN2jX-2pJKMq7-2pJchEG-2pG8uR5-2pHD1cc-2pGcHF2-2pJbEFG-2pGDHXj-2pGqWxp-2pFUNno-2pHR3Ej-2pGDHX4-2pHz4RC-2pHwaRM-2pHoXXi-2pHD1ch-2pHwaRX-2pG4oqF-2pG5ANX-2pGsaQX-2pJzMaU-2pGK6Tw-2pHoryr-2pHb8fy-2pHz4Rx-8kUdq-8kUdp-2pHCtyA-2pGZruC-2pG1Jm2-2pJLo4m-2pHxRCK-2pH62EC-2kLMEak-2pJmMjZ-2pGK6TB',
          wikiLink: 'https://en.wikipedia.org/wiki/Rova_of_Antananarivo',
          hasLandmark: true,
        },
        city: {
          name: 'Antananarivo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-9NkRPZHp94E',
          wikiLink: 'https://en.wikipedia.org/wiki/Antananarivo',
        },
        landmarksRound: [
          { code: 'CR', number: 1 },
          { code: 'FI', number: 2 },
          { code: 'MY', number: 3 },
        ],
        landmarkPlaces: [
          'Rock of Gibraltar',
          'Cathedral of Brasilica',
          'Philharmonic Garden ',
          'Fasil Ghebbi',
          'Bikini Atoll',
          'Petra',
          'Elmina Castle',
        ],
        uniqueId: 'CdBGQNfh',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Malawi',
    code: 'MW',
    capital: 'Lilongwe',
    lat: -13.5,
    long: 34,
    cities: ['Lilongwe', 'Zomba', 'Mzuzu', 'Mangochi', 'Kasungu', 'Blantyre'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/f8Vkuc6fXQA',
          wikiLink: 'https://en.wikipedia.org/wiki/Malawi',
        },
        landmark: {
          name: 'Liwonde National Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Liwonde_National_Park#/media/File:Liwonde_Park_-_viiew_of_Shire_River.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Liwonde_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Blantyre',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Blantyre#/media/File:Blantyre_City.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Blantyre',
        },
        landmarksRound: [
          { code: 'GL', number: 1 },
          { code: 'AR', number: 2 },
          { code: 'FI', number: 2 },
        ],
        landmarkPlaces: [
          'Olavinlinna',
          'Monument to the Heroes of the Restoration',
          'Umayyad Mosque',
          'Middle of the World City',
          'Tian Tan Buddha',
          'Amalienborg',
          'Abu Simbel Temples',
        ],
        uniqueId: '4LhbTmbj',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/malawi-view-ocean-sea-beach-242567/',
          wikiLink: 'https://en.wikipedia.org/wiki/Malawi',
        },
        landmark: {
          name: 'Mumbo Island',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mumbo_Island#/media/File:Mumbo_Island_02.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mumbo_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Lilongwe',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/neiljs/5579483953',
          wikiLink: 'https://en.wikipedia.org/wiki/Lilongwe',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '52x6WNWS',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Malawi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-brown-sand-during-daytime-eFVlSmIyzpM',
          wikiLink: 'https://en.wikipedia.org/wiki/Malawi',
        },
        landmark: {
          name: 'Mumbo Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ralphpina/2814053116',
          wikiLink: 'https://en.wikipedia.org/wiki/Mumbo_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Lilongwe',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lilongwe#/media/File:National_Bank_@_citycenter_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lilongwe',
        },
        landmarksRound: [
          { code: 'IM', number: 4 },
          { code: 'LS', number: 4 },
          { code: 'DM', number: 4 },
        ],
        landmarkPlaces: [
          "Memorial de l'Anse",
          'Parthenon',
          "St. Olaf's Church",
          'The Marble Mountains',
          'National Museum of Costume',
          'Lope National Park',
          'Burj Khalifa',
        ],
        uniqueId: 'bnyBybBr',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Malawi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-boat-on-sea-near-palm-trees-during-daytime-f8Vkuc6fXQA',
          wikiLink: 'https://en.wikipedia.org/wiki/Malawi',
        },
        landmark: {
          name: 'Chongoni Rock Art Area',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Chongoni_Rock_Art_Area#/media/File:Chongoni_Rock-Art_Area-110124.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Chongoni_Rock_Art_Area',
          hasLandmark: true,
        },
        city: {
          name: 'Blantyre',
          photographer: '',
          imageLink: 'https://en.wikipedia.org/wiki/File:Blantyre_City.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Blantyre',
        },
        landmarksRound: [
          { code: 'LS', number: 4 },
          { code: 'FR', number: 2 },
          { code: 'LU', number: 3 },
        ],
        landmarkPlaces: [
          'Chamarel Waterfall',
          "St. Peter's Basilica",
          'Mulafossur Waterfall',
          'Baháʼí Terraces',
          'Brandenburg Gate',
          'Tsingy De Bemaraha',
          'Lake Lalolalo',
        ],
        uniqueId: 'xPX9DlZ5',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Malawi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-brown-rock-formation-during-daytime-VvV29KYNPVY',
          wikiLink: 'https://en.wikipedia.org/wiki/Malawi',
        },
        landmark: {
          name: 'Majete Wildlife Reserve',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/asifkassam/35986907902/in/photolist-WQ3iXm-WQ3qys-YdK2kS-VRziC4-YcakE5-Rm3E94-MJJf9Y-N1n2up-oXZqEV-NDHiVE-YcafJJ-pCidck-Rkk2fU-RnYQPK-pSCgBw-pCoExC-pCmCbe-pCicSH-oXZxhH-pCoK1W-pUS6YE-oXZrfn-Xd3KEo-bna4P6-pSCiRw-2pAaec2-pUy5Uv-pUS8C1-pUS3X9-pUy6Sn-pUS9Ad-pUS4xh-oXZvcF-oXWtgd-pCkKd3-2pAcv6g-pCmHN6-SyVHyL-pCi8QK-RvDhUh-pCozU9-RnYQbR-SNDSiR-pUxYjB-bna3DB-bna1zK-bna1NR-bna1oF',
          wikiLink: 'https://en.wikipedia.org/wiki/Majete_Wildlife_Reserve',
          hasLandmark: true,
        },
        city: {
          name: 'Zomba',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/sylvia_sanders/52692993054/in/photolist-2ohin3f-2ohgZJ2-2ohjvR9-2ohgZQ9-2ohjvBb-2ohgZSo-2pZkpsq-etCdtu-etz4LX-25ZmuX-7i3k7Q-aM7ZrZ-6DEHub-aM7UuT-98qqBn-6KCcHL-7hYssg-6KCcG5-aM81DM-7i3nAs-6KCcPm-aM7XqR-aM7NKT-aM7LR6-aM83xK-aM7W5X-7fRJgr-7fRJmX-nBam1-aM7YBa-6KCcKb-aM7Sdc-aM82E8-7fVEdQ-6KCcLU-aM7Qek-98qqAn-aM85Tx-nBfNx-7fVE7Q-7fRJhK-98tzu5-98tztb-98qqDR-98tzrY-5U9ywf-aM7TjZ-nBapc-7fRJiz-7h4Am5',
          wikiLink: 'https://en.wikipedia.org/wiki/Zomba,_Malawi',
        },
        landmarksRound: [
          { code: 'BW', number: 6 },
          { code: 'SR', number: 2 },
          { code: 'EE', number: 6 },
        ],
        landmarkPlaces: [
          'Church of Our Savior',
          'Fort Zeelandia ',
          'Jerash',
          'Plateau Mosque',
          'Baths of Antoninus',
          'Jardin de Balata',
          'Diego Garcia',
        ],
        uniqueId: 'TLNJGyJ3',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Lilongwe',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/90672529@N00/20812636645/in/photolist-xH9cLR-xH8Pmk-wL76QL-xGhzSG-xGidwU-xqvxfd-xENDp7-wL7qHq-xqCk2n-xH8XUT-wL6Sp7-xH8amx-xquQxU-wL7KQy-xGhUky-xqC3Gi-xH8zjn-eWFuh-XfqzzA-XfqxZb-xqwcab-xH8Uxn-XicNY6-8yeWxX-2w8AD8-9v3jcc-7cf8XF-3nMCC6-8DnKGa-xqvcUj-2kGV94V-7XTbBa-7cpJCM-2JCJmt-5h8d92-9asi1-9asfG-9asn3-3nSaa3-8yi18q-7ctyuj-7ctA6h-wLf7Hk-7cpJKv-7ctyeW-9asnK-246ws24-9ashe-bSSsQM-674JAg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lilongwe',
        },
        landmark: {
          name: 'Lake Malawi National Park',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/gianniparola/6319512931/in/photolist-aCra2v-2kX8U19-9SYerK-aCr2G4-Ra2r8U-JsAA3F-2htTunT-2oESvxV-9T24JS-2opMPLC-9T222f-2nwvDKB-2hP7S4N-PQo1sc-2nv5iCD-2kX8Rus-cQsKkj-2q883yG-97Ukh8-aCtLru-c5WaBu-c5W3Bj-hC9ht6-aCr7i4-c5VA39-2kX8QMA-c5Wjjs-2kXewh6-c5VYm9-df5wUg-2bGmBPM-5TEwzy-8JLtKQ-2gFRX-aZy9mB-2q89gUE-dw5oVJ-oDfRzL-yz1cqp-dLP2J9-9SYdJe-5TExey-PjDVZX-2pa2E9M-2mKLd6M-JYK4oM-5V61Um-26tNbYA-2iRDcyK-2isAH8z',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Malawi_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Blantyre',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/14008620@N03/4387078304/in/photolist-7FEVx7-4E2zbU-7z3DLK-7jro8m-7eDSE5-7jrGGW-8eBL41-7jrLRb-4hrmPy-98kxT5-98hoJk-98hoGz-7hspsC-7hosor-8ZdbCg-8eBMEd-7iB4Yk-qWuQRq-qn4VS2-7jnPBz-WPKS2V-7iB4NK-ceDhh7-2dwGdQp-7FEVuf-7iB5jr-A9Za3K-2kGUK3j-7iEZxb-iiHF5s-k4968V-s7uajo-rZBoP5-7jnuFH-FciJ1A-hQUoPn-7jrHXd-8ZgeBf-7xuTbZ-FGyx3A-7iB5er-7cX6vS-k45W5v-k4b7Es-k46PLB-qWAF4g-pQAd9n-qWs6KN-muWCg4-ko6bZX',
          wikiLink: 'https://en.wikipedia.org/wiki/Blantyre',
        },
        landmarksRound: [
          { code: 'MX', number: 4 },
          { code: 'BS', number: 2 },
          { code: 'CC', number: 5 },
        ],
        landmarkPlaces: [
          'Doonagore Castle, Doolin, Ireland',
          'Trakai Island Castle',
          'Niagara Falls',
          'Cape Coast Castle',
          'Salomons Atoll',
          'Turaida Castle',
          'Golden Gate',
        ],
        uniqueId: 'MPThlv8K',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Malaysia',
    code: 'MY',
    capital: 'Kuala Lumpur',
    lat: 2.5,
    long: 112.5,
    cities: [
      'Kuching',
      'Kuala Lumpur',
      'Malacca',
      'Ipoh',
      'Johor Bahru',
      'George Town',
      'Kota Kinabalu',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/WaL-LPfgUBg',
          wikiLink: 'https://en.wikipedia.org/wiki/Batu_Caves',
        },
        landmark: {
          name: 'Petronas Towers',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kuala-lumpur-petronas-twin-towers-1820944/',
          wikiLink: 'https://en.wikipedia.org/wiki/Petronas_Towers',
          hasLandmark: true,
        },
        city: {
          name: 'Kuching',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kuching#/media/File:Traffic_Junction_Near_Civic_Centre_Tower,_Kuching.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuching',
        },
        landmarksRound: [
          { code: 'MO', number: 1 },
          { code: 'PY', number: 1 },
          { code: 'MU', number: 2 },
        ],
        landmarkPlaces: [
          'Gediminas Castle Tower',
          'Leaning Tower of Pisa',
          'Wieliczka Salt Mine',
          'Heydar Aliyev Center',
          'Frogner Park',
          'Saqsayhuaman',
          'Motherland Monument',
        ],
        uniqueId: 'bVrS9cY2',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kuala-lumpur-petronas-twin-towers-1820944/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuala_Lumpur',
        },
        landmark: {
          name: 'Langkawi Sky Bridge',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bridge-rope-architecture-steel-2354814/',
          wikiLink: 'https://en.wikipedia.org/wiki/Langkawi_Sky_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Kuala Lumpur',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-skyline-buildings-urban-1284258/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuala_Lumpur',
        },
        landmarksRound: [
          { code: 'MU', number: 1 },
          { code: 'RE', number: 1 },
          { code: 'JO', number: 1 },
        ],
        landmarkPlaces: [
          'Grand Ducal Palace',
          'Amber Palace',
          'Baalbek',
          "Dunn's River Falls",
          'Lukan Longshan Temple',
          'Ocean Park',
          'Mulafossur Waterfall',
        ],
        uniqueId: 'xQpLdXQG',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kuala Lumpur',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/twin-tower-malaysia-6U-sSfBV-gM',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuala_Lumpur',
        },
        landmark: {
          name: 'Sultan Abdul Samad Building',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sultan_Abdul_Samad_Building#/media/File:Sultan_Abdul_Samad_National_Day.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sultan_Abdul_Samad_Building',
          hasLandmark: true,
        },
        city: {
          name: 'George Town',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:George_Town,_Penang_at_night_(2)_(cropped_4to3,_KOMTAR_centred).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/George_Town,_Penang',
        },
        landmarksRound: [
          { code: 'GQ', number: 1 },
          { code: 'XK', number: 3 },
          { code: 'DM', number: 1 },
        ],
        landmarkPlaces: [
          'Alhambra',
          'Leaning Tower of Pisa',
          'Islamic Center',
          'Arahoho Blowhole',
          'Liberian National Museum',
          'Arc de Triomphe',
          'Baths of Antoninus',
        ],
        uniqueId: 'XwchddXH',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Malaysia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-between-high-rise-buildings-during-night-time-xuElil9fmfg',
          wikiLink: 'https://en.wikipedia.org/wiki/Malaysia',
        },
        landmark: {
          name: 'Langkawi Sky Bridge',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/boudster/3440896274',
          wikiLink: 'https://en.wikipedia.org/wiki/Langkawi_Sky_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Kuala Lumpur',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/twin-tower-malaysia-6U-sSfBV-gM',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuala_Lumpur',
        },
        landmarksRound: [
          { code: 'HT', number: 4 },
          { code: 'ZM', number: 1 },
          { code: 'MW', number: 3 },
        ],
        landmarkPlaces: [
          'Blue Lagoon',
          'Vajdahunyad Castle',
          'Great Siege Tunnels',
          'The Marble Mountains',
          'Magnus Cathedral',
          'Erbil Citadel',
          'Copán',
        ],
        uniqueId: 'lPt2B5Hw',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Malaysia',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/135025625@N03/25000160451/in/photolist-2kGx6mF-2kqmmCR-E6bmHa-4rDrTu-4rDBAj-82cvQx-2kQng9c-2kLgYoK-2kMnxUr-2jKU4tS-4KbhZb-2kqgZuh-2jNdL37-2kLxs1J-2kbbbMX-2kLmchX-2jMfJmL-fK771x-2jNewLr-2m9yTxG-2m9ymT2-fKoDsQ-2kuERC3-fKoHcA-fK77Dg-fK7cDn-2kbruBr-2jW9Yn3-2jV2ASz-fKoNab-UtfBq6-fK7jAt-fKoN1w-2jW9Yfu-3KJYaq-2jMfJpm-2kNypAV-fK74NT-2kQYH4N-2koP7nQ-2kMav8n-DjUFLj-2fDgbYC-VGL1kH-fK7hwc-dC68kV-7Qv7fg-u4hcZd-2jNdLuK-2cgCXeh',
          wikiLink: 'https://en.wikipedia.org/wiki/Malaysia',
        },
        landmark: {
          name: 'Petronas Towers',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/rimeses/48821025668/in/photolist-2ho9w2S-vdpF5q-4B3kvK-6G9rSn-2gTcCY8-ab5eyN-FatXb7-Vrpigs-cp7bpY-oNCZo4-33YVPP-gcXLju-LVz3wa-33YVQg-bjfdna-5VPh9L-4nraJm-33YVPB-j3DVnp-rxARfa-5QW8ke-5gGk7i-6gScqR-4ms6Ca-D2N165-boYChP-aw4RBs-aw4RYG-34Nyoh-34NyoW-PT4758-9VBEFo-4VXCc-qzsicr-a9QQJt-8JHKqC-fH5FxF-8c8N4E-3pChE5-5LCxzs-4pPDte-33CFCC-97puez-8c5t1r-9X2hL9-pfd3kv-5rVr8L-4mwwLS-9VyNAH-7LXa66',
          wikiLink: 'https://en.wikipedia.org/wiki/Petronas_Towers',
          hasLandmark: true,
        },
        city: {
          name: 'Kota Kinabalu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/silpink_2010/7408283796/in/photolist-6dY8hq-chDoEY-2fcXE8n-4KLwPw-SwofYj-2kxkv9C-9Han39-9Han9w-2m6w8bK-4KvYup-awDdWd-6qxSJQ-LdmReQ-4KJKjb-2kTjypy-4KLCNh-4KLEPh-2kNHsFt-pLeQB1-CZkBJa-6qxTxS-2kHc7bF-2kVcWzg-2kYk38S-4KGKF1-4KEuMn-2nwXYqU-4KEkeg-2kMrM8N-4KLBhj-2kzP1Qf-4KJrBY-uZRT4-4KJk19-4KJmfQ-2m3bnUR-2kJh5dv-4KGiRk-4KEaop-2kwXqVS-4KJHEY-2oMdYB9-4raY9e-4KGLHG-awAmge-7whpKK-s1CjQX-4KJzVw-4XKLnT-6rFCih',
          wikiLink: 'https://en.wikipedia.org/wiki/Kota_Kinabalu',
        },
        landmarksRound: [
          { code: 'IN', number: 2 },
          { code: 'CY', number: 1 },
          { code: 'AT', number: 3 },
        ],
        landmarkPlaces: [
          "Women's Bridge",
          'Clock Tower',
          'Jazeera Beach',
          'Apia Town Clock Tower',
          'Fongafale',
          'Sistine Chapel',
          'Hato Caves',
        ],
        uniqueId: 'j6Gh5vBq',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Kuala Lumpur',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/7976002@N08/535878894/in/photolist-Pmw8h-KoLStf-4vEFCQ-619wSE-59CrLY-2g5oU1n-5ZKtKW-2nAc3a4-28tbdzt-2oxUB9B-CK3Tf-JPbngr-RdKSQs-276qMmX-JPboYe-pUWdVR-3hwzP-5ZKtBb-3hwzQ-eGF1wz-4W5ri3-3hwzM-2nmLf8A-5eYXJ8-28t6mTc-25HchEj-LkdTM5-5f4k3C-bsqj5R-LQp4je-9VV9Ds-rbf14G-qTLw77-rbbHmD-5eYXQp-T1NZrK-2puwbsC-28t7Lnz-LkdG8J-3yF691-2kJ9ALV-4JXGr-WvF3fx-25H8uQE-qhLuLj-276vZPH-qewDY8-2oiD4U1-28tb6N2-pCyHF4',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuala_Lumpur',
        },
        landmark: {
          name: 'Batu Caves',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/shallow-focus-photo-of-hindu-god-statue-9OKjbCqn0io',
          wikiLink: 'https://en.wikipedia.org/wiki/Batu_Caves',
          hasLandmark: true,
        },
        city: {
          name: 'George Town',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/bacshi/52645460226/in/photolist-2od6KbJ-73Kcsm-2nkhEPJ-74Wmz1-2iKaBvQ-73DgGf-ba4fyD-2gSvuRM-2megVKR-Dkenwk-NKxwJn-GPUVep-2odSMj3-2od48o6-NzQ4dH-2od9aem-FUzs3p-72pEYR-2bR4YRL-23V2mi9-VuCNua-2o8V9qG-222z1ud-2odSydA-71Pths-2pAZYCn-2maYJay-2k75Xsj-sjxPSC-2pBiX6b-78Cfr2-74oZxg-2gWeEg1-NXyJm1-74WtgQ-bUTYoi-2oNnwx8-23YAMv9-GVzKoC-22ioGBU-6KATBs-ba4dga-2nRmwxH-2kSi4RJ-2ix1Qn6-2o4qDKn-2iLs1ae-T4cdqZ-2nURmXd-2czuQqU',
          wikiLink: 'https://en.wikipedia.org/wiki/George_Town,_Penang',
        },
        landmarksRound: [
          { code: 'EG', number: 6 },
          { code: 'CU', number: 4 },
          { code: 'GY', number: 3 },
        ],
        landmarkPlaces: [
          'Kim Il Sung Square',
          'Hill of Crosses',
          'Charles Bridge',
          'Hato Caves',
          'Green Grotto Caves',
          'Kunta Kinteh Island',
          'Marovo Lagoon',
        ],
        uniqueId: 'WN3wchKs',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Maldives',
    code: 'MV',
    capital: 'Malé',
    lat: 3.25,
    long: 73,
    cities: [
      'Rasdhoo',
      'Malé',
      'Thinadhoo City',
      'Addu City',
      'Fuvahmulah City',
      'Kulhudhuffushi City',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/xPsFXsbXJRg',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives',
        },
        landmark: {
          name: 'Islamic Center',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Islamic_Centre_(Maldives)#/media/File:Viber_image_2021-04-27_21-51-07-925_(2).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Islamic_Centre_(Maldives)',
          hasLandmark: true,
        },
        city: {
          name: 'Malé',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Maldives#/media/File:Male-total.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mal%C3%A9',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '6S88Ftxd',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/maldives-hut-shack-villa-beach-4703551/',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives',
        },
        landmark: {
          name: 'Republic Square',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Maldives#/media/File:JumhooreeMaidan.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives#Culture',
          hasLandmark: true,
        },
        city: {
          name: 'Addu',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Hulhumeedhoo_Aerial_view_2013.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Addu_City',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'WjVg3kNk',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Maldives',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-dock-beside-white-motor-boat-uWpggIb3iHs',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives',
        },
        landmark: {
          name: 'Muliaage',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Muliaage#/media/File:Muliaage_presidential_residence_of_maldives.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Muliaage',
          hasLandmark: true,
        },
        city: {
          name: 'Malé',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mal%C3%A9#/media/File:Male-total.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mal%C3%A9',
        },
        landmarksRound: [
          { code: 'JP', number: 2 },
          { code: 'TC', number: 1 },
          { code: 'EG', number: 4 },
        ],
        landmarkPlaces: [
          'Kasubi Royal Tombs',
          'Palace of Peace and Reconciliation',
          'Castle Cornet',
          'Tegucigalpa Cathedral',
          'Neuschwanstein Castle',
          'Cape Coast Castle',
          "Saint Michael's Cave",
        ],
        uniqueId: 'LYf2WxgN',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Maldives',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-cottage-on-blue-body-of-water-during-daytime-k9Zeq6EH_bk',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives',
        },
        landmark: {
          name: 'Tsunami Monument',
          photographer: '',
          imageLink:
            'https://en.m.wikipedia.org/wiki/File:Tsunami_Monument_(32177338373).jpg',
          wikiLink:
            'https://commons.wikimedia.org/wiki/File:Tsunami_Monument_(32177338373).jpg',
          hasLandmark: true,
        },
        city: {
          name: 'Addu City',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Addu_City#/media/File:Addu_Convention_Centre.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Addu_City',
        },
        landmarksRound: [
          { code: 'MQ', number: 2 },
          { code: 'MX', number: 2 },
          { code: 'EH', number: 1 },
        ],
        landmarkPlaces: [
          'Mawson Peak',
          'Pigeon Rocks',
          'Mont Orgueil Castle',
          'Monument to the Heroes of the Restoration',
          'Temple of Literature',
          'Cedars of God',
          'Annandale Waterfall',
        ],
        uniqueId: 'lDckVG7b',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Maldives',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/47300487@N04/4338710090/in/photolist-7Bp2rG-7Bp2mo-7Bkdua-7Bp2Kh-7BkcXB-7Bp1Kb-7Bp2Hf-7Bp2AQ-7BkdFx-7Bp1Ub-7BkdTB-7Bp1SC-7BkdRR-7Bp2to-7Bp1Py-7Bp1uY-7BkdZT-2JAegu-rBJEuZ-2JvVxT-mwvNj9-76mmXF-cwwe65-7Bp2fq-7Bp29d-ijmeMP-ieQqu4-idupmS-iduKxv-ik6bao-aAmHo4-7Bp1jS-ieQcEY-idue1F-63DdYZ-iduF7r-cwwdZq-X6iGVo-cwwefj-iePVBh-ik5P5t-cwweaA-XChutm-kK9nwK-aAmHKg-iPzP8P-aDXLXK-47VEiY-iPBMFq-iiNfdL',
          wikiLink: 'https://en.wikipedia.org/wiki/Maldives',
        },
        landmark: {
          name: 'Tsunami Monument     ',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/skyrim/5978254665/in/photolist-tavnFq-8zU1wr-251ydHN-a7h7Qi-87gVYv-jX8his-anURTk-74h9Vh-2nsjguA-3nRqSK-K2MpXK-3FhRiv-JSzwY3-nrxLjz-JSx6tj-7Hqg2-Aznbij-J1rXs8-Hssz4f-JURv5t-JiDXZj-6n6DLr-JUPiuZ-K2FvDB-J6mmqN-8DJDSs-7FrJd-7H8NF-8QMKoS-8UGipw-npMe8x-2fcCtQr-xAC6SH-cHPUyJ-5UpSTV-bqYUPC-2psNspc-eM6eKp-GSnYEj-npMpbi-npPrxY-nrxMfx-icWQK1-2psNtLa-2psNtLf-2psLjar-2psMXRj-9qtzA2',
          wikiLink:
            'https://en.m.wikipedia.org/wiki/File:The_monument_to_the_victims_of_tsunami.jpg',
          hasLandmark: true,
        },
        city: {
          name: 'Malé',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/yellow-sports-bike-parked-beside-blue-and-pink-building-LcoLb5U0oVg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mal%C3%A9',
        },
        landmarksRound: [
          { code: 'KM', number: 5 },
          { code: 'VU', number: 2 },
          { code: 'HK', number: 2 },
        ],
        landmarkPlaces: [
          'National Capitol of Cuba',
          'Lukan Longshan Temple',
          'Lake Piso',
          'Tsodilo Hills',
          'Lake Lalolalo',
          'Jal Mahal',
          'Sistine Chapel',
        ],
        uniqueId: 'Xhkr5g4s',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Malé',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/riphthootha/5187421779/in/photolist-8UoTLx-2m2f7zg-axUnCT-5npdG1-9csuTm-5Cq5fB-cdk8V3-6zao7Z-2kyTBow-2kAbR99-b4T3xB-5AMaxK-68pF2p-2oRqAvW-Cp87nY-2kEMe3a-2nocRBc-8P8bVD-2oqKLgk-2mUoZXJ-2m7ZPnh-2cZNg4A-2oQThhV-2oB13db-2nPA5UD-ChykeP-2kycx1e-2kvzz9n-24PX7J5-2nLG3Fr-2fyAmRz-2j9NF4M-2ovsdbp-2p7xYnU-2phHD2X-YAs3gH-2nTD3W9-YRrgCH-29aBnJ-2nR3DvP-2iW9iWD-2ofZAZw-297yG15-2kZrv17-2j9eruN-2oL6rAG-D6rm4-2oRfefz-2pCXYBU-2m46qKQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Mal%C3%A9',
        },
        landmark: {
          name: 'Ithaa',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/77410909@N08/7878932458/in/photolist-5E6VPF-2GoH3u-4kRcwL-5eLkVj-5E6VZK-2GiWRT-apMmfh-8L1YgE-d1eA85-pNYohZ-5ZKj86-8KUZ4p-4qipmh-pwzb4Y-4qekCP-6S5DkA-6S5Dow-6S1A5c-KigNfk-6S5DpG-5ApPHL-6S5DqJ-6S1AaX-4qippJ-4qipKq-RNs4Xj-s8DG8E-5E6Wmg-3TUFQH-7b56d6-drFX5q-5Ebdh5-kFnJR6-kFnaoB-5E6X6g-kFnbq6-4qipTj-6S5Ds3-4qekH8-4qekLH-6S1Acn-4qipt1-4qipPS-6hvRmc-PjEe3N-drFXx5-coHvGA-5E6XhB',
          wikiLink: 'https://en.wikipedia.org/wiki/Ithaa',
          hasLandmark: true,
        },
        city: {
          name: 'Addu City',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/matey/20009855370/in/photolist-wucJSo-oqAL4R-2ouJp5X-PaVCm3-SXvKr4-2ouFuFJ-2ouLsVn-2ouJprt-2ouKk4b-2ouFv8a-2ouJpqM-2ouKk1R-2ouFuXk-2ouFuKr-2ouLHy8-rfRmag-2ouLHDd-2ouLttG-GjKiht-2ouJpDH-2ouLtBY-dHxLB5-dHxJKL-dHxPBN-9DdcPi-2ouFuDQ-9i2TzC-U6shSV-2ouLtn9-2ouLtkA-2ouLtdS-2ouKjzA-9QmAMf-JTyHVL-26HGcdn-HhSGiQ-256GtuN-Qf8w93-aShRXF-22vWzqo-ecCR8K-A1iyV-U8aMKB-hW63AH-Ufykae-9azzUT-9azzUH-aAxEMh-9azzV6-dmRH2e',
          wikiLink: 'https://en.wikipedia.org/wiki/Addu_City',
        },
        landmarksRound: [
          { code: 'LK', number: 1 },
          { code: 'GD', number: 3 },
          { code: 'TO', number: 1 },
        ],
        landmarkPlaces: [
          'Metropolitan Cathedral of San Salvador',
          'Shoes on the Danube Bank',
          'Philharmonic Garden ',
          'Mumbo Island',
          'Brimstone Hill Fortress',
          'Kylemore Abbey',
          'Victoria Falls',
        ],
        uniqueId: 'vqhmzCtk',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Mali',
    code: 'ML',
    capital: 'Bamako',
    lat: 17,
    long: -4,
    cities: ['Bamako', 'Sikasso', 'Mopti', 'Koutiala', 'Ségou', 'Kayes'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mali-boats-good-produce-town-80855/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mali',
        },
        landmark: {
          name: 'Great Mosque of Djenne',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Great_Mosque_of_Djenn%C3%A9#/media/File:Great_Mosque_of_Djenn%C3%A9_1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Mosque_of_Djenn%C3%A9',
          hasLandmark: false,
        },
        city: {
          name: 'Gao',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Gao#/media/File:Gao_Mali_2006.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gao',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Yp2bwBGx',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Village_Telly_in_Mali.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mali',
        },
        landmark: {
          name: 'Tomb of Askia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tomb_of_Askia#/media/File:Askia.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tomb_of_Askia',
          hasLandmark: false,
        },
        city: {
          name: 'Bamako',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bamako#/media/File:Bamakolooking_north_from_the_old_bridge.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bamako',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'PVP9cP55',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Timbuktu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-body-of-water-through-a-magnifying-glass-xvixfkRw94o',
          wikiLink: 'https://en.wikipedia.org/wiki/Timbuktu',
        },
        landmark: {
          name: 'Great Mosque of Djenné',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Great_Mosque_of_Djenn%C3%A9#/media/File:Djenne_great_mud_mosque.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Mosque_of_Djenn%C3%A9',
          hasLandmark: true,
        },
        city: {
          name: 'Bamako',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bamako#/media/File:Place_de_la_libert%C3%A9_-_Bamako.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bamako',
        },
        landmarksRound: [
          { code: 'UY', number: 1 },
          { code: 'UG', number: 1 },
          { code: 'GW', number: 3 },
        ],
        landmarkPlaces: [
          'Two Lovers Point',
          'Cacheu Fort',
          'Amboseli National Park, Kenya',
          "St. Olaf's Church",
          'Karnak',
          'Larabanga Mosque',
          'Kerid Crater',
        ],
        uniqueId: '8jRqz2Lz',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Mali',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jmenj/8438034181',
          wikiLink: 'https://en.wikipedia.org/wiki/Mali',
        },
        landmark: {
          name: 'Tomb of Askia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tomb_of_Askia#/media/File:Tombeau_dAskia_in_Gao_by_David_Sessoms.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tomb_of_Askia',
          hasLandmark: true,
        },
        city: {
          name: 'Sikasso',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sikasso#/media/File:Le_grand_march%C3%A9_de_Sikasso.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sikasso',
        },
        landmarksRound: [
          { code: 'KI', number: 4 },
          { code: 'MQ', number: 2 },
          { code: 'FR', number: 2 },
        ],
        landmarkPlaces: [
          'Alte Rheinbrücke',
          'Fort Frederick',
          'Fort Napoléon des Saintes',
          'Chinguetti',
          'Abuna Yemata Guh',
          'Cape Coast Castle',
          'National Museum of Costume',
        ],
        uniqueId: 'fQDkYHx4',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Mali',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/gozilah/6731376281/in/photolist-bfQ4DK-9ss6h3-9ss6sY-9ss6r7-9ss6d3-qoieNf-9ss6ph-9sp71X-9sp73P-9ss6e3-72bkwG-9sp6VX-twoQu-59B5ct-9sp6UF-AcLEEc-9sp6S4-9sp6Pz-9sp6Zt-9ss6ob-72bjk5-kakPv-7vFc3j-7U3X1D-BDYjub-2ZRokb-wd1rYU-wd1sqL-37D72j-37D7Fo-37D7uo-VFHUF3-TXrDpA-9sp76H-4rFFzo-4HQ7np-4rBAUi-ddZN2k-8eCgRS-JucW1-zAy6L3-3gk9nN-5GVDn4-5p3eVx-4qZv3A-8adXMe-59B5cP-UXATMu-4r8QgX-5MQNUD',
          wikiLink: 'https://en.wikipedia.org/wiki/Mali',
        },
        landmark: {
          name: 'Sankoré Madrasah',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/rajarajaraja/3194846045/in/photolist-9muBSA-5SorQ7-5Sjqqv-qyQibX-2ostWEC-2nRdhwK-4XHuFh-dPEvn8',
          wikiLink: 'https://en.wikipedia.org/wiki/Sankor%C3%A9_Madrasah',
          hasLandmark: true,
        },
        city: {
          name: 'Sikasso',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/lotor-matic/455335020/in/photolist-bfwXtT-Gn2pm-Gn2hQ-Gn2pY-GeHeN-4s5e88-4s9i5L-qAFohx-qjr94c-pE5DXr-qjibR5-pE5Dvp-qjhsZh-qyyQsu-qjpfVe-qyyLM3-pDRjaY-qAM8n1-qji17E-bfwXpr-bfxojt-bfxnyn-CQKex-4ZdC33-4Zbn2K-ia2uC-bfxnEZ-deLG3-deLB8-pE5SEk-deLeZ-deLnh-deLpj-deLEL-deLvx-deLsm-deLUk-deLYf-deLXx-deLHN-deLqi-deLNj-deLWm-deLMa-deLy1-deLth-deLLh-deLux-deLRE-deLJL',
          wikiLink: 'https://en.wikipedia.org/wiki/Sikasso',
        },
        landmarksRound: [
          { code: 'ID', number: 6 },
          { code: 'AL', number: 4 },
          { code: 'JP', number: 2 },
        ],
        landmarkPlaces: [
          'Szechenyi Thermal Bath',
          'Mount Kenya',
          'Neuschwanstein Castle',
          'Christ Church Cathedral',
          'Fongafale',
          'Plaza de la Catedral',
          'Mlilwane Wildlife Sanctuary',
        ],
        uniqueId: 'lhMBs9qT',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Bamako',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/27126129@N00/159092714/in/photolist-f4oHQ-67m9jx-3JV5gq-a1ekR-f4gfs-67qnWA-3JV4DA-67qnCm-67qnaW-67m1EV-4rt3vm-5RCxPz-a1ekP-AQN1M-q8WgQ-c7VG9U-71AdQJ-dRw3Xw-6Ev5uc-9CJ6BJ-9e58iZ-B2h5o-7iWWEp-B2hfg-ErH9F-ErFV5-3JQpDZ-3JUJpo-9CF9AZ-3JQqdH-3JUHMy-5Aanek-3JUJgN-bBeGxP-8vrA8h-3JUHU3-3JQq4K-5Aan36-5AamWz-B2hbL-6EuTqH-98Un9-yKGkJ-Ad67-yKGkL-27QxB37-2hum8eC-2huoQEY-2hupPan-2hupMjy',
          wikiLink: 'https://en.wikipedia.org/wiki/Bamako',
        },
        landmark: {
          name: 'Great Mosque of Djenné',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/cydnero/25580206341/in/photolist-2kfLEzL-22dpK5Z-9kmseU-2kqjNsq-smMvH1-2ko1vuu-EYrf9n-RJLWUf-bBQTAJ-QTwy81-QE6HgM-J7AXKE-2opdHYG-2kw7cUk-2k93xv4-2ooYYxC-2kvGMZ1-2kifTnA-2k3YdTU-FLpUPv-aFU8Ct-HkmMmy-5B58JD-Sb42Dk-aCKDij-4dkRtL-5AnPar-dyMd2n-6jpaH9-rTz2Bg-EcUxr-9dubJ4-p9Q7R9-atevop-5B58za-iaunu-7gEtWZ-iasXe-2iVZCej-4H26yD-rB6KHw-Fw9Ma4-rTzesa-5BYL6N-6mWPNU-q2hkVw-RW4814-5B58w4-iau4K-5SjPUr',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Mosque_of_Djenn%C3%A9',
          hasLandmark: true,
        },
        city: {
          name: 'Ségou',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/chugues/2226526636/in/photolist-4oKwNh-6afNYm-2p2L9Yx-aMXv9V-9mYkS4-9GiqAC-9GezzP-6ArzoH-7DNNoQ-69zdZ8-yKgJ-9ArAC-yKA3-69zdJ2-69zfUp-69zeXk-69zgqp-4rsezh-4rscFf-f4gfy-aW4UH2-4oFsQv-6cz8kG-69zeuF-9Ghu57-4oFt7H-LsDdu-4s9ipE-5wU9E1-5aMKPf-yKkD-2p5Cjgv-h4F8A-9jF41H-2p2xS3E-a93qLv-7H71q-f4oHU-5vGm6t-75hmUe-75mdAb-7EYwNH-6FM5zk-yLGf-4nfcQx-4s5br8-5SKAnt-9dmK11-7EYv1k-73o6gk',
          wikiLink: 'https://en.wikipedia.org/wiki/S%C3%A9gou',
        },
        landmarksRound: [
          { code: 'FK', number: 5 },
          { code: 'BG', number: 3 },
          { code: 'LK', number: 2 },
        ],
        landmarkPlaces: [
          'Dragon and Tiger Pagodas',
          'Frida Kahlo Museum',
          'Tomb of Hafez',
          'Bonriki',
          'Kamianets-Podilskyi Castle',
          'Perito Moreno Glacier',
          'Juche Tower',
        ],
        uniqueId: 'vRVflTdZ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Malta',
    code: 'MT',
    capital: 'Valletta',
    lat: 35.83333333,
    long: 14.58333333,
    cities: [
      'Birgu',
      'Senglea',
      'Mdina',
      'Sliema',
      'Valletta',
      'Birkirkara',
      'Cospicua',
      'Victoria',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/dWK5VEPIVwk',
          wikiLink:
            'https://en.wikipedia.org/wiki/Sanctuary_of_Our_Lady_of_Mellie%C4%A7a',
        },
        landmark: {
          name: 'Blue Grotto',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/malta-blue-grotto-sea-2747465/',
          wikiLink: 'https://en.wikipedia.org/wiki/Blue_Grotto_(Malta)',
          hasLandmark: true,
        },
        city: {
          name: 'Valletta',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/malta-travel-tourism-europe-island-485321/',
          wikiLink: 'https://en.wikipedia.org/wiki/Valletta',
        },
        landmarksRound: [
          { code: 'CW', number: 2 },
          { code: 'DZ', number: 1 },
          { code: 'BT', number: 2 },
        ],
        landmarkPlaces: [
          'Langkawi Sky Bridge',
          'Tenaru Falls',
          'Gangtey Monastery',
          'Sule Pagoda',
          'Rijksmuseum',
          'Palace of Peace and Reconciliation',
          'Fasil Ghebbi',
        ],
        uniqueId: 'Bl3kfcYB',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/popeye-village-malta-architecture-4158038/',
          wikiLink: 'https://en.wikipedia.org/wiki/Malta',
        },
        landmark: {
          name: 'Mosta Rotunda',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mosta-rotunda-basilica-church-7503028/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rotunda_of_Mosta',
          hasLandmark: true,
        },
        city: {
          name: 'Birgu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Birgu#/media/File:Birgu-Vittoriosa_-_Malta.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Birgu',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'MqMSpkWJ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Marsaxlokk',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/blue-and-yellow-boat-on-water-near-brown-concrete-building-during-daytime-3F904UCiZGw',
          wikiLink: 'https://en.wikipedia.org/wiki/Marsaxlokk',
        },
        landmark: {
          name: 'Ħal Saflieni Hypogeum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/xiquinho/27798202362',
          wikiLink: 'https://en.wikipedia.org/wiki/%C4%A6al_Saflieni_Hypogeum',
          hasLandmark: true,
        },
        city: {
          name: 'Valletta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/concrete-street-under-blue-sky-during-daytime-9cYiqVDeXDc',
          wikiLink: 'https://en.wikipedia.org/wiki/Valletta',
        },
        landmarksRound: [
          { code: 'UZ', number: 2 },
          { code: 'GB', number: 1 },
          { code: 'LU', number: 4 },
        ],
        landmarkPlaces: [
          'Ducie Island',
          'Big Ben',
          'Boudhanath',
          'Semuc Champey',
          'House of the Black Heads',
          'Ceking Rice Terrace',
          'Lope National Park',
        ],
        uniqueId: 'b7lNsy4g',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Malta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-view-of-body-of-water-Exf495AtWZI',
          wikiLink: 'https://en.wikipedia.org/wiki/Malta',
        },
        landmark: {
          name: 'Rotunda of Mosta',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Rotunda_of_Mosta#/media/File:Malta_-_Mosta_-_Triq_il-Kbira_+_Rotunda_01_ies.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Rotunda_of_Mosta',
          hasLandmark: true,
        },
        city: {
          name: 'Birkirkara',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Birkirkara#/media/File:Malta_-_Birkirkara_-_Triq_l-Imdina_+_Simonds_Farsons_Cisk_01_ies.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Birkirkara',
        },
        landmarksRound: [
          { code: 'GP', number: 2 },
          { code: 'KZ', number: 1 },
          { code: 'IS', number: 3 },
        ],
        landmarkPlaces: [
          'Palace of Versailles',
          'Ruínas de São Paulo',
          'Fasil Ghebbi',
          'Elmina Castle',
          'Mutanabbi Statue',
          'Castle Cornet',
          'Charyn Canyon',
        ],
        uniqueId: 'JMr3jWGf',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Malta',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/blue-ocean-water-surrounded-mountain-under-blue-and-white-cloudy-sky-RZC_fPZ5-AA',
          wikiLink: 'https://en.wikipedia.org/wiki/Malta',
        },
        landmark: {
          name: "Saint John's Co-Cathedral",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/mmm-yoso/49499615791/in/photolist-2iq7tez-2pk7mNv-298ev4v-2kagT2r-2ar66dm-2g7hw6h-PBJrB1-2ovqZCR-27pLeqb-HuZD1W-293eJEp-RgUKe6-2a3aV53-29p2KsZ-2iF8mJ5-281ajHo-27UGXFj-VmL5J8-SvHapF-SgTZTL-SgU1S9-265nwzn-2f3ca2t-2n8dLAb-2iqVGF5-PXF9Qd-2n86vRF-2eXzJFu-2pTsfDf-2aPdPei-HcQVgn-2eXzK1h-24Wfn5t-2pFqd9E-2puh7oQ-RgQvdi-2jfqM5m-dkc3Pa-24qK9Vk-2n87hx5-2n7KVbM-24rBXcP-NUgiom-FvjKty-279camZ-2n8cFog-2n87hyN-2n8NxYS-2n8F8R9-2n86vTz',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_John%27s_Co-Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Cospicua',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/eduiturri/51276214543/in/photolist-2acAkfy-2m8719x-Tfe4K8-2m868ZY-2nFbWwN-2mrFrev-2m6rFZE-nncjx1-8y77Ui-8y78iF-2jkxrkJ-2jkBBXB-2jkBDAG-2nYQEF3-dYM51-e31MT-e31JY-e31MF-e31z9-dYM4M-dYM7d-dYM5u-e31Jq-dYM5h-e31N7-nnchWW-noXvvA-5HLA2Q-5HLyRj-2m6oTRW-2i8YNAc-7mJX95-8Y8jR7-QArwBY-5HyUhq-RUpqp9-bb2aAM-gwXUcw-HvTPtw-2oBzLXm-5HuAHK-2ohpTjr-8Y5hHF-23wDZnK-2ohyv3t-e31zn-e31uq-79MLCA-e31y9-5HLztC',
          wikiLink: 'https://en.wikipedia.org/wiki/Cospicua',
        },
        landmarksRound: [
          { code: 'CR', number: 1 },
          { code: 'MQ', number: 2 },
          { code: 'MS', number: 3 },
        ],
        landmarkPlaces: [
          'Bayon Temple',
          'Palace of the Grand Master of the Knights of Rhodes',
          'Jerash',
          'Thaba Bosiu',
          'Great Mosque of Porto-Novo',
          'Chorsu Bazaar',
          'Tsavo East National Park',
        ],
        uniqueId: 'KfSMvJ6n',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Valletta',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/50141659@N00/52285795630/in/photolist-2nEjnr1-JaJ8wT-2opxkQ6-oJ3Qg6-ePVNSU-2maS65b-2jKvgbq-2aJv2Ej-2jKprSh-2opiQk7-JHVLCx-As98hr-oZ5za9-X5sn7Y-QYZST-2nX4xGa-8zB7Q8-3phgpu-9NZ22g-2jKLC3f-a2Znru-BY7Lvw-KSQTP7-2oXswp-25rqJhn-aDBz12-7WGh6T-wJMFQ-2nR46i2-q3bBze-69gS29-CpVioh-2ne86W8-Hiw6wG-2oXsva-8rVvkk-a7NLjr-edY5Sv-7ryDkk-7rCwDU-b3Vus-2a4YrAp-7rCKWN-8Daon1-7vY1i2-a7REHY-a7NMfM-a7NMJD-q3ugzE-etCEc8',
          wikiLink: 'https://en.wikipedia.org/wiki/Valletta',
        },
        landmark: {
          name: "St. Paul's Catacombs",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/sulitskiy/40359942842/in/photolist-2iBgiNs-GdGHRv-9k45uQ-237ZSUA-24utfTu-X6GaEH-24vEGNa-2mHEPrQ-2nU8UV7-2nUahGz-2nUahBK-2eFLFkh-25gVcGt-PbK8Dn-NpcHQa-2nUbco2-2iGo7EA-255fMsY-2dsBrFP-SPPpSd-Gdekdr-VgfCNE-2nR8DdZ-JcT53g-HTU6b3-HTJ4jw-TpQdrM-Tmegmy-N2ZtWA-5CWgxh-2Wcd4d-FdYEbt-SPPqGu-2gTENei-SaY4r6-28uxcpp-2f9hrPG-S8kefm-MrshuA-xtSikY-2nUahAH-TAF3q-87Gguu-TySkx-TAFdW-TaZWso-S8kfUJ-7NkLaw-2f9hrHj-7NgN5P',
          wikiLink: 'https://en.wikipedia.org/wiki/St._Paul%27s_Catacombs',
          hasLandmark: true,
        },
        city: {
          name: 'Victoria',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/51839301@N00/52684190510/in/photolist-2ogwfmf-2ofADeV-2oeeYqj-2oe3NxL-2oHyk1q-CxpyyH-qj7nPx-2offEPY-2ofWbRp-2oeT6f6-2oezUBR-KfaTCA-anzMZh-2jD7Uyb-2jqX6c2-SgYzRX-2nEVDnm-2jvAPnG-2nLiiPe-2b2SaSv-SekXtw-5sE8Rd-6vaNaq-cjbDMS-7WMFDE-bqAzhm-2ogaSHu-24KQu2F-Fk8NXh-2oHwr8k-FB53Eb-W7jQCy-2ipPiYS-SekVg5-2n1wdwj-23DDP3L-5sEeDw-FTYhVW-SekUQW-7WMGhm-7WMHEU-7WJwt6-2dY1Gd4-FTYhAN-7WJkrV-7WJjRi-7WMAqG-9LjVUn-7WJiH6-2jeGmG1',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria,_Malta',
        },
        landmarksRound: [
          { code: 'KE', number: 2 },
          { code: 'AI', number: 3 },
          { code: 'CA', number: 4 },
        ],
        landmarkPlaces: [
          "Vianden's Castle",
          'Laferriere Citadel',
          'Viñales Valley',
          'Abuna Yemata Guh',
          'Verona Arena',
          'Stonehenge',
          'Cathedral Our Lady of the Rosary',
        ],
        uniqueId: 'JNCBpvqh',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    capital: 'Majuro',
    lat: 9,
    long: 168,
    cities: ['Majuro', 'Ebeye', 'Ajeltake', 'Laura'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/marshall-islands-usa-beach-coast-1064876/',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
        },
        landmark: {
          name: 'Bikini Atoll',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bikini_Atoll#/media/File:Sikorsky_SH-3G_Sea_King_from_Helicopter_Combat_Support_Squadron_1_in_flight_during_an_aerial_radiation_survey_over_Bikini_Atoll_in_November_1978.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bikini_Atoll',
          hasLandmark: false,
        },
        city: {
          name: 'Majuro',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Marshall_Islands#/media/File:Sunset_-_Majuro.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Majuro',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'fM6lBNys',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Marshall_Islands_%2840325565%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
        },
        landmark: {
          name: 'Arno Atoll',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Arno_Atoll#/media/File:ArnoAtoll_ASTER_2002jul25.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
          hasLandmark: false,
        },
        city: {
          name: 'Ebeye',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ebeye_Island#/media/File:Ebeye_Island.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ebeye_Island',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'pFLdwLlQ',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Marshall Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-palm-trees-during-sunset-OSzKOGIJ3bw',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
        },
        landmark: {
          name: 'Enewetak Atoll',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Enewetak_Atoll#/media/File:Runit_Dome_001.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Enewetak_Atoll',
          hasLandmark: true,
        },
        city: {
          name: 'Majuro',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Majuro#/media/File:Alele_Museum_Aussenansicht.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Majuro',
        },
        landmarksRound: [
          { code: 'LS', number: 4 },
          { code: 'ZW', number: 2 },
          { code: 'IE', number: 1 },
        ],
        landmarkPlaces: [
          'Erbil Citadel',
          'Baháʼí Terraces',
          'Lake Armor',
          'Holy Family Cathedral',
          'Roman Forum',
          'East Side Gallery',
          'Maluti Mountains',
        ],
        uniqueId: 'rbWbJ8cs',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Marshall Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mrlins/302904968',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
        },
        landmark: {
          name: 'Bikini Atoll',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bikini_Atoll#/media/File:Sikorsky_SH-3G_Sea_King_from_Helicopter_Combat_Support_Squadron_1_in_flight_during_an_aerial_radiation_survey_over_Bikini_Atoll_in_November_1978.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bikini_Atoll',
          hasLandmark: true,
        },
        city: {
          name: 'Ebeye',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Ebeye#/media/File:A_basketball_game_in_Ebeye,_Marshall_Islands,_February_2012._Photo-_Erin_Magee_-_DFAT_(12426531414).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ebeye',
        },
        landmarksRound: [
          { code: 'VA', number: 2 },
          { code: 'KE', number: 2 },
          { code: 'TF', number: 1 },
        ],
        landmarkPlaces: [
          'Sistine Chapel',
          'Ten Thousand Buddhas Monastery',
          'Tash Rabat',
          'Tsavo East National Park',
          'Gullfoss',
          'Durrell Wildlife Park',
          "Fisherman's Bastion",
        ],
        uniqueId: '8NvpgWwJ',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Marshall Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/102182523@N07/9811274503/in/photolist-4yFwn-WJXFMb-2mQyEiH-6LNUYC-2k6Meas-2jEA8M2-2jPTTYo-2jL9P1w-2jXa8FX-2jQer4b-2jQFamd-2nouVYv-2kmBTJJ-fWZm6e-2oskU6r-2ndej2y-2oQzAzz-JP1tu-2oEiacb-4yFsA-2kU7F91-2mGYqL5-MrA98N-2kA5WEz-2jJXE1v-2nvHr1Z-2kA2pqd-4yFvc-2kA2pMa-4yFsb-2oiTSua-5Sm8R6-7p9oDF-5TbhSH-2oXMVVh-2mVXJAr-5SmdJB-5Sm8Rk-5Sm8Re-2oQF447-RvmdC5-4yFo8-ZN6TF8-2nctsyr-2nBmuSN-4yFkd-5Sm8Ra-yoFFu-5UhVVb',
          wikiLink: 'https://en.wikipedia.org/wiki/Marshall_Islands',
        },
        landmark: {
          name: 'Ebeye Island',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/musubk/48746514987/in/photolist-2hgyCAX-2hgyC5S-2hgxGCk-2hgvZND-2hgyDr9-2hgw1hQ-2hgxRfa-2hgxHEF-2hgyE8E-rC7x6E-SMv4jo-4CHv5G-ozoDRC-aagVqs-rC7wLG-rUyd2b-rCeazD-rSomYL-rC6hh1-rSomLw-rC7x33-rSomhL-rCeaEi-rSom2q-rAmxsH-tzBZgZ-6dXbPr-f6q8Nu-7FiQBB-f3GTPK-7FiQBz-aZQGtg-2kXQJsX-9q6im6-bH7PRk-6dXcqi-UYCJP5-rCeaSc-rSommy-nDkm7-4B9Usz-aZQuy8-f3GUui-ekcga-f3GUQK-f3GTJR-gnGonA-2mMf2fX-f3GU6Z-7FiQBk',
          wikiLink: 'https://en.wikipedia.org/wiki/Ebeye_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Ajeltake',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/thejduce/6807346476/in/photolist-2m885x3-bnxqWG-dwAVuF-btMv5i-bmF3Wc-basSa6-b6eVHP-bsEabF-b9JHap-b6exWK-bCEFw6-citUvC-2pKp97j-bpKKxE-pVEhd-pVEia-pVEgw-bpKKwu-pVEiZ-citQc7-bpKLdh-bpKKSf-bpKJLE-2jqjsy6-2jqnrwJ-2pKmDHF',
          wikiLink: 'https://en.wikipedia.org/wiki/Ajeltake',
        },
        landmarksRound: [
          { code: 'SZ', number: 5 },
          { code: 'CL', number: 6 },
          { code: 'GL', number: 4 },
        ],
        landmarkPlaces: [
          'Herat Citadel',
          'Hundertwasserhaus',
          'Ark of Bukhara',
          'Christ of Havana',
          'Mata-Utu Cathedral',
          'Cliffs of Moher',
          'Köl-Suu',
        ],
        uniqueId: 'Ct8gchwJ',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Majuro',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/mccormickwest/4235966319/in/photolist-7sjrd4-7soqaJ-6Sa234-nUwr9Z-2mM6oib-d7jiKW-Y39yFN-2kA5V9d-2kA6zjh-2kA6zr1-fZYQ-2kA6A6T-2narHbZ-6HMb2z-7sopRL-nAjz-2kA5WEz-2kA5We4-aU1HNP-2kA5VQy-2kA2pqd-2kA2pMa-2mbbqB4-5jg9j7-3bdGM-2kA2q8v-6HMnbK-2kA6Aqv-STcBa-6HRpaL-6HRjWu-6HRv7u-6HMfWX-ntYh-6HRo53-6HMr8X-6HMcVg-68mYKu-nKZR8-6HRsaA-6HMbW2-6HMk8P-6HMq9k-6HMeX2-d7jiZj-23VgV8-2nFtdK1-nM3mEg-5WgDJc-9XqfY',
          wikiLink: 'https://en.wikipedia.org/wiki/Majuro',
        },
        landmark: {
          name: 'Roi-Namur',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/kwajaleinhourglass/49436947167/in/photolist-2ijzh34-2oRR145-Ty3YRc-TFVAaw-65ERYy-Taxv7d-ofAz1-TFVzx9-TaxjdQ-TvBQL7-TFVYnN-TvBSzY-2oQzAzz-Ty4vNn-2oRHCUA-7FiLGP-2ijzhmk-Ty3VJa-8JycoD-SvGxcZ-TvBXYd-TvBrLj-6tQzgk-SvGWTB-TvBtzj-TaxWNd-2oQF447-TKyZBt-TaxoeW-77E819-6rXkFd-AriV2-2mL1Rd2-2kuJxLJ-TKyGZR-6tUHM5-TvBVRC-TFW4mA-84wVXS-viZ7WY-84wVXW-84wVXY-6sHyk2-QSVh3-7q8qsc-7FiLGT-7FiLGX-xQp1Z-7FiLH6-vyg8Wo',
          wikiLink: 'https://en.wikipedia.org/wiki/Roi-Namur',
          hasLandmark: true,
        },
        city: {
          name: 'Laura',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/191587779@N06/50925323357/in/photolist-2kA6Aqv-9XqfY-2oJsB6R-sLq9t-g5JLS-5G55gY-sLq9B-sLq9m-sLq9L-qtgvWb-aEFg18-qLFPro-sdQSYW-st9aHY-rreGr4-rHJkSR-2jDvYS7-r8EBUx-eWNRL-sdRvbf-suUENo-rYAc7P-8YmLoV-CtPDm-qLgWqC-rr99Ku-2iRA7kz-rFrHC1-rt4W1D-j3fAEo-rprPPr-2iTrYg2-rbBVtU-2iYL1DK-rHLprv-ryrko1-qMkdzn-sCYwqy-2j3SjW9-rHEtaG-qLFZDN-rpmQyx-rkW1Uc-5WgDJp-sVq3Q6-rrbSeA-rHzPYW-sD2xmV-rkT3K3-sCLhYL',
          wikiLink: 'https://en.wikipedia.org/wiki/Laura,_Marshall_Islands',
        },
        landmarksRound: [
          { code: 'SO', number: 2 },
          { code: 'LS', number: 5 },
          { code: 'CW', number: 6 },
        ],
        landmarkPlaces: [
          'Pierre Savorgnan de Brazza Memorial',
          'Crystal Caves',
          'Great Blue Hole',
          'Lakes of Ounianga',
          'Machu Picchu',
          'Malolotja National Park',
          'Souq Waqif',
        ],
        uniqueId: 'nQFfr6Ln',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Martinique',
    code: 'MQ',
    capital: 'Fort-de-France',
    lat: 14.666667,
    long: -61,
    cities: [
      'Fort-de-France',
      'Le Lamentin',
      'Le Robert',
      'Sainte-Marie',
      'Le Francois',
      'Ducos',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caribbean-martinique-sea-beach-67886/',
          wikiLink: 'https://en.wikipedia.org/wiki/Martinique',
        },
        landmark: {
          name: 'Jardin de Balata',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Balata_Garden#/media/File:Jardin_de_Balata.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Balata_Garden',
          hasLandmark: true,
        },
        city: {
          name: 'Le Lamentin',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Le_Lamentin_decollage_banner.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Le_Lamentin',
        },
        landmarksRound: [
          { code: 'GG', number: 2 },
          { code: 'IE', number: 1 },
          { code: 'BS', number: 2 },
        ],
        landmarkPlaces: [
          'The Little Chapel',
          'Wat Arun',
          'Seven Colored Earths',
          'Castle Rushden',
          'Predjama Castle',
          'Pulhapanzak Waterfalls',
          "Saint Michael's Cave",
        ],
        uniqueId: '9DxzRbvj',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/nature-sky-blue-sky-clouds-2358189/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Pel%C3%A9e',
        },
        landmark: {
          name: "Memorial de l'Anse",
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Cap_110#/media/Fichier:Cap110.jpg',
          wikiLink: 'https://fr.wikipedia.org/wiki/Cap_110',
          hasLandmark: true,
        },
        city: {
          name: 'Fort-de-France',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort-de-France#/media/File:Fort-de-France-baie.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort-de-France',
        },
        landmarksRound: [
          { code: 'LB', number: 2 },
          { code: 'KZ', number: 2 },
          { code: 'PT', number: 2 },
        ],
        landmarkPlaces: [
          'Gyeongbukgong Palace',
          'Acropolis',
          'Sukhbaatar Square',
          'Elmina Castle',
          'Cathedral Our Lady of the Rosary',
          'Amalienborg',
          'Ocean Park',
        ],
        uniqueId: 'P8cLT8xq',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Martinique',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-palm-trees-near-mountain-during-daytime-wSEv7Nanuxc',
          wikiLink: 'https://en.wikipedia.org/wiki/Martinique',
        },
        landmark: {
          name: 'Cap 110',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Cap_110#/media/Fichier:Cap110.jpg',
          wikiLink: 'https://fr.wikipedia.org/wiki/Cap_110',
          hasLandmark: true,
        },
        city: {
          name: 'Fort-de-France',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort-de-France#/media/File:Fort-de-france-harbor.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort-de-France',
        },
        landmarksRound: [
          { code: 'PF', number: 3 },
          { code: 'DE', number: 1 },
          { code: 'MC', number: 2 },
        ],
        landmarkPlaces: [
          "Martyr's Memorial",
          'Memorial da Escravatura e do Tráfico Negreiro',
          'Roman theatre',
          'Baobab Avenue',
          'Tian Tan Buddha',
          'Victoria Falls',
          'Mont Ross',
        ],
        uniqueId: 'JGFjxT4x',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Martinique',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-and-green-mountain-under-white-clouds-during-daytime-E8uYKuyCuhU',
          wikiLink: 'https://en.wikipedia.org/wiki/Martinique',
        },
        landmark: {
          name: 'La Savane des esclaves',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/gillyan9/24995088856',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Savane',
          hasLandmark: true,
        },
        city: {
          name: 'Le Lamentin',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Le_Lamentin#/media/File:Aeroport_Aime_Cesaire.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Le_Lamentin',
        },
        landmarksRound: [
          { code: 'EE', number: 2 },
          { code: 'MT', number: 3 },
          { code: 'GY', number: 3 },
        ],
        landmarkPlaces: [
          'Rock of Cashel',
          'Maluti Mountains',
          'Grand Ducal Palace',
          'Baths of Antoninus',
          'Ducie Island',
          'Sans-Souci Palace',
          'Lake Armor',
        ],
        uniqueId: 'c6KTHjfr',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Martinique',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/183070308@N06/51861677903/in/photolist-2n1QE1p-2nD8o8K-2n1QeWk-2krQLk4-2nk7Xaq-2nD8nRH-2ne2GwW-2nD8nDd-2nk7XyG-2nk6Qmr-2ndWwfz-2krV2tG-2mkJfUs-2n1TAfz-2krQL5z-2krV2af-2krUAwB-2ne43gm-2n1QE95-2hcxqFX-2fpWkcg-2gLPbuT-2fq2AjF-2eiZu4f-RDx4zB-2hd17vJ-2hcxsmL-2fpWkYM-hEntUT-2e23siZ-ei5BjN-6R3DJm-2eiZt1d-2e23rR6-RDx52i-2eiZupL-2hczb7R-bzxdHT-bzxdz6-bmCm2W-2ej5er9-2eiZtxf-TgGAMA-2hcxqhR-TgGA5d-2eiZstw-RDx1Q8-RDx1ir-bzxdmR-RDx1wc',
          wikiLink: 'https://en.wikipedia.org/wiki/Martinique',
        },
        landmark: {
          name: 'Cap 110',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/mgophoto/53069676616/in/photolist-2oRzXWq-QmJaTJ-K3EUQj-TGzbcU-F3Cmwv-2pyNBFy-W7DrZu-22SZqTJ-HYREta-HYRFgH-WVUkLR-WLqWTL-wempzi-oBzDar-2fctHxp-2hJJdLj-WR8Ypq-WUgKa4-X4MQap-Y3NMYM-X4PQDK-X4XmFL-21UdeH2-2oNk5uk-e2os6N-Y5qcUo-qRUVM-XJGzEp-qGyioa-VCY8C7-VWD4Sm-WRtEF3-2kBT7NN-XSPx7f-6cr3b4-Zw341m-FUFZKg-4mLoaS-RrduwK-TKPEfT-T1S2F2-BDRaRX-WRtAcy-WRtGKJ-TsZRAa-kP1oLb-21gcGDd-SSZA2J-2pDfrA6-2mjLhm6',
          wikiLink: 'https://fr.wikipedia.org/wiki/Cap_110',
          hasLandmark: true,
        },
        city: {
          name: 'Le Robert',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/dbrothier/49126399943/in/photolist-2koAHnf-kJPb18-2hR8Daz-RUs4h3-7BhJxb-CvbkDr-2q4MsTw-2q4Pz9n-2pXLsD9-RVXQ5V-97W5pV-6xyWRV-DtF7Tz-2q51WTB-2pSfw5W-2q4Bs5N-2ndzCXo-2g9aHZQ-2q4PimV-64c5xq-sdxPX7-7BX8x1-WfGBmM-2mXxRk1-GL2zpm-95zkV1-2g9b9ks-95vYKi-6vYo9G-6vUgQt-5GrmEG-95z7S9-95vVZ8-95yHjk-2q51WY6-fhFjwU-95zbiJ-95zfXL-95zhE9-95BFzu-95w3ur-95w1RX-95zEqm-6vYxDb-5GrgHs-6vUZPM-6vUdGB-95zdZf-2g9aHmU',
          wikiLink: 'https://en.wikipedia.org/wiki/Le_Robert',
        },
        landmarksRound: [
          { code: 'SI', number: 4 },
          { code: 'TR', number: 1 },
          { code: 'DK', number: 5 },
        ],
        landmarkPlaces: [
          'Edinburgh Castle',
          'Alexander Nevsky Cathedral',
          'Dancing House',
          'Cologne Cathedral',
          'Kittiwake Shipwreck',
          'Kampong Kianggeh',
          'Christ Church Cathedral',
        ],
        uniqueId: '8z9hctNh',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Fort-de-France',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/wollis/46077219242/in/photolist-2ormSBz-2kZzj23-Gd5nSf-22YCJkg-2m2hdmp-vJfNXN-H8eU1z-9ojxd2-2dcFMYw-8YnEJW-2iTn552-bd87r2-2mwk5KA-Rmx5je-RGNu2s-2mLk3yd-2o6NS51-YhkmkD-Xe3CfN-ykgWW7-yZFLFs-ykhi1W-PWEPqY-yY2oo1-ymDfaC-2kAVrNv-EF8MXp-VM4QWS-s1iSPD-23TNDNR-CDUzcM-CeYVCY-2ip1ccj-rL3yzD-D8XtUz-DaHCnc-tMzmms-2ipfAXr-2jb6U1J-2kDFDt2-Wqmacf-qSz3YF-27jU1vE-2pympxZ-25WoTds-247Cvqk-X1vJs9-ryakNz-2isP7dZ-RqJtGd',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort-de-France',
        },
        landmark: {
          name: 'Balata Garden',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/matso/23369573440/in/photolist-BB6b11-zgb5T4-21UsZMB-23tCpKf-AbWUaR-qdXBQU-nmRkic-yiddEw-rAePVr-yXFW97-EZJUnL-CxgVWR-8vcEPs-cB4QB9-Tg4XSU-iRNHvM-9hUnQp-wQho2-yXFw5L-qvvDRT-yijMgG-yimcry-zhW1fg-24zRw7H-rfnsZD-wQcVz1-24zRx4x-WYqhyE-S9aGqz-DNet1n-24zRwyp-qHZEGG-qRjHDw-2f7GGvE-24zRxgM-24zRwie-216AHPy-e1hN2f-qYTpfi-qjiHSq-ym3f1t-qYXLdd-QqVUDM-USKiQh-QqVV68-dGxGbs-UekTPx-dLdiqo-8DoPdB-qGB8fX',
          wikiLink: 'https://en.wikipedia.org/wiki/Balata_Garden',
          hasLandmark: true,
        },
        city: {
          name: 'Ducos',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/98788292@N04/14489394056/in/photolist-o5nW3U-3NwaQV-3NwagR-2pBZY7e-2p6XzzY-a9WzJX-o5nZym-2oAE1ny-2o3FmXA-3NwbGt-2o3GpxE-3NAp2S-2o3Fmnn-Dz2PLU-2o3DXTJ-2o3Gqic-2o3GoG6-2o3Fvks-2o3GrKf-2o3Bx2e-2o3GMEd-2o3GLX1-2o3FsiA-2o3FuiC-2o3Gmxg-2o3BBjh-2o3BvH2-2o3BpH1-2o3DULw-2o3BAJp-2o3GFct-weiRNq-2o3FiWL-2o3Gm6p-2o3Fpf1-2o3GqQz-2o3DWT2-2o3GKc2-2o3BzWc-2o3DUYf-2o3DVMQ-2o3Gq1t-2o3FryK-2o3BAC7-2o3GJJt-2o3DV5N-2o3DZgD-2o3BtCq-2o3GhXR-2o3DXLu',
          wikiLink: 'https://en.wikipedia.org/wiki/Ducos,_Martinique',
        },
        landmarksRound: [
          { code: 'LY', number: 3 },
          { code: 'CW', number: 1 },
          { code: 'DK', number: 1 },
        ],
        landmarkPlaces: [
          'Fasil Ghebbi',
          'Bamboo Cathedral',
          'Arecibo Observatory',
          'Church of Saint Lazarus',
          'Sundarbans',
          'Tsodilo Hills',
          'Christ Church Cathedral',
        ],
        uniqueId: '29yKm2hK',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Mauritania',
    code: 'MR',
    capital: 'Nouakchott',
    lat: 20,
    long: -12,
    cities: ['Nouakchott', 'Nouadhibou', 'Nema', 'Kaedi', 'Kiffa', 'Atar'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/0xFA04r_0ds',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritania',
        },
        landmark: {
          name: 'Chinguetti',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Chinguetti#/media/File:Chinguetti_mosquee.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Chinguetti',
          hasLandmark: true,
        },
        city: {
          name: 'Nouakchott',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nouakchott#/media/File:Nouakchott.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nouakchott',
        },
        landmarksRound: [
          { code: 'LS', number: 1 },
          { code: 'CW', number: 2 },
          { code: 'MO', number: 1 },
        ],
        landmarkPlaces: [
          'Matobo National Park',
          "Diocletian's Palace",
          'Petronas Towers',
          'Chichen Itza',
          'Baiterek',
          'Ruhyyet Palace',
          'Temple of Literature',
        ],
        uniqueId: 'yCGrYdmG',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/desert-camels-arab-mauritania-733728/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritania',
        },
        landmark: {
          name: 'Ben Amera',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ben_Amera#/media/File:Ben_Amira_2006.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ben_Amera',
          hasLandmark: true,
        },
        city: {
          name: 'Nouadhibou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nouadhibou#/media/File:Nouadhibou,BlvdMedian.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nouadhibou',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '2z6L3Lky',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Mauritania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-standing-at-the-edge-of-a-rock-mountain-facing-the-mountains-during-day-0xFA04r_0ds',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritania',
        },
        landmark: {
          name: 'Chinguetti',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Chinguetti#/media/File:Chinguetti-Vue_Goblale_Vieille_ville.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Chinguetti',
          hasLandmark: true,
        },
        city: {
          name: 'Nouakchott',
          photographer: '',
          imageLink: 'https://en.wikipedia.org/wiki/File:Nouakchott.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nouakchott',
        },
        landmarksRound: [
          { code: 'KW', number: 2 },
          { code: 'KI', number: 2 },
          { code: 'ER', number: 2 },
        ],
        landmarkPlaces: [
          'Stephen the Great Monument',
          'Montserrat Volcano Observatory',
          'Patuxai',
          'Tomb of Hafez',
          'Burj Khalifa',
          'Macau Tower',
          'The Little Chapel',
        ],
        uniqueId: 'qGStQ4Nf',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Mauritania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/woman-sitting-inside-room-rYqm_OZaZrU',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritania',
        },
        landmark: {
          name: 'Richat Structure',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/24354425@N03/11898156296',
          wikiLink: 'https://en.wikipedia.org/wiki/Richat_Structure',
          hasLandmark: true,
        },
        city: {
          name: 'Nouadhibou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nouadhibou#/media/File:Nouadhibou,NumerowatN.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nouadhibou',
        },
        landmarksRound: [
          { code: 'LS', number: 4 },
          { code: 'MS', number: 2 },
          { code: 'GU', number: 1 },
        ],
        landmarkPlaces: [
          'Jökulsárlón',
          'Milwane Wildlife Sanctuary',
          'Buddha Park',
          'Ruínas de São Paulo',
          'Leptis Magna',
          'Mata-Utu Cathedral',
          'Laxey Wheel',
        ],
        uniqueId: 'gZQy5zKt',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Mauritania',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/127571429@N04/31809482255/in/photolist-Pem1tz-QhAA4z-QeSnVd-QpADjf-QhAzBc-Pem1ma-QpAFQN-QhAymM-QsTURe-Pem14M-QpACQE-QhAAHa-Pem1hH-QpAFBb-2njJoE9-QsTVbn-QpADxS-Pem1eX-Pem1wR-QpADru-QpAFtf-QhAAbP-Pem1xT-QpAE27-QhAyv4-Pem1DV-QpAGK3-PTYgkf-QpADUJ-QpAFNd-QpABRA-QhAyeH-QpADks-QpAC7A-Pem1zX-PbAXyL-QpABTj-QhAzvv-QpADyU-QpADFC-Pem1kt-QpADWC-QpADCb-QpACqG-QpADNm-QsTSk2-Pem17T-Pem1c2-PekZY6-QpADFN',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritania',
        },
        landmark: {
          name: 'Chinguetti Mosque',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/51937301@N03/15519588018/in/photolist-pDpWPL-23F3VNg-2kP5YQR-2kJcF2Y-2ekg7qq-2kQzJSn-2npYC8v-2nzub2K-ePJv1e-2kNq5tN-2kSLbhK-6p7j9p-7iRtKn-823pPR-cBLwAq-24ALpGc-9GtjaU-2owqPjT-2owt9xA-2npTm3A-2m1B8TA-2kRrL9w-2kQJ8bv-4HX33Y-2T5HSZ-bseQ9W-4btJFs-2owqJmG-bq483e-bq44W8-9EesSV-2owsL25-2owrFwz-bq45Te-pvALVf-73SK4b-73WaEH-qbwMif-4wrHns-2ieWZ6k-zFrBQ-2ipuqZX-2ipsqHF-2ipuZnc-2ownMGr-2owsKVU-9Ees6i-2gfKY4x-2iprTxV-2ownMKH',
          wikiLink: 'https://en.wikipedia.org/wiki/Chinguetti_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Nouadhibou',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/142758044@N07/28830419916/in/photolist-KVDrps-K2Sim7-KRHbLF-KVDqHh-KRHc2v-K2SggW-K2SfbE-K2Shbb-KRHbbx-KVEJVs-K2SgWU-K2Sg6L-KRHbxz-ooKqxY-KYoR9c-op2A1G-2oDFjW2-7bDXs8-2oYtTvg-2p3irSf-7bPrHS-7ceQ6u-6mQkWV-3DXUbZ-aXSem8-e6ctu7-e66QZX-e6cr8d-e6cv81-e6cvME-e66Ptv-e66MhM-e6crW5-e6cMh3-e66Lya-79iY9T-e79QDJ-e678iz-e67bLD-e74e7k-e66JaF-e6cmBu-e6cPd9-e6cQDU-e72YWR-e677Ax-2oEy2WF-2oEDaGz-7bGCvb-awktzA',
          wikiLink: 'https://en.wikipedia.org/wiki/Nouadhibou',
        },
        landmarksRound: [
          { code: 'IE', number: 3 },
          { code: 'RW', number: 2 },
          { code: 'CZ', number: 5 },
        ],
        landmarkPlaces: [
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Château de Chambord',
          "Women's Bridge",
          'Cristo de La Misericordia',
          'Les Eclaireurs Lighthouse',
          'Champagne Reef, Dominica',
          'Gangtey Monastery',
        ],
        uniqueId: 'gyPflWcV',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Nouakchott',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-herd-of-camel-standing-next-to-each-other-mvTggVGxfK4',
          wikiLink: 'https://en.wikipedia.org/wiki/Nouakchott',
        },
        landmark: {
          name: 'Ben Amera',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/catsitchyfeet/3993513146/in/photolist-dbEmxi-dbEnkd-dbEphN-dbD8Vu-dbEoxJ-dbEmgk-dbEoEi-dbEmbF-8ZyPJe-oM81fD-2n9XoDV-2na6vN1-oM8iPN-dbEthw-dbEsQw-dbD8ew-75TNjW-dbD5LM-dbD9uE-dbEwvA-75PVcr-dbEypy-dbEuBA-dbEwbG-dbEoJF-dbEv5g-dbD6mZ-dbD7ti-dbD6bF-dbD6z2-dbD79P-FGPGrA-2h35cYe-dbEtdF-dbD7wK-dbDa2q-dbD8oh-dbD8RE-dbD5SM-dbD9cw-dbD9BL-dbEkWP-dbD6VK-dbD9F5-dbD6SH-dbD6jk-dbD6YZ-dbD6De-dbD8ks-dbD8wb',
          wikiLink: 'https://en.wikipedia.org/wiki/Ben_Amera',
          hasLandmark: true,
        },
        city: {
          name: 'Kiffa',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/maik_kregel/48020433606/in/photolist-2gapgUs-9nkfSK-3ZFBF8-2oad3T7-2oa8ihE-2gaqk1b-2niHrsj-2niQ2c8-2niQ2a9-pouKkN-6Nvkj2-4V9BEY-JxABm-mvPTKi-brQd4U-mvPn3Z-2pNdYcT-yGjnas-9SdkEJ-6ehRJs-2jLbq6w-cGCXXE-2pNfMUc-eh1GQp-2pNghyY-eh1GtK-2pNf6u8-mvRaTu-mvPTmc-brQhxG-mvPTSn-mvPnmK-8NdaMq-mvPTPM-2Wz8U-2YnQ48-946Gu2-9fQ64b-4waAaw-2oCp8LW-eh1H8a-SQP2mJ-2pNf6x4-46YzrB-d4DyzU-2pNf612-d4Dy8f-2pNf5W4-5YMGfs-2q2MEa1',
          wikiLink: 'https://en.wikipedia.org/wiki/Kiffa',
        },
        landmarksRound: [
          { code: 'EC', number: 2 },
          { code: 'HT', number: 3 },
          { code: 'CR', number: 2 },
        ],
        landmarkPlaces: [
          'Salar de Uyuni',
          'Annaberg Sugar Plantation',
          'Island of Mozambique',
          'Viru Gate',
          'Maiden Tower',
          'Mount Karthala',
          'Badshahi Mosque',
        ],
        uniqueId: 'Hv4PvKmq',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Mauritius',
    code: 'MU',
    capital: 'Port Louis',
    lat: -20.28333333,
    long: 57.55,
    cities: [
      'Port Louis',
      'Curepipe',
      'Vacoas-Phoenix',
      'Central Flacq',
      'Quatre Bornes',
      'Beau-Bassin Rose-Hill',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/wVuRAom5v6U',
          wikiLink: 'https://en.wikipedia.org/wiki/Le_Morne_Brabant',
        },
        landmark: {
          name: 'Seven Colored Earths',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mauritius-seven-colored-earth-4502626/',
          wikiLink: 'https://en.wikipedia.org/wiki/Seven_Coloured_Earths',
          hasLandmark: true,
        },
        city: {
          name: 'Curepipe',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:The_view_from_Trou_aux_Cerfs_%283007258484%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Curepipe',
        },
        landmarksRound: [
          { code: 'KE', number: 1 },
          { code: 'FI', number: 1 },
          { code: 'CY', number: 2 },
        ],
        landmarkPlaces: [
          'Blue Lagoon',
          'Vianden Castle',
          'Church of Our Savior',
          'Cologne Cathedral',
          'Monday Falls',
          'Aleppo Citadel',
          "Memorial de l'Anse",
        ],
        uniqueId: 'LZW6YxFW',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/boat-see-men-mauritus-coast-850510/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritius',
        },
        landmark: {
          name: 'Chamarel Waterfall',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/waterfall-green-landscape-tropical-798571/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chamarel',
          hasLandmark: true,
        },
        city: {
          name: 'Port Louis',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Port_Louis#/media/File:Port_Louis,_Mauritius.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Louis',
        },
        landmarksRound: [
          { code: 'AU', number: 2 },
          { code: 'AD', number: 1 },
          { code: 'MW', number: 1 },
        ],
        landmarkPlaces: [
          'Al Fateh Grand Mosque',
          'Chinguetti',
          'Floralis Generica',
          "Prince's Palace of Monaco",
          'Rijksmuseum',
          'Olavinlinna',
          'Monday Falls',
        ],
        uniqueId: 'j9x4fznp',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Mauritius',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-island-beside-body-of-water-ByAHlRiTQjo',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritius',
        },
        landmark: {
          name: 'Seven Coloured Earths',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Seven_Coloured_Earths#/media/File:Seven_coloured_earths_mauritius.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Seven_Coloured_Earths',
          hasLandmark: true,
        },
        city: {
          name: 'Port Louis',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Port_Louis#/media/File:Champ_de_Mars.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Louis',
        },
        landmarksRound: [
          { code: 'SZ', number: 4 },
          { code: 'ME', number: 1 },
          { code: 'MG', number: 2 },
        ],
        landmarkPlaces: [
          'Acropolis',
          'Independence Monument',
          'Uganda National Mosque',
          "Ruins of St. Paul's",
          'Karnak',
          'Baiterek',
          "St. Peter's Basilica",
        ],
        uniqueId: '9QJFqjC3',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Mauritius',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/waterfalls-in-the-middle-of-green-trees-EBUBRr9i4bk',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritius',
        },
        landmark: {
          name: 'Notre Dame Auxiliatrice',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Cap_Malheureux#/media/Fichier:Church_Notre_Dame_Auxiliatrice,_Mauritius.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cap_Malheureux',
          hasLandmark: true,
        },
        city: {
          name: 'Beau-Bassin Rose-Hill',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Beau_Bassin-Rose_Hill#/media/File:Plaza_(Mauritius).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Beau-Bassin_Rose-Hill',
        },
        landmarksRound: [
          { code: 'GI', number: 1 },
          { code: 'LI', number: 4 },
          { code: 'KG', number: 3 },
        ],
        landmarkPlaces: [
          'Trevi Fountain',
          'Parque Nacional de Malabo',
          'Arecibo Observatory',
          'Mount Fuji',
          'Champagne Reef, Dominica',
          'Baalbek',
          'Fouta Djallon',
        ],
        uniqueId: 'XgSDY4rH',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Mauritius',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-island-beside-body-of-water-ByAHlRiTQjo',
          wikiLink: 'https://en.wikipedia.org/wiki/Mauritius',
        },
        landmark: {
          name: 'Seven Coloured Earths',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-scenic-view-of-a-lush-green-valley-1OLqSEXXlGA',
          wikiLink: 'https://en.wikipedia.org/wiki/Seven_Coloured_Earths',
          hasLandmark: true,
        },
        city: {
          name: 'Curepipe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-house-with-a-red-and-white-door-and-a-blue-door-ADFz5JCOEnk',
          wikiLink: 'https://en.wikipedia.org/wiki/Curepipe',
        },
        landmarksRound: [
          { code: 'MY', number: 2 },
          { code: 'SS', number: 2 },
          { code: 'AL', number: 2 },
        ],
        landmarkPlaces: [
          'Kampong Kianggeh',
          'Kunta Kinteh Island',
          'Patuxay Monument',
          'Green Grotto Caves',
          'Magnus Cathedral',
          'Château Frontenac',
          'Ithaa',
        ],
        uniqueId: 'zCRKy8Ns',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Port Louis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-person-sitting-on-a-bench-in-front-of-a-building-PVLgpVX2-qE',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Louis',
        },
        landmark: {
          name: 'Jummah Mosque',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/aktkbk/46528632892/in/photolist-2g6Mswr-6fmspx-o1gvD-23Dwhvr-2dTzpwu-2bSqvPc-5Akt5M-8tGten-rn1xz9-8tQzAX-7F86mP-2dcFTqo-7F86jR-7FbY6W-6fqrFU-6fqrPE-6fm2Pa-2czozye-2dTzgfu-2dYci9x-RQcV8E-9Gtn5A-9kHxV2-5SYrk7-8CwCHi-6fqrzh-rQ12Th-6fqrq9-dK7bxT-9gtwxT-29VzsxZ-zvfUxF-3pYom-ZuZPxZ-dKK7i9-7F7Yht-dYEMp8-kKstot-arsyjv-oACLzL-eppKAV-9fnDsP-7JfaBP-o9G1DF-5C5Qo8-Xj37zU-9uP6hn-5m4zR2',
          wikiLink: 'https://en.wikipedia.org/wiki/Jummah_Mosque_(Mauritius)',
          hasLandmark: true,
        },
        city: {
          name: 'Port Louis',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Port_Louis,_Mauritius.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Louis',
        },
        landmarksRound: [
          { code: 'MO', number: 2 },
          { code: 'MG', number: 1 },
          { code: 'ZW', number: 5 },
        ],
        landmarkPlaces: [
          'Donkey Sanctuary',
          'Sans-Souci Palace',
          'Durrell Wildlife Park',
          'Jal Mahal',
          'Liberian National Museum',
          'House of Slaves',
          'Serengeti',
        ],
        uniqueId: 'KX3GBVkr',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Mayotte',
    code: 'YT',
    capital: 'Mamoudzou',
    lat: -12.83333333,
    long: 45.16666666,
    cities: [
      'Acoua',
      'Mamoudzou',
      'Longoni',
      'Koungou',
      'Bandrele',
      'Pamandzi',
      'Bandrélé',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tr%c3%a9vani-beach-mayotte-indian-ocean-604064/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tr%C3%A9vani',
        },
        landmark: {
          name: 'Mont Choungui',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mont_Choungui#/media/File:Choungui_nord.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mont_Choungui',
          hasLandmark: true,
        },
        city: {
          name: 'Mamoudzou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mamoudzou#/media/File:Mamoudzou_(10029936275).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mamoudzou',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'L8HY3s4g',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/sada-mayotte-mosque-604056/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mayotte',
        },
        landmark: {
          name: 'Dziani Dzaha',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dziani_Dzaha#/media/File:Dziani_Dzaha_(2850515800).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dziani_Dzaha',
          hasLandmark: true,
        },
        city: {
          name: 'Dzaoudzi',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mayotte-dzaoudzi-archipelago-2232049/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dzaoudzi',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Nvlgp7jb',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Dzaoudzi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-mountain-near-body-of-water-during-sunset-qjyY80RtOEU',
          wikiLink: 'https://en.wikipedia.org/wiki/Dzaoudzi',
        },
        landmark: {
          name: 'Dziani Dzaha',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dziani_Dzaha#/media/File:Dziani_Dzaha_in_Volcanic_Crater_in_Mayotte.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dziani_Dzaha',
          hasLandmark: true,
        },
        city: {
          name: 'Mamoudzou',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mamoudzou#/media/File:Mamoudzou_(10029936275).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mamoudzou',
        },
        landmarksRound: [
          { code: 'GD', number: 1 },
          { code: 'GB', number: 1 },
          { code: 'IQ', number: 4 },
        ],
        landmarkPlaces: [
          'Fasil Ghebbi',
          'Sans-Souci Palace',
          'Solis Theater',
          'Mont Ross',
          'Pulhapanzak Waterfalls',
          'Columbus Park',
          'Doonagore Castle, Doolin, Ireland',
        ],
        uniqueId: 'ttVVWC2G',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Mayotte',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-rock-formation-on-sea-shore-during-daytime-fAXuwGnZJFc',
          wikiLink: 'https://en.wikipedia.org/wiki/Mayotte',
        },
        landmark: {
          name: 'Tsingoni Mosque',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tsingoni_Mosque#/media/File:Mosqu%C3%A9e_-_panoramio_(1).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tsingoni_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Koungou',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Koungou#/media/File:Koungou.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Koungou',
        },
        landmarksRound: [
          { code: 'CI', number: 4 },
          { code: 'KE', number: 3 },
          { code: 'FK', number: 1 },
        ],
        landmarkPlaces: [
          'Langkawi Sky Bridge',
          'Taputapuatea marae',
          'Vaduz Cathedral',
          'Victoria Falls',
          'Neuschwanstein Castle',
          "St. Peter's Church",
          'Vaduz Castle',
        ],
        uniqueId: '7yY7tCWT',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Mayotte',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/42276499@N08/4226235492/in/photolist-b25Ccz-b25D6x-b25CFT-b25Dnx-b25Cni-7rsyzb-21nCjjz-2fpqgLD-5Piy7X-RBLMG8-2jmjpHL-4piJn9-RBLMAr-6GiHkD-avKJ6T-6GiBii-DeXHPq-21nCo6x-21nCj8c-DeXP3m-21nCkjR-7VHZQe-6GiCdc-4yfvoK-JL73qf-7qinp9-21nCkzv-DeXM39-7qetSM-21nCp8n-21nCoUX-21nCppz-21nCmz6-DeXKGy-21nCnLK-DeXNgb-c6kWwb-7qDGwW-5hwu7y-acW6VE-ZhpjBL-7rEMZZ-7yU37p-7yU39x-4HDMce-4HDM8B-g5B3H-2kWFjcs-5PkdYV-2nd4tzz',
          wikiLink: 'https://en.wikipedia.org/wiki/Mayotte',
        },
        landmark: {
          name: "N'Gouja",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/148116443@N05/50113839587/in/photolist-2jmowz2-2jmowzn-2jmjpsW-2kjWBB2-2kjZQwh-2kjWD2G-UDdwvD-4HR8sP-4HRbBx-2kk1ok2-bDWc1h-pZTKGM-jxmLg-aWv5dT-aWvgqr-p47ig3-pHyitJ-p478TA-bYqHry-c49XAG-aBuH8W-4ZnZ2T-5ZZubY-gjCVeD-8BtetQ-9H3pob-S9bk1d-WNtMLv-Y2XPyP-7CgFmQ-cNpYy-cZd94h-4HV2th-3Y4eqb-4HLpRh-2cq64vq-2oAGQPX-2nopCnh-2noje6X-9GwuRU-rrxKKq-2nos4XZ-2nojdjX-2noprfq-2nopFGJ-83SdT1-6E4oeL-6Dshgx-6DwqKY-CahBvQ',
          wikiLink: 'https://fr.wikipedia.org/wiki/N%27Gouja',
          hasLandmark: true,
        },
        city: {
          name: 'Bandrélé',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/130828646@N02/49766859793/in/photolist-2iPJayM-2iPxKen-DH8WF1-8xG8SK-5rQNid-DH8KFq-Uqdma6-ahKTfd-5DxCpL-4HE1LR-E7o3Pc-DHtZip-jmKw7r-ECxPUm-DHtGmD-ECxUC9-mrVu18-EFbNaD-DRZJPX-ECVCFm-EnguJU-4HE1Rz-4HHADC-4HE1JP-4HDm1R-4HHAxQ-b3Pfbv-8M6mvY-2oZvLZh-rkXzZg-2iAd6pr-pFPMX8-4JsTCq-4JszVm-4HDkYT-4HDkTX-Enf93A-4HHAAb-DREDno-ECUA7W-4JsMnq-QMhse-2iy9yS3-pW9Zou-EF9Uba-2YwSYS-SaoKk9-DS1cRc-9kK32Y-rEc6iN',
          wikiLink: 'https://en.wikipedia.org/wiki/Bandr%C3%A9l%C3%A9',
        },
        landmarksRound: [
          { code: 'BH', number: 3 },
          { code: 'JE', number: 5 },
          { code: 'AS', number: 3 },
        ],
        landmarkPlaces: [
          'Roman Baths',
          'Church of Our Lady of the Rosary',
          'Al Alam Palace',
          'Niagara Falls',
          'Malolotja National Park',
          'Ħal Saflieni Hypogeum',
          'Panama Canal',
        ],
        uniqueId: 'kRgZvLfZ',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Mamoudzou',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/130828646@N02/49752546348/in/photolist-2dHTDHx-SYz2sf-ghj3BM-2iNsNFf-2iNvMXJ-GP8tFF-2f7Jtck-2dHTUfP-SYzgTu-2f36dAW-24veQ1z-2e1PoYs-RmmNfF-2e1PkZG-SYzjQG-2dHTyp6-4DL37d-4DL3aW-GLXpbG-4DL3af-4DFLgp-4DFLhz-4DL36w-4DFLda-4DL3eQ-GERqpC-GLXohs-pXhJbj-Gp4sWm-5doGPT-7yU37p-7yU39x-4DL3dh-4DFLbr-Gp4tK5-8SXwDe-m7dNVD-FTCZSC-4DL31S-c3nuW5-pX9Pdx-4DFL7g-4DL365-4DFCPX-4DL34j-4DFL8x-4DKUQ5-4DL32S-4DFgqc-4DFL7K',
          wikiLink: 'https://en.wikipedia.org/wiki/Mamoudzou',
        },
        landmark: {
          name: 'Plage de Sakouli',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/mwanasimba/3052200844/in/photolist-5DHjYu-5DHjdq-5DHhW7-5DHiTC-5DHhJS-5DD1da-5DD1o4-5DHijb-5DCZPB-5DHmiq-5DD3Er-2jdVLdg-5DHm6h-5DHkPm-2jdYxdf-2oNRtVJ-UUhGQQ-Fhv2JH-9trcRr-9trf6Z-3kiujz-q1uJw6-qMNufH-9treAH-9trdWT-9kK1g5-9kK2eQ-9trfGv-9tvU8w-9kK23U',
          wikiLink: 'https://fr.wikipedia.org/wiki/Plage_de_Sakouli',
          hasLandmark: true,
        },
        city: {
          name: 'Pamandzi',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/jan-guy/35550552306/in/photolist-2opGr1S-WatSD1-2otcfWi-2otcfDV-2otdmxN-roa8fo-5kTL2A-ghjrKt-2nYhWvg-qc8Q9B-ikHbdB-ivGNM3-ikH934-5ErLuX-2dTNbBq-ds7sDm-5ErLAB-4DH48K-4DMjSw-4DH41M-4DH3X8-4DH3Ti-4DH44X-2odQq9a-ahGa3B-ghj5v7-2p29jVQ-5F7JXw-a3RBzS-ds5PXp-aixytz-2oV2zhC-a3RBvq-dsfsbr-dsfxzF-dsf1PX-dsf7bB-5EDXLG-dsfcun-dsiQU5-dsfneM-dsfrMj-dseV7c-4DMjRJ-dsiTcd-5kXQth-5EDXUS-5kMeig-5kS4Ej-pX8qbH',
          wikiLink: 'https://en.wikipedia.org/wiki/Pamandzi',
        },
        landmarksRound: [
          { code: 'BG', number: 2 },
          { code: 'ID', number: 4 },
          { code: 'IM', number: 6 },
        ],
        landmarkPlaces: [
          'Queen Emma Bridge',
          'Gyeongbukgong Palace',
          'Domes of Fabedougou',
          'Monte Carlo Casino',
          'Funafala',
          'Paphos Archaeological Park',
          'Badshahi Mosque',
        ],
        uniqueId: 'nQZPdCLF',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Mexico',
    code: 'MX',
    capital: 'Mexico City',
    lat: 23,
    long: -102,
    cities: [
      'Oaxaca',
      'Mexico City',
      'Merida',
      'Guadalajara',
      'Cancun',
      'Puebla',
      'Durango ',
      'Veracruz',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/OYGN7McXKCM',
          wikiLink: 'https://en.wikipedia.org/wiki/Chichen_Itza',
        },
        landmark: {
          name: 'Basilica of Our Lady of Guadalupe',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Guadalupe#/media/File:Bazilika_de_Guadalupe_DSC_7276.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Guadalupe',
          hasLandmark: true,
        },
        city: {
          name: 'Oaxaca',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/oaxaca-city-houses-mexico-colonial-381666/',
          wikiLink: 'https://en.wikipedia.org/wiki/Oaxaca_City',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'kfDmz2Ky',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/guanajuato-city-buildings-mountains-5838206/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guanajuato',
        },
        landmark: {
          name: 'Chichen Itza',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/chich%c3%a9n-itza-mexico-pyramid-maya-3874449/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chichen_Itza',
          hasLandmark: true,
        },
        city: {
          name: 'Mexico City',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-travel-tourism-mexico-city-7069010/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mexico_City',
        },
        landmarksRound: [
          { code: 'IQ', number: 1 },
          { code: 'BD', number: 2 },
          { code: 'GS', number: 1 },
        ],
        landmarkPlaces: [
          'Golden Gate Bridge',
          'Cathedral of Brasilica',
          'Christ Church Cathedral',
          'Hofburg Palace',
          'Queen Emma Bridge',
          'Amber Palace',
          'Kelonia',
        ],
        uniqueId: 'snp58RMZ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Valladolid',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/low-angle-photo-of-temple-QKrLdkoYDrc',
          wikiLink: 'https://en.wikipedia.org/wiki/Valladolid,_Yucat%C3%A1n',
        },
        landmark: {
          name: 'Chichen Itza, Mexico',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-people-standing-ear-gray-temple-VPavA7BBxK0',
          wikiLink: 'https://en.wikipedia.org/wiki/Chichen_Itza',
          hasLandmark: true,
        },
        city: {
          name: 'Mexico City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-jXBdu3_inik',
          wikiLink: 'https://en.wikipedia.org/wiki/Mexico_City',
        },
        landmarksRound: [
          { code: 'LB', number: 2 },
          { code: 'IL', number: 4 },
          { code: 'PF', number: 4 },
        ],
        landmarkPlaces: [
          'Monument to the Heroes of the Restoration',
          'Larabanga Mosque',
          'Ducie Island',
          'Bagne des Annamites',
          'Cathédrale Notre-Dame',
          'Dubai Frame',
          "Ruins of St. Paul's",
        ],
        uniqueId: '3ck9yyWf',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'San Miguel de Allende',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-standing-on-corner-road-near-concrete-buildings-during-daytime-3snKY0XMKwg',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Miguel_de_Allende',
        },
        landmark: {
          name: 'Frida Kahlo Museum',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Frida_Kahlo_Museum#/media/File:OutsideCasaAzul.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Frida_Kahlo_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Guadalajara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-near-water-fountain-cGOkS_UvttU',
          wikiLink: 'https://en.wikipedia.org/wiki/Guadalajara',
        },
        landmarksRound: [
          { code: 'GM', number: 3 },
          { code: 'LT', number: 1 },
          { code: 'AE', number: 1 },
        ],
        landmarkPlaces: [
          'Chorsu Bazaar',
          'Molinere Underwater Sculpture Park',
          'Jökulsárlón',
          'The 1763 Monument',
          'Petronas Towers',
          'Cape Coast Castle',
          'Sistine Chapel',
        ],
        uniqueId: 'mxRKDrxH',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Mexico',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/mountain-peak-during-daytime-uILhNE1VcwA',
          wikiLink: 'https://en.wikipedia.org/wiki/Mexico',
        },
        landmark: {
          name: 'Tulum',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-swimming-on-body-of-water-Uqp4kDemaew',
          wikiLink: 'https://en.wikipedia.org/wiki/Tulum',
          hasLandmark: true,
        },
        city: {
          name: 'Durango ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-sun-shines-through-the-trees-in-the-forest-ep3dwKWCakI',
          wikiLink: 'https://en.wikipedia.org/wiki/Durango_(city)',
        },
        landmarksRound: [
          { code: 'GM', number: 5 },
          { code: 'HT', number: 4 },
          { code: 'BH', number: 4 },
        ],
        landmarkPlaces: [
          'Kokoda Track',
          'Karlštejn Castle',
          "Prince's Palace of Monaco",
          'Basilica of Our Lady of Peace',
          'Predjama Castle',
          'Atlas Cove',
          "St. Elizabeth's Cathedral",
        ],
        uniqueId: 'HHxqxFPx',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Mexico City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-the-top-of-a-hill-8A4E8bGLUqY',
          wikiLink: 'https://en.wikipedia.org/wiki/Mexico_City',
        },
        landmark: {
          name: 'Plaza de la Constitución',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-chandelier-hanging-from-the-ceiling-u33gu2lGzMk',
          wikiLink:
            'https://es.wikipedia.org/wiki/Plaza_de_la_Constituci%C3%B3n_(Ciudad_de_M%C3%A9xico)',
          hasLandmark: true,
        },
        city: {
          name: 'Veracruz',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-white-building-with-a-clock-tower-on-top-of-it-8BI8kq5Wn0s',
          wikiLink: 'https://en.wikipedia.org/wiki/Veracruz',
        },
        landmarksRound: [
          { code: 'VA', number: 2 },
          { code: 'TW', number: 1 },
          { code: 'GE', number: 2 },
        ],
        landmarkPlaces: [
          'Morne Seychellois',
          'Tomb of Hafez',
          'Seljalandsfoss',
          'Rarotonga',
          'Mont Orgueil Castle',
          'Naval Support Facility Diego Garcia',
          'Jardin de Balata',
        ],
        uniqueId: 'Nxp7M43D',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Micronesia',
    code: 'FM',
    capital: 'Palikir',
    lat: 6.91666666,
    long: 158.25,
    cities: ['Palikir', 'Weno', ''],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Islands_on_the_southern_barrier_reef_of_Pohnpei_%28Federated_States_of_Micronesia%29_-_Bikini.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pohnpei',
        },
        landmark: {
          name: 'Nan Madol',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nan_Madol#/media/File:Nan_Madol_megalithic_site,_Pohnpei_(Federated_States_of_Micronesia)_4.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nan_Madol',
          hasLandmark: false,
        },
        city: {
          name: 'Kolonia',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Pohnpei_Airport,_Micronesia_%284018917563%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kolonia',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Kgs7FN3Y',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Islands_on_the_southern_barrier_reef_of_Pohnpei_%28Federated_States_of_Micronesia%29_-_Penieu_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pohnpei',
        },
        landmark: {
          name: 'Chuuk Lagoon',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Chuuk_lagoon,_Mochun_Fanananei_Pass_aerial_view_%28cropped%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Chuuk_Lagoon',
          hasLandmark: false,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'FffWkShY',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Micronesia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-mountains-g18MsEMgdNY',
          wikiLink: 'https://en.wikipedia.org/wiki/Micronesia',
        },
        landmark: {
          name: 'Chuuk Lagoon',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/66550630@N08/14823785147',
          wikiLink: 'https://en.wikipedia.org/wiki/Chuuk_Lagoon',
          hasLandmark: true,
        },
        city: {
          name: 'Palikir',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/18792313@N05/2036280321',
          wikiLink: 'https://en.wikipedia.org/wiki/Palikir',
        },
        landmarksRound: [
          { code: 'GA', number: 3 },
          { code: 'VU', number: 1 },
          { code: 'MO', number: 2 },
        ],
        landmarkPlaces: [
          'Arecibo Observatory',
          'Kiritimati',
          "Vianden's Castle",
          'Mosta Rotunda',
          'Temple of Literature',
          'Fort Zeelandia',
          "St. Peter's Church",
        ],
        uniqueId: 'hqH9LWcM',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Micronesia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/sea-diver-eRGe-x7lI1Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Micronesia',
        },
        landmark: {
          name: 'Nan Madol',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nan_Madol#/media/File:Nan_Madol,_Pohnpei.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nan_Madol',
          hasLandmark: true,
        },
        city: {
          name: 'Weno',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Weno#/media/File:Weno_island.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Weno',
        },
        landmarksRound: [
          { code: 'VU', number: 1 },
          { code: 'KW', number: 2 },
          { code: 'LV', number: 4 },
        ],
        landmarkPlaces: [
          'Golden Gate',
          'Tomb of Hafez',
          'Ten Thousand Buddhas Monastery',
          "People's Liberation Army Museum",
          'Christ Church Cathedral',
          'La Savane des esclaves',
          'Alhambra',
        ],
        uniqueId: 'PgVD2htV',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Micronesia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/underwater-photo-9yynIWfRCpI',
          wikiLink: 'https://en.wikipedia.org/wiki/Micronesia',
        },
        landmark: {
          name: 'Nan Madol',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/191587779@N06/50924499678/in/photolist-2kA2nz9-2kA6xV5-2kA2oEv-N5F6vm-2kA5UoA-2kA6xxB-DEMYMh-2kA2o5C-2kA2ncq-2kA5SMV-2kA5SYB-CuwDK-2kA2nS8-CuwAU-EboysC-N8q1XA-76iW4X-FfTF6C-EKKXSX-EKKZ3n-FF152r-NhfiFW-4gXX2C-FCHaK7-2kA2mxV-7mtEPy-46WfKK-6a6bmU-Se7kNG-eczeut-xrQWHg-TqvA5Z-SaBYtR-76iUPF-DMuGif-vU8AsV-8Mfan-e34aib-8Mfao-76j3nz-2bRQsTN-76jmUP-76o4ts-76o5Lu-76og4L-76omrf-76omPS-5omiai-76j8NZ-8Mfaj',
          wikiLink: 'https://en.wikipedia.org/wiki/Nan_Madol',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'LS', number: 5 },
          { code: 'GA', number: 3 },
          { code: 'GG', number: 6 },
        ],
        landmarkPlaces: [
          'Trevi Fountain',
          'Hato Caves',
          'Church of Our Lady of the Rosary',
          'Shwedagon Pagoda',
          'St. Lawrence Church',
          'Yellowstone National Park',
          'Adolphe Bridge',
        ],
        uniqueId: 'm59rYykL',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Palikir',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/frankkehl/15152954985/in/photolist-DL2XoA-oNwAG1-2kqKY7Q-DFxBHc-4x7Zhz-2kqLsF9-CSpP2V-daQqbi-2kqLsFz-p61RAz-KFnQC4-2krAyVY-24A2UJp-a19t9j-7J6uTq-823aVJ-94h8C3-2kqLsDW-2kqLsus-a16BhK-a19tkw-2Q7oSd-9BrrPK-seMPys-2kqGbFH-oNvPGc-a16AZZ-a19sYm-a19t5U-Dwk89W-p3YPU5-a16B8k-oNwBV2-DMubNC-a19t1y-fUJXgX-KBsMdd-mWB84E-oNw51o-8BGndB-p5YNWh-a2S8r1-a16Bpv-9YKrM5-DnFNVU-9Vx9iP-9Vx8ti-a19sWw-LxbArT-9mNB1k',
          wikiLink: 'https://en.wikipedia.org/wiki/Palikir',
        },
        landmark: {
          name: 'Rai stones',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/radahann/4894702778/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rai_stones',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'KY', number: 2 },
          { code: 'MD', number: 3 },
          { code: 'CD', number: 1 },
        ],
        landmarkPlaces: [
          'Salomon Islands',
          'Sistine Chapel',
          'Taputapuatea marae',
          'Buddha Park',
          'Matobo National Park',
          'Château Frontenac',
          'Abu Simbel',
        ],
        uniqueId: 'ST5Cp7f9',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Moldova',
    code: 'MD',
    capital: 'Chisinau',
    lat: 47,
    long: 29,
    cities: ['Chisinau', 'Soroca', 'Bender', 'Balti', 'Comrat', 'Tiraspol'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/j0EXEOHgTlk',
          wikiLink: 'https://en.wikipedia.org/wiki/Orhei',
        },
        landmark: {
          name: 'Soroca Fort',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Soroca_Fort#/media/File:%D0%A1%D0%BE%D1%80%D0%BE%D0%BA%D1%81%D0%BA%D0%B0%D1%8F_%D0%BA%D1%80%D0%B5%D0%BF%D0%BE%D1%81%D1%82%D1%8C_Cetatea_Soroca_Soroca_Fortress_(42928890910).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Soroca_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Tiraspol',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tiraspol#/media/File:%D0%9F%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D1%8C_%D0%A1%D1%83%D0%B2%D0%BE%D1%80%D0%BE%D0%B2%D0%B0_%D0%BF%D0%BE%D1%81%D0%BB%D0%B5_%D0%BF%D0%B0%D1%80%D0%B0%D0%B4%D0%B0.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tiraspol',
        },
        landmarksRound: [
          { code: 'HN', number: 1 },
          { code: 'TJ', number: 1 },
          { code: 'TH', number: 2 },
        ],
        landmarkPlaces: [
          'Marina Bay Sands',
          'Winter Palace',
          'Stone House',
          'Romney Manor',
          'Chorsu Bazaar',
          "Ruins of St. Paul's",
          'Voortrekker Monument',
        ],
        uniqueId: 'xBH2h5VD',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/moldova-landscape-scenic-stream-105172/',
          wikiLink: 'https://en.wikipedia.org/wiki/Moldova',
        },
        landmark: {
          name: 'Stephen the Great Monument',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Monumentul_a_lui_Stefan_cel_Mare,_Chisinau_Stefan_the_Great_Monument,_Chisinau,_Moldova_%2849601054843%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Stephen_the_Great_Monument',
          hasLandmark: true,
        },
        city: {
          name: 'Chisinau',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Chi%C8%99in%C4%83u#/media/File:Arcul_de_Triumf_din_Chi%C8%99in%C4%83u_11_-_May_-_2019_18.09.59_67.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Chi%C8%99in%C4%83u',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '8HhXg33x',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Moldova',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-body-of-water-during-daytime-j0EXEOHgTlk',
          wikiLink: 'https://en.wikipedia.org/wiki/Moldova',
        },
        landmark: {
          name: 'Valea Morilor',
          photographer: '',
          imageLink:
            'https://ro.wikipedia.org/wiki/Valea_Morilor_(Chi%C8%99in%C4%83u)#/media/Fi%C8%99ier:Scara_cu_cascade_Valea_morilor_(2016)_(3).jpg',
          wikiLink:
            'https://ro.wikipedia.org/wiki/Valea_Morilor_(Chi%C8%99in%C4%83u)',
          hasLandmark: true,
        },
        city: {
          name: 'Chisinau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-white-concrete-buildings-during-daytime-Kn5QME2SDzo',
          wikiLink: 'https://en.wikipedia.org/wiki/Chisinau',
        },
        landmarksRound: [
          { code: 'YT', number: 3 },
          { code: 'LA', number: 2 },
          { code: 'TF', number: 1 },
        ],
        landmarkPlaces: [
          'Alcázar de Colón',
          'Abu Simbel',
          'Arahoho Blowhole',
          'Little Chapel',
          'Obelisk of Axum',
          'Boiling Lake',
          'Kpatawee Waterfall',
        ],
        uniqueId: 'LslTnt9J',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Moldova',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-view-photography-of-asphalt-road-in-between-trees-8bs-Kz8ACdM',
          wikiLink: 'https://en.wikipedia.org/wiki/Moldova',
        },
        landmark: {
          name: 'Curchi Monastery',
          photographer: '',
          imageLink:
            'https://www.wikidata.org/wiki/Q4248462#/media/File:M%C4%83n%C4%83stirea_%E2%80%9ENa%C8%99terea_Maicii_Domnului%E2%80%9D_%C3%AEn_Curchi.jpg',
          wikiLink: 'https://www.wikidata.org/wiki/Q4248462',
          hasLandmark: true,
        },
        city: {
          name: 'Tiraspol',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-and-white-bus-on-road-during-daytime-MpYI91GTKWI',
          wikiLink: 'https://en.wikipedia.org/wiki/Tiraspol',
        },
        landmarksRound: [
          { code: 'UY', number: 1 },
          { code: 'GH', number: 3 },
          { code: 'FM', number: 3 },
        ],
        landmarkPlaces: [
          'Parque Nacional de Malabo',
          "Saint Michael's Cave",
          'Parthenon',
          'Gateway Arch',
          'Mount Kenya',
          'Frida Kahlo Museum',
          'Tadrart Acacus',
        ],
        uniqueId: 'MdrqJZqT',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Chisinau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-park-in-a-city-aRA508nQxH0',
          wikiLink: 'https://en.wikipedia.org/wiki/Chi%C8%99in%C4%83u',
        },
        landmark: {
          name: 'Soroca Fort',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/peamasher/45735076132/in/photolist-KVBinB-eTHVbR-eTHUzn-eTHTPH-oWTGxX-7epSYZ-2hGEKrJ-eTVjbf-2cFsdKo-2cFse6J-2kYqL55-soAuav-2kRWjv4-2cKVY8g-oFkXB1-2kYqLhQ-QCb6LC-25WSiLB-27xbrx5-R2rrqV-VRTKeM-2kYvoD5-7etPg3-phafYP-VRTJRT-W4p4b8-VND2jG-2akGVQM-W4p4gZ-qyK3gC-W4p4mP-VND2qy-W4p68e-VZTtHd-VRTJmV-VRTG4Z-VRTFp2-W4p6tp-VRTFrM-VRTJAn-VND2h7-VND2HC-VZTuhQ-W4p6XF-VRTHm8-W4p5k2',
          wikiLink: 'https://en.wikipedia.org/wiki/Soroca_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Tiraspol',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-standing-on-green-grass-field-during-daytime-wLGg4gqemYY',
          wikiLink: 'https://en.wikipedia.org/wiki/Tiraspol',
        },
        landmarksRound: [
          { code: 'CY', number: 4 },
          { code: 'BY', number: 4 },
          { code: 'KN', number: 1 },
        ],
        landmarkPlaces: [
          'Mount Alava',
          'Kaaba',
          'Baiterek',
          "Fisherman's Bastion",
          'Rizal Park',
          'Hiroshima Peace Memorial',
          'Tegallalang Rice Terrace',
        ],
        uniqueId: 'plSwhy92',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Chisinau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-many-windows-and-a-sky-background-Igm0ctfeaCU',
          wikiLink: 'https://en.wikipedia.org/wiki/Chi%C8%99in%C4%83u',
        },
        landmark: {
          name: 'Căpriana monastery',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/nicolasdne/51411113673/in/photolist-2mk2oXB-nqCuer-cHq1ku-nqmad9-9RH1xv-LmsSAY-2bhraPz-2cFfLAF-2q8mFW5-2q8mFVZ-NV2CTP-YV5L5J-66T3Xh-NV2BuM-ZYecZz-88qrTb-cRyC2f-2cAShmJ-2bhubhx-ZTu7x7-MF1h76-ZV5hdf-CS9MmA-9TTKA6-MF1gYa-6cHzJk-ZTu7pm-ZTu7GA-ZYedsP-ZYed8k-cRABmL-97htaQ-97htrs-97ht8o-97eosF-omxzRN-97httq-97eonB-8t2Wj5-8t2xKf-db4B5y-5wubim-cHq1hs-omxukP-2bhucFV-29bVg2w-oCPiUW-oB18DY-oCLd6V-oD15VL',
          wikiLink: 'https://en.wikipedia.org/wiki/C%C4%83priana_monastery',
          hasLandmark: true,
        },
        city: {
          name: 'Balti',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/vlitvinov/52743819550/in/photolist-2omMRZ9-5X9gTw-MmXuD-tdQHXC-U63Ymn-tW72jL-7ECm9c-GYShfU-D5ArrV-Po1UXk-oL51bE-U6Q4YS-DcPZhK-6dUWrD-kxYVv-9YSwXf-b8nqk6-9dsrv6-63h8HT-4bGc6X-4bLczW-4bGbzn-4bGcnz-6NhDe7-5AEW1b-8Xge9X-b4n1pF-2hQ71yf-tfGVf6-ixbA7R-8XgegK-bd2txt-6y1EDq-8Xgfcp-8Xgewg-bd2sAX-8XgehV-HKFG9f-91A1Mb-WSAz48-pYnVdB-mct6Z8-7425eR-6mkjoE-dmr3J-8XgeRZ-6csima-2jWAoad-tQhbAn-77X1Xw',
          wikiLink: 'https://en.wikipedia.org/wiki/B%C4%83l%C8%9Bi',
        },
        landmarksRound: [
          { code: 'JP', number: 5 },
          { code: 'GP', number: 6 },
          { code: 'ER', number: 6 },
        ],
        landmarkPlaces: [
          'Zaisan Memorial',
          'Old Dongola',
          'Lake Niamawi',
          'Helsinki Cathedral',
          'Château Frontenac',
          'Buddha Dordenma statue',
          'Harrison Point Lighthouse',
        ],
        uniqueId: 'kqCF4grv',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Monaco',
    code: 'MC',
    capital: 'Monaco City',
    lat: 43.73333333,
    long: 7.4,
    cities: [
      'Monaco City',
      'La Condamine',
      'Moneghetti',
      'La Koll',
      'Saint-Roman',
      'Menton',
      'Beausoleil',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/QvEULJG78U8',
          wikiLink: 'https://en.wikipedia.org/wiki/Fontvieille,_Monaco',
        },
        landmark: {
          name: "Prince's Palace of Monaco",
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/prince-palace-monaco-palace-187312/',
          wikiLink: 'https://en.wikipedia.org/wiki/Prince%27s_Palace_of_Monaco',
          hasLandmark: true,
        },
        city: {
          name: 'Monaco City',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/monaco-center-monte-carlo-1552605/',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco_City',
        },
        landmarksRound: [
          { code: 'CH', number: 1 },
          { code: 'ID', number: 1 },
          { code: 'CW', number: 1 },
        ],
        landmarkPlaces: [
          'Big Ben',
          'Bran Castle',
          'Gediminas Castle Tower',
          'Kelonia',
          'Cologne Cathedral',
          'Petronas Towers',
          'Mount Nyriagongo',
        ],
        uniqueId: 'pTYHL53h',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/monaco-city-bay-europe-france-410301/',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco',
        },
        landmark: {
          name: 'Monte Carlo Casino',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/game-bank-casino-monte-carlo-188881/',
          wikiLink: 'https://en.wikipedia.org/wiki/Monte_Carlo_Casino',
          hasLandmark: true,
        },
        city: {
          name: 'La Condamine',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/La_Condamine#/media/File:%D0%9B%D0%B0-%D0%9A%D0%BE%D0%BD%D0%B4%D0%B0%D0%BC%D0%B8%D0%BD._%D0%9D%D0%B0_%D1%83%D0%BB._%D0%9F%D1%80%D0%B8%D0%BD%D1%86%D0%B5%D1%81%D1%81%D1%8B_%D0%9A%D0%B0%D1%80%D0%BE%D0%BB%D0%B8%D0%BD%D1%8B._-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Condamine',
        },
        landmarksRound: [
          { code: 'SJ', number: 1 },
          { code: 'CN', number: 2 },
          { code: 'FK', number: 2 },
        ],
        landmarkPlaces: [
          'The Little Chapel',
          'Predjama Castle',
          'Golden Gate',
          'Cape of Good Hope',
          'Azadi Tower',
          'Somapura Mahavihara',
          'Rizal Park',
        ],
        uniqueId: '2YhxJyJx',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Monaco',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-boat-on-sea-during-daytime-1CkSNmbT7J0',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco',
        },
        landmark: {
          name: 'Fort Antoine Theatre',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/31594504@N06/37145069043',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Antoine_Theatre',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'KZ', number: 2 },
          { code: 'GH', number: 2 },
          { code: 'IQ', number: 2 },
        ],
        landmarkPlaces: [
          'Petra',
          'Gazimestan',
          'Bikini Atoll',
          "Fa'aruma'i Waterfalls",
          'Fasil Ghebbi',
          'Abu Simbel Temples',
          'Marcus Aurelius Arch',
        ],
        uniqueId: '6BFQYwJM',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Monaco',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/palm-trees-near-body-of-water-during-daytime-1DEdhNLCRvA',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco',
        },
        landmark: {
          name: 'Oceanographic Museum of Monaco',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Mus%C3%A9e_oc%C3%A9anographique_de_Monaco#/media/Fichier:Musee_Oceanographique_de_Monaco_-_panoramio.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Oceanographic_Museum_of_Monaco',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'GD', number: 4 },
          { code: 'NP', number: 1 },
          { code: 'VA', number: 2 },
        ],
        landmarkPlaces: [
          'Grand Ducal Palace',
          'Ocean Park',
          'Abu Simbel',
          'Nan Madol',
          'Doonagore Castle, Doolin, Ireland',
          'Mont Ross',
          "Ruins of St. Paul's",
        ],
        uniqueId: 'tHj4Jcx3',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Monaco',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-docks-with-yachts-and-motorboats-during-daytime-GT4TGeuZZp0',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco',
        },
        landmark: {
          name: 'Cathedral of Our Lady Immaculate',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-church-with-a-large-ceiling-with-a-painting-on-it-p_s2a6xd7lA',
          wikiLink:
            'https://en.wikipedia.org/wiki/Cathedral_of_Our_Lady_Immaculate',
          hasLandmark: true,
        },
        city: {
          name: 'Menton',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/ocean-waves-crashing-on-shore-during-daytime-gxQn26etnSU',
          wikiLink: 'https://en.wikipedia.org/wiki/Menton',
        },
        landmarksRound: [
          { code: 'ZW', number: 3 },
          { code: 'AG', number: 6 },
          { code: 'ST', number: 2 },
        ],
        landmarkPlaces: [
          'Richat Structure',
          'Isalo National Park',
          "Women's Bridge",
          'Mlilwane Wildlife Sanctuary',
          'Chichen Itza, Mexico',
          'Gibbs Hill Lighthouse',
          'Vatican Necropolis',
        ],
        uniqueId: 'zpFZRXZM',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Monaco City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-near-white-concrete-building-during-daytime-SuOsl2q3kCA',
          wikiLink: 'https://en.wikipedia.org/wiki/Monaco_City',
        },
        landmark: {
          name: 'Monte Carlo Casino',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-black-and-white-photo-of-a-building-with-a-clock-tower-g1ADVYZYjhE',
          wikiLink: 'https://en.wikipedia.org/wiki/Monte_Carlo_Casino',
          hasLandmark: true,
        },
        city: {
          name: 'Beausoleil',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-row-of-buildings-with-palm-trees-in-front-of-them-CHkY2hMGvs8',
          wikiLink: 'https://en.wikipedia.org/wiki/Beausoleil,_Alpes-Maritimes',
        },
        landmarksRound: [
          { code: 'FK', number: 3 },
          { code: 'IO', number: 2 },
          { code: 'FJ', number: 4 },
        ],
        landmarkPlaces: [
          'Registan',
          'Pula Arena',
          'Mosta Rotunda',
          'Hundertwasserhaus',
          'Old Panama',
          'Roi-Namur',
          'Palazzo Pubblico',
        ],
        uniqueId: 'pWTC5LNw',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Mongolia',
    code: 'MN',
    capital: 'Ulaanbaatar',
    lat: 46,
    long: 105,
    cities: [
      'Ulaanbaatar',
      'Erdenet',
      'Ulgii',
      'Darkhan',
      'Murun',
      'Dalanzadgad',
      'Khovd ',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/yhbanN00pb8',
          wikiLink: 'https://en.wikipedia.org/wiki/Yurt',
        },
        landmark: {
          name: 'Zaisan Memorial',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Zaisan_Memorial#/media/File:World_War_II_in_Ulaanbaatar_(6228546502).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Zaisan_Memorial',
          hasLandmark: true,
        },
        city: {
          name: 'Ulaanbaatar',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/night-view-urban-ulaanbaatar-centre-2172718/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ulaanbaatar',
        },
        landmarksRound: [
          { code: 'NA', number: 2 },
          { code: 'VN', number: 2 },
          { code: 'GE', number: 1 },
        ],
        landmarkPlaces: [
          'Olavinlinna',
          'Leaning Tower of Pisa',
          'Mostar Old Bridge',
          'Mount Nyriagongo',
          'Rhine Falls',
          'Vilnius Cathedral',
          'Bran Castle',
        ],
        uniqueId: '6PhLHm2R',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/desert-sand-dunes-mongolia-gobi-7630943/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gobi_Desert',
        },
        landmark: {
          name: 'Sukhbaatar Square',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/S%C3%BCkhbaatar_Square#/media/File:Chinggis_Square.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/S%C3%BCkhbaatar_Square',
          hasLandmark: true,
        },
        city: {
          name: 'Erdenet',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Erdenet#/media/File:Erdenet_01.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Erdenet',
        },
        landmarksRound: [
          { code: 'KG', number: 1 },
          { code: 'CW', number: 1 },
          { code: 'PY', number: 1 },
        ],
        landmarkPlaces: [
          'Timgad',
          'Tash Rabat',
          'Rock of Cashel',
          'Masjid al-Haram',
          'Christ the Redeemer',
          'Anne Frank House',
          'Mount Kenya',
        ],
        uniqueId: 'hyhm4F9n',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Equestrian statue of Genghis Khan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/statue-hand-holding-gold-rod-8o9htwKDslc',
          wikiLink:
            'https://en.wikipedia.org/wiki/Equestrian_statue_of_Genghis_Khan',
        },
        landmark: {
          name: 'Gorkhi-Terelj National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/wooden-houses-near-green-leafed-trees-during-daytime-lI1qwJeP7lE',
          wikiLink: 'https://en.wikipedia.org/wiki/Gorkhi-Terelj_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Ulaanbaatar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-night-time-adBwOz3tstk',
          wikiLink: 'https://en.wikipedia.org/wiki/Ulaanbaatar',
        },
        landmarksRound: [
          { code: 'CR', number: 2 },
          { code: 'BZ', number: 1 },
          { code: 'CU', number: 2 },
        ],
        landmarkPlaces: [
          'Sundarbans',
          'Turtle Cove',
          'Bahrain Fort',
          'Amalienborg',
          'Oriental Pearl TV Tower',
          "Devil's Throat",
          'CN Tower',
        ],
        uniqueId: '3bhpcTPq',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Mongolia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountain-near-cliff-under-white-cloudy-sky-t3nn6PQv-CY',
          wikiLink: 'https://en.wikipedia.org/wiki/Mongolia',
        },
        landmark: {
          name: 'Erdene Zuu Monastery',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/12517755@N05/20361247342/in/photolist-x2fHsE-ec4TkZ-wZFSRf-hSAUZq-fZJfBR-29anruW-Ky8C3D-ecar8U-ni3eKH-ai9WNa-2a2gfsr-29KfthU-29WYNRy-28HD1U6-dfT2Vh-2hhBgFC-2hhB7V1-ec4NZ4-4HGjQA-21sK8UU-deX8sC-8F3mxZ-61fERW-7SwQ-7BTH21-2iR9kS-6UYCpT-2ggniyu-2ggnd7v-atdgCW-8FbwLT-ataECa-fMGn36-2jPYda-GyYtLM-21vyoXc-DnQdzQ-DnQcuy-Zqqrf5-ZqqwgY-21qFynA-Zqq6Rq-21vycMg-DnQeUd-21vy5Kk-2oSh72n-21sKy7w-DnQtoh-21qFCFW-2xLqqa',
          wikiLink: 'https://en.wikipedia.org/wiki/Erdene_Zuu_Monastery',
          hasLandmark: true,
        },
        city: {
          name: 'Darkhan',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/temuulenb/48716589488/in/photolist-2hdVfN5-LfAcvu-9UJz39-24EE9ZT-2hFiFT8-2j3XrVJ-2k9VeV1-2kyWHj4-agswAo-2jUCFXS-agswwh-agpM58-2k5PLFa-2mwEpKe-2oHy7YU-2n1EvwD-2mWUc24-bxa92a-QV1Mrm-2nDTbyT-34hNcc-2ogkfeM-agpLUR-2p7WX3N-2nDN5ni-2nDVzRY-2nDT7uy-2nDN58v-2n4mh64-2n4modj-2n4rcYa-2mWT1X3-2n4mgNq-2n4moba-2ksEcci-2nDVXag-2nE3mg6-2n8VbUu-2n4t2Sc-2mwBXnT-2mwwE2j-2nE4tpi-Lf4eA-2nDN5jc-2n4t2Jr-2mwAGkD-2mwACvQ-2mwBXbA-2mXUAvw-2mXFn2a',
          wikiLink: 'https://en.wikipedia.org/wiki/Darkhan_(city)',
        },
        landmarksRound: [
          { code: 'BE', number: 1 },
          { code: 'AL', number: 2 },
          { code: 'KM', number: 3 },
        ],
        landmarkPlaces: [
          'St. Alexander Nevsky Cathedral',
          'Bayon Temple',
          'Salar de Uyuni',
          'Mount Alava',
          'Vardzia',
          'Somapura Mahavihara',
          'Bayon Temple',
        ],
        uniqueId: 'MctVYnFF',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Mongolia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-man-standing-on-top-of-a-rocky-mountain-OWDK5JeRlmU',
          wikiLink: 'https://en.wikipedia.org/wiki/Mongolia',
        },
        landmark: {
          name: 'Equestrian statue of Genghis Khan',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/twiga_swala/53329536429/in/photolist-2pfxPd2-VKfaPu-gfYDPM-2oX4kf6-2pfyqhG-pPLy22-2pfw3tw-2pfw4ri-2pfxcBX-2pfxdBT-2pfxcBr-2pfxdQP-2pfyqkT-2pfxPnW-2pfyqxB-geThFj-2pfxdvv-2iGQr4u-2pfrzSE-2pfxdQD-2pfryQQ-2pfw4xf-2pfrzMK-2pfrzL2-2pfrzGj-2pfrzGp-2pfxcAj-NSRHTG-2oLweKs-wSjkgY-bQ3z7P-2oX5o9w-2oX4khf-2oX5oa3-2oMFHhJ-7YyjV4-2oRP2gj-EJhf8N-wSiTFG-f21g8P-dBpWf6-7YBzoL-wSriAF-ggtVps-2oLweKx-PkV4Uj-7YykWx-7YByXh-2oMJ7eF-7YykCk',
          wikiLink:
            'https://en.wikipedia.org/wiki/Equestrian_statue_of_Genghis_Khan',
          hasLandmark: true,
        },
        city: {
          name: 'Ulaanbaatar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-roof-of-a-building-with-a-building-in-the-background-NuHXbzuh5XY',
          wikiLink: 'https://en.wikipedia.org/wiki/Ulaanbaatar',
        },
        landmarksRound: [
          { code: 'BR', number: 4 },
          { code: 'CI', number: 4 },
          { code: 'VG', number: 5 },
        ],
        landmarkPlaces: [
          'Registan Square',
          'Grand Mosque of Bobo-Dioulasso',
          'Salt Cathedral of Zipaquirá',
          "Lac 'Assal",
          'Kokoda Track',
          'Mlilwane Wildlife Sanctuary',
          'Dancing House',
        ],
        uniqueId: 'pWGg5vC8',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Ulaanbaatar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-black-jacket-and-pants-standing-beside-man-statue-X2JmU9_1dL4',
          wikiLink: 'https://en.wikipedia.org/wiki/Ulaanbaatar',
        },
        landmark: {
          name: 'Gandantegchinlen Monastery',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/konradlembcke/47598964991/in/photolist-2fwa8Yc-nqD69x-JCQwnq-2dbqdQy-2dbqeqS-x8NJtB-x7uzEs-UQubZQ-wbopue-nrupVT-R854bL-xYCLAu-eg2CNH-R8544G-2jHrs9q-HbvL5g-2aujCbY-2dbqffY-R853qN-R853qh-2kkppuW-xGZUu6-xYCL8A-2jb8Lmq-aiM9wK-xGU9Vb-2jb4NsJ-2jb7abm-2dfPJQx-x3ub45-xYCKLd-y1aTsr-p3fCJP-oL2NUr-atNQj9-p3w3UX-oL2Q8u-gxYMni-oL29SM-p3w5cX-dcu3rL-8zXsXv-NPhfQB-2kAgszm-28NZ9Ud-YEthab-2kybyMi-2kyfaaZ-2kyfa3j-2a9Kxav',
          wikiLink: 'https://en.wikipedia.org/wiki/Gandantegchinlen_Monastery',
          hasLandmark: true,
        },
        city: {
          name: 'Khovd ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-body-of-water-during-daytime-g4l7kM6XjeY',
          wikiLink: 'https://en.wikipedia.org/wiki/Khovd_(city)',
        },
        landmarksRound: [
          { code: 'MR', number: 6 },
          { code: 'CR', number: 1 },
          { code: 'KR', number: 2 },
        ],
        landmarkPlaces: [
          'Two Lovers Point',
          'Pedro St. James Castle',
          'Grand Turk Lighthouse',
          'Kigali Genocide Memorial',
          'Rijksmuseum',
          'Tenaru Falls',
          'Kokoda Track',
        ],
        uniqueId: 'GwqP4dcJ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Montenegro',
    code: 'ME',
    capital: 'Podgorica',
    lat: 42.5,
    long: 19.3,
    cities: [
      'Podgorica',
      'Niksic',
      'Herceg Novi',
      'Koto',
      'Tivat',
      'Kotor',
      'Budva',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/_nupO8tu6sg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bay_of_Kotor',
        },
        landmark: {
          name: 'Ostrog Monastery',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/montenegro-monastery-ostrog-rocks-2348571/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ostrog_Monastery',
          hasLandmark: true,
        },
        city: {
          name: 'Niksic',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nik%C5%A1i%C4%87#/media/File:Nik%C5%A1i%C4%87.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nik%C5%A1i%C4%87',
        },
        landmarksRound: [
          { code: 'EE', number: 1 },
          { code: 'AL', number: 1 },
          { code: 'HK', number: 1 },
        ],
        landmarkPlaces: [
          'Chinguetti',
          'Mount Paget',
          'Palace of Peace and Reconciliation',
          'Vardzia',
          'Akershus Fortress',
          'Tikal National Park',
          'Leaning Tower of Pisa',
        ],
        uniqueId: 'z8xgpDcr',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/perast-cove-kotor-montenegro-sea-4612526/',
          wikiLink: 'https://en.wikipedia.org/wiki/Montenegro',
        },
        landmark: {
          name: "St. Tryphon's Cathedral",
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/montenegro-old-kotor-medieval-3404144/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kotor_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Podgorica',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/montenegro-podgorica-communication-1351910/',
          wikiLink: 'https://en.wikipedia.org/wiki/Podgorica',
        },
        landmarksRound: [
          { code: 'BA', number: 1 },
          { code: 'CD', number: 2 },
          { code: 'BA', number: 2 },
        ],
        landmarkPlaces: [
          'Vaduz Cathedral',
          'Masjid al-Haram',
          'Tegucigalpa Cathedral',
          'Tomb of Hafez',
          'Frogner Park',
          'Alexander Nevsky Cathedral',
          'Taj Mahal',
        ],
        uniqueId: 'Kg3xhxJt',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Montenegro',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/animals-on-field-KYbDibssjXg',
          wikiLink: 'https://en.wikipedia.org/wiki/Montenegro',
        },
        landmark: {
          name: 'Bay of Kotor',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-and-a-lake-qIPzMw3PNE4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bay_of_Kotor',
          hasLandmark: true,
        },
        city: {
          name: 'Podgorica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-white-building-with-a-red-flag-on-top-of-it-EusJD-U4CJE',
          wikiLink: 'https://en.wikipedia.org/wiki/Podgorica',
        },
        landmarksRound: [
          { code: 'MD', number: 2 },
          { code: 'DE', number: 3 },
          { code: 'LT', number: 3 },
        ],
        landmarkPlaces: [
          'Sistine Chapel',
          'Motherland Monument',
          'Egyptian Museum',
          'Annandale Waterfall',
          'Temple of Literature',
          'The Basilica of Our Lady of Peace of Yamoussoukro',
          'Europa Point Lighthouse',
        ],
        uniqueId: '3wWML4vy',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Montenegro',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-near-body-of-water-during-daytime-CaMeI7mYvzo',
          wikiLink: 'https://en.wikipedia.org/wiki/Montenegro',
        },
        landmark: {
          name: 'Ostrog Monastery',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/92799346@N02/21523997035',
          wikiLink: 'https://en.wikipedia.org/wiki/Ostrog_Monastery',
          hasLandmark: true,
        },
        city: {
          name: 'Kotor',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-mountains-during-daytime-vAiCza4PuGk',
          wikiLink: 'https://en.wikipedia.org/wiki/Kotor',
        },
        landmarksRound: [
          { code: 'IR', number: 3 },
          { code: 'MX', number: 4 },
          { code: 'SV', number: 2 },
        ],
        landmarkPlaces: [
          'Two Lovers Point',
          'Seven Colored Earths',
          'Baiterek',
          'National Museum of Arts, Rites and Traditions',
          'Registan Square',
          'Little Chapel',
          'Blue Grotto',
        ],
        uniqueId: '2TYyC9V7',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Montenegro',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-white-and-brown-cruise-ship-on-water-1AUe0hwdC3o',
          wikiLink: 'https://en.wikipedia.org/wiki/Montenegro',
        },
        landmark: {
          name: 'Ostrog Monastery',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-on-a-cliff-dR_aDjF6HQ0',
          wikiLink: 'https://en.wikipedia.org/wiki/Ostrog_Monastery',
          hasLandmark: true,
        },
        city: {
          name: 'Herceg Novi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tall-building-with-a-flag-on-the-front-of-it-0GQgAa7uQkg',
          wikiLink: 'https://en.wikipedia.org/wiki/Herceg_Novi',
        },
        landmarksRound: [
          { code: 'UA', number: 1 },
          { code: 'VU', number: 3 },
          { code: 'TF', number: 6 },
        ],
        landmarkPlaces: [
          'Fongafale',
          'Fort Nuestra Señora de la Soledad',
          "N'Djamena Grand Mosque",
          'Kastelholm Castle',
          "Christian's Cave",
          'Wallblake House',
          'Blue Grotto',
        ],
        uniqueId: 'VBZz2YZ3',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Kotor',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-body-of-water-during-daytime-b_e5K7B3MzQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Kotor',
        },
        landmark: {
          name: 'Đurđevića Tara Bridge',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-train-traveling-over-a-bridge-surrounded-by-trees-LGTDMXk2yAM',
          wikiLink:
            'https://en.wikipedia.org/wiki/%C4%90ur%C4%91evi%C4%87a_Tara_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Budva',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-the-ocean-and-a-city-from-a-rocky-shore-3XIMtgEnD0c',
          wikiLink: 'https://en.wikipedia.org/wiki/Budva',
        },
        landmarksRound: [
          { code: 'JO', number: 1 },
          { code: 'CN', number: 3 },
          { code: 'AL', number: 5 },
        ],
        landmarkPlaces: [
          'Limu Pools',
          'Republic Square',
          'Bahrain Fort',
          'Tomb of Hafez',
          "Women's Bridge",
          'Ten Thousand Buddhas Monastery',
          'Lotus Temple',
        ],
        uniqueId: 'DPLhMdKb',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Montserrat',
    code: 'MS',
    capital: 'Plymouth',
    lat: 16.75,
    long: -62.2,
    cities: [
      'Plymouth',
      'Brades',
      'Salem',
      'Little Bay',
      'Davy Hill',
      'Weekes',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/landscape-nature-moutains-panorama-3524568/',
          wikiLink: 'https://en.wikipedia.org/wiki/Montserrat',
        },
        landmark: {
          name: 'Montserrat Volcano Observatory',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Montserrat_Volcano_Observatory#/media/File:Montserrat_Volcano_Observatory.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Montserrat_Volcano_Observatory',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '8fW9gbJH',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/landscape-nature-park-water-7189086/',
          wikiLink: 'https://en.wikipedia.org/wiki/Montserrat',
        },
        landmark: {
          name: 'Soufriere Hills',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Soufri%C3%A8re_Hills#/media/File:Soufri%C3%A8re_Hills_volcano_in_Monserrat.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Soufri%C3%A8re_Hills',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'WzC6RHwB',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Montserrat',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/5809290289',
          wikiLink: 'https://en.wikipedia.org/wiki/Montserrat',
        },
        landmark: {
          name: 'Soufrière Hills volcano',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/chuckstanley/8619376097',
          wikiLink: 'https://en.wikipedia.org/wiki/Soufri%C3%A8re_Hills',
          hasLandmark: true,
        },
        city: {
          name: 'Plymouth',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Plymouth,_Montserrat#/media/File:Plymouth_Montserrat_Heli.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Plymouth,_Montserrat',
        },
        landmarksRound: [
          { code: 'DO', number: 3 },
          { code: 'FR', number: 4 },
          { code: 'KG', number: 1 },
        ],
        landmarkPlaces: [
          'Rock of Gibraltar',
          'Kathmandu Durbar Square',
          'Chishimba Falls',
          'Lake Lalolalo',
          'Gracanica Monastery',
          'Victoria Falls',
          'Lake Armor',
        ],
        uniqueId: 'PvrnlBnf',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Montserrat',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rosco-photo/45066504401',
          wikiLink: 'https://en.wikipedia.org/wiki/Montserrat',
        },
        landmark: {
          name: 'National Museum of Montserrat',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/National_Museum_of_Montserrat#/media/File:Montserrat_%E2%80%94_National_Museum_of_Montserrat_(building).JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_Museum_of_Montserrat',
          hasLandmark: true,
        },
        city: {
          name: 'Brades',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Brades#/media/File:Brades_administration_2019.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Brades',
        },
        landmarksRound: [
          { code: 'GU', number: 2 },
          { code: 'KW', number: 2 },
          { code: 'GF', number: 4 },
        ],
        landmarkPlaces: [
          "St. Peter's Church",
          'Liberian National Museum',
          'Emerald Pool',
          'Sanctuaire de Notre-Dame des Larmes',
          'Katse Dam',
          'Fort Ceperou',
          "Lac 'Assal",
        ],
        uniqueId: '8q8GPbfN',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Montserrat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-on-the-side-of-a-road-in-front-of-a-mountain-c0bqpJHXlJI',
          wikiLink: 'https://en.wikipedia.org/wiki/Montserrat',
        },
        landmark: {
          name: 'Montserrat Volcano Observatory',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Montserrat_Volcano_Observatory#/media/File:Montserrat_Volcano_Observatory.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Montserrat_Volcano_Observatory',
          hasLandmark: true,
        },
        city: {
          name: 'Brades',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/53062469324/in/photolist-2oQX2sL-F9qVw4-rv2wr-qArpbW-CHHnrY-2oREeYh-57taLn-rv2vS-rv3BB-VmNK-7CtaEF-rv3zN-4YiC5z-rv3xz-9h6ryk-jtnnX4-jtoMtP-jtqjue-jtqZnC-jtnjXX-jtoT2e-jtnVF4-9hSr5-8yZnTK-jtnnJi',
          wikiLink: 'https://en.wikipedia.org/wiki/Brades',
        },
        landmarksRound: [
          { code: 'HT', number: 1 },
          { code: 'KY', number: 1 },
          { code: 'IN', number: 1 },
        ],
        landmarkPlaces: [
          "Martyr's Memorial",
          'Erta Ale',
          'Stone House',
          'Umayyad City Ruins',
          'Church of Saints Simon and Helena',
          'Viru Gate',
          'Cheshire Hall Plantation',
        ],
        uniqueId: 't7WCzhDs',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Plymouth',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/53069231925/in/photolist-Z6RTMC-2oRxFKk-2bFYi-Z6RU2W-2oRGS8C-9Rp2YU-2bFYr-Gf4LqH-FCwSqG-2bFYm-2eEnR-2eEo9-4pNzko-4pNzhh-2eEnL-4pJwCZ-Z6RUDh-9xL2Co-eVwjeQ-5U43im-9gWdiY-Z6RTrh-2bFYv-2bFYB-e48bp-2oRhMzH-9j7eVz-67onAK-2oRASVd-2n9umoG-2oQX2sL-s4R1y7-KLT7dN-2oRtP6R-2oRgJ5k-67ooaa-9j6AuT-9j5PyF-9j6ADR-9j9Jdo-9j5NWr-9sde8D-Pmvyvy-bTd54X-5GyaDi-5GyaCa-e8Epia-5GCszG',
          wikiLink: 'https://en.wikipedia.org/wiki/Plymouth,_Montserrat',
        },
        landmark: {
          name: 'Soufrière Hills',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-yellow-cable-car-going-up-a-mountain-side-_UFIVvezTUI',
          wikiLink: 'https://en.wikipedia.org/wiki/Soufri%C3%A8re_Hills',
          hasLandmark: true,
        },
        city: {
          name: 'Davy Hill',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/rosco-photo/31024948218/in/photolist-BNvdGy-4CVJai-CCHHei-CHHnrY-CCHFSR-CCHHfv-2bk3r2d-MDAXFB-PgyYdj-2aiRhG5-2bk3rp7-MDAZmk-2a1SnWK-Ccw4sB',
          wikiLink: 'https://en.wikipedia.org/wiki/Davy_Hill',
        },
        landmarksRound: [
          { code: 'BF', number: 5 },
          { code: 'JM', number: 6 },
          { code: 'GW', number: 3 },
        ],
        landmarkPlaces: [
          'Obelisk of Axum',
          'Patuxay Monument',
          'Topkapı Palace',
          'Burj Khalifa',
          'Rarotonga',
          'Castle Cornet',
          'Mount Kilimanjaro',
        ],
        uniqueId: 'PSpCX3GJ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Morocco',
    code: 'MA',
    capital: 'Rabat',
    lat: 32,
    long: -5,
    cities: [
      'Marrakesh',
      'Rabat',
      'Fes',
      'Casablanca',
      'Tangier',
      'Meknes',
      'Fez',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NncAbldgViA',
          wikiLink: 'https://en.wikipedia.org/wiki/Chefchaouen',
        },
        landmark: {
          name: 'Ouzoud Falls',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/ouzoud-morocco-falls-waterfall-298820/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ouzoud_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Marrakesh',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/morocco-marrakesh-city-centre-5989338/',
          wikiLink: 'https://en.wikipedia.org/wiki/Marrakesh',
        },
        landmarksRound: [
          { code: 'AR', number: 2 },
          { code: 'MU', number: 1 },
          { code: 'LS', number: 1 },
        ],
        landmarkPlaces: [
          'Frogner Park',
          'Castle Cornet',
          'Ampitheater of El Jem',
          'Zaisan Memorial',
          'Blue Grotto',
          'Arecibo Observatory',
          'Narikala Fortress',
        ],
        uniqueId: 'vXVp64Kb',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/morocco-city-historic-village-clay-2349647/',
          wikiLink: 'https://en.wikipedia.org/wiki/Morocco',
        },
        landmark: {
          name: 'Koutoubia',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/morocco-marrakech-koutoubia-minaret-1361243/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kutubiyya_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Rabat',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/morocco-rabat-architecture-city-6203712/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rabat',
        },
        landmarksRound: [
          { code: 'ME', number: 2 },
          { code: 'BG', number: 1 },
          { code: 'MO', number: 2 },
        ],
        landmarkPlaces: [
          'Sistine Chapel',
          'The Blue Eye',
          'Castillo San Cristobal',
          'Maiden Tower',
          'Tomb of Hafez',
          "Saint Michael's Cave",
          'Umayyad Mosque',
        ],
        uniqueId: 'xdyF4nTk',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Morocco',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-with-two-kids-riding-on-a-motorcycle-yPbHMWJTTOU',
          wikiLink: 'https://en.wikipedia.org/wiki/Morocco',
        },
        landmark: {
          name: 'Hassan II Mosque',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-white-building-with-a-green-roof-next-to-the-ocean-ymEGRkA25zQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Hassan_II_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Fez',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-house-during-daytime-644X6zmy1Hk',
          wikiLink: 'https://en.wikipedia.org/wiki/Fez,_Morocco',
        },
        landmarksRound: [
          { code: 'BZ', number: 4 },
          { code: 'IR', number: 5 },
          { code: 'LY', number: 1 },
        ],
        landmarkPlaces: [
          'Xunatunich Mayan Ruins',
          'Linnahall',
          'Brimstone Hill Fortress',
          'Soufriere Hills',
          'Basílica de Suyapa',
          'Roman ruins in Batna',
          'Dolly Beach',
        ],
        uniqueId: 'rw76mLzk',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Chefchaouen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-blue-alley-with-potted-plants-and-a-bench-CMmgfHQiYsc',
          wikiLink: 'https://en.wikipedia.org/wiki/Chefchaouen',
        },
        landmark: {
          name: 'Bahia Palace',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/concrete-street-between-trees-leading-to-a-building-F7vahPayTKE',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahia_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Marrakesh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/three-women-in-front-of-fruit-carriage-stand-RCmr0Pe9Uxg',
          wikiLink: 'https://en.wikipedia.org/wiki/Marrakesh',
        },
        landmarksRound: [
          { code: 'ET', number: 2 },
          { code: 'BE', number: 5 },
          { code: 'SZ', number: 3 },
        ],
        landmarkPlaces: [
          'High Knoll Fort',
          'Umayyad Mosque',
          'Jerash',
          'Tsavo East National Park',
          'Ħal Saflieni Hypogeum',
          'Doonagore Castle, Doolin, Ireland',
          'Fiji Museum',
        ],
        uniqueId: 'bRfXSQBD',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    capital: 'Maputo',
    lat: -18.25,
    long: 35,
    cities: [
      'Beira',
      'Quelimane',
      'Pemba',
      'Chimoio',
      'Lichinga',
      'Maputo',
      'Nampula',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/LbhNj3qARcM',
          wikiLink: 'https://en.wikipedia.org/wiki/Maputo',
        },
        landmark: {
          name: 'Island of Mozambique',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Island_of_Mozambique#/media/File:Ilha_de_Mocambique.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Island_of_Mozambique',
          hasLandmark: true,
        },
        city: {
          name: 'Maputo',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mozambique-africa-maputo-bridge-3974424/',
          wikiLink: 'https://en.wikipedia.org/wiki/Maputo',
        },
        landmarksRound: [
          { code: 'FI', number: 1 },
          { code: 'TR', number: 2 },
          { code: 'NZ', number: 1 },
        ],
        landmarkPlaces: [
          'Stone House',
          'Heydar Aliyev Center',
          'Amalienborg',
          'Liwonde National Park',
          'Tjibaou Cultural Center',
          'Pico Basile',
          'Arc de Triomphe',
        ],
        uniqueId: 'QxXbbvwq',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/african-women-walking-along-road-2983081/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mozambique',
        },
        landmark: {
          name: 'Fort Sao Sebastiao',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Forte_de_S%C3%A3o_Sebasti%C3%A3o_-_Igreja.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fort_S%C3%A3o_Sebasti%C3%A3o',
          hasLandmark: true,
        },
        city: {
          name: 'Beira',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Beira,_Mozambique#/media/File:Beira_center_2000.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Beira,_Mozambique',
        },
        landmarksRound: [
          { code: 'HK', number: 2 },
          { code: 'BZ', number: 2 },
          { code: 'RS', number: 1 },
        ],
        landmarkPlaces: [
          'Kim Il Sung Square',
          'Sao Filipe Royale Fortress',
          'Vilnius Cathedral',
          'Borobudur Temple',
          'Pigeon Rocks',
          'Atomium',
          '',
        ],
        uniqueId: 'n5qZtvn7',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Mozambique',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/sailboat-on-sea-during-daytime-gO3uzl86USU',
          wikiLink: 'https://en.wikipedia.org/wiki/Mozambique',
        },
        landmark: {
          name: 'Independence Square',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-white-building-with-cars-parked-in-front-of-it-hazGzH2uUpA',
          wikiLink:
            'https://en.wikipedia.org/wiki/Pra%C3%A7a_da_Independ%C3%AAncia',
          hasLandmark: true,
        },
        city: {
          name: 'Beira',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Beira,_Mozambique_%2813-07-2012%29_152.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Beira,_Mozambique',
        },
        landmarksRound: [
          { code: 'BB', number: 4 },
          { code: 'LB', number: 2 },
          { code: 'IE', number: 2 },
        ],
        landmarkPlaces: [
          "Christian's Cave",
          'Arahoho Blowhole',
          'Church of Saint Lazarus',
          'Dolly Beach',
          "Dunn's River Falls",
          'Tokyo Skytree',
          "Martyr's Memorial",
        ],
        uniqueId: 'KG9gpjPj',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Maputo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-white-building-sitting-on-the-side-of-a-road-Ak9PXE6QCPo',
          wikiLink: 'https://en.wikipedia.org/wiki/Maputo',
        },
        landmark: {
          name: 'Maputo City Hall',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/potenzh/2068848621/in/photolist-49PoBP-83kJk2-aDstQy-4dvcwN-4drdwM-QEhufB-eBBpvt-23bB8Gp-cAkk71-28q7ouo-28q7o7j-BX4hHU-2QCnrt-22773Ko-2272niq-Jr8k5j-brDSXA-a4JVpu-bmq7WH-daZZwL-cYsuEo-7mAqVJ-k4mHvF-qdd9Sx-adbVyf-2pjQZHu-2pjRUwH-w5CSTi-5oVwAJ-2pjRUwx-auuMfH-brDNEQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Maputo_City_Hall',
          hasLandmark: true,
        },
        city: {
          name: 'Nampula',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/mozambiqueportugalblogcom/5230734793/in/photolist-8YdTdD-6ZzGYv-6ZA7JZ-6qrRFn-6ZzDnH-6qvZWf-6ZEe67-6qrRHi-6ZAdqi-6ZEf23-6ZAewp-6ZEemY-6qrRSk-56JU2E-6ZEeSG-6ZActv-6ZEfwW-6ZAdST-6ZE9Qu-6ZEdV3-6ZEfcw-6ZA9AD-6ZAdGX-6ZA98Z-6ZE96w-6ZzD7R-6ZAcjB-6ZE8Vh-6ZEcsU-6ZAcH8-6ZAaFv-6ZE7Zo-6ZAb2v-6ZDB2U-6ZDsKd-6ZEaSq-6ZAbXM-6ZEcYC-6ZEbju-6ZA8P4-6ZEbGJ-6ZEaAC-6ZEb9m-6ZEcDA-6ZA7xX-6ZE8bd-6ZE9gf-6ZA6Ak-6ZAbrn-6ZEc55',
          wikiLink: 'https://en.wikipedia.org/wiki/Nampula',
        },
        landmarksRound: [
          { code: 'TR', number: 1 },
          { code: 'FO', number: 1 },
          { code: 'MO', number: 2 },
        ],
        landmarkPlaces: [
          'Chichen Itza, Mexico',
          'Victoria Falls',
          'Wawel Castle',
          'Madriu-Perafita-Claror Valley',
          'Gorkhi-Terelj National Park',
          'Bagne des Annamites',
          'Xunantunich Mayan Ruins',
        ],
        uniqueId: '7GDTRYtq',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Myanmar',
    code: 'MM',
    capital: 'Naypyidaw',
    lat: 22,
    long: 98,
    cities: ['Naypyidaw', 'Yangon', 'Mandalay', 'Bago', 'Myeik', 'Sagaing'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/a_-hYhh-R3Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Bagan',
        },
        landmark: {
          name: 'Shwedagon Pagoda',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/golden-temple-temple-pagoda-259800/',
          wikiLink: 'https://en.wikipedia.org/wiki/Shwedagon_Pagoda',
          hasLandmark: true,
        },
        city: {
          name: 'Yangon',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/yangon-shwedagon-sulepagoda-myanmar-5050076/',
          wikiLink: 'https://en.wikipedia.org/wiki/Yangon',
        },
        landmarksRound: [
          { code: 'GD', number: 1 },
          { code: 'ME', number: 1 },
          { code: 'EG', number: 2 },
        ],
        landmarkPlaces: [
          'Vardzia',
          'Saint Sava Temple',
          'Sydney Opera House',
          'Burj Khalifa',
          'Matobo National Park',
          'English Harbour',
          'Koutoubia',
        ],
        uniqueId: 'nFSGQNCR',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kuthodaw-pagoda-temple-city-pagoda-5632067/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kuthodaw_Pagoda',
        },
        landmark: {
          name: 'Sule Pagoda',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sule_Pagoda#/media/File:Sule_Pagoda_Yangoon.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sule_Pagoda',
          hasLandmark: true,
        },
        city: {
          name: 'Mandalay',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Mandalay_--_Downtown.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Mandalay',
        },
        landmarksRound: [
          { code: 'KP', number: 1 },
          { code: 'BD', number: 2 },
          { code: 'JM', number: 1 },
        ],
        landmarkPlaces: [
          'Mount Yasur',
          'Somapura Mahavihara',
          "St. Peter's Church",
          'Oriental Pearl TV Tower',
          'Patuxay Monument',
          'Narikala Fortress',
          'Christ Church Cathedral',
        ],
        uniqueId: '4QHQ3lgv',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Myanmar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-with-trees-and-buildings-in-the-background-6yOFqJM7zIs',
          wikiLink: 'https://en.wikipedia.org/wiki/Myanmar',
        },
        landmark: {
          name: 'Shwedagon Pagoda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/low-angle-photo-of-gold-temple-uEPIw_ItJDQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Shwedagon_Pagoda',
          hasLandmark: true,
        },
        city: {
          name: 'Yangon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gold-and-white-concrete-building-UFsb3AZ26N0',
          wikiLink: 'https://en.wikipedia.org/wiki/Yangon',
        },
        landmarksRound: [
          { code: 'KY', number: 2 },
          { code: 'BW', number: 3 },
          { code: 'CM', number: 3 },
        ],
        landmarkPlaces: [
          'Sibebe Rock',
          'La Corbière',
          'Castle Cornet',
          'Erbil Citadel',
          'Mount Everest',
          'Iguazu Falls',
          'Christmas Island National Park',
        ],
        uniqueId: 'gW2YdgPF',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Naypyidaw',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/64193047@N08/12990354203/in/photolist-iigYRa-kMUXJc-2mgMA4R-229xNYi-226QK1U-E1GyMs-HcX2i6-214hP3N-HcX2JX-226QgRd-HcX3uz-E1GxQs-2grNk77-Cv2Nf8-214hN2j-226QiiS-HcX3BP-Cv2xnc-Cv2NRD-229xCUv-229xBHx-226Qhwm-226QDMC-226QGY7-226QGm5-226QC6b-E1Gycu-226QygN-214hW7s-226QAbu-229xKkX-HcX4m4-226QwLJ-E1GzxL-HcX1YZ-229xM6a-226Qxho-226QrKm-226QEw3-214hVcG-214hLc7-229xBbk-ZNeqfv-229xDxp-2ohF3ai-PeiGpe-EDhsWB-P6vXje-E1H16y-226QHTJ',
          wikiLink: 'https://en.wikipedia.org/wiki/Naypyidaw',
        },
        landmark: {
          name: 'Dhammayangyi Temple',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-small-car-parked-in-front-of-a-large-building-wI-etVtLx98',
          wikiLink: 'https://en.wikipedia.org/wiki/Dhammayangyi_Temple',
          hasLandmark: true,
        },
        city: {
          name: 'Mandalay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/view-of-ice-mountain-at-myanmar-Z8WZJHXOM5Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Mandalay',
        },
        landmarksRound: [
          { code: 'US', number: 1 },
          { code: 'RO', number: 1 },
          { code: 'MA', number: 2 },
        ],
        landmarkPlaces: [
          'Torre de la Libertad',
          'North Keeling',
          'Grand Turk Lighthouse',
          'Prague astronomical clock',
          'National Museum of Bermuda',
          'Fort Nuestra Senora de la Soledad',
          'Great Pyramid of Giza',
        ],
        uniqueId: 'lZ42qX3F',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Namibia',
    code: 'NA',
    capital: 'Windhoek',
    lat: -22,
    long: 17,
    cities: [
      'Rundu',
      'Windhoek',
      'Walvis Bay',
      'Swakopmund',
      'Gobabis',
      'Keetmanshoop',
      'Lüderitz',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/pWshkmAH4qA',
          wikiLink: 'https://en.wikipedia.org/wiki/Sandwich_Harbour',
        },
        landmark: {
          name: 'Deadvlei',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/namibia-deadvlei-desert-dessert-4790487/',
          wikiLink: 'https://en.wikipedia.org/wiki/Deadvlei',
          hasLandmark: true,
        },
        city: {
          name: 'Rundu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Rundu#/media/File:Rundu_(2019).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Rundu',
        },
        landmarksRound: [
          { code: 'NO', number: 1 },
          { code: 'MK', number: 1 },
          { code: 'BD', number: 2 },
        ],
        landmarkPlaces: [
          'Bahrain Fort',
          'Trevi Fountain',
          'Aleppo Citadel',
          'Pyramiden',
          'South Pole (ceremonial)',
          'Palace of Versailles',
          'Tavarua',
        ],
        uniqueId: 'FKsvHFX8',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sossusvlei-namib-namibia-sand-dune-5339728/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sossusvlei',
        },
        landmark: {
          name: 'Epupa Falls',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Epupa_Falls_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Epupa_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Windhoek',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/church-namibia-windhoek-3767555/',
          wikiLink: 'https://en.wikipedia.org/wiki/Windhoek',
        },
        landmarksRound: [
          { code: 'NO', number: 2 },
          { code: 'NP', number: 1 },
          { code: 'PL', number: 2 },
        ],
        landmarkPlaces: [
          'Castillo San Cristobal',
          'Pulhapanzak Waterfalls',
          'Bled Castle',
          'Tegucigalpa Cathedral',
          'Christ of Havana',
          'Hofburg Palace',
          'Rhine Falls',
        ],
        uniqueId: 'SbFsg75Y',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Namibia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-bench-near-window-mi9rbLeTj1A',
          wikiLink: 'https://en.wikipedia.org/wiki/Namibia',
        },
        landmark: {
          name: 'Sossusvlei',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-sand-under-blue-sky-during-daytime-GvcHBUlisw0',
          wikiLink: 'https://en.wikipedia.org/wiki/Sossusvlei',
          hasLandmark: true,
        },
        city: {
          name: 'Walvis Bay',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/worldbank/5321477994',
          wikiLink: 'https://en.wikipedia.org/wiki/Walvis_Bay',
        },
        landmarksRound: [
          { code: 'BE', number: 4 },
          { code: 'HR', number: 4 },
          { code: 'GL', number: 5 },
        ],
        landmarkPlaces: [
          'Ouagadougou Cathedral',
          'Spittal Pond',
          'Mont Orgueil Castle',
          'Hungarian Parliament Building',
          'Independence Monument',
          'Pipeline Mosque',
          'Dancing House',
        ],
        uniqueId: 'vxdPx4K6',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Windhoek',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-man-riding-a-bike-down-a-street-next-to-a-wall-HqM70UrfhiQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Windhoek',
        },
        landmark: {
          name: 'Etosha National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-elephants-walk-ED5afJsV5Vo',
          wikiLink: 'https://en.wikipedia.org/wiki/Etosha_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Lüderitz',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/stoecklinm/50727495961/in/photolist-2khBFek-bqwkTy-bDrgWp-274Txwk-2ov2SJM-2fWtbsa-2kZ1JnZ-9p4owN-Tq26AU-2nixJMw-2kZ2R9E-2oqbYag-DZzTF4-9p4qfo-2ov7RZo-Co8dNC-Bt3Gz4-Ci99F2-Ci9cGc-Bt3e16-6AE9Sq-Ci8YM4-8zgLCi-Bt3urg-7uFKHi-2kXJP3S-2pXePsj-2fRRndA-9p4mJd-2kXBmC4-9p1iDp-2kXLpyK-7uFKXe-2kXFXNs-7uFL3r-2eYs1P1-Behbvq-8FSdvE-9p4khY-7kq4sX-7kpYen-7ktZb9-Nu71Vq-7ku4LA-pWjMXx-7kpXw8-7ku3Qf-5QKVjf-QdBaKP-aqwEdU',
          wikiLink: 'https://en.wikipedia.org/wiki/L%C3%BCderitz',
        },
        landmarksRound: [
          { code: 'DM', number: 3 },
          { code: 'AR', number: 3 },
          { code: 'ST', number: 2 },
        ],
        landmarkPlaces: [
          'Rotunda of Mosta',
          'Palacio Salvo',
          'Soufrière Hills volcano',
          'Tenaru Falls',
          'Gateway Arch',
          'Port-aux-Français',
          'East Side Gallery',
        ],
        uniqueId: 'YClS8n3b',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Nauru',
    code: 'NR',
    capital: 'Yaren',
    lat: -0.53333333,
    long: 166.9166667,
    cities: ['Yaren', 'Menen'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/bjJU61GHALI',
          wikiLink: 'https://en.wikipedia.org/wiki/Nauru',
        },
        landmark: {
          name: 'Buada Lagoon',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Buada_Lagoon#/media/File:Buada_Lagoon,_Nauru_2007.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Buada_Lagoon',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'pdwJfCnR',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Living_on_a_Blue_Planet_-_Nauru.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nauru',
        },
        landmark: {
          name: 'Anibare Bay',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Anibare_Bay#/media/File:Living_on_a_Blue_Planet_-_Nauru.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Anibare_Bay',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '9CmHDnrz',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Nauru',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/sea-water-and-rocks-by-the-beach-coast-during-daylight-bjJU61GHALI',
          wikiLink: 'https://en.wikipedia.org/wiki/Nauru',
        },
        landmark: {
          name: 'Anibare Bay',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/90271314@N07/8202819762/in/photolist-2gsT1un-duRAwW-bzLRKK-2jchrGx-zNZzLC-AHJufw-AJQmXy-A848Yo-Cueyc-7b2YEP-7b2Z5Z-7b2Z7a-7b2YHT-7b6Nnd-7b2Z1t-7b2YZ2-7b6N7U-7b6N9Q-7b2YGF-7b6NaS-7b2Z9c-7b2YRk-2iRLL5T-7b2Z2F-7b2YFF-7b2Z8g-7b6NbQ-7b2Z4K',
          wikiLink: 'https://en.wikipedia.org/wiki/Anibare_Bay',
          hasLandmark: true,
        },
        city: {
          name: 'Yaren',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/119791932@N04/29492508862/in/photolist-LW9Po7-28DMgcw-LW9PrJ-LEd67E-M6RRBT-LW9PuE-M6RR5v-2h53zZq-LYJQ1Z-L9LgbB-M6RRXx-LEd8bj-fa9vAE-LYJMaP-2mXcoYM-LYJPPr-L9Lhxp-Hh8Zh1-M6RRhV-b8h9vM-LYJMyz-6JpV6Y-LYJQCk-M3QHH7-LW9P99-25GGpj3-aXGVg8-M3QJfQ-aXGRxH-9vHiAt-aXGUEV-aXGUS6-bncHwS-2kYQ9dC-baqVwM-a1fkFy-aXGSkv-bncyW7-aXGSDr-aXGT5P-bncujS-aXGTjz-L9LfZK-aXGTDk-aXGUm6-2pPGThc-XXiiZ3-aXGRLr-aXGU6T-aXGS1F',
          wikiLink: 'https://en.wikipedia.org/wiki/Yaren_District',
        },
        landmarksRound: [
          { code: 'UZ', number: 3 },
          { code: 'SV', number: 6 },
          { code: 'PK', number: 2 },
        ],
        landmarkPlaces: [
          'Casa Loma',
          'Crystal Caves',
          'Mount Cameroon',
          'Majete Wildlife Reserve',
          'Muzeu Historik Kombëtar',
          'Wieliczka Salt Mine',
          'St. Barnabas Chapel',
        ],
        uniqueId: 'DH9zQkpf',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Yaren',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/indynelson/50437637018/in/photolist-2jR5vrx-2jR15gm-2bqz6yy-MJUMkR-2pkBHwR',
          wikiLink: 'https://en.wikipedia.org/wiki/Yaren_District',
        },
        landmark: {
          name: 'Buada Lagoon',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/46226987@N00/412905121/in/photolist-CufiP-qUcNfS-7Jj9hG-CueBD',
          wikiLink: 'https://en.wikipedia.org/wiki/Buada_Lagoon',
          hasLandmark: true,
        },
        city: {
          name: 'Menen',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/indynelson/50437643633/in/photolist-2jR4BuW-2jR17ep-2jR4BAT-2jR16JS-2jR5xiJ-2jR5xsg-2jR17gD-2jR174V-2jR4BCG-2jR5xxr-2jR17i2-2jR157i-niHUyF-Cueyc-2gtcQEd-2gtcQGh-2gtd7fs-XswNrd-eMswMQ-8p38Be-fNeaBB-351ijs-jZZ7zC-2gxbEec-2gtd7ek-staVbJ-2jR5y1R-2jR168b-2jR5vmh-2jR15x3-2jR5wcq-2jR16sV-2jR4CnH-2jR4CQ1-2jR15JW-2jR4CTN-2jR4Cpm-2jR4C6f-DjFWAY-95MjNf-5jXrRC-cq77um-cjZ779-8Mo7BQ-2hJyDC4-2hJxxGC-2hJxxxQ-2hJuWXi-2hJyDsz',
          wikiLink: 'https://en.wikipedia.org/wiki/Meneng_District',
        },
        landmarksRound: [
          { code: 'DO', number: 4 },
          { code: 'FJ', number: 3 },
          { code: 'CM', number: 1 },
        ],
        landmarkPlaces: [
          'Malolotja National Park',
          'A-Ma Temple',
          'Jardin Botanique de Deshaies',
          'Plaza Francia',
          'Montserrat Volcano Observatory',
          'Chapel of the Snows',
          'Cathedral Our Lady of the Rosary',
        ],
        uniqueId: 'sRhlySnG',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Nepal',
    code: 'NP',
    capital: 'Kathmandu',
    lat: 28,
    long: 84,
    cities: [
      'Kathmandu',
      'Pokhara',
      'Bharatpur',
      'Biratnagar',
      'Janakpur',
      'Itahari',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/2bxAoXcfwwM',
          wikiLink: 'https://en.wikipedia.org/wiki/Pokhara',
        },
        landmark: {
          name: 'Boudhanath',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/buddha-nepal-hindu-buddhism-2522466/',
          wikiLink: 'https://en.wikipedia.org/wiki/Boudhanath',
          hasLandmark: true,
        },
        city: {
          name: 'Kathmandu',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/pashupatinath-temple-ancient-nepal-2470062/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kathmandu',
        },
        landmarksRound: [
          { code: 'EG', number: 1 },
          { code: 'YE', number: 1 },
          { code: 'KY', number: 2 },
        ],
        landmarkPlaces: [
          'Devin Castle',
          'Grand Ducal Palace',
          'Hagia Sophia',
          'Wawel Castle',
          'Monte Carlo Casino',
          'Voortrekker Monument',
          "Diocletian's Palace",
        ],
        uniqueId: 'SJC5nkqy',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mount-everest-mountains-trekking-6395759/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nepal',
        },
        landmark: {
          name: 'Kathmandu Durbar Square',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/temple-palace-durbar-square-street-5790082/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kathmandu_Durbar_Square',
          hasLandmark: true,
        },
        city: {
          name: 'Pokhara',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fishtail-mountain-pokhara-city-5009522/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pokhara',
        },
        landmarksRound: [
          { code: 'AM', number: 1 },
          { code: 'NC', number: 2 },
          { code: 'ET', number: 1 },
        ],
        landmarkPlaces: [
          'Sky Tower',
          'Mount Yasur',
          'Panfilov Park',
          'Monastery of St. Naum',
          'Cathedral Our Lady of the Rosary',
          'Vianden Castle',
          'Mount Kenya',
        ],
        uniqueId: 'wN4P69pY',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Nepal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-car-on-road-near-green-mountains-during-daytime-_5-GEPZoza4',
          wikiLink: 'https://en.wikipedia.org/wiki/Nepal',
        },
        landmark: {
          name: 'Boudhanath',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-KKm1ua7MSf0',
          wikiLink: 'https://en.wikipedia.org/wiki/Boudhanath',
          hasLandmark: true,
        },
        city: {
          name: 'Kathmandu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-daytime-59Al83Zjtf8',
          wikiLink: 'https://en.wikipedia.org/wiki/Kathmandu',
        },
        landmarksRound: [
          { code: 'KG', number: 1 },
          { code: 'TF', number: 3 },
          { code: 'TN', number: 2 },
        ],
        landmarkPlaces: [
          'Tsingoni Mosque',
          'Gracanica Monastery',
          'Uganda National Mosque',
          'Sibebe Rock',
          'The Blue Mosque',
          'Bled Castle',
          'Baiterek',
        ],
        uniqueId: 'JXDyPfpY',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Kàtmandu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-mountains-under-blue-sky-during-daytime-8__IZFB9AD8',
          wikiLink: 'https://en.wikipedia.org/wiki/Kathmandu',
        },
        landmark: {
          name: 'Mount Everest',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-mountain-range-from-an-airplane-XEW_Wd4240c',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Everest',
          hasLandmark: true,
        },
        city: {
          name: 'Pokhara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-during-daytime-hV5oC-_0TqE',
          wikiLink: 'https://en.wikipedia.org/wiki/Pokhara',
        },
        landmarksRound: [
          { code: 'JO', number: 2 },
          { code: 'JP', number: 2 },
          { code: 'KZ', number: 2 },
        ],
        landmarkPlaces: [
          'Macau Tower',
          'Fouta Djallon',
          "St. Peter's Basilica",
          'Tarawa',
          'Shoes on the Danube Bank',
          'Cathedral Our Lady of the Rosary',
          'Emerald Pool',
        ],
        uniqueId: '2MvTmrl4',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Nepal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/assorted-color-of-apparel-hanged-below-creek-across-glacier-mountain-dstd4DoLQ90',
          wikiLink: 'https://en.wikipedia.org/wiki/Nepal',
        },
        landmark: {
          name: 'Pashupatinath Temple',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tall-building-with-a-clock-on-the-top-of-it-7JRiHJc_8K8',
          wikiLink: 'https://en.wikipedia.org/wiki/Pashupatinath_Temple',
          hasLandmark: true,
        },
        city: {
          name: 'Pokhara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/grayscale-photo-of-angel-statue-zzQ69HkheTU',
          wikiLink: 'https://en.wikipedia.org/wiki/Pokhara',
        },
        landmarksRound: [
          { code: 'EG', number: 5 },
          { code: 'NG', number: 1 },
          { code: 'FM', number: 4 },
        ],
        landmarkPlaces: [
          'Jesuit Mission of the Holy Trinity',
          'Sky Tower',
          'Gidan Rumfa',
          "Martyrs' Square",
          'Basilica of Our Lady of Peace',
          'St. Alexander Nevsky Cathedral',
          'Vatican Necropolis',
        ],
        uniqueId: 'thvPnZbq',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Kathmandu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/woman-waking-away-front-the-hanging-decor-Y3u0xBXhGtY',
          wikiLink: 'https://en.wikipedia.org/wiki/Kathmandu',
        },
        landmark: {
          name: 'Boudhanath',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-very-tall-building-with-a-lot-of-flags-on-top-of-it-Pqk8WhRrEIs',
          wikiLink: 'https://en.wikipedia.org/wiki/Boudhanath',
          hasLandmark: true,
        },
        city: {
          name: 'Biratnagar',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/old_lilith/38634069660/in/photolist-21RXGVj-2ixyLZ1-QgBWby-26LXrSZ-23Yu9TT-2op6LGr-D5JiG-JwxRFa-2ma2uqa-o57yLw-SgWM83-S5Nae6-2hhpjTU-rmnRMq-cFxUTW-cFxUWm-2b4ohVP-29F2K1k-MimpRc-cFxUYs-3EfueK-EyGjq2-KzUYJq-UBCgx5-Vb1nuq-qQsdpX-egnDVM-TZV4WK-buuCHr-nkxS5y-2pXQzfE-3EjkvN-6M479N-8hXsk1-48BZdM-3EjPCy-dTmEus-2n6WSgR-dTmKfo-48BZdH-dTmG6N-dTmr6Q-JC8jc-qQs9f4-qQu5fz-3Efoqz-6Bkvw8-3c2KFQ-2no6RAz-2q2oaFH',
          wikiLink: 'https://en.wikipedia.org/wiki/Biratnagar',
        },
        landmarksRound: [
          { code: 'MM', number: 4 },
          { code: 'YE', number: 2 },
          { code: 'MZ', number: 4 },
        ],
        landmarkPlaces: [
          'Cap 110',
          'Stone House',
          'Seven Colored Earths',
          'Tsingy De Bemaraha',
          'Angkor Wat',
          'Plaza Murillo',
          'Senado Square',
        ],
        uniqueId: 'XkXZl67k',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Netherlands',
    code: 'NL',
    capital: 'Amsterdam',
    lat: 52.5,
    long: 5.75,
    cities: [
      'Utrecht',
      'Amsterdam',
      'The Hague',
      'Eindhoven',
      'Breda',
      'Rotterdam',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/MaNZajl-IFY',
          wikiLink: 'https://en.wikipedia.org/wiki/Deventer',
        },
        landmark: {
          name: 'Rijksmuseum',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rijksmuseum-amsterdam-museum-2127625/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rijksmuseum',
          hasLandmark: true,
        },
        city: {
          name: 'Rotterdam',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rotterdam-maastoren-erasmus-bridge-4152380/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rotterdam',
        },
        landmarksRound: [
          { code: 'PK', number: 1 },
          { code: 'JP', number: 1 },
          { code: 'MR', number: 1 },
        ],
        landmarkPlaces: [
          "St. Peter's Church",
          'Badshahi Mosque',
          'Bahrain Fort',
          'Palace of Peace and Reconciliation',
          'Patuxay Monument',
          'Dhakeshwari Temple',
          'Rila Monastery',
        ],
        uniqueId: 'p89Clw7b',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/canal-buildings-city-114290/',
          wikiLink: 'https://en.wikipedia.org/wiki/Netherlands',
        },
        landmark: {
          name: 'Anne Frank House',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Anne_Frank_House#/media/File:Amsterdam_(NL),_Anne-Frank-Huis_--_2015_--_7185.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Anne_Frank_House',
          hasLandmark: true,
        },
        city: {
          name: 'Utrecht',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/utrecht-holland-bell-tower-panorama-887134/',
          wikiLink: 'https://en.wikipedia.org/wiki/Utrecht',
        },
        landmarksRound: [
          { code: 'CW', number: 2 },
          { code: 'CA', number: 2 },
          { code: 'GE', number: 1 },
        ],
        landmarkPlaces: [
          'Villa Folio',
          'Tjibaou Cultural Center',
          'Grand Ducal Palace',
          'Kelonia',
          'Olavinlinna',
          'Monastery of St. Naum',
          'Tomb of Hafez',
        ],
        uniqueId: 'cTKSpdfj',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Amsterdam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bridge-over-a-body-of-water-with-buildings-in-the-background-b3l4P6PyCQg',
          wikiLink: 'https://en.wikipedia.org/wiki/Amsterdam',
        },
        landmark: {
          name: 'Erasmusbrug',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-walking-in-street-near-buildings-at-daytime-D_TfRsHGiRc',
          wikiLink: 'https://en.wikipedia.org/wiki/Erasmusbrug',
          hasLandmark: true,
        },
        city: {
          name: 'Amsterdam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-street-scene-with-a-train-on-the-tracks-wokE4YwxVnQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Amsterdam',
        },
        landmarksRound: [
          { code: 'AQ', number: 3 },
          { code: 'MX', number: 2 },
          { code: 'BM', number: 1 },
        ],
        landmarkPlaces: [
          'Virunga National Park',
          'Linnahall',
          'Deadvlei',
          'African Renaissance Monument',
          'Diego Garcia',
          'Ostrog Monastery',
          'Rotunda of Mosta',
        ],
        uniqueId: 'Gq9vxbX8',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Netherlands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lake-surrounded-by-trees-in-a-park-xGa5-1BABr0',
          wikiLink: 'https://en.wikipedia.org/wiki/Netherlands',
        },
        landmark: {
          name: 'Rijksmuseum',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-fountain-in-front-of-it-EFGjX6kJedw',
          wikiLink: 'https://en.wikipedia.org/wiki/Rijksmuseum',
          hasLandmark: true,
        },
        city: {
          name: 'Rotterdam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-people-walking-on-park-near-city-buildings-F7I6sexmIS8',
          wikiLink: 'https://en.wikipedia.org/wiki/Rotterdam',
        },
        landmarksRound: [
          { code: 'BZ', number: 1 },
          { code: 'MD', number: 4 },
          { code: 'AR', number: 4 },
        ],
        landmarkPlaces: [
          'Apia Town Clock Tower',
          'George Washington House',
          'Gibbs Hill Lighthouse',
          'Bayon',
          'Tiwanaku',
          'Altun Ha',
          'Kwame Nkrumah Mausoleum',
        ],
        uniqueId: 'cvrxDy8J',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    capital: 'Noumea',
    lat: -21.5,
    long: 165.5,
    cities: [
      'Noumea',
      'Dumbea',
      'Mont-Dore',
      'Paita',
      'Bourail',
      'Kone',
      'Dumbéa',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/KDABcgfxFEM',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Caledonia',
        },
        landmark: {
          name: 'Amedee Lighthouse',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Am%C3%A9d%C3%A9e_Lighthouse#/media/File:Noum%C3%A9a_Phare_Am%C3%A9d%C3%A9e.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Am%C3%A9d%C3%A9e_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Dumbea',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dumb%C3%A9a#/media/File:Monts-Koghis_-_Pic_Malaoui_(687_m)_-_Vue_sur_Noum%C3%A9a_(3225081920).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Jean-Marie_Tjibaou_Cultural_Centre',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'fNzR3G5m',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/new-caledonia-landscape-riverbed-101389/',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Caledonia',
        },
        landmark: {
          name: 'Tjibaou Cultural Center',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Jean-Marie_Tjibaou_Cultural_Centre#/media/File:Egrant-190-91.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Jean-Marie_Tjibaou_Cultural_Centre',
          hasLandmark: true,
        },
        city: {
          name: 'Noumea',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Noum%C3%A9a#/media/File:Noumea_centre_1402561139_375c811796_o.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Noum%C3%A9a',
        },
        landmarksRound: [
          { code: 'EG', number: 2 },
          { code: 'IT', number: 1 },
          { code: 'JM', number: 1 },
        ],
        landmarkPlaces: [
          'Pyramiden',
          'Maiden Tower',
          'Golden Gate Bridge',
          'George Washington House',
          'Vianden Castle',
          'Galle Fort',
          'Heydar Aliyev Center',
        ],
        uniqueId: 'VWlgv7Bc',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'New Caledonia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-with-a-building-in-the-background-g9TYsgqVZEI',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Caledonia',
        },
        landmark: {
          name: 'Amédée Lighthouse',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/anura/52450814458/in/photolist-jkPXXd-2nUU8Md-2awvQi-8Z3WK4-6Lp86a-8kYGb3-77G5fh-aj3v8b-5gyZkn-a6f5oJ-W4NYz-4hB9mG-6Mh48e-7okrEW-7wZkCG-W7TT1-6eUHFr-5gz1sa-52jpvH-kqac1-5xjbF6-5LXUmV-aiZJwx-pqzc9m-2nURsSX-2itw1f3-7ihWRw-YnmCd1-6uFe4R-91wBoH-2nURsVh-iUUBp-765o69-2nUSRf3-ZnMaUU-2nURt2E-77kPYx-7Cf5D4-9rFJAT-2nUNMLF-Zs6Cp2-2nURt5k-77pKoh-2nUNMGT-2nUU8SJ-2nUU8HA-9S6hzZ-B3kujV-B9HhLq-7ie8m8/',
          wikiLink: 'https://en.wikipedia.org/wiki/Am%C3%A9d%C3%A9e_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Dumbéa',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/31511560@N05/2953967964/in/photolist-5v2RL9-6FLbFu-BTx2Pj-q48qxG-qkvCit-poWegZ-EGqcR4-wuKPAS-DGtrn9-8srmq9-2n9Zr3u-2ipyh4n-2ou9v71-2nafj3b-V5Rv4h-2n9Z4yv-2ndMxNZ-2n9Z3Uz-2jbmrjM-2n9ZqQf-q47BAL-q4eQyX-poGzp3-pcCS7k-UK4rcy-VgLbXq-2nFPtdv-2nacaok-V5RuBW-2jCARwv-V8AUF8-2gKaqKP-UK4oXJ-2gH2HJf-VgLbao-2gKap2P-UK4oG3-NsAjb3-2nTvGUw-2jCA4YL-sbMeRZ-2nakG58-2nac9pm-V5RtsS-UK4pqY-VkjTM8-UK4sj3-caqChC-cbr95U-V5Rub5',
          wikiLink: 'https://en.wikipedia.org/wiki/Dumb%C3%A9a',
        },
        landmarksRound: [
          { code: 'HK', number: 3 },
          { code: 'CD', number: 5 },
          { code: 'MT', number: 6 },
        ],
        landmarkPlaces: [
          'Loango National Park',
          'Chronicle of Georgia',
          'Potala Palace',
          'Grand Mosque',
          'Iluissat Icefjord',
          'Wawel Castle',
          'Aloba Arch',
        ],
        uniqueId: 'cSYBbjLd',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Nouméa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-the-ocean-from-a-hill-EyaW2XMu7qM',
          wikiLink: 'https://en.wikipedia.org/wiki/Noum%C3%A9a',
        },
        landmark: {
          name: 'Jean-Marie Tjibaou Cultural Centre',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/geoftheref/28479861751/in/photolist-KoEJFa-aE7GtV-agErRD-YLR6DA-9RevWC-X5b51e-aE7H3X-beQLdg-beQMzc-beRdSB-bePc72-aEbxwo-TuTJ21-aEbxVb-XGAnCm-aE7G9p-aEbyvW-9RbBJX-9RevTo-Ypb4YR-dmRZeB-beRbh2-YiBnuP-X8k2Wj-2dAsbJG-Y84XQy-Y5ecBb-Y5dZNm-Y3Lv2W-XaLiHB-dmRJh7-XaLvQc-2p4ASb2-9TzJpL-XJjamC-X8jPr9-dmwbXu-UMn83e-UMn8ma-TcA1KD-9hvC5M-hCdzPS-BBAVXp-aEtCX7-25io1Rk-2fQnx5S-2ew2tZT-S9qMfv-at4yLT-Zv8FJw',
          wikiLink:
            'https://en.wikipedia.org/wiki/Jean-Marie_Tjibaou_Cultural_Centre',
          hasLandmark: true,
        },
        city: {
          name: 'Bourail',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-an-island-in-the-middle-of-the-ocean-ddC7ffYjJmQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Bourail',
        },
        landmarksRound: [
          { code: 'GH', number: 6 },
          { code: 'MS', number: 5 },
          { code: 'ER', number: 5 },
        ],
        landmarkPlaces: [
          'Leptis Magna',
          'Buddha Dordenma statue',
          'Nara Park',
          'Temple of Literature',
          'Mount Bromo',
          'Skansin',
          'Alexander Nevsky Cathedral',
        ],
        uniqueId: 'P3kC3m4g',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    capital: 'Wellington',
    lat: -41,
    long: 174,
    cities: [
      'Christchurch',
      'Wellington',
      'Auckland',
      'Tauranga',
      'Dunedin',
      'Hamilton',
      'Queenstown',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/73F4pKoUkM0',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Tekapo',
        },
        landmark: {
          name: 'Sky Tower',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-sky-clouds-buildings-1283687/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sky_Tower_(Auckland)',
          hasLandmark: true,
        },
        city: {
          name: 'Christchurch',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Christchurch#/media/File:Sumner._Christchurch_NZ_(13506509155).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Christchurch',
        },
        landmarksRound: [
          { code: 'IN', number: 1 },
          { code: 'BN', number: 1 },
          { code: 'TW', number: 2 },
        ],
        landmarkPlaces: [
          'Monteverde Cloud Forest',
          'Turku Castle',
          'Bratislava Castle',
          'Forbidden City',
          'Twelve Apostles',
          'Fasil Ghebbi',
          'Vardzia',
        ],
        uniqueId: 'DxmSWkKt',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/eagle-mountains-lake-reflection-1450672/',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Zealand',
        },
        landmark: {
          name: 'Huka Falls',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/new-zealand-taupo-huka-falls-4003565/',
          wikiLink: 'https://en.wikipedia.org/wiki/Huka_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Wellington',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/new-zealand-wellington-capital-city-4875972/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wellington',
        },
        landmarksRound: [
          { code: 'IR', number: 2 },
          { code: 'BH', number: 2 },
          { code: 'BS', number: 2 },
        ],
        landmarkPlaces: [
          'Stone House',
          'Church of Our Savior',
          'Animal Flower Cave',
          'Sydney Opera House',
          'Abu Simbel Temples',
          'Chillon Castle',
          'Silver Pagoda',
        ],
        uniqueId: 'fcQ7SNCG',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'New Zealand',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-house-between-flowers-v2lJbPVMqr8',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Zealand',
        },
        landmark: {
          name: 'Mount Taranaki',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/mountain-near-body-of-water-during-daytime-LZVmvKlchM0',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Taranaki',
          hasLandmark: true,
        },
        city: {
          name: 'Queenstown',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/buildings-near-body-of-water-and-mountains-under-clear-blue-sky-and-white-clouds-at-daytime-5BuxuWIJF1Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Queenstown,_New_Zealand',
        },
        landmarksRound: [
          { code: 'GU', number: 4 },
          { code: 'NO', number: 2 },
          { code: 'KY', number: 1 },
        ],
        landmarkPlaces: [
          'Silver Pagoda',
          'Petra',
          'Kunta Kinteh Island',
          'Independence Square',
          'Fort Charlotte',
          'Mutanabbi Statue',
          'Wieliczka Salt Mine',
        ],
        uniqueId: 'DLJSxHYg',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'New Zealand',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-green-mountains-during-daytime-zWWq8J6igOo',
          wikiLink: 'https://en.wikipedia.org/wiki/New_Zealand',
        },
        landmark: {
          name: 'Lake Tekapo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-lake-and-mountain-73F4pKoUkM0',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Tekapo',
          hasLandmark: true,
        },
        city: {
          name: 'Auckland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cityscape-photo-during-daytime-hIKVSVKH7No',
          wikiLink: 'https://en.wikipedia.org/wiki/Auckland',
        },
        landmarksRound: [
          { code: 'GI', number: 4 },
          { code: 'MU', number: 1 },
          { code: 'MT', number: 6 },
        ],
        landmarkPlaces: [
          'Atafu',
          'Buddha Dordenma statue',
          'Turaida Castle',
          'Borobudur Temple',
          'Gateway Arch',
          'Rijksmuseum',
          'Million Dollar Point',
        ],
        uniqueId: '2ZzbvdxN',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    capital: 'Managua',
    lat: 13,
    long: -85,
    cities: ['Masaya', 'León', 'Managua'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/7lXv17JzmpA',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Crist%C3%B3bal_Volcano',
        },
        landmark: {
          name: 'Cristo de La Misericordia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Christ_of_the_Mercy#/media/File:Christ_of_the_Mercy_statue,_San_Juan_del_Sur.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Christ_of_the_Mercy',
          hasLandmark: true,
        },
        city: {
          name: 'Leon',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Le%C3%B3n,_Nicaragua#/media/File:Iglesiadelarecoleccion.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Le%C3%B3n,_Nicaragua',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'rhGNM4qh',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/plantation-coffee-nicaragua-jungle-108987/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nicaragua',
        },
        landmark: {
          name: 'Leon Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Le%C3%B3n_Cathedral,_Nicaragua#/media/File:Nicaragua_2017-03-13_(33663091692).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Le%C3%B3n_Cathedral,_Nicaragua',
          hasLandmark: true,
        },
        city: {
          name: 'Managua',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/managua-panorama-lake-city-horizon-943433/',
          wikiLink: 'https://en.wikipedia.org/wiki/Managua',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '6NHnYFJP',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Nicaragua',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/pathway-of-houses-under-blue-clouds-during-daytime-Ye9MgFlD10E',
          wikiLink: 'https://en.wikipedia.org/wiki/Nicaragua',
        },
        landmark: {
          name: 'Catarina',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/tangoscar/50847446472/in/photolist-2ktdskA-2kt9itg-eHfm6-c5Q5qE-vpvFA-vp4R9-vpvFR-vp4R7-vpvFN-8ecYFF-8ecZuD-8ed1GB-8edam4-8egmeA-8egfg5-8ed5j8-8ed9DR-8ed542-8eghhS-8ed7a8-8egf6W-8ecZHg-8egf47-8egh47-8ecYJa-8egpUG-8egjBU-8egk4Q-8ed2Mz-8ed4Mr-8ed1ez-8egeBf-8eggSW-8egfSL-8eggzN-8egh77-8ecZYe-8ecZQr-8ecYZc-8ecZ3R-8eggDy-8eghaj-8egh15-8egfL7-8ed1dX-8egfZ7-8egfHA-8eggPb-8eggjm-8ecZTt',
          wikiLink: 'https://en.wikipedia.org/wiki/Catarina,_Nicaragua',
          hasLandmark: true,
        },
        city: {
          name: 'Masaya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-leafed-trees-under-cloudy-sky-qpcqMmD7KS8',
          wikiLink: 'https://en.wikipedia.org/wiki/Masaya',
        },
        landmarksRound: [
          { code: 'UZ', number: 1 },
          { code: 'CX', number: 3 },
          { code: 'ID', number: 4 },
        ],
        landmarkPlaces: [
          'Two Lovers Point',
          'Erbil Citadel',
          'Iguazu Falls',
          'Marina Bay Sands',
          'Hagia Sophia',
          "Fisherman's Bastion",
          "Tiger's Nest",
        ],
        uniqueId: '799mfznv',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Managua',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-bird-on-brown-tree-branch-during-daytime-jr8V04y_LTw',
          wikiLink: 'https://en.wikipedia.org/wiki/Managua',
        },
        landmark: {
          name: 'Cristo de la Misericordia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-statue-on-top-of-a-hill-with-a-sky-background-mN6Lid6Y_Ps',
          wikiLink: 'https://es.wikipedia.org/wiki/Cristo_de_la_Misericordia',
          hasLandmark: true,
        },
        city: {
          name: 'León',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-standing-near-white-building-under-blue-sky-ZnWMp_Jnz7o',
          wikiLink: 'https://en.wikipedia.org/wiki/Le%C3%B3n,_Nicaragua',
        },
        landmarksRound: [
          { code: 'FR', number: 3 },
          { code: 'VA', number: 3 },
          { code: 'BN', number: 1 },
        ],
        landmarkPlaces: [
          'Christmas Island National Park',
          "Queen's Staircase",
          'Stockholm Palace',
          'State History Museum',
          'Hvalsey Church',
          'Shell Beach',
          'Boali',
        ],
        uniqueId: 'rkJpMYtf',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Niger',
    code: 'NE',
    capital: 'Niamey',
    lat: 16,
    long: 8,
    cities: ['Agadez', 'Zinder', 'Niamey'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/niger-evils-river-africa-436253/',
          wikiLink: 'https://en.wikipedia.org/wiki/Niger',
        },
        landmark: {
          name: 'Great Mosque of Niamey',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Vue_de_la_grande_mosqu%C3%A9e_de_Niamey_01.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Mosque_of_Niamey',
          hasLandmark: true,
        },
        city: {
          name: 'Zinder',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Zinder#/media/File:Zinder_centre_ville.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Zinder',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'dHy6xVdn',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Niger,_Niamey,_Dunes_%283%29,_landscape.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Niamey',
        },
        landmark: {
          name: 'Agadez Grand Mosque',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Niger,_Agadez_%2828%29,_grand_mosque,_old_city.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Agadez_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Niamey',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Niamey#/media/File:Niamey_from_grand_mosque_theatre_2006.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Niamey',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'nWMQwvwN',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Niger',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-dirt-road-in-the-middle-of-a-desert-4sRoy8CwJPw',
          wikiLink: 'https://en.wikipedia.org/wiki/Niger',
        },
        landmark: {
          name: 'Grand Mosque of Niamey',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/itravelanddance/31742244894/in/photolist-7kvFWn-QmXiwo-6w6FrV-45wLMa-fiKuZe-fiKuZn-fiZJ9C-fiKuwP-45zDF9-514xoN/',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Mosque_of_Niamey',
          hasLandmark: true,
        },
        city: {
          name: 'Agadez',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/elmina/263252176/in/photolist-pgeGC-Ys818b-CG3QjG-5BzRm7-3YAPo5-ZHm2Cy-ZHkRcd-ZHkQ5y-ZKkiYW-7zrKPL-ZKkaqo-ZNZY6c-oZxmc-3YBYRh-rsMySj-qS1Q2K-ZCGb7y-ZHm6sN-YHm9bE-YAKhzu-ZtXf6E-oZzcC-hNL7YG-CzmfcE-5BvA3e-5BvAei-CzmeH3-5BvzMZ-ZBb6aj-5Bvzw2-5BvzkX-CzmfP1-YAKh4E-5BvzhH-Czmgby-oxd8m-dkkVmE-mNmH-ZhHgYE-5BzRBo-ZDzjGr-ZFtkt6-B3Z7S-YtPkVb-2PHTpq-Gk5gpP-2PC86z-2PC86R-5VC3cu-H75TpU',
          wikiLink: 'https://en.wikipedia.org/wiki/Agadez',
        },
        landmarksRound: [
          { code: 'MN', number: 5 },
          { code: 'VG', number: 2 },
          { code: 'DK', number: 6 },
        ],
        landmarkPlaces: [
          'Hell',
          'Buddha Park',
          'Timgad',
          'Galle Fort',
          'Emily Bay',
          "St. Paul's Catacombs",
          'Kamianets-Podilskyi Castle',
        ],
        uniqueId: 'YCH9TNSw',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Niamey',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/350org/4058383234/in/photolist-7bCgWJ-mLbBW-6yJUhq-Y4PTgb-76jaet-2aDyu3W-5yz3g-7bRNe5-2SmzcV-4G2ZZT-nEzkMi-87tb9h-2kYHGiC-4FCL7t-68nDQe-68rRXC-68rRZS-68nDRT-joXvts-2SmzF4-8WPYHQ-4qZsyZ-pDwney-5CecZy-4qZmEP-9FgZgz-C3v6Sm-9N3AvX-XiXCFz-qfduFo-qfduGW-2mfiWVg-9NcFwm-dCfpgw-7jnWHa-7jrPvd-87tbVE-BNVgr-iQxS4C-7jnWWZ-7jnVWa-82jTEX-pkvvkG-pZW3Vy-6HvuJ4-6uq371-7jrPQU-7jnU3M-7jnXc8-Y8sbwx',
          wikiLink: 'https://en.wikipedia.org/wiki/Niamey',
        },
        landmark: {
          name: 'Musée National Boubou Hama',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/jeanot/5772480254/in/photolist-9N6sAN-9N6uFj-9N6tgC',
          wikiLink:
            'https://en.wikipedia.org/wiki/Mus%C3%A9e_National_Boubou_Hama',
          hasLandmark: true,
        },
        city: {
          name: 'Zinder',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/toole_box/338428248/in/photolist-vUwVN-vUwVF-vUwVG-vUwVH-2k7RDBf-26fkaDV-Erp78-ErpyP-Erpuq-ErpkJ-2n1pAW6-5BPnvN-2nf6daN-28FGW7U-dQnUgf-217Fejb-217FdDU-2jm8w9d-cm1JjG-217Fegq-2k7RDFZ-2k7Mirp-2k7QQVD-9gA95e-2k7QFWX-Errvu-ax9iUk-yCUd4-ax9jXF-yCUsz-ax9j5T-ax9iX4-yCU8x-2brQyeF-axc2pu-ax9jAz-ax9jaz-axc1Yd-axc2G3-66nnWN-axc2Q5-yCUkd-yCUmu-yCUag-yCUgX-yCUp5-2d7Zav-yCUro-yCUpm-yCUmT',
          wikiLink: 'https://en.wikipedia.org/wiki/Zinder',
        },
        landmarksRound: [
          { code: 'LY', number: 2 },
          { code: 'KY', number: 3 },
          { code: 'GI', number: 2 },
        ],
        landmarkPlaces: [
          'Kravica Waterfall',
          'Rock of Gibraltar',
          'Grand Ducal Palace',
          'Maasai Mara',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Salar de Uyuni',
          'Arc de Triomphe',
        ],
        uniqueId: 'T8DKPJB2',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Nigeria',
    code: 'NG',
    capital: 'Abuja',
    lat: 10,
    long: 8,
    cities: ['Port Harcourt', 'Ibadan', 'Abuja'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/6UqJTfoXIq8',
          wikiLink: 'https://en.wikipedia.org/wiki/Lagos',
        },
        landmark: {
          name: 'Tafawa Balewa Square',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Tafawa_Balewa_Square_Image.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tafawa_Balewa_Square',
          hasLandmark: true,
        },
        city: {
          name: 'Abuja',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Abuja#/media/File:View_of_Abuja_from_Katampe_hill_01.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Abuja',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'ZrlvDrk8',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vehicles-road-city-traffic-street-5871002/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lagos',
        },
        landmark: {
          name: 'Gidan Rumfa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Gidan_Rumfa#/media/File:Gate_to_the_Gidan_Rumfa_(2009)_in_Kano,_Nigeria..jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gidan_Rumfa',
          hasLandmark: true,
        },
        city: {
          name: 'Kano',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kano_(city)#/media/File:KanofromDalaHill.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kano_(city)',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'x5Gy29v3',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Nigeria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tree-that-is-next-to-some-rocks-tSrmA33GnUQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Nigeria',
        },
        landmark: {
          name: 'Abuja National Mosque',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Abuja_National_Mosque#/media/File:Abuja_National_Mosque.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Abuja_National_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Port Harcourt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-man-holding-up-a-sign-in-front-of-a-crowd-ceawFbpA-14',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Harcourt',
        },
        landmarksRound: [
          { code: 'PN', number: 3 },
          { code: 'UY', number: 2 },
          { code: 'JM', number: 3 },
        ],
        landmarkPlaces: [
          'Lake Tekapo',
          'Pulu Keeling National Park',
          'Dziani Dzaha',
          'Fasil Ghebbi',
          'The Baths',
          'Acropolis',
          'Baie Larose',
        ],
        uniqueId: '2Xnk7BdK',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Lagos',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-standing-at-grey-concrete-road-tmz3Z1L1qaQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Lagos',
        },
        landmark: {
          name: 'Freedom Park',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/jujufilm/5528427321/in/photolist-9qwCQr-gR9u63-9qzDaL-9qwSgV-feMKap-9qzBkS-9qzEiJ-9qwBDz-9qwSWP-9qwBjp-9qzFaW-9qwHU2-9qzQUj-9qzyrf-9qzRdy-9qzEJy-9qzT3m-9qwyGT-9qwKk2-9qzPeb-9qzSGm-9qzxFY-9qzUoL-9qwLxK-9qwAXM-9qzc5y-9qzFuf-R1GbkL-9qzFNo-9qw8cD-9qyHVC-9qzNhf-9qzz7j-9qzHZo-9qwJSp-9qwG4x-9qwTcT-o98cm1-qzZb4E-kV7KX2-9qzzXJ-GtRpVX-9qwNUr-9qwGP6-9qwRMn-9qwMR8-9qzSau-9qzLVG-9qzK57-2otuj4d',
          wikiLink: 'https://en.wikipedia.org/wiki/Freedom_Park_(Lagos)',
          hasLandmark: true,
        },
        city: {
          name: 'Ibadan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-group-of-green-plants-Ukzl5Um35xM',
          wikiLink: 'https://en.wikipedia.org/wiki/Ibadan',
        },
        landmarksRound: [
          { code: 'LU', number: 1 },
          { code: 'IE', number: 5 },
          { code: 'LY', number: 1 },
        ],
        landmarkPlaces: [
          'Basilica del Voto Nacional',
          'Leon Cathedral',
          'Sandy Island',
          'Azadi Tower',
          'Ocean Park',
          'Anjar',
          'Helsinki Cathedral',
        ],
        uniqueId: 'z376SQGL',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Niue',
    code: 'NU',
    capital: 'Alofi',
    lat: -19.03333333,
    long: -169.8666667,
    cities: ['Avatele', 'Tuapa', 'Alofi', 'Makefu', 'Namukulu', 'Hikutavake'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Niue_K%C3%BCstenlandschaft_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Niue',
        },
        landmark: {
          name: 'Limu Pools',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Limu_Pools,_Niue.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Niue',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'SrbbZDCb',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Rockpools_and_cliff_at_Niue.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Niue',
        },
        landmark: {
          name: 'Talava Arches',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Talava_Arches_1,_Niue.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Geography_of_Niue',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'F4LpPKHg',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Niue',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/nicktheworldtraveler/3763768900/in/photolist-6JAinf-6JA3bs-6JvVYg-6JvYap-2iYDbRw-h8vbn9-wcH5qJ-6Jyw9F-6JCLBS-6JB6WA-6JzPHY-6JyKtR-6JwFK6-KFSVJS-vxXcfw-6JzP1Q-6JCKxd-2iYDaHK-6JwJrD-6JzZFS-6JA5yh-6JAf47-6JAd4o-2iYDbf6-6JzSJN-6JA8NU-2iYyQzi-6JAHQE-vxY2XG-6JAKYW-wuiCsE-6JwfPr-wujXLR-vy6Dp8-6JB7YQ-6JAN7U-6JweFe-6JwgP6-6JCPeA-6JzTso-6Jw4PK-2h6BB4t-2iYDaKy-6JwcoT-2iYDc7m-2iYyQAA-2iYBAYc-2iYBARU-6JzUEG-2iYySe5',
          wikiLink: 'https://en.wikipedia.org/wiki/Niue',
        },
        landmark: {
          name: 'Togo Chasm',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/vuorikari/773404574/in/photolist-2bkUeC-XSokBH-Aq4uwg-d7d48j-78T5nY-aoVYE2-vxY7rG-XSonc6-WRdYaD-WSmMc6-78Pdsc-fTeGCf-fLTs9c-fLTqZ6-6HYeh2-wuimAQ-gWHHia-wsEBxN-WRdWNa-WSmRFr-B2Grx8-6Fqqtv-2bgt54-WSmG8t-wsEQsA-B2Gs9D-vxccTo-wdnLof-6JBTkW-6JBPn9-B2Gr3F-6JxZFk-wdnaMQ-6JBQsE-6JC2vq-6JxHpc-WEz4uF-58hkUn-WgX9B7-6J3jTj-vx8EYd-yXk9QT-6JC3HJ-6JBVrW-6JxMni-6JBZho-AnKgCB-6JxVna-6JBK3s-AYq8Gd',
          wikiLink:
            'https://it.wikipedia.org/wiki/File:Togo_Chasm_at_the_sea_edge,_Niue.jpg',
          hasLandmark: true,
        },
        city: {
          name: 'Avatele',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/48208248@N04/49637600068/in/photolist-2iCiF9Q-2q82WPz-B2FUBR-2q82w27-2pivtNS-Mz7seU-2pivtNG-8ho3sE-L39cAA-LjcadE-LQPfr5-MaBNMr-b6fiZ-2orUWv5-Mz7vJQ-2dT83bD-dnGUZ3-dnGPmx-dnGXcQ-dnGRND-dnGW1j-aqYQPp-f1Q4Ws-goJbG-fGWnCf-2q9u7Hh-KgQksN-aokB9H-gWJp4o-fr4yjR-aKN4sk-LqkkFR-MmhT8Q-XPExyA-fazqpC-dgjJH5-JLz5s4-KE9LQQ-JLt4Lh-4k2UUV-aKN5Vv-goJbH-gWJdXE-h8sgMe-LvxwaX-h8sj7e-gWJbGj-gWKb5x-h8si9o-h8scNx',
          wikiLink: 'https://en.wikipedia.org/wiki/Avatele',
        },
        landmarksRound: [
          { code: 'UY', number: 3 },
          { code: 'MU', number: 3 },
          { code: 'EE', number: 5 },
        ],
        landmarkPlaces: [
          'Les Eclaireurs Lighthouse',
          'Rock of Gibraltar',
          'Liberation Tower',
          'Roi-Namur',
          'Dziani Dzaha',
          'Okavango River',
          'Lovrijenac',
        ],
        uniqueId: 'r5m4Dw8R',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Alofi',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/14197492@N07/22991592951/in/photolist-B2FVz2-MEUzpz-MEVwjK-3hHcho-LQTBgx-LQTatk-wv1nxk-MNfE7n-AnJZ9A-MNg4Ve-pS7vdo-LQT4ur-AJ67Aw-LQP45Y-pcRmVT-MEV5Gx-pcRpMK-LQT7FM-2jjcPUs-vVMTb1-MCf9QU-MNfACi-6JCAcG-6JCFAE-MCfbM9-Mz7Lmf-pcRocv-MEUBhn-q9v4Ei-MThLty-q7o9jQ-2jm9rRM-pBaeku-2jpmBPw-MF5twm-MThjnW-M6JZFG-2j5pGd5-q9y2Pu-qgAfCW-MX4wEy-2hqiuCo-M6NNfB-27NpEbE-qgzQv7-M6JXm1-pB9QMo-2jjcPXo-M6K5bd-MVSH5D',
          wikiLink: 'https://en.wikipedia.org/wiki/Alofi',
        },
        landmark: {
          name: 'Hio Beach',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/csoteloc/28443627260/in/photolist-Kkt2qy-AnK8LK-SP7YWL-wxsE7D-ZBmvhY-RQVqnA-agNKDn-8mcYNF-8mg5M5-8mg6PL-8mg82C-6jAqR5-ah9Yc7-vxWo85-q9sStz-SxCtA3-wfR1Eq-5qHXuW-AnHhQw-Aq2hsv-s1kq9-AYnXy1-9HhT18-4gFpMT-e96DPP-6NgKF3-9mmA3Z-9gBWNM-9mpKqs-9mmzot-ajfJKo-9mpHks-XzAfc6-9mmCoD-9mmFQK-9mpFUE-6tpv1D-6S5pa3-kGiB2F-6ttm4b-91e8AM-6N9vBn-pSe6bi-9gF2ww-9bPs1T-8L2cNe-6tnZzX-6N9GgF-AZusPd-3B4MJD',
          wikiLink: 'https://ceb.wikipedia.org/wiki/Hio_Beach',
          hasLandmark: true,
        },
        city: {
          name: 'Tuapa',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/coookiek/6046041806/in/photolist-adgxA7-99EdS8-99EcHn-aDvFpX-adgndQ-7jnBnJ-99EdLc-99EbeK-adguAy-7jiCbt-99EcVn-7jnuEs-99Eb6P-99DZHk-99Ed9e-6KQeCP-99DT9M-99HiMU-6KPCXp-99Hhcs-99E2AT-99DLPB-99GSKm-99HhFG-99Hhu7-99HjFE-99HiXE-adgimE-adggGY-99H6Po-99DQJk-99GViw-addzbn-6KSzLf-6CHcaQ-99EdjM-99EaTX-6KPzXa-99HjfS-7jnE2L-99HjsJ-99Hh1y-99EabD-6KRM1j-7jiKN6-99HciL-6KNq1z-6KPp1a-6KLBZX-6KQJ1L',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuapa',
        },
        landmarksRound: [
          { code: 'GR', number: 5 },
          { code: 'GU', number: 5 },
          { code: 'JP', number: 2 },
        ],
        landmarkPlaces: [
          'Alexander Nevsky Cathedral',
          'Blue Grotto',
          'Predjama Castle',
          'Hof',
          'Mawson Peak',
          'Marigot Bay',
          'Fort Karl',
        ],
        uniqueId: 'VdzJwGZz',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    capital: 'Kingston',
    lat: -29.03333333,
    long: 167.95,
    cities: [
      'Kingston',
      'Burnt Pine',
      'Anson Bay',
      'Bumboras',
      'Cascade',
      'Longridge',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/coast-coastline-scenic-scenery-2446324/',
          wikiLink: 'https://en.wikipedia.org/wiki/Norfolk_Island',
        },
        landmark: {
          name: 'Emily Bay',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Emily_Bay_Norfolk_Island.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Norfolk_Island',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '7Fb7PfwB',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/norfolk-island-islands-landscape-2446296/',
          wikiLink: 'https://en.wikipedia.org/wiki/Norfolk_Island',
        },
        landmark: {
          name: 'St. Barnabas Chapel',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Norfolk_Island_St_Barnabas_Chapel.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Norfolk_Island',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'SfVQGJvf',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Norfolk Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-small-white-bird-sitting-under-a-tree-D1T712fMFiM',
          wikiLink: 'https://en.wikipedia.org/wiki/Norfolk_Island',
        },
        landmark: {
          name: 'Norfolk Island National Park',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/wdwbarber/2678004223/in/photolist-55DtmB-egM4et-efKWpt-f8mmbr-Gj1YJw-6RYrkh-5UomvJ-55ChYH-7EDgBu-2nnjgSN-DT24Bx-2nYfdML-HrSn73-HYy5NT-2m4AqEU-fhEurq-NjCJVz-QxqcMV-2ma1Tmi-2p9qCsS-3yXiEt-55Hz1E-3yXmaM-2m4QYpS-i3mmae-3z1oZb-21qv2i6-CqLaDF-NvsV9G-C6VXsQ-CotKBh-225qwgS-2nzRkLc-EBfAoB-8RbZt1-9nD26b-Hb6Zum-NHvjAN-3z2Dio-R24inW-NUrBi1-7VTuGf-2m7Merz-2nzRkH6-NjX74C-2oizDSg-eh74Hk-NzJPkb-3yXcQ4-2muVpVf',
          wikiLink:
            'https://en.wikipedia.org/wiki/Norfolk_Island_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Kingston',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/133795069@N05/34120574090/in/photolist-TZ7RWb-n86pEF-AZsTUY-AYmkHq-2kGBEMe-24fdyu6-2kHnp7A-ApZA3V-AnFvsP-UCTQYH-2kGBxbx-2kLrs3H-3yZzhE-2h68iW7-2kKKFq9-2gipgp6-3DT8yE-2pxHf1h-2kGxTeU-2nzFHfF-AnFuoV-AZsXBN-2hqP6r4-V1wHsR-B2Cwdt-B1DBSx-A7sib4-3z1SQd-2hTfJip-2onoggx-AZsJW7-AnFKY3-25ScT5i-4d1hJU-A4BVe9-mQHL2m-fMTqEC-2izNwGx-ZTnnNF-Sbpz9X-3DNYe4-9Naxkz-3yVsCr-2noWwHQ-22eHvr4-2nfmFHX-8dEzKx-2cDgbSP-2ppzm29-SbpvGX',
          wikiLink: 'https://en.wikipedia.org/wiki/Kingston,_Norfolk_Island',
        },
        landmarksRound: [
          { code: 'AG', number: 4 },
          { code: 'BF', number: 2 },
          { code: 'NP', number: 4 },
        ],
        landmarkPlaces: [
          'Borobudur Temple',
          'Lake Niamawi',
          'Berat Castle',
          'Capilano Suspension Bridge',
          'Karnak',
          'Seven Rile Lakes',
          'St. Lawrence Church',
        ],
        uniqueId: 'w5q8FPZB',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Kingston',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/16938270@N00/53753733338/in/photolist-pSM4bP-2pU2WaQ-26UmQuk-2htGQ9H-2nZmoqC-2kLDp4E-JDVV7P-2oh7p1u-U9Vb6C-9NdigN-8z8Lwo-2kGBDtN-2pkqpLp-2osZvdd-TZ7RWb-2gipgp6-2h7bhPG-2h5EsY3-3z2QGq-2n55Mm2-AZsTUY-n86pEF-2kGBEMe-2kHnp7A-2kLDs9Y-24fdyu6-AYmkHq-ApZA3V-AnFvsP-UCTQYH-2kGBxbx-2kLrs3H-3yZzhE-2h68iW7-2kKKFq9-3DT8yE-2pxHf1h-2kGxTeU-2nzFHfF-AnFuoV-AZsXBN-2hqP6r4-V1wHsR-B2Cwdt',
          wikiLink: 'https://en.wikipedia.org/wiki/Kingston,_Norfolk_Island',
        },
        landmark: {
          name: 'Emily Bay',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/joy_snow/38880125831/in/photolist-22eGNR6-23w7JzY-21vQt7X-PNrvYU-Q8da87-J9hVbS-J9qMAL-21GgHMD-224C7VS-2aYCHc5-Sdaies-23gA6TE-G4sx6K-G7fkmE-RTCtUL-3DTnjG-271J7rB-HUFY5X-24Va92n-23w5ab7-26dn8QU-26QvmcW-iRQvLR-Zrc9T5-iRS2L8-HqW3Re-2cmpP5e-coXhPq-26VCmmS-oh4fz7-aQCfqk-21zSv3x-26QvmiC-25i97Kj-28DCgb1-YMJ4X2-23QJBAT-H8wi6T-28DCgAu-iRTDLq-21awNry-TLoKgG-2evHqSr-25EyUBg-TLoJXA-Hb6Zh7-Mi9HPh-2596WEj-2dJE3xb',
          wikiLink:
            'https://ceb.wikipedia.org/wiki/Emily_Bay_(luuk_sa_Pulo_sa_Norfolk)',
          hasLandmark: true,
        },
        city: {
          name: 'Burnt Pine',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/sleepoutside/3569473154/in/photolist-6rqu1A-oFVFZk-8DvXQp-eig6cy-h3x9E6-btgaj2-jA6xM5-dwywep-kYkhzw-BH4kuR-9HhLcv-koL5YF-5kgbSz-DqTrjv-koeUqV-6cMUHg-dv6rEa-dm5ESi-Nbrjqu-9qbPLR-MJCwf-r3KYMa-dy1B4Y-dJnKya-iXLbRu-oQ2vFK-28HJeKh-j51uhh-DX6J7b-9aWiu8-LEmGru-tX5qjg-a6BbnY-LEmHqd-rYWrT6-8kPGq6-dg1cp4-im3kox-isuBBr-a1masW-dwwrUZ-EGsiEg-cRXV3j-8hSZaU-cbybdf-dnoatt-75sCzD-okFPNn-ddHSGQ-9m3gZg',
          wikiLink: 'https://en.wikipedia.org/wiki/Burnt_Pine',
        },
        landmarksRound: [
          { code: 'BS', number: 4 },
          { code: 'BY', number: 1 },
          { code: 'MD', number: 1 },
        ],
        landmarkPlaces: [
          'San Juan National Historic Site',
          'Mir Castle',
          'Grand Mosque of Bobo-Dioulasso',
          'Wawel Castle',
          'Anjar',
          'Sant Joan de Caselles',
          'Tegucigalpa Cathedral',
        ],
        uniqueId: 'W6LPJQbF',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'North Korea',
    code: 'KP',
    capital: 'Pyongyang',
    lat: 40,
    long: 127,
    cities: ['Sinujiu', 'Pyongyang', 'Chongjin', 'Nampo', 'Hamhung', 'Wonsan'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/3dSv5LXts8A',
          wikiLink: 'https://en.wikipedia.org/wiki/Pyongyang',
        },
        landmark: {
          name: 'Kim Il Sung Square',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/north-korea-pyongyang-building-2662076/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kim_Il-sung_Square',
          hasLandmark: true,
        },
        city: {
          name: 'Wonsan',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Wonsan#/media/File:Wonsan_waterfront_(2937890043).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Wonsan',
        },
        landmarksRound: [
          { code: 'PF', number: 1 },
          { code: 'IE', number: 2 },
          { code: 'OM', number: 2 },
        ],
        landmarkPlaces: [
          'Wat Xiengthong',
          'Pulhapanzak Waterfalls',
          'Castillo San Cristobal',
          'Turku Castle',
          'Parthenon',
          'La Sagrada Familia',
          'Gediminas Castle Tower',
        ],
        uniqueId: 'CpRnMc5p',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/north-korea-soldiers-poor-force-2377251/',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Korea',
        },
        landmark: {
          name: 'Juche Tower',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Juche_Tower#/media/File:Juche-Tower-2014.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Juche_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Sinujiu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sinuiju#/media/File:Aerial_view_of_Downtown_Sinuiju.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sinuiju',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'fYqQHQDC',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'North Korea',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/roman-harak/5015266763/',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Korea',
        },
        landmark: {
          name: 'Monument to Party Founding',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-under-blue-sky-during-daytime-z89pcnApqfk',
          wikiLink: 'https://en.wikipedia.org/wiki/Monument_to_Party_Founding',
          hasLandmark: true,
        },
        city: {
          name: 'Pyongyang',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-crossing-and-standing-at-the-streets-near-buildings-9u70LHzL1ME',
          wikiLink: 'https://en.wikipedia.org/wiki/Pyongyang',
        },
        landmarksRound: [
          { code: 'MW', number: 1 },
          { code: 'EE', number: 5 },
          { code: 'MT', number: 5 },
        ],
        landmarkPlaces: [
          'We Are Our Mountains',
          'Gishora Drum Sanctuary',
          'Kalandula Falls',
          'The Blue Mosque',
          'Gediminas Castle Tower',
          'Chichen Itza',
          "St. John's Cathedral",
        ],
        uniqueId: 'CW2CC4mC',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'North Korea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-near-building-during-daytime-O4qX4vBSVAI',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Korea',
        },
        landmark: {
          name: 'Juche Tower',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-red-tower-near-body-of-water-during-daytime-PqNJRBIs9Ac',
          wikiLink: 'https://en.wikipedia.org/wiki/Juche_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Hamhung',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/kernbeisser/2480647612/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hamhung',
        },
        landmarksRound: [
          { code: 'GD', number: 2 },
          { code: 'LU', number: 4 },
          { code: 'MD', number: 4 },
        ],
        landmarkPlaces: [
          'Karnak',
          "St. Peter's Basilica",
          'Vaduz Cathedral',
          'Maluti Mountains',
          'El Capitolio',
          'Mount Karthala',
          'English Harbour',
        ],
        uniqueId: 'tVKNFbb9',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'North Macedonia',
    code: 'MK',
    capital: 'Skopje',
    lat: 41.83333333,
    long: 22,
    cities: ['Tetovo', 'Skopje', 'Ohrid', 'Prilep', 'Veles', 'Bitola', 'Štip'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/4CzzaJnQh0c',
          wikiLink: 'https://en.wikipedia.org/wiki/Skopje',
        },
        landmark: {
          name: 'Skopje Fortress',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Skopje_Fortress#/media/File:KaleFortress-Skopje2.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Skopje_Fortress',
          hasLandmark: true,
        },
        city: {
          name: 'Bitola',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bitola#/media/File:Sirok_sokak.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bitola',
        },
        landmarksRound: [
          { code: 'LA', number: 2 },
          { code: 'HK', number: 1 },
          { code: 'PT', number: 1 },
        ],
        landmarkPlaces: [
          'Fort Christian',
          'Red Square',
          'Niagara Falls',
          'Timgad',
          'Luis I Bridge',
          'Stonehenge',
          'Registan Square',
        ],
        uniqueId: '7sLrSfxR',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/beach-boats-morning-landscape-5252448/',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Macedonia',
        },
        landmark: {
          name: 'Monastery of St. Naum',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/monastery-of-saint-naum-ochrid-lake-4334306/',
          wikiLink: 'https://en.wikipedia.org/wiki/Monastery_of_Saint_Naum',
          hasLandmark: true,
        },
        city: {
          name: 'Tetovo',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-landscape-houses-architecture-6261441/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tetovo',
        },
        landmarksRound: [
          { code: 'NZ', number: 2 },
          { code: 'UG', number: 1 },
          { code: 'CA', number: 2 },
        ],
        landmarkPlaces: [
          'Amberd',
          'Deception Island',
          'Taj Mahal',
          'Lotto World',
          'Elmina Castle',
          'Amalienborg',
          'Azadi Tower',
        ],
        uniqueId: 'QRBjKdSr',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'North Macedonia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-leafed-trees-eDzaur7RwFY',
          wikiLink: 'https://en.wikipedia.org/wiki/North_Macedonia',
        },
        landmark: {
          name: 'Memorial House of Mother Teresa',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/aiva/42311504244/in/photolist-27sVw6S-2gHQMtB-RtmYVh-SNq8fZ-2gdDMBY-a2BUF6-2n1D3Un-2gdDMqR-2kVQi7H-db3nKx-qwVLUB-2q81pss-a2BUKz-a2EM49-Y5bgZA-ZLqmp3-2aqoDbk-2gdDL12-drts4L-RGozMp-252PRK9-2647anq-2gdE2Qp-2gdDM2Q-nKqBhV-267Vort-2nzrLFo-23mYcmA-HZ6sdE-RyFMnX-VZ6UhJ-W2CzFR-24JCpYp-263Ym2N-252zuDu-2pdh9C2-e52LPb-GrCWop-7cMHMn-hUKqEG-GrCWBa-267QNAv-2nCJrrU-RxPbxn-RF3R2d-2nzcUb9-RhuCUW-2gdE2w8-2gdDLo6-2gdDKTU',
          wikiLink:
            'https://en.wikipedia.org/wiki/Memorial_House_of_Mother_Teresa',
          hasLandmark: true,
        },
        city: {
          name: 'Štip',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-on-a-field-playing-soccer-Z2aAb6HmDjA',
          wikiLink: 'https://en.wikipedia.org/wiki/%C5%A0tip',
        },
        landmarksRound: [
          { code: 'PH', number: 1 },
          { code: 'LS', number: 5 },
          { code: 'MW', number: 1 },
        ],
        landmarkPlaces: [
          'Chinguetti',
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Salar de Uyuni',
          "Devil's Bridge",
          'Cristo Rei',
          'Laxey Wheel',
          'California Lighthouse',
        ],
        uniqueId: 'R9TcxX3g',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Skopje',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-mountain-under-white-clouds-during-daytime-BRIrtLcf4YE',
          wikiLink: 'https://en.wikipedia.org/wiki/Skopje',
        },
        landmark: {
          name: 'Plaošnik',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/robert_zahariev/32466613945/in/photolist-RsXTcX-2kGMsvw-2mfPUbD-2iCkeUh-UPArFN-2miQCZy-2peVZGf-EaQFCe-5gjSf2-2y5LQz-2pgy1Ct-2peQeBm-6RVJj3-5VAcJy-4JPC4v-2XLtnc-rMFFNY-TsxmVE-2oHvb9p-2oHvb3h-2oHxuhw-2oHs4VV-dRmvZq-dRfYQc-dRmx8E-U49scH-fz62LK-UZAfFE-6Wmjur-8ehQgv-5T4tnr-24bLzHD-9XBXe1-2XR2KG-8NscjD-2XR7Z9-84ccKi-2n3rwpL-2n3wT8a-2n3y6Zg-dRfZ6R-dRfYxv-dRfYGc-dRmwRb-dRmwGU-dRmwZj-dRmxEN-VjyG1a-bj3Zcz-2n3wsGe',
          wikiLink: 'https://en.wikipedia.org/wiki/Plao%C5%A1nik',
          hasLandmark: true,
        },
        city: {
          name: 'Ohrid',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-up-a-hill-next-to-a-body-of-water-D9ZJzAeUGkw',
          wikiLink: 'https://en.wikipedia.org/wiki/Ohrid',
        },
        landmarksRound: [
          { code: 'KR', number: 2 },
          { code: 'VE', number: 4 },
          { code: 'EE', number: 6 },
        ],
        landmarkPlaces: [
          'Maletsunyane Falls',
          'Chomoni Beach',
          'Nesbitt Castle',
          'Cathedral of St. Florin',
          'Mir Castle',
          'Amboseli National Park, Kenya',
          'Vakil Mosque',
        ],
        uniqueId: 'rBcYCyWh',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    capital: 'Saipan',
    lat: 15.2,
    long: 145.75,
    cities: ['Capitol Hill', 'Laulau', 'Saipan', 'Susupe'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/saipan-island-beach-ocean-175425/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saipan',
        },
        landmark: {
          name: 'Suicide Cliff',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Suicide_Cliff#/media/File:SUICIDE_CLIFF.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Suicide_Cliff',
          hasLandmark: false,
        },
        city: {
          name: 'Susupe',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Susupe#/media/File:Downtownsusupe.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Susupe',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'dXtBJMkg',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/saipan-island-beach-ocean-175424/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saipan',
        },
        landmark: {
          name: 'House of Taga',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Fallen_latte_stones_at_Taga_House,_Tinian.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/House_of_Taga',
          hasLandmark: false,
        },
        city: {
          name: 'Garapan',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Beach_Road_-_Garapan_Saipan_-_panoramio_%281%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Garapan',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'WsXlbpJB',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Northern Mariana Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/selective-focus-photography-of-kanji-script-lanterns-0PnQQ4hp_u8',
          wikiLink: 'https://en.wikipedia.org/wiki/Northern_Mariana_Islands',
        },
        landmark: {
          name: 'Suicide Cliff',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/30987550@N06/13393231364/in/photolist-mpvP59-ajCqm2-kKoHe-ajChuP-ebBGSN-ajCABZ-4aGAgn-rt9Ktj-SGXqnc-Kf2j2d-rtm9EM-7Kt9Wb-nQEZSh-nHTH95-8SgQtX-kKotC-FYTdHU-KaRsJ4-dvhZSB-Q52jY-DqEdC2-25GxukV-dU6y6-Hfu4y-3X6JL-rPyhGG-H7T3Dz-PQneQF-cxemv-bZQGQE-acowdU-hyxvue-XHxoQS-8Nxcda-aPq8dD-27kTQv3-LbDKV-ehCnCD-9a97hA-2Dpw61-E5gA3y-pGqrmr-mrxgak-DhYW7N-oFDR2S-8dk2CU-y3VTM-zF3kGD-7JZ9i8-8r98tu',
          wikiLink: 'https://en.wikipedia.org/wiki/Suicide_Cliff',
          hasLandmark: true,
        },
        city: {
          name: 'Capitol Hill',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/35706139@N03/13751635853/in/photolist-mXbJeT-e68EvV-mj6Hu-fahTds-6rLsZP-2q1xsTv-2oR26Yr-8tz8gb-8tz86u-8tz8NW-8tw878-8tz7HL-8tw7FD-8tw6W2-8tw7xK-8tz7Xw-6ySYSb-mXbHY2-c4FQZf-DMu1NE-DKHFaL-ZyUsat-21Tv3nZ-ZN8T8j-21TwdTM-nVZvA-68Ahgu-KJAxYG-nVZvz-nVZvC-nVZvF-LBsS5U-KJAxCw-2iVCNee-7WVfRF-74yTGu-nW1Wf-Zx6GvT-Zx9ywk-Gr4rQ-21QFY5Y-VqwDpn-nW1Wg-3hkXdC-wU1oCx-uKjiRx-nW1Wb-74v6uK-74yWAJ-kAqNDb',
          wikiLink: 'https://en.wikipedia.org/wiki/Capitol_Hill,_Saipan',
        },
        landmarksRound: [
          { code: 'GL', number: 1 },
          { code: 'MW', number: 4 },
          { code: 'CZ', number: 1 },
        ],
        landmarkPlaces: [
          'Schlossberg (Graz)',
          'Szechenyi Thermal Bath',
          'Nizwa Fort',
          'George Washington House',
          'Salomons Atoll',
          'Vilnius Cathedral',
          'Eltz Castle',
        ],
        uniqueId: 'Dc8Z33S6',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Saipan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-on-sea-under-white-clouds-during-daytime-zCVGDMXRdeQ',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saipan,_Northern_Mariana_Islands',
        },
        landmark: {
          name: 'House of Taga',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/33461472@N05/32743682863/in/photolist-RTrW9g-T8qeov-ajCqmi-5poeeh-7Ghhx3-rWBj9C-7ZdQrK-r2Ug53-7ZdNJV-a46Cw8-ajFKfo-a49vRY-4oW8VA-5thF8o-bvzzaQ-5piXdr-5poebJ-5poefY-bvzzaE-bvzvk9-STzGK7-Dojptk-crWF2o-5FCi5y-Tt6xfE-6y6aGB-UauyzJ-BwTM4b-UFWEXh-UauyH9-UFWEkW-UauyaL-UFWESh-UFWEQd-Tt6xqQ-UauyQy-Tt6xwm-2jYUmTP-73UaVu-4mWcg7-2e5fN9t-TjU7sY-2ju63oz-kA1n5y-a9R4rW-7Ghphu-2jYYiyi-2juabLn-6uk5QD-5dP5SQ',
          wikiLink: 'https://en.wikipedia.org/wiki/House_of_Taga',
          hasLandmark: true,
        },
        city: {
          name: 'Laulau',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/77591274@N00/4736860495/in/photolist-8dzDAx-cjBcWU-brk9s3-bqYQ9E-7DoRqT-8hy6Lb-9a6QhX-bEdPuv-7DsDQj-7Qe8u7-eNp2i-9tpHDh-b9XLL8-bznmXR-b8RGwH-cjBdaG-cjBd4C-4HztP2-9uuHzX-KVveh-9a9Yyj-7DsFvy-7KRXrM-7PZGXj-7CREFK-9PjGM9-7NcE4T-9z7UCz-7avR2J-9z7WDH-9zb1Lo-9zaZbN-7D5eTU-9a6SuF-9a9UCh-4aGCkt-9a6Lvi-8dDb99-7HJvw1-9zb3pA-9a9UeW-9zb3C1-9z7VqT-9zb1y7-9zb31U-9zaXoq-9z7YNi-9z7UV4-9zaYGC-9z7WXP',
          wikiLink: 'https://en.wikipedia.org/wiki/Laulau,_Saipan',
        },
        landmarksRound: [
          { code: 'JO', number: 4 },
          { code: 'GT', number: 6 },
          { code: 'DO', number: 2 },
        ],
        landmarkPlaces: [
          'Tarawa',
          'Al Alam Palace',
          'Republic Square',
          'Basilica of Our Lady of Guadalupe',
          'Sukhbaatar Square',
          'Langkawi Sky Bridge',
          'Dolly Beach',
        ],
        uniqueId: 'cgqwrd26',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Norway',
    code: 'NO',
    capital: 'Oslo',
    lat: 62,
    long: 10,
    cities: [
      'Oslo',
      'Bergen',
      'Alesund',
      'Frederikstad',
      'Kristiansand',
      'Stavanger',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/khbjgGAerPU',
          wikiLink: 'https://en.wikipedia.org/wiki/Preikestolen',
        },
        landmark: {
          name: 'Akershus Fortress',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/akershus-fortress-at-night-norway-2517239/',
          wikiLink: 'https://en.wikipedia.org/wiki/Akershus_Fortress',
          hasLandmark: true,
        },
        city: {
          name: 'Oslo',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/oslo-akerbrygge-norway-1091011/',
          wikiLink: 'https://en.wikipedia.org/wiki/Oslo',
        },
        landmarksRound: [
          { code: 'EG', number: 2 },
          { code: 'FR', number: 1 },
          { code: 'BB', number: 1 },
        ],
        landmarkPlaces: [
          'Umayyad Mosque',
          'Lukan Longshan Temple',
          'Monteverde Cloud Forest',
          'Devin Castle',
          'Saint Sava Temple',
          'Badshahi Mosque',
          'Wawel Castle',
        ],
        uniqueId: '8cKjvhjn',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mountains-sunset-clouds-estuary-5784152/',
          wikiLink: 'https://en.wikipedia.org/wiki/Norway',
        },
        landmark: {
          name: 'Frogner Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Frogner_Park#/media/File:NOR-2016-Frogner_Park-Vigeland_Installation-The_Monolith.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Frogner_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Bergen',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/norway-bergen-landscape-4766392/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bergen',
        },
        landmarksRound: [
          { code: 'BY', number: 1 },
          { code: 'IS', number: 1 },
          { code: 'EE', number: 1 },
        ],
        landmarkPlaces: [
          'Chimi Lhakhang',
          'Amber Palace',
          'Wat Arun',
          'George Washington House',
          'Sukhbaatar Square',
          'Ruhyyet Palace',
          'Christ the Redeemer',
        ],
        uniqueId: 'D8N5hgjX',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Reine',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-houses-near-ocean-water-and-mountains-covered-with-green-leafed-tress-landscape-photography--yOZe-6UT1w',
          wikiLink: 'https://en.wikipedia.org/wiki/Reine',
        },
        landmark: {
          name: 'Trolltunga',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-standing-on-gray-high-rise-rock-formation-at-daytime-P9BY2joAcwk',
          wikiLink: 'https://en.wikipedia.org/wiki/Trolltunga',
          hasLandmark: true,
        },
        city: {
          name: 'Oslo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-gathering-outside-building-aS3ftVZLrVI',
          wikiLink: 'https://en.wikipedia.org/wiki/Oslo',
        },
        landmarksRound: [
          { code: 'KG', number: 6 },
          { code: 'DO', number: 1 },
          { code: 'WS', number: 2 },
        ],
        landmarkPlaces: [
          'Big Ben',
          'Tenaru Falls',
          'Kpatawee Falls',
          'Niagara Falls',
          'Hundertwasserhaus',
          'Palace of the Grand Master of the Knights of Rhodes',
          'Kalandula Falls',
        ],
        uniqueId: 'FCCcyTcj',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Hovden',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-gray-house-near-mountain-under-cloud-LNrxP3CWEiQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Hovden,_Nordland',
        },
        landmark: {
          name: 'Nidaros Cathedral',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/190022557@N04/51691184128/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nidaros_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Bergen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/colorful-townhouse-beside-body-of-water-in-sweden-VoD0rb4twTE',
          wikiLink: 'https://en.wikipedia.org/wiki/Bergen',
        },
        landmarksRound: [
          { code: 'HK', number: 2 },
          { code: 'DK', number: 4 },
          { code: 'TV', number: 4 },
        ],
        landmarkPlaces: [
          'Senado Square',
          'Deadvlei',
          'Charles Bridge',
          'Forbidden City',
          "N'Gouja",
          'Iguazu Falls',
          'Christ the King Cathedral',
        ],
        uniqueId: 'wZqBrR4s',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Oman',
    code: 'OM',
    capital: 'Muscat',
    lat: 21,
    long: 57,
    cities: ['Muscat', 'Salalah', 'Sohar', 'Sur', 'Rustaq', 'Seeb', 'Muttrah'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9R-jEko1PKc',
          wikiLink: 'https://en.wikipedia.org/wiki/Muttrah',
        },
        landmark: {
          name: 'Nizwa Fort',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/nizwa-fort-fort-oman-travel-3245729/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nizwa_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Muscat',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/muscat-oman-harbor-travel-boat-2455049/',
          wikiLink: 'https://en.wikipedia.org/wiki/Muscat',
        },
        landmarksRound: [
          { code: 'AE', number: 1 },
          { code: 'MZ', number: 2 },
          { code: 'HN', number: 1 },
        ],
        landmarkPlaces: [
          'Blue Grotto',
          '',
          'Tokyo Skytree',
          'Patuxay Monument',
          'Panfilov Park',
          'Tenaru Falls',
          'Magnus Cathedral',
        ],
        uniqueId: 'R97WbFjy',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sultan-qaboos-grand-mosque-oman-5963726/',
          wikiLink: 'https://en.wikipedia.org/wiki/Oman',
        },
        landmark: {
          name: 'Al Alam Palace',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/oman-muscat-nutmeg-capital-city-5508234/',
          wikiLink: 'https://en.wikipedia.org/wiki/Al_Alam_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Salalah',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Salalah#/media/File:Al_hafa_corniche.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Salalah',
        },
        landmarksRound: [
          { code: 'NP', number: 2 },
          { code: 'BD', number: 2 },
          { code: 'TW', number: 2 },
        ],
        landmarkPlaces: [
          'Baalbek',
          'Floralis Generica',
          'Church of Saint Lazarus',
          'Christ Church Cathedral',
          'Alexander Nevsky Cathedral',
          'Ulu Temburong National Park',
          'Iluissat Icefjord',
        ],
        uniqueId: 'C5yr3BWW',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Muscat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-tower-near-body-of-water-during-daytime-RVA1h5Svl1U',
          wikiLink: 'https://en.wikipedia.org/wiki/Muscat',
        },
        landmark: {
          name: 'Nizwa Fort',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-near-mountain-during-daytime-8eRuJ7Ltyzg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nizwa_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Salalah',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-green-boats-on-calm-body-of-water-5W-KwRBfUoQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Salalah',
        },
        landmarksRound: [
          { code: 'FM', number: 5 },
          { code: 'IT', number: 1 },
          { code: 'KG', number: 2 },
        ],
        landmarkPlaces: [
          'Senado Square',
          'Petra',
          'Palazzo Pubblico',
          'Fouta Djallon',
          'National Shrine Basilica of our Lady of Las Lajas',
          "National Martyrs' Memorial",
          'Kastelholm Castle',
        ],
        uniqueId: 'F3GPsWGv',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Muscat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-formation-on-sea-under-blue-sky-during-daytime-ovfdH_3MudQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Muscat',
        },
        landmark: {
          name: 'Bahla Fort',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-flag-of-the-country-of-jordan-flying-in-the-wind-EWLipvtuZbw',
          wikiLink: 'https://en.wikipedia.org/wiki/Bahla_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Muttrah',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-near-road-during-daytime-fqPNvcjlk5E',
          wikiLink: 'https://en.wikipedia.org/wiki/Muttrah',
        },
        landmarksRound: [
          { code: 'NG', number: 1 },
          { code: 'YT', number: 2 },
          { code: 'AR', number: 2 },
        ],
        landmarkPlaces: [
          'English Harbour',
          'Potala Palace',
          'Detwah Lagoon',
          'Rarotonga',
          'Cape Coast Castle',
          'Salomons Atoll',
          'Plitvice Lakes National Park',
        ],
        uniqueId: 'QJcjcHtT',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Pakistan',
    code: 'PK',
    capital: 'Islamabad',
    lat: 30,
    long: 70,
    cities: [
      'Karachi',
      'Islamabad',
      'Lahore',
      'Peshawar',
      'Faisalabad',
      'Quetta',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/KOwjW3N0Cto',
          wikiLink: 'https://en.wikipedia.org/wiki/Uch',
        },
        landmark: {
          name: 'Badshahi Mosque',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lahore-lhr-badshahi-mosque-2299807/',
          wikiLink: 'https://en.wikipedia.org/wiki/Badshahi_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Karachi',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Karachi#/media/File:Bahadurabad_Area_Karachi_City.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Karachi',
        },
        landmarksRound: [
          { code: 'FJ', number: 1 },
          { code: 'LK', number: 1 },
          { code: 'SG', number: 1 },
        ],
        landmarkPlaces: [
          'Predjama Castle',
          'Tjibaou Cultural Center',
          'Chichen Itza',
          'Twelve Apostles',
          'Buenos Aires Obelisk',
          "Saint Michael's Cave",
          'Rila Monastery',
        ],
        uniqueId: 'jh6NmVw9',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bridge-village-morning-khanpur-961208/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pakistan',
        },
        landmark: {
          name: 'Lahore Fort',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lahore_Fort#/media/File:Lahore_Fort_Top_view.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Lahore_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Islamabad',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Islamabad#/media/File:Faisal_Mosque_in_the_background_of_Centaurus_Mall.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Islamabad',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '9Nykn5zf',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Pakistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-river-running-through-a-snowy-valley-KNtN9TLClVo',
          wikiLink: 'https://en.wikipedia.org/wiki/Pakistan',
        },
        landmark: {
          name: 'Faisal Mosque',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/4-post-museum-under-golden-hour-uc9GjDHj1yA',
          wikiLink: 'https://en.wikipedia.org/wiki/Faisal_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Peshawar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-small-truck-parked-next-to-a-building-6y70tkORY38',
          wikiLink: 'https://en.wikipedia.org/wiki/Peshawar',
        },
        landmarksRound: [
          { code: 'IE', number: 6 },
          { code: 'EG', number: 3 },
          { code: 'DZ', number: 2 },
        ],
        landmarkPlaces: [
          'Notre Dame Auxiliatrice',
          'Gibbs Hill Lighthouse',
          'Ban Gioc–Detian Falls',
          'Charles Bridge',
          'Independence Monument',
          'Christmas Island National Park',
          'Lope National Park',
        ],
        uniqueId: 'mny3ryRH',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Pakistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-mountain-during-daytime-mE-Ls-jlb64',
          wikiLink: 'https://en.wikipedia.org/wiki/Pakistan',
        },
        landmark: {
          name: 'Tomb of Shah Rukn-e-Alam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-brown-concrete-building-under-blue-sky-during-daytime-kyOthi68nMs',
          wikiLink: 'https://en.wikipedia.org/wiki/Tomb_of_Shah_Rukn-e-Alam',
          hasLandmark: true,
        },
        city: {
          name: 'Karachi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-street-filled-with-lots-of-traffic-next-to-tall-buildings-AH3rI-qMFq0',
          wikiLink: 'https://en.wikipedia.org/wiki/Karachi',
        },
        landmarksRound: [
          { code: 'VG', number: 5 },
          { code: 'KN', number: 2 },
          { code: 'BB', number: 4 },
        ],
        landmarkPlaces: [
          'Sans-Souci Palace',
          'Aloba Arch',
          'Boudhanath',
          'Island of Mozambique',
          'Fort Charlotte',
          'Registan Square',
          'Mont Ross',
        ],
        uniqueId: 'w4lCQqTr',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Palau',
    code: 'PW',
    capital: 'Ngerulmud',
    lat: 7.5,
    long: 134.5,
    cities: ['Ngerulmud', 'Koror'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/0lCw_kqmb7w',
          wikiLink: 'https://en.wikipedia.org/wiki/Palau',
        },
        landmark: {
          name: 'Belau National Museum',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Belau_National_Museum#/media/File:A_bai_in_Palau.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Belau_National_Museum',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Nwg55lFd',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/island-water-resort-hotel-2407521/',
          wikiLink: 'https://en.wikipedia.org/wiki/Palau',
        },
        landmark: {
          name: 'Rock Islands',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Rock_Islands#/media/File:Ngerukewid-2016-aerial-view-Luka-Peternel.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Rock_Islands',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'PTqwlctG',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Palau',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/rocks-on-seashore-under-clear-blue-sky-at-daytime-V-DLKzBadVk',
          wikiLink: 'https://ro.wikipedia.org/wiki/Palau',
        },
        landmark: {
          name: 'Jellyfish Lake',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/picturecorrect/14416212092',
          wikiLink: 'https://en.wikipedia.org/wiki/Jellyfish_Lake',
          hasLandmark: true,
        },
        city: {
          name: 'Ngerulmud',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/michael_from_berlin/53318885928/in/photolist-2peBebG-2pez4Sy-2pezCvS-2peGj57-2peGXsP-2peJLAH-2oJMnQE-2oJK16Q-2oJFWnj-2oJMnTk-2oJFWqL-2oJLUaM-2oJLTZb-2oJJZVe-2oJFWud-awCP6u-2iS9Krf-qq8P7z-2iS5nYK-2iS9KBR-2iS5nWW-2iS5o5M-DWvDJg-2iS5nTQ-a19tqY-a19trG-a16By2-6zEYHd-7TeGCb-6yfAbX-9YFn2Y-njEpxo-9Yt2tD-9YyMkn-2prgnwX-9YK3zU-6zEYgj-6zEYvG-9Yt2yK-6zEXTU-9YqvCn-9YK3Cy-9YBBff-6zEYAG-7XYefy-7Tbsft-9Yqv7r-9YtpXb-9YyMbr-6zARLB',
          wikiLink: 'https://en.wikipedia.org/wiki/Ngerulmud',
        },
        landmarksRound: [
          { code: 'CU', number: 6 },
          { code: 'LU', number: 3 },
          { code: 'LY', number: 3 },
        ],
        landmarkPlaces: [
          'Tsingoni Mosque',
          'Easter Island',
          'Kunta Kinteh Island',
          'Castle Cornet',
          'Cathedral of Saint Mother Teresa',
          'Almsee',
          'Arch of Marcus Aurelius',
        ],
        uniqueId: 'h5y67m5C',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Ngerulmud',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/michael_from_berlin/53318574188/in/photolist-2pezCvS-2peGj57-2peGXsP-2peJLAH-2oJMnQE-2oJK16Q-2oJFWnj-2oJMnTk-2oJFWqL-2oJLUaM-2oJLTZb-2oJJZVe-2oJFWud-awCP6u-2iS9Krf-qq8P7z-2iS5nYK-2iS9KBR-2iS5nWW-2iS5o5M-DWvDJg-2iS5nTQ-a19tqY-a19trG-a16By2-6zEYHd-7TeGCb-6yfAbX-9YFn2Y-njEpxo-9Yt2tD-9YyMkn-2prgnwX-9YK3zU-6zEYgj-6zEYvG-9Yt2yK-6zEXTU-9YqvCn-9YK3Cy-9YBBff-6zEYAG-7XYefy-7Tbsft-9Yqv7r-9YtpXb-9YyMbr-6zARLB-26X319e-njz9yX',
          wikiLink: 'https://en.wikipedia.org/wiki/Ngerulmud',
        },
        landmark: {
          name: 'Ngardmau Waterfalls',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/anwar-nillufary/31720708592/in/photolist-Qk3VwQ-PZ8ooA-PgPT2U-Dbsue6-PgNQKw-5QUt2m-PgNVFU-Dm6Qdj-bZA2BW-egByvF-E3C2Bh-c5zo2m-22LDkR4-dL3Dfz-6Xm41S-6Xh6Rn-9Vu7ys-imkh9e-6Xm3qE-dnecGb-sADENM-7Mx7Pr-aV5mTt-qN9cSJ-rfETFF-7JngN2-7Jr2Vs-o3AGWq-sADKgV-aia4Be-6BGmnB-5g7FKo-dndVWR-7Wg5CP-487iE-c5zmnf-eixqfA-c6SLwd-fQcHdJ-egHcrS-dSoizw-o3HtWX-cguyj7-cguxUf-9a6nUu-c6SQFN-aiT6AL-F95H6h-bogb2-5a5gGP',
          wikiLink: 'https://en.wikipedia.org/wiki/Ngardmau',
          hasLandmark: true,
        },
        city: {
          name: 'Koror',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-island-in-the-middle-of-a-body-of-water-9uZAo969KCg',
          wikiLink: 'https://en.wikipedia.org/wiki/Koror',
        },
        landmarksRound: [
          { code: 'YT', number: 3 },
          { code: 'CX', number: 4 },
          { code: 'AM', number: 6 },
        ],
        landmarkPlaces: [
          'Seven Coloured Earths',
          'The Pitcairn Museum',
          'Kylemore Abbey',
          'Chishimba Falls',
          'Basilique Marie-Reine-des-Apôtres',
          'National Museum of Montserrat',
          'Vianden Castle',
        ],
        uniqueId: 'n7rN28hm',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Palestine',
    code: 'PS',
    capital: 'Ramallah',
    lat: 31.9,
    long: 35.2,
    cities: ['Gaza ', 'Nablus', 'Ramallah'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jerusalem-israel-old-town-walls-1712855/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerusalem',
        },
        landmark: {
          name: 'Western Wall and the Dome of the Rock',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jerusalem-western-wall-temple-4592574/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dome_of_the_Rock',
          hasLandmark: false,
        },
        city: {
          name: 'Nablus',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nablus#/media/File:Nablus,_January_2022.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nablus',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'xdsz3swY',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pxhere.com/en/photo/1598181',
          wikiLink: 'https://en.wikipedia.org/wiki/Palestine_(region)',
        },
        landmark: {
          name: '',
          photographer: '',
          imageLink: '',
          wikiLink: '',
          hasLandmark: false,
        },
        city: {
          name: 'Gaza City',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Gaza_City#/media/File:Gaza_City.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Gaza_City',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'F4PKvMJJ',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Palestine',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-sea-near-red-and-white-lighthouse-under-white-clouds-during-daytime-l41hUeMwQ-8',
          wikiLink: 'https://en.wikipedia.org/wiki/State_of_Palestine',
        },
        landmark: {
          name: 'Al-Aqsa Mosque',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-and-beige-dome-building-under-blue-sky-during-daytime-L6eC5CMAj-k',
          wikiLink: 'https://en.wikipedia.org/wiki/Al-Aqsa_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Gaza ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-very-large-building-that-has-a-bunch-of-rubble-on-it-usO7cawa_XQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Gaza_City',
        },
        landmarksRound: [
          { code: 'TJ', number: 1 },
          { code: 'BG', number: 2 },
          { code: 'GU', number: 6 },
        ],
        landmarkPlaces: [
          'Sri Siva Subramaniya Temple',
          'La Soufriere',
          'Mont Ross',
          'Seven Colored Earths',
          'Boudhanath',
          'Tsingy De Bemaraha',
          'Fortín de La Galera',
        ],
        uniqueId: 'y6Fqwd6M',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Jerusalem',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-the-top-of-a-hill-lvyDnUkCmWw',
          wikiLink: 'https://en.wikipedia.org/wiki/Jerusalem',
        },
        landmark: {
          name: 'Western Wall',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-gathered-near-pole-d_so1tRFKJk',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Wall',
          hasLandmark: true,
        },
        city: {
          name: 'Nablus',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-on-parking-lot-during-daytime-TqMtUYlDKdI',
          wikiLink: 'https://en.wikipedia.org/wiki/Nablus',
        },
        landmarksRound: [
          { code: 'KG', number: 3 },
          { code: 'VU', number: 2 },
          { code: 'CK', number: 5 },
        ],
        landmarkPlaces: [
          'Agadez Grand Mosque',
          'Koutoubia',
          'Moroccan sand wall',
          'Chimi Lhakhang',
          'Fortín de La Galera',
          'The Basilica of Our Lady of Peace of Yamoussoukro',
          'Arco de Santa Catalina',
        ],
        uniqueId: 'p6C2fmcM',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Panama',
    code: 'PA',
    capital: 'Panama City',
    lat: 9,
    long: -80,
    cities: [
      'Colon',
      'Panama City',
      'Tocumen',
      'David',
      'Ancon',
      'Santiago',
      'San Miguelito',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/8oMiql-Q9nM',
          wikiLink: 'https://en.wikipedia.org/wiki/Casco_Viejo,_Panama',
        },
        landmark: {
          name: 'Old Panama',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Panam%C3%A1_Viejo#/media/File:Iglesia_y_Convento_de_las_Monjas_de_la_Concepci%C3%B3n,_Panam%C3%A1_Viejo.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Panam%C3%A1_Viejo',
          hasLandmark: true,
        },
        city: {
          name: 'Colon',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Col%C3%B3n,_Panama#/media/File:CO-colon-2000-02.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Col%C3%B3n,_Panama',
        },
        landmarksRound: [
          { code: 'MW', number: 1 },
          { code: 'BY', number: 1 },
          { code: 'NA', number: 1 },
        ],
        landmarkPlaces: [
          'Pigeon Rocks',
          'Belem Tower',
          'Golden Gate',
          'Oriental Pearl TV Tower',
          'Hazrat Ali Mazar',
          'Valle de Cocora',
          'Parthenon',
        ],
        uniqueId: 'F85rWmJW',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/beach-san-blas-islands-panama-4388225/',
          wikiLink: 'https://en.wikipedia.org/wiki/Panama',
        },
        landmark: {
          name: 'Panama Canal',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/panama-panamericana-bridge-4953251/',
          wikiLink: 'https://en.wikipedia.org/wiki/Panama_Canal',
          hasLandmark: true,
        },
        city: {
          name: 'Panama City',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/panama-bay-architecture-skyline-182959/',
          wikiLink: 'https://en.wikipedia.org/wiki/Panama_City',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'VrQGxvJV',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'San Blas Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/canoe-on-beach-V-uYocR8k8k',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Blas_Islands',
        },
        landmark: {
          name: 'Panamá Viejo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/124877198@N04/15647168806/',
          wikiLink: 'https://en.wikipedia.org/wiki/Panam%C3%A1_Viejo',
          hasLandmark: true,
        },
        city: {
          name: 'San Miguelito',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/brennwald/25573532781/',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Miguelito',
        },
        landmarksRound: [
          { code: 'MU', number: 3 },
          { code: 'UG', number: 3 },
          { code: 'GY', number: 6 },
        ],
        landmarkPlaces: [
          'Europa Point Lighthouse',
          'Shell Beach',
          'National Park of American Samoa',
          'Liberation Tower',
          'Voortrekker Monument',
          'Đurđevića Tara Bridge',
          'Neuschwanstein Castle',
        ],
        uniqueId: 'v3ZxPH22',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Panama',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-floating-cottage-during-daytiem-FR3pMnh6xCA',
          wikiLink: 'https://en.wikipedia.org/wiki/Panama',
        },
        landmark: {
          name: 'Chagres and Fort San Lorenzo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/125990078@N06/21988251631/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Chagres_and_Fort_San_Lorenzo',
          hasLandmark: true,
        },
        city: {
          name: 'Panama City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/panama-veki-bus-on-highway-beside-high-rise-building-during-night-time-_HmXOCgpnnI',
          wikiLink: 'https://en.wikipedia.org/wiki/Panama_City',
        },
        landmarksRound: [
          { code: 'GT', number: 3 },
          { code: 'PW', number: 1 },
          { code: 'MD', number: 1 },
        ],
        landmarkPlaces: [
          'Port-aux-Français',
          'Ouagadougou Cathedral',
          'Amber Palace',
          'Cité de Carcassonne',
          'Pashupatinath Temple',
          'Muri Lagoon',
          'High Knoll Fort',
        ],
        uniqueId: 'm96KWk9Q',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    capital: 'Port Moresby',
    lat: -6,
    long: 147,
    cities: ['Port Moresby', 'Wabag'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/GOIR5V_OH-k',
          wikiLink: 'https://en.wikipedia.org/wiki/Papua_New_Guinea',
        },
        landmark: {
          name: 'Mount Wilhelm',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mountains-lakes-nature-landscape-5960985/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Wilhelm',
          hasLandmark: true,
        },
        city: {
          name: 'Lae',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lae#/media/File:Lae-CityPanorama.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lae',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '76F2mm9z',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rabaul-volcano-papua-new-guinea-4380826/',
          wikiLink: 'https://en.wikipedia.org/wiki/Papua_New_Guinea',
        },
        landmark: {
          name: 'Kokoda Track',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Kokoda_track_Papua_New_Guinea.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Kokoda_Track',
          hasLandmark: true,
        },
        city: {
          name: 'Port Moresby',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Port_Moresby#/media/File:POM_Downtown.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Moresby',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Q99jcmZ7',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Papua New Guinea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-island-surrounded-by-water-during-daytime-n1LrwXzsnuU',
          wikiLink: 'https://en.wikipedia.org/wiki/Papua_New_Guinea',
        },
        landmark: {
          name: 'Tavurvur Volcano',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/arizzotto/3388412958/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tavurvur',
          hasLandmark: true,
        },
        city: {
          name: 'Port Moresby',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/184033345@N08/50981911296/',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Moresby',
        },
        landmarksRound: [
          { code: 'SM', number: 1 },
          { code: 'LT', number: 5 },
          { code: 'ET', number: 2 },
        ],
        landmarkPlaces: [
          'Qalito Island',
          'Iguazu Falls',
          'Mumbo Island',
          'Blue Lagoon',
          'Salomon Islands',
          'Monteverde Cloud Forest',
          'Kpatawee Falls',
        ],
        uniqueId: 'Z5kRsWfs',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Papua New Guinea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-person-riding-a-bike-down-the-middle-of-a-road-SrRfS_VQHeM',
          wikiLink: 'https://en.wikipedia.org/wiki/Papua_New_Guinea',
        },
        landmark: {
          name: 'War Cemetery',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/195258938@N02/52655469557/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Port_Moresby_(Bomana)_War_Cemetery',
          hasLandmark: true,
        },
        city: {
          name: 'Wabag',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-green-building-sitting-on-the-side-of-a-road-OxN4Tila71g',
          wikiLink: 'https://en.wikipedia.org/wiki/Wabag',
        },
        landmarksRound: [
          { code: 'IE', number: 4 },
          { code: 'AQ', number: 4 },
          { code: 'YE', number: 1 },
        ],
        landmarkPlaces: [
          'Holy Family Cathedral',
          'Charles Bridge',
          'Tikal Temple I',
          'Hato Caves',
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Pukapuka',
          'Nizwa Fort',
        ],
        uniqueId: 'c2b2kfps',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Paraguay',
    code: 'PY',
    capital: 'Asuncion',
    lat: -23,
    long: -58,
    cities: [
      'Ciudad del Este',
      'Asuncion',
      'San Lorenzo',
      'Nemby',
      'Encarnacion',
      'Capiata',
      'Asunción',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/5zqhGcQTMQU',
          wikiLink: 'https://en.wikipedia.org/wiki/Aregu%C3%A1',
        },
        landmark: {
          name: 'Monday Falls',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Saltos_del_Monday#/media/File:Salto_Monday_Paraguay.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saltos_del_Monday',
          hasLandmark: true,
        },
        city: {
          name: 'Asuncion',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ciudad_del_Este#/media/File:CDE011.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ciudad_del_Este',
        },
        landmarksRound: [
          { code: 'CO', number: 2 },
          { code: 'MW', number: 1 },
          { code: 'AM', number: 2 },
        ],
        landmarkPlaces: [
          'Panfilov Park',
          'Chillon Castle',
          'African Renaissance Monument',
          'Kim Il Sung Square',
          'Silver Pagoda',
          'Tsingy De Bemaraha',
          'Pulhapanzak Waterfalls',
        ],
        uniqueId: '6tjtRWTf',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/road-rain-automobile-woman-184623/',
          wikiLink: 'https://en.wikipedia.org/wiki/Paraguay',
        },
        landmark: {
          name: 'Jesuit Mission of the Holy Trinity',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/La_Sant%C3%ADsima_Trinidad_de_Paran%C3%A1#/media/File:Trinidad_(Paraguay).JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/La_Sant%C3%ADsima_Trinidad_de_Paran%C3%A1',
          hasLandmark: true,
        },
        city: {
          name: 'Ciudad del Este',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ciudad_del_Este#/media/File:CDE011.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ciudad_del_Este',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'jBF97XLL',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Paraguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-boat-in-the-water-TYVAt_z5Zyw',
          wikiLink: 'https://en.wikipedia.org/wiki/Paraguay',
        },
        landmark: {
          name: 'La Santísima Trinidad de Paraná',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/bahayla/1129663590/',
          wikiLink:
            'https://en.wikipedia.org/wiki/La_Sant%C3%ADsima_Trinidad_de_Paran%C3%A1',
          hasLandmark: true,
        },
        city: {
          name: 'Ciudad del Este',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/tight-traffic-in-the-middle-of-city-during-cloudy-day-ms9UH0mfnLY',
          wikiLink: 'https://en.wikipedia.org/wiki/Ciudad_del_Este',
        },
        landmarksRound: [
          { code: 'US', number: 4 },
          { code: 'HT', number: 5 },
          { code: 'NZ', number: 2 },
        ],
        landmarkPlaces: [
          'Castle Cornet',
          'Ostrog Monastery',
          'Obelisk of Axum',
          'Arch of Marcus Aurelius',
          "Dunn's River Falls",
          'Shwedagon Pagoda',
          'Chamarel Waterfall',
        ],
        uniqueId: 'Y93vJLZH',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Paraguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-cloudy-sky-during-daytime-AZ7fAnvI3QY',
          wikiLink: 'https://en.wikipedia.org/wiki/Paraguay',
        },
        landmark: {
          name: 'National Pantheon of the Heroes',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/sulitskiy/19084550075/',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_Pantheon_of_the_Heroes#:~:text=The%20National%20Pantheon%20of%20the,Paraguay%2C%20and%20a%20national%20monument',
          hasLandmark: true,
        },
        city: {
          name: 'Asunción',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-many-buildings-2e6IkM-6Z1U',
          wikiLink: 'https://en.wikipedia.org/wiki/Asunci%C3%B3n',
        },
        landmarksRound: [
          { code: 'MC', number: 3 },
          { code: 'GB', number: 2 },
          { code: 'GI', number: 3 },
        ],
        landmarkPlaces: [
          'Panama Canal',
          'Suicide Cliff',
          'Tsavo East National Park',
          'Cap 110',
          'Epupa Falls',
          'Neveh Shalom Synagogue',
          'Irazú Volcano',
        ],
        uniqueId: 'LdQGP355',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Peru',
    code: 'PE',
    capital: 'Lima',
    lat: -10,
    long: -76,
    cities: [
      'Arequipa',
      'Lima',
      'Cusco',
      'Cajamarca',
      'Huancayo',
      'Chiclayo',
      'Puno',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/hjanvZlqoB8',
          wikiLink: 'https://en.wikipedia.org/wiki/Vinicunca',
        },
        landmark: {
          name: 'Machu Picchu',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/machupicchu-peru-i-ncas-1138641/',
          wikiLink: 'https://en.wikipedia.org/wiki/Machu_Picchu',
          hasLandmark: true,
        },
        city: {
          name: 'Arequipa',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/square-arequipa-peru-cathedral-5165633/',
          wikiLink: 'https://en.wikipedia.org/wiki/Arequipa',
        },
        landmarksRound: [
          { code: 'DZ', number: 1 },
          { code: 'ME', number: 1 },
          { code: 'MO', number: 2 },
        ],
        landmarkPlaces: [
          'Trevi Fountain',
          'Red Square',
          'Chinguetti',
          'The Blue Eye',
          'Akershus Fortress',
          'Amber Palace',
          'Seven Colored Earths',
        ],
        uniqueId: 'VvQ6slll',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/peru-tourism-cuzco-landscape-5143632/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cusco',
        },
        landmark: {
          name: 'Saqsayhuaman',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sacsayhuam%C3%A1n#/media/File:Sacsayhuam%C3%A1n,_Cusco,_Per%C3%BA,_2015-07-31,_DD_05.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Sacsayhuam%C3%A1n',
          hasLandmark: true,
        },
        city: {
          name: 'Lima',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lima-cityscape-city-sunset-peru-1157119/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lima',
        },
        landmarksRound: [
          { code: 'AM', number: 2 },
          { code: 'CO', number: 1 },
          { code: 'VI', number: 2 },
        ],
        landmarkPlaces: [
          'Marcus Aurelius Arch',
          'Boudhanath',
          'Easter Island',
          'Niagara Falls',
          'Amberd',
          'Azadi Tower',
          'Souq Waqif',
        ],
        uniqueId: 'fKyvWMGF',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Peru',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-with-green-mountains-during-daytime-hgxr9EbYZP0',
          wikiLink: 'https://en.wikipedia.org/wiki/Peru',
        },
        landmark: {
          name: 'Lake Titicaca',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-near-brown-thatched-houses-across-river-xq0MW-DM6Cc',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Titicaca',
          hasLandmark: true,
        },
        city: {
          name: 'Puno',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-small-town-in-the-desert-WDnSRJBVAyE',
          wikiLink: 'https://en.wikipedia.org/wiki/Puno?variant=zh-cn',
        },
        landmarksRound: [
          { code: 'KN', number: 2 },
          { code: 'GD', number: 4 },
          { code: 'RS', number: 2 },
        ],
        landmarkPlaces: [
          'Linnahall',
          'Alte Rheinbrücke',
          'Sant Joan de Caselles',
          'Chinguetti',
          'Blue Mountains',
          'Laferriere Citadel',
          'Đurđevića Tara Bridge',
        ],
        uniqueId: 'pb3sP3X5',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Machu Picchu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-4-legged-farm-animal-standing-on-grass-ulUnRNuC_ok',
          wikiLink: 'https://en.wikipedia.org/wiki/Machu_Picchu',
        },
        landmark: {
          name: 'Machu Picchu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-mountain-EKNe678ktEY',
          wikiLink: 'https://en.wikipedia.org/wiki/Machu_Picchu',
          hasLandmark: true,
        },
        city: {
          name: 'Lima',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/road-near-body-of-water-and-buildings-at-daytime-mZKEIRBStv4',
          wikiLink: 'https://en.wikipedia.org/wiki/Lima',
        },
        landmarksRound: [
          { code: 'ZW', number: 1 },
          { code: 'LT', number: 1 },
          { code: 'TC', number: 3 },
        ],
        landmarkPlaces: [
          'Quilotoa',
          'Sistine Chapel',
          'Jardin Botanique de Deshaies',
          'Charyn Canyon',
          'Liberian National Museum',
          'Chinguetti Mosque',
          'Valle de Cocora',
        ],
        uniqueId: 'l76XD93J',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Philippines',
    code: 'PH',
    capital: 'Manila',
    lat: 13,
    long: 122,
    cities: [
      'Cebu City',
      'Manila',
      'Quezon City',
      'Davao City',
      'Caloocan',
      'Taguig',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/n6BxzwOGTC4',
          wikiLink: 'https://en.wikipedia.org/wiki/Bontoc,_Mountain_Province',
        },
        landmark: {
          name: 'Rizal Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Rizal_Park#/media/File:Rizal_Park_Front_View.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Rizal_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Cebu City',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cebu_City#/media/File:Cebu_City_(Aerial).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cebu_City',
        },
        landmarksRound: [
          { code: 'ID', number: 1 },
          { code: 'CY', number: 2 },
          { code: 'GS', number: 2 },
        ],
        landmarkPlaces: [
          'Villa Folio',
          'Monument to the Heroes of the Restoration',
          'Castillo San Cristobal',
          'Vianden Castle',
          'Rock of Cashel',
          'Macau Tower',
          'Mount Nyriagongo',
        ],
        uniqueId: 'KJVGLHSY',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/pacific-rainbow-ocean-clouds-4491547/',
          wikiLink: 'https://en.wikipedia.org/wiki/Philippines',
        },
        landmark: {
          name: 'Fort Santiago',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort_Santiago#/media/File:Entrance_of_Fort_Santiago,_Intramuros,_Manila_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Santiago',
          hasLandmark: true,
        },
        city: {
          name: 'Manila',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/manila-city-manila-bay-night-sea-1709394/',
          wikiLink: 'https://en.wikipedia.org/wiki/Manila',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'rN3zWlfd',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'El Nido',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/reynisdrangar-cliff-under-blue-sky-during-daytime-nuNkbkQQf9U',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Nido,_Palawan',
        },
        landmark: {
          name: 'The Ruins',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/low-angle-photography-of-brown-concrete-2-storey-building-between-trees-QsRG5VAPNQo',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Ruins_(mansion)',
          hasLandmark: true,
        },
        city: {
          name: 'Quezon City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-old-bus-parked-on-the-side-of-the-road-eflWruRVQ_M',
          wikiLink: 'https://en.wikipedia.org/wiki/Quezon_City',
        },
        landmarksRound: [
          { code: 'AR', number: 2 },
          { code: 'MR', number: 5 },
          { code: 'TD', number: 3 },
        ],
        landmarkPlaces: [
          'Souq Waqif',
          'Karlštejn Castle',
          'Demerara Bridge',
          'Great Mosque of Porto-Novo\n',
          'Blue Mountains',
          'Tash Rabat',
          'Abuja National Mosque',
        ],
        uniqueId: 'gtnBPKfs',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Bohol',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-sandy-beach-with-palm-trees-in-the-background-lA-I1uW-xek',
          wikiLink: 'https://en.wikipedia.org/wiki/Bohol',
        },
        landmark: {
          name: 'Fort Santiago',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/kcpaige/8300045846/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Santiago',
          hasLandmark: true,
        },
        city: {
          name: 'Manila',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-at-daytime-SKTHSf53KYc',
          wikiLink: 'https://en.wikipedia.org/wiki/Manila',
        },
        landmarksRound: [
          { code: 'KZ', number: 2 },
          { code: 'GP', number: 5 },
          { code: 'HU', number: 2 },
        ],
        landmarkPlaces: [
          'St. Barnabas Chapel',
          'Altos de Chavón',
          'Cliffs of Moher',
          'Basilica of Our Lady of Guadalupe',
          'Galaxy Macau',
          'Noravank Monastery',
          "Jacob's Ladder",
        ],
        uniqueId: 'dTzYLxsB',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Pitcairn Islands',
    code: 'PN',
    capital: 'Adamstown',
    lat: -25.06666666,
    long: -130.1,
    cities: ['Adamstown'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/doublenz/10531933983',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
        landmark: {
          name: "Christian's Cave",
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:%22Christian%27s_Cave%22_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'ZD7NPQDb',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Pitcairn_Island_In_The_Distance.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
        landmark: {
          name: 'Ducie Island',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ducie_Island#/media/File:Ducie02_AKK.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ducie_Island',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'cxnWs5yz',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Pitcairn Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/s_k_brown/48808216266/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
        landmark: {
          name: 'The Pitcairn Museum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/4864744569',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Island_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Adamstown',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Adamstown,_Pitcairn_Islands#/media/File:Adamstown1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Adamstown,_Pitcairn_Islands',
        },
        landmarksRound: [
          { code: 'GW', number: 1 },
          { code: 'IS', number: 1 },
          { code: 'JO', number: 2 },
        ],
        landmarkPlaces: [
          'Kathmandu Durbar Square',
          'Liberian National Museum',
          'Fasil Ghebbi',
          'Angel Falls',
          'Taputapuatea marae',
          'Fouta Djallon',
          'Tarawa',
        ],
        uniqueId: 'k5LnV72v',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Pitcairn Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/peterfarr/27923976992',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
        landmark: {
          name: 'Henderson Island',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Henderson_Island_(Pitcairn_Islands)#/media/File:Henderson.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Henderson_Island_(Pitcairn_Islands)',
          hasLandmark: true,
        },
        city: {
          name: 'Adamstown',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Adamstown#/media/File:Adamstown.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Adamstown',
        },
        landmarksRound: [
          { code: 'IM', number: 2 },
          { code: 'CI', number: 2 },
          { code: 'GU', number: 4 },
        ],
        landmarkPlaces: [
          'Pura Ulun Danu Bratan',
          'Allahverdi Khan Bridge',
          'Múlafossur Waterfall',
          'Semuc Champey',
          'Elmina Castle',
          'Stone House',
          'Hvalsey Church',
        ],
        uniqueId: 'yxlkLzw7',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Pitcairn Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/tonyprobst/5603232230/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
        landmark: {
          name: 'The Pitcairn Museum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/30593522@N05/6776644944/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Island_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Adamstown',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/doublenz/10531933983/',
          wikiLink: 'https://en.wikipedia.org/wiki/Adamstown,_Pitcairn_Islands',
        },
        landmarksRound: [
          { code: 'VC', number: 2 },
          { code: 'MM', number: 1 },
          { code: 'BH', number: 4 },
        ],
        landmarkPlaces: [
          'Alexander Nevsky Cathedral',
          'Walzin Castle ',
          'Rhine Falls',
          'Mount Nyriagongo',
          'Cathedral Our Lady of the Rosary',
          'Rijksmuseum',
          'Chishimba Falls',
        ],
        uniqueId: '2f8qHStq',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Pitcairn Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/4867915775/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitcairn_Islands',
        },
        landmark: {
          name: 'Henderson Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/5820398232/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Henderson_Island_(Pitcairn_Islands)',
          hasLandmark: true,
        },
        city: {
          name: 'Adamstown',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/up70mm/4868461596/',
          wikiLink: 'https://en.wikipedia.org/wiki/Adamstown',
        },
        landmarksRound: [
          { code: 'CD', number: 1 },
          { code: 'BM', number: 1 },
          { code: 'EC', number: 5 },
        ],
        landmarkPlaces: [
          'Nara Park',
          'The Pearl Monument',
          'Lake Yoa',
          'The Little Chapel',
          'Chorsu Bazaar',
          'Crystal Caves',
          'Blue Lagoon',
        ],
        uniqueId: '3zSbjcrB',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Poland',
    code: 'PL',
    capital: 'Warsaw',
    lat: 52,
    long: 20,
    cities: [
      'Warsaw',
      'Gdansk',
      'Poznan',
      'Wroclaw',
      'Zakopane',
      'Krakow',
      'Gdańsk',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/i-p1zqFros8',
          wikiLink: 'https://en.wikipedia.org/wiki/Main_Square,_Krak%C3%B3w',
        },
        landmark: {
          name: 'Wieliczka Salt Mine',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/wieliczka-salt-mine-church-mine-4482528/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wieliczka_Salt_Mine',
          hasLandmark: true,
        },
        city: {
          name: 'Krakow',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/street-tower-krakow-poland-tourism-4472321/',
          wikiLink: 'https://en.wikipedia.org/wiki/Krak%C3%B3w',
        },
        landmarksRound: [
          { code: 'SY', number: 2 },
          { code: 'GH', number: 2 },
          { code: 'KN', number: 2 },
        ],
        landmarkPlaces: [
          'Amalienborg',
          'Bled Castle',
          'Stonehenge',
          'Pigeon Rocks',
          'Cliffs of Moher',
          'La Sagrada Familia',
          'Golden Gate',
        ],
        uniqueId: 'C5Xm5Zhy',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Gqc1jl2kd1U',
          wikiLink: 'https://en.wikipedia.org/wiki/Poland',
        },
        landmark: {
          name: 'Wawel Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/royal-cathedral-wawel-royal-castle-601223/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wawel_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Warsaw',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/warsaw-the-old-town-sunset-evening-823079/',
          wikiLink: 'https://en.wikipedia.org/wiki/Warsaw',
        },
        landmarksRound: [
          { code: 'CH', number: 2 },
          { code: 'BH', number: 2 },
          { code: 'CN', number: 2 },
        ],
        landmarkPlaces: [
          'Okavango Delta',
          'Liwonde National Park',
          'Palace of Versailles',
          'Fort Fincastle',
          'Deadvlei',
          'Kittiwake Shipwreck',
          'Wat Xiengthong',
        ],
        uniqueId: 'z65pzymh',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kraków',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-near-beige-building-7rCdqEuQ1rg',
          wikiLink: 'https://en.wikipedia.org/wiki/Krak%C3%B3w',
        },
        landmark: {
          name: 'Royal Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/charles79/14789115714/',
          wikiLink: 'https://en.wikipedia.org/wiki/Royal_Castle,_Warsaw',
          hasLandmark: true,
        },
        city: {
          name: 'Gdańsk',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-boat-docked-near-buildings-KcjKvX7w8YQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Gda%C5%84sk',
        },
        landmarksRound: [
          { code: 'GG', number: 2 },
          { code: 'AL', number: 1 },
          { code: 'FK', number: 2 },
        ],
        landmarkPlaces: [
          'Lake Lalolalo',
          'Deadvlei',
          'Santa Catalina Arch',
          "King's Palace Museum",
          'Moroccan sand wall',
          'Al Saleh Mosque',
          'Nan Madol',
        ],
        uniqueId: 'QPKBMRLq',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Poland',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/black-horses-i-p1zqFros8',
          wikiLink: 'https://en.wikipedia.org/wiki/Poland',
        },
        landmark: {
          name: 'Kraków Cloth Hall',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-lot-of-birds-around-it-iLr6iT9buiQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Krak%C3%B3w_Cloth_Hall',
          hasLandmark: true,
        },
        city: {
          name: 'Warsaw',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-people-on-street-near-the-brown-concrete-building-u2GVjG8Ajig',
          wikiLink: 'https://en.wikipedia.org/wiki/Warsaw',
        },
        landmarksRound: [
          { code: 'JO', number: 1 },
          { code: 'EE', number: 6 },
          { code: 'IR', number: 1 },
        ],
        landmarkPlaces: [
          'Jardin de Balata',
          'St. James and Our Lady of the Pillar Cathedral',
          'Macau Tower',
          'Ħal Saflieni Hypogeum',
          'Mount Kenya',
          'Rundetaarn',
          "Jame' Asr Hassanil Bolkiah Mosque",
        ],
        uniqueId: 'Ftm9s3kt',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Portugal',
    code: 'PT',
    capital: 'Lisbon',
    lat: 39.5,
    long: -8,
    cities: ['Porto', 'Braga', 'Lisbon', 'Faro', 'Aveiro', 'Evora'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ljhCEaHYWJ8',
          wikiLink: 'https://en.wikipedia.org/wiki/Trams_in_Lisbon',
        },
        landmark: {
          name: 'Belem Tower',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bel%c3%a9m-tower-belem-tower-lisbon-2809818/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bel%C3%A9m_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Porto',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/portugal-porto-eifel-bridge-1350646/',
          wikiLink: 'https://en.wikipedia.org/wiki/Porto',
        },
        landmarksRound: [
          { code: 'BB', number: 1 },
          { code: 'UA', number: 2 },
          { code: 'BY', number: 1 },
        ],
        landmarkPlaces: [
          'Langkawi Sky Bridge',
          'Jardin de Balata',
          'Old Panama',
          'Herat Citadel',
          'Arc de Triomphe',
          'Chorsu Bazaar',
          "St. Tryphon's Cathedral",
        ],
        uniqueId: 'lHK5Lf9h',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tram-train-road-building-monument-4379656/',
          wikiLink: 'https://en.wikipedia.org/wiki/Portugal',
        },
        landmark: {
          name: 'Luis I Bridge',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bridge-city-river-dom-luis-i-bridge-6819792/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dom_Lu%C3%ADs_I_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Braga',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bridge-house-village-city-5753249/',
          wikiLink: 'https://en.wikipedia.org/wiki/Braga',
        },
        landmarksRound: [
          { code: 'LT', number: 1 },
          { code: 'VA', number: 1 },
          { code: 'HU', number: 2 },
        ],
        landmarkPlaces: [
          "St. Olaf's Church",
          "Dunn's River Falls",
          'Arecibo Observatory',
          'Anne Frank House',
          'Ouzoud Falls',
          'Mir Castle',
          'Dragon and Tiger Pagodas',
        ],
        uniqueId: 'MVs7mY68',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Lisbon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/train-passing-in-between-buildings-Bg8atM-RVuw',
          wikiLink: 'https://en.wikipedia.org/wiki/Lisbon',
        },
        landmark: {
          name: 'São Jorge Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/lightmeister/34823081495/',
          wikiLink: 'https://en.wikipedia.org/wiki/S%C3%A3o_Jorge_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Lisbon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-white-patio-umbrellas-near-pink-and-white-painted-concrete-building-5ZB0w0vSgPk',
          wikiLink: 'https://en.wikipedia.org/wiki/Lisbon',
        },
        landmarksRound: [
          { code: 'GP', number: 5 },
          { code: 'BR', number: 6 },
          { code: 'PS', number: 4 },
        ],
        landmarkPlaces: [
          'Mont Ross',
          'Grand Mosque',
          'Đurđevića Tara Bridge',
          'Freedom Park',
          'Muzeu Historik Kombëtar',
          'Olavinlinna',
          'The Pinnacles Desert',
        ],
        uniqueId: 'h7Jf5BZZ',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Portugal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-mountain-near-sea-cWdefpoj3PU',
          wikiLink: 'https://en.wikipedia.org/wiki/Portugal',
        },
        landmark: {
          name: 'Porto Cathedral',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/28938413@N03/17055450414/',
          wikiLink: 'https://en.wikipedia.org/wiki/Porto_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Porto',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-docked-near-seaside-promenade-Prb-sjOUBFs',
          wikiLink: 'https://en.wikipedia.org/wiki/Porto',
        },
        landmarksRound: [
          { code: 'MR', number: 1 },
          { code: 'NF', number: 2 },
          { code: 'ER', number: 6 },
        ],
        landmarkPlaces: [
          'Gediminas Castle Tower',
          'Moulin Bézard',
          'Brest Fortress',
          'St. Alexander Nevsky Cathedral',
          'Lighthouse Dona Maria Pia',
          'War Cemetery',
          'Po Lin Monastery ',
        ],
        uniqueId: 'WxSDbf5w',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    capital: 'San Juan',
    lat: 18.25,
    long: -66.5,
    cities: [
      'San Juan',
      'Bayamon',
      'Utuado',
      'Carolina',
      'Mayaguez',
      'Bayamón',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/48iZAbNgU8U',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico',
        },
        landmark: {
          name: 'Castillo San Cristobal',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Castillo_San_Crist%C3%B3bal_%28Fort_San_Crist%C3%B3bal%29_%285422458974%29.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Castillo_San_Crist%C3%B3bal_(San_Juan)',
          hasLandmark: true,
        },
        city: {
          name: 'San Juan',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:San_Juan_2015.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico',
        },
        landmarksRound: [
          { code: 'GB', number: 1 },
          { code: 'JM', number: 2 },
          { code: 'CY', number: 1 },
        ],
        landmarkPlaces: [
          'Brandenburg Gate',
          'Green Grotto Caves',
          'Koutoubia',
          'Forbidden City',
          'Cathedral of Brasilica',
          'Romney Manor',
          "Prince's Palace of Monaco",
        ],
        uniqueId: 'b6TmcYlX',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/graveyard-san-juan-puerto-rico-602357/',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico',
        },
        landmark: {
          name: 'Arecibo Observatory',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Arecibo_Radiotelescopio_SJU_06_2019_7472.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Arecibo_Observatory',
          hasLandmark: true,
        },
        city: {
          name: 'Bayamon',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Barrio_Hato_Tejas,_Bayam%C3%B3n,_Puerto_Rico.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bayam%C3%B3n,_Puerto_Rico',
        },
        landmarksRound: [
          { code: 'CH', number: 1 },
          { code: 'BH', number: 1 },
          { code: 'MR', number: 1 },
        ],
        landmarkPlaces: [
          'Stone House',
          'Hagia Sophia',
          'Mount Paget',
          'The Grand Palace',
          'Great Blue Hole',
          'National Shrine Basilica of our Lady of Las Lajas',
          'House of the Black Heads',
        ],
        uniqueId: 'FXrqtvws',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'San Juan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-near-body-of-water-during-daytime-48iZAbNgU8U',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico',
        },
        landmark: {
          name: 'El Yunque National Forest',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/El_Yunque_National_Forest#/media/File:Puerto_Rico_El_Yunque_1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Yunque_National_Forest',
          hasLandmark: true,
        },
        city: {
          name: 'Bayamón',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:Bayamon_Puerto_Rico_aerial_view.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bayam%C3%B3n,_Puerto_Rico',
        },
        landmarksRound: [
          { code: 'SI', number: 1 },
          { code: 'FK', number: 1 },
          { code: 'LT', number: 1 },
        ],
        landmarkPlaces: [
          'Iluissat Icefjord',
          'Demerara Bridge',
          'Ilulissat Icefjord',
          "Christian's Cave",
          'Petra',
          'Fort Napoléon des Saintes',
          'Plaza Francia',
        ],
        uniqueId: 'z449h98S',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Arecibo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-and-trees-during-daytime-HaFV1cviJ_4',
          wikiLink: 'https://en.wikipedia.org/wiki/Arecibo,_Puerto_Rico',
        },
        landmark: {
          name: 'San Juan National Historic Site',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/San_Juan_National_Historic_Site#/media/File:San_Felipe_del_Morro_view_across_bay.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/San_Juan_National_Historic_Site',
          hasLandmark: true,
        },
        city: {
          name: 'San Juan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-daytime-zq08xWkBgsI',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico',
        },
        landmarksRound: [
          { code: 'HM', number: 3 },
          { code: 'LA', number: 1 },
          { code: 'TF', number: 1 },
        ],
        landmarkPlaces: [
          'Motherland Monument',
          'Burana Tower',
          'Soufrière Hills volcano',
          'Rock of Gibraltar',
          'Marcus Aurelius Arch',
          'Peel Castle',
          'Liwonde National Park',
        ],
        uniqueId: 'vF2dHVGF',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Puerto Rico',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/blue-sea-under-white-clouds-at-daytime-UzlkIYRM8EE',
          wikiLink: 'https://en.wikipedia.org/wiki/Puerto_Rico',
        },
        landmark: {
          name: 'Cathedral of San Juan',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/124651729@N04/51096644717/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Cathedral_of_San_Juan,_Puerto_Rico',
          hasLandmark: true,
        },
        city: {
          name: 'Bayamón',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/189158977@N04/52460758271/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bayam%C3%B3n,_Puerto_Rico',
        },
        landmarksRound: [
          { code: 'MO', number: 2 },
          { code: 'CM', number: 6 },
          { code: 'VE', number: 1 },
        ],
        landmarkPlaces: [
          'Morne Seychellois',
          'Jal Mahal',
          'Roman Forum',
          'Grand Ducal Palace',
          'Henderson Island',
          'Fongafale',
          'Little Chapel',
        ],
        uniqueId: 'BPXWfJKJ',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Puerto Rico',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-bicycle-parked-beside-green-tree-during-daytime-Wi-dnjfkE_0',
          wikiLink: 'https://en.wikipedia.org/wiki/Puerto_Rico',
        },
        landmark: {
          name: 'Cabo Rojo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-and-brown-island-during-daytime-2m6-KFSL-2g',
          wikiLink: 'https://en.wikipedia.org/wiki/Cabo_Rojo,_Puerto_Rico',
          hasLandmark: true,
        },
        city: {
          name: 'San Juan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-blue-and-pink-painted-high-rise-building-XV5Osh0TOuc',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico',
        },
        landmarksRound: [
          { code: 'VA', number: 2 },
          { code: 'CC', number: 1 },
          { code: 'HK', number: 6 },
        ],
        landmarkPlaces: [
          'Maiden Tower',
          'Hungarian Parliament Building',
          'National Pantheon',
          'Herat Citadel',
          'Chapel of the Snows',
          'Verona Arena',
          'Middle of the World City',
        ],
        uniqueId: '5rMNHlQr',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Qatar',
    code: 'QA',
    capital: 'Doha',
    lat: 25.5,
    long: 51.25,
    cities: [
      'Doha',
      'Al Rayyan',
      'Al Khor',
      'Dukhan',
      'Al Wakrah',
      'Ar-Rayyan',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Do6yoytec5E',
          wikiLink: 'https://en.wikipedia.org/wiki/Museum_of_Islamic_Art,_Doha',
        },
        landmark: {
          name: 'Souq Waqif',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Souq_Waqif#/media/File:Souq_Waqif,_Doha,_Catar,_2013-08-05,_DD_84.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Souq_Waqif',
          hasLandmark: true,
        },
        city: {
          name: 'Doha',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/skyscrapers-building-architectural-3850732/',
          wikiLink: 'https://en.wikipedia.org/wiki/Doha',
        },
        landmarksRound: [
          { code: 'HU', number: 1 },
          { code: 'MY', number: 2 },
          { code: 'LU', number: 1 },
        ],
        landmarkPlaces: [
          'Kim Il Sung Square',
          'Tenaru Falls',
          'Vardzia',
          'Tsingy De Bemaraha',
          'Katse Dam',
          'Leaning Tower of Pisa',
          'National Shrine Basilica of our Lady of Las Lajas',
        ],
        uniqueId: 'ZdpBx5B4',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/skyscrapers-building-architectural-3850732/',
          wikiLink: 'https://en.wikipedia.org/wiki/Doha',
        },
        landmark: {
          name: 'The Pearl Monument',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Doha_Corniche#/media/File:The_Pearl_Monument.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Doha_Corniche',
          hasLandmark: true,
        },
        city: {
          name: 'Al Rayyan',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Al_Rayyan_(city)#/media/File:View_of_Wadi_Al_Gaiya_Street_in_Mehairja_from_Aspire_Zone_parking_lot.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Al_Rayyan_(city)',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '6kJkqxyN',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Qatar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/boats-in-the-water-1UF8ddEalwk',
          wikiLink: 'https://en.wikipedia.org/wiki/Qatar',
        },
        landmark: {
          name: 'Imam Muhammad ibn Abd al-Wahhab Mosque',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/40046898@N00/24141882243/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Imam_Muhammad_ibn_Abd_al-Wahhab_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Al Wakrah',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/fisherbray/49196303636/',
          wikiLink: 'https://en.wikipedia.org/wiki/Al_Wakrah',
        },
        landmarksRound: [
          { code: 'AM', number: 5 },
          { code: 'SG', number: 1 },
          { code: 'TF', number: 3 },
        ],
        landmarkPlaces: [
          'Paradise Island',
          "N'Djamena Grand Mosque",
          'Fasil Ghebbi',
          'Pigeon Rocks',
          'Bayon',
          'Dubai Frame',
          'Erdene Zuu Monastery',
        ],
        uniqueId: '7vNwpGlR',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'The Pearl Island',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tall-blue-building-with-palm-trees-in-front-of-it-huzHfOPHgXY',
          wikiLink: 'https://en.wikipedia.org/wiki/The_Pearl_Island',
        },
        landmark: {
          name: 'Al Zubara Fort',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-in-the-middle-of-a-desert-OTGVq0p-hZo',
          wikiLink:
            'https://en.wikipedia.org/wiki/Al_Zubara_Forthttps://en.wikipedia.org/wiki/Al_Zubara_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Doha',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-white-van-parked-in-front-of-a-yellow-building-WmUXxFNfjXo',
          wikiLink: 'https://en.wikipedia.org/wiki/Doha',
        },
        landmarksRound: [
          { code: 'TF', number: 3 },
          { code: 'WS', number: 1 },
          { code: 'IM', number: 1 },
        ],
        landmarkPlaces: [
          'Detwah Lagoon',
          'Jardin de Balata',
          'Monument to Party Founding',
          'Torre de la Libertad',
          'Bouni Beach',
          'National Museum of Costume',
          'Pyramids of Meroe',
        ],
        uniqueId: '3NWnprVT',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Republic of the Congo',
    code: 'CG',
    capital: 'Brazzaville',
    lat: -1,
    long: 15,
    cities: ['Lubumbashi', 'Kinshasa', 'Brazzaville'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:Brazzaville_bird_eye_view.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Brazzaville',
        },
        landmark: {
          name: 'Basilique Sainte Anne du Congo',
          photographer: '',
          imageLink: 'https://snl.no/Brazzaville',
          wikiLink:
            'https://en.wikipedia.org/wiki/Basilica_of_Sainte-Anne-du-Congo_in_Brazzaville',
          hasLandmark: true,
        },
        city: {
          name: 'Pointe-Noire',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Pointe-Noire#/media/File:Pointe-Noire_downtown.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pointe-Noire',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'yYP4qS2w',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/File:Pointe-Noire_downtown.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pointe-Noire',
        },
        landmark: {
          name: 'Pierre Savorgnan de Brazza Memorial',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Memorial_de_Brazza.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pierre_Savorgnan_de_Brazza',
          hasLandmark: true,
        },
        city: {
          name: 'Brazzaville',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/brazzaville-congo-africa-city-7444939/',
          wikiLink: 'https://en.wikipedia.org/wiki/Brazzaville',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'ByvdM2PV',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Republic of the Congo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bus-parked-in-front-of-a-building-dJ8uDfnaZFc',
          wikiLink: 'https://en.wikipedia.org/wiki/Republic_of_the_Congo',
        },
        landmark: {
          name: 'Odzala-Kokoua National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jackieandjohn/8107408252/',
          wikiLink: 'https://en.wikipedia.org/wiki/Odzala-Kokoua_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Lubumbashi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-near-building-during-day-0uhXHQYEbzM',
          wikiLink: 'https://fr.wikipedia.org/wiki/Lubumbashi',
        },
        landmarksRound: [
          { code: 'BR', number: 2 },
          { code: 'GW', number: 3 },
          { code: 'ET', number: 1 },
        ],
        landmarkPlaces: [
          'Two Lovers Point',
          'Niagara Falls',
          'Cheshire Hall Plantation',
          'Amboseli National Park, Kenya',
          'India Gate',
          'Baths of Antoninus',
          'Ilulissat Icefjord',
        ],
        uniqueId: 'C2w9HKlR',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Republic of the Congo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-couple-of-boats-that-are-sitting-in-the-water-Bm_0FqhVPww',
          wikiLink: 'https://en.wikipedia.org/wiki/Republic_of_the_Congo',
        },
        landmark: {
          name: 'Réserve du Mont Fouari',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-wooden-house-near-body-of-water-during-daytime-VuXRueqh6Vg',
          wikiLink: 'https://fr.wikipedia.org/wiki/R%C3%A9serve_du_Mont_Fouari',
          hasLandmark: true,
        },
        city: {
          name: 'Kinshasa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-under-blue-sky-and-white-clouds-during-daytime-StJWD4ci8wY',
          wikiLink: 'https://en.wikipedia.org/wiki/Kinshasa',
        },
        landmarksRound: [
          { code: 'VG', number: 6 },
          { code: 'BM', number: 1 },
          { code: 'GR', number: 2 },
        ],
        landmarkPlaces: [
          'National Pantheon',
          'Hof',
          'Niagara Falls',
          'Gibbs Hill Lighthouse',
          'Taj Mahal',
          'Detwah Lagoon',
          'Jerash',
        ],
        uniqueId: '4L43jRGV',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Reunion',
    code: 'RE',
    capital: 'Saint-Denis',
    lat: -21.15,
    long: 55.5,
    cities: [
      'Saint-Pierre',
      'Saint-Denis',
      'Saint-Paul',
      'Le Tampon',
      'Le Port',
      'Saint-Andre',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/f9xoWEnLxlQ',
          wikiLink: 'https://en.wikipedia.org/wiki/R%C3%A9union',
        },
        landmark: {
          name: 'Kelonia',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/K%C3%A9lonia#/media/Fichier:K%C3%A9lonia.jpg',
          wikiLink: 'https://fr.wikipedia.org/wiki/K%C3%A9lonia',
          hasLandmark: true,
        },
        city: {
          name: 'Saint-Pierre',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Saint-Pierre,_R%C3%A9union#/media/File:Ville_Saint-Pierre1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint-Pierre,_R%C3%A9union',
        },
        landmarksRound: [
          { code: 'EE', number: 1 },
          { code: 'TM', number: 1 },
          { code: 'AT', number: 1 },
        ],
        landmarkPlaces: [
          'National Shrine Basilica of our Lady of Las Lajas',
          'Tikal National Park',
          'Belem Tower',
          'Bled Castle',
          'Vaduz Cathedral',
          'Middle of the World City',
          'Vardzia',
        ],
        uniqueId: 'HwP2bBjy',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/R%C3%A9union#/media/File:Ppalmistes2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/R%C3%A9union',
        },
        landmark: {
          name: 'Villa Folio',
          photographer: '',
          imageLink:
            'https://fr.wikipedia.org/wiki/Villa_Folio#/media/Fichier:Maison_Folio.jpg',
          wikiLink: 'https://fr.wikipedia.org/wiki/Villa_Folio',
          hasLandmark: true,
        },
        city: {
          name: 'Saint-Denis',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Saint-Denis,_R%C3%A9union#/media/File:Saint_Denis_au_cr%C3%A9puscule_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint-Denis,_R%C3%A9union',
        },
        landmarksRound: [
          { code: 'FR', number: 1 },
          { code: 'CH', number: 2 },
          { code: 'GG', number: 2 },
        ],
        landmarkPlaces: [
          'Umayyad Mosque',
          'Fort Sao Sebastiao',
          'Luis I Bridge',
          'Mount Paget',
          'Xunatunich Mayan Ruins',
          'Hagia Sophia',
          'Badshahi Mosque',
        ],
        uniqueId: 'KnTr844s',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Reunion',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-on-brown-rock-formation-near-body-of-water-during-daytime-ODHXC8W0RVg',
          wikiLink: 'https://en.wikipedia.org/wiki/R%C3%A9union',
        },
        landmark: {
          name: 'Piton des Neiges',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-standing-on-cliff-during-daytime-CZUYQ0HeQtw',
          wikiLink: 'https://en.wikipedia.org/wiki/Piton_des_Neiges',
          hasLandmark: true,
        },
        city: {
          name: 'Saint-Denis',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/130677558@N07/26698711311/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint-Denis,_R%C3%A9union',
        },
        landmarksRound: [
          { code: 'CF', number: 3 },
          { code: 'IL', number: 6 },
          { code: 'IS', number: 2 },
        ],
        landmarkPlaces: [
          'Dancing House',
          'Réserve du Mont Fouari',
          'Kibira National Park',
          'Source du Nil',
          'Skansin',
          'Predjama Castle',
          'Big Ben',
        ],
        uniqueId: '2zlKyqmF',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Reunion',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bridge-with-a-walkway-over-it-ooompEei8QE',
          wikiLink: 'https://en.wikipedia.org/wiki/R%C3%A9union',
        },
        landmark: {
          name: 'Grand Brûlé',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-near-mountain-during-daytime-15-7ks3NTAU',
          wikiLink: 'https://fr.wikipedia.org/wiki/Grand_Br%C3%BBl%C3%A9',
          hasLandmark: true,
        },
        city: {
          name: 'Saint-Paul',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/megatatan/4797440737/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint-Paul,_R%C3%A9union',
        },
        landmarksRound: [
          { code: 'CY', number: 4 },
          { code: 'GQ', number: 4 },
          { code: 'PW', number: 4 },
        ],
        landmarkPlaces: [
          'Pigeon Rocks',
          'Nan Madol',
          'Stone House',
          'National Museum of Bermuda',
          'Hiroshima Peace Memorial',
          'Eltz Castle',
          'Acropolis',
        ],
        uniqueId: 'FWmSpQWK',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Romania',
    code: 'RO',
    capital: 'Bucharest',
    lat: 46,
    long: 25,
    cities: [
      'Brasov',
      'Bucharest',
      'Sibiu',
      'Constanta',
      'Iasi',
      'Oradea',
      'Brașov',
      'Sighișoara',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/nKY59_d9FlA',
          wikiLink: 'https://en.wikipedia.org/wiki/Pele%C8%99_Castle',
        },
        landmark: {
          name: 'Bran Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fortress-bran-romania-castle-2413390/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bran_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Brasov',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/buildings-city-aerial-view-4011921/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bra%C8%99ov',
        },
        landmarksRound: [
          { code: 'ZA', number: 1 },
          { code: 'AQ', number: 1 },
          { code: 'LB', number: 1 },
        ],
        landmarkPlaces: [
          'Timgad',
          'Gangtey Monastery',
          'Amber Palace',
          'Chorsu Bazaar',
          'Huka Falls',
          'Charles Bridge',
          'African Renaissance Monument',
        ],
        uniqueId: 'QNW9zSMQ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/transilvania-romania-alpine-5632013/',
          wikiLink: 'https://en.wikipedia.org/wiki/Romania',
        },
        landmark: {
          name: 'Merry Cemetery',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Merry_Cemetery#/media/File:Rumunia,_Sapanta,_Weso%C5%82y_Cmentarz_-Aw58-_28.04.12_r..JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Merry_Cemetery',
          hasLandmark: true,
        },
        city: {
          name: 'Bucharest',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bucharest-night-romania-lights-4009121/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bucharest',
        },
        landmarksRound: [
          { code: 'NP', number: 1 },
          { code: 'AR', number: 2 },
          { code: 'GB', number: 2 },
        ],
        landmarkPlaces: [
          'Karnak',
          'Aleppo Citadel',
          'Pamir Mountains',
          'Olavinlinna',
          'Arc de Triomphe',
          'Masjid al-Haram',
          'Tjibaou Cultural Center',
        ],
        uniqueId: 'HpPj7Jsq',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Romania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-house-on-a-hill-8BDuTPoN_jA',
          wikiLink: 'https://en.wikipedia.org/wiki/Romania',
        },
        landmark: {
          name: 'Barajul Drăgan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-bridge-between-mountains-vYi0kzbK4sU',
          wikiLink: 'https://ro.wikipedia.org/wiki/Barajul_Dr%C4%83gan',
          hasLandmark: true,
        },
        city: {
          name: 'Brașov',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-shot-of-buildings-dyjV3T1qfLE',
          wikiLink: 'https://en.wikipedia.org/wiki/Bra%C8%99ov',
        },
        landmarksRound: [
          { code: 'OM', number: 2 },
          { code: 'LY', number: 4 },
          { code: 'VN', number: 2 },
        ],
        landmarkPlaces: [
          'Dubai Frame',
          'Mount Taranaki',
          'Porte du Non Retour',
          'Fort Santiago',
          'National Museum of Costa Rica     ',
          'Pierre Savorgnan de Brazza Memorial',
          'Monumento al Divino Salvador del Mundo',
        ],
        uniqueId: 'vf5KnVLC',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Romania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-with-boats-and-trees-around-it-zLIMxP0SHZA',
          wikiLink: 'https://en.wikipedia.org/wiki/Romania',
        },
        landmark: {
          name: 'Peles Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-structure-nKY59_d9FlA',
          wikiLink: 'https://en.wikipedia.org/wiki/Pele%C8%99_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Sighișoara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/beige-and-brown-gothic-building-on-hill-VJSUSrgN56w',
          wikiLink: 'https://en.wikipedia.org/wiki/Sighi%C8%99oara',
        },
        landmarksRound: [
          { code: 'ML', number: 6 },
          { code: 'LY', number: 3 },
          { code: 'BT', number: 6 },
        ],
        landmarkPlaces: [
          'Christ the King Cathedral',
          'Atacama Large Millimeter Array',
          'Sundarbans',
          'Valle de la Luna',
          'Karlštejn Castle',
          'Notre Dame de Lourdes',
          'Big Ben',
        ],
        uniqueId: '5pHnsyWB',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Russia',
    code: 'RU',
    capital: 'Moscow',
    lat: 60,
    long: 100,
    cities: [
      'Kazan',
      'Saint Petersburg',
      'Moscow',
      'Yekaterinburg',
      'Omsk',
      'Novosibirsk',
      'Volgograd',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/MEWRrCmEiGc',
          wikiLink: 'https://en.wikipedia.org/wiki/Moscow_State_University',
        },
        landmark: {
          name: 'Red Square',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/moscow-church-russian-russia-2105606/',
          wikiLink: 'https://en.wikipedia.org/wiki/Red_Square',
          hasLandmark: true,
        },
        city: {
          name: 'Kazan',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kazan-city-architecture-cityscape-3227834/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kazan',
        },
        landmarksRound: [
          { code: 'GT', number: 2 },
          { code: 'JP', number: 2 },
          { code: 'US', number: 1 },
        ],
        landmarkPlaces: [
          'Sule Pagoda',
          'Golden Gate',
          "Diocletian's Palace",
          'Alhambra',
          'Baalbek',
          'Epupa Falls',
          '',
        ],
        uniqueId: 'R5DvSxZx',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caucasus-russia-elbrus-river-5302236/',
          wikiLink: 'https://en.wikipedia.org/wiki/Russia',
        },
        landmark: {
          name: 'Winter Palace',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/hermitage-st-petersburg-s-2612663/',
          wikiLink: 'https://en.wikipedia.org/wiki/Winter_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Saint Petersburg',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/saint-petersburg-peter-st-petersburg-2547440/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Petersburg',
        },
        landmarksRound: [
          { code: 'LV', number: 2 },
          { code: 'BD', number: 1 },
          { code: 'ER', number: 2 },
        ],
        landmarkPlaces: [
          'Stonehenge',
          'Marcus Aurelius Arch',
          'Hazrat Ali Mazar',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Herat Citadel',
          "Diocletian's Palace",
          'Tenaru Falls',
        ],
        uniqueId: 'YkQRMMCN',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Izhevsk',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-playground-in-front-of-it-IZi2hSr_XKQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Izhevsk',
        },
        landmark: {
          name: 'Lake Baikal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/melting-ice-on-water-near-gray-mountain-at-daytime-RbRWDUyDEWQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Baikal',
          hasLandmark: true,
        },
        city: {
          name: 'Saint Petersburg',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-street-filled-with-lots-of-traffic-next-to-tall-buildings-wz4WOKdKPXQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Petersburg',
        },
        landmarksRound: [
          { code: 'CX', number: 6 },
          { code: 'KY', number: 3 },
          { code: 'TH', number: 1 },
        ],
        landmarkPlaces: [
          'Bradda Head',
          'Island of Mozambique',
          'Pulhapanzak Waterfalls',
          'Alcázar de Colón',
          'Catarina',
          'Paphos Archaeological Park',
          'Shell Beach',
        ],
        uniqueId: '6KgKRxWB',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Russia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-of-forest-GWggAB8uAlA',
          wikiLink: 'https://en.wikipedia.org/wiki/Russia',
        },
        landmark: {
          name: 'Kul Sharif Mosque',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/31176607@N05/46957847842/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kul_Sharif_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Volgograd',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-snow-covered-field-with-a-fence-and-houses-in-the-background-4FGadKCxc64',
          wikiLink: 'https://en.wikipedia.org/wiki/Volgograd',
        },
        landmarksRound: [
          { code: 'WS', number: 2 },
          { code: 'AE', number: 1 },
          { code: 'GL', number: 2 },
        ],
        landmarkPlaces: [
          'Tikal Temple I',
          'Easter Island',
          'Oriental Pearl TV Tower',
          'The Baths',
          'Bayon',
          'Vilnius Cathedral',
          "National Martyrs' Memorial",
        ],
        uniqueId: 'VmbXSN4L',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Rwanda',
    code: 'RW',
    capital: 'Kigali',
    lat: -2,
    long: 30,
    cities: ['Gisenyi', 'Kigali'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/riqHVFDBt-8',
          wikiLink: 'https://en.wikipedia.org/wiki/Rwanda',
        },
        landmark: {
          name: 'Kigali Genocide Memorial',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kigali_Genocide_Memorial#/media/File:Kigali_Genocide_Memorial_(6817416143).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kigali_Genocide_Memorial',
          hasLandmark: true,
        },
        city: {
          name: 'Kigali',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kigali-rwanda-africa-city-building-5327838/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kigali',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'DQrVmMs4',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kigali-rwanda-africa-to-travel-5256022/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rwanda',
        },
        landmark: {
          name: "King's Palace Museum",
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Nyanza,_Rwanda#/media/File:King's_palace_in_Nyanza.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Nyanza,_Rwanda',
          hasLandmark: true,
        },
        city: {
          name: 'Muhanga',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Muhanga#/media/File:GitaramaCityCropped.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Muhanga',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'XgcgkDXN',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Rwanda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-goats-on-brown-grass-during-daytime-Edr2cBcMn-M',
          wikiLink: 'https://en.wikipedia.org/wiki/Rwanda',
        },
        landmark: {
          name: 'Akagera National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-landscape-with-trees-and-water-in-the-background-4MoRgY7H42s',
          wikiLink: 'https://en.wikipedia.org/wiki/Akagera_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Gisenyi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-boat-that-is-sitting-in-the-water-OYeYN7TmU-g',
          wikiLink: 'https://en.wikipedia.org/wiki/Gisenyi',
        },
        landmarksRound: [
          { code: 'GY', number: 4 },
          { code: 'DZ', number: 5 },
          { code: 'BA', number: 4 },
        ],
        landmarkPlaces: [
          'Batu Caves',
          'Maiden Tower',
          'Blue Mountains',
          'Ħal Saflieni Hypogeum',
          'Viru Gate',
          'Tsunami Monument',
          'Muzeu Historik Kombëtar',
        ],
        uniqueId: 'LVT4TfQC',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Rwanda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-woman-walking-through-a-lush-green-field-ZD6EipK6iOY',
          wikiLink: 'https://en.wikipedia.org/wiki/Rwanda',
        },
        landmark: {
          name: 'Nyungwe park',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/road-near-forest-cryqKSeLX74',
          wikiLink: 'https://en.wikipedia.org/wiki/Nyungwe_Forest',
          hasLandmark: true,
        },
        city: {
          name: 'Kigali',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-road-with-grass-and-trees-on-the-side-QRkbb0ZFW9s',
          wikiLink: 'https://en.wikipedia.org/wiki/Kigali',
        },
        landmarksRound: [
          { code: 'BI', number: 6 },
          { code: 'BB', number: 5 },
          { code: 'GL', number: 2 },
        ],
        landmarkPlaces: [
          'Monte Carlo Casino',
          'Cheshire Hall Plantation',
          'Machu Picchu',
          "Queen's Staircase",
          'Molinere Underwater Sculpture Park',
          'Kastelholm Castle',
          'Deception Island',
        ],
        uniqueId: 'sJnCRRF2',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Saint Barthelemy',
    code: 'BL',
    capital: 'Gustavia',
    lat: 18.5,
    long: -63.41666666,
    cities: ['Lorient', 'Gustavia'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/shell-beach-saint-barth%c3%a9lemy-5140800/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy',
        },
        landmark: {
          name: 'Fort Karl',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/46666832614',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Y2GMKsyn',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Grotte_du_puits_Saint_Barth%C3%A9l%C3%A9my.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy',
        },
        landmark: {
          name: 'Shell Beach',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Shell_Beach_St._Barts.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'T5PVPKL9',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Barthélemy',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-sandy-beach-with-a-hill-in-the-background-881zmv78Asw',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy',
        },
        landmark: {
          name: 'Colombier',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ashleycoates/39665015440/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Colombier,_Saint_Barth%C3%A9lemy',
          hasLandmark: true,
        },
        city: {
          name: 'Lorient',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/didierbeck/932445904/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Lorient,_Saint_Barth%C3%A9lemy',
        },
        landmarksRound: [
          { code: 'BW', number: 3 },
          { code: 'BA', number: 4 },
          { code: 'BE', number: 2 },
        ],
        landmarkPlaces: [
          'Pamir Mountains',
          'Chapel of the Snows',
          'Chinguetti Mosque',
          'Uganda National Mosque',
          'Plaza Francia',
          'Fort Charlotte',
          'Lukan Longshan Temple',
        ],
        uniqueId: '5BTFS6tC',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Barthélemy',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-harbor-with-boats-in-the-water-zamjZ-wAjZk',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy',
        },
        landmark: {
          name: 'Church of Our Lady of the Assumption',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/carriegibson/7856862696/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Church_of_Our_Lady_of_the_Assumption,_Gustavia',
          hasLandmark: true,
        },
        city: {
          name: 'Gustavia',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/109349798@N08/10976056563/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gustavia',
        },
        landmarksRound: [
          { code: 'EE', number: 5 },
          { code: 'DJ', number: 4 },
          { code: 'MH', number: 4 },
        ],
        landmarkPlaces: [
          'Tsingy De Bemaraha',
          'La Corbière',
          'Matafao Peak',
          'Fort Cépérou',
          'Our Lady of Ardilliers Catholic Church',
          'The Ethnographic Open-Air Museum of Latvia',
          'Boudhanath',
        ],
        uniqueId: 'FyxqHt53',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Saint Helena',
    code: 'SH',
    capital: 'Jamestown',
    lat: -24.143474,
    long: -10.030696,
    cities: ['Jamestown', 'Longwood'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:St-Helena-Jamestown.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamestown,_Saint_Helena',
        },
        landmark: {
          name: 'High Knoll Fort',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/High_Knoll_Fort#/media/File:High_Knoll_Fort_on_Saint_Helena_Island,_March_2020.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/High_Knoll_Fort',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'F8rHWCdz',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:St_Helena,_view_south_over_%27Lot%27_toward_Sandy_Bay,_from_Sandy_Bay_Ridge,_1985-6._Peter_Neaum._-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sandy_Bay,_Saint_Helena',
        },
        landmark: {
          name: "Jacob's Ladder",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Jacob%27s_Ladder_(Saint_Helena)#/media/File:Jacobs_Ladder_viewed_from_the_harbour_of_Jamestown.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Jacob%27s_Ladder_(Saint_Helena)',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'RqQjd64n',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Helena',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/hukalo/52757629502/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Helena',
        },
        landmark: {
          name: 'Plantation House',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/44838539@N07/13901797604/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Plantation_House,_Saint_Helena',
          hasLandmark: true,
        },
        city: {
          name: 'Jamestown',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/lxtbush/13685169944/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jamestown,_Saint_Helena',
        },
        landmarksRound: [
          { code: 'JE', number: 5 },
          { code: 'AX', number: 4 },
          { code: 'IT', number: 4 },
        ],
        landmarkPlaces: [
          'Twelve Apostles',
          'Macau Tower',
          'Trakai Island Castle',
          'Tarawa',
          'House of the Black Heads',
          'Kamianets-Podilskyi Castle',
          'Chimi Lhakhang',
        ],
        uniqueId: 'RsQh8tTl',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Helena',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/hukalo/52758574270/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Helena',
        },
        landmark: {
          name: 'Longwood House',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/44838539@N07/13901468213/',
          wikiLink: 'https://en.wikipedia.org/wiki/Longwood_House',
          hasLandmark: true,
        },
        city: {
          name: 'Longwood',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/marshasown/46933375012/',
          wikiLink: 'https://en.wikipedia.org/wiki/Longwood,_Saint_Helena',
        },
        landmarksRound: [
          { code: 'AR', number: 6 },
          { code: 'BA', number: 4 },
          { code: 'MA', number: 4 },
        ],
        landmarkPlaces: [
          'Shell Beach',
          'Mount Cameroon',
          'Bunce Island',
          'Demerara Bridge',
          'National Museum of Costume',
          'Oceania House ',
          'Codrington College',
        ],
        uniqueId: 'Ksk69xCF',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    capital: 'Basseterre',
    lat: 17.33333333,
    long: -62.75,
    cities: [
      'Basseterre',
      'Charlestown',
      'Cayon',
      'Sandy Pointe Town',
      'Gingerland',
      'Old Road Town',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cruise-ships-passengers-caribbean-6593595/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis',
        },
        landmark: {
          name: 'Brimstone Hill Fortress',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Brimstone_Hill_Fortress_National_Park#/media/File:BrimstoneHill01.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Brimstone_Hill_Fortress_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Charlestown',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Charlestown,_Nevis#/media/File:Nevis_Charlestown.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Charlestown,_Nevis',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'mQSGgGN8',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/120420083@N05/13924571953',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis',
        },
        landmark: {
          name: 'Romney Manor',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Karibik,_St._Kitts_-_Romney_Manor_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis',
          hasLandmark: true,
        },
        city: {
          name: 'Basseterre',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/saint-kitts-st-kitts-panorama-3475296/',
          wikiLink: 'https://en.wikipedia.org/wiki/Basseterre',
        },
        landmarksRound: [
          { code: 'KR', number: 2 },
          { code: 'LT', number: 1 },
          { code: 'HK', number: 1 },
        ],
        landmarkPlaces: [
          'House of the Black Heads',
          'Kim Il Sung Square',
          'Petronas Towers',
          'Abu Simbel Temples',
          'Xunatunich Mayan Ruins',
          'Church of Our Savior',
          'Hato Caves',
        ],
        uniqueId: 'WD9NpPLy',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Kitts and Nevis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-with-a-mountain-in-the-background-650lErxpQZA',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis',
        },
        landmark: {
          name: 'Brimstone Hill Fortress National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/124651729@N04/26835296198/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Brimstone_Hill_Fortress_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Charlestown',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/49852673301/',
          wikiLink: 'https://en.wikipedia.org/wiki/Charlestown,_Nevis',
        },
        landmarksRound: [
          { code: 'BW', number: 3 },
          { code: 'CO', number: 5 },
          { code: 'GF', number: 1 },
        ],
        landmarkPlaces: [
          'Mirador Roc Del Quer | Canillo',
          'To Sua Ocean Trench',
          'Salvo Palace',
          'Santa Catalina Arch',
          'Great Pyramid of Giza',
          'Registan Square',
          "Queen's Staircase",
        ],
        uniqueId: 'x7fc7GFS',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Kitts and Nevis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/house-near-trees-and-greenfield-xHJ5H3_AyeI',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis',
        },
        landmark: {
          name: 'Berkeley Memorial',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/55378459@N07/53658851304/',
          wikiLink: 'https://en.wikipedia.org/wiki/Berkeley_Memorial',
          hasLandmark: true,
        },
        city: {
          name: 'Basseterre',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-palm-tree-on-the-side-of-a-road-5I_iC_Oxhw0',
          wikiLink: 'https://en.wikipedia.org/wiki/Basseterre',
        },
        landmarksRound: [
          { code: 'LA', number: 5 },
          { code: 'BF', number: 2 },
          { code: 'TN', number: 4 },
        ],
        landmarkPlaces: [
          'Deadvlei',
          'Bayon',
          'Regina Mundi Cathedral',
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Cheshire Hall Plantation',
          'Nesvizh Castle',
          'Leon Cathedral',
        ],
        uniqueId: 'PNZGHnkk',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    capital: 'Castries',
    lat: 13.88333333,
    long: -60.96666666,
    cities: ['Castries', 'Soufrière'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/st-lucia-caribbean-island-106119/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Lucia',
        },
        landmark: {
          name: 'Pitons',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/piton-caribbean-volcano-pitons-164280/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pitons_(Saint_Lucia)',
          hasLandmark: true,
        },
        city: {
          name: 'Vieux Fort',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Vieux_Fort,_Saint_Lucia#/media/File:STLUC-vieuxfort.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Vieux_Fort,_Saint_Lucia',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Llf3PKNd',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/st-lucia-caribbean-island-106120/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Lucia',
        },
        landmark: {
          name: 'Marigot Bay',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caribbean-bay-nature-sea-1015941/',
          wikiLink: 'https://en.wikipedia.org/wiki/Marigot_Bay',
          hasLandmark: true,
        },
        city: {
          name: 'Castries',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/st-lucia-castries-caribbean-island-4911631/',
          wikiLink: 'https://en.wikipedia.org/wiki/Castries',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'shF95Rk5',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Lucia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-mountains-pBnM1-mDR4A',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Lucia',
        },
        landmark: {
          name: 'Pigeon Island',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/sulitskiy/52954075038/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pigeon_Island_(Saint_Lucia)',
          hasLandmark: true,
        },
        city: {
          name: 'Castries',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/shorty_76/1430909626/',
          wikiLink: 'https://en.wikipedia.org/wiki/Castries',
        },
        landmarksRound: [
          { code: 'BW', number: 6 },
          { code: 'PA', number: 1 },
          { code: 'DK', number: 4 },
        ],
        landmarkPlaces: [
          'Grand Turk Lighthouse',
          'One Foot Island',
          'Roman Forum',
          'Bayon Temple',
          'Erdene Zuu Monastery',
          'La Sagrada Familia',
          'Kokoda Track',
        ],
        uniqueId: 'ZGpVVnc8',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Lucia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-cars-parked-in-front-of-a-body-of-water-5gfBxVCULiE',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Lucia',
        },
        landmark: {
          name: 'Marigot Bay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-body-of-water-during-daytime-bG_xVUDGiQw',
          wikiLink: 'https://en.wikipedia.org/wiki/Marigot_Bay',
          hasLandmark: true,
        },
        city: {
          name: 'Soufrière',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-boat-sitting-on-top-of-a-beach-next-to-the-ocean-bJgZBhe6OrI',
          wikiLink: 'https://en.wikipedia.org/wiki/Soufri%C3%A8re,_Saint_Lucia',
        },
        landmarksRound: [
          { code: 'BD', number: 2 },
          { code: 'CA', number: 1 },
          { code: 'OM', number: 3 },
        ],
        landmarkPlaces: [
          'Te Rua Manga (The Needle)',
          'Metropolitan Cathedral of San Salvador',
          'Linnahall',
          'Red Square',
          'Fortín de La Galera',
          'Po Lin Monastery ',
          'Alhambra',
        ],
        uniqueId: 'NcSf2hX7',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Saint Martin',
    code: 'MF',
    capital: 'Marigot',
    lat: 18.08333333,
    long: -63.95,
    cities: ['Marigot', 'Grand Case'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/airplane-maho-beach-saint-martin-2690200/',
          wikiLink: 'https://en.wikipedia.org/wiki/Maho_Beach',
        },
        landmark: {
          name: 'Fort Amsterdam',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Sint_Maarten_-_Fort_Amsterdam_-_panoramio.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fort_Amsterdam_(Sint_Maarten)',
          hasLandmark: false,
        },
        city: {
          name: 'Marigot',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/49887745551',
          wikiLink: 'https://en.wikipedia.org/wiki/Marigot,_Saint_Martin',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'd9df48cf',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Pinel_Island,_Saint_Martin.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/List_of_Caribbean_islands#Collectivity_of_Saint_Martin',
        },
        landmark: {
          name: 'Ilet de Pinel',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Pinel_Island,_Saint_Martin.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/Outline_of_the_Collectivity_of_Saint_Martin',
          hasLandmark: false,
        },
        city: {
          name: 'Philipsburg',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/124651729@N04/51319894543',
          wikiLink: 'https://en.wikipedia.org/wiki/Philipsburg,_Sint_Maarten',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'zBJFHtmJ',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Martin',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-and-brown-mountains-beside-blue-sea-during-daytime-ofrgcQc1Plg',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Martin_(island)',
        },
        landmark: {
          name: 'Mary Star of the Sea Church',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/41710899@N08/14348268292/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Mary_Star_of_the_Sea_Church,_Grand_Case',
          hasLandmark: true,
        },
        city: {
          name: 'Marigot',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/107892449@N03/49324546697/',
          wikiLink: 'https://en.wikipedia.org/wiki/Marigot,_Saint_Martin',
        },
        landmarksRound: [
          { code: 'VN', number: 5 },
          { code: 'SV', number: 6 },
          { code: 'MO', number: 3 },
        ],
        landmarkPlaces: [
          'Casa Loma',
          'Fiat Tagliero Building',
          'Solis Theater',
          'The Grand Palace',
          'Jazeera Beach',
          'Liberation Tower',
          'Akagera National Park',
        ],
        uniqueId: '8qTphlFq',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Martin',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-beach-with-a-lot-of-people-on-it-948zYke541Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Martin_(island)',
        },
        landmark: {
          name: 'Fort Amsterdam',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/theactionitems/6288896296/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fort_Amsterdam_(Sint_Maarten)',
          hasLandmark: true,
        },
        city: {
          name: 'Grand Case',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/krisspao/26160109229/',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Case,_Saint_Martin',
        },
        landmarksRound: [
          { code: 'RE', number: 4 },
          { code: 'LR', number: 4 },
          { code: 'AX', number: 1 },
        ],
        landmarkPlaces: [
          'St. Peter the Apostle Cathedral',
          'Cristo de La Misericordia',
          "Fa'aruma'i Waterfalls",
          'Notre Dame de Lourdes',
          'Church of Our Lady of the Rosary',
          'Tatamailau',
          'Fiat Tagliero Building',
        ],
        uniqueId: 'LmrZGdQl',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    capital: 'Saint-Pierre',
    lat: 46.83333333,
    long: -56.33333333,
    cities: ['Saint-Pierre', 'Miquelon-Langlade'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tibarama-caledonia-new-caledonia-6103043/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Pierre_and_Miquelon',
        },
        landmark: {
          name: 'Our Lady of Ardilliers Catholic Church',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Our_Lady_of_Ardilliers_Church,_Miquelon#/media/File:Eglise_de_Miquelon.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Our_Lady_of_Ardilliers_Church,_Miquelon',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'jzRGC4T8',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/danteaguiar/48350975052/in/photolist-2i1XMs-paMqWF-tNR3v-SybBmT-TJkcvy-TN16UB-SybCcF-SybByM-TJkcMA-SybyHg-Sybz72-SybCNa-TcXkyL-DsMekt-tNR3r-4foCKY-spg6vf-LaMFG7-EgW8gn-f6xb95-tNR3t-24sZttT-9jemf2-FWVGe8-GQyYH2-28eocaA-JDL2pK-G2W6Bk-GqvS83-GqxyPS-LaMHqY-JDL4Eg-28eoe8U-25xEth7-JDL5rM-LaMGPC-LaMz4h-28eo1Uf-LaMwUh-25xEtyu-LaMxQL-2gEBomU-28eoasN-FVckz2-LaML9d-JDL6RR-JDL1ak-2gqhEc7-2gqhX2q-dqSZQ4',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Pierre_and_Miquelon',
        },
        landmark: {
          name: 'Saint-Pierre Cathedral of Saint Pierre and Miquelon',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/St._Pierre_Cathedral,_Saint-Pierre#/media/File:Cathedral_of_St_Pierre_and_Miquelon.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Pierre_Cathedral,_Saint-Pierre',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'DNRXxlkk',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Pierre and Miquelon',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/benandlarry/52974436753/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Pierre_and_Miquelon',
        },
        landmark: {
          name: 'Île-aux-Marins',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/richarddesmarais/21019716143/',
          wikiLink: 'https://en.wikipedia.org/wiki/%C3%8Ele-aux-Marins',
          hasLandmark: true,
        },
        city: {
          name: 'Saint-Pierre',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/benandlarry/52972117582/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint-Pierre,_Saint-Pierre_and_Miquelon',
        },
        landmarksRound: [
          { code: 'BM', number: 1 },
          { code: 'KE', number: 3 },
          { code: 'IS', number: 6 },
        ],
        landmarkPlaces: [
          'Al Fateh Grand Mosque',
          'Almsee',
          'Cao Grande Peak',
          'Iluissat Icefjord',
          'Ruínas de São Paulo',
          'Holy Trinity Cathedral',
          'Lake Koman',
        ],
        uniqueId: 'BDHHvyPc',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Pierre and Miquelon',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/henryksadura/42922938230/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint_Pierre_and_Miquelon',
        },
        landmark: {
          name: 'Pointe aux Cannon Battery',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/benandlarry/52972822330/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pointe_aux_Cannon_Battery',
          hasLandmark: true,
        },
        city: {
          name: 'Miquelon-Langlade',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ian_a_gratton/28990474788/',
          wikiLink: 'https://en.wikipedia.org/wiki/Miquelon-Langlade',
        },
        landmarksRound: [
          { code: 'IN', number: 2 },
          { code: 'VN', number: 1 },
          { code: 'IR', number: 3 },
        ],
        landmarkPlaces: [
          'Salar de Uyuni',
          'Majete Wildlife Reserve',
          'National Museum of Montserrat',
          'Palais de la Nation',
          'National Park of American Samoa',
          'Berat Castle',
          'National Pantheon of the Heroes',
        ],
        uniqueId: 'h9spDFZm',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    capital: 'Kingstown',
    lat: 13.25,
    long: -61.2,
    cities: ['Kingstown', 'Port Elizabeth'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jetty-sea-boats-sunset-dock-6643909/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines',
        },
        landmark: {
          name: 'Fort Charlotte',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort_Charlotte,_Saint_Vincent#/media/File:Kingstown_%E2%80%93_Fort_Charlotte_-_panoramio.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fort_Charlotte,_Saint_Vincent',
          hasLandmark: true,
        },
        city: {
          name: 'Port Elizabeth',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/49804087672',
          wikiLink:
            'https://en.wikipedia.org/wiki/Port_Elizabeth,_Saint_Vincent_and_the_Grenadines',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'FG6qD6Lb',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/shipwreck-sea-6643956/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines',
        },
        landmark: {
          name: 'La Soufriere',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/la-soufriere-volcano-table-rock-3639039/',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Soufri%C3%A8re_(volcano)',
          hasLandmark: true,
        },
        city: {
          name: 'Kingstown',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/st-vincent-kingstown-the-grenadines-4652732/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kingstown',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '8XbvsXTw',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Saint Vincent and the Grenadines',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-sandy-beach-with-palm-trees-on-a-sunny-day-6BpsiQs0dkk',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines',
        },
        landmark: {
          name: 'Fort Charlotte',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/46596664564/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fort_Charlotte,_Saint_Vincent',
          hasLandmark: true,
        },
        city: {
          name: 'Kingstown',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/189135636@N02/51899927820/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Kingstown,_Saint_Vincent_and_the_Grenadines',
        },
        landmarksRound: [
          { code: 'BB', number: 2 },
          { code: 'AT', number: 5 },
          { code: 'VI', number: 4 },
        ],
        landmarkPlaces: [
          'Chutes de la Métché',
          'National Museum of Costume',
          'Wieliczka Salt Mine',
          'Uyuni Salt Flat',
          'Fort Santiago',
          'Potala Palace',
          'Ostrog Monastery',
        ],
        uniqueId: 'sqdCNDKJ',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Saint Vincent and the Grenadines',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-and-white-whale-on-blue-water-fVn_l9Nfdd8',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines',
        },
        landmark: {
          name: 'Mustique',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-pool-surrounded-by-rocks-and-trees-with-a-gazebo-in-the-background-cdjuk9dxqPU',
          wikiLink: 'https://en.wikipedia.org/wiki/Mustique',
          hasLandmark: true,
        },
        city: {
          name: 'Port Elizabeth',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-bay-with-boats-in-the-water-L2ybAJjmtZM',
          wikiLink:
            'https://en.wikipedia.org/wiki/Port_Elizabeth,_Saint_Vincent_and_the_Grenadines',
        },
        landmarksRound: [
          { code: 'BG', number: 4 },
          { code: 'IS', number: 3 },
          { code: 'CM', number: 3 },
        ],
        landmarkPlaces: [
          'Turaida Castle',
          'Pierre Savorgnan de Brazza Memorial',
          'Mary Star of the Sea Church',
          'Amberd',
          'Lake Piso',
          'Parque Colón',
          'Adolphe Bridge',
        ],
        uniqueId: 'yFlhCgKF',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Samoa',
    code: 'WS',
    capital: 'Apia',
    lat: -13.58333333,
    long: -172.3333333,
    cities: ['Apia', 'Afega'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/palm-trees-beach-dream-beach-59/',
          wikiLink: 'https://en.wikipedia.org/wiki/Samoa',
        },
        landmark: {
          name: 'Apia Town Clock Tower',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Central_Bank_of_Samoa_%282009%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Samoa',
          hasLandmark: true,
        },
        city: {
          name: 'Apia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Apia#/media/File:WS-Apia-Immac-concept.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Apia',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '9YwG6sYh',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/palm-trees-beach-dream-beach-52/',
          wikiLink: 'https://en.wikipedia.org/wiki/Samoa',
        },
        landmark: {
          name: 'To Sua Ocean Trench',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Trench_from_top_of_ladder_by_tree_To_Sua_Ocean_Trench_Samoa.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Samoa',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'zJ99XvqF',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Samoa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-house-on-white-sand-under-white-clouds-during-daytime-BR1ixF95cGs',
          wikiLink: 'https://en.wikipedia.org/wiki/Samoa',
        },
        landmark: {
          name: 'Immaculate Conception Cathedral',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mosesharold/16675259102/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Immaculate_Conception_Cathedral,_Apia',
          hasLandmark: true,
        },
        city: {
          name: 'Apia',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/125501349@N04/46914345562/',
          wikiLink: 'https://en.wikipedia.org/wiki/Apia',
        },
        landmarksRound: [
          { code: 'CU', number: 6 },
          { code: 'IE', number: 6 },
          { code: 'GL', number: 5 },
        ],
        landmarkPlaces: [
          'Al Bastakiya',
          'Tulum',
          'Visoki Dečani',
          'Roman Baths',
          'Stone House',
          'Sanctuaire de Notre-Dame des Larmes',
          'Pigeon Island',
        ],
        uniqueId: 'ctFKx7Z6',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Samoa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-and-red-rooster-on-road-during-daytime-1K52ZwwDLD4',
          wikiLink: 'https://en.wikipedia.org/wiki/Samoa',
        },
        landmark: {
          name: 'Piula Cave Pool',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mavnlt/24617589974/',
          wikiLink: 'https://en.wikipedia.org/wiki/Piula_Cave_Pool',
          hasLandmark: true,
        },
        city: {
          name: 'Afega',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/80179083@N00/32897807908/',
          wikiLink: 'https://en.wikipedia.org/wiki/Afega',
        },
        landmarksRound: [
          { code: 'ZM', number: 2 },
          { code: 'CI', number: 1 },
          { code: 'VI', number: 1 },
        ],
        landmarkPlaces: [
          'Little Chapel',
          'Kunta Kinteh Island',
          'Tenaru Falls',
          'Tatacoa Desert',
          'Lake Titicaca',
          'Mont Orgueil',
          'Derinkuyu',
        ],
        uniqueId: 'fQdZYvkN',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'San Marino',
    code: 'SM',
    capital: 'City of San Marino',
    lat: 43.76666666,
    long: 12.41666666,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/S1JVabNkXP0',
          wikiLink: 'https://en.wikipedia.org/wiki/Monte_Titano',
        },
        landmark: {
          name: 'Guaita Tower',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/san-marino-tower-architecture-2495109/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guaita',
          hasLandmark: true,
        },
        city: {
          name: 'Serravalle',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:SerravalleRSMPanorama.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Serravalle_(San_Marino)',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'xWrnh4yp',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/san-marino-city-urban-buildings-1966566/',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Marino',
        },
        landmark: {
          name: 'Palazzo Pubblico',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Palazzo_Pubblico_(San_Marino)#/media/File:Palazzo_pubblico_(San_Marino)_-_Day.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Palazzo_Pubblico_(San_Marino)',
          hasLandmark: true,
        },
        city: {
          name: 'Borgo Maggiore',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Borgo_Maggiore#/media/File:Borgo_Maggiore_seen_from_San_Marino_-_June_2016.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Borgo_Maggiore',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'knmGKlnW',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'San Marino',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-many-buildings-noMwZfUFghU',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Marino',
        },
        landmark: {
          name: 'Basilica di San Marino',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/billy_wilson/50256479351/',
          wikiLink: 'https://en.wikipedia.org/wiki/Basilica_di_San_Marino',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'GM', number: 1 },
          { code: 'BR', number: 3 },
          { code: 'PA', number: 4 },
        ],
        landmarkPlaces: [
          'Kerid Crater',
          "Devil's Bridge",
          'The Ruins',
          'Anne Frank House',
          'Salar de Uyuni',
          'Sant Joan de Caselles',
          'Tazumal',
        ],
        uniqueId: 'F6SKG9bm',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'San Marino',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-mountains-in-the-background-NQ30CZe_h_E',
          wikiLink: 'https://en.wikipedia.org/wiki/San_Marino',
        },
        landmark: {
          name: 'Guaita',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-mountain-during-daytime-zW0BxeyvF0w',
          wikiLink: 'https://en.wikipedia.org/wiki/Guaita',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'AU', number: 3 },
          { code: 'FI', number: 4 },
          { code: 'GM', number: 4 },
        ],
        landmarkPlaces: [
          'Imam Husayn Shrine',
          'Al-Aqsa Mosque',
          'Khor Virap Monastery',
          'Shoes on the Danube Bank',
          'Emily Bay',
          'Roman Forum',
          'High Knoll Fort',
        ],
        uniqueId: 'hLcfwnlG',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Sao Tome and Principe',
    code: 'ST',
    capital: 'São Tomé',
    lat: 1,
    long: 7,
    cities: ['São Tomé', 'Santo António'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Sao_Tome_Praia_Inhame_14_%2816247212021%29.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe',
        },
        landmark: {
          name: 'Cao Grande Peak',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Pico_C%C3%A3o_Grande#/media/File:Pico_C%C3%A3o_Grande.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pico_C%C3%A3o_Grande',
          hasLandmark: true,
        },
        city: {
          name: 'São Tomé',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9#/media/File:2012SaoTome-8_(8042889363).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'N9WcP6xd',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/43789968@N05/4056983982',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe',
        },
        landmark: {
          name: 'Lagoa Azul Lighthouse',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Lagoa_Azul_Lighthouse,_S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe_-_20070804.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lagoa_Azul_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Santo Antonio',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Santo_Ant%C3%B3nio#/media/File:Santo_Ant%C3%B3nio,_Capital_of_Pr%C3%ADncipe.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Santo_Ant%C3%B3nio',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'ZvLJ6cMv',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Sao Tome and Principe',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/afdbprojects/49748624168/',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe',
        },
        landmark: {
          name: 'Parque Natural Obô de São Tomé',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ximopons/49639315286/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Parque_Natural_Ob%C3%B4_de_S%C3%A3o_Tom%C3%A9',
          hasLandmark: true,
        },
        city: {
          name: 'São Tomé',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/afdbprojects/49748772448/',
          wikiLink: 'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9',
        },
        landmarksRound: [
          { code: 'TN', number: 2 },
          { code: 'SK', number: 1 },
          { code: 'NI', number: 1 },
        ],
        landmarkPlaces: [
          'Al Zubara Fort',
          'Kwame Nkrumah Mausoleum',
          'Little Bay',
          "Women's Bridge",
          'Alhambra',
          'Demerara Bridge',
          'Sainte-Chapelle',
        ],
        uniqueId: '4gv3pWGt',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Sao Tome and Principe',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ximopons/49736970988/',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe',
        },
        landmark: {
          name: 'São Sebastião Museum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/ximopons/49750336492/',
          wikiLink:
            'https://en.wikipedia.org/wiki/S%C3%A3o_Sebasti%C3%A3o_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Santo António',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/lolo4717/34530868171/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Santo_Ant%C3%B3nio,_S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe',
        },
        landmarksRound: [
          { code: 'MA', number: 3 },
          { code: 'GE', number: 3 },
          { code: 'BO', number: 4 },
        ],
        landmarkPlaces: [
          'Dziani Dzaha',
          'Fasil Ghebbi',
          'Rose Hall',
          'Salvo Palace',
          'The Pitcairn Museum',
          'Buddha Park',
          "Tiger's Nest",
        ],
        uniqueId: '345RFtpC',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    capital: 'Riyadh',
    lat: 25,
    long: 45,
    cities: ['Mecca', 'Riyadh', 'Tabuk', 'Al Khobar', 'Taif', 'Jeddah'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/rzzS0_pMsD0',
          wikiLink: 'https://en.wikipedia.org/wiki/Al-Masjid_an-Nabawi',
        },
        landmark: {
          name: 'Masjid al-Haram',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/makkah-ksa-saudi-arabia-3986709/',
          wikiLink: 'https://en.wikipedia.org/wiki/Masjid_al-Haram',
          hasLandmark: true,
        },
        city: {
          name: 'Jeddah',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/jeddah-high-rise-saudi-arabia-652162/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jeddah',
        },
        landmarksRound: [
          { code: 'SN', number: 2 },
          { code: 'BN', number: 1 },
          { code: 'MZ', number: 1 },
        ],
        landmarkPlaces: [
          'Dhakeshwari Temple',
          'Wat Arun',
          'Monument to the Heroes of the Restoration',
          'Gyeongbukgong Palace',
          'Trevi Fountain',
          'Christ the Redeemer',
          'Rijksmuseum',
        ],
        uniqueId: 'GrKPtq9Z',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mosque-islam-saudi-arabia-islamic-2654552/',
          wikiLink: 'https://en.wikipedia.org/wiki/Saudi_Arabia',
        },
        landmark: {
          name: 'Kaaba',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kaaba-the-pilgrim-s-guide-religion-4758809/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaaba',
          hasLandmark: true,
        },
        city: {
          name: 'Mecca',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mecca-mekkah-saudi-arabia-saudi-66970/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mecca',
        },
        landmarksRound: [
          { code: 'HR', number: 1 },
          { code: 'IN', number: 1 },
          { code: 'BH', number: 1 },
        ],
        landmarkPlaces: [
          'Vaduz Cathedral',
          'Predjama Castle',
          'Annandale Waterfall',
          'Cape of Good Hope',
          'Taj Mahal',
          'Narikala Fortress',
          'Chimi Lhakhang',
        ],
        uniqueId: '3QvPQqsG',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Saudi Arabia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/sand-dune-and-mountain-scenery-7vOpzRF33Zo',
          wikiLink: 'https://en.wikipedia.org/wiki/Saudi_Arabia',
        },
        landmark: {
          name: 'Quba Mosque',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/197895451@N06/53718999128/',
          wikiLink: 'https://en.wikipedia.org/wiki/Quba_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Riyadh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-photography-of-city-rkRYbmPuhOE',
          wikiLink: 'https://en.wikipedia.org/wiki/Riyadh',
        },
        landmarksRound: [
          { code: 'KG', number: 5 },
          { code: 'ET', number: 1 },
          { code: 'CL', number: 6 },
        ],
        landmarkPlaces: [
          'Copper Mine',
          'Fort Santiago',
          'Pedro St. James Castle',
          'Chapel of the Snows',
          'Khor Virap Monastery',
          'Winter Palace',
          'Cacheu Fort',
        ],
        uniqueId: 'JgJS5dKk',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Saudi Arabia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-row-of-palm-trees-with-mountains-in-the-background-ARpmY5qq7Lk',
          wikiLink: 'https://en.wikipedia.org/wiki/Saudi_Arabia',
        },
        landmark: {
          name: "Prophet's Mosque",
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-yellow-painted-castle-qbvAboqHkBg',
          wikiLink: 'https://en.wikipedia.org/wiki/Prophet%27s_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Jeddah',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-during-sunset-ZC1go68bTic',
          wikiLink: 'https://en.wikipedia.org/wiki/Jeddah',
        },
        landmarksRound: [
          { code: 'KG', number: 1 },
          { code: 'GA', number: 1 },
          { code: 'IT', number: 3 },
        ],
        landmarkPlaces: [
          'Harrison Point Lighthouse',
          'Notre-Dame of Cap-Haitian',
          'California Lighthouse',
          'Faisal Mosque',
          'Kuang Si Falls',
          'Ben Amera',
          'Villers Abbey',
        ],
        uniqueId: 'KWqqSJVX',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Senegal',
    code: 'SN',
    capital: 'Dakar',
    lat: 14,
    long: -14,
    cities: [
      'Kaolack',
      'Dakar',
      'Saint Louis',
      'Theis',
      'Ziguinchor',
      'Louga',
      'Touba',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-n1rIjE55Mw',
          wikiLink: 'https://en.wikipedia.org/wiki/Saint-Louis,_Senegal',
        },
        landmark: {
          name: 'House of Slaves',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/House_of_Slaves#/media/File:Senegal_Gor%C3%A9e_(8).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/House_of_Slaves',
          hasLandmark: true,
        },
        city: {
          name: 'Kaolack',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Main_street_in_Kaolack_%28Senegal%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaolack',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'pBl28rGq',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/fisherman-mosque-dakar-senegal-246976/',
          wikiLink: 'https://en.wikipedia.org/wiki/Senegal',
        },
        landmark: {
          name: 'African Renaissance Monument',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/African_Renaissance_Monument#/media/File:African_Renaissance_Monument_(5502494604).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/African_Renaissance_Monument',
          hasLandmark: true,
        },
        city: {
          name: 'Dakar',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dakar#/media/File:Dakars_Mamelles_(5824544243).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dakar',
        },
        landmarksRound: [
          { code: 'BH', number: 1 },
          { code: 'RU', number: 2 },
          { code: 'BO', number: 1 },
        ],
        landmarkPlaces: [
          'Herat Citadel',
          'Predjama Castle',
          'Old Panama',
          'Blue Lagoon',
          'Timgad',
          'Wat Xiengthong',
          'Dragon and Tiger Pagodas',
        ],
        uniqueId: 'mMqNsGq5',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Senegal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/blue-and-white-boat-on-water-near-city-buildings-during-daytime--n1rIjE55Mw',
          wikiLink: 'https://en.wikipedia.org/wiki/Senegal',
        },
        landmark: {
          name: 'Great Mosque of Touba',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/bethmoon527/46627027081/',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Mosque_of_Touba',
          hasLandmark: true,
        },
        city: {
          name: 'Dakar',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-a-high-point-of-view-IbhDrmssndU',
          wikiLink: 'https://en.wikipedia.org/wiki/Dakar',
        },
        landmarksRound: [
          { code: 'PH', number: 4 },
          { code: 'BA', number: 3 },
          { code: 'NL', number: 4 },
        ],
        landmarkPlaces: [
          'Pyramid of Tirana',
          'Castle Cornet',
          'Mostar Old Bridge',
          'Villers Abbey',
          'Old Bridge',
          'Richat Structure',
          'Tarawa',
        ],
        uniqueId: 'GrxC3gnY',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Senegal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-houses-near-green-trees-under-white-clouds-during-daytime-8nb36vQrjTc',
          wikiLink: 'https://en.wikipedia.org/wiki/Senegal',
        },
        landmark: {
          name: 'Mosque of Divinity',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/askthelai/8552960329/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mosque_of_Divinity',
          hasLandmark: true,
        },
        city: {
          name: 'Touba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-tower-in-the-middle-of-a-city-y5QZJVEqw2c',
          wikiLink: 'https://en.wikipedia.org/wiki/Touba,_Senegal',
        },
        landmarksRound: [
          { code: 'CM', number: 4 },
          { code: 'AM', number: 6 },
          { code: 'MK', number: 3 },
        ],
        landmarkPlaces: [
          'Stonehenge',
          'Senado Square',
          'Oceanographic Museum of Monaco',
          'Köl-Suu',
          'Mount Nyriagongo',
          'Larabanga Mosque',
          'Blue Mountains',
        ],
        uniqueId: '5pc5G5p3',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Serbia',
    code: 'RS',
    capital: 'Belgrade',
    lat: 44,
    long: 21,
    cities: [
      'Novi Sad',
      'Belgrade',
      'Nis',
      'Subotica',
      'Zrenjanin',
      'Kragujevac',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/xHnomVS0SiU',
          wikiLink: 'https://en.wikipedia.org/wiki/Belgrade',
        },
        landmark: {
          name: 'Saint Sava Temple',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/belgrade-serbia-5010494/',
          wikiLink: 'https://en.wikipedia.org/wiki/Church_of_Saint_Sava',
          hasLandmark: true,
        },
        city: {
          name: 'Novi Sad',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/river-sunset-evening-buildings-6519448/',
          wikiLink: 'https://en.wikipedia.org/wiki/Novi_Sad',
        },
        landmarksRound: [
          { code: 'FR', number: 1 },
          { code: 'GH', number: 1 },
          { code: 'GH', number: 2 },
        ],
        landmarkPlaces: [
          'Pyramiden',
          'Cliffs of Moher',
          'Palace of Peace and Reconciliation',
          'Dubai Frame',
          'Grandvalira',
          'Chimi Lhakhang',
          'Grand Ducal Palace',
        ],
        uniqueId: 'NcnCMgN3',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/%c4%91erdap-serbia-castle-river-old-2129567/',
          wikiLink: 'https://en.wikipedia.org/wiki/Serbia',
        },
        landmark: {
          name: 'Golubac Fortress',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/serbia-golubac-golubac-fortress-5332404/',
          wikiLink: 'https://en.wikipedia.org/wiki/Golubac_Fortress',
          hasLandmark: true,
        },
        city: {
          name: 'Belgrade',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/architecture-belgrade-gardos-kula-4569749/',
          wikiLink: 'https://en.wikipedia.org/wiki/Belgrade',
        },
        landmarksRound: [
          { code: 'MX', number: 2 },
          { code: 'GQ', number: 1 },
          { code: 'US', number: 1 },
        ],
        landmarkPlaces: [
          'Grandvalira',
          'Monteverde Cloud Forest',
          'Cologne Cathedral',
          'CN Tower',
          'Ostrog Monastery',
          'Blue Grotto',
          'Dragon and Tiger Pagodas',
        ],
        uniqueId: 'tgBBbTsC',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Serbia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-surrounded-by-mountains-MyYE8w1Qgeo',
          wikiLink: 'https://en.wikipedia.org/wiki/Serbia',
        },
        landmark: {
          name: 'Skull Tower',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mikey1952uk/52500747875/',
          wikiLink: 'https://en.wikipedia.org/wiki/Skull_Tower',
          hasLandmark: true,
        },
        city: {
          name: 'Belgrade',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-buildings-during-daytime-UUBctovhljY',
          wikiLink: 'https://en.wikipedia.org/wiki/Belgrade',
        },
        landmarksRound: [
          { code: 'WS', number: 1 },
          { code: 'GA', number: 1 },
          { code: 'CY', number: 4 },
        ],
        landmarkPlaces: [
          "Prince's Palace of Monaco",
          'Ostrog Monastery',
          'Point of Ayre Lighthouse',
          'Tsodilo',
          'Tadrart Acacus',
          'Rizal Park',
          'Allahverdi Khan Bridge',
        ],
        uniqueId: 'SMzpTrDb',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Zlatibor',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-lake-surrounded-by-snow-covered-hills-and-trees-5dQdruCugWs',
          wikiLink: 'https://en.wikipedia.org/wiki/Zlatibor',
        },
        landmark: {
          name: "St. Mark's Church, Belgrade",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/98688033@N03/9503368379/',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Mark%27s_Church,_Belgrade',
          hasLandmark: true,
        },
        city: {
          name: 'Subotica',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-tall-buildings-td3WCckRCGs',
          wikiLink: 'https://en.wikipedia.org/wiki/Subotica',
        },
        landmarksRound: [
          { code: 'EE', number: 4 },
          { code: 'MY', number: 6 },
          { code: 'GH', number: 2 },
        ],
        landmarkPlaces: [
          'Palais de Congres',
          'Uganda National Mosque',
          "King's Palace Museum",
          "Fisherman's Bastion",
          'La Sagrada Familia',
          'Seven Rile Lakes',
          'Molinere Underwater Sculpture Park',
        ],
        uniqueId: 'JvB8RLJ4',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Seychelles',
    code: 'SC',
    capital: 'Victoria',
    lat: -4.58333333,
    long: 55.66666666,
    cities: ['Victoria', 'Anse Royale'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/HoNnEDKIar4',
          wikiLink: 'https://en.wikipedia.org/wiki/Seychelles',
        },
        landmark: {
          name: 'Anse Lazio',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/seychelles-anse-lazio-praslin-2286750/',
          wikiLink: 'https://en.wikipedia.org/wiki/Anse_Lazio',
          hasLandmark: true,
        },
        city: {
          name: 'Victoria',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Seychelles#/media/File:Ile_de_Mahe_-_Victoria_(9).JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria,_Seychelles',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'fhdxyqwn',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/seychelles-landscape-sea-beach-4916045/',
          wikiLink: 'https://en.wikipedia.org/wiki/Seychelles',
        },
        landmark: {
          name: 'Morne Seychellois',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Morne_Seychellois_Mountain.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Morne_Seychellois',
          hasLandmark: true,
        },
        city: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Beau_Vallon_%28Seychelles%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Beau_Vallon,_Seychelles',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'HjzmJBRD',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'La Digue',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-beach-with-a-hut-on-it-Iqc0NV05YqQ',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Digue',
        },
        landmark: {
          name: 'Anse Lazio',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/denis5000/21045364542/',
          wikiLink: 'https://en.wikipedia.org/wiki/Anse_Lazio',
          hasLandmark: true,
        },
        city: {
          name: 'Victoria',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/bhaktapur/47660089762/',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria,_Seychelles',
        },
        landmarksRound: [
          { code: 'CV', number: 4 },
          { code: 'KZ', number: 1 },
          { code: 'AF', number: 1 },
        ],
        landmarkPlaces: [
          'Kalandula Falls',
          'Vaduz Castle',
          'Herat Citadel',
          'Temple of Literature',
          'Palace of Peace and Reconciliation',
          'Baiterek',
          'Sao Filipe Royale Fortress',
        ],
        uniqueId: 'fSJw2hVL',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Seychelles',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-during-daytime-_ropNcPmpW8',
          wikiLink: 'https://en.wikipedia.org/wiki/Seychelles',
        },
        landmark: {
          name: 'Sainte Anne Marine National Park',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/travelourplanet/6291134875/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Sainte_Anne_Marine_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Anse Royale',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/130928817@N02/16935505304/',
          wikiLink: 'https://en.wikipedia.org/wiki/Anse_Royale,_Seychelles',
        },
        landmarksRound: [
          { code: 'LV', number: 6 },
          { code: 'BI', number: 5 },
          { code: 'MS', number: 6 },
        ],
        landmarkPlaces: [
          'Cheshire Hall Plantation',
          'Emerald Pool',
          'Te Rua Manga (The Needle)',
          'Kastelholm Castle',
          "Notre-Dame d'Afrique",
          'Tomb of Shah Rukn-e-Alam',
          'Serengeti',
        ],
        uniqueId: 'Hq6xwtWc',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    capital: 'Freetown',
    lat: 8.5,
    long: -11.5,
    cities: ['Freetown', 'Bo'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/coast-sea-sierra-leone-island-6508040/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sierra_Leone',
        },
        landmark: {
          name: 'Cotton Tree',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cotton_Tree_(Sierra_Leone)#/media/File:Cotton_Tree_(Sierra_Leone).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cotton_Tree_(Sierra_Leone)',
          hasLandmark: true,
        },
        city: {
          name: 'Freetown',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Freetown#/media/File:Freetown-aerialview.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Freetown',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'XdhbrMzH',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sierra-leone-town-coast-sea-ocean-6508049/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sierra_Leone',
        },
        landmark: {
          name: 'Bunce Island',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bunce_Island#/media/File:Bunce_Island_Beach.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bunce_Island',
          hasLandmark: true,
        },
        city: {
          name: 'Kenema',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kenema#/media/File:Street_in_Kenema_03.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kenema',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'hCLKh3Mg',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Sierra Leone',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-a-body-of-water-in-the-background-Vqd22Lb0rJc',
          wikiLink: 'https://en.wikipedia.org/wiki/Sierra_Leone',
        },
        landmark: {
          name: 'Banana Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/51937301@N03/8117135686/',
          wikiLink: 'https://en.wikipedia.org/wiki/Banana_Islands',
          hasLandmark: true,
        },
        city: {
          name: 'Freetown',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-daytime-e8msPgapx0U',
          wikiLink: 'https://en.wikipedia.org/wiki/Freetown',
        },
        landmarksRound: [
          { code: 'HU', number: 1 },
          { code: 'BA', number: 4 },
          { code: 'GH', number: 2 },
        ],
        landmarkPlaces: [
          'Plaošnik',
          'Tsodilo',
          'Fort Christian',
          'Machu Picchu',
          'Tsodilo Hills',
          'Republic Square',
          'Mustique',
        ],
        uniqueId: 'J2kwxpRq',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Freetown',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-on-a-rocky-hill-7S028lQqTSs',
          wikiLink: 'https://en.wikipedia.org/wiki/Freetown',
        },
        landmark: {
          name: 'Outamba-Kilimi National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/51937301@N03/8118602674/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Outamba-Kilimi_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Bo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-foggy-city-eEbM6QXpu-Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Bo,_Sierra_Leone',
        },
        landmarksRound: [
          { code: 'GH', number: 2 },
          { code: 'EE', number: 5 },
          { code: 'PN', number: 3 },
        ],
        landmarkPlaces: [
          'Hagia Sophia',
          'Kunta Kinteh Island',
          'Cap 110',
          'Gorkhi-Terelj National Park',
          'Amédée Lighthouse',
          'Derinkuyu',
          'Limu Pools',
        ],
        uniqueId: 'RFnVwYGj',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Singapore',
    code: 'SG',
    capital: 'Singapore',
    lat: 1.36666666,
    long: 103.8,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/McM5BW4jQHc',
          wikiLink: 'https://en.wikipedia.org/wiki/Gardens_by_the_Bay',
        },
        landmark: {
          name: 'Marina Bay Sands',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/singapore-marina-bay-sands-landmark-1990090/',
          wikiLink: 'https://en.wikipedia.org/wiki/Marina_Bay_Sands',
          hasLandmark: true,
        },
        city: {
          name: 'Jurong East',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Jurong_East#/media/File:Jurong_East_HDBscape_(8407109505).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Jurong_East',
        },
        landmarksRound: [
          { code: 'MA', number: 2 },
          { code: 'BS', number: 2 },
          { code: 'CO', number: 1 },
        ],
        landmarkPlaces: [
          'Maiden Tower',
          'Xunatunich Mayan Ruins',
          'Wat Xiengthong',
          'Arahoho Blowhole',
          'Hazrat Ali Mazar',
          'Turku Castle',
          'Floralis Generica',
        ],
        uniqueId: 'cTMPmYyM',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/buildings-skyline-city-lights-647400/',
          wikiLink: 'https://en.wikipedia.org/wiki/Singapore',
        },
        landmark: {
          name: 'Singapore Flyer',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/singapore-ferris-wheel-big-wheel-243671/',
          wikiLink: 'https://en.wikipedia.org/wiki/Singapore_Flyer',
          hasLandmark: true,
        },
        city: {
          name: 'Bukit Batok',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bukit_Batok#/media/File:BukitBatok.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Bukit_Batok',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'MjVHSTTt',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Singapore',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/multicolored-lights-on-posts-4HVCsDOg0qI',
          wikiLink: 'https://en.wikipedia.org/wiki/Singapore',
        },
        landmark: {
          name: 'Marina Bay Sands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-buildings-near-body-of-water-Ncmd8uLe8H0',
          wikiLink: 'https://en.wikipedia.org/wiki/Marina_Bay_Sands',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'NG', number: 4 },
          { code: 'DK', number: 1 },
          { code: 'RW', number: 3 },
        ],
        landmarkPlaces: [
          'Florence Cathedral',
          'Neuschwanstein Castle',
          'Ark of Bukhara',
          'Champagne Reef, Dominica',
          'Saunders Island',
          'Angkor Wat',
          'National Park of American Samoa',
        ],
        uniqueId: 'ZvRs3DN7',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Singapore',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-at-the-market-C8gvt2LMX94',
          wikiLink: 'https://en.wikipedia.org/wiki/Singapore',
        },
        landmark: {
          name: 'National Museum of Singapore',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/tree-in-front-of-beige-building-cJ8XCFz2c20',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_Museum_of_Singapore',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'GY', number: 1 },
          { code: 'FK', number: 6 },
          { code: 'BB', number: 3 },
        ],
        landmarkPlaces: [
          'Salomon Islands',
          'St. Peter the Apostle Cathedral',
          'Tenaru Falls',
          'Al Saleh Mosque',
          'Winter Palace',
          'Fouta Djallon',
          'Basilique Marie-Reine-des-Apôtres',
        ],
        uniqueId: 'Vsz7b6RX',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Slovakia',
    code: 'SK',
    capital: 'Bratislava',
    lat: 48.66666666,
    long: 19.5,
    cities: [
      'Kosice',
      'Bratislava',
      'Nitra',
      'Presov',
      'Trencin',
      'Bardejov',
      'Košice',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vgPgg8X2NyA',
          wikiLink: 'https://en.wikipedia.org/wiki/Bratislava',
        },
        landmark: {
          name: 'Bratislava Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bratislava-slovakia-castle-evening-3641286/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bratislava_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Kosice',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kosice-outlook-the-tower-the-city-1380221/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ko%C5%A1ice',
        },
        landmarksRound: [
          { code: 'TR', number: 1 },
          { code: 'VU', number: 1 },
          { code: 'MM', number: 2 },
        ],
        landmarkPlaces: [
          'The Marble Mountains',
          'Kittiwake Shipwreck',
          'Xunatunich Mayan Ruins',
          'Aleppo Citadel',
          'Marina Bay Sands',
          'Dhakeshwari Temple',
          "Fisherman's Bastion",
        ],
        uniqueId: '75Nt8DPt',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tatra-mountains-slovakia-lake-7231545/',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovakia',
        },
        landmark: {
          name: 'Devin Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bratislava-slovakia-dev%c3%adn-channel-4401453/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dev%C3%ADn_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Bratislava',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/bratislava-slovakia-1569359/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bratislava',
        },
        landmarksRound: [
          { code: 'BW', number: 1 },
          { code: 'NA', number: 2 },
          { code: 'HN', number: 2 },
        ],
        landmarkPlaces: [
          'Jardin de Balata',
          'Szechenyi Thermal Bath',
          'South Pole (ceremonial)',
          'Golden Gate',
          'The Grand Palace',
          'Rila Monastery',
          'The Blue Mosque',
        ],
        uniqueId: 'QfmNQPRS',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Slovakia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-covered-mountains-RErhr8r1q6s',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovakia',
        },
        landmark: {
          name: 'The Ľubovňa Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-tower-and-a-castle-on-a-hill-8qbuW0pQ5Ns',
          wikiLink:
            'https://en.wikipedia.org/wiki/Star%C3%A1_%C4%BDubov%C5%88a',
          hasLandmark: true,
        },
        city: {
          name: 'Košice',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-old-car-parked-in-a-narrow-alleyway-8QzOpV3SjqQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Ko%C5%A1ice',
        },
        landmarksRound: [
          { code: 'GR', number: 4 },
          { code: 'BR', number: 3 },
          { code: 'BG', number: 1 },
        ],
        landmarkPlaces: [
          'Fortaleza de São José da Amura',
          'Casa de la Vall',
          'National Museum of Arts, Rites and Traditions',
          'Baiterek',
          'Kyrenia Castle',
          'Heydar Mosque',
          'Lake Niamawi',
        ],
        uniqueId: 'KLpwVxCg',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Slovakia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-house-on-snow-covered-ground-during-daytime-krp9O-SbTTA',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovakia',
        },
        landmark: {
          name: 'Kôprovský štít',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-mountain-ranges-under-purple-and-pink-skies--g7axSVst6Y',
          wikiLink:
            'https://sk.wikipedia.org/wiki/K%C3%B4provsk%C3%BD_%C5%A1t%C3%ADt',
          hasLandmark: true,
        },
        city: {
          name: 'Bratislava',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-red-boat-on-river-during-daytime-wI6yVpvU4ic',
          wikiLink: 'https://en.wikipedia.org/wiki/Bratislava',
        },
        landmarksRound: [
          { code: 'MC', number: 3 },
          { code: 'GI', number: 4 },
          { code: 'NR', number: 1 },
        ],
        landmarkPlaces: [
          'Schlossberg (Graz)',
          'Monday Falls',
          'Old Panama',
          'Plage de Grande Anse',
          'São Jorge Castle',
          'Monteverde Cloud Forest',
          'Frida Kahlo Museum',
        ],
        uniqueId: 'YKfwP6Dh',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Slovenia',
    code: 'SI',
    capital: 'Ljubljana',
    lat: 46.11666666,
    long: 14.81666666,
    cities: ['Koper', 'Ljubljana', 'Novo Mesto', 'Kranj', 'Celje', 'Maribor'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/W3FC_bCPw8E',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Bled',
        },
        landmark: {
          name: 'Bled Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/the-needle-bled-slovenia-castle-2746879/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bled_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Koper',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Koper#/media/File:Koper_modern_residential_quarter.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Koper',
        },
        landmarksRound: [
          { code: 'NP', number: 1 },
          { code: 'BA', number: 2 },
          { code: 'EG', number: 1 },
        ],
        landmarkPlaces: [
          'Saint Sava Temple',
          'Tegucigalpa Cathedral',
          'Chamarel Waterfall',
          'Iskanderkul',
          'Al Fateh Grand Mosque',
          'George Washington House',
          'Herat Citadel',
        ],
        uniqueId: 'mHTtVZK5',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/ljubljana-flow-slovenia-bridge-2149704/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ljubljana',
        },
        landmark: {
          name: 'Predjama Castle',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/castle-predjama-castle-67600/',
          wikiLink: 'https://en.wikipedia.org/wiki/Predjama_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Ljubljana',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/europe-ljubljana-castle-3643773/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ljubljana',
        },
        landmarksRound: [
          { code: 'BW', number: 1 },
          { code: 'AU', number: 1 },
          { code: 'AL', number: 1 },
        ],
        landmarkPlaces: [
          'Parthenon',
          'Wat Xiengthong',
          'Pamir Mountains',
          'Santa Catalina Arch',
          'Huka Falls',
          'Temple of Literature',
          "Dunn's River Falls",
        ],
        uniqueId: 'pVsxJ4Yw',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Slovenia',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/view-of-mountain-sQLri8iHb58',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovenia',
        },
        landmark: {
          name: 'Lake Bled',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-near-lake-and-snow-covered-mountain-during-daytime-z5xyqSYE8pE',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Bled',
          hasLandmark: true,
        },
        city: {
          name: 'Ljubljana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-near-brown-concrete-building-during-daytime-It35Occ0TrA',
          wikiLink: 'https://en.wikipedia.org/wiki/Ljubljana',
        },
        landmarksRound: [
          { code: 'KZ', number: 3 },
          { code: 'FR', number: 4 },
          { code: 'MQ', number: 4 },
        ],
        landmarkPlaces: [
          'Petronas Towers',
          'Macau Tower',
          'Mawson Peak',
          'Sultan Abdul Samad Building',
          'Blue Lagoon',
          'Mlilwane Wildlife Sanctuary',
          'Plage de Grande Anse',
        ],
        uniqueId: '8pJm3nDx',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Lake Bled',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-photography-of-white-building-surrounded-by-body-of-water-at-daytime-34RmaD-jVbY',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Bled',
        },
        landmark: {
          name: 'Škocjan Caves',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/%C5%A0kocjan_Caves#/media/File:SkocjanskeJame_2013jpg.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/%C5%A0kocjan_Caves',
          hasLandmark: true,
        },
        city: {
          name: 'Maribor',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-buildings-with-a-tower-_Zp5uh88ueQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Maribor',
        },
        landmarksRound: [
          { code: 'DM', number: 3 },
          { code: 'IQ', number: 1 },
          { code: 'TN', number: 1 },
        ],
        landmarkPlaces: [
          'Annaberg Sugar Plantation',
          'Larabanga Mosque',
          'Laxey Wheel',
          'Ħal Saflieni Hypogeum',
          'Nasir al-Mulk Mosque',
          'Brandenburg Gate',
          'Gediminas Castle Tower',
        ],
        uniqueId: '7HrhT2bj',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Slovenia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/island-surrounded-by-water-and-mountains-at-daytime-pOWBHdgy1Lo',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovenia',
        },
        landmark: {
          name: 'Triglav National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-goats-on-green-grass--x3fTDZkgs8',
          wikiLink: 'https://en.wikipedia.org/wiki/Triglav_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Koper',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-a-hill-top-0gYEIZMaqMQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Koper',
        },
        landmarksRound: [
          { code: 'EE', number: 6 },
          { code: 'EC', number: 5 },
          { code: 'IQ', number: 4 },
        ],
        landmarkPlaces: [
          'Church of Saint Lazarus',
          'Grand Mosque',
          'Parque Colón',
          'Sistine Chapel',
          'Umayyad City Ruins',
          'Big Ben',
          'Blue Mountains',
        ],
        uniqueId: 'F9W4CDmR',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Slovenia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-and-mountains-under-blue-sky-and-white-clouds-during-daytime-vEArMceumaI',
          wikiLink: 'https://en.wikipedia.org/wiki/Slovenia',
        },
        landmark: {
          name: 'Predjama Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-castle-built-into-the-side-of-a-cliff-FcfLAua2iqg',
          wikiLink: 'https://en.wikipedia.org/wiki/Predjama_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Ljubljana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-sidewalk-near-white-concrete-building-during-daytime-eaJGcm5mXEw',
          wikiLink: 'https://en.wikipedia.org/wiki/Ljubljana',
        },
        landmarksRound: [
          { code: 'WF', number: 1 },
          { code: 'BJ', number: 2 },
          { code: 'GA', number: 1 },
        ],
        landmarkPlaces: [
          'Old Panama',
          'Peel Castle',
          'Arch of Marcus Aurelius',
          'Basilica del Voto Nacional',
          'Lopé National Park',
          'Cliffs of Moher',
          'Bissagos Islands',
        ],
        uniqueId: 'QzF43CVL',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    capital: 'Honiara',
    lat: -8,
    long: 159,
    cities: ['Honiara', 'Gizo', 'Auki', 'Kirakira', 'Buala', 'Gizo'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/o7oV-rGy53Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Solomon_Islands',
        },
        landmark: {
          name: 'Tenaru Falls',
          photographer: '',
          imageLink:
            'https://www.pexels.com/photo/anonymous-traveler-enjoying-rapid-waterfall-in-mountains-during-vacation-4153337/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tenaru_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Honiara',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Honiara#/media/File:Honiara_View.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Honiara',
        },
        landmarksRound: [
          { code: 'FK', number: 2 },
          { code: 'CZ', number: 2 },
          { code: 'ER', number: 2 },
        ],
        landmarkPlaces: [
          'Bratislava Castle',
          'Niagara Falls',
          'Sukhbaatar Square',
          'Lukan Longshan Temple',
          'Gangtey Monastery',
          'Al Alam Palace',
          'Tavarua',
        ],
        uniqueId: 'c9gh44FW',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/the-solomon-islands-pacific-4573372/',
          wikiLink: 'https://en.wikipedia.org/wiki/Solomon_Islands',
        },
        landmark: {
          name: 'Marovo Lagoon',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Marovo_Lagoon_%2816_August_2008%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Marovo_Lagoon',
          hasLandmark: true,
        },
        city: {
          name: 'Gizo',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/dfataustralianaid/10721997443',
          wikiLink: 'https://en.wikipedia.org/wiki/Gizo,_Solomon_Islands',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Xnd9cLyC',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Solomon Islands',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/adelaide_archivist/9526168535/',
          wikiLink: 'https://en.wikipedia.org/wiki/Solomon_Islands',
        },
        landmark: {
          name: 'Guadalcanal',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/10065348@N04/1577358288/',
          wikiLink: 'https://en.wikipedia.org/wiki/Guadalcanal',
          hasLandmark: true,
        },
        city: {
          name: 'Auki',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/aboico/6985841677/',
          wikiLink: 'https://en.wikipedia.org/wiki/Auki,_Solomon_Islands',
        },
        landmarksRound: [
          { code: 'SC', number: 4 },
          { code: 'DM', number: 1 },
          { code: 'MR', number: 5 },
        ],
        landmarkPlaces: [
          'Grand Mosque of Bobo-Dioulasso',
          'Mawson Peak',
          'Chapel of the Snows',
          'Harrison Point Lighthouse',
          'Nan Madol',
          'Gateway Arch',
          'Talava Arches',
        ],
        uniqueId: 'jLwXjqJ5',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Solomon Islands',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/152791564@N03/40306538034/',
          wikiLink: 'https://en.wikipedia.org/wiki/Solomon_Islands',
        },
        landmark: {
          name: 'Honiara Central Market',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/samfalagudwan/226213723/',
          wikiLink: 'https://en.wikipedia.org/wiki/Honiara_Central_Market',
          hasLandmark: true,
        },
        city: {
          name: 'Honiara',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/79191095@N00/2391931212/',
          wikiLink: 'https://en.wikipedia.org/wiki/Honiara',
        },
        landmarksRound: [
          { code: 'PA', number: 3 },
          { code: 'LT', number: 1 },
          { code: 'PK', number: 2 },
        ],
        landmarkPlaces: [
          'Cape of Good Hope',
          'The Marble Mountains',
          'Casa Loma',
          'Tarawa',
          'Cameroon Reunification',
          'Silver Pagoda',
          'Naval Support Facility Diego Garcia',
        ],
        uniqueId: '3wX2ZKzv',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Somalia',
    code: 'SO',
    capital: 'Mogadishu',
    lat: 10,
    long: 49,
    cities: ['Mogadishu'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/I7c0n1_7dZs',
          wikiLink: 'https://en.wikipedia.org/wiki/Mogadishu',
        },
        landmark: {
          name: 'Jazeera Beach',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Jazeera_Beach#/media/File:Jazeera_beach_view.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Jazeera_Beach',
          hasLandmark: true,
        },
        city: {
          name: 'Mogadishu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mogadishu#/media/File:Pexels-yahye-ali-ahmed-3720815.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mogadishu',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'V5t6hGnZ',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/river-somalia-water-5049398/',
          wikiLink: 'https://en.wikipedia.org/wiki/Somalia',
        },
        landmark: {
          name: 'Daallo Mountain',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Daallo_Mountain_-_Erigavo,_Sanaag_Region,_Somaliland_05.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Daallo_Mountain',
          hasLandmark: true,
        },
        city: {
          name: 'Merca',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:2013_02_02_AMISOM_Merca_G_%288465140792%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Merca',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'SJfXHJwB',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Somalia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-rocky-beach-with-a-building-in-the-background-I7c0n1_7dZs',
          wikiLink: 'https://en.wikipedia.org/wiki/Somalia',
        },
        landmark: {
          name: 'Mosque of Islamic Solidarity',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/eriyori/28816276178/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Mosque_of_Islamic_Solidarity',
          hasLandmark: true,
        },
        city: {
          name: 'Mogadishu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/yusuf-dahirsomaliland/2384514112/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mogadishu',
        },
        landmarksRound: [
          { code: 'GB', number: 1 },
          { code: 'SM', number: 3 },
          { code: 'LY', number: 5 },
        ],
        landmarkPlaces: [
          'Monument to Party Founding',
          'Mount St. Benedict Abbey',
          'Bouni Beach',
          'Heydar Aliyev Center',
          'Uyuni Salt Flat',
          'Gateway Arch',
          'Matobo National Park',
        ],
        uniqueId: 'XW6thMqZ',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Somalia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-rock-formation-beside-body-of-water-during-daytime-dfoAUysuiBI',
          wikiLink: 'https://en.wikipedia.org/wiki/Somalia',
        },
        landmark: {
          name: 'Cattedrale di Mogadiscio',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/david_mutua/30802619371/in/album-72157675192545980',
          wikiLink: 'https://it.wikipedia.org/wiki/Cattedrale_di_Mogadiscio',
          hasLandmark: true,
        },
        city: {
          name: 'Mogadishu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-white-car-driving-down-a-street-next-to-a-tall-building-ldc62SGkCis',
          wikiLink: 'https://en.wikipedia.org/wiki/Mogadishu',
        },
        landmarksRound: [
          { code: 'ER', number: 3 },
          { code: 'KR', number: 2 },
          { code: 'NG', number: 1 },
        ],
        landmarkPlaces: [
          'Great Mosque of Djenné',
          'Mawson Peak',
          'La Corbière',
          'Monument du 22 novembre',
          'La Moneda Palace',
          'Fort Nuestra Senora de la Soledad',
          'Tash Rabat',
        ],
        uniqueId: '5F7V3CCQ',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'South Africa',
    code: 'ZA',
    capital: 'Pretoria',
    lat: -29,
    long: 24,
    cities: [
      'Cape Town',
      'Johannesburg',
      'Pretoria',
      'Durban',
      'Gqeberha',
      'Pietermaritzburg',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/1GgWbP74phY',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Town',
        },
        landmark: {
          name: 'Cape of Good Hope',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/south-africa-cape-town-cape-997540/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_of_Good_Hope',
          hasLandmark: true,
        },
        city: {
          name: 'Cape Town',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cape-town-table-mountain-waterfront-1562907/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Town',
        },
        landmarksRound: [
          { code: 'CL', number: 1 },
          { code: 'MN', number: 2 },
          { code: 'LT', number: 1 },
        ],
        landmarkPlaces: [
          'Christ the Redeemer',
          'Ruhyyet Palace',
          'Alexander Nevsky Cathedral',
          'Golubac Fortress',
          'Akershus Fortress',
          'Langkawi Sky Bridge',
          'Iskanderkul',
        ],
        uniqueId: 'R2cxKzTf',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/capetown-beach-sunset-aerial-view-2295679/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Town',
        },
        landmark: {
          name: 'Voortrekker Monument',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/voortrekker-monument-monument-392629/',
          wikiLink: 'https://en.wikipedia.org/wiki/Voortrekker_Monument',
          hasLandmark: true,
        },
        city: {
          name: 'Johannesburg',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/johannesburg-south-africa-city-408643/',
          wikiLink: 'https://en.wikipedia.org/wiki/Johannesburg',
        },
        landmarksRound: [
          { code: 'LK', number: 1 },
          { code: 'CA', number: 2 },
          { code: 'UY', number: 1 },
        ],
        landmarkPlaces: [
          'Leaning Tower of Pisa',
          'Gangtey Monastery',
          'Blue Grotto',
          "Diocletian's Palace",
          'Salvo Palace',
          'Galle Fort',
          'Karnak',
        ],
        uniqueId: 'Wws8dvgY',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'South Africa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-city-and-mountain-J-yDj62KSxE',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Africa',
        },
        landmark: {
          name: 'Voortrekker Monument',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/aohrmund/2371138030/',
          wikiLink: 'https://en.wikipedia.org/wiki/Voortrekker_Monument',
          hasLandmark: true,
        },
        city: {
          name: 'Cape Town',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/ocean-water-during-sunset-6IItkfnl-Jg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cape_Town',
        },
        landmarksRound: [
          { code: 'KR', number: 2 },
          { code: 'BM', number: 2 },
          { code: 'GY', number: 5 },
        ],
        landmarkPlaces: [
          'Paphos Archaeological Park',
          'Lighthouse Dona Maria Pia',
          'Schlossberg (Graz)',
          'Pukapuka',
          'Predjama Castle',
          'Grand Ducal Palace',
          'Pula Arena',
        ],
        uniqueId: 'GHVXSY98',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'South Africa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/african-penguins-on-seashore-beside-boulder-UjpEGHu8uNU',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Africa',
        },
        landmark: {
          name: 'Constitution Hill',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/south-african-tourism/17068473644/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Constitution_Hill,_Johannesburg',
          hasLandmark: true,
        },
        city: {
          name: 'Pretoria',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/daniel_arseniuk/16525700851/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pretoria',
        },
        landmarksRound: [
          { code: 'FO', number: 3 },
          { code: 'KR', number: 2 },
          { code: 'MR', number: 1 },
        ],
        landmarkPlaces: [
          'Tomb of Hafez',
          'Church of Our Lady of the Assumption',
          'Christ the King Cathedral',
          'Nasir al-Mulk Mosque',
          'Hazrat Ali Mazar',
          'Punakha Dzong',
          'Tsingy Rouge',
        ],
        uniqueId: 'HK9N8J9D',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'South Georgia',
    code: 'GS',
    capital: 'King Edward Point',
    lat: -54.5,
    long: -37,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Z3EwKKX513k',
          wikiLink:
            'https://en.wikipedia.org/wiki/South_Georgia_and_the_South_Sandwich_Islands',
        },
        landmark: {
          name: 'Grytviken Church',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Norwegian_Anglican_Church,_Grytviken#/media/File:Grytviken_church.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Norwegian_Anglican_Church,_Grytviken',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'BA', number: 2 },
          { code: 'FK', number: 2 },
          { code: 'LB', number: 1 },
        ],
        landmarkPlaces: [
          'Aleppo Citadel',
          'Wat Xiengthong',
          'Epupa Falls',
          'Bellapais Abbey',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Blue Lagoon',
          'Monastery of St. Naum',
        ],
        uniqueId: 'CwjsDh6F',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/south-georgia-king-penguins-4709461/',
          wikiLink:
            'https://en.wikipedia.org/wiki/South_Georgia_and_the_South_Sandwich_Islands',
        },
        landmark: {
          name: 'Mount Paget',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mount_Paget#/media/File:Mount_Paget.jpeg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Paget',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'IQ', number: 1 },
          { code: 'JM', number: 1 },
          { code: 'MA', number: 1 },
        ],
        landmarkPlaces: [
          'Ruhyyet Palace',
          'Acropolis',
          'Salvo Palace',
          'Deception Island',
          'Saint Sava Temple',
          'Old Dongola',
          'Dragon and Tiger Pagodas',
        ],
        uniqueId: 'YgJzN7tx',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'South Korea',
    code: 'KR',
    capital: 'Seoul',
    lat: 37,
    long: 127.5,
    cities: [
      'Busan',
      'Incheon',
      'Seoul',
      'Daegu',
      'Gwangju',
      'Ulsan',
      'Jeonju',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/xRAEPmP9M8s',
          wikiLink: 'https://en.wikipedia.org/wiki/Haedong_Yonggungsa',
        },
        landmark: {
          name: 'Gyeongbukgong Palace',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Gyeongbokgung#/media/File:%EB%B0%A4%EC%9D%98_%EA%B2%BD%EB%B3%B5%EA%B6%81.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gyeongbokgung',
          hasLandmark: true,
        },
        city: {
          name: 'Busan',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/building-high-rise-building-beach-2380214/',
          wikiLink: 'https://en.wikipedia.org/wiki/Busan',
        },
        landmarksRound: [
          { code: 'LB', number: 1 },
          { code: 'IR', number: 2 },
          { code: 'PA', number: 1 },
        ],
        landmarkPlaces: [
          'Tokyo Skytree',
          'Maiden Tower',
          'Amberd',
          'Arc de Triomphe',
          'Valle de Cocora',
          'The Marble Mountains',
          'Nizwa Fort',
        ],
        uniqueId: 'pbz2f27q',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/business-bar-restaurant-shield-5475283/',
          wikiLink: 'https://en.wikipedia.org/wiki/Seoul',
        },
        landmark: {
          name: 'Lotto World',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lotte_World#/media/File:Lotte_World_morning_view_6.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lotte_World',
          hasLandmark: true,
        },
        city: {
          name: 'Incheon',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/incheon-songdo-building-1754841/',
          wikiLink: 'https://en.wikipedia.org/wiki/Incheon',
        },
        landmarksRound: [
          { code: 'MK', number: 1 },
          { code: 'BB', number: 1 },
          { code: 'EG', number: 1 },
        ],
        landmarkPlaces: [
          'Valle de Cocora',
          'Charles Bridge',
          'Stone House',
          'Christ Church Cathedral',
          'Hofburg Palace',
          'Tegucigalpa Cathedral',
          'Bratislava Castle',
        ],
        uniqueId: 'qPjqdHVZ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'South Korea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-surrounded-by-a-lush-green-hillside-EVtsYVN6r7U',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Korea',
        },
        landmark: {
          name: 'Bongeunsa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/60914930@N00/4226203968/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bongeunsa',
          hasLandmark: true,
        },
        city: {
          name: 'Jeonju',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/city-during-day-9im7JmZKDpY',
          wikiLink: 'https://en.wikipedia.org/wiki/Jeonju',
        },
        landmarksRound: [
          { code: 'TC', number: 2 },
          { code: 'BE', number: 5 },
          { code: 'CK', number: 1 },
        ],
        landmarkPlaces: [
          'Stonehenge',
          'Etosha National Park',
          'Petra',
          'Hungarian Parliament Building',
          'Lake Piso',
          'São Jorge Castle',
          'Basilica of Our Lady of Guadalupe',
        ],
        uniqueId: 'rkvGswBj',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'South Korea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-lots-of-tall-buildings-in-the-background-2SfRk-QaY40',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Korea',
        },
        landmark: {
          name: 'Haedong Yonggungsa',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/24205958@N05/48758282657/',
          wikiLink: 'https://en.wikipedia.org/wiki/Haedong_Yonggungsa',
          hasLandmark: true,
        },
        city: {
          name: 'Seoul',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/empty-street-between-houses--e6Xu27_T50',
          wikiLink: 'https://en.wikipedia.org/wiki/Seoul',
        },
        landmarksRound: [
          { code: 'BH', number: 2 },
          { code: 'CN', number: 2 },
          { code: 'DK', number: 1 },
        ],
        landmarkPlaces: [
          'Tulum',
          'Christianskirkjan',
          'Morne Seychellois',
          'Chichen Itza',
          'Fort Frederick',
          'Mata-Utu Cathedral',
          'Paradise Island',
        ],
        uniqueId: 'z9S5cG49',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'South Sudan',
    code: 'SS',
    capital: 'Juba',
    lat: 7,
    long: 30,
    cities: ['Malakal', 'Juba'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Juba#/media/File:John_Garang_Mausoleum_Square_in_Juba.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Juba',
        },
        landmark: {
          name: 'Equatoria Tower',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:UAP_Equatoria_Tower.jpg',
          wikiLink: '',
          hasLandmark: true,
        },
        city: {
          name: 'Bor',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bor,_South_Sudan#/media/File:Merol_Market,_Bor_Town,_Jonglei_State,_South_Sudan.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bor,_South_Sudan',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'z5hm8MjQ',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:JUBA_VIEW.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Juba',
        },
        landmark: {
          name: 'Juba Bridge',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Juba#/media/File:Sudan_Juba_bridge.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Juba_Nile_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Juba',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Juba#/media/File:JUBA_VIEW.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Juba',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'JnVXBhs5',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'South Sudan',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/worldhumanitariansummit/27097432156/',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Sudan',
        },
        landmark: {
          name: 'Boma National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/121503917@N08/13735685703/',
          wikiLink: 'https://en.wikipedia.org/wiki/Boma_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Malakal',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/bbcworldservice/5898016915/',
          wikiLink: 'https://en.wikipedia.org/wiki/Malakal',
        },
        landmarksRound: [
          { code: 'GW', number: 6 },
          { code: 'NF', number: 3 },
          { code: 'CY', number: 2 },
        ],
        landmarkPlaces: [
          'Tavurvur Volcano',
          'Atlas Cove',
          'Nasir al-Mulk Mosque',
          'Sundarbans',
          'Vaduz Castle',
          'Mount Fuji',
          'Cintra Bay',
        ],
        uniqueId: 'gpNMXgnL',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'South Sudan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/white-aircraft-7MXH1282qaM',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Sudan',
        },
        landmark: {
          name: 'Nimule National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/121503917@N08/13420286774/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nimule_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Juba',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/tom-mcshane/13923642131/in/album-72157644172600973',
          wikiLink: 'https://en.wikipedia.org/wiki/Juba',
        },
        landmarksRound: [
          { code: 'CX', number: 6 },
          { code: 'NG', number: 3 },
          { code: 'GF', number: 6 },
        ],
        landmarkPlaces: [
          'Abuna Yemata Guh',
          'Belau National Museum',
          'Freedom Park',
          'Te Rua Manga (The Needle)',
          'Twelve Apostles',
          'Fort Amsterdam',
          'Cathedral of St. Florin',
        ],
        uniqueId: 'ZQFxKgp8',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Spain',
    code: 'ES',
    capital: 'Madrid',
    lat: 40,
    long: -4,
    cities: [
      'Valencia',
      'Madrid',
      'Bilbao',
      'Seville',
      'Granada',
      'Barcelona',
      'Alicante',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/PWrNP4bQHB4',
          wikiLink:
            'https://en.wikipedia.org/wiki/Plaza_de_Espa%C3%B1a,_Seville',
        },
        landmark: {
          name: 'La Sagrada Familia',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sagrada-fam%c3%adlia-cathedral-552084/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sagrada_Fam%C3%ADlia',
          hasLandmark: true,
        },
        city: {
          name: 'Barcelona',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/street-roundabout-barcelona-spain-427998/',
          wikiLink: 'https://en.wikipedia.org/wiki/Barcelona',
        },
        landmarksRound: [
          { code: 'RU', number: 1 },
          { code: 'BY', number: 1 },
          { code: 'MT', number: 1 },
        ],
        landmarkPlaces: [
          'Tsingy De Bemaraha',
          'Easter Island',
          'Luis I Bridge',
          'Tash Rabat',
          'Abu Simbel Temples',
          'Mount Kenya',
          'Queen Emma Bridge',
        ],
        uniqueId: '89L5DKG4',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/valencia-spain-calatrava-sunset-1049389/',
          wikiLink: 'https://en.wikipedia.org/wiki/Valencia',
        },
        landmark: {
          name: 'Alhambra',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/alhambra-castle-castle-complex-179171/',
          wikiLink: 'https://en.wikipedia.org/wiki/Alhambra',
          hasLandmark: true,
        },
        city: {
          name: 'Valencia',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cited-arts-science-valencia-spain-905231/',
          wikiLink: 'https://en.wikipedia.org/wiki/Valencia',
        },
        landmarksRound: [
          { code: 'AL', number: 1 },
          { code: 'FI', number: 1 },
          { code: 'BT', number: 1 },
        ],
        landmarkPlaces: [
          'Soroca Fort',
          'Red Square',
          'Masjid al-Haram',
          'Pico Basile',
          "Dunn's River Falls",
          'Boudhanath',
          'Galle Fort',
        ],
        uniqueId: 'lB9Dxq4H',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Granada',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/group-of-people-sitting-on-grass-field-during-daytime-hTTtWbtQQNY',
          wikiLink: 'https://en.wikipedia.org/wiki/Granada',
        },
        landmark: {
          name: 'Sagrada Família',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-crowd-of-people-walking-in-front-of-a-castle-wlJTouPox_U',
          wikiLink: 'https://en.wikipedia.org/wiki/Sagrada_Fam%C3%ADlia',
          hasLandmark: true,
        },
        city: {
          name: 'Bilbao',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/buildings-near-trees-Er_Z4qZHIEI',
          wikiLink: 'https://en.wikipedia.org/wiki/Bilbao',
        },
        landmarksRound: [
          { code: 'MT', number: 3 },
          { code: 'KG', number: 4 },
          { code: 'IE', number: 2 },
        ],
        landmarkPlaces: [
          'Palace of Versailles',
          'Abu Simbel Temples',
          'Tegucigalpa Cathedral',
          'Abu Simbel',
          'Kiritimati',
          'Salvo Palace',
          'Baiterek',
        ],
        uniqueId: 'spRzgblJ',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Consuegra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-a-row-of-windmills-k7I-Ci7Sq7c',
          wikiLink: 'https://en.wikipedia.org/wiki/Consuegra',
        },
        landmark: {
          name: 'Plaza de España, Sevilla',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-gray-castle-near-gray-lamp-oBprHcCwI2k',
          wikiLink:
            'https://es.wikipedia.org/wiki/Plaza_de_Espa%C3%B1a_(Sevilla)',
          hasLandmark: true,
        },
        city: {
          name: 'Valencia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-3-storey-house-M-J7gtp4m8c',
          wikiLink: 'https://en.wikipedia.org/wiki/Valencia',
        },
        landmarksRound: [
          { code: 'LY', number: 2 },
          { code: 'KE', number: 4 },
          { code: 'GA', number: 4 },
        ],
        landmarkPlaces: [
          'Monumento al Divino Salvador del Mundo',
          'Chongoni Rock Art Area',
          'Hagia Sophia',
          'Iluissat Icefjord',
          'Meteora',
          'Mont Ross',
          "St. Peter's Church",
        ],
        uniqueId: 'cks3sDYb',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Casares',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-white-houses-ovgRb5WLWMQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Casares,_M%C3%A1laga',
        },
        landmark: {
          name: 'Seville Cathedral',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-during-daytime-photo-62QRdDoe44M',
          wikiLink: 'https://en.wikipedia.org/wiki/Seville_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Alicante',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/pier-during-daytime-FObL2dxx9dg',
          wikiLink: 'https://en.wikipedia.org/wiki/Alicante',
        },
        landmarksRound: [
          { code: 'TD', number: 1 },
          { code: 'IR', number: 3 },
          { code: 'FO', number: 6 },
        ],
        landmarkPlaces: [
          'Panfilov Park',
          'Roman ruins in Batna',
          'Great Mosque of Kufa',
          'Fongafale',
          'Mount Yasur',
          'Naval Support Facility Diego Garcia',
          'Old Town Square',
        ],
        uniqueId: '2CXSmSnz',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Spain',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-gray-rock-mountain-beside-body-of-water-eWFdaPRFjwE',
          wikiLink: 'https://en.wikipedia.org/wiki/Spain',
        },
        landmark: {
          name: 'Santa Maria de Montserrat Abbey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-mountain-during-daytime-oqFHLfLFtmc',
          wikiLink:
            'https://en.wikipedia.org/wiki/Santa_Maria_de_Montserrat_Abbey',
          hasLandmark: true,
        },
        city: {
          name: 'Seville',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-brown-table-outside-Z95viY3WaZs',
          wikiLink: 'https://en.wikipedia.org/wiki/Seville',
        },
        landmarksRound: [
          { code: 'CI', number: 2 },
          { code: 'TZ', number: 1 },
          { code: 'LR', number: 2 },
        ],
        landmarkPlaces: [
          'Wawel Castle',
          'Ampitheater of El Jem',
          'Kiritimati',
          'Parque Colón',
          'Deception Island',
          'Devin Castle',
          'Borobudur Temple',
        ],
        uniqueId: 'HRlXPzQ9',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    capital: 'Sri Jayawardenepura Kotte',
    lat: 7,
    long: 81,
    cities: [
      'Colombo',
      'Kandy',
      'Jaffna',
      'Galle',
      'Batticaloa',
      'Anuradhapura',
      'Sri Jayawardenepura Kotte',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/smUAKwMT8XA',
          wikiLink: 'https://en.wikipedia.org/wiki/Sigiriya',
        },
        landmark: {
          name: 'Galle Fort',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/galle-fort-ocean-clouds-storm-5115527/',
          wikiLink: 'https://en.wikipedia.org/wiki/Galle_Fort',
          hasLandmark: true,
        },
        city: {
          name: 'Kandy',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kandy#/media/File:SL_Kandy_asv2020-01_img11_Arthurs_Seat_view.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kandy',
        },
        landmarksRound: [
          { code: 'TR', number: 1 },
          { code: 'FO', number: 1 },
          { code: 'HN', number: 2 },
        ],
        landmarkPlaces: [
          'Tegucigalpa Cathedral',
          'Valle de Cocora',
          'Marina Bay Sands',
          'Tjibaou Cultural Center',
          'Tsingy De Bemaraha',
          'Kittiwake Shipwreck',
          'Castillo San Cristobal',
        ],
        uniqueId: 'JBcgWHwx',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tower-building-road-lotus-tower-7314495/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sri_Lanka',
        },
        landmark: {
          name: 'Sri Dalada Maligawa',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/sri-lanka-kandy-2107620/',
          wikiLink: 'https://en.wikipedia.org/wiki/Temple_of_the_Tooth',
          hasLandmark: true,
        },
        city: {
          name: 'Jaffna',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:Jaffna.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Jaffna',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'mJGgyfj8',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Sri Lanka',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-rock-island-6gv2F4-Hpds',
          wikiLink: 'https://en.wikipedia.org/wiki/Sri_Lanka',
        },
        landmark: {
          name: 'Colombo Lighthouse',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/hicaztaksim/34617530800/',
          wikiLink: 'https://en.wikipedia.org/wiki/Colombo_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Kandy',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mecapla/15027613646/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kandy',
        },
        landmarksRound: [
          { code: 'PK', number: 4 },
          { code: 'DZ', number: 3 },
          { code: 'BF', number: 4 },
        ],
        landmarkPlaces: [
          'Căpriana monastery',
          'Mosta Rotunda',
          'Virgin of El Panecillo',
          'Grand Ducal Palace',
          'Mosquee Al-Hamoudi',
          'Kravica Waterfall',
          'Gidan Rumfa',
        ],
        uniqueId: 'NRyncND4',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Sri Lanka',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-mountain-during-daytime-PTw2xsseQxM',
          wikiLink: 'https://en.wikipedia.org/wiki/Sri_Lanka',
        },
        landmark: {
          name: "St. Lucia's Cathedral",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/195921469@N04/52582560887/',
          wikiLink: 'https://en.wikipedia.org/wiki/St._Lucia%27s_Cathedral',
          hasLandmark: true,
        },
        city: {
          name: 'Colombo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-standing-in-front-of-street-food-vendor-H1p0lqo8LfE',
          wikiLink: 'https://en.wikipedia.org/wiki/Colombo',
        },
        landmarksRound: [
          { code: 'JE', number: 4 },
          { code: 'FJ', number: 6 },
          { code: 'AS', number: 5 },
        ],
        landmarkPlaces: [
          'Chinguetti',
          'The Ľubovňa Castle',
          'Dolly Beach',
          'Church of Saints Simon and Helena',
          'The Baths',
          'Wieliczka Salt Mine',
          'Boiling Lake',
        ],
        uniqueId: 'HYhDzycJ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Sudan',
    code: 'SD',
    capital: 'Khartoum',
    lat: 15,
    long: 30,
    cities: [
      'Nyala',
      'Khartoum',
      'Port Sudan',
      'Kassala',
      'Kosti',
      'Wad Madani',
      'Atbara',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/9IafO_YOVik',
          wikiLink: 'https://en.wikipedia.org/wiki/Nile',
        },
        landmark: {
          name: 'Old Dongola',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Old_Dongola#/media/File:Old_Dobgola_Kingdom_of_Makuria,_Throne_hall_(2)_(34019327053).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Old_Dongola',
          hasLandmark: true,
        },
        city: {
          name: 'Nyala',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nyala,_Sudan#/media/File:Cinema_street2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nyala,_Sudan',
        },
        landmarksRound: [
          { code: 'EC', number: 1 },
          { code: 'SK', number: 1 },
          { code: 'LB', number: 1 },
        ],
        landmarkPlaces: [
          'Kravica Waterfall',
          'Tian Tan Buddha',
          'Pamir Mountains',
          'Akershus Fortress',
          "Ruins of St. Paul's",
          'Charles Bridge',
          'Belvedere Lookout',
        ],
        uniqueId: 'j8RGstPG',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-townscape-horizon-line-3229028/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sudan',
        },
        landmark: {
          name: 'Pyramids of Meroe',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cartoon-meroe-pyramid-old-one-3050417/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pyramids_of_Mero%C3%AB',
          hasLandmark: true,
        },
        city: {
          name: 'Khartoum',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Khartoum#/media/File:Elmek_Nimir_Bridge.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Khartoum',
        },
        landmarksRound: [
          { code: 'PH', number: 1 },
          { code: 'IE', number: 2 },
          { code: 'VE', number: 2 },
        ],
        landmarkPlaces: [
          'Bratislava Castle',
          'Iluissat Icefjord',
          'Red Square',
          'Souq Waqif',
          'Hato Caves',
          'Mount Yasur',
          'Christ the Redeemer',
        ],
        uniqueId: 'bCnjddCC',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Atbara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-brick-building-with-a-balcony-and-balcony-railing-xks4AnfgxJ8',
          wikiLink: 'https://en.wikipedia.org/wiki/Atbara',
        },
        landmark: {
          name: 'Jebel Barkal',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/8651152334/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jebel_Barkal',
          hasLandmark: true,
        },
        city: {
          name: 'Khartoum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/kopikocok/3523463390/',
          wikiLink: 'https://en.wikipedia.org/wiki/Khartoum',
        },
        landmarksRound: [
          { code: 'ZA', number: 2 },
          { code: 'SA', number: 1 },
          { code: 'MC', number: 5 },
        ],
        landmarkPlaces: [
          'Moulin Bézard',
          'Sule Pagoda',
          'Blue Mountains',
          'Akagera National Park',
          'Muliaage',
          'Lake Piso',
          'Paradise Island',
        ],
        uniqueId: 'Mn6xmKjZ',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Sudan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-buildings-during-sunset-9IafO_YOVik',
          wikiLink: 'https://en.wikipedia.org/wiki/Sudan',
        },
        landmark: {
          name: 'Pyramids of Meroë',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-pyramids-sitting-in-the-middle-of-a-desert-33o_TBUL9IM',
          wikiLink: 'https://en.wikipedia.org/wiki/Pyramids_of_Mero%C3%AB',
          hasLandmark: true,
        },
        city: {
          name: 'Atbara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-red-brick-building-with-green-shutters-on-the-windows-U48xrhvi9Rk',
          wikiLink: 'https://en.wikipedia.org/wiki/Atbara',
        },
        landmarksRound: [
          { code: 'GQ', number: 4 },
          { code: 'NL', number: 4 },
          { code: 'NC', number: 3 },
        ],
        landmarkPlaces: [
          'Gun Hill Signal Station',
          'Fort Shirley',
          'Predjama Castle',
          'Great Blue Hole ',
          'Queen Emma Bridge',
          'Cape Coast Castle',
          'Etoile rouge',
        ],
        uniqueId: 'r7ccJ4cG',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Suriname',
    code: 'SR',
    capital: 'Paramaribo',
    lat: 4,
    long: -56,
    cities: [
      'Nieuw Amsterdam',
      'Paramaribo',
      'Nieuw Nickerie',
      'Brokopondo',
      'Moengo',
      'Lelydorp',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/amazon-suriname-south-america-265260/',
          wikiLink: 'https://en.wikipedia.org/wiki/Suriname',
        },
        landmark: {
          name: 'Fort Zeelandia',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Fort_Zeelandia_%2830451941123%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Zeelandia_(Paramaribo)',
          hasLandmark: true,
        },
        city: {
          name: 'Nieuw Amsterdam',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nieuw_Amsterdam,_Suriname#/media/File:New_amsterdam_from_air.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nieuw_Amsterdam,_Suriname',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'c3HRyTjM',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/water-boat-travel-vacation-3338367/',
          wikiLink: 'https://en.wikipedia.org/wiki/Suriname',
        },
        landmark: {
          name: 'Neveh Shalom Synagogue',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Neveh_Shalom_Synagogue#/media/File:MoscheeSynagoge.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Neveh_Shalom_Synagogue',
          hasLandmark: true,
        },
        city: {
          name: 'Paramaribo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Paramaribo#/media/File:Waterkant_Paramaribo_New.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Paramaribo',
        },
        landmarksRound: [
          { code: 'IT', number: 2 },
          { code: 'AU', number: 2 },
          { code: 'TR', number: 1 },
        ],
        landmarkPlaces: [
          "St. Tryphon's Cathedral",
          'Villa Folio',
          'Big Ben',
          'Somapura Mahavihara',
          'Temple of Literature',
          'Saqsayhuaman',
          'Wieliczka Salt Mine',
        ],
        uniqueId: 'cbqggpFW',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Suriname',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/143274264@N07/25367857139/',
          wikiLink: 'https://en.wikipedia.org/wiki/Suriname',
        },
        landmark: {
          name: 'Neveh Shalom Synagogue',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/vikingman/29172863357/',
          wikiLink: 'https://en.wikipedia.org/wiki/Neveh_Shalom_Synagogue',
          hasLandmark: true,
        },
        city: {
          name: 'Lelydorp',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/47748104@N00/2704087805/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lelydorp',
        },
        landmarksRound: [
          { code: 'GT', number: 3 },
          { code: 'PK', number: 4 },
          { code: 'FO', number: 2 },
        ],
        landmarkPlaces: [
          'Queen Emma Bridge',
          'Tombs of the Kings (Paphos)',
          'Atacama Large Millimeter Array',
          'Lake Assal ',
          'Leon Cathedral',
          'Merry Cemetery',
          'Pha That Luang',
        ],
        uniqueId: 'hsVsQVNZ',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Suriname',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/driftwood-on-shore-oKyF4ZYUo2k',
          wikiLink: 'https://en.wikipedia.org/wiki/Suriname',
        },
        landmark: {
          name: 'Jules Wijdenbosch Bridge',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/vikingman/44110494181/',
          wikiLink: 'https://en.wikipedia.org/wiki/Jules_Wijdenbosch_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Paramaribo',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/grandma-shirley/3498860555/',
          wikiLink: 'https://en.wikipedia.org/wiki/Paramaribo',
        },
        landmarksRound: [
          { code: 'BE', number: 5 },
          { code: 'OM', number: 3 },
          { code: 'AD', number: 3 },
        ],
        landmarkPlaces: [
          'Chutes de Boali',
          'Jerash',
          'Pigeon Rocks',
          'Kul Sharif Mosque',
          'The Grand Palace',
          'Palacio Salvo',
          'Day Forest National Park',
        ],
        uniqueId: 'Y8kvZ339',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
    capital: 'Longyearbyen',
    lat: 77.553604,
    long: 23.670272,
    cities: ['Barentsburg', 'Longyearbyen'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/NmXaYwkw560',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard_and_Jan_Mayen',
        },
        landmark: {
          name: 'Pyramiden',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:Pyramiden_35.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Pyramiden',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'AZ', number: 1 },
          { code: 'BY', number: 1 },
          { code: 'BR', number: 2 },
        ],
        landmarkPlaces: [
          'Epupa Falls',
          'Blue Lagoon',
          'Sistine Chapel',
          'Neveh Shalom Synagogue',
          'Soroca Fort',
          'Cathedral Our Lady of the Rosary',
          'Szechenyi Thermal Bath',
        ],
        uniqueId: '39W5nyQR',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/snow-mountain-winter-hiking-1185467/',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard',
        },
        landmark: {
          name: 'Svalbard Church',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Longyearbyen-Church-2006.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard_Church',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'xyJPYCqf',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Svalbard and Jan Mayen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bunch-of-animals-that-are-laying-in-the-snow-B89HI-j1k6M',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard_and_Jan_Mayen',
        },
        landmark: {
          name: 'Svalbard Church',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mosesharold/7639834572/',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard_Church',
          hasLandmark: true,
        },
        city: {
          name: 'Barentsburg',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/28084979@N07/7041731469/',
          wikiLink: 'https://en.wikipedia.org/wiki/Barentsburg',
        },
        landmarksRound: [
          { code: 'MG', number: 3 },
          { code: 'LA', number: 1 },
          { code: 'LV', number: 4 },
        ],
        landmarkPlaces: [
          'Mosque of Divinity',
          'Equatoria Tower',
          'Semuc Champey',
          "People's Liberation Army Museum",
          'Nidaros Cathedral',
          'Plaza Francia',
          'Camana Bay',
        ],
        uniqueId: 'yJbWTDrW',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Svalbard and Jan Mayen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-photo-of-landscape-NqankxAMqxs',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard_and_Jan_Mayen',
        },
        landmark: {
          name: 'Svalbard Museum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/145428914@N03/38982351290/',
          wikiLink: 'https://en.wikipedia.org/wiki/Svalbard_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Longyearbyen',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/184033345@N08/53016836059/',
          wikiLink: 'https://en.wikipedia.org/wiki/Longyearbyen',
        },
        landmarksRound: [
          { code: 'ME', number: 6 },
          { code: 'TT', number: 1 },
          { code: 'AM', number: 4 },
        ],
        landmarkPlaces: [
          'Okavango Delta',
          'Căpriana monastery',
          'Baščaršija',
          'Dhakeshwari Temple',
          'Altun Ha',
          'Alexander Nevsky Cathedral',
          'Anjar',
        ],
        uniqueId: '6cGdtCrK',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Sweden',
    code: 'SE',
    capital: 'Stockholm',
    lat: 62,
    long: 15,
    cities: [
      'Gothenburg',
      'Stockholm',
      'Malmo',
      'Uppsala',
      'Helsingborg',
      'Orebro',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/58X3XfxxevU',
          wikiLink: 'https://en.wikipedia.org/wiki/Abisko_National_Park',
        },
        landmark: {
          name: 'Skansen',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Skansen#/media/File:Skansen-SkogaholmHerrgard-westwing-MathewDodson.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Skansen',
          hasLandmark: true,
        },
        city: {
          name: 'Malmo',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/turning-torso-malm%c3%b6-sk%c3%a5ne-sweden-4009540/',
          wikiLink: 'https://en.wikipedia.org/wiki/Malm%C3%B6',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'KmNqhBXJ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sweden-clouds-travel-scenery-4104028/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sweden',
        },
        landmark: {
          name: 'Stockholm Palace',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/stockholm-the-riksdag-3411026/',
          wikiLink: 'https://en.wikipedia.org/wiki/Stockholm_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Gothenburg',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/gothenburg-overlooking-the-harbour-2414242/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gothenburg',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'fHkFwnwN',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Sweden',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photography-of-shacks-near-body-of-water-L7pz0YUnKdg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sweden',
        },
        landmark: {
          name: 'Malmö Castle',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/stevejanas/16052769314/',
          wikiLink: 'https://en.wikipedia.org/wiki/Malm%C3%B6_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Gothenburg',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/river-surrounded-by-concrete-buildings-during-daytime-20qv9cTILhU',
          wikiLink: 'https://en.wikipedia.org/wiki/Gothenburg',
        },
        landmarksRound: [
          { code: 'YE', number: 4 },
          { code: 'IS', number: 3 },
          { code: 'CK', number: 4 },
        ],
        landmarkPlaces: [
          'Sukhbaatar Square',
          'Notre Dame of Bangui Cathedral',
          'Badshahi Mosque',
          'Monte Carlo Casino',
          'Edinburgh Castle',
          'Acropolis',
          'Stonehenge',
        ],
        uniqueId: 'jYZM7lXF',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Sweden',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-between-trees-under-cloudy-sky-rB7-LCa_diU',
          wikiLink: 'https://en.wikipedia.org/wiki/Sweden',
        },
        landmark: {
          name: 'Sofiero Palace',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/184898381@N07/52142637542/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sofiero_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Stockholm',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/rover-near-road-and-buildings-GRIEUEaCr5w',
          wikiLink: 'https://en.wikipedia.org/wiki/Stockholm',
        },
        landmarksRound: [
          { code: 'TM', number: 3 },
          { code: 'AZ', number: 6 },
          { code: 'GD', number: 6 },
        ],
        landmarkPlaces: [
          'Buenos Aires Obelisk',
          'Pulu Keeling National Park',
          'Gateway Arch',
          'Phoenix Islands Protected Area',
          'Monte Carlo Casino',
          "Dunn's River Falls",
          'Donkey Sanctuary',
        ],
        uniqueId: 'pl3C2MJm',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Switzerland',
    code: 'CH',
    capital: 'Bern',
    lat: 47,
    long: 8,
    cities: ['Zurich', 'Lucerne', 'Lausanne', 'Chur', 'Basel', 'Bern'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/BYPMtjYMEyk',
          wikiLink: 'https://en.wikipedia.org/wiki/Lauterbrunnen',
        },
        landmark: {
          name: 'Chillon Castle',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/castle-chillon-switzerland-3603881/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chillon_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Bern',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/buildings-city-architecture-bern-6262595/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bern',
        },
        landmarksRound: [
          { code: 'PF', number: 1 },
          { code: 'BZ', number: 2 },
          { code: 'IQ', number: 1 },
        ],
        landmarkPlaces: [
          'Narikala Fortress',
          'Boudhanath',
          'Senso-ji',
          'Tegucigalpa Cathedral',
          'Turku Castle',
          'Ruhyyet Palace',
          "Fisherman's Bastion",
        ],
        uniqueId: 'vY9kxHk4',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/buildings-city-architecture-bern-6262595/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bern',
        },
        landmark: {
          name: 'Rhine Falls',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rhine-falls-waterfall-schaffhausen-1622140/',
          wikiLink: 'https://en.wikipedia.org/wiki/Rhine_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Zurich',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bridge-city-river-zurich-4636745/',
          wikiLink: 'https://en.wikipedia.org/wiki/Z%C3%BCrich',
        },
        landmarksRound: [
          { code: 'LB', number: 2 },
          { code: 'BG', number: 1 },
          { code: 'MW', number: 1 },
        ],
        landmarkPlaces: [
          'Turku Castle',
          'Hazrat Ali Mazar',
          'Nizwa Fort',
          'Chorsu Bazaar',
          'Cathedral Our Lady of the Rosary',
          'Kathmandu Durbar Square',
          'Old Town Square',
        ],
        uniqueId: '5zyd8dmm',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Switzerland',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/black-painted-house-in-the-middle-of-the-trees-zlV1Ksvifvs',
          wikiLink: 'https://en.wikipedia.org/wiki/Switzerland',
        },
        landmark: {
          name: 'Oberhofen Castle',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/white-castle-_wfjeANNuNE',
          wikiLink: 'https://en.wikipedia.org/wiki/Oberhofen_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Lucerne',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-concrete-buildings-under-white-and-gray-sky-at-daytime-xpw5IOgLjuw',
          wikiLink: 'https://en.wikipedia.org/wiki/Lucerne',
        },
        landmarksRound: [
          { code: 'MO', number: 5 },
          { code: 'OM', number: 2 },
          { code: 'LV', number: 2 },
        ],
        landmarkPlaces: [
          'Belau National Museum',
          'Golden Bridge ',
          'Richat Structure',
          'Pyramids of Meroë',
          'Altos de Chavón',
          'Kokoda Track',
          'Fiat Tagliero',
        ],
        uniqueId: 'gThBnYMn',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Zermatt',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-near-road-and-mountain-under-blue-sky-during-daytime-photography-SpEQIUw7_TQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Zermatt',
        },
        landmark: {
          name: 'Pilatus ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-and-gray-mountains-NaEIPJ51EHE',
          wikiLink: 'https://en.wikipedia.org/wiki/Pilatus_(mountain)',
          hasLandmark: true,
        },
        city: {
          name: 'Zurich',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-cityscape-Qgph3PrfOFY',
          wikiLink: 'https://en.wikipedia.org/wiki/Zurich',
        },
        landmarksRound: [
          { code: 'VC', number: 2 },
          { code: 'ER', number: 4 },
          { code: 'GG', number: 2 },
        ],
        landmarkPlaces: [
          'Crystal Caves',
          'Gangtey Monastery',
          'Quadiriki Caves',
          'Fort Fincastle',
          'Ribat of Sousse',
          'Red Square',
          'Punakha Dzong',
        ],
        uniqueId: '989KHLRD',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Syria',
    code: 'SY',
    capital: 'Damascus',
    lat: 35,
    long: 38,
    cities: ['Homs', 'Damascus', 'Aleppo', 'Hama', 'Duma', 'Daraa'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/syria-apamea-roman-ruins-heaven-4033538/',
          wikiLink: 'https://en.wikipedia.org/wiki/Apamea,_Syria',
        },
        landmark: {
          name: 'Aleppo Citadel',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Citadel_of_Aleppo#/media/File:Citadel_of_Aleppo.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Citadel_of_Aleppo',
          hasLandmark: true,
        },
        city: {
          name: 'Homs',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Homs#/media/File:Al-Shuhadaa_Square_-_Hims,_Syria.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Homs',
        },
        landmarksRound: [
          { code: 'GE', number: 2 },
          { code: 'GL', number: 2 },
          { code: 'KN', number: 2 },
        ],
        landmarkPlaces: [
          'National Shrine Basilica of our Lady of Las Lajas',
          'Annandale Waterfall',
          'Sao Filipe Royale Fortress',
          'Pyramids of Meroe',
          'Vaduz Cathedral',
          'Ostrog Monastery',
          'Karnak',
        ],
        uniqueId: 'bKGrn4q5',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/syria-aleppo-citadel-entrance-1886425/',
          wikiLink: 'https://en.wikipedia.org/wiki/Aleppo',
        },
        landmark: {
          name: 'Umayyad Mosque',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/damascus-syria-umayyad-mosque-3120492/',
          wikiLink: 'https://en.wikipedia.org/wiki/Umayyad_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Damascus',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Damascus#/media/File:Umayyad_Mosque,_Damascus.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Damascus',
        },
        landmarksRound: [
          { code: 'GH', number: 2 },
          { code: 'UG', number: 1 },
          { code: 'BD', number: 2 },
        ],
        landmarkPlaces: [
          "St. Stephen's Cathedral",
          'Saint Sava Temple',
          'Independence Monument',
          'Santa Catalina Arch',
          'Gravensteen',
          'Grand Ducal Palace',
          'Twelve Apostles',
        ],
        uniqueId: '7R5z8pzJ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kessab',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-the-ocean-from-the-top-of-a-hill-MVvs9JiR3W0',
          wikiLink: 'https://en.wikipedia.org/wiki/Kessab',
        },
        landmark: {
          name: 'Citadel of Aleppo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-under-blue-sky-during-daytime-j0OhftYAXR4',
          wikiLink: 'https://en.wikipedia.org/wiki/Citadel_of_Aleppo',
          hasLandmark: true,
        },
        city: {
          name: 'Daraa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-destroyed-building-in-a-city-QF_CGDotbGY',
          wikiLink: 'https://en.wikipedia.org/wiki/Daraa',
        },
        landmarksRound: [
          { code: 'VC', number: 3 },
          { code: 'GQ', number: 3 },
          { code: 'CC', number: 4 },
        ],
        landmarkPlaces: [
          'Camana Bay',
          'Mata-Utu Cathedral',
          'Tjibaou Cultural Center',
          'Masjid al-Haram',
          'Hof',
          'Gracanica Monastery',
          'Ceking Rice Terrace',
        ],
        uniqueId: 'S4DkdM4w',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Syria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-with-a-hill-in-the-background-Iq2nsH2xNEI',
          wikiLink: 'https://en.wikipedia.org/wiki/Syria',
        },
        landmark: {
          name: 'Palmyra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-pillar-under-blue-sky-during-daytime-Ra8TBB7DZm8',
          wikiLink: 'https://en.wikipedia.org/wiki/Palmyra',
          hasLandmark: true,
        },
        city: {
          name: 'Aleppo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-clock-tower-on-top-of-it-uIJwIoBvWcI',
          wikiLink: 'https://en.wikipedia.org/wiki/Aleppo',
        },
        landmarksRound: [
          { code: 'LY', number: 4 },
          { code: 'NL', number: 2 },
          { code: 'DZ', number: 4 },
        ],
        landmarkPlaces: [
          'Chagres and Fort San Lorenzo',
          'Terrace of the Elephants',
          "St. Olaf's Church",
          'Billionth Barrel Monument',
          'Luxor Temple',
          "People's Liberation Army Museum",
          'Obelisk of Axum',
        ],
        uniqueId: 'qTCNjpXF',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Taiwan',
    code: 'TW',
    capital: 'Taipei',
    lat: 23.5,
    long: 121,
    cities: ['Tainan', 'Taichung', 'Taipei', 'Keelung', 'Kaohsiung', 'Hualien'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/UDv1n0xIpU8',
          wikiLink: 'https://en.wikipedia.org/wiki/Jiufen',
        },
        landmark: {
          name: 'Dragon and Tiger Pagodas',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dragon_and_Tiger_Pagodas#/media/File:Dragon_and_Tiger_Pagodas_02.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dragon_and_Tiger_Pagodas',
          hasLandmark: true,
        },
        city: {
          name: 'Tainan',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Downtown_Tainan%282012%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tainan',
        },
        landmarksRound: [
          { code: 'MK', number: 1 },
          { code: 'GS', number: 1 },
          { code: 'TN', number: 1 },
        ],
        landmarkPlaces: [
          'Mount Nyriagongo',
          'Angel Falls',
          'Merry Cemetery',
          'Erbil Citadel',
          'Ocean Park',
          'Arahoho Blowhole',
          'Old Dongola',
        ],
        uniqueId: 'lvBXRQHY',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cityscape-city-city-lights-evening-5543224/',
          wikiLink: 'https://en.wikipedia.org/wiki/Taiwan',
        },
        landmark: {
          name: 'Lukan Longshan Temple',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Lugang_Lukang_Longshan_Temple_Erstes_Tor_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lukang_Longshan_Temple',
          hasLandmark: true,
        },
        city: {
          name: 'Taichung',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/metropolitan-taichung-city-dusk-2354546/',
          wikiLink: 'https://en.wikipedia.org/wiki/Taichung',
        },
        landmarksRound: [
          { code: 'BS', number: 1 },
          { code: 'ER', number: 2 },
          { code: 'EE', number: 2 },
        ],
        landmarkPlaces: [
          'Fort Fincastle',
          'Frogner Park',
          'Bran Castle',
          'Badshahi Mosque',
          'Azadi Tower',
          'Rizal Park',
          'Pulhapanzak Waterfalls',
        ],
        uniqueId: 'VMVS6dJH',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Taiwan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-and-red-pagoda-temple-ZAwMMAoabrU',
          wikiLink: 'https://en.wikipedia.org/wiki/Taiwan',
        },
        landmark: {
          name: 'Fo Guang Shan Buddha Museum',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-courtyard-through-a-stone-wall-2-kg2_1FRR8',
          wikiLink: 'https://en.wikipedia.org/wiki/Fo_Guang_Shan_Buddha_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Kaohsiung',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-boats-floating-in-a-body-of-water-nOMuGT_hcY0',
          wikiLink: 'https://en.wikipedia.org/wiki/Kaohsiung',
        },
        landmarksRound: [
          { code: 'FI', number: 6 },
          { code: 'GI', number: 5 },
          { code: 'IT', number: 3 },
        ],
        landmarkPlaces: [
          'Soufriere Hills',
          'Krka National Park',
          'Great Mosque of Niamey',
          'Viñales Valley',
          'Soroca Fort',
          'Lake Bled',
          "Lac 'Assal",
        ],
        uniqueId: '6cnV6tkT',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Taiwan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-near-mountain-under-white-sky-during-daytime-L6xHmv2R3G4',
          wikiLink: 'https://en.wikipedia.org/wiki/Taiwan',
        },
        landmark: {
          name: 'Chiang Kai-Shek Memorial Hall',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-visiting-a-tourist-spot-lmoxyu1PXVU',
          wikiLink:
            'https://en.wikipedia.org/wiki/Chiang_Kai-shek_Memorial_Hall',
          hasLandmark: true,
        },
        city: {
          name: 'Taipei',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-building-during-daytime-au3CYbd7vCU',
          wikiLink: 'https://en.wikipedia.org/wiki/Taipei',
        },
        landmarksRound: [
          { code: 'MY', number: 5 },
          { code: 'SB', number: 3 },
          { code: 'DE', number: 6 },
        ],
        landmarkPlaces: [
          'Roman Baths',
          "Lac 'Assal",
          'Hell',
          'Cathedral Our Lady of the Rosary',
          'Tsodilo Hills',
          'Phoenix Islands Protected Area',
          'Chichen Itza',
        ],
        uniqueId: 'QTtR7Xy4',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    capital: 'Dushanbe',
    lat: 39,
    long: 71,
    cities: [
      'Dushanbe',
      'Kulob',
      'Qurgonteppa',
      'Panjakent',
      'Tursunzoda',
      'Khujand',
      'Hisar ',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/zot26dCO_4I',
          wikiLink: 'https://en.wikipedia.org/wiki/Pamir_Mountains',
        },
        landmark: {
          name: 'Iskanderkul',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Iskanderkul#/media/File:Iskander-kul,_Tajikistan.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Iskanderkul',
          hasLandmark: true,
        },
        city: {
          name: 'Khujand',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Khujand#/media/File:%D0%90%D0%BB%D0%B5%D1%8F_%D1%81%D0%BB%D0%B0%D0%B2%D1%8B_%D0%B2_%D0%A5%D1%83%D1%87%D0%B0%D0%BD%D0%B4%D0%B5.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Khujand',
        },
        landmarksRound: [
          { code: 'LU', number: 1 },
          { code: 'GG', number: 2 },
          { code: 'HR', number: 1 },
        ],
        landmarkPlaces: [
          'Seven Colored Earths',
          'Macau Tower',
          'Pico Basile',
          'Turku Castle',
          'Mulafossur Waterfall',
          'Merry Cemetery',
          'Somapura Mahavihara',
        ],
        uniqueId: 'HjGCHVh9',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tajikistan-iskanderkul-alex-andersee-4584690/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tajikistan',
        },
        landmark: {
          name: 'Pamir Mountains',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/nature-travel-exploration-outdoors-4606064/',
          wikiLink: 'https://en.wikipedia.org/wiki/Pamir_Mountains',
          hasLandmark: true,
        },
        city: {
          name: 'Dushanbe',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Dushanbe,Tadjikistan_%2812774957295%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dushanbe',
        },
        landmarksRound: [
          { code: 'NA', number: 2 },
          { code: 'SY', number: 1 },
          { code: 'FO', number: 1 },
        ],
        landmarkPlaces: [
          'Vardzia',
          'CN Tower',
          'Devin Castle',
          'Christiansborg Palace',
          'Grand Ducal Palace',
          'Souq Waqif',
          'Christ of Havana',
        ],
        uniqueId: 'YNjHrgy9',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Hisar ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-castle-through-two-brick-walls-4MLxMrsSEaA',
          wikiLink: 'https://en.wikipedia.org/wiki/Hisar_(Tajikistan)',
        },
        landmark: {
          name: 'Pamir Mountains',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-gray-mountain-painting-h3a7hMonbBI',
          wikiLink: 'https://en.wikipedia.org/wiki/Pamir_Mountains',
          hasLandmark: true,
        },
        city: {
          name: 'Hisar ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-lot-of-steps-leading-up-to-it-L1Zf59Vln2M',
          wikiLink: 'https://en.wikipedia.org/wiki/Hisar_(Tajikistan)',
        },
        landmarksRound: [
          { code: 'MW', number: 2 },
          { code: 'GQ', number: 6 },
          { code: 'AS', number: 6 },
        ],
        landmarkPlaces: [
          'Atlas Cove',
          'Juche Tower',
          'Capernaum',
          'Kalandula Falls',
          'Plaza Francia',
          'Machu Picchu',
          'One Foot Island',
        ],
        uniqueId: 'MgtsQNrr',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tajikistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-mountain-under-blue-sky-during-daytime-lywaqpgB9sg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tajikistan',
        },
        landmark: {
          name: 'Yaghnob Valley',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-wooden-house-on-rocky-mountain-during-daytime-eQvWFDIjAJU',
          wikiLink: 'https://en.wikipedia.org/wiki/Yaghnob_Valley',
          hasLandmark: true,
        },
        city: {
          name: 'Dushanbe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-buildings-tCoqeVfPmis',
          wikiLink: 'https://en.wikipedia.org/wiki/Dushanbe',
        },
        landmarksRound: [
          { code: 'AE', number: 1 },
          { code: 'GN', number: 3 },
          { code: 'MV', number: 3 },
        ],
        landmarkPlaces: [
          'Fort Karl',
          'Oriental Pearl TV Tower',
          'Monument du 22 novembre',
          'Melk Abbey',
          'St. Nicholas Abbey Heritage Railway',
          'Sistine Chapel',
          'National Pantheon',
        ],
        uniqueId: 'lsMC4YWQ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Tanzania',
    code: 'TZ',
    capital: 'Dodoma',
    lat: -6,
    long: 35,
    cities: [
      'Dodoma',
      'Dar es Salaam',
      'Mwanza',
      'Tanga',
      'Morogoro',
      'Zanzibar',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/B8QoatPKAJg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Ngorongoro_Conservation_Area',
        },
        landmark: {
          name: 'Serengeti',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/acacia-tree-tanzania-safari-277352/',
          wikiLink: 'https://en.wikipedia.org/wiki/Serengeti',
          hasLandmark: true,
        },
        city: {
          name: 'Dodoma',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dodoma#/media/File:Beautiful_sunset_in_Dodoma_University_Tanzania.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dodoma',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'ZzpYff3W',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mount-kilimanjaro-mountain-mount-7287226/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tanzania',
        },
        landmark: {
          name: 'Mount Kilimanjaro',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mount-kilimanjaro-mountain-safari-1025146/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Kilimanjaro',
          hasLandmark: true,
        },
        city: {
          name: 'Dar es Salaam',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tanzania-dar-es-salaam-city-2042777/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dar_es_Salaam',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'D8KdwN63',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Dar es Salaam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-at-night-from-a-window-07MPxe8sEEU',
          wikiLink: 'https://en.wikipedia.org/wiki/Dar_es_Salaam',
        },
        landmark: {
          name: 'Mount Kilimanjaro',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-mountain-with-snow-MucgePbA9Xk',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Kilimanjaro',
          hasLandmark: true,
        },
        city: {
          name: 'Dodoma',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/kiliweb/6032681239/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dodoma',
        },
        landmarksRound: [
          { code: 'DM', number: 6 },
          { code: 'RS', number: 1 },
          { code: 'MN', number: 4 },
        ],
        landmarkPlaces: [
          "Dunn's River Falls",
          'Great Blue Hole ',
          'Kunta Kinteh Island',
          'Bab Al Bahrain',
          'Annaberg Sugar Plantation',
          "Queen's Staircase",
          'Acropolis',
        ],
        uniqueId: 'L2RtfCqm',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tanzania',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-mountain-with-brown-soil-in-vicinity-UNOMwllEXfg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tanzania',
        },
        landmark: {
          name: 'Tarangire National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/zebra-near-mountains-2_5IXdiwmyM',
          wikiLink: 'https://en.wikipedia.org/wiki/Tarangire_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Dar es Salaam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tall-building-with-lots-of-windows-and-palm-trees-nPjaO3Cn62Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Dar_es_Salaam',
        },
        landmarksRound: [
          { code: 'ID', number: 2 },
          { code: 'BN', number: 3 },
          { code: 'EC', number: 1 },
        ],
        landmarkPlaces: [
          'Twelve Apostles',
          'Plantation House',
          'Maasai Mara',
          'Niagara Falls',
          'Pamir Mountains',
          'Boudhanath',
          'Mary Star of the Sea Church',
        ],
        uniqueId: 'JWwhCxvY',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Thailand',
    code: 'TH',
    capital: 'Bangkok',
    lat: 15,
    long: 100,
    cities: [
      'Chiang Mai',
      'Phuket',
      'Bangkok',
      'Pattaya City',
      'Krabi',
      'Thani',
      'Pattaya',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/BU6lABNbTpA',
          wikiLink: 'https://en.wikipedia.org/wiki/Phi_Phi_Islands',
        },
        landmark: {
          name: 'The Grand Palace',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/grand-palace-bangkok-temple-night-1822487/',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Chiang Mai',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/asia-street-city-everyday-life-7097815/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chiang_Mai',
        },
        landmarksRound: [
          { code: 'HU', number: 2 },
          { code: 'KZ', number: 2 },
          { code: 'DE', number: 1 },
        ],
        landmarkPlaces: [
          'Katse Dam',
          'Wat Xiengthong',
          'Buenos Aires Obelisk',
          'Olavinlinna',
          'Monument to the Heroes of the Restoration',
          'Monastery of St. Naum',
          'Middle of the World City',
        ],
        uniqueId: 'RvBMC8RN',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/grand-palace-bangkok-temple-night-1822487/',
          wikiLink: 'https://en.wikipedia.org/wiki/Bangkok',
        },
        landmark: {
          name: 'Wat Arun',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bangkok-thailand-wat-arun-sunset-5477405/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wat_Arun',
          hasLandmark: true,
        },
        city: {
          name: 'Phuket',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/phuket-town-overlook-phuket-1731277/',
          wikiLink: 'https://en.wikipedia.org/wiki/Phuket_(city)',
        },
        landmarksRound: [
          { code: 'JP', number: 2 },
          { code: 'ID', number: 1 },
          { code: 'LB', number: 1 },
        ],
        landmarkPlaces: [
          'Cape of Good Hope',
          "Dunn's River Falls",
          'Saint Sava Temple',
          'Alhambra',
          'Saqsayhuaman',
          'Stone House',
          'Neveh Shalom Synagogue',
        ],
        uniqueId: 'jbcdf5GZ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Ao Nang',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photo-of-brown-bench-near-swimming-pool-Koei_7yYtIo',
          wikiLink: 'https://en.wikipedia.org/wiki/Ao_Nang',
        },
        landmark: {
          name: 'Wat Rong Khun',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jimchoate/26902360934/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wat_Rong_Khun',
          hasLandmark: true,
        },
        city: {
          name: 'Pattaya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-boats-floating-on-top-of-a-large-body-of-water-BiauPQGw3rQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Pattaya',
        },
        landmarksRound: [
          { code: 'GQ', number: 3 },
          { code: 'GM', number: 3 },
          { code: 'DZ', number: 3 },
        ],
        landmarkPlaces: [
          'Mount Karthala',
          'Deadvlei',
          'Okavango River',
          'Shiwa Ngandu',
          'Buada Lagoon',
          'Little Bay',
          'Umayyad Mosque',
        ],
        uniqueId: '2q5bmXCw',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Phi Phi Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-body-of-water-surrounded-with-mountains-BU6lABNbTpA',
          wikiLink: 'https://en.wikipedia.org/wiki/Phi_Phi_Islands',
        },
        landmark: {
          name: 'Wiang Kum Kam',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/standing-statue-and-temples-landmark-during-daytime-gsllxmVO4HQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Wiang_Kum_Kam',
          hasLandmark: true,
        },
        city: {
          name: 'Bangkok',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-riding-boat-in-river-near-marketplace-during-daytime-_ffkj8TnuGo',
          wikiLink: 'https://en.wikipedia.org/wiki/Bangkok',
        },
        landmarksRound: [
          { code: 'MS', number: 2 },
          { code: 'TD', number: 4 },
          { code: 'AD', number: 4 },
        ],
        landmarkPlaces: [
          'Taj Mahal',
          'Tash Rabat',
          'Tian Tan Buddha',
          'Cathedral of Our Lady Immaculate',
          'Plaza de la Constitución',
          'Chomoni Beach',
          'Basilica of Our Lady of Peace',
        ],
        uniqueId: 'nXr4l66p',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    capital: 'Dili',
    lat: -8.83333333,
    long: 125.9166667,
    cities: ['Dili', 'Venilale'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/mangrove-timor-leste-atauro-tree-7507812/',
          wikiLink: 'https://en.wikipedia.org/wiki/East_Timor',
        },
        landmark: {
          name: 'Tatamailau',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Tatamailau#/media/File:View_to_Mt_Tatamailau,_Ainaro_on_a_clear_day.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tatamailau',
          hasLandmark: true,
        },
        city: {
          name: 'Liquicia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Liqui%C3%A7%C3%A1#/media/File:Liqui%C3%A7%C3%A1Igreja1.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Liqui%C3%A7%C3%A1',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'HC5gvByH',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Timor-Leste_Coastline.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/East_Timor',
        },
        landmark: {
          name: 'Cristo Rei',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Cristo_Rei_of_Dili#/media/File:Dili_-_Christusstatue_'Cristo_Rei'.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Cristo_Rei_of_Dili',
          hasLandmark: true,
        },
        city: {
          name: 'Dili',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dili#/media/File:Aussenministerium_und_Avenida_de_Portugal.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dili',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'FFdrrg9C',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Timor-Leste',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-beach-with-a-clock-tower-pxJkmLhBhCc',
          wikiLink: 'https://en.wikipedia.org/wiki/East_Timor',
        },
        landmark: {
          name: 'Motael Church',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/6395937749/',
          wikiLink: 'https://en.wikipedia.org/wiki/Motael_Church',
          hasLandmark: true,
        },
        city: {
          name: 'Dili',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-boat-docked-in-a-harbor-next-to-a-building-bDmZPLAxrsk',
          wikiLink: 'https://en.wikipedia.org/wiki/Dili',
        },
        landmarksRound: [
          { code: 'TN', number: 3 },
          { code: 'SB', number: 4 },
          { code: 'BM', number: 6 },
        ],
        landmarkPlaces: [
          'Kelonia',
          'Stone House',
          'Singapore Flyer',
          'Doonagore Castle, Doolin, Ireland',
          'Petra',
          'Plateau Mosque',
          'Quadiriki Caves',
        ],
        uniqueId: 'MhYNc6J4',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Liquiçá',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-beach-and-ocean-52CdMdfFhxE',
          wikiLink: 'https://en.wikipedia.org/wiki/Liqui%C3%A7%C3%A1',
        },
        landmark: {
          name: 'Cristo Rei of Dili',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/davidredfearn/36377655221/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cristo_Rei_of_Dili',
          hasLandmark: true,
        },
        city: {
          name: 'Venilale',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-old-building-with-blue-and-red-trim-IN7_bRLj0iI',
          wikiLink: 'https://en.wikipedia.org/wiki/Venilale',
        },
        landmarksRound: [
          { code: 'BE', number: 3 },
          { code: 'KG', number: 2 },
          { code: 'MH', number: 3 },
        ],
        landmarkPlaces: [
          'Kasubi Tombs',
          'National Museum of Costa Rica     ',
          'Big Ben',
          'Kuwait Towers',
          'Burj Khalifa',
          'Fort Sao Sebastiao',
          'Island of Mozambique',
        ],
        uniqueId: 'D7XsXfNV',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Togo',
    code: 'TG',
    capital: 'Lome',
    lat: 8,
    long: 1.16666666,
    cities: ['Lome', 'Dapaong'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/africa-togo-village-6170643/',
          wikiLink: 'https://en.wikipedia.org/wiki/Togo',
        },
        landmark: {
          name: 'Independence Monument',
          photographer: '',
          imageLink:
            "https://en.wikipedia.org/wiki/Independence_Monument,_Lome#/media/File:Lom%C3%A9-Monument_de_l'Ind%C3%A9pendance.jpg",
          wikiLink: 'https://en.wikipedia.org/wiki/Independence_Monument,_Lome',
          hasLandmark: true,
        },
        city: {
          name: 'Kpalime',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kpalim%C3%A9#/media/File:Marchekpalime.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kpalim%C3%A9',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'cmXjChZ8',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/africa-togo-village-6170633/',
          wikiLink: 'https://en.wikipedia.org/wiki/Togo',
        },
        landmark: {
          name: 'Palais de Congres',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lom%C3%A9#/media/File:Buildings_in_Lom%C3%A9_(6717151255).jpg',
          wikiLink:
            'https://fr.wikipedia.org/wiki/Palais_des_congr%C3%A8s_de_Lom%C3%A9',
          hasLandmark: true,
        },
        city: {
          name: 'Lome',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lom%C3%A9#/media/File:Blvd_des_Armee_Lome_(Lome,_TOGO_2019).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lom%C3%A9',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '8ZSsMZ6Q',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Togo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/153562252@N05/49570659711/',
          wikiLink: 'https://en.wikipedia.org/wiki/Togo',
        },
        landmark: {
          name: 'Fazao-Malfakassa National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/11285168@N07/2182794423/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Fazao_Malfakassa_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Lome',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/villagestoryteller/48864913533/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lom%C3%A9',
        },
        landmarksRound: [
          { code: 'YT', number: 5 },
          { code: 'FJ', number: 6 },
          { code: 'LV', number: 4 },
        ],
        landmarkPlaces: [
          'Romney Manor',
          'Buzludzha monument',
          'Senso-ji',
          "St Bavo's Cathedral, Ghent",
          'Prague astronomical clock',
          'Lake Assal ',
          'Basílica de Suyapa',
        ],
        uniqueId: 'CV2Jd4xG',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Togo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/faceofclimate/8735553697/',
          wikiLink: 'https://en.wikipedia.org/wiki/Togo',
        },
        landmark: {
          name: 'Koutammakou',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/antonellasinopoli/14965652257/',
          wikiLink: 'https://en.wikipedia.org/wiki/Koutammakou',
          hasLandmark: true,
        },
        city: {
          name: 'Dapaong',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/carinedalem/32815710676/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dapaong',
        },
        landmarksRound: [
          { code: 'AM', number: 4 },
          { code: 'PH', number: 3 },
          { code: 'TC', number: 4 },
        ],
        landmarkPlaces: [
          'Hungarian Parliament Building',
          'House of the Blackheads',
          'Al Bastakiya',
          'Turaida Castle',
          'Great Blue Hole ',
          'National Museum of Singapore',
          'Oceanographic Museum of Monaco',
        ],
        uniqueId: 'qwPm9mYt',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Tokelau',
    code: 'TK',
    capital: 'Fakaofo',
    lat: -9,
    long: -172,
    cities: ['Nukunonu', 'Fakaofo'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Tokelau_barge.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokelau',
        },
        landmark: {
          name: 'Atafu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/dfataustralianaid/12779814375',
          wikiLink: 'https://en.wikipedia.org/wiki/Atafu',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'J5qcs3Nd',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Aerial-view-tokelau-2012-photo-new-zealand-ministry-of-foreign-affairs-and-trade_12780239164_o.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokelau',
        },
        landmark: {
          name: 'Fakaofo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fakaofo#/media/File:Fakaofo_Atoll.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fakaofo',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'jTWxYwjp',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Tokelau',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/72923065@N00/2930850430/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokelau',
        },
        landmark: {
          name: 'Nukunonu',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/72923065@N00/2930849460/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nukunonu',
          hasLandmark: true,
        },
        city: {
          name: 'Nukunonu',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/189875006@N07/50269694548/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nukunonu',
        },
        landmarksRound: [
          { code: 'IE', number: 1 },
          { code: 'PN', number: 3 },
          { code: 'AW', number: 2 },
        ],
        landmarkPlaces: [
          'Rundetaarn',
          'Baalbek',
          'The Little Chapel',
          'Monument du 22 novembre',
          'Xunantunich Mayan Ruins',
          'Doonagore Castle, Doolin, Ireland',
          'Grand Mosque of Bobo-Dioulasso',
        ],
        uniqueId: 'YYBKSmlR',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tokelau',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/raobhask/3409238336/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tokelau',
        },
        landmark: {
          name: 'Nukunonu Church',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/raobhask/3408407701/',
          wikiLink: 'https://en.wikipedia.org/wiki/Nukunonu_Church',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'ME', number: 2 },
          { code: 'GG', number: 2 },
          { code: 'GQ', number: 4 },
        ],
        landmarkPlaces: [
          'Buzludzha monument',
          "St. Joseph's Cathedral",
          'Monte Carlo Casino',
          'Etoile rouge',
          'Tokyo Skytree',
          'Anjar',
          'Stockholm Palace',
        ],
        uniqueId: 'jJNc54wQ',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Tonga',
    code: 'TO',
    capital: "Nuku'alofa",
    lat: -20,
    long: -175,
    cities: ['Neiafu', "Nuku'alofa"],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://www.britannica.com/place/Tonga/Economy',
          wikiLink: 'https://en.wikipedia.org/wiki/Tonga',
        },
        landmark: {
          name: "Mapu'a Vaea Blowholes",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mapu_a_Vaea#/media/File:Mapu_a_Vaea.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mapu_a_Vaea',
          hasLandmark: true,
        },
        city: {
          name: 'Neiafu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Neiafu_(Vava%CA%BBu)#/media/File:Neiafu.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Neiafu_(Vava%CA%BBu)',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'sGD3358s',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/shipwreck-ocean-sea-rusty-3821992/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tonga',
        },
        landmark: {
          name: "Ha'amonga'a Maui Trilithon",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ha%CA%BBamonga_%CA%BBa_Maui#/media/File:Ha%CA%BBamonga.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ha%CA%BBamonga_%CA%BBa_Maui',
          hasLandmark: true,
        },
        city: {
          name: "Nuku'alofa",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nuku%CA%BBalofa#/media/File:Talamahu.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nuku%CA%BBalofa',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'gBZ35hvZ',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Tonga',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/10869636@N08/14699199006/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tonga',
        },
        landmark: {
          name: 'Royal Palace',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/angelahuxham/321021303/',
          wikiLink: 'https://en.wikipedia.org/wiki/Royal_Palace,_Tonga',
          hasLandmark: true,
        },
        city: {
          name: 'Neiafu',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/39853979@N04/3663056867/',
          wikiLink: 'https://en.wikipedia.org/wiki/Neiafu',
        },
        landmarksRound: [
          { code: 'RU', number: 4 },
          { code: 'UA', number: 2 },
          { code: 'IR', number: 5 },
        ],
        landmarkPlaces: [
          'Notre Dame Auxiliatrice',
          'Château de Chambord',
          'Bunce Island',
          'Memorial da Escravatura e do Tráfico Negreiro',
          'Altos de Chavón',
          'Tjibaou Cultural Center',
          'Mount Kenya',
        ],
        uniqueId: 'MjXZbf4R',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tonga',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/liz_detheo/4074850751/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tonga',
        },
        landmark: {
          name: 'Haʻamonga ʻa Maui',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/raobhask/3380217513/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ha%CA%BBamonga_%CA%BBa_Maui',
          hasLandmark: true,
        },
        city: {
          name: "Nuku'alofa",
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/sonialastrega/10183498845/',
          wikiLink: "https://en.wikipedia.org/wiki/Nuku'alofa",
        },
        landmarksRound: [
          { code: 'GP', number: 6 },
          { code: 'PY', number: 4 },
          { code: 'AU', number: 6 },
        ],
        landmarkPlaces: [
          'Charyn Canyon',
          'Detwah Lagoon',
          'Neveh Shalom Synagogue',
          'Hio Beach',
          'Pilatus ',
          'Fort Nuestra Señora de la Soledad',
          "Two Lover's Point",
        ],
        uniqueId: 'ckylH5R6',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    capital: 'Port of Spain',
    lat: 11,
    long: -61,
    cities: ['San Fernando', 'Port of Spain'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/DEMrRuvJvqo',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_of_Spain',
        },
        landmark: {
          name: 'Mount St. Benedict Abbey',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mount_Saint_Benedict#/media/File:TnT_St._Augustine_Mount_St._Benedict.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Saint_Benedict',
          hasLandmark: true,
        },
        city: {
          name: 'San Fernando',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/San_Fernando,_Trinidad_and_Tobago#/media/File:STAPP_022_San_Fernando_San_Fernando_Hill.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/San_Fernando,_Trinidad_and_Tobago',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'g5k9yPTw',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/water-nature-landscape-panoramic-3274999/',
          wikiLink: 'https://en.wikipedia.org/wiki/Trinidad_and_Tobago',
        },
        landmark: {
          name: 'Bamboo Cathedral',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Lighthouse_At_Fort_King_George_Tobago_%28145874753%29.jpeg',
          wikiLink: 'https://en.wikipedia.org/wiki/Scarborough,_Tobago',
          hasLandmark: true,
        },
        city: {
          name: 'Port of Spain',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Port_of_Spain#/media/File:QPSpicfromhilton23.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_of_Spain',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Wytv6mYc',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Trinidad and Tobago',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-a-mountain-in-the-background-5eYQc8J2WGc',
          wikiLink: 'https://en.wikipedia.org/wiki/Trinidad_and_Tobago',
        },
        landmark: {
          name: 'Cathedral of the Immaculate Conception',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/13219890235/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Cathedral_of_the_Immaculate_Conception_(Port_of_Spain)',
          hasLandmark: true,
        },
        city: {
          name: 'San Fernando',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/phillipsnic/6421727475/',
          wikiLink:
            'https://en.wikipedia.org/wiki/San_Fernando,_Trinidad_and_Tobago',
        },
        landmarksRound: [
          { code: 'GB', number: 4 },
          { code: 'ES', number: 6 },
          { code: 'KM', number: 4 },
        ],
        landmarkPlaces: [
          'Holy Trinity Cathedral',
          'Grand Mosque of Niamey',
          "Mapu'a Vaea Blowholes",
          'Kim Il Sung Square',
          'Jules Wijdenbosch Bridge',
          'Fakaofo',
          'Edinburgh Castle',
        ],
        uniqueId: 'qwhqZfQj',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Trinidad and Tobago',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/house-on-shore-during-day-UCKqi8xqIOc',
          wikiLink: 'https://en.wikipedia.org/wiki/Trinidad_and_Tobago',
        },
        landmark: {
          name: 'Mayaro Bay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-concrete-road-between-green-palm-trees-under-blue-sky-during-daytime-TXaTm3DHElY',
          wikiLink: 'https://en.wikipedia.org/wiki/Mayaro_Bay',
          hasLandmark: true,
        },
        city: {
          name: 'Port of Spain',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-paved-area-with-trees-and-buildings-in-the-background-JGj2c3l4bzA',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_of_Spain',
        },
        landmarksRound: [
          { code: 'HK', number: 4 },
          { code: 'TR', number: 1 },
          { code: 'SB', number: 3 },
        ],
        landmarkPlaces: [
          'Mount Yasur',
          'Charles Bridge',
          'Roi-Namur',
          'Petra',
          'Domes of Fabedougou',
          'Castle Cornet',
          'Jardin botanique de Deshaies',
        ],
        uniqueId: '59knkHDj',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Tunisia',
    code: 'TN',
    capital: 'Tunis',
    lat: 34,
    long: 9,
    cities: [
      'Tunis',
      'Kairoan',
      'Gabes',
      'Sousse',
      'Hammamet',
      'Sfax',
      'Tataouine',
      'Kairouan',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/5NqUXa6pZDM',
          wikiLink: 'https://en.wikipedia.org/wiki/Sidi_Bou_Said',
        },
        landmark: {
          name: 'Ampitheater of El Jem',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Amphitheatre_of_El_Jem#/media/File:El_Djem_Amphitheater_aerial_view.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Amphitheatre_of_El_Jem',
          hasLandmark: true,
        },
        city: {
          name: 'Tunis',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tunis-tunisia-cityscape-city-106951/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tunis',
        },
        landmarksRound: [
          { code: 'GL', number: 2 },
          { code: 'BH', number: 2 },
          { code: 'GQ', number: 1 },
        ],
        landmarkPlaces: [
          'Deadvlei',
          'Souq Waqif',
          'Blue Grotto',
          'Huka Falls',
          'Tavarua',
          'Pamir Mountains',
          'Baiterek',
        ],
        uniqueId: 't3VQqwqK',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/tunis-tunisia-cityscape-city-106951/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tunis',
        },
        landmark: {
          name: 'Baths of Antoninus',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Baths_of_Antoninus#/media/File:Antonine_Baths_at_Carthage.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Baths_of_Antoninus',
          hasLandmark: true,
        },
        city: {
          name: 'Kairoan',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kairouan#/media/File:Imgp7579a.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kairouan',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'V3f5rKq7',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Tunisia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-camel-on-desert-during-daytime-KZrumAkJWnQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Tunisia',
        },
        landmark: {
          name: 'Ribat of Sousse',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ribat_of_Sousse#/media/File:Medina_of_Sousse-130323.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ribat_of_Sousse',
          hasLandmark: true,
        },
        city: {
          name: 'Tunis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-in-red-suit-walking-on-sidewalk-during-daytime-NiaPA75c5Hw',
          wikiLink: 'https://en.wikipedia.org/wiki/Tunis',
        },
        landmarksRound: [
          { code: 'CI', number: 3 },
          { code: 'MU', number: 2 },
          { code: 'MC', number: 2 },
        ],
        landmarkPlaces: [
          'Ilulissat Icefjord',
          'Bagne des Annamites',
          'Panfilov Park',
          'Bay of Kotor',
          'National Museum of Costume',
          'Azadi Tower',
          'Christ Church Cathedral',
        ],
        uniqueId: '8VgCwBGs',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tunis',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-white-and-brown-concrete-building-during-daytime-SIvfVmejLyY',
          wikiLink: 'https://en.wikipedia.org/wiki/Tunis',
        },
        landmark: {
          name: 'Baths of Antoninus',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Baths_of_Antoninus#/media/File:Antonine_Baths_at_Carthage.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Baths_of_Antoninus',
          hasLandmark: true,
        },
        city: {
          name: 'Sfax',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-street-during-night-time-6CjuVye7aN0',
          wikiLink: 'https://en.wikipedia.org/wiki/Sfax',
        },
        landmarksRound: [
          { code: 'CI', number: 4 },
          { code: 'IS', number: 2 },
          { code: 'KI', number: 1 },
        ],
        landmarkPlaces: [
          'The Blue Mosque',
          'Molinere Underwater Sculpture Park',
          'Verona Arena',
          'The Little Chapel',
          'Colo-i-Suva Forest Reserve',
          'Fasil Ghebbi',
          'Alexander Nevsky Cathedral',
        ],
        uniqueId: 'FLgHMQ7H',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Sidi Bou Said',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-near-white-and-blue-seaside-resort-viewing-blue-sea-under-blue-and-white-sky-RRHmfYRIXXo',
          wikiLink: 'https://en.wikipedia.org/wiki/Sidi_Bou_Said',
        },
        landmark: {
          name: 'Amphitheatre of El Jem',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-stone-building-with-el-djem-in-the-background-Df7s58si_mM',
          wikiLink: 'https://en.wikipedia.org/wiki/Amphitheatre_of_El_Jem',
          hasLandmark: true,
        },
        city: {
          name: 'Tataouine',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-stone-building-with-a-lot-of-windows-4c99Wn3HCao',
          wikiLink: 'https://en.wikipedia.org/wiki/Tataouine',
        },
        landmarksRound: [
          { code: 'CX', number: 2 },
          { code: 'LU', number: 4 },
          { code: 'ES', number: 6 },
        ],
        landmarkPlaces: [
          'Million Dollar Point',
          'Roman ruins in Batna',
          'Lake Titicaca',
          "St. Lucia's Cathedral",
          'Anjar',
          'Little Bay',
          'Boudhanath',
        ],
        uniqueId: 'q28yrFvl',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Tunisia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-domed-roofs-with-kairouan-in-the-background-CHkhYa4aI-A',
          wikiLink: 'https://en.wikipedia.org/wiki/Tunisia',
        },
        landmark: {
          name: 'Great Mosque of Kairouan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-cannon-on-display-in-front-of-a-building-E2vjL529H4Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Great_Mosque_of_Kairouan',
          hasLandmark: true,
        },
        city: {
          name: 'Kairouan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/houses-under-blue-sky-x8Y-s2Oic2Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Kairouan',
        },
        landmarksRound: [
          { code: 'ST', number: 3 },
          { code: 'GN', number: 6 },
          { code: 'MM', number: 3 },
        ],
        landmarkPlaces: [
          'Parthenon',
          'Reunification Monument',
          'Yellowstone National Park',
          'Tikal National Park',
          'Gergeti Trinity Church',
          'Chinguetti',
          'Tatamailau',
        ],
        uniqueId: 'j6Z6fGHp',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Turkey',
    code: 'TR',
    capital: 'Ankara',
    lat: 39,
    long: 35,
    cities: [
      'Ankara',
      'Izmir',
      'Sanliurfa',
      'Konya',
      'Bursa',
      'Antalya',
      'Istanbul',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/bBF9e2UUh88',
          wikiLink: 'https://en.wikipedia.org/wiki/Cappadocia',
        },
        landmark: {
          name: 'The Blue Mosque',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/istanbul-turkey-m-islam-4455899/',
          wikiLink: 'https://en.wikipedia.org/wiki/Blue_Mosque,_Istanbul',
          hasLandmark: true,
        },
        city: {
          name: 'Ankara',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/city-ankara-architecture-urban-4147937/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ankara',
        },
        landmarksRound: [
          { code: 'SI', number: 1 },
          { code: 'ME', number: 1 },
          { code: 'AD', number: 1 },
        ],
        landmarkPlaces: [
          'Rock of Cashel',
          'Vilnius Cathedral',
          'Arc de Triomphe',
          'Galle Fort',
          'Deception Island',
          'Charles Bridge',
          'Kim Il Sung Square',
        ],
        uniqueId: 'MGSFTBxV',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/river-sunset-maiden-s-tower-views-4873198/',
          wikiLink: 'https://en.wikipedia.org/wiki/Istanbul',
        },
        landmark: {
          name: 'Hagia Sophia',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/hagia-sophia-turkey-istanbul-church-216471/',
          wikiLink: 'https://en.wikipedia.org/wiki/Hagia_Sophia',
          hasLandmark: true,
        },
        city: {
          name: 'Izmir',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/izmir-panorama-tourism-bell-tower-3731303/',
          wikiLink: 'https://en.wikipedia.org/wiki/%C4%B0zmir',
        },
        landmarksRound: [
          { code: 'EC', number: 1 },
          { code: 'CW', number: 2 },
          { code: 'HU', number: 2 },
        ],
        landmarkPlaces: [
          'Frogner Park',
          'Romney Manor',
          'Palace of Versailles',
          'Deadvlei',
          'Souq Waqif',
          'Roman theatre',
          'Gyeongbukgong Palace',
        ],
        uniqueId: 'VrccjNry',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Cappadocia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/hot-air-balloon-contest-t7YycgAoVSw',
          wikiLink: 'https://en.wikipedia.org/wiki/Cappadocia',
        },
        landmark: {
          name: 'Derinkuyu',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/nikidel/5624229516',
          wikiLink: 'https://en.wikipedia.org/wiki/Derinkuyu_underground_city',
          hasLandmark: true,
        },
        city: {
          name: 'Bursa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-buildings-at-daytime-wvB63ChubnI',
          wikiLink: 'https://en.wikipedia.org/wiki/Bursa',
        },
        landmarksRound: [
          { code: 'GM', number: 1 },
          { code: 'US', number: 1 },
          { code: 'CI', number: 2 },
        ],
        landmarkPlaces: [
          'Annandale Waterfall',
          'Grand Mosque of Kuwait',
          'Peel Castle',
          'El Yunque National Forest',
          'Dubai Frame',
          'Demerara Bridge',
          'Chinguetti',
        ],
        uniqueId: 'TWNjFzZn',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'İstanbul',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-under-blue-sky-during-daytime-T3jvNXWWpy0',
          wikiLink: 'https://en.wikipedia.org/wiki/Istanbul',
        },
        landmark: {
          name: 'Topkapı Palace',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Topkap%C4%B1_Palace#/media/File:Topkap%C4%B1_-_01.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Topkap%C4%B1_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Antalya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-gray-concrete-bridge-near-brown-concrete-building-during-daytime-BeTKC3wIOz8',
          wikiLink: 'https://en.wikipedia.org/wiki/Antalya',
        },
        landmarksRound: [
          { code: 'GT', number: 1 },
          { code: 'FO', number: 4 },
          { code: 'LY', number: 3 },
        ],
        landmarkPlaces: [
          'El Yunque National Forest',
          'Elmina Castle',
          'The 1763 Monument',
          'Pulhapanzak Waterfalls',
          'Chuuk Lagoon',
          'Borobudur Temple',
          'House of the Blackheads',
        ],
        uniqueId: '5p4XVqPZ',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Halfeti',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/photography-of-tower-and-buildings-tR05vRFVa-4',
          wikiLink: 'https://en.wikipedia.org/wiki/Halfeti',
        },
        landmark: {
          name: 'Süleymaniye Mosque',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-large-white-building-with-many-spires-DA4-0pVigXs',
          wikiLink: 'https://en.wikipedia.org/wiki/S%C3%BCleymaniye_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Antalya',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/landscape-photo-of-white-apartment-buildings-KZdifUvmMSg',
          wikiLink: 'https://en.wikipedia.org/wiki/Antalya',
        },
        landmarksRound: [
          { code: 'BB', number: 4 },
          { code: 'AR', number: 4 },
          { code: 'GP', number: 2 },
        ],
        landmarkPlaces: [
          'Capernaum',
          'Nyungwe park',
          'Mlilwane Wildlife Sanctuary',
          'Punakha Dzong',
          'Åland Maritime Museum',
          'Belovezhskaya Pushcha National Park',
          'The Little Chapel',
        ],
        uniqueId: 'hgggZygd',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Turkey',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-mountains-near-body-of-water-during-daytime-8nVmfffGh5k',
          wikiLink: 'https://en.wikipedia.org/wiki/Turkey',
        },
        landmark: {
          name: 'Olba',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-ruins-of-the-ancient-city-of-ephesia-KEiO-5k-P7Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Olba_(ancient_city)',
          hasLandmark: true,
        },
        city: {
          name: 'Istanbul',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-buildings-and-flying-birds-0n0AHB1fgTQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Istanbul',
        },
        landmarksRound: [
          { code: 'AT', number: 5 },
          { code: 'MM', number: 3 },
          { code: 'GR', number: 3 },
        ],
        landmarkPlaces: [
          'National Museum of Arts, Rites and Traditions',
          'Camana Bay',
          'Oberhofen Castle',
          'Baobab Avenue',
          'Valea Morilor',
          'Bongeunsa',
          'Ein Gedi',
        ],
        uniqueId: '2mk7Fhgg',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    capital: 'Ashgabat',
    lat: 40,
    long: 60,
    cities: [
      'Ashgabat',
      'Turkmenabat',
      'Balkanabat',
      'Dashoguz',
      'Mary',
      'Türkmenbaşy',
      'Ashkhabad',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Turkmenistan-Ashgabat.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ashgabat',
        },
        landmark: {
          name: 'Ruhyyet Palace',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ruhy%C3%BDet_Palace#/media/File:Independence_Day_Parade_-_Flickr_-_Kerri-Jo_(160).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ruhy%C3%BDet_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'Ashgabat',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ashgabat#/media/File:Independence_Square,_Ashgabat.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ashgabat',
        },
        landmarksRound: [
          { code: 'HR', number: 1 },
          { code: 'GB', number: 2 },
          { code: 'LV', number: 1 },
        ],
        landmarkPlaces: [
          'Taj Mahal',
          'Belvedere Lookout',
          'Big Ben',
          'Cologne Cathedral',
          'Iskanderkul',
          'Magnus Cathedral',
          'Sydney Opera House',
        ],
        uniqueId: 'mTRPPbCD',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://commons.wikimedia.org/wiki/File:Turkmenistan.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Turkmenistan',
        },
        landmark: {
          name: 'Independence Monument',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Independence_Monument,_Ashgabat#/media/File:Independence_Monument_(1)_(45993949352).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Independence_Monument,_Ashgabat',
          hasLandmark: true,
        },
        city: {
          name: 'Turkmenabat',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/T%C3%BCrkmenabat#/media/File:%D0%A6%D0%B5%D0%BD%D1%82%D1%80_%D0%A2%D1%83%D1%80%D0%BA%D0%BC%D0%B5%D0%BD%D0%B0%D0%B1%D0%B0%D0%B4%D0%B0.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/T%C3%BCrkmenabat',
        },
        landmarksRound: [
          { code: 'BH', number: 2 },
          { code: 'PY', number: 1 },
          { code: 'BD', number: 1 },
        ],
        landmarkPlaces: [
          'Macau Tower',
          'Pyramiden',
          'English Harbour',
          'Great Blue Hole',
          'Grand Ducal Palace',
          'Deception Island',
          'Castle Cornet',
        ],
        uniqueId: '4qZJcCfc',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Turkmenistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-deserted-place-with-hole-in-middle-VOinFS9FDkQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Turkmenistan',
        },
        landmark: {
          name: 'Independence Monument',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Independence_Monument,_Ashgabat#/media/File:Independence_Monument_(1)_(45993949352).jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Independence_Monument,_Ashgabat',
          hasLandmark: true,
        },
        city: {
          name: 'Ashgabat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-street-light-in-the-middle-of-a-park-hBfVhJKh6zQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Ashgabat',
        },
        landmarksRound: [
          { code: 'JE', number: 3 },
          { code: 'LS', number: 1 },
          { code: 'MQ', number: 3 },
        ],
        landmarkPlaces: [
          'Cintra Bay',
          'Sanctuaire de Notre-Dame des Larmes',
          'Notre Dame Auxiliatrice',
          'Solis Theater',
          'Topkapı Palace',
          'Gracanica Monastery',
          'Uganda National Mosque',
        ],
        uniqueId: '7XvSKNWX',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Turkmenistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/mountain-covered-with-snow-view-WdKBrVAKN4U',
          wikiLink: 'https://en.wikipedia.org/wiki/Turkmenistan',
        },
        landmark: {
          name: 'Door to Hell',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/heropsycho/12550673124/',
          wikiLink: 'https://en.wikipedia.org/wiki/Darvaza_gas_crater',
          hasLandmark: true,
        },
        city: {
          name: 'Türkmenbaşy',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/T%C3%BCrkmenba%C5%9Fy,_Turkmenistan#/media/File:Turkmenbashi_Railway_Station.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/T%C3%BCrkmenba%C5%9Fy,_Turkmenistan',
        },
        landmarksRound: [
          { code: 'MD', number: 4 },
          { code: 'MV', number: 3 },
          { code: 'TF', number: 2 },
        ],
        landmarkPlaces: [
          'Kylemore Abbey',
          'Tsingy De Bemaraha',
          'Cedars of God',
          'Pigeon Rocks',
          'Phoenix Islands Protected Area',
          'Ampitheater of El Jem',
          'Durrell Wildlife Park',
        ],
        uniqueId: 'CxRN9tYq',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Ashkhabad',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-stone-walkway-with-a-pergolated-arbor-in-the-middle-of-it-PcETEa8v6MI',
          wikiLink: 'https://en.wikipedia.org/wiki/Ashgabat',
        },
        landmark: {
          name: 'Independence Monument',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/156583605@N03/40121778445/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Independence_Monument_(Turkmenistan)',
          hasLandmark: true,
        },
        city: {
          name: 'Türkmenbaşy',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/sjameron/1230293693/',
          wikiLink:
            'https://en.wikipedia.org/wiki/T%C3%BCrkmenba%C5%9Fy,_Turkmenistan',
        },
        landmarksRound: [
          { code: 'LT', number: 6 },
          { code: 'KG', number: 1 },
          { code: 'CO', number: 1 },
        ],
        landmarkPlaces: [
          'Maqam Echahid',
          'Cathédrale Notre-Dame',
          "St. Peter's Church",
          'Basilica of Our Lady of Guadalupe',
          'Angel Falls',
          'India Gate',
          'Al Fateh Grand Mosque',
        ],
        uniqueId: 'CV4smSn2',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Awaza',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-bridge-over-the-sea-during-daytime-ew2LSkOXuqc',
          wikiLink:
            'https://unsplash.com/photos/white-bridge-over-the-sea-during-daytime-ew2LSkOXuqc',
        },
        landmark: {
          name: 'Tomb of Ahmad Sanjar',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/25833968@N06/6461824657/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tomb_of_Ahmad_Sanjar',
          hasLandmark: true,
        },
        city: {
          name: 'Ashkhabad',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-park-bench-in-front-of-a-city-street-cz5tJvsH1nY',
          wikiLink: 'https://en.wikipedia.org/wiki/Ashgabat',
        },
        landmarksRound: [
          { code: 'SJ', number: 4 },
          { code: 'DM', number: 2 },
          { code: 'GY', number: 5 },
        ],
        landmarkPlaces: [
          'Charles Bridge',
          'Isalo National Park',
          'Moulin Bézard',
          'Pics de Sindou',
          'Equestrian statue of Genghis Khan',
          "Queen's Staircase",
          'Emi Koussi',
        ],
        uniqueId: 'LYwdZ5LZ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    capital: 'Cockburn Town',
    lat: 21.75,
    long: -71.58333333,
    cities: [
      'Cockburn Town',
      'Providenciales',
      'Wheeland',
      'Kew',
      'Blue Hills',
      'Cockburn Harbour ',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/turks-caicos-island-teal-water-2307310/',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
        landmark: {
          name: 'Grace Bay Beach',
          photographer: '',
          imageLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
          wikiLink:
            'https://commons.wikimedia.org/wiki/File:Sunset_in_Grace_Bay,_Turks_and_Caicos_Islands.jpg',
          hasLandmark: true,
        },
        city: {
          name: 'Cockburn Town',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/124651729@N04/52374938636',
          wikiLink: 'https://en.wikipedia.org/wiki/Cockburn_Town',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'DYmhwwsM',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/turks-and-caicos-beach-caicos-ocean-1220019/',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
        landmark: {
          name: 'Cheshire Hall Plantation',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cheshire_Hall_Plantation#/media/File:Main_House_ruins_at_Cheshire_Hall_plantation.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cheshire_Hall_Plantation',
          hasLandmark: true,
        },
        city: {
          name: 'Providenciales',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Providenciales#/media/File:Leeward_Highway_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Providenciales',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'M5T2v79V',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Turks and Caicos Islands',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/coconut-trees-mW5oo5llyoY',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
        landmark: {
          name: 'Grand Turk Lighthouse',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Grand_Turk_Lighthouse#/media/File:Lighthouse,_Grand_Turk_-_panoramio.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Turk_Lighthouse',
          hasLandmark: true,
        },
        city: {
          name: 'Cockburn Town',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cockburn_Town#/media/File:Grand_Turk_(5963091243).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cockburn_Town',
        },
        landmarksRound: [
          { code: 'ZM', number: 1 },
          { code: 'GA', number: 4 },
          { code: 'JM', number: 2 },
        ],
        landmarkPlaces: [
          'Senso-ji',
          'Valea Morilor',
          'Fort Ceperou',
          'Blue Grotto',
          'Kaieteur Falls',
          'Jökulsárlón',
          'Tsunami Monument',
        ],
        uniqueId: 'SpMDXmdF',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Turks and Caicos Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-view-of-dock-and-house-gXGSp7-KUQQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
        landmark: {
          name: 'Cheshire Hall Plantation',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cheshire_Hall_Plantation#/media/File:Main_House_ruins_at_Cheshire_Hall_plantation.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cheshire_Hall_Plantation',
          hasLandmark: true,
        },
        city: {
          name: 'Providenciales',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Providenciales#/media/File:Leeward_Highway_2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Providenciales',
        },
        landmarksRound: [
          { code: 'MO', number: 2 },
          { code: 'GM', number: 4 },
          { code: 'JP', number: 3 },
        ],
        landmarkPlaces: [
          'Burj Khalifa',
          'Abu Simbel Temples',
          'Trafalgar Falls',
          'Langkawi Sky Bridge',
          "Fa'aruma'i Waterfalls",
          'Arc de Triomphe',
          'Santa Catalina Arch',
        ],
        uniqueId: 'fcxD2XPb',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Turks and Caicos Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-near-blue-sea-under-blue-and-white-cloudy-sky-during-daytime-erbh0VzctyE',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
        landmark: {
          name: 'Chalk Sound National Park',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/karimhddd/23754909183/',
          wikiLink: 'https://en.wikipedia.org/wiki/Providenciales',
          hasLandmark: true,
        },
        city: {
          name: 'Providenciales',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-patio-umbrella-on-seashore-KvsZ9x12tBE',
          wikiLink: 'https://en.wikipedia.org/wiki/Providenciales',
        },
        landmarksRound: [
          { code: 'FR', number: 3 },
          { code: 'VI', number: 2 },
          { code: 'AI', number: 2 },
        ],
        landmarkPlaces: [
          'Tatamailau',
          'Plage de Grande Anse',
          'Castle Rushden',
          'Devin Castle',
          'Ma Chor Nui Nui Temple',
          'Bran Castle',
          'Hagia Sophia',
        ],
        uniqueId: 'NGSWFG75',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Turks and Caicos Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-in-swimming-pool-during-daytime-ynHcCuvABCg',
          wikiLink: 'https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands',
        },
        landmark: {
          name: 'Turks and Caicos National Museum',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/bbhh/14176614398/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Turks_and_Caicos_National_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Cockburn Town',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-boats-on-sea-under-cloudy-sky-during-daytime-3X-hHOYQ07I',
          wikiLink: 'https://en.wikipedia.org/wiki/Cockburn_Town',
        },
        landmarksRound: [
          { code: 'BR', number: 1 },
          { code: 'AT', number: 5 },
          { code: 'CA', number: 6 },
        ],
        landmarkPlaces: [
          'Plage de Sakouli',
          'Mosquee Al-Hamoudi',
          'Hio Beach',
          'Ta Prohm ',
          'Cathedral of the Immaculate Conception',
          'Epupa Falls',
          'Salar de Uyuni',
        ],
        uniqueId: 'l3H66YKh',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    capital: 'Funafuti',
    lat: -8,
    long: 178,
    cities: [
      'Fangaua',
      'Funafuti',
      'Vaiaku Village',
      'Asau',
      'Tanrake',
      'Kulia',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sea-ocean-water-beach-nature-384055/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
        landmark: {
          name: 'Fongafale',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Tuvalu_Funafuti_Atoll.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Fongafale',
          hasLandmark: false,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'w77V4tZR',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/dfataustralianaid/12779525995',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
        landmark: {
          name: 'Funafala',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Funafala#/media/File:Ocean_side_Funafuti.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Funafala',
          hasLandmark: false,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'P4kX27fp',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Tuvalu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/asiandevelopmentbank/32895329278',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
        landmark: {
          name: 'Fongafale',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Tuvalu_Funafuti_Atoll.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Fongafale',
          hasLandmark: true,
        },
        city: {
          name: 'Funafuti',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Funafuti#/media/File:Fetu_Ao_Lima_(Morning_Star_Church),_Congregational_Christian_Church_of_Tuvalu.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Funafuti',
        },
        landmarksRound: [
          { code: 'KE', number: 3 },
          { code: 'GT', number: 1 },
          { code: 'DM', number: 1 },
        ],
        landmarkPlaces: [
          'Baobab Avenue',
          'The Blue Mosque',
          'Amboseli National Park, Kenya',
          'Fort Antoine Theatre',
          'Burj Khalifa',
          'Peel Castle',
          'Arahoho Blowhole',
        ],
        uniqueId: 'Gk3Prqb8',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Tuvalu',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/mrlins/304276123',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
        landmark: {
          name: 'Funafala',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Funafala#/media/File:Ocean_side_Funafuti.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Funafala',
          hasLandmark: true,
        },
        city: {
          name: 'Vaiaku Village',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Vaiaku#/media/File:Government_office_building.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaiaku',
        },
        landmarksRound: [
          { code: 'MV', number: 1 },
          { code: 'MV', number: 4 },
          { code: 'TN', number: 3 },
        ],
        landmarkPlaces: [
          'Santa Catalina Arch',
          'Emerald Pool',
          'Dziani Dzaha',
          'Kasubi Royal Tombs',
          'Brandenburg Gate',
          'Chuuk Lagoon',
          'Metropolitan Cathedral of San Salvador',
        ],
        uniqueId: 'jhjCxV33',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Tuvalu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/michael_from_berlin/52829984615/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
        landmark: {
          name: 'Fongafale',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/roving_spirits/37160085202/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fongafale',
          hasLandmark: true,
        },
        city: {
          name: 'Vaiaku Village',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/asiandevelopmentbank/39805614203/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vaiaku',
        },
        landmarksRound: [
          { code: 'TW', number: 2 },
          { code: 'BI', number: 4 },
          { code: 'KR', number: 3 },
        ],
        landmarkPlaces: [
          'Sainte-Chapelle',
          "Jacob's Ladder",
          "Vianden's Castle",
          'Mata-Utu Cathedral',
          'Mosta Rotunda',
          'Great Mosque of Djenné',
          'Đurđevića Tara Bridge',
        ],
        uniqueId: 'RPKD9nJ7',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Tuvalu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/gosha_krzystkiewicz/4519730272/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tuvalu',
        },
        landmark: {
          name: 'Funafala',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/jelite/38870298850/',
          wikiLink: 'https://en.wikipedia.org/wiki/Funafala',
          hasLandmark: true,
        },
        city: {
          name: 'Funafuti',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/michael_from_berlin/52831907703/',
          wikiLink: 'https://en.wikipedia.org/wiki/Funafuti',
        },
        landmarksRound: [
          { code: 'KY', number: 1 },
          { code: 'ET', number: 6 },
          { code: 'HM', number: 3 },
        ],
        landmarkPlaces: [
          'Pula Arena',
          'El Yunque National Forest',
          'Wat Rong Khun',
          'Plaza Francia',
          'Kyrenia Castle',
          'Great Mosque of Djenné',
          'Soroca Fort',
        ],
        uniqueId: '3dcdNnTq',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Uganda',
    code: 'UG',
    capital: 'Kampala',
    lat: 1,
    long: 32,
    cities: ['Kampala', 'Jinja', 'Gulu', 'Mbarara', 'Jinja', 'Mbale'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/murchison-national-park-uganda-2111157/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Murchison_Falls_National_Park',
        },
        landmark: {
          name: 'Kasubi Royal Tombs',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kasubi_Tombs#/media/File:Kampala_Kasubi_Tombs.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kasubi_Tombs',
          hasLandmark: true,
        },
        city: {
          name: 'Kampala',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kampala-uganda-city-africa-capital-2376011/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kampala',
        },
        landmarksRound: [
          { code: 'BH', number: 1 },
          { code: 'OM', number: 1 },
          { code: 'IT', number: 1 },
        ],
        landmarkPlaces: [
          'Church of Our Savior',
          'Wawel Castle',
          "Fisherman's Bastion",
          'The Marble Mountains',
          "Queen's Staircase",
          'Grand Ducal Palace',
          'The Blue Mosque',
        ],
        uniqueId: 'CJsRTQdm',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lake-victoria-beach-africa-uganda-2108871/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Victoria',
        },
        landmark: {
          name: 'Uganda National Mosque',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Uganda_National_Mosque#/media/File:Laika_ac_Gaddafi_National_Mosque,_Kampala_(6693328097).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Uganda_National_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Jinja',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Jinja,_Uganda#/media/File:Uganda_Jinja_Streetview.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Jinja,_Uganda',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'KHnBVpGQ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Kampala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-people-riding-boat-on-sea-during-daytime-j9kIRsK8jqU',
          wikiLink: 'https://en.wikipedia.org/wiki/Kampala',
        },
        landmark: {
          name: 'Kasubi Tombs',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kasubi_Tombs#/media/File:Kampala_Kasubi_Tombs.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kasubi_Tombs',
          hasLandmark: true,
        },
        city: {
          name: 'Kampala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-on-side-of-the-road-near-building-during-daytime-QCxS5cBoQQU',
          wikiLink: 'https://en.wikipedia.org/wiki/Kampala',
        },
        landmarksRound: [
          { code: 'TF', number: 4 },
          { code: 'HM', number: 4 },
          { code: 'ER', number: 3 },
        ],
        landmarkPlaces: [
          'Larabanga Mosque',
          'Peel Castle',
          'Panfilov Park',
          'Two Lovers Point',
          'Cheshire Hall Plantation',
          'The Pitcairn Museum',
          'Fasil Ghebbi',
        ],
        uniqueId: 'VqyrT5SS',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Fort Portal',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-on-mountain-during-daytime-8PF8fl6e6yE',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Portal',
        },
        landmark: {
          name: "Kabaka's Palace",
          photographer: '',
          imageLink:
            'https://upload.wikimedia.org/wikipedia/commons/e/e9/Kabaka%27s_Palace.jpg',
          wikiLink:
            'https://en.m.wikipedia.org/wiki/File:Kabaka%27s_Palace.jpg',
          hasLandmark: true,
        },
        city: {
          name: 'Gulu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Gulu#/media/File:Gulu_Town_Skyline_01.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Gulu',
        },
        landmarksRound: [
          { code: 'GA', number: 2 },
          { code: 'KW', number: 2 },
          { code: 'GW', number: 4 },
        ],
        landmarkPlaces: [
          'Detwah Lagoon',
          'St. James and Our Lady of the Pillar Cathedral',
          'Leptis Magna',
          'Jardin de Balata',
          'Mausoleum of Khoja Ahmed Yasawi',
          'Plateau Mosque',
          'Jardin Botanique de Deshaies',
        ],
        uniqueId: 'lZXRlxHV',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Uganda',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-photography-of-lake-at-the-forest-wrM9TOVDSrs',
          wikiLink: 'https://en.wikipedia.org/wiki/Uganda',
        },
        landmark: {
          name: 'Uganda National Mosque',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rwoan/15378952274/',
          wikiLink: 'https://en.wikipedia.org/wiki/Uganda_National_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Gulu',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/upcparty/5424533101/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gulu',
        },
        landmarksRound: [
          { code: 'MK', number: 4 },
          { code: 'HR', number: 1 },
          { code: 'NU', number: 4 },
        ],
        landmarkPlaces: [
          'Gangtey Monastery',
          'Two Lovers Point',
          'La Sagrada Familia',
          'Nukunonu',
          'Plage de Sakouli',
          'Independence Monument',
          'Omar Ali Saifuddien Mosque',
        ],
        uniqueId: 'hVZVkfv4',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Lake Bunyonyi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-hills-and-trees-KQ5djKAN35s',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Bunyonyi',
        },
        landmark: {
          name: 'Queen Elizabeth National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-plant-and-grass-during-daytime-8LOp69ILKF8',
          wikiLink:
            'https://en.wikipedia.org/wiki/Queen_Elizabeth_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Kampala',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-sitting-on-black-and-red-motorcycle-2zOSkNTj-Ks',
          wikiLink: 'https://en.wikipedia.org/wiki/Kampala',
        },
        landmarksRound: [
          { code: 'CL', number: 6 },
          { code: 'VE', number: 1 },
          { code: 'FJ', number: 2 },
        ],
        landmarkPlaces: [
          'Hungarian Parliament Building',
          'Cape Coast Castle',
          'Lagoa Azul Lighthouse',
          'Cane Garden Bay',
          'Cathedral of San Juan',
          'Belem Tower',
          'Kunta Kinteh Island',
        ],
        uniqueId: 'jSPGwhMc',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Ukraine',
    code: 'UA',
    capital: 'Kyiv',
    lat: 49,
    long: 32,
    cities: [
      'Odesa',
      'Kharkiv',
      'Kherson',
      'Bakhmut',
      'Mariupol',
      'Kyiv',
      'Lviv',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/a6jaMBfDeoo',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyiv_Pechersk_Lavra',
        },
        landmark: {
          name: 'Golden Gate',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Golden_Gate,_Kyiv#/media/File:Golden_Gate,_Kyiv.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Golden_Gate,_Kyiv',
          hasLandmark: true,
        },
        city: {
          name: 'Kyiv',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kiev-ukraine-city-sky-building-5202547/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyiv',
        },
        landmarksRound: [
          { code: 'NZ', number: 2 },
          { code: 'AM', number: 1 },
          { code: 'FK', number: 2 },
        ],
        landmarkPlaces: [
          'Stone House',
          'Romney Manor',
          'Patuxay Monument',
          'Old Dongola',
          "St. Olaf's Church",
          'Christiansborg Palace',
          'Fort Christian',
        ],
        uniqueId: '3rwXsKss',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/river-buildings-street-kiev-6824576/',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyiv',
        },
        landmark: {
          name: 'Motherland Monument',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/kiev-ukraine-motherland-monument-2746806/',
          wikiLink: 'https://en.wikipedia.org/wiki/Motherland_Monument',
          hasLandmark: true,
        },
        city: {
          name: 'Odesa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Odesa#/media/File:Beach-Chayka-aerial-4.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Odesa',
        },
        landmarksRound: [
          { code: 'TR', number: 1 },
          { code: 'MC', number: 2 },
          { code: 'IM', number: 1 },
        ],
        landmarkPlaces: [
          'Baiterek',
          'Amalienborg',
          "St. Tryphon's Cathedral",
          'Tash Rabat',
          'Pamir Mountains',
          'Buenos Aires Obelisk',
          'Olavinlinna',
        ],
        uniqueId: '4C2Z4KHJ',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Ukraine',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/hays-on-field--YGqltMewrI',
          wikiLink: 'https://en.wikipedia.org/wiki/Ukraine',
        },
        landmark: {
          name: 'Kamianets-Podilskyi Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/grey-concrete-castle-iTdZOpYPD9g',
          wikiLink: 'https://en.wikipedia.org/wiki/Kamianets-Podilskyi_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Odesa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-on-a-path-between-trees-and-buildings-W0o_DPeYm6U',
          wikiLink: 'https://en.wikipedia.org/wiki/Odesa',
        },
        landmarksRound: [
          { code: 'LI', number: 1 },
          { code: 'NP', number: 4 },
          { code: 'GH', number: 4 },
        ],
        landmarkPlaces: [
          'Cintra Bay',
          'Mount Fuji',
          'Liberian National Museum',
          'Mount Yasur',
          'Vajdahunyad Castle',
          'Cheshire Hall Plantation',
          'Kaieteur Falls',
        ],
        uniqueId: '7PQp89Ld',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Ukraine',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/fCOgPYP_bHU',
          wikiLink: 'https://en.wikipedia.org/wiki/Ukraine',
        },
        landmark: {
          name: 'Lychakiv Cemetery',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lychakiv_Cemetery#/media/File:Lw%C3%B3w_-_Cmentarz_%C5%81yczakowski_02.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Lychakiv_Cemetery',
          hasLandmark: true,
        },
        city: {
          name: 'Kharkiv',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-on-side-of-the-road-near-buildings-during-daytime-KGTIBWSa0_I',
          wikiLink: 'https://en.wikipedia.org/wiki/Kharkiv',
        },
        landmarksRound: [
          { code: 'DJ', number: 2 },
          { code: 'AE', number: 2 },
          { code: 'HK', number: 1 },
        ],
        landmarkPlaces: [
          'Tarawa',
          'Atlas Cove',
          'Taputapuatea marae',
          "Prince's Palace of Monaco",
          'Stonehenge',
          'Belvedere Lookout',
          'Cape Coast Castle',
        ],
        uniqueId: 'jQYnNZv8',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Odesa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-white-concrete-building-jTboXfpKqUA',
          wikiLink: 'https://en.wikipedia.org/wiki/Odesa',
        },
        landmark: {
          name: 'Pripyat',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-near-green-trees-during-daytime-6YCIhlSfkc8',
          wikiLink: 'https://en.wikipedia.org/wiki/Pripyat',
          hasLandmark: true,
        },
        city: {
          name: 'Kyiv',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-brown-suspension-bridge-during-daytime-50vvwcNFFzU',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyiv',
        },
        landmarksRound: [
          { code: 'NE', number: 2 },
          { code: 'ZM', number: 3 },
          { code: 'FR', number: 4 },
        ],
        landmarkPlaces: [
          'Iluissat Icefjord',
          'Chorsu Bazaar',
          'Fongafale',
          'Philharmonic Garden ',
          'Maletsunyane Falls',
          'Peel Castle',
          'Citadel of Aleppo',
        ],
        uniqueId: '2nySyvRZ',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Kyiv',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-truck-is-parked-in-front-of-a-windmill-bUgP0IWJRPU',
          wikiLink: 'https://en.wikipedia.org/wiki/Kyiv',
        },
        landmark: {
          name: 'Mother Ukraine',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/person-standing-on-cliff-painting-Gx5RF9rXVkE',
          wikiLink: 'https://en.wikipedia.org/wiki/Mother_Ukraine',
          hasLandmark: true,
        },
        city: {
          name: 'Lviv',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/birds-eye-view-of-city-building-TaWaGbjp60Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Lviv',
        },
        landmarksRound: [
          { code: 'KI', number: 4 },
          { code: 'JP', number: 1 },
          { code: 'KW', number: 2 },
        ],
        landmarkPlaces: [
          'Skopje Fortress',
          'Million Dollar Point',
          'Palazzo Pubblico',
          'Prague astronomical clock',
          'Salar de Uyuni',
          'Rabban Hormizd Monastery',
          'Baths of Antoninus',
        ],
        uniqueId: '7NvC8lnS',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    capital: 'Abu Dhabi',
    lat: 24,
    long: 54,
    cities: [
      'Abu Dhabi',
      'Dubai',
      'Al Ain',
      'Ajman',
      'Sharjah',
      'Ras Al-Khaimah',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Fr6zexbmjmc',
          wikiLink: 'https://en.wikipedia.org/wiki/Burj_Khalifa',
        },
        landmark: {
          name: 'Burj Khalifa',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/burj-khalifa-emirates-dubai-uae-2212978/',
          wikiLink: 'https://en.wikipedia.org/wiki/Burj_Khalifa',
          hasLandmark: true,
        },
        city: {
          name: 'Abu Dhabi',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/abu-dhabi-city-skyline-emirates-1177898/',
          wikiLink: 'https://en.wikipedia.org/wiki/Abu_Dhabi',
        },
        landmarksRound: [
          { code: 'AM', number: 2 },
          { code: 'CW', number: 2 },
          { code: 'GH', number: 1 },
        ],
        landmarkPlaces: [
          "Saint Michael's Cave",
          'Szechenyi Thermal Bath',
          "St. Olaf's Church",
          'Romney Manor',
          'Chimi Lhakhang',
          'Boudhanath',
          'Cape of Good Hope',
        ],
        uniqueId: 'ffR3c8nz',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/downtown-dubai-uae-tourism-city-4045035/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubai',
        },
        landmark: {
          name: 'Dubai Frame',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/dubai-frame-landmark-architecture-6486776/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubai_Frame',
          hasLandmark: true,
        },
        city: {
          name: 'Dubai',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/dubai-emirates-burj-khalifa-skyline-4044183/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubai',
        },
        landmarksRound: [
          { code: 'VU', number: 1 },
          { code: 'BD', number: 1 },
          { code: 'LS', number: 1 },
        ],
        landmarkPlaces: [
          'Monastery of St. Naum',
          'Rock of Cashel',
          'Souq Waqif',
          "St. Tryphon's Cathedral",
          'Charles Bridge',
          'Voortrekker Monument',
          'Palace of Versailles',
        ],
        uniqueId: 'xbMqvVSX',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Dubai',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/city-during-day-VbDjv8-8ibc',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubai',
        },
        landmark: {
          name: 'Sheikh Zayed Grand Mosque',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-walking-on-near-swimming-pool-ERLAcTp-8MQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Sheikh_Zayed_Grand_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Abu Dhabi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-buildings-during-night-time-PvMwVuZZfFc',
          wikiLink: 'https://en.wikipedia.org/wiki/Abu_Dhabi',
        },
        landmarksRound: [
          { code: 'HT', number: 4 },
          { code: 'ZM', number: 1 },
          { code: 'IR', number: 4 },
        ],
        landmarkPlaces: [
          'Verona Arena',
          'Gracanica Monastery',
          'Azadi Tower',
          'Topkapı Palace',
          'San Juan National Historic Site',
          'Egyptian Museum',
          'Big Ben',
        ],
        uniqueId: 'lsbYhhFx',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'United Arab Emirates',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/vehicles-on-desert-during-daytime-nEENSEbLIsY',
          wikiLink: 'https://en.wikipedia.org/wiki/United_Arab_Emirates',
        },
        landmark: {
          name: 'Al Bastakiya',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Al_Bastakiya#/media/File:Camello_y_campamento,_Al_Bastakiya,_Dubai,_2009-11-23,_DD_8878.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Al_Bastakiya',
          hasLandmark: true,
        },
        city: {
          name: 'Dubai',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photo-of-city-highway-surrounded-by-high-rise-buildings-Fr6zexbmjmc',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubai',
        },
        landmarksRound: [
          { code: 'MC', number: 4 },
          { code: 'SI', number: 3 },
          { code: 'GD', number: 3 },
        ],
        landmarkPlaces: [
          'Cedars of God',
          'Larabanga Mosque',
          'Big Ben',
          'Soufriere Hills',
          'Golden Gate Bridge',
          'Castillo San Cristobal',
          'Dziani Dzaha',
        ],
        uniqueId: '4TG5MN7x',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'United Arab Emirates',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-sitting-on-top-of-a-beach-next-to-a-body-of-water-tKWrQTywzJ8',
          wikiLink: 'https://en.wikipedia.org/wiki/United_Arab_Emirates',
        },
        landmark: {
          name: 'Madinat Jumeirah',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/body-of-water-near-houses-fFmPEAWaXTk',
          wikiLink: 'https://en.wikipedia.org/wiki/Madinat_Jumeirah',
          hasLandmark: true,
        },
        city: {
          name: 'Dubai',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-city-with-tall-buildings-bpIISsHtlWk',
          wikiLink: 'https://en.wikipedia.org/wiki/Dubai',
        },
        landmarksRound: [
          { code: 'CD', number: 3 },
          { code: 'UY', number: 1 },
          { code: 'TR', number: 3 },
        ],
        landmarkPlaces: [
          'Brimstone Hill Fortress',
          "Prince's Palace of Monaco",
          'Erbil Citadel',
          'Nxai Pan National Park',
          "St. Peter's Basilica",
          'Etoile rouge',
          'Bamboo Cathedral',
        ],
        uniqueId: 'htHQnr9g',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'United Arab Emirates',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-blue-airplane-on-brown-field-during-daytime-5hz4B8WBLxc',
          wikiLink: 'https://en.wikipedia.org/wiki/United_Arab_Emirates',
        },
        landmark: {
          name: 'Museum of the Future',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-ferris-wheel-in-the-front-FpvVkrCG36Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Museum_of_the_Future',
          hasLandmark: true,
        },
        city: {
          name: 'Abu Dhabi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-white-building-with-a-lot-of-tall-buildings-in-the-background-DDMFhAPS19Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Abu_Dhabi',
        },
        landmarksRound: [
          { code: 'LC', number: 3 },
          { code: 'MR', number: 2 },
          { code: 'ZA', number: 2 },
        ],
        landmarkPlaces: [
          'Mont Ross',
          'Arch of Marcus Aurelius',
          'Pico Basile',
          'Gyeongbukgong Palace',
          'Kaaba',
          'Old Dongola',
          'Freedom Park',
        ],
        uniqueId: 'mWD52NWL',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    capital: 'London',
    lat: 54,
    long: -2,
    cities: [
      'Cardiff',
      'Liverpool',
      'London',
      'Edinburgh',
      'Birmingham',
      'Belfast',
      'Swansea',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/PhBF73HeEVM',
          wikiLink: 'https://en.wikipedia.org/wiki/Lairg',
        },
        landmark: {
          name: 'Stonehenge',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/sunrise-stonehenge-ancient-sky-938998/',
          wikiLink: 'https://en.wikipedia.org/wiki/Stonehenge',
          hasLandmark: true,
        },
        city: {
          name: 'Cardiff',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cardiff-city-buildings-sunrise-3891731/',
          wikiLink: 'https://en.wikipedia.org/wiki/Cardiff',
        },
        landmarksRound: [
          { code: 'MY', number: 1 },
          { code: 'BY', number: 1 },
          { code: 'SA', number: 2 },
        ],
        landmarkPlaces: [
          'Acropolis',
          'Queen Emma Bridge',
          'Bellapais Abbey',
          'Villa Folio',
          'Frogner Park',
          'Vilnius Cathedral',
          'Leaning Tower of Pisa',
        ],
        uniqueId: 'pCBJCctF',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/castle-building-architecture-7728772/',
          wikiLink: 'https://en.wikipedia.org/wiki/Edinburgh',
        },
        landmark: {
          name: 'Big Ben',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/architecture-big-ben-buildings-city-1866767/',
          wikiLink: 'https://en.wikipedia.org/wiki/Big_Ben',
          hasLandmark: true,
        },
        city: {
          name: 'Liverpool',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/liverpool-landmark-cityscape-2538914/',
          wikiLink: 'https://en.wikipedia.org/wiki/Liverpool',
        },
        landmarksRound: [
          { code: 'EC', number: 1 },
          { code: 'NC', number: 2 },
          { code: 'ME', number: 2 },
        ],
        landmarkPlaces: [
          'Cathedral of Brasilica',
          'Old Town Square',
          'Rizal Park',
          'Rila Monastery',
          'Wawel Castle',
          'House of the Black Heads',
          'Vianden Castle',
        ],
        uniqueId: 'scHrv42v',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Glenfinnan Viaduct',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/train-on-bridge-surrounded-with-trees-at-daytime-XVoyX7l9ocY',
          wikiLink: 'https://en.wikipedia.org/wiki/Glenfinnan_Viaduct',
        },
        landmark: {
          name: 'Roman Baths',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Roman_Baths_(Bath)#/media/File:Ba%C3%B1os_Romanos,_Bath,_Inglaterra,_2014-08-12,_DD_39-41_HDR.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Roman_Baths_(Bath)',
          hasLandmark: true,
        },
        city: {
          name: 'Liverpool',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-red-boat-on-water-near-brown-concrete-building-during-daytime-tFXJwy-Ki9w',
          wikiLink: 'https://en.wikipedia.org/wiki/Liverpool',
        },
        landmarksRound: [
          { code: 'YE', number: 2 },
          { code: 'PN', number: 1 },
          { code: 'FR', number: 2 },
        ],
        landmarkPlaces: [
          'Fiat Tagliero',
          'Cité de Carcassonne',
          'Ruínas de São Paulo',
          "Dunn's River Falls",
          'Great Mosque of Asmara',
          'Elmina Castle',
          'Plaza Francia',
        ],
        uniqueId: 'Ldz9tWpl',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Eilean Donan Castle',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-castle-on-shore-ZHF033ykCx8',
          wikiLink: 'https://en.wikipedia.org/wiki/Eilean_Donan',
        },
        landmark: {
          name: 'Edinburgh Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Edinburgh_Castle#/media/File:Edinburgh_Castle_from_the_North.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Edinburgh_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Birmingham',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-on-road-between-high-rise-buildings-during-night-time-zwdkxQZu0Ko',
          wikiLink: 'https://en.wikipedia.org/wiki/Birmingham',
        },
        landmarksRound: [
          { code: 'JM', number: 1 },
          { code: 'CI', number: 1 },
          { code: 'MS', number: 3 },
        ],
        landmarkPlaces: [
          "Dunn's River Falls",
          'Chinguetti',
          'Atlas Cove',
          'Plaza Francia',
          'Grand Turk Lighthouse',
          'Hiroshima Peace Memorial',
          'Soufrière Hills volcano',
        ],
        uniqueId: 'nqNRbPV3',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Belfast',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-person-on-street-near-trees-9lrWn9uo68A',
          wikiLink: 'https://en.wikipedia.org/wiki/Belfast',
        },
        landmark: {
          name: 'York Minster',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-large-cathedral-in-a-city-JcOa7Z5WCbU',
          wikiLink: 'https://en.wikipedia.org/wiki/York_Minster',
          hasLandmark: true,
        },
        city: {
          name: 'Swansea',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-brick-building-with-many-windows-0n5UNxH40jI',
          wikiLink: 'https://en.wikipedia.org/wiki/Swansea',
        },
        landmarksRound: [
          { code: 'CO', number: 5 },
          { code: 'SS', number: 3 },
          { code: 'UG', number: 5 },
        ],
        landmarkPlaces: [
          'Two Lovers Point',
          'Fort Christian',
          'Fortín de La Galera',
          'Motael Church',
          'Fort Charlotte',
          'Tomb of Hafez',
          'Plage de Grande Anse',
        ],
        uniqueId: 'QQDP4SBp',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Edinburgh',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/cars-parked-near-multi-color-buildings-Ipln10Lk_WY',
          wikiLink: 'https://en.wikipedia.org/wiki/Edinburgh',
        },
        landmark: {
          name: 'Holyrood Palace',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/an-aerial-view-of-a-large-building-surrounded-by-trees-LhGlEP5o6ig',
          wikiLink: 'https://en.wikipedia.org/wiki/Holyrood_Palace',
          hasLandmark: true,
        },
        city: {
          name: 'London',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-a-river-running-through-it-sROrHUIyYyU',
          wikiLink: 'https://en.wikipedia.org/wiki/London',
        },
        landmarksRound: [
          { code: 'VG', number: 5 },
          { code: 'GA', number: 2 },
          { code: 'YT', number: 2 },
        ],
        landmarkPlaces: [
          'Plaza de la Catedral',
          'Lake Armor',
          'Po Lin Monastery ',
          'Mount Karthala',
          'Rhine Falls',
          'Kuang Si Falls',
          'Basilica of Our Lady of Guadalupe',
        ],
        uniqueId: '2Y99Hjmj',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'United States',
    code: 'US',
    capital: 'Washington D.C.',
    lat: 38,
    long: -97,
    cities: [
      'Chicago',
      'Boston',
      'Las Vegas',
      'Miami',
      'Nashville',
      'Salt Lake City',
      'Washington D.C.',
      'Napa',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/-rNlq4IN_1k',
          wikiLink: 'https://en.wikipedia.org/wiki/United_states',
        },
        landmark: {
          name: 'Golden Gate Bridge',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/golden-gate-bridge-san-francisco-4271360/',
          wikiLink: 'https://en.wikipedia.org/wiki/Golden_Gate_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Chicago',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/architecture-chicago-buildings-1869211/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chicago',
        },
        landmarksRound: [
          { code: 'IR', number: 1 },
          { code: 'DE', number: 2 },
          { code: 'HU', number: 2 },
        ],
        landmarkPlaces: [
          'Taj Mahal',
          'Liwonde National Park',
          'Tavarua',
          'Mostar Old Bridge',
          'Wat Xiengthong',
          'Pigeon Rocks',
          'Easter Island',
        ],
        uniqueId: 'hKLNHzlM',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/big-bend-texas-landscape-scenic-113099/',
          wikiLink: 'https://en.wikipedia.org/wiki/Big_Bend_(Texas)',
        },
        landmark: {
          name: 'Gateway Arch',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/st-louis-st-louis-arch-illinois-996718/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gateway_Arch',
          hasLandmark: true,
        },
        city: {
          name: 'Boston',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/boston-massachusetts-faneuil-hall-1631460/',
          wikiLink: 'https://en.wikipedia.org/wiki/Boston',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'ZWlc9YnK',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Golden Gate Bridge',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/golden-gate-bridge-california-o4mP43oPGHk',
          wikiLink: 'https://en.wikipedia.org/wiki/Golden_Gate_Bridge',
        },
        landmark: {
          name: 'Mount Rushmore',
          photographer: '',
          imageLink: 'https://en.wikipedia.org/wiki/File:Mountrushmore.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Rushmore',
          hasLandmark: true,
        },
        city: {
          name: 'Nashville',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/american-football-arena-beside-building-and-roadway-during-daytime-GmoaEH48m8c',
          wikiLink: 'https://en.wikipedia.org/wiki/Nashville,_Tennessee',
        },
        landmarksRound: [
          { code: 'LR', number: 2 },
          { code: 'GP', number: 2 },
          { code: 'UA', number: 4 },
        ],
        landmarkPlaces: [
          'Tian Tan Buddha',
          'Petra',
          'Pico Basile',
          'Fouta Djallon',
          'Rotunda of Mosta',
          'Hiroshima Peace Memorial',
          'National Museum of Costume',
        ],
        uniqueId: 'CQMt7kS5',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Granite Falls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-pine-trees-near-body-of-water-during-daytime-yf0v_jHm9Ds',
          wikiLink: 'https://en.wikipedia.org/wiki/Granite_Falls,_Washington',
        },
        landmark: {
          name: 'Yellowstone National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-on-beach-shore-during-daytime-_3MkVwpRXcI',
          wikiLink: 'https://en.wikipedia.org/wiki/Yellowstone_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Salt Lake City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/tram-near-buildings-during-daytime-pzzHdzlFFXE',
          wikiLink: 'https://en.wikipedia.org/wiki/Salt_Lake_City',
        },
        landmarksRound: [
          { code: 'ZM', number: 2 },
          { code: 'GY', number: 1 },
          { code: 'LS', number: 3 },
        ],
        landmarkPlaces: [
          'Stonehenge',
          'Baháʼí Terraces',
          'Brandenburg Gate',
          'Múlafossur Waterfall',
          'Sanctuaire de Notre-Dame des Larmes',
          'Petronas Towers',
          'Columbus Park',
        ],
        uniqueId: 'PPJvNptD',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Montana',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-white-clouds-during-daytime-KQSCcRim-s8',
          wikiLink: 'https://en.wikipedia.org/wiki/Montana',
        },
        landmark: {
          name: 'Monument Valley',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-tree-trunk-on-brown-sand-during-daytime-XU8XB5OUfz0',
          wikiLink: 'https://en.wikipedia.org/wiki/Monument_Valley',
          hasLandmark: true,
        },
        city: {
          name: 'Napa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-with-a-tower-XyEEBP5_pXI',
          wikiLink: 'https://en.wikipedia.org/wiki/Napa,_California',
        },
        landmarksRound: [
          { code: 'HK', number: 6 },
          { code: 'BY', number: 3 },
          { code: 'GF', number: 4 },
        ],
        landmarkPlaces: [
          'Jökulsárlón',
          'Palazzo Pubblico',
          'Plaza de la Catedral',
          'Etosha National Park',
          'Tash Rabat',
          'Fort Nuestra Señora de la Soledad',
          'Blagaj Tekija',
        ],
        uniqueId: 'mPKjvG2r',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'New York',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-with-graffiti-on-the-side-of-it-XFXz574267k',
          wikiLink: 'https://en.wikipedia.org/wiki/New_York_City',
        },
        landmark: {
          name: 'Grand Canyon',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-the-grand-canyon-of-the-grand-canyon-y0z73VaapxQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Grand_Canyon',
          hasLandmark: true,
        },
        city: {
          name: 'Las Vegas',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/space-needle-fR4CDMhw3L8',
          wikiLink: 'https://en.wikipedia.org/wiki/Las_Vegas',
        },
        landmarksRound: [
          { code: 'GA', number: 6 },
          { code: 'GQ', number: 1 },
          { code: 'MS', number: 1 },
        ],
        landmarkPlaces: [
          'Saqsayhuaman',
          'Okavango River',
          'Iluissat Icefjord',
          'Big Ben',
          'Gishora Drum Sanctuary',
          'Linnahall',
          'Chichen Itza',
        ],
        uniqueId: 'jSvLmWLZ',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'United States Virgin Islands',
    code: 'VI',
    capital: 'Charlotte Amalie',
    lat: 18.35,
    long: -64.933333,
    cities: [
      'Annaly',
      'Charlotte Amalie',
      'Cruz Bay',
      'Frederiksted',
      'Christiansted',
      'Blessing',
      'Frenchtown',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Magens_Bay,_St._Thomas,_USVI.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Magens_Bay',
        },
        landmark: {
          name: 'Annaberg Sugar Plantation',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Annaberg_Plantation_windmill.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_John,_U.S._Virgin_Islands',
          hasLandmark: true,
        },
        city: {
          name: 'Charlotte Amalie',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/usvi-st-thomas-charlotte-amalie-1154521/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Charlotte_Amalie,_U.S._Virgin_Islands',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'vjTRzHm2',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Cruz_Bay,_Saint_John;_United_States_Virgin_Islands.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Cruz_Bay,_U.S._Virgin_Islands',
        },
        landmark: {
          name: 'Fort Christian',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Fort_Christian#/media/File:FortChristian.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Christian',
          hasLandmark: true,
        },
        city: {
          name: 'Frederiksted',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/prayitnophotography/22910031253',
          wikiLink:
            'https://en.wikipedia.org/wiki/Frederiksted,_U.S._Virgin_Islands',
        },
        landmarksRound: [
          { code: 'AF', number: 1 },
          { code: 'HU', number: 1 },
          { code: 'PR', number: 1 },
        ],
        landmarkPlaces: [
          'Al Fateh Grand Mosque',
          'Al Alam Palace',
          'Deadvlei',
          'Atomium',
          'Merry Cemetery',
          'Fasil Ghebbi',
          'Amalienborg',
        ],
        uniqueId: 'Z6RBqQQz',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'United States Virgin Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/grayscale-photo-of-beach-shore-99eHoxAvneI',
          wikiLink:
            'https://en.wikipedia.org/wiki/United_States_Virgin_Islands',
        },
        landmark: {
          name: 'Annaberg plantation',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Annaberg,_Saint_John,_U.S._Virgin_Islands#/media/File:Annaberg_Plantation_windmill.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Annaberg,_Saint_John,_U.S._Virgin_Islands',
          hasLandmark: true,
        },
        city: {
          name: 'Charlotte Amalie',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-boats-on-sea-during-daytime-4-4uwhK5PaY',
          wikiLink: 'https://en.wikipedia.org/wiki/Charlotte_Amalie',
        },
        landmarksRound: [
          { code: 'CI', number: 1 },
          { code: 'ZM', number: 2 },
          { code: 'YT', number: 4 },
        ],
        landmarkPlaces: [
          'Gazimestan',
          'Church of Our Savior',
          'Alte Rheinbrücke',
          'Meteora',
          'Plaza Francia',
          'Sri Siva Subramaniya Temple',
          'Taputapuatea marae',
        ],
        uniqueId: 'HHgRd9Zf',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'St. Thomas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bird-flying-over-water-aFmpIn4OJ_s',
          wikiLink:
            'https://en.wikipedia.org/wiki/Saint_Thomas,_U.S._Virgin_Islands',
        },
        landmark: {
          name: 'Point Udal',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Point_Udall_(U.S._Virgin_Islands)#/media/File:PointUdall-Millennium.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Point_Udall_(U.S._Virgin_Islands)',
          hasLandmark: true,
        },
        city: {
          name: 'Cruz Bay',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cruz_Bay,_U.S._Virgin_Islands#/media/File:Cruzbay.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cruz_Bay',
        },
        landmarksRound: [
          { code: 'LV', number: 3 },
          { code: 'JO', number: 3 },
          { code: 'LS', number: 4 },
        ],
        landmarkPlaces: [
          'Petronas Towers',
          'Mulafossur Waterfall',
          'Detwah Lagoon',
          'Funafala',
          'Tarawa',
          'Malolotja National Park',
          'Fortaleza de São José da Amura',
        ],
        uniqueId: 'NR4GHqjy',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'United States Virgin Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-boat-on-sea-during-daytime-iBjow4JiQpU',
          wikiLink:
            'https://en.wikipedia.org/wiki/United_States_Virgin_Islands',
        },
        landmark: {
          name: 'Point Udall',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/skypictures/32650854214/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Point_Udall_(U.S._Virgin_Islands)',
          hasLandmark: true,
        },
        city: {
          name: 'Frenchtown',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-filled-with-lots-of-boats-aWESCjuxIOE',
          wikiLink:
            'https://en.wikipedia.org/wiki/Frenchtown,_U.S._Virgin_Islands',
        },
        landmarksRound: [
          { code: 'BR', number: 2 },
          { code: 'HT', number: 4 },
          { code: 'TF', number: 4 },
        ],
        landmarkPlaces: [
          'Ostrog Monastery',
          'Mount Yasur',
          'Luxor Temple',
          'New Cathedral of Cuenca',
          'Viru Gate',
          'Akanda National Park',
          'Timgad',
        ],
        uniqueId: 'J7gcGkWf',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'United States Virgin Islands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-and-gray-rock-formation-on-sea-shore-during-daytime-k4dds3yHxm4',
          wikiLink:
            'https://en.wikipedia.org/wiki/United_States_Virgin_Islands',
        },
        landmark: {
          name: 'Fort Christian',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/124651729@N04/33948572278/',
          wikiLink: 'https://en.wikipedia.org/wiki/Fort_Christian',
          hasLandmark: true,
        },
        city: {
          name: 'Charlotte Amalie',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/top-view-of-village-during-daytime-XO77KF1UEgU',
          wikiLink: 'https://en.wikipedia.org/wiki/Charlotte_Amalie',
        },
        landmarksRound: [
          { code: 'CR', number: 6 },
          { code: 'GY', number: 2 },
          { code: 'LU', number: 1 },
        ],
        landmarkPlaces: [
          'Krka National Park',
          'Mulafossur Waterfall',
          'Langkawi Sky Bridge',
          'Gibbs Hill Lighthouse',
          'Lovrijenac',
          'Al Fateh Grand Mosque',
          'The Ruins',
        ],
        uniqueId: 's3kWmMmX',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Uruguay',
    code: 'UY',
    capital: 'Montevideo',
    lat: -33,
    long: -56,
    cities: [
      'Salto',
      'Paysandu',
      'Ciudad de la Costa',
      'Maldonado',
      'Rivera',
      'Montevideo',
      'Punta del Este',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/Y_J9VezlMPc',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Mano_de_Punta_del_Este',
        },
        landmark: {
          name: 'Salvo Palace',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Palacio_Salvo#/media/File:Palacio_Salvo.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Palacio_Salvo',
          hasLandmark: true,
        },
        city: {
          name: 'Montevideo',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rambla-beach-montevideo-uruguay-613113/',
          wikiLink: 'https://en.wikipedia.org/wiki/Montevideo',
        },
        landmarksRound: [
          { code: 'PH', number: 1 },
          { code: 'LA', number: 2 },
          { code: 'IR', number: 1 },
        ],
        landmarkPlaces: [
          'Sky Tower',
          'Dubai Frame',
          'Anne Frank House',
          'Rila Monastery',
          'Panfilov Park',
          'Vilnius Cathedral',
          'Palace of Versailles',
        ],
        uniqueId: 'rd5GTwcX',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/palacio-salvo-montevideo-uruguay-2830233/',
          wikiLink: 'https://en.wikipedia.org/wiki/Uruguay',
        },
        landmark: {
          name: 'Solis Theater',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sol%C3%ADs_Theatre#/media/File:2016_Columnas_del_Tatro_sol%C3%ADs_de_Montevideo.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sol%C3%ADs_Theatre',
          hasLandmark: true,
        },
        city: {
          name: 'Ciudad de la Costa',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ciudad_de_la_Costa#/media/File:Puente_americas_canelones.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ciudad_de_la_Costa',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'zSdgG2Jt',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Uruguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-lighthouse-on-gray-sand-during-sunset-8xWgYDhAGrQ',
          wikiLink: 'https://en.wikipedia.org/wiki/Uruguay',
        },
        landmark: {
          name: 'La Mano de Punta del Este',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/gray-stone-on-brown-wooden-table-near-body-of-water-during-daytime-Y_J9VezlMPc',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Mano_de_Punta_del_Este',
          hasLandmark: true,
        },
        city: {
          name: 'Montevideo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-pedestrian-lane-during-daytime-N0y0FTutCyg',
          wikiLink: 'https://en.wikipedia.org/wiki/Montevideo',
        },
        landmarksRound: [
          { code: 'PR', number: 4 },
          { code: 'FO', number: 3 },
          { code: 'YT', number: 2 },
        ],
        landmarkPlaces: [
          'Christ Church Cathedral',
          'Plaza de España, Sevilla',
          'Atlas Cove',
          'Cacheu Fort',
          'Fort Ceperou',
          'Molinere Underwater Sculpture Park',
          'Allahverdi Khan Bridge',
        ],
        uniqueId: 'gdRQjHqq',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Uruguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-under-blue-sky-during-daytime-YqUW7fT5NwM',
          wikiLink: 'https://en.wikipedia.org/wiki/Uruguay',
        },
        landmark: {
          name: 'Palacio Salvo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/southbound228/9601671953',
          wikiLink: 'https://en.wikipedia.org/wiki/Palacio_Salvo',
          hasLandmark: true,
        },
        city: {
          name: 'Salto',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Salto,_Uruguay#/media/File:Plaza_Artigas,_Salto.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Salto,_Uruguay',
        },
        landmarksRound: [
          { code: 'MR', number: 4 },
          { code: 'LY', number: 2 },
          { code: 'EH', number: 1 },
        ],
        landmarkPlaces: [
          'Soroca Fort',
          'Borobudur Temple',
          'Ilulissat Icefjord',
          'Jökulsárlón',
          'Mumbo Island',
          'Molinere Underwater Sculpture Park',
          'Fort Ceperou',
        ],
        uniqueId: 'k8QBwSJW',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Uruguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-bunch-of-boats-that-are-in-the-water-LjEuThIORwU',
          wikiLink: 'https://en.wikipedia.org/wiki/Uruguay',
        },
        landmark: {
          name: 'Palacio Salvo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-under-blue-sky-during-daytime-D_GJeAfV1xA',
          wikiLink: 'https://en.wikipedia.org/wiki/Palacio_Salvo',
          hasLandmark: true,
        },
        city: {
          name: 'Montevideo',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-body-of-water-with-a-city-in-the-background-Ht_G1h2Obyg',
          wikiLink: 'https://en.wikipedia.org/wiki/Montevideo',
        },
        landmarksRound: [
          { code: 'TF', number: 4 },
          { code: 'LK', number: 2 },
          { code: 'CG', number: 1 },
        ],
        landmarkPlaces: [
          'Kasubi Royal Tombs',
          'Nassau Public Library',
          'Cathedral of Brasilica',
          'Hiroshima Peace Memorial',
          'Ark of Bukhara',
          'Tatamailau',
          'Pyramid of Tirana',
        ],
        uniqueId: 'm8WqmjCF',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Uruguay',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-brown-and-white-horse-standing-on-top-of-a-lush-green-field-cc58bUVWxWI',
          wikiLink: 'https://en.wikipedia.org/wiki/Uruguay',
        },
        landmark: {
          name: 'Nuestra Señora de la Candelaria',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-blue-church-with-a-red-roof-LrJO7_oyd08',
          wikiLink:
            'https://en.wikipedia.org/wiki/Nuestra_Se%C3%B1ora_de_la_Candelaria,_Punta_del_Este',
          hasLandmark: true,
        },
        city: {
          name: 'Punta del Este',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-and-a-body-of-water-nrPv_8ALoDo',
          wikiLink: 'https://en.wikipedia.org/wiki/Punta_del_Este',
        },
        landmarksRound: [
          { code: 'CD', number: 2 },
          { code: 'US', number: 3 },
          { code: 'CH', number: 1 },
        ],
        landmarkPlaces: [
          'Grand Turk Lighthouse',
          'St. Barnabas Chapel',
          'Maletsunyane Falls',
          'Sofiero Palace',
          'Sky Tower',
          "St. Lucia's Cathedral",
          'Anjar',
        ],
        uniqueId: '7shJlyxq',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    capital: 'Tashkent',
    lat: 41,
    long: 64,
    cities: [
      'Tashkent',
      'Bukhara',
      'Samarkand',
      'Nukus',
      'Namangan',
      'Andijan',
      'Khiva',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/x-xwFxX2wVU',
          wikiLink: 'https://en.wikipedia.org/wiki/Samarkand',
        },
        landmark: {
          name: 'Chorsu Bazaar',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Inside_Chorsu_bazaar_in_Tashkent,_Uzbekistan.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Chorsu_Bazaar',
          hasLandmark: true,
        },
        city: {
          name: 'Bukhara',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Bukhara_city_center.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bukhara',
        },
        landmarksRound: [
          { code: 'LI', number: 1 },
          { code: 'SK', number: 2 },
          { code: 'HN', number: 1 },
        ],
        landmarkPlaces: [
          'Fort Fincastle',
          'Vianden Castle',
          'Wieliczka Salt Mine',
          'Palace of Versailles',
          'Tomb of Hafez',
          'Stone House',
          'Rhine Falls',
        ],
        uniqueId: 'p5YGBQNd',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/bibi-xanom-mosque-samarkand-196944/',
          wikiLink: 'https://en.wikipedia.org/wiki/Uzbekistan',
        },
        landmark: {
          name: 'Registan Square',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/uzbekistan-mosque-samarkand-2636505/',
          wikiLink: 'https://en.wikipedia.org/wiki/Registan',
          hasLandmark: true,
        },
        city: {
          name: 'Tashkent',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/uzbekistan-tashkent-capital-city-4580836/',
          wikiLink: 'https://en.wikipedia.org/wiki/Tashkent',
        },
        landmarksRound: [
          { code: 'VU', number: 1 },
          { code: 'FR', number: 2 },
          { code: 'VI', number: 2 },
        ],
        landmarkPlaces: [
          'Forbidden City',
          'George Washington House',
          'Christ Church Cathedral',
          'Cologne Cathedral',
          'Church of Saint Lazarus',
          'Golden Gate Bridge',
          'Boudhanath',
        ],
        uniqueId: 'dwrc4hrx',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Uzbekistan',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/brown-house-2O8sAwInqT8',
          wikiLink: 'https://en.wikipedia.org/wiki/Uzbekistan',
        },
        landmark: {
          name: 'Ark of Bukhara',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Ark_of_Bukhara#/media/File:Ark_fortress_in_Bukhara.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ark_of_Bukhara',
          hasLandmark: true,
        },
        city: {
          name: 'Tashkent',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-concrete-building-near-green-trees-during-daytime-hDO53B4wFWU',
          wikiLink: 'https://en.wikipedia.org/wiki/Tashkent',
        },
        landmarksRound: [
          { code: 'GG', number: 2 },
          { code: 'PF', number: 4 },
          { code: 'PN', number: 1 },
        ],
        landmarkPlaces: [
          'Azadi Tower',
          'Mount Kenya',
          'Ceking Rice Terrace',
          'Emerald Pool',
          'Sanctuaire de Notre-Dame des Larmes',
          'Tsingy Rouge',
          'Verona Arena',
        ],
        uniqueId: '7gLf3n3D',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Uzbekistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-field-under-white-sky-during-daytime-6HJ0-lKtpsc',
          wikiLink: 'https://en.wikipedia.org/wiki/Uzbekistan',
        },
        landmark: {
          name: 'Registan',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Registan#/media/File:Registan_square_Samarkand.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Registan',
          hasLandmark: true,
        },
        city: {
          name: 'Samarkand',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-and-brown-concrete-dome-building-cKBg2lao-k8',
          wikiLink: 'https://en.wikipedia.org/wiki/Samarkand',
        },
        landmarksRound: [
          { code: 'UG', number: 1 },
          { code: 'GU', number: 3 },
          { code: 'HU', number: 4 },
        ],
        landmarkPlaces: [
          'Boudhanath',
          'Iluissat Icefjord',
          'Salvo Palace',
          "Christian's Cave",
          'Alhambra',
          'Grand Mosque of Kuwait',
          'Akanda National Park',
        ],
        uniqueId: 'rvvBg6MW',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Uzbekistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-dirt-road-surrounded-by-trees-under-a-cloudy-sky-q7A2qWWKAUw',
          wikiLink: 'https://en.wikipedia.org/wiki/Uzbekistan',
        },
        landmark: {
          name: 'Ulugh Beg Observatory',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/stevecruise/11148739933/',
          wikiLink: 'https://en.wikipedia.org/wiki/Ulugh_Beg_Observatory',
          hasLandmark: true,
        },
        city: {
          name: 'Khiva',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-street-with-buildings-and-people-UFepYOLd4u8',
          wikiLink: 'https://en.wikipedia.org/wiki/Khiva',
        },
        landmarksRound: [
          { code: 'VE', number: 2 },
          { code: 'CN', number: 3 },
          { code: 'BW', number: 3 },
        ],
        landmarkPlaces: [
          'Doonagore Castle, Doolin, Ireland',
          'Dochula Pass',
          'Annandale Waterfall',
          'Bayon',
          'Heydar Mosque',
          "Queen's Staircase",
          'Charles Bridge',
        ],
        uniqueId: '7gGMc3Yw',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Uzbekistan',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-city-under-blue-sky-during-daytime-VYBSxYpCUOc',
          wikiLink: 'https://en.wikipedia.org/wiki/Uzbekistan',
        },
        landmark: {
          name: 'Tilya Kori Madrasa',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-with-a-domed-roof-baj0E9SPgLU',
          wikiLink: 'https://en.wikipedia.org/wiki/Tilya_Kori_Madrasa',
          hasLandmark: true,
        },
        city: {
          name: 'Samarkand',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-blue-dome-on-top-of-it-c2a73WllU6Y',
          wikiLink: 'https://en.wikipedia.org/wiki/Samarkand',
        },
        landmarksRound: [
          { code: 'JP', number: 3 },
          { code: 'MR', number: 5 },
          { code: 'HN', number: 6 },
        ],
        landmarkPlaces: [
          'Colombier',
          'St. Lawrence Church',
          "Queen's Staircase",
          'Equestrian statue of Genghis Khan',
          'Porte du Non Retour',
          'Little Chapel',
          'Mount Karthala',
        ],
        uniqueId: 'cWjpqZg3',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    capital: 'Port Vila',
    lat: -16,
    long: 167,
    cities: [
      'Luganville',
      'Port Vila',
      'Port Orly',
      'Isangel',
      'Lakatoro',
      'Norsup',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/JxL6t8iVri4',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
        landmark: {
          name: 'Mount Yasur',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mount_Yasur#/media/File:Mount_Yasur_eruption_2006,_Tanna_Island,_Vanuatu,_VAN_0516.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Yasur',
          hasLandmark: true,
        },
        city: {
          name: 'Port Vila',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Port_Vila#/media/File:Port_Vila_aerial.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Vila',
        },
        landmarksRound: [
          { code: 'NL', number: 1 },
          { code: 'OM', number: 2 },
          { code: 'GT', number: 2 },
        ],
        landmarkPlaces: [
          'Dubai Frame',
          'Herat Citadel',
          'Santa Catalina Arch',
          'Huka Falls',
          'Burj Khalifa',
          'Hagia Sophia',
          'Blue Grotto',
        ],
        uniqueId: 'JNqrVsgJ',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vanuatu-harbor-bay-water-shoreline-80771/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
        landmark: {
          name: 'Champagne Beach',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Champagne_Beach_(Vanuatu)#/media/File:Champagne_Beach_Vanuatu.JPG',
          wikiLink: 'https://en.wikipedia.org/wiki/Champagne_Beach_(Vanuatu)',
          hasLandmark: true,
        },
        city: {
          name: 'Luganville',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Luganville#/media/File:Main_Street_Luganville_(28661070193).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Luganville',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'BDhwgzCh',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Vanuatu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-of-green-trees-and-body-of-water-during-daytime-JxL6t8iVri4',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
        landmark: {
          name: 'Million Dollar Point',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/wickateers/3706042515',
          wikiLink:
            'https://en.wikipedia.org/wiki/Naval_Advance_Base_Espiritu_Santo',
          hasLandmark: true,
        },
        city: {
          name: 'Port Vila',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Port_Vila#/media/File:PortVilaCityHall.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Vila',
        },
        landmarksRound: [
          { code: 'MT', number: 2 },
          { code: 'ML', number: 4 },
          { code: 'PN', number: 1 },
        ],
        landmarkPlaces: [
          'Arecibo Observatory',
          'Karnak',
          'Grand Ducal Palace',
          'Pura Ulun Danu Bratan',
          'Monumento al Divino Salvador del Mundo',
          'Malolotja National Park',
          'Elmina Castle',
        ],
        uniqueId: 'jXhGjGFs',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Vanuatu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-and-trees-S_r6KV1xihE',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
        landmark: {
          name: 'Mount Yasur',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/eriagn/9107999340',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Yasur',
          hasLandmark: true,
        },
        city: {
          name: 'Luganville',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Luganville#/media/File:Main_Street_Luganville_(28661070193).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Luganville',
        },
        landmarksRound: [
          { code: 'MU', number: 4 },
          { code: 'TM', number: 1 },
          { code: 'JM', number: 3 },
        ],
        landmarkPlaces: [
          'Palace of Peace and Reconciliation',
          'Tsingy De Bemaraha',
          'Fongafale',
          'National Museum of Montserrat',
          'Kpatawee Falls',
          'Fort Nuestra Senora de la Soledad',
          'St. Peter the Apostle Cathedral',
        ],
        uniqueId: 'yD8Y5tmr',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Vanuatu',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/body-of-water-YkMC1mqegTw',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
        landmark: {
          name: 'Champagne Beach',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/39551170@N02/28660966453/',
          wikiLink: 'https://en.wikipedia.org/wiki/Champagne_Beach_(Vanuatu)',
          hasLandmark: true,
        },
        city: {
          name: 'Luganville',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/bokis-foto_man/30443944541/',
          wikiLink: 'https://en.wikipedia.org/wiki/Luganville',
        },
        landmarksRound: [
          { code: 'KY', number: 3 },
          { code: 'VE', number: 2 },
          { code: 'LK', number: 4 },
        ],
        landmarkPlaces: [
          'Ħal Saflieni Hypogeum',
          'Tikal National Park',
          'Pulu Keeling National Park',
          'Eltz Castle',
          'Philharmonic Garden ',
          'Henderson Island',
          'Leon Cathedral',
        ],
        uniqueId: 'jr7kTS4G',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Vanuatu',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-near-green-trees-during-daytime--SYNBazCTds',
          wikiLink: 'https://en.wikipedia.org/wiki/Vanuatu',
        },
        landmark: {
          name: 'Mount Yasur',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-very-tall-mountain-with-a-very-dark-cloud-in-the-sky-OYfnFaward4',
          wikiLink: 'https://en.wikipedia.org/wiki/Mount_Yasur',
          hasLandmark: true,
        },
        city: {
          name: 'Port Vila',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/michael_from_berlin/52827177943/',
          wikiLink: 'https://en.wikipedia.org/wiki/Port_Vila',
        },
        landmarksRound: [
          { code: 'MQ', number: 2 },
          { code: 'BR', number: 4 },
          { code: 'ZA', number: 3 },
        ],
        landmarkPlaces: [
          'Perito Moreno Glacier',
          'Palacio Salvo',
          'Luis I Bridge',
          'Montserrat Volcano Observatory',
          'Limu Pools',
          'Sans-Souci Palace',
          'Chagres and Fort San Lorenzo',
        ],
        uniqueId: 'SChTJ5sK',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Vatican City',
    code: 'VA',
    capital: 'Vatican City',
    lat: 41.9,
    long: 12.45,
    cities: [],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/mJDuO7CzO74',
          wikiLink: 'https://en.wikipedia.org/wiki/St._Peter%27s_Basilica',
        },
        landmark: {
          name: 'Sistine Chapel',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Sistine_Chapel#/media/File:Veduta_della_cappella_sistina_dai_giardini_vaticani,_03.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sistine_Chapel',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'AL', number: 1 },
          { code: 'MU', number: 2 },
          { code: 'GT', number: 1 },
        ],
        landmarkPlaces: [
          "St. Tryphon's Cathedral",
          'Neveh Shalom Synagogue',
          'Ulu Temburong National Park',
          'Annandale Waterfall',
          'Tomb of Hafez',
          'Ouzoud Falls',
          'South Pole (ceremonial)',
        ],
        uniqueId: 'zHv3wmtm',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/vatican-rome-st-peters-basilica-594612/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_City',
        },
        landmark: {
          name: "St. Peter's Basilica",
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/st-peter-s-basilica-rome-catholic-2677061/',
          wikiLink: 'https://en.wikipedia.org/wiki/St._Peter%27s_Basilica',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'DQzl7yj9',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Vatican City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-concrete-building-under-white-clouds-during-daytime-tG8CuF9KG5I',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_City',
        },
        landmark: {
          name: 'Vatican Necropolis',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/69716881@N02/13289628674',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_Necropolis',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'TN', number: 1 },
          { code: 'HT', number: 3 },
          { code: 'LS', number: 1 },
        ],
        landmarkPlaces: [
          'Cheshire Hall Plantation',
          'Basilica of Our Lady of Peace',
          'Jökulsárlón',
          'The Blue Mosque',
          'Cacheu Fort',
          'Hiroshima Peace Memorial',
          'Fasil Ghebbi',
        ],
        uniqueId: 'ZzB8ZPwg',
        hasMapAndCityRound: false,
      },
      {
        number: 4,
        mainImage: {
          name: 'Vatican City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-city-0Bs3et8FYyg',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_City',
        },
        landmark: {
          name: 'Sistine Chapel',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/justaslice/49903870651',
          wikiLink: 'https://en.wikipedia.org/wiki/Sistine_Chapel',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'NP', number: 1 },
          { code: 'GP', number: 1 },
          { code: 'IR', number: 1 },
        ],
        landmarkPlaces: [
          'Tallinn Town Hall',
          'Kunta Kinteh Island',
          'Topkapı Palace',
          'Múlafossur Waterfall',
          'Million Dollar Point',
          'National Museum of Costume',
          'Champagne Reef, Dominica',
        ],
        uniqueId: 'vZJrFk7h',
        hasMapAndCityRound: false,
      },
      {
        number: 5,
        mainImage: {
          name: 'Vatican City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/low-angle-photography-of-buildings-during-daytime-UAdVpeCpdAg',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_City',
        },
        landmark: {
          name: "St. Peter's Basilica",
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-park-during-daytime-D3YTCO3S-xE',
          wikiLink: 'https://en.wikipedia.org/wiki/St._Peter%27s_Basilica',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'TO', number: 4 },
          { code: 'LR', number: 3 },
          { code: 'CD', number: 4 },
        ],
        landmarkPlaces: [
          "Jame' Asr Hassanil Bolkiah Mosque",
          'Leptis Magna',
          'Freedom Park',
          'Stonehenge',
          'The Big Buddha',
          "St. Paul's Catacombs",
          'Wiang Kum Kam',
        ],
        uniqueId: 'Qp4t8CYy',
        hasMapAndCityRound: false,
      },
      {
        number: 6,
        mainImage: {
          name: 'Vatican City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-street-with-a-domed-building-in-the-background-tyyvHmF68rc',
          wikiLink: 'https://en.wikipedia.org/wiki/Vatican_City',
        },
        landmark: {
          name: 'Gardens of Vatican City',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/taigatrommelchen/51335029189/',
          wikiLink: 'https://en.wikipedia.org/wiki/Gardens_of_Vatican_Cityv',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [
          { code: 'US', number: 6 },
          { code: 'KH', number: 4 },
          { code: 'TC', number: 4 },
        ],
        landmarkPlaces: [
          'Old Dongola',
          'Erasmusbrug',
          'Köl-Suu',
          'Oriental Pearl TV Tower',
          'Big Ben',
          "Prophet's Mosque",
          'National Gallery',
        ],
        uniqueId: 'bZ4j7Dfr',
        hasMapAndCityRound: false,
      },
    ],
  },
  {
    name: 'Venezuela',
    code: 'VE',
    capital: 'Caracas',
    lat: 8,
    long: -66,
    cities: [
      'Caracas',
      'La Plata',
      'Merida',
      'Ciudad Bolivar',
      'Barquisimeto',
      'Maracaibo',
      'Charallave',
      'Mérida',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/ZZeuF8ZLi3o',
          wikiLink: 'https://en.wikipedia.org/wiki/Venezuela',
        },
        landmark: {
          name: 'Plaza Francia',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Plaza_Francia,_Buenos_Aires#/media/File:Plaza_Francia,_Recoleta.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Plaza_Francia,_Buenos_Aires',
          hasLandmark: true,
        },
        city: {
          name: 'La Plata',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/La_Plata#/media/File:Casco_urbano_fundacional_de_la_Ciudad_de_La_Plata.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/La_Plata',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'YYXrnhl4',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/caracas-venezuela-sunset-clouds-4539664/',
          wikiLink: 'https://en.wikipedia.org/wiki/Caracas',
        },
        landmark: {
          name: 'Angel Falls',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Angel_Falls#/media/File:Angel_falls_panoramic_20080314.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Angel_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Caracas',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Caracas#/media/File:Plaza_Venezuela_Sunset.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Caracas',
        },
        landmarksRound: [
          { code: 'CA', number: 1 },
          { code: 'PT', number: 2 },
          { code: 'GE', number: 1 },
        ],
        landmarkPlaces: [
          'Charles Bridge',
          'Burj Khalifa',
          'Umayyad Mosque',
          'Marcus Aurelius Arch',
          'Golden Gate Bridge',
          'Uyuni Salt Flat',
          'Saqsayhuaman',
        ],
        uniqueId: 'pM7LbFfv',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Las Mercedes',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photograph-of-city-near-mountain-F4LHE4PBWEA',
          wikiLink: 'https://en.wikipedia.org/wiki/Las_Mercedes,_Caracas',
        },
        landmark: {
          name: 'National Pantheon',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:National_Pantheon_of_Venezuela#/media/File:Panteon_Nacional_-_Caracas.JPG',
          wikiLink:
            'https://en.wikipedia.org/wiki/National_Pantheon_of_Venezuela',
          hasLandmark: true,
        },
        city: {
          name: 'Caracas',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photograph-of-city-near-mountain-F4LHE4PBWEA',
          wikiLink: 'https://en.wikipedia.org/wiki/Caracas',
        },
        landmarksRound: [
          { code: 'PR', number: 1 },
          { code: 'PR', number: 3 },
          { code: 'TR', number: 2 },
        ],
        landmarkPlaces: [
          'Annaberg Sugar Plantation',
          'Grace Bay Beach',
          'Fort Christian',
          'Ampitheater of El Jem',
          'The Ethnographic Open-Air Museum of Latvia',
          'Grand Turk Lighthouse',
          'Fortaleza de São José da Amura',
        ],
        uniqueId: 'TCzzxS76',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'El Consejo',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/coconut-trees-bn873zF3ekc',
          wikiLink: 'https://en.wikipedia.org/wiki/El_Consejo',
        },
        landmark: {
          name: 'Fortín de La Galera',
          photographer: '',
          imageLink:
            'https://es.wikipedia.org/wiki/Fort%C3%ADn_de_La_Galera#/media/Archivo:Fortin_La_Galera_01.jpg',
          wikiLink: 'https://es.wikipedia.org/wiki/Fort%C3%ADn_de_La_Galera',
          hasLandmark: true,
        },
        city: {
          name: 'Maracaibo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Maracaibo#/media/File:Basilica_of_the_chinita_on_the_night_of_the_Nazarene_procession.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Maracaibo',
        },
        landmarksRound: [
          { code: 'GP', number: 2 },
          { code: 'KG', number: 3 },
          { code: 'MO', number: 4 },
        ],
        landmarkPlaces: [
          'Ark of Bukhara',
          'Roman Baths',
          'St. Peter the Apostle Cathedral',
          'Ampitheater of El Jem',
          'Tsingoni Mosque',
          'Tian Tan Buddha',
          'Liwonde National Park',
        ],
        uniqueId: 'qNRYy4Xw',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Venezuela',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/venezuela-cross-port-dairy-991906/',
          wikiLink: 'https://en.wikipedia.org/wiki/Venezuela',
        },
        landmark: {
          name: 'Plaza Francia',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/8653836@N04/1424921458/',
          wikiLink: 'https://en.wikipedia.org/wiki/Plaza_Francia_(Caracas)',
          hasLandmark: true,
        },
        city: {
          name: 'Charallave',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/city-with-high-rise-buildings-during-night-time-b6Pzz9yrjsg',
          wikiLink: 'https://en.wikipedia.org/wiki/Charallave',
        },
        landmarksRound: [
          { code: 'US', number: 1 },
          { code: 'PT', number: 4 },
          { code: 'RS', number: 1 },
        ],
        landmarkPlaces: [
          'Naval Support Facility Diego Garcia',
          'Tenaru Falls',
          'Marigot Bay',
          'Cattedrale di Mogadiscio',
          'Monumento al Divino Salvador del Mundo',
          'Immaculate Conception Cathedral',
          'Neuschwanstein Castle',
        ],
        uniqueId: '7G5TckbX',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Venezuela',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-boat-in-the-water-with-palm-trees-in-the-background-8wEun5h7ycc',
          wikiLink: 'https://en.wikipedia.org/wiki/Venezuela',
        },
        landmark: {
          name: 'Fortín de La Galera',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/joycer_g/2453657152/',
          wikiLink: 'https://es.wikipedia.org/wiki/Fort%C3%ADn_de_La_Galera',
          hasLandmark: true,
        },
        city: {
          name: 'Mérida',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-city-from-the-top-of-a-hill-G2lFWVI93cM',
          wikiLink: 'https://en.wikipedia.org/wiki/M%C3%A9rida,_M%C3%A9rida',
        },
        landmarksRound: [
          { code: 'ZW', number: 5 },
          { code: 'HU', number: 5 },
          { code: 'HR', number: 6 },
        ],
        landmarkPlaces: [
          'Mother Ukraine',
          'Mount Nyriagongo',
          'Elmina Castle',
          'Matobo National Park',
          'Tarangire National Park',
          'Roman Baths',
          'Pulu Keeling National Park',
        ],
        uniqueId: 'qyZmb6jF',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Vietnam',
    code: 'VN',
    capital: 'Hanoi',
    lat: 16.16666666,
    long: 107.8333333,
    cities: [
      'Hanoi',
      'Haiphong',
      'Da Nang',
      'Ho Chi Minh City',
      'Can Tho',
      'Hue',
      'Hoi An',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/vcu-OZBxxRk',
          wikiLink: 'https://en.wikipedia.org/wiki/H%E1%BA%A1_Long_Bay',
        },
        landmark: {
          name: 'Temple of Literature',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/the-memorial-of-literature-7169179/',
          wikiLink: 'https://en.wikipedia.org/wiki/Temple_of_Literature,_Hanoi',
          hasLandmark: true,
        },
        city: {
          name: 'Haiphong',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Haiphong,_Vietnam_-_panoramio_%288%29.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Haiphong',
        },
        landmarksRound: [
          { code: 'CA', number: 1 },
          { code: 'AU', number: 1 },
          { code: 'BT', number: 1 },
        ],
        landmarkPlaces: [
          'Pyramiden',
          'Parthenon',
          'Voortrekker Monument',
          'Souq Waqif',
          'Tsingy De Bemaraha',
          'Kittiwake Shipwreck',
          'Monteverde Cloud Forest',
        ],
        uniqueId: 'WgSMwwWl',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/view-landscape-nature-vietnam-2843338/',
          wikiLink: 'https://en.wikipedia.org/wiki/Vietnam',
        },
        landmark: {
          name: 'The Marble Mountains',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/marble-mountain-mountain-vietnam-4470296/',
          wikiLink: 'https://en.wikipedia.org/wiki/Marble_Mountains_(Vietnam)',
          hasLandmark: true,
        },
        city: {
          name: 'Da Nang',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/cityscape-danang-pretty-3716797/',
          wikiLink: 'https://en.wikipedia.org/wiki/Da_Nang',
        },
        landmarksRound: [
          { code: 'CY', number: 2 },
          { code: 'BA', number: 1 },
          { code: 'KR', number: 2 },
        ],
        landmarkPlaces: [
          'Chamarel Waterfall',
          'Shwedagon Pagoda',
          'Great Blue Hole',
          'Chorsu Bazaar',
          'National Shrine Basilica of our Lady of Las Lajas',
          'Palace of Versailles',
          'Tavarua',
        ],
        uniqueId: '6zQmtvTH',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Hạ Long',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-angle-photography-of-boats-on-water-near-hill-during-daytime-vcu-OZBxxRk',
          wikiLink: 'https://en.wikipedia.org/wiki/H%E1%BA%A1_Long',
        },
        landmark: {
          name: 'Ban Gioc–Detian Falls',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/yoyo0/48370537232',
          wikiLink:
            'https://en.wikipedia.org/wiki/Ban_Gioc%E2%80%93Detian_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Hanoi',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/red-and-white-train-on-rail-road-during-daytime-vP-N4_E9GcE',
          wikiLink: 'https://en.wikipedia.org/wiki/Hanoi',
        },
        landmarksRound: [
          { code: 'GY', number: 2 },
          { code: 'GG', number: 2 },
          { code: 'WF', number: 2 },
        ],
        landmarkPlaces: [
          'Mount Everest',
          'Cathédrale Notre-Dame',
          'Tsingy Rouge',
          'Christ Church Cathedral',
          'Soufriere Hills',
          'Big Ben',
          'Memorial da Escravatura e do Tráfico Negreiro',
        ],
        uniqueId: 'C7sfKTJn',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Ninh Bình',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-house-between-two-cliffs-8MhejqEghLk',
          wikiLink: 'https://en.wikipedia.org/wiki/Ninh_B%C3%ACnh',
        },
        landmark: {
          name: 'Temple of Literature',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/beggs/32154960113',
          wikiLink: 'https://en.wikipedia.org/wiki/Temple_of_Literature,_Hanoi',
          hasLandmark: true,
        },
        city: {
          name: 'Ho Chi Minh City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/white-and-brown-boat-on-water-near-city-buildings-during-daytime-NuBqNEZvTwU',
          wikiLink: 'https://en.wikipedia.org/wiki/Ho_Chi_Minh_City',
        },
        landmarksRound: [
          { code: 'MS', number: 2 },
          { code: 'XK', number: 4 },
          { code: 'SZ', number: 2 },
        ],
        landmarkPlaces: [
          'National Pantheon',
          'Katse Dam',
          "Vianden's Castle",
          'Petronas Towers',
          'National Museum of Costume',
          'Christ Church Cathedral',
          'Ducie Island',
        ],
        uniqueId: 'jC7wwT3c',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Ninh Bình',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-view-photography-of-brown-pagoda-temple-during-daytime-JF4o7WErDn8',
          wikiLink: 'https://en.wikipedia.org/wiki/Ninh_B%C3%ACnh',
        },
        landmark: {
          name: 'Golden Bridge ',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/people-walking-on-bridge-during-daytime-PknaOrb1lVo',
          wikiLink: 'https://en.wikipedia.org/wiki/Golden_Bridge_(Vietnam)',
          hasLandmark: true,
        },
        city: {
          name: 'Hoi An',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/two-women-riding-boat-beside-brown-house-27HacQwqvA0',
          wikiLink: 'https://en.wikipedia.org/wiki/H%E1%BB%99i_An',
        },
        landmarksRound: [
          { code: 'PF', number: 2 },
          { code: 'AF', number: 2 },
          { code: 'LI', number: 5 },
        ],
        landmarkPlaces: [
          'Baiterek',
          'Anse Lazio',
          'Charyn Canyon',
          'Victoria Falls Bridge',
          'Vaduz Castle',
          'Herat Citadel',
          'Ducie Island',
        ],
        uniqueId: 'tCCPZm9P',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Nguyễn Huệ Boulevard',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/high-rise-building-near-body-of-water-during-daytime-soYzQJvctKI',
          wikiLink:
            'https://en.wikipedia.org/wiki/Nguy%E1%BB%85n_Hu%E1%BB%87_Boulevard',
        },
        landmark: {
          name: 'Khau Phạ Pass',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/aerial-photography-of-green-mountain-3QFQOxGCVmE',
          wikiLink: 'https://en.wikipedia.org/wiki/Khau_Ph%E1%BA%A1_Pass',
          hasLandmark: true,
        },
        city: {
          name: 'Ho Chi Minh City',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-street-filled-with-lots-of-people-UtbUQWxElVs',
          wikiLink: 'https://en.wikipedia.org/wiki/Ho_Chi_Minh_City',
        },
        landmarksRound: [
          { code: 'TK', number: 3 },
          { code: 'GH', number: 3 },
          { code: 'KM', number: 5 },
        ],
        landmarkPlaces: [
          'Alexander Nevsky Cathedral',
          "Devil's Bridge",
          "St. Olaf's Church",
          'Fongafale',
          'Semuc Champey',
          'Lake Baikal',
          'Point Venus',
        ],
        uniqueId: 'yWsRzJ3k',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
    capital: 'Mata-Utu',
    lat: -13.3,
    long: -176.2,
    cities: ['Mata-Utu', 'Leava', 'Liku', 'Alele', 'Halalo', 'Vailala'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Mata-Utu_seen_from_a_drone_%28Wallis_and_Futuna%29.png',
          wikiLink: 'https://en.wikipedia.org/wiki/Mata_Utu',
        },
        landmark: {
          name: 'Lake Lalolalo',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Lac_Lalolalo_Sept._2018_drone.png',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Lalolalo',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'Jzsx6T6h',
        hasMapAndCityRound: false,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://pxhere.com/en/photo/1642780',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallis_and_Futuna',
        },
        landmark: {
          name: 'Mata-Utu Cathedral',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Cath%C3%A9drale_de_Mata-Utu_%28Wallis-et-Futuna%29_drone.png',
          wikiLink:
            'https://en.wikipedia.org/wiki/Cathedral_of_Our_Lady_of_the_Assumption,_Mata-Utu',
          hasLandmark: true,
        },
        city: { name: '', photographer: '', imageLink: '', wikiLink: '' },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '2T9h33Vw',
        hasMapAndCityRound: false,
      },
      {
        number: 3,
        mainImage: {
          name: 'Wallis and Futuna',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/frf123/27192910604',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallis_and_Futuna',
        },
        landmark: {
          name: 'Talietumu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Talietumu#/media/File:Talietumu.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Talietumu',
          hasLandmark: true,
        },
        city: {
          name: 'Mata-Utu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Mata_Utu#/media/File:287_Place_Sagato_Soane_le_lendemain_de_la_f%C3%AAte_du_territoire_2017_(36269295603).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Mata-Utu',
        },
        landmarksRound: [
          { code: 'FO', number: 2 },
          { code: 'LB', number: 1 },
          { code: 'JE', number: 4 },
        ],
        landmarkPlaces: [
          'Palacio Salvo',
          'Independence Monument',
          'Wat Xiengthong',
          'La Savane des esclaves',
          'Mount Fuji',
          'Funafala',
          'Grace Bay Beach',
        ],
        uniqueId: 'dDRf5MFj',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Wallis and Futuna',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/frf123/27704836162/in/pool-wallis_and_futuna/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallis_and_Futuna',
        },
        landmark: {
          name: 'Lake Lalolalo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/frf123/27192910604',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Lalolalo',
          hasLandmark: true,
        },
        city: {
          name: 'Leava',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/neumuller/50023794798',
          wikiLink: 'https://en.wikipedia.org/wiki/Leava',
        },
        landmarksRound: [
          { code: 'IT', number: 3 },
          { code: 'MQ', number: 3 },
          { code: 'GM', number: 1 },
        ],
        landmarkPlaces: [
          'Lake Armor',
          'Kylemore Abbey',
          'Chinguetti',
          'Vajdahunyad Castle',
          "St. Tryphon's Cathedral",
          'Topkapı Palace',
          'The Basilica of Our Lady of Peace of Yamoussoukro',
        ],
        uniqueId: 'R7CXGdTg',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Wallis and Futuna',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/neumuller/34055199544/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallis_and_Futuna',
        },
        landmark: {
          name: 'Cathedral of Our Lady of the Assumption',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/128743393@N04/16185247366/',
          wikiLink:
            'https://en.wikipedia.org/wiki/Cathedral_of_Our_Lady_of_the_Assumption,_Mata-Utu',
          hasLandmark: true,
        },
        city: {
          name: 'Mata-Utu',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/roving_spirits/36941610301/',
          wikiLink: 'https://en.wikipedia.org/wiki/Mata-Utu',
        },
        landmarksRound: [
          { code: 'IE', number: 3 },
          { code: 'GL', number: 5 },
          { code: 'JO', number: 4 },
        ],
        landmarkPlaces: [
          'Réserve du Mont Fouari',
          'Kunta Kinteh Island',
          'St. Alexander Nevsky Cathedral',
          "St. Paul's Cathedral",
          'Sainte-Chapelle',
          'Nuestra Señora de la Candelaria',
          'La Soufriere',
        ],
        uniqueId: 'JdhKwlfX',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Wallis and Futuna',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/klm_pics/8233178829/',
          wikiLink: 'https://en.wikipedia.org/wiki/Wallis_and_Futuna',
        },
        landmark: {
          name: 'Lake Lalolalo',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/lespearce/5087044766/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Lalolalo',
          hasLandmark: true,
        },
        city: {
          name: 'Leava',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/roving_spirits/36539987600/',
          wikiLink: 'https://en.wikipedia.org/wiki/Leava',
        },
        landmarksRound: [
          { code: 'JM', number: 4 },
          { code: 'TC', number: 5 },
          { code: 'AL', number: 1 },
        ],
        landmarkPlaces: [
          'Detwah Lagoon',
          'Great Mosque of Kairouan',
          'Fort Frederick',
          'Perito Moreno Glacier',
          'Monday Falls',
          'Château de Chambord',
          'Pamir Mountains',
        ],
        uniqueId: 'KW8ML3Ty',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Western Sahara',
    code: 'EH',
    capital: 'El Aaiún',
    lat: 24.5,
    long: -13,
    cities: [
      'El Marsa',
      'Laayoune',
      'Hawza',
      'Dakhla',
      'Smara',
      'Cape Bojador',
      'El Aaiún',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Western_sahara_landscape.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
        landmark: {
          name: 'Cintra Bay',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Cintra_Bay#/media/File:Western_Sahara,_2007-12-25.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Cintra_Bay',
          hasLandmark: true,
        },
        city: {
          name: 'Dakhla',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dakhla,_Western_Sahara#/media/File:Dakhla,_Western_Sahara_(11).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dakhla,_Western_Sahara',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'YgcfFhrT',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://www.jns.org/report-israel-us-consider-deal-to-recognize-moroccan-sovereignty-over-western-sahara/',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
        landmark: {
          name: "People's Liberation Army Museum",
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/People%27s_Liberation_Army_Museum#/media/File:Mus%C3%A9e_RASD_Polisario.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/People%27s_Liberation_Army_Museum',
          hasLandmark: true,
        },
        city: {
          name: 'Smara',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Smara#/media/File:Smara,rooftopE.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Smara',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'd9mX4nXg',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Western Sahara',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/un_photo/4796660796',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
        landmark: {
          name: 'St. Francis of Assisi Cathedral',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/St._Francis_of_Assisi_Cathedral,_Laayoune#/media/File:Laayoune_Cathedral_2011.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Francis_of_Assisi_Cathedral,_Laayoune',
          hasLandmark: true,
        },
        city: {
          name: 'Laayoune',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Laayoune#/media/File:Street_view_from_Laayoune_2011.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Laayoune',
        },
        landmarksRound: [
          { code: 'LB', number: 1 },
          { code: 'GF', number: 4 },
          { code: 'GH', number: 3 },
        ],
        landmarkPlaces: [
          'Maluti Mountains',
          'Fort Antoine Theatre',
          'Independence Monument',
          'Copán',
          'Ħal Saflieni Hypogeum',
          "Dunn's River Falls",
          'The Pitcairn Museum',
        ],
        uniqueId: 'rzrWrhj7',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Western Sahara',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/un_photo/5309455601',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
        landmark: {
          name: 'Moroccan sand wall',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Moroccan_Western_Sahara_Wall#/media/File:Muro_desde_el_aire.jpg',
          wikiLink:
            'https://en.wikipedia.org/wiki/Moroccan_Western_Sahara_Wall',
          hasLandmark: true,
        },
        city: {
          name: 'Dakhla',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/Category:Dakhla#/media/File:A_man_in_black_dress_before_a_shut_telephone_booth_in_Dakhla.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dakhla,_Western_Sahara',
        },
        landmarksRound: [
          { code: 'GU', number: 2 },
          { code: 'MW', number: 1 },
          { code: 'MD', number: 3 },
        ],
        landmarkPlaces: [
          'Alte Rheinbrücke',
          'Mawson Peak',
          'Laferriere Citadel',
          'Columbus Park',
          'Arecibo Observatory',
          'Borobudur Temple',
          'Maluti Mountains',
        ],
        uniqueId: 'xZtLkXvn',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Western Sahara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/motorcycle-in-the-middle-of-desert-sberykLLUmw',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
        landmark: {
          name: 'St. Francis of Assisi Cathedral',
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/davidstanleytravel/6810120437/in/photolist-bnMDxB',
          wikiLink:
            'https://en.wikipedia.org/wiki/St._Francis_of_Assisi_Cathedral,_Laayoune',
          hasLandmark: true,
        },
        city: {
          name: 'Laayoune',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/granduck/2489858556/',
          wikiLink: 'https://en.wikipedia.org/wiki/Laayoune',
        },
        landmarksRound: [
          { code: 'AQ', number: 5 },
          { code: 'SO', number: 2 },
          { code: 'CX', number: 4 },
        ],
        landmarkPlaces: [
          'Fortín de La Galera',
          'Liberian National Museum',
          'Citadel of Aleppo',
          'Atlas Cove',
          'Berat Castle',
          'Pedro St. James Castle',
          'Tarawa',
        ],
        uniqueId: 'w83vdVTG',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Western Sahara',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/top-view-of-white-boat-docked-on-shore-Cn-EpLcknlY',
          wikiLink: 'https://en.wikipedia.org/wiki/Western_Sahara',
        },
        landmark: {
          name: 'Laayoune',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/tomasomi/8633389457/',
          wikiLink: 'https://en.wikipedia.org/wiki/Laayoune',
          hasLandmark: true,
        },
        city: {
          name: 'Dakhla',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/harmsplaatjes/32411112703/',
          wikiLink: 'https://en.wikipedia.org/wiki/Dakhla,_Western_Sahara',
        },
        landmarksRound: [
          { code: 'ML', number: 6 },
          { code: 'CY', number: 6 },
          { code: 'PR', number: 1 },
        ],
        landmarkPlaces: [
          'Angel Falls',
          'Sri Dalada Maligawa',
          'Bled Castle',
          'Kravica Waterfall',
          'Christmas Island National Park',
          'Henderson Island',
          'Baiterek',
        ],
        uniqueId: 'kWxbqS7v',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Yemen',
    code: 'YE',
    capital: "Sana'a",
    lat: 15,
    long: 48,
    cities: ["Sana'a", 'Taizz', 'Zinjibar', 'Aden', 'Ibb', 'Al Mukalla'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/REwZEYzw19g',
          wikiLink: 'https://en.wikipedia.org/wiki/Dracaena_cinnabari',
        },
        landmark: {
          name: 'Stone House',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Dar_al-Hajar#/media/File:Dar_al_hajar_edit.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Dar_al-Hajar',
          hasLandmark: true,
        },
        city: {
          name: "Sana'a",
          photographer: '',
          imageLink: 'https://pixabay.com/photos/city-sana-a-yemen-5046666/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sanaa',
        },
        landmarksRound: [
          { code: 'MQ', number: 1 },
          { code: 'RE', number: 1 },
          { code: 'GL', number: 1 },
        ],
        landmarkPlaces: [
          'Christ the Redeemer',
          'Souq Waqif',
          'Golden Gate',
          'Ampitheater of El Jem',
          'Taj Mahal',
          'St. Alexander Nevsky Cathedral',
          'Chimi Lhakhang',
        ],
        uniqueId: 'rcVccWDV',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/yemen-middle-east-neighborhood-6037293/',
          wikiLink: 'https://en.wikipedia.org/wiki/Yemen',
        },
        landmark: {
          name: 'Detwah Lagoon',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Detwah_Lagoon#/media/File:Detwah_lagoon_(6408178947).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Detwah_Lagoon',
          hasLandmark: true,
        },
        city: {
          name: 'Taizz',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Taiz#/media/File:Taiz_(15182373707).jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Taiz',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'zjGcfP2g',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Yemen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-concrete-building-under-blue-sky-during-daytime-YY9_yyBD-UU',
          wikiLink: 'https://en.wikipedia.org/wiki/Yemen',
        },
        landmark: {
          name: 'Stone House',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/rod_waddington/39886640580',
          wikiLink: 'https://en.wikipedia.org/wiki/Dar_al-Hajar',
          hasLandmark: true,
        },
        city: {
          name: "Sana'a",
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-night-view-of-a-city-with-green-lights-5Qw6sMWqWLg',
          wikiLink: 'https://en.wikipedia.org/wiki/Sana%27a',
        },
        landmarksRound: [
          { code: 'JP', number: 4 },
          { code: 'VA', number: 1 },
          { code: 'TR', number: 3 },
        ],
        landmarkPlaces: [
          'Enewetak Atoll',
          "Lac 'Assal",
          'Grace Bay Beach',
          'Annaberg Sugar Plantation',
          'Senso-ji',
          'The Blue Mosque',
          'Panfilov Park',
        ],
        uniqueId: 'f5ZMnzMf',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Socotra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-trees-in-the-middle-of-a-forest-nQvFebPtqbw',
          wikiLink: 'https://en.wikipedia.org/wiki/Socotra',
        },
        landmark: {
          name: 'Al Saleh Mosque',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/36209325@N05/4309671020',
          wikiLink: 'https://en.wikipedia.org/wiki/Al_Saleh_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Aden',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Aden#/media/File:MallaDistrict.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Aden',
        },
        landmarksRound: [
          { code: 'SI', number: 3 },
          { code: 'AE', number: 3 },
          { code: 'IT', number: 3 },
        ],
        landmarkPlaces: [
          'Frida Kahlo Museum',
          'Mount Yasur',
          'Tsunami Monument',
          'Golden Gate Bridge',
          'Langkawi Sky Bridge',
          'Mont Orgueil Castle',
          'Burj Khalifa',
        ],
        uniqueId: 'D57MlVRB',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Yemen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/the-sun-is-setting-over-the-sand-dunes-f9rQRvPkWds',
          wikiLink: 'https://en.wikipedia.org/wiki/Yemen',
        },
        landmark: {
          name: 'Queen Arwa Mosque',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-city-with-many-buildings-vNTrQ49rByg',
          wikiLink: 'https://en.wikipedia.org/wiki/Queen_Arwa_Mosque',
          hasLandmark: true,
        },
        city: {
          name: 'Aden',
          photographer: '',
          imageLink: 'https://www.flickr.com/photos/130657197@N05/16902386342/',
          wikiLink: 'https://en.wikipedia.org/wiki/Aden',
        },
        landmarksRound: [
          { code: 'FO', number: 6 },
          { code: 'KH', number: 2 },
          { code: 'SZ', number: 4 },
        ],
        landmarkPlaces: [
          'Saunders Island',
          'Pico Basile',
          'Independence Monument',
          'Agadez Grand Mosque',
          'Dhammayangyi Temple',
          'Grand Mosque of Kuwait',
          'Hwange National Park',
        ],
        uniqueId: 'jsTK2bcd',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Yemen',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-canyon-with-a-few-buildings-NSEluxZW_k88',
          wikiLink: 'https://en.wikipedia.org/wiki/Yemen',
        },
        landmark: {
          name: 'Socotra',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-tree-with-a-mountain-in-the-background-REwZEYzw19g',
          wikiLink: 'https://en.wikipedia.org/wiki/Socotra',
          hasLandmark: true,
        },
        city: {
          name: "Sana'a",
          photographer: '',
          imageLink:
            'https://www.flickr.com/photos/rod_waddington/51886508651/',
          wikiLink: 'https://en.wikipedia.org/wiki/Sana%27a',
        },
        landmarksRound: [
          { code: 'CI', number: 2 },
          { code: 'PH', number: 1 },
          { code: 'KE', number: 1 },
        ],
        landmarkPlaces: [
          'Akershus Fortress',
          'Kuang Si Falls',
          'Kpatawee Waterfall',
          'Neuschwanstein Castle',
          'Cristo Rei',
          'Christmas Island National Park',
          'Church of Our Savior',
        ],
        uniqueId: 'lSN5Y92d',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Zambia',
    code: 'ZM',
    capital: 'Lusaka',
    lat: -15,
    long: 30,
    cities: ['Lusaka', 'Kitwe', 'Ndola', 'Livingstone', 'Kabwe', 'Chipata'],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/zambia-sky-clouds-mountains-lake-94526/',
          wikiLink: 'https://en.wikipedia.org/wiki/Zambia',
        },
        landmark: {
          name: 'Victoria Falls',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rainbow-waterfall-water-landscape-3320571/',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Ndola',
          photographer: '',
          imageLink:
            'https://commons.wikimedia.org/wiki/File:Ndola_view_-_Flickr.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Ndola',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '29CWYrvy',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/rainbow-waterfall-water-landscape-3320571/',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Falls',
        },
        landmark: {
          name: 'Chishimba Falls',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/falls-rocks-water-leaves-evening-7289265/',
          wikiLink: 'https://en.wikipedia.org/wiki/Chisimba_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Lusaka',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/lusaka-skyline-morning-6228562/',
          wikiLink: 'https://en.wikipedia.org/wiki/Lusaka',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: '5mkSySTP',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Zambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/silhouette-of-trees-near-body-of-water-62CtkV44DuY',
          wikiLink: 'https://en.wikipedia.org/wiki/Zambia',
        },
        landmark: {
          name: 'Shiwa Ngandu',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Shiwa_Ngandu#/media/File:HouseatShiwaNgandu.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Shiwa_Ngandu',
          hasLandmark: true,
        },
        city: {
          name: 'Lusaka',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Lusaka#/media/File:Kafue_round_about.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Lusaka',
        },
        landmarksRound: [
          { code: 'GQ', number: 3 },
          { code: 'MG', number: 4 },
          { code: 'LS', number: 4 },
        ],
        landmarkPlaces: [
          'Victoria Falls Bridge',
          'Ten Thousand Buddhas Monastery',
          'Hiroshima Peace Memorial',
          'Predjama Castle',
          'Palacio Salvo',
          'Nan Madol',
          'Rock of Cashel',
        ],
        uniqueId: '3MgnRLb7',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Zambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-group-of-people-walking-on-a-train-track-3RsU_reOgO0',
          wikiLink: 'https://en.wikipedia.org/wiki/Zambia',
        },
        landmark: {
          name: 'Victoria Falls',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Victoria_Falls#/media/File:Cataratas_Victoria,_Zambia-Zimbabue,_2018-07-27,_DD_16-20_PAN.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Kitwe',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Kitwe#/media/File:St_John_Convent_School_Kitwe.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Kitwe',
        },
        landmarksRound: [
          { code: 'MV', number: 1 },
          { code: 'JO', number: 2 },
          { code: 'KW', number: 1 },
        ],
        landmarkPlaces: [
          'Allahverdi Khan Bridge',
          'Fort Antoine Theatre',
          'Arahoho Blowhole',
          'Alhambra',
          'Azadi Tower',
          'Vajdahunyad Castle',
          'Bay of Kotor',
        ],
        uniqueId: '82znbx4N',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Zambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/man-riding-on-motorcycle-between-trees-CvOLyQRE5vE',
          wikiLink: 'https://en.wikipedia.org/wiki/Zambia',
        },
        landmark: {
          name: 'Mosi-oa-Tunya National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-trees-under-white-clouds-and-blue-sky-during-daytime-gnKICnDA5Hw',
          wikiLink: 'https://en.wikipedia.org/wiki/Mosi-oa-Tunya_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Ndola',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-stadium-with-a-green-field-in-front-of-it-McgrH1Ib8mA',
          wikiLink: 'https://en.wikipedia.org/wiki/Ndola',
        },
        landmarksRound: [
          { code: 'AZ', number: 4 },
          { code: 'NL', number: 1 },
          { code: 'FR', number: 1 },
        ],
        landmarkPlaces: [
          'Chutes de Boali',
          'Amberd',
          'Sukhbaatar Square',
          'Chinko Nature Reserve',
          'Chalk Sound National Park',
          'Tokyo Skytree',
          'White Bay Bar',
        ],
        uniqueId: 'NKWdXSv6',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Zambia',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-with-trees-around-it-and-a-sunset-4N15WgbmA9I',
          wikiLink: 'https://en.wikipedia.org/wiki/Zambia',
        },
        landmark: {
          name: 'South Luangwa National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/brown-animals-on-pasture-during-daytime-aTAcckKiphI',
          wikiLink: 'https://en.wikipedia.org/wiki/South_Luangwa_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Lusaka',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-building-with-a-crane-on-top-of-it-IZHcwmcp6Zw',
          wikiLink: 'https://en.wikipedia.org/wiki/Lusaka',
        },
        landmarksRound: [
          { code: 'AR', number: 5 },
          { code: 'IE', number: 2 },
          { code: 'MW', number: 4 },
        ],
        landmarkPlaces: [
          'Frogner Park',
          'Tarawa',
          'Mlilwane Wildlife Sanctuary',
          'St. Alexander Nevsky Cathedral',
          "Kabaka's Palace",
          'Great Siege Tunnels',
          'Golubac Fortress',
        ],
        uniqueId: 'pcKC9FXW',
        hasMapAndCityRound: true,
      },
    ],
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    capital: 'Harare',
    lat: -20,
    long: 30,
    cities: [
      'Bulawayo',
      'Harare',
      'Chitungwiza',
      'Mutare',
      'Marondera',
      'Chinhoyi',
      'Nyanga',
    ],
    game: [
      {
        number: 1,
        mainImage: {
          name: '',
          photographer: '',
          imageLink: 'https://unsplash.com/photos/JCFpOA5gnRA',
          wikiLink: 'https://en.wikipedia.org/wiki/Lake_Kariba',
        },
        landmark: {
          name: 'Victoria Falls Bridge',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/victoria-falls-bird-s-eye-view-4857680/',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Falls_Bridge',
          hasLandmark: true,
        },
        city: {
          name: 'Bulawayo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bulawayo#/media/File:Bulawayo_CBD.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bulawayo',
        },
        landmarksRound: [],
        landmarkPlaces: [],
        uniqueId: 'SZmwmn4J',
        hasMapAndCityRound: true,
      },
      {
        number: 2,
        mainImage: {
          name: '',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/pond-field-mountains-trees-meadow-6075837/',
          wikiLink: 'https://en.wikipedia.org/wiki/Zimbabwe',
        },
        landmark: {
          name: 'Matobo National Park',
          photographer: '',
          imageLink:
            'https://pixabay.com/photos/matobo-hills-granite-rock-6574279/',
          wikiLink: 'https://en.wikipedia.org/wiki/Matobo_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Harare',
          photographer: '',
          imageLink: 'https://pixabay.com/photos/city-zimbabwe-harare-568208/',
          wikiLink: 'https://en.wikipedia.org/wiki/Harare',
        },
        landmarksRound: [
          { code: 'GT', number: 2 },
          { code: 'CW', number: 1 },
          { code: 'PT', number: 1 },
        ],
        landmarkPlaces: [
          'Wat Xiengthong',
          'Green Grotto Caves',
          'Voortrekker Monument',
          'Al Alam Palace',
          'Motherland Monument',
          'Arecibo Observatory',
          'Lotto World',
        ],
        uniqueId: 'jRKgzR2Z',
        hasMapAndCityRound: true,
      },
      {
        number: 3,
        mainImage: {
          name: 'Victoria Falls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/water-falls-on-brown-rocky-mountain-during-daytime-xsypL99HP3Q',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Falls',
        },
        landmark: {
          name: 'Nesbitt Castle',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Nesbitt_Castle#/media/File:Nesbittcastle2.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Nesbitt_Castle',
          hasLandmark: true,
        },
        city: {
          name: 'Harare',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Harare#/media/File:Parlament_of_Zimbabwe.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Harare',
        },
        landmarksRound: [
          { code: 'IM', number: 3 },
          { code: 'US', number: 3 },
          { code: 'TC', number: 3 },
        ],
        landmarkPlaces: [
          'East Side Gallery',
          'Valea Morilor',
          'Cape Coast Castle',
          'Cité de Carcassonne',
          'Pipeline Mosque',
          'Acropolis',
          'Basilica of Our Lady of Guadalupe',
        ],
        uniqueId: 'xhbJtMb6',
        hasMapAndCityRound: true,
      },
      {
        number: 4,
        mainImage: {
          name: 'Eastern Highlands',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/green-grass-field-scenery-hLXnoE4TLSI',
          wikiLink: 'https://en.wikipedia.org/wiki/Eastern_Highlands',
        },
        landmark: {
          name: 'Matobo National Park',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Matobo_National_Park#/media/File:Sunrise_Matobo_Zimbabwe.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Matobo_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Bulawayo',
          photographer: '',
          imageLink:
            'https://en.wikipedia.org/wiki/Bulawayo#/media/File:Bulawayo_City_Hall.jpg',
          wikiLink: 'https://en.wikipedia.org/wiki/Bulawayo',
        },
        landmarksRound: [
          { code: 'UG', number: 4 },
          { code: 'GF', number: 4 },
          { code: 'ME', number: 4 },
        ],
        landmarkPlaces: [
          'Mount Rushmore',
          'The Ethnographic Open-Air Museum of Latvia',
          'Kunta Kinteh Island',
          'East Side Gallery',
          'Roman theatre',
          'Monumento al Divino Salvador del Mundo',
          'Tomb of Askia',
        ],
        uniqueId: 'xx2QFQKm',
        hasMapAndCityRound: true,
      },
      {
        number: 5,
        mainImage: {
          name: 'Victoria Falls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-body-of-water-surrounded-by-trees-and-clouds-hSdX08KTwk8',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Falls',
        },
        landmark: {
          name: 'Hwange National Park',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/herd-of-elephant-drinking-water-from-lake-I7KBqnjN9xM',
          wikiLink: 'https://en.wikipedia.org/wiki/Hwange_National_Park',
          hasLandmark: true,
        },
        city: {
          name: 'Harare',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-building-with-a-lot-of-windows-uiMVkWV2VVg',
          wikiLink: 'https://en.wikipedia.org/wiki/Harare',
        },
        landmarksRound: [
          { code: 'KZ', number: 5 },
          { code: 'AF', number: 1 },
          { code: 'SC', number: 2 },
        ],
        landmarkPlaces: [
          'Anse Lazio',
          'Fortress of São Miguel',
          'Qargha Reservoir',
          'Point Venus',
          'Belvedere Lookout',
          'Temple of Literature',
          'Arahoho Blowhole',
        ],
        uniqueId: 'Lbmq9CJB',
        hasMapAndCityRound: true,
      },
      {
        number: 6,
        mainImage: {
          name: 'Zimbabwe',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-grassy-field-with-a-mountain-in-the-background-LozNVrK-dJU',
          wikiLink: 'https://en.wikipedia.org/wiki/Zimbabwe',
        },
        landmark: {
          name: 'Victoria Falls',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-large-waterfall-with-trees-in-the-background--q_1O9QNvPk',
          wikiLink: 'https://en.wikipedia.org/wiki/Victoria_Falls',
          hasLandmark: true,
        },
        city: {
          name: 'Nyanga',
          photographer: '',
          imageLink:
            'https://unsplash.com/photos/a-view-of-a-valley-from-a-high-point-of-view-qefUmV2BjHs',
          wikiLink: 'https://en.wikipedia.org/wiki/Nyanga,_Zimbabwe',
        },
        landmarksRound: [
          { code: 'NG', number: 1 },
          { code: 'LB', number: 3 },
          { code: 'BJ', number: 2 },
        ],
        landmarkPlaces: [
          'Soroca Fort',
          'Pripyat',
          'Frogner Park',
          'Nasir al-Mulk Mosque',
          'Church of Our Lady of the Rosary',
          'Tegucigalpa Cathedral',
          'South Pole (ceremonial)',
        ],
        uniqueId: 'scwWmDBc',
        hasMapAndCityRound: true,
      },
    ],
  },
];
