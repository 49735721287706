import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './wheretaken-new.component.html',
  styleUrls: ['./wheretaken-new.component.scss'],
})
export class WheretakenNewComponent {
  constructor(public dialogRef: MatDialogRef<WheretakenNewComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
