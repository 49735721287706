import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ITeuteufGameInfo } from 'src/app/interfaces/interfaces';
import { CDNDataService } from 'src/app/services/cdn.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-teuteuf-games',
  templateUrl: './teuteuf-games.component.html',
  styleUrls: ['./teuteuf-games.component.scss'],
})
export class TeuteufGamesComponent implements OnInit {
  content$: Observable<ITeuteufGameInfo>;
  langCode: string = 'en';

  constructor(
    private data: DataService,
    private title: Title,
    private cdnDataService: CDNDataService
  ) {
    this.content$ = this.cdnDataService.getGameInfo();
    this.title.setTitle('Teuteuf Games - WhereTaken');
  }

  ngOnInit(): void {}

  getGameIconUrl(gameName: string): string {
    return this.cdnDataService.getGameIconUrl(gameName);
  }

  navigateBack() {
    this.data.navigateToPreviousPage();
  }
}
