<div class="input-container" *ngIf="state$ | async as state">
  <div *ngIf="!state.showShare">
    <div class="input-wrapper">
      <div #dropdown *ngIf="showListBox" class="list-box">
        <a (click)="countrySelect(item)" class="item text-black dark:!text-white !bg-white dark:!bg-slate-800"
          *ngFor="let item of list">
          {{ item }}
        </a>
      </div>
      <input [formControl]="input" #inputBox (focus)="onFocus()" (click)="onFocus()" class="text-black dark:!text-white" type="text"
        placeholder="Enter a country/region..." />
    </div>
    <button (click)="guess()"
      class="text-black dark:!text-white font-bold p-1 flex items-center justify-center border-2 uppercase my-0.5 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700 w-full rounded">
      <b>
        <span class="text-lg">📷</span>
        GUESS
      </b>
    </button>
  </div>
  <div [ngClass]="{ 'confetti-under': !showConfetti }" class="confetti" #confettiContainer></div>
  <div class="relative" *ngIf="state.showShare">
    <div class="mt-2 mb-3 text-center" *ngIf="state.showAnswer">
      <p class="text-black dark:!text-white ">You didn't get it today 😢</p>
      <p class="text-black dark:!text-white">
        The country or region was <b>{{ (state$ | async)?.country }}</b>
      </p>
    </div>

    <div #bonusRound class="p-2 bg-gray-300 dark:bg-slate-700 rounded text-center flex flex-col justify-center items-center gap-1"
      *ngIf="state.showShare">
      <p class="font-bold text-black dark:!text-white">
        <span class="text-orange-700">★</span> Bonus Round ~ 1/3 - Landmarks <span
          class="text-orange-700">★</span>
      </p>
      <button class="!bg-green-600 text-white mb-2 w-full rounded py-1" (click)="goToLandmarks()">
        <b>🎁 Play the bonus round</b>
      </button>
    </div>
    <div>
      <app-share-buttons></app-share-buttons>
      <app-end-mobile-ad></app-end-mobile-ad>
    </div>
  </div>
</div>
