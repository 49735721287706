<mat-dialog-content>
  <div *ngIf="image$ | async as image">
      <div class="flex justify-center items-center">
          <img [ngSrc]="image" width="922" height="720">
      </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="w-full flex justify-end items-end">
      <button mat-button mat-dialog-close
      class="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">Close</button>
  </div>
</mat-dialog-actions>
