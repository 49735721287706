<div class="p-4 premium-bg text-white rounded w-full text-center flex flex-col justify-center items-center gap-1 shadow-md">
  <h5 class="text-2xl font-bold">Become a Teuteuf Premium Member </h5>
  <div class="grid md:grid-cols-2 gap-2 mt-2">
    <div class="flex gap-2 items-center justify-center">
      <img ngSrc="/assets/tick.png" alt="tick" height="30" width="30" class="invert h-5 w-5">
      <p>No ads</p>
    </div>
    <div class="flex gap-2 items-center justify-center">
      <img ngSrc="/assets/tick.png" alt="tick" height="30" width="30" class="invert h-5 w-5">
      <p>Replay games</p>
    </div>
    <div class="flex gap-2 items-center justify-center">
      <img ngSrc="/assets/tick.png" alt="tick" height="30" width="30" class="invert h-5 w-5">
      <p>Play the archive</p>
    </div>
    <div class="flex gap-2 items-center justify-center">
      <img ngSrc="/assets/tick.png" alt="tick" height="30" width="30" class="invert h-5 w-5">
      <p>Dedicated support</p>
    </div>
  </div>
  <button (click)="signUpToPremium()" class="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded mt-3">Sign up now</button>
</div>
