import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { Game } from 'src/app/interfaces/interfaces';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  darkMode!: boolean;
  state$: Observable<Game>;
  user$: Observable<UserDetails>;

  constructor(
    private data: DataService,
    private user: UserService,
    private router: Router
  ) {
    this.state$ = this.data.getState();
    this.user$ = this.user.getUserDetails();
  }

  ngOnInit(): void {}
}
