import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Link } from 'src/app/interfaces/Link';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { LinksService } from 'src/app/services/links.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  user$: Observable<UserDetails>
  link$: Observable<Link>

  constructor(private user: UserService, private links: LinksService, private analytics: AnalyticsService) {
    this.user$ = this.user.getUserDetails();
    this.link$ = this.links.links$;
  }

  ngOnInit(): void {
  }

  linkClicked() {
    this.analytics.sendEvent('footer_link_clicked', {
      event_category: 'The link in the footer box was clicked',
      event_label: 'Footer link clicked',
      value: 1
    });
  }
}
