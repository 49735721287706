import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  constructor(public dialogRef: MatDialogRef<ErrorComponent>, private router: Router) { }

  closeErrorMessage() {
    this.router.navigate(['/']);
    this.dialogRef.close();
  }
}
