<mat-dialog-content>
  <div class="text-black dark:!text-white">
    <h4 class="font-bold text-center mt-3 mb-3">
      We logged you out
    </h4>
    <p class="text-center">You have been logged out as you were inactive for a period of time.</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="w-full flex justify-end items-end">
      <button mat-button mat-dialog-close
      class="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">Close</button>
  </div>
</mat-dialog-actions>
