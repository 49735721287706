<mat-dialog-content>
    <div class=" text-black dark:!text-white mt-4 p-2">
        <div class="flex  items-center justify-center flex-col">
            <div class="flex items-center gap-1">
                <h1>
                    <b class="text-black dark:!text-white text-3xl md:text-5xl">WHERE<span
                            class="text-blue-600 dark:text-blue-300 ml-1">TAKEN</span></b>
                </h1>
                <img class="w-7 h-7 md:w-10 md:h-10" ngSrc="./assets/world.png" width="72" height="72" />
            </div>
            <p class="text-xs md:text-base font-bold mb-8">The daily image based geography game.</p>
        </div>
        <h1 class="text-lg md:!text-2xl font-bold mb-2">How to play</h1>
        <div class="flex flex-col gap-6">
            <div>
                <!-- <h4 class="font-bold text-lg">Step 1</h4> -->
                <p class="mb-2 text-sm md:!text-base"><span class="font-bold">Step 1<br> </span>Look at the image and think of what country
                    that photo was taken in.</p>
            </div>
            <div>
                <!-- <h4 class="font-bold text-lg">Step 2</h4> -->
                <p class="mb-2 text-sm md:!text-base"><span class="font-bold">Step 2<br> </span>Enter a country in the input box. You have 6
                    chances.</p>
                <img class="px-0 md:px-6" src="./assets/input.png">
            </div>
            <div>
                <!-- <h4 class="font-bold text-lg">Step 3</h4> -->
                <p class="mb-2 text-sm md:!text-base"><span class="font-bold">Step 3<br> </span>Enter your guess. Clues are given to help you.
                </p>
                <img class="px-0 md:px-6" src="./assets/example-game.png">
            </div>
            <div>
                <!-- <h4 class="font-bold text-lg">Step 4</h4> -->
                <p class="mb-2 text-sm md:!text-base"><span class="font-bold">Step 4<br> </span>Play the bonus rounds and share with your
                    friends!</p>
            </div>
        </div>
        <!-- <img src="./assets/wt.gif"> -->
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="w-full flex justify-end items-end">
        <button mat-button mat-dialog-close
            class="bg-blue-600 hover:bg-blue-400; text-white font-bold py-2 px-4 rounded">Play!</button>
    </div>
</mat-dialog-actions>