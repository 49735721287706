<div *ngIf="state$ | async as state" class="text-black dark:!text-white mt-3 relative">
  <a routerLink="/landmarks" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>
  <div *ngIf="!state.hasMapAndCityRound" class="text-center mb-6">
    <h4 class="text-lg font-bold text-center mb-1 mt-3">
      There is no map round today
    </h4>
    <p class="mb-3">But because you came this far, here is a free star!</p>
    <app-progress></app-progress>
  </div>
  <div class="flex flex-col items-center justify-center">
    <div *ngIf="state.hasMapAndCityRound" class="w-full mb-3">
      <h4 class="text-lg font-bold text-center mb-4 mt-3">
        What is the capital city of {{ state.country }}?
      </h4>
      <app-capital-city-guesses></app-capital-city-guesses>
      <app-progress *ngIf="!state.map.showRound" class="mt-3"></app-progress>
      <p *ngIf="!state.capitalCity.guessed && state.capitalCity.roundOver" class="text-center mt-2 font-bold text-base">
        Oh no, you didn't get it today! <br>The capital of {{ state.country }} is {{ state.capitalCity.answer }}</p>
    </div>
    <div #secondQuestion class="w-full" *ngIf="state.capitalCity.roundOver && state.map.showRound && state.capital">
      <h4 class="text-lg font-bold text-center mb-4 mt-8">
        Where in {{ state.country }} is {{ state.capitalCity.answer }}?
      </h4>
      <div class="px-4 md:px-16 w-full mb-4">
        <app-svg-map [countryCode]="state.countryCode"></app-svg-map>
      </div>
      <div [ngClass]="{
          'confetti-under': !showConfetti,
          'first-question' : !state.map.showRound,
          'second-question' : state.map.showRound
        }" class="confetti" #confettiContainer></div>
      <app-progress></app-progress>
      <div *ngIf="!state.map.roundOver" class="mt-2">
        <p class="text-center">
          Click on a location. You have <span>{{ state.map.guessesRemaining }}</span> guess<span
            *ngIf="state.map.guessesRemaining >= 2 || state.landmarks.guessesRemaining <=0">es</span> remaining.
        </p>
      </div>
    </div>
    <div #bonusRound *ngIf="state.map.roundOver" class="w-full">
      <div *ngIf="state.hasMapAndCityRound">
        <p *ngIf="state.map.guessed && state.capitalCity.guessed" class="text-center mt-4 font-bold">Nice one! You
          correctly guessed {{ state.capital }} and its location.</p>
        <p *ngIf="(!state.map.guessed && state.capitalCity.guessed) || (state.map.guessed && !state.capitalCity.guessed)"
          class="text-center mt-4 font-bold">You got one out of two correct today. You just missed out on a star.</p>
        <p *ngIf="!state.map.guessed && !state.capitalCity.guessed" class="text-center mt-4 font-bold">Oh no, you didn't
          get it today!</p>
      </div>
      <div
        class="p-2 mt-4 bg-gray-300 dark:bg-slate-700 rounded w-full text-center flex flex-col justify-center items-center gap-1">
        <p class="font-bold text-black dark:!text-white">
          <span class="text-orange-700">★</span> Bonus Round ~ 3/3 - City <span class="text-orange-700">★</span>
        </p>
        <button class="!bg-green-600 text-white mb-2 w-full rounded py-1" routerLink="/city">
          <b>🎁 Play the bonus round</b>
        </button>
      </div>
      <app-share-buttons></app-share-buttons>
    </div>

    <div *ngIf="state.capitalCity.roundOver && state.map.showRound">
      <app-end-mobile-ad></app-end-mobile-ad>
    </div>
  </div>
</div>
