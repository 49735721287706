import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';

declare global {
  interface Window {
    adngin?: any;
    destroyRefreshTopAd?: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  constructor(private platform: PlatformService) { }

  loadAdScript() {
    console.log("Loading ad script");
    if(!this.platform.isInBrowser()) return;
    const href = window.location.href;
    let src = ""
    if(href.includes("staging")) {
      console.log("Loading STAGING ads script");
      src = "https://staging-cdn.snigelweb.com/adengine/wheretaken.teuteuf.fr/loader.js";
    } else {
      console.log("Loading LIVE ads script");
      src = "https://cdn.snigelweb.com/adengine/wheretaken.teuteuf.fr/loader.js";
    }
    let node = document.createElement('script');
    node.src = src;
    node.type = 'text/javascript';
    node.async = true;
    node.setAttribute("data-cfasync", "false");
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  destroyTopAd() {
    if (typeof window.destroyRefreshTopAd === "function") {
      try {
        window.destroyRefreshTopAd();
      } catch (e) {
        console.error(e);
      }
    }
  }

  startAuction(adName: string, device: string) {
    if(device === 'desktop' && window.innerWidth < 1280 ) {
      return;
    } else if(device === 'mobile' && window.innerWidth >= 1280) {
      return;
    }
    setTimeout(() => {
      if (typeof window.adngin.cmd === "object") {
        try {
          if (window.adngin && window.adngin.adnginLoaderReady) {
            console.log("Starting auction for " + adName);
            window.adngin.cmd.startAuction([adName]);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, 500);
  }
}
