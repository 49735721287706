<div class="mt-3 text-black dark:!text-white">
    <a routerLink="/fix-stats" class="text-blue-500 hover:text-blue-400">&#8592; Back</a>
    <div class="flex flex-col">
        <h4 class="text-lg font-bold mb-1 mt-3">
            Enter in the your stats
        </h4>
        <p>Enter your guess distribution, plus your total games, current streak and max streak. You can find your stats at <a class="text-blue-600" href="https://wheretaken.com/stats" target="_blank">https://wheretaken.com/stats</a></p>
        <form [formGroup]="statsForm">
            <div class="flex gap-2 mt-4">
                <div>
                    <label for="one" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">1</label>
                    <input formControlName="one" type="number" id="one" class="bg-gray-50 border !border-gray-600 dark:!border-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                </div>
                <div>
                    <label for="two" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">2</label>
                    <input formControlName="two" type="number" id="two" class="bg-gray-50 border !border-gray-600 dark:!border-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                </div>
                <div>
                    <label for="three" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">3</label>
                    <input formControlName="three" type="number" id="three" class="bg-gray-50 border !border-gray-600 dark:!border-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                </div>
                <div>
                    <label for="four" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">4</label>
                    <input formControlName="four" type="number" id="four" class="bg-gray-50 border !border-gray-600 dark:!border-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                </div>
                <div>
                    <label for="five" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">5</label>
                    <input formControlName="five" type="number" id="five" class="bg-gray-50 border !border-gray-600 dark:!border-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                </div>
                <div>
                    <label for="six" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">6</label>
                    <input formControlName="six" type="number" id="six" class="bg-gray-50 border !border-gray-600 dark:!border-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                </div>
            </div>
            <div class="mt-4 flex w-full gap-3">
                <div class="w-3/6">
                    <label for="total" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Total games</label>
                    <input formControlName="totalGames" type="number" id="total" class="bg-gray-50 border !border-gray-600 dark:!border-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                </div>
                <div class="w-3/6">
                    <label for="current" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Current streak</label>
                    <input formControlName="currentStreak" type="number" id="current" class="bg-gray-50 border !border-gray-600 dark:!border-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                </div>
                <div class="w-3/6">
                    <label for="max" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Max streak</label>
                    <input formControlName="maxStreak" type="number" id="max" class="bg-gray-50 border !border-gray-600 dark:!border-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                </div>
            </div>
            <div class="flex justify-center mt-3">
                <button (click)="submitForm()" class="bg-blue-600 text-white font-bold py-2 px-4 rounded mt-2">Change stats</button>
            </div>
        </form>
      </div>
      <div *ngIf="showErrorAlert" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-3" role="alert">
        <strong class="font-bold">Oh no!&nbsp;</strong>
        <span class="block sm:inline">Those numbers don't add up...</span>
      </div>
      <div *ngIf="showChangedAlert" class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-3" role="alert">
        <strong class="font-bold">Nice!&nbsp;</strong>
        <span class="block sm:inline">Your stats have been changed</span>
      </div>
</div> 