<mat-dialog-content>
  <div class="text-black dark:!text-white">
    <div class="absolute right-4 top-4">
      <button (click)="closeDialog()" mat-button class="text-black font-bold rounded">
        &#x2715;
      </button>
    </div>
    <h4 class="font-bold text-center mt-3 mb-3">
      Error!
    </h4>
    <p class="mb-2">WhereTaken Classic's official website is https://wheretaken.teuteuf.fr/ It looks like you're playing
      on a
      website
      that has stolen it!</p>
    <p class="mb-2">These websites steal revenue from game creators by re-hosting their games and overlaying their own
      ads. Play
      WhereTaken Classic on wheretaken.teuteuf.fr, and you'll always have the latest version, fewer ads and help
      support
      the creators (i.e. us)!</p>
    <p>Thanks,</p>
    <p class="mb-2">- Teuteuf Team</p>
  </div>
  <div class="w-full flex justify-center items-center mt-2">
    <a href="https://wheretaken.teuteuf.fr/?utm_campaign=iframe_popup" target="_blank"
      class="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">
      Go There
    </a>
  </div>
</mat-dialog-content>