import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { Game } from 'src/app/interfaces/interfaces';
import { AdsService } from 'src/app/services/ads.service';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-top-banner-ad',
  templateUrl: './top-banner-ad.component.html',
  styleUrls: ['./top-banner-ad.component.scss']
})
export class TopBannerAdComponent {

  state$: Observable<Game>;
  userDetails$: Observable<UserDetails>;

  constructor(private ads: AdsService, private data: DataService, private user: UserService){
    this.state$ = this.data.getState();
    this.userDetails$ = this.user.getUserDetails();
  }

  toggleTopAd(isOpen: boolean) {
    // Destroy if not showing ad anymore
    if(isOpen) {
      this.ads.destroyTopAd();
    }
    else {
      this.ads.startAuction("top_banner", 'mobile');
    }
    this.data.toggleTopAdOpen();
  }

}
