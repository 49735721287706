<div *ngIf="user$ | async as user">
  <div *ngIf="link$ | async as link"
    class="flex flex-col gap-6 items-center mb-6 mt-10 text-black dark:!text-white">
    <div class="text-center" *ngIf="!user.subscription?.isPremium">
      <a href="https://account.teuteuf.fr/premium-sign-up" class="font-bold text-blue-700 dark:text-blue-500">Sign up
        for Teuteuf Premium</a>
      <p>Remove ads, play the archive and replay games.</p>
    </div>
    <div *ngIf="link && link.link && link.text">
      <span><b>❤️ WHERE<span class="text-blue-600 dark:text-blue-300">TAKEN</span>?</b></span>
      <span> - </span>
      <a (click)="linkClicked()" class="underline" [href]="link.link" target="_blank">{{ link.text }}</a>
    </div>
    <div className="flex gap-1 justify-center items-center mt-2 w-full" style="display: flex; justify-content: center; align-items: center; gap: 6px">
      <a href="https://www.facebook.com/teuteufgames/" target="_blank" rel="noreferrer">
        <img src="/assets/icons/facebook.png" alt="facebook" style="width: 36px; height: 36px;"  />
      </a>
      <a href="https://twitter.com/Worldle_Game" target="_blank" rel="noreferrer">
        <img src="/assets/icons/twitter.png" alt="twitter" style="width: 36px; height: 36px;" />
      </a>
      <a href="https://www.instagram.com/teuteufgames" target="_blank" rel="noreferrer">
        <img src="/assets/icons/instagram.png" alt="instagram" style="width: 31px; height: 31px;" />
      </a>
    </div>
  </div>
</div>
