import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as confetti from 'canvas-confetti';
import { map, Observable, Subscription } from 'rxjs';
import { Country, Game, Stats } from 'src/app/interfaces/interfaces';
import { CountriesService } from 'src/app/services/countries.service';
import { DataService } from 'src/app/services/data.service';
import { EventsService } from 'src/app/services/events.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-city-input',
  templateUrl: './city-input.component.html',
  styleUrls: ['./city-input.component.scss']
})
export class CityInputComponent {
  valueChanges!: Subscription;
  state!: Subscription;
  box!: Subscription;

  confetti = false;
  showConfetti = true;

  @ViewChild('dropdown', { read: ElementRef, static: false })
  dropdown!: ElementRef;
  @ViewChild('confettiContainer', { static: false })
  confettiContainer!: ElementRef;
  @ViewChild('inputBox', { static: false })
  inputBox!: ElementRef;

  @HostListener('document:click', ['$event'])
  clickOut() {
    if (this.dropdown !== undefined) {
      if (this.focusClicked) {
        this.focusClicked = false;
      } else {
        this.showListBox = false;
      }
    }
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.showListBox && this.list.length > 0) {
      this.input.setValue(this.list[0]);
      this.showListBox = false;
    } else {
      this.guess();
    }
  }

  showListBox = false;
  input = new UntypedFormControl('');
  focusClicked = false;
  cityNames: string[] = [];
  list: string[] = [];
  state$: Observable<Game>;
  showAnswer$: Observable<boolean>;
  stats$: Observable<Stats>;
  inputValue$: any;
  copied = false;

  constructor(
    private data: DataService,
    private countries: CountriesService,
    private renderer: Renderer2,
    private events: EventsService,
    private user: UserService
  ) {
    this.state$ = this.data.getState();
    this.showAnswer$ = this.state$.pipe(map((val) => val.showAnswer));
    this.inputValue$ = this.state$.pipe(map((val) => val.guessValue));
    this.stats$ = this.user.getStats();
  }

  ngOnInit(): void {
    this.onChanges();
    this.inputChange();
    let states: Country[] = this.countries.getCountries();
    states.forEach(state => {
      this.cityNames.push(state.capital);
      state.cities.forEach(city => {
        this.cityNames.push(city);
      });
    });
    //Remove duplicates
    this.cityNames = [...new Set(this.cityNames)];
    this.cityNames = JSON.parse(JSON.stringify(this.cityNames));

    this.box = this.events.boxClicked.subscribe(val => {
      this.inputBox && this.inputBox.nativeElement.focus();
      this.filterList("");
    })
  }

  onFocus() {
    if (this.input.value.length >= 0) {
      this.focusClicked = true;
      this.showListBox = true;
    }
  }

  onChanges() {
    this.valueChanges = this.input.valueChanges.subscribe((val) => {
      if (val && val.length > 0) {
        this.showListBox = true;
        this.filterList(val);
      } else {
        this.showListBox = false;
      }
    });
  }

  inputChange() {
    this.state = this.state$.subscribe((val) => {
      if(val.capitalCity.showConfetti && !val.capitalCity.shownConfetti) {
        this.confettiRun();
        this.data.cityConfetti();
      }
    });
  }

  filterList(val: string) {
    this.list = [];
    this.cityNames.forEach((word) => {
      if (word.toLowerCase().includes(val.toLowerCase())) {
        this.list.push(word);
      }
    });
    this.list.sort();
  }

  guess() {
    this.data.capitalCityGuess(this.input.value, this.cityNames);
    this.input.reset();
  }

  citySelect(city: string) {
    this.data.updateInputValueCity(city);
    this.input.setValue(city);
    this.showListBox = false;
  }

  ngOnDestroy() {
    this.valueChanges.unsubscribe();
    this.state.unsubscribe();
  }

  confettiRun() {
    const canvas = this.renderer.createElement('canvas');
    this.renderer.appendChild(this.confettiContainer.nativeElement, canvas);

    let myConfetti = confetti.create(canvas, { resize: true });

    myConfetti({
      origin: {
        x: 0.5,
        y: 1,
      },
    });
  }

}
