import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'iframe-warning-dialog',
  templateUrl: './iframe-warning.component.html',
  styleUrls: ['./iframe-warning.component.scss'],
})
export class IFrameWarningComponent {
  constructor(public dialogRef: MatDialogRef<IFrameWarningComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
